import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatChip } from '@angular/material/chips';
import { MatChipList } from '@angular/material/chips';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButton, MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-add-leave-group-pop-up',
  templateUrl: './add-leave-group-pop-up.component.html',
  styleUrls: ['./add-leave-group-pop-up.component.css'],
})
export class AddLeaveGroupPopUpComponent implements OnInit {
  leaveGroupData: any[] = [];
  formGroup!: FormGroup;
  leaveTypeFilter = new FormControl<string>('');
  leaveTypeSelectValues: { value: string }[] = [];
  leaveTypeSelect: FormControl<string[]>;

  newLeaveGroupNameControl = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<AddLeaveGroupPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private httpService: HttpserviceService, 
    private fb: FormBuilder
  ) {
    this.leaveTypeSelect = new FormControl([], Validators.required); 
    }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
        newLeaveGroupName: this.newLeaveGroupNameControl,
        leaveTypeSelect: this.leaveTypeSelect,
    });

    this.httpService.httpGet(`/LeaveGroup/GetLeaveTypes?CID=${this.data.CID}`, '').subscribe(response => {
        if (response && response.respdata) {
          
            this.leaveGroupData = response.respdata.map(leaveType => ({
                LeaveTypeID: leaveType.LeaveTypeID,
                LeaveDisplayName: `${leaveType.LeaveTypeID} - ${leaveType.LeaveName}` 
            }));
            console.log(this.leaveGroupData);
        } else {
            console.error('No leave group data found in response');
        }
    });
}

isSelected(leaveTypeDisplayName: string): boolean {
  return this.leaveTypeSelectValues.some(item => item.value === leaveTypeDisplayName);
}

remove(leaveType: string) {
  const index = this.leaveTypeSelectValues.findIndex(item => item.value === leaveType);
  if (index >= 0) {
      this.leaveTypeSelectValues.splice(index, 1);
      this.leaveTypeSelect.setValue(this.leaveTypeSelectValues.map(item => item.value));
      //this.leaveTypeSelect.updateValueAndValidity();
  }
}


  toggleSelection(leaveTypeDisplayName: string) {
    const index = this.leaveTypeSelectValues.findIndex(item => item.value === leaveTypeDisplayName);

    if (index >= 0) {
        
        this.leaveTypeSelectValues.splice(index, 1);
    } else {
        
        this.leaveTypeSelectValues.push({ value: leaveTypeDisplayName });
    } 
    this.leaveTypeSelect.setValue(this.leaveTypeSelectValues.map(item => item.value));
    //this.leaveTypeSelect.updateValueAndValidity(); 
    this.leaveTypeFilter.setValue('');
}

  

  filteredLeaveTypes() {
    const filterValue = this.leaveTypeFilter.value?.toLowerCase() || '';
    return this.leaveGroupData.filter(leaveType =>
        leaveType.LeaveDisplayName.toLowerCase().includes(filterValue)
    );
}
  Add_New_Leave_Group() {
    if (this.formGroup.valid) {
      const leaveTypeIds = this.leaveTypeSelectValues.map(leaveType => parseInt(leaveType.value.split(' - ')[0]));
      const data = {
        Flag: 2, 
        CID: this.data.CID, 
        LeaveGroupName: this.newLeaveGroupNameControl.value,
        LeaveTypeIDs: JSON.stringify(leaveTypeIds), 
        "@LeaveGroupID": "" 
      };

      
      this.httpService.httpPost('/LeaveGroup/SetLeaveGroupConfiguration', data).subscribe(response => {
        console.log(response);
        this.dialogRef.close(response);
      });
    } else {
      this.formGroup.markAllAsTouched();
      this.leaveTypeSelect.setErrors({ required: true }); 
      this.leaveTypeSelect.markAsTouched()
    }
  }

  onClosePopup() {
    this.dialogRef.close();
  }
}