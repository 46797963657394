import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';


@Component({
  selector: 'app-monthlyreport',
  templateUrl: './monthlyreport.component.html',
  styleUrls: ['./monthlyreport.component.css']
})
export class MonthlyreportComponent implements OnInit {
  @ViewChild('stepper') stepper;

  dataSource: any;
  dataSource1: any;
  MonthlyReportFormGroup: FormGroup;
  CustomDataFormGroup:FormGroup;
  Companies: any;
  Employees:any;
  Months:any;
  employeeid:string[]=[];
  selectedEmpID: any;
  cid:any; 
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  showSpinner:any;
  myControl1 = new FormControl();
  filteredOptions1: Observable<string[]>;
  selectedProjectID: number;
  projectid: string[] = [];
  selectedprojectid: any;
  Projectlist:any;
  isEditable = true;
  Curmonthvalue=new Date().getMonth()+1

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,
    public notificationService: NotificationService, private datePipe: DatePipe) 
    { 
      this.MonthlyReportFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] , 
        myControl: ['', Validators.required],                
        fcMonth: ['', Validators.required],             
        fcYear: ['', Validators.required]   
      });
      this.CustomDataFormGroup = this.formBuilder.group({                 
      });
    }

  ngOnInit(): void {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    this.PageLoad();
    this.MonthlyReportFormGroup.controls.fcCompany.setValue(this.cid);
   // console.log('curmonth',this.Curmonthvalue)

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); 
      
      this.filteredOptions1 = this.myControl1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter1(value, 'myControl1'))
      );

      this.MonthlyReportFormGroup.controls.fcYear.setValue(new Date().getFullYear());
      this.MonthlyReportFormGroup.controls.fcMonth.setValue(this.Curmonthvalue);

  }

  private _filter1(value: string, control: string): string[] {
    
    const filterValue = value.toLowerCase();
    // console.log('controlID',filterValue);
    this.selectedprojectid=''
    // console.log('FilterProjectID',this.employeeidname);
  //  console.log('FilterProjectID',this.projectid);
    if (control === 'myControl1') {
      //console.log('FilterProjectID',this.projectid);
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }

  }
  selectedEmployee1(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
    if (control === 'myControl1') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.Projectlist, { 'ProjectName': event.option.value }), 'ProjectID'));
    }
   

  }
  changesite(e) {
    this.cid=e.value;
    this.Employees = '';
    this.Projectlist='';
    this.projectid = [];
    this.employeeid= [];
    this.PageLoad();
    this.myControl.setValue('');   
    this.myControl1.setValue('');   
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  selectedemployee(event) {             
    // this.selectedEmpID = event.option.value;
    // const str: string = this.selectedEmpID;   
    // this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-')); 

      const inputString = event.option.value;    
      const parts = inputString.split("-");        
      const lastPart = parts.pop();      
      this.selectedEmpID = parts.join("-");
  }

  Submit() {   
    
    this.showSpinner=true;
    let params = {          
      CID: this.cid,         
      Flag:  this.selectedEmpID==undefined ? "GETGRID" :"GETGRID1",       
      LedgerID: this.selectedEmpID,
      Month: this.MonthlyReportFormGroup.get('fcMonth').value,
      Year:this.MonthlyReportFormGroup.get('fcYear').value,
      ProjectID:this.selectedprojectid
    };
    this.service.httpPost('/ETSMonthlyReport/GetMonthlyData',params)
    .subscribe(
      (data) => {
        this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.dataSource = result.respdata.MonthlyData; 
          this.dataSource1 = result.respdata.MonthlyLeaveData; 
      // console.log('fkjsg',this.dataSource1)
          this.stepper.selectedIndex = 1;                   
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      }); 
  }

  onSearchClear(){
    this.MonthlyReportFormGroup.reset();   
    this.myControl.setValue('');   
    this.myControl1.setValue('');   
    this.selectedEmpID=undefined;
    this.dataSource='';
    this.stepper.selectedIndex = 0;   
    this.MonthlyReportFormGroup.controls.fcCompany.setValue(this.cid);
    this.MonthlyReportFormGroup.controls.fcYear.setValue(new Date().getFullYear());    
    this.MonthlyReportFormGroup.controls.fcMonth.setValue(this.Curmonthvalue);
  }
  SearchClear()
  {
    this.myControl1.setValue('');  
  }
  PageLoad() {
    // console.log('ledgerid',sessionStorage.getItem("groupid"));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",
      EmployeeID:"",              
      Department:"",
      LeaveType: "",
      FromDate:"",
      ToDate:"",
      RtnStatus:"",
      UserID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid")
     
    };
    this.service.httpPost('/ETSMonthlyReport/GetMonthlyData',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = curUser.dtsiteid;                         
            this.Employees = result.respdata.Employees;
            this.Months = result.respdata.Month;
            this.Projectlist=result.respdata.Project;
            this.projectid = _.pluck(this.Projectlist, 'ProjectName');
            this.employeeid= _.pluck(this.Employees, 'Name');              
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

}
