
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ENTER, COMMA, I } from '@angular/cdk/keycodes';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { MatAutocompleteActivatedEvent, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-employee-hierarchy',
  templateUrl: './employee-hierarchy.component.html',
  styleUrls: ['./employee-hierarchy.component.css']
  // ,
  // encapsulation: ViewEncapsulation.None
})
export class EmployeeHierarchyComponent implements OnInit {
  flag = 'ADD';
  employeelistselected: any = [];
  employeelist: any;
  model: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: any = [];
  temp:any = [];
  autocompleteTagsOptionActivated = false;
  
  @ViewChild('fruitInput') fruitInput: ElementRef;
  @ViewChild('autoInput', { read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor(private dialog: MatDialog,
              public notificationService: NotificationService,
              public dialogRef: MatDialogRef<EmployeeHierarchyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any){

    this.flag = this.data.flag;
    // this.row = this.data.row;
    this.employeelist=this.data.employeelist

    // this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((fruit: string | null) => fruit ? this._filter(fruit) : this.employeelist.slice()));


    this.fruitCtrl.valueChanges.subscribe(search => {
      this.filteredFruits = of(this.employeelist.filter(item =>
      item.name.toLowerCase().includes(search)
      ));
      });
      

  }
  ngOnInit(): void {
    this.dialogRef.updateSize('70%');
  }
  optionActivated($event: MatAutocompleteActivatedEvent) {
    if ($event.option) {
      this.autocompleteTagsOptionActivated = true;
    }
  }
  submit(flag){
    this.model = this.employeelistselected;  //get the model from the form
          this.onClose(flag,this.model);
  }
  exit(flag){
    this.model = this.employeelistselected;  //get the model from the form
          this.onClose(flag,this.model);
  }


  onClose(flag,model){
    this.dialogRef.close({event: flag, data: model});
  }

  add(event: MatChipInputEvent): void {
    debugger
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.employeelistselected.push({
        id:Math.random(),
        name:value.trim()
      });

      // this.employeelistselected = options.map(o => o.value);
    
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit, indx): void {
    this.employeelistselected.splice(indx, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.employeelistselected.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    
  }
}
