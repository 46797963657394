import { Component, Inject, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { User } from './../../../Modules/user';
import { MAT_DIALOG_DATA, MatDialog,MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dependencyrpt',
  templateUrl: './dependencyrpt.component.html',
  styleUrls: ['./dependencyrpt.component.scss'],
})
export class DependencyrptComponent implements OnInit {

  DependencyReportFormGroup: FormGroup;
  cid:any;
  dataSource:any;
  
  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog: MatDialog,
    public notificationService: NotificationService, private datePipe: DatePipe,
    public dialogRef: MatDialogRef<DependencyrptComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
     
      //console.log('Dialog data',this.data)

    this.DependencyReportFormGroup = this.formBuilder.group({                 
     });
    
   }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    this.PageLoad();
  }

  PageLoad() {
   
    let params = {
          CID: this.data.CID,
          Flag: "PageLoad",     
          ReqType:this.data.ReqType,
          Client:this.data.Client,
          Product:this.data.Product, 
          Priority:this.data.Priority,
          Project:this.data.Project,
          Issues:this.data.Issues,
          Status:this.data.Status,
          Location:this.data.Location,
          Branch:this.data.Branch,
          AssignTo:this.data.AssignTo,
          Reportby:this.data.Reportby,
          DateType:'cs',  
          Subject:this.data.Subject,   
          UserID:this.data.UserID, 
          FilterBy:this.data.FilterBy,
          TicketCategory:this.data.TicketCategory,      
          SLA:this.data.SLA,      
          FromDate:this.data.FromDate,
          ToDate:this.data.ToDate
    };
    this.service.httpPost('/DependencyReport/GetDependency',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.dataSource=result.respdata.Dependency;
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onCloseDialog()
  {
     this.dialogRef.close({event: 'CLEAR'}); 
  }

  onCellPrepared (e) {   
    if (e.rowType === "data") {
        if (e.column.dataField === "SrcStatus") {                   
           e.cellElement.style.backgroundColor = e.data.SrcColorCode;
        }
        if (e.column.dataField ==="DstStatus") {                        
          e.cellElement.style.backgroundColor = e.data.DstColorCode;
       }
    }
  }
}
