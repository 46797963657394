import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DialogleaveapprovalComponent } from './../dialogleaveapproval/dialogleaveapproval.component';
//import { FormGroup,FormControl } from '@angular/Forms';
import { Component, OnInit, Pipe, PipeTransform, ViewChild, NgModule, enableProdMode } from '@angular/core';
import { LeaveApprovalService, Employee } from '../leave-approvalValidate';
// import { BrowserModule } from '@angular/platform-browser';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { DxPopupModule, DxButtonModule, DxTemplateModule } from 'devextreme-angular';
// import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { NewpopupleavedirectapprovalComponent } from '../newpopupleavedirectapproval/newpopupleavedirectapproval.component';

@Component({
  selector: 'app-empleaverequest',
  templateUrl: './empleaverequest.component.html',
  styleUrls: ['./empleaverequest.component.css']
})
export class EmpleaverequestComponent implements OnInit {

  employeelist: any;
  ledgerid= '';
  dataSource1: any;
  leavedetails: any;
  otherdetails: any;
  requestyear: any= 0;
  popupVisible = false;
  CID: any;
  navbarOpen = true;
  companies: any;
  isLinear = false;
  CurLedgerID:any;  
  toggle= 'filter_list';
  addbtnvisible: boolean = false;
  viewbtnvisible: boolean = false;
  filteredOptions: Observable<string[]>;
  empid:string[]=[];
  approverdetails:any;

  displayedColumns = ['FromDate', 'ToDate','CalendarDay', 'DaysRequested', 'LeaveType', 'Status', 'Comments', 'Action'];
  displayedColumns1 = ['CategoryName', 'LeaveCount'];
  displayedColumns2 = ['CategoryName', 'Available', 'Taken'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service: LeaveApprovalService, private NotificationService: NotificationService, 
    private generic: GenericService, private service1: UserService,
    private dialog: MatDialog, private dialogService: DialogService) { }

  async ngOnInit() {
      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      this.CurLedgerID=curUser.ledgerid;
      this.CID=JSON.parse(sessionStorage.getItem('cid'));  
      this.requestyear = new Date().getFullYear();
 
      // this.getUserList();
      await  this.getemployeelist();
      await this.Submit();
      
     
  }

  async Submit(){
    // await this.getemployeelist();
    console.log('Refere')
   await this.getleaveapproval(); 
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    if (this.navbarOpen == false)
    { this.toggle = 'close'; }
    else{this.toggle = 'filter_list'; }
  }
  show()
  {
    this.popupVisible = true;
  }
  showInfo(eventName) {
    this.popupVisible = true;
  }
  onvaluechanged() {
    this.getleaveapproval();
   }
  addRow() {
    if (this.CurLedgerID != '')
   {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: 'ADD',
      ledgerid: this.CurLedgerID,
      requestyear: this.requestyear,
      cid:this.CID,
      type: 0
    };
    const dialogRef = this.dialog.open(NewpopupleavedirectapprovalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {     
      this.getleaveapproval();     
    });
  }
  }
  onEdit(data, _flag){
     this.service.populateForm(data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: _flag,
      ledgerid: this.CurLedgerID,
      requestyear: this.requestyear,
      cid:this.CID,
      type: 0,
      daystatus:data.DayStatus
    };
    const dialogRef = this.dialog.open(NewpopupleavedirectapprovalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {     
      this.getleaveapproval();     
    });
  }
  onDelete(data, _flag){
     data.RtnDate = data.ToDate;
     const data1 = data;
    this.dialogService.openConfirmDialog('Are you sure to delete this leave?')
    .afterClosed().subscribe(res => {
      if (res) {
        const params = {
          cid: this.CID,
      flag: 'DELETE',
      LedgerID: this.CurLedgerID,
      LeaveID: data.LeaveID,
      LeaveType: data.CategoryName,
      Comment: data.Comments,
      DayStatus: data.DayStatus,
      FromDate: data.FromDate,
      ToDate: data.ToDate,
      ReturnDate: data.ToDate,
      Session: data.SessionType,
      UserID: '',
      EmpUniqID	: sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid"),
      GroupID: sessionStorage.getItem('groupid'),
      Year: this.requestyear,
      Type: 0
        };
        const params1 = Object.assign(params);
        this.service.setleaveapproval(params1)
          .subscribe(
            data => {
              let result: any;
              result = data;
              this.NotificationService.warn('Record deleted successfully');
              this.getleaveapproval();
            },
            error => {
              console.log(error);
        });
      }
    });

  }

  onView(data, _flag){
    console.log('DATA',data)
    const params = {
      cid: this.CID,
      flag: _flag,
      ledgerid: data.LedgerID,
      LeaveID: data.LeaveID,
      year: 0

        };
        this.service.GetLeaveDetails(params)
        .subscribe(data => {
          let result: any;               
          result = data;
          if (result.errno === 0){
            this.approverdetails = result.respdata.Table;          
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }
  async getemployeelist(){
        const params = {
          cid: this.CID,
          flag: 'PAGELOAD',
          ledgerid: 0,
          LeaveID: 0,
          year: this.requestyear

            };
       let t= await this.service.GetLeaveDetails(params)
        .toPromise().then(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.companies = result.respdata.Table;
            // this.getleaveapproval(); 
            this.getUserList();
           
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
            
          }
        },
        error => {
          console.log('Error : ', error);
        });
      }

   async getUserList(){
        let params = {
          cid: JSON.parse(sessionStorage.getItem('cid')),
          menuid: "STS_94",
          groupid: JSON.parse(sessionStorage.getItem('groupid'))
        }
        let t= await this.service1.getUserList(params)
        .toPromise().then((data) => {
          let result: any;
          result=data;
          let btnVisilityData;
          btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
          this.addbtnvisible = btnVisilityData.addflag;   
          this.viewbtnvisible = btnVisilityData.viewflag;                     
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)         
            console.log(error);
          else throw error;       
        });
    
      }
   async getleaveapproval(){
        const params = {
          cid: this.CID,
          flag: 'LOADLEAVE',
          ledgerid: this.CurLedgerID,
          LeaveID: 0,
          year: this.requestyear

            };
            console.log('Refresh', params)
           let t= await this.service.GetLeaveDetails(params)
            .toPromise().then(data => {
              let result: any;
              result = data;
              if (result.errno === 0){
                this.dataSource1 = result.respdata.Table1;
                this.leavedetails = result.respdata.Table;
                //this.otherdetails = result.respdata.Table;
              }
              else{
                this.NotificationService.success(result.errdesc); //':: Problem details');
              }
            },
            error => {
              console.log('Error : ', error);
            });
          }

          onChangeEvent(event: any){
           
               const params = {
                cid: this.CID,
                flag: 'LOADLEAVE',
                ledgerid: this.CurLedgerID,
                LeaveID: 0,
                year: event.target.value
      
                  };
                  this.service.GetLeaveDetails(params)
                  .subscribe(data => {
                    let result: any;
                    result = data;
                    if (result.errno === 0){
                      this.dataSource1 = result.respdata.Table2;
                      this.leavedetails = result.respdata.Table1;
                      this.otherdetails = result.respdata.Table;
                    }
                    else{
                      this.NotificationService.success(result.errdesc); //':: Problem details');
                    }
                  },
                  error => {
                    console.log('Error : ', error);
                  });
            }
}
