
import { Component, OnInit,ViewChild } from '@angular/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { GenericService } from '../../../../Services/OtherServices/generic.service';
import { User } from 'src/app/Modules/user';

export interface User1 {
  Name: string;
}

@Component({
  selector: 'app-emp-att-report-view',
  templateUrl: './emp-att-report-view.component.html',
  styleUrls: ['./emp-att-report-view.component.scss'],
})
export class EmpAttReportViewComponent implements OnInit {
  @ViewChild('stepper') stepper;
  flag:any
  ledgerid= 0;
  Categoryid= 0;
  CID: any;
  SubCategory:any;
  frmdate= new Date();
  companies:any;
  category:any;
  Subcategory:any;
  employeelist:any;
  datasource:any;
  datasource1:any;
  empid:string[]=[];
  filteredOptions: any;
  myControl = new FormControl();
  AttendanceGroup : FormGroup;
  TimepunchDataGroup : FormGroup;
  Effortlist:any;
  PostedBy:any;
  isEditable = true; 
  Effort = {
   store: {
       type: 'array',
       data: [
           { id: 2, name: 'All' },
           { id: 3, name: 'Post' },
           { id: 1, name: 'Not Post' }
       ],
       key: 'id'
   }};

 constructor(private service:HttpserviceService, private formBuilder: FormBuilder,private generic: GenericService,
  public notificationService: NotificationService, private datePipe: DatePipe)
    { 

     this.AttendanceGroup = this.formBuilder.group({  
       fcCompany: ['', Validators.required] ,             
       fcCategory: [''], 
       fcSubCategory: [''],  
       fcEffort: [''],   
       fcDate: [''],
       fcPostedBy:['']
        
   });
   this.TimepunchDataGroup = this.formBuilder.group({                 
   });
    }

 ngOnInit(): void {
  this.CID=JSON.parse(sessionStorage.getItem('cid'));
   this.Effortlist = this.Effort.store.data;
   this.flag="PAGELOAD";
   this.getAttendancereport();
   this.myControl.setValue({Name: 'ALL'});
   this.AttendanceGroup.controls.fcCompany.setValue(this.CID);
   this.AttendanceGroup.controls.fcCategory.setValue(0);
   this.AttendanceGroup.controls.fcSubCategory.setValue(0);
   this.AttendanceGroup.controls.fcPostedBy.setValue(0);
   this.AttendanceGroup.controls.fcEffort.setValue(2);
   this.AttendanceGroup.controls.fcDate.setValue(this.frmdate);
   this.filteredOptions = this.myControl.valueChanges
     .pipe(
       startWith<string | User1>(''),
       map(value => typeof value === 'string' ? value : value.Name),
       map(name => name ? this._filter(name) : this.empid.slice())
     );
 }
 applyFilter(event: Event) {
   const filterValue = (event.target as HTMLInputElement).value;
   this.datasource.filter = filterValue.trim().toLowerCase();
 }
 displayFn(empid?: User1): string | undefined {
   return empid ? empid.Name : undefined;
 }
 selectedproduct(event) {    
   this.ledgerid=event.option.value;   
   this.myControl.setValue({Name: event.option.value }); 
   this.ledgerid=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));     
 }
 private _filter(name: string): string[] {

   const filterValue = name.toLowerCase();   
     return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
 }
 onCompanySelect(event)
 {
   this.CID=event.value;
   this.flag="PAGELOAD";
   this.employeelist = '';
   this.empid = [];
   this.getAttendancereport();
   this.myControl.setValue({Name: 'ALL'});
   this.AttendanceGroup.controls.fcCompany.setValue(this.CID);
   this.AttendanceGroup.controls.fcCategory.setValue(0);
   this.AttendanceGroup.controls.fcSubCategory.setValue(0);
   this.AttendanceGroup.controls.fcPostedBy.setValue(0);
   this.AttendanceGroup.controls.fcEffort.setValue(2);
   this.AttendanceGroup.controls.fcDate.setValue(this.frmdate);
   this.filteredOptions = this.myControl.valueChanges
     .pipe(
       startWith<string | User1>(''),
       map(value => typeof value === 'string' ? value : value.Name),
       map(name => name ? this._filter(name) : this.empid.slice())
     );
  
 }
 
 onSearchClear(){
  this.AttendanceGroup.reset(); 
  this.datasource='';   
  this.AttendanceGroup.controls.fcCompany.setValue(this.CID);
  this.AttendanceGroup.controls.fcCategory.setValue(0);
  this.AttendanceGroup.controls.fcSubCategory.setValue(0);
  this.AttendanceGroup.controls.fcPostedBy.setValue(0);
  this.AttendanceGroup.controls.fcEffort.setValue(2);
  this.AttendanceGroup.controls.fcDate.setValue(this.frmdate);
  this.stepper.selectedIndex = 0; 
  this.ledgerid=0;
}


 Refresh()
 {
   this.flag="LOADGRID";
   this.getAttendancereport();
 }

 
exportAsExcel()
{
  this.generic.excelExport(this.datasource, 'Effort','EffortList for ' + this.datePipe.transform(this.frmdate, 'dd-MM-yyyy'));
}

 getAttendancereport(){
   
   let  params

 if(this.flag=="PAGELOAD")  
 {
  
   params= {
        
    cid: this.CID,
    LedgerID: this.ledgerid,
    Category: "0",
    SubCategory:"0",
    Effort:2,
    date: this.datePipe.transform(this.frmdate, 'dd-MM-yyyy'),
    flag: this.flag,
    PostedBy:0
   }
 }
 else
 {
   params=
   {
     cid:this.AttendanceGroup.get('fcCompany').value,
     LedgerID: this.ledgerid,
     Category: this.AttendanceGroup.get('fcCategory').value,
     SubCategory: this.AttendanceGroup.get('fcSubCategory').value,
     Effort:this.AttendanceGroup.get('fcEffort').value,
     date: this.datePipe.transform(this.AttendanceGroup.get('fcDate').value,"dd-MM-yyyy"),
     flag: this.flag,
     PostedBy:this.AttendanceGroup.get('fcPostedBy').value         
 }

};
let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      const query = `cid=${params.cid}&LedgerID=${params.LedgerID}&Category=${params.Category}&SubCategory=${params.SubCategory}&Effort=${params.Effort}&date=${params.date}&flag=${params.flag}&PostedBy=${params.PostedBy}`;
       this.service.httpGet('/AttendanceDetails/GetAttendanceDetails?',query)
       .subscribe(data => {
         let result: any;
         result = data;
         if (result.errno === 0){
           if (this.flag=="PAGELOAD")
           {
             this.companies = curUser.dtsiteid;
             this.CID = JSON.parse(sessionStorage.getItem('cid'));
             this.category = result.respdata.Categorylist;
             this.Subcategory = result.respdata.SubCategorylist;
             this.PostedBy=result.respdata.PostedBy;
             this.employeelist = result.respdata.EmployeeList;
             this.empid= _.pluck(this.employeelist, 'Name');

           }
           else
           {
             this.datasource = result.respdata.Main;
             this.datasource1 = result.respdata.LeaveDetails;
             this.stepper.selectedIndex = 1; 
           }
         }
         else{
           this.notificationService.success(result.errdesc); //':: Problem details');
         }
       },
       (error) => {
        //  if (error instanceof BadInput)           
           console.log(error);
        //  else throw error;       
       });
     }

}
