import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';



@Component({
  selector: 'app-ticketreport',
  templateUrl: './ticketreport.component.html',
  styleUrls: ['./ticketreport.component.css']
})
export class TicketreportComponent implements OnInit {

  dataSource: any;
  ITSdataSource: any;
  TicketReportFormGroup: FormGroup;
  Companies: any;
  TotHours:any;
  Subject:String;
  TicketNo:String;
  Project:String;
  Priority:String;
  Createdby:String;
  Requeston:String;
  Status:String;
  RequestType:String;
  CreatedDate:String;
  Description:String;
  Client: String;
  cid:any;
  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,
    public notificationService: NotificationService, private datePipe: DatePipe) 
    {
      this.TicketReportFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] ,                 
        fcTicketNo: ['', Validators.required]
      });
    }

  ngOnInit(): void {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    this.PageLoad();
    this.TicketReportFormGroup.controls.fcCompany.setValue(this.cid);
  }

  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID:this.cid, 
      Flag: "LOADDDL"           
    };
    this.service.httpPost('/ETSTicketReport/GetTicketData',params)
      .subscribe (
        (data) => {
          // console.log('params: ', params);
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = curUser.dtsiteid;;               
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }


  Submit(){
    let params = {     
      CID: this.TicketReportFormGroup.get('fcCompany').value, 
      TicketNo:  this.TicketReportFormGroup.get('fcTicketNo').value,       
      Flag: "LOADGRID"      
    };
    this.service.httpPost('/ETSTicketReport/GetTicketData',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.dataSource = result.respdata.TicketData; 
          this.ITSdataSource = result.respdata.ITSData;      
          
          this.Subject=this.ITSdataSource[0].Subject;
          this.TicketNo=this.ITSdataSource[0].TicketNo; 
          this.Project=this.ITSdataSource[0].Project; 
          this.Priority=this.ITSdataSource[0].priority; 
          this.Createdby=this.ITSdataSource[0].Createdby; 
          this.Requeston=this.ITSdataSource[0].RequestOn; 
          this.Status=this.ITSdataSource[0].Status;
          this.RequestType=this.ITSdataSource[0].RequestType; 
          this.CreatedDate=this.ITSdataSource[0].date; 
          this.Description=this.ITSdataSource[0].Description; 
          this.Client=this.ITSdataSource[0].Client;

          this.TotHours = result.respdata.TotHrs[0].Hours;
          // this.TicketReportFormGroup.controls.TotHours.setValue(this.TotHours[0].Hours);         
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

  changesite(e) {
    this.cid=e.value;
    this.PageLoad();
  }

  onSearchClear(){
    this.TicketReportFormGroup.reset();  
    this.TotHours='00:00';  
    this.Subject='';
    this.TicketNo=''; 
    this.Project=''; 
    this.Priority=''; 
    this.Createdby=''; 
    this.Requeston=''; 
    this.Status='';
    this.RequestType=''; 
    this.CreatedDate=''; 
    this.Description=''; 
    this.Client='';   
    this.dataSource='';
    this.TicketReportFormGroup.controls.fcCompany.setValue(this.cid);
  }

}
