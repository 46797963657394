import { User } from './../../../../Modules/user';
import { MatCardContent } from '@angular/material/card';
import { TrainingacessComponent } from './../trainingacess/trainingacess.component';
// import { DialogService } from '.././../../../shared/dialog.service';
import { Component, OnInit,ViewChild, } from '@angular/core';
import {TrainingComponent} from './../training/training.component';
// import { MatDialog, MatDialogConfig, MatPaginator, MatSort } from '@angular/material';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import * as _ from 'underscore/underscore-min.js';
import { DatePipe, formatDate } from '@angular/common';
// import { MatCardModule } from '@angular/material/MatCardContent';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';


// import { GoalaccessComponent } from './../goalaccess/goalaccess.component';
import {MatDialog,MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import { DomSanitizer } from '@angular/platform-browser';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM/YYYY',
  },
  display: {
    dateInput: 'MMM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-traininglist',
  templateUrl: './traininglist.component.html',
  styleUrls: ['./traininglist.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class TraininglistComponent implements OnInit {

  myFormGroup:FormGroup;
  myFormGroup1:FormGroup;

  companies:any
  datasource:any;
  commentlist:any;
  Reportingemployees:any;
  managerquestions:any;
  selectedOptions:any;
  comment:any
  index:any
  extravisable:Boolean=false
  TID:any
  CurLedgerID:any
  UserName:any
  form_template:any;
  assessmentvisible:boolean=true;
  managervisible:boolean=false;
  assessmentType:string='Employee';
  LedgerID:any
  ProfileImage:any;
  Profilevisible:boolean=false;
  currentuserImage:any;
  ProfileName:any
  fd=new Date()
  animate = false;
  hideme = {};
  date = new FormControl(moment());
  limit:any=0;
  commentvisible:boolean=false;
  selectedValue: string;
  groupvisible:boolean=false;
  commentcount:Number=0;

  showFloatingButtons = false;
  expanded = [];
  CID:any;
  
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns =['TID','TName','Description','EntryDate','Status','Action'];
  constructor(private datePipe: DatePipe, private sanitizer: DomSanitizer,public service: HttpserviceService,public notificationService: NotificationService,private dialog: MatDialog,
    private DialogService: DialogService) {
    this.hideme = {};
   }

  ngOnInit(): void {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.myFormGroup=new FormGroup(
      {}
    );

    this.myFormGroup1=new FormGroup(
      {}
    );
   
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserName=curUser.username;
    this.LedgerID=curUser.ledgerid;
    this.getGoalsdetails();
  }
  onCompanySelect(event)
  {
    this.CID=event;
    this.getGoalsdetails();

  }

  refresh()
  {
    this.getGoalsdetails();
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.getGoalsdetails()
  }

  addRow()
  {
    console.log("popupflag" , "hi")
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: 'ADD',
      TID:0,
      cid:this.CID
     
    }
    const dialogRef = this.dialog.open(TrainingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {  
      this.getGoalsdetails(); 
    });
  }
  onEdit(event, item){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: "Edit",
      TName:item.TName,
      TID:item.TID,
      Description:item.Description,
      EntryDate:item.EntryDate,
      StartDate:item.StartDate,
      EndDate:item.EndDate,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(TrainingComponent, dialogConfig); 
    dialogRef.afterClosed().subscribe(result => {  
      this.getGoalsdetails(); 
    });       
  }
  onDelete(item)
  {
    this.DialogService.openConfirmDialog('Are you sure to delete this training?')
    .afterClosed().subscribe(res => {
      if (res) {
        const params = {
          cid:this.CID,
          flag:'DELETE',
          TID:item.TID,
          LedgerID:this.CurLedgerID,
          FromDate:this.datePipe.transform(this.fd, 'yyy-MM-dd')
        };
        const query = `cid=${params.cid}&flag=${params.flag}&TID=${params.TID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}`;
        this.service.httpGet('/Training/GetTraining?',query)
          .subscribe(
            data => {
              let result: any;
              result = data;
              this.notificationService.warn('Deleted successfully');
              this.getGoalsdetails();
            },
            (error: AppError) =>{
              if (error instanceof BadInput)           
              console.log(error);
            else throw error;
        });
      }
    });

  }
  loadmore()
  {
    if(this.commentlist.length!==this.limit)
    {
      this.limit=this.commentlist.length;
      this.commentvisible=false
    }
  }
  onMore(event, item,rowindex)
  {
    console.log()
    // this.limit=1;
    this.comment=""
    item.Visibility=item.Visibility==false ? true : false;

    Object.keys(this.hideme).forEach(h => {
      this.hideme[h] = false;
     
    });
    
     if(this.TID !==item.TID)
     {

      this.TID=item.TID
      this.extravisable = this.extravisable == false ? true : false;
      this.hideme[item.TID] = true
     } 
     else if (this.TID ==item.TID)
     {
      this.TID=item.TID
      this.extravisable = this.extravisable == false ? true : false;
      this.hideme[item.TID] = this.extravisable
     }
     
       this.Commentdetails(item.TID);

  }
  Commentdetails(value)
  {
    let params = {
      cid:this.CID,
      flag:'TrainingSub',
      TID:value,
      LedgerID:this.CurLedgerID,
      FromDate:this.datePipe.transform(this.fd, 'yyy-MM-dd')
       }
          const query = `cid=${params.cid}&flag=${params.flag}&TID=${params.TID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}`;
          this.service.httpGet('/Training/GetTraining?',query)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
              this.commentlist=result.respdata.CommentList;
              if(this.commentlist.length>=1)
              {
                this.limit=2;
                this.commentvisible=this.commentlist.length >= 3 ? true : false;
              }else{this.commentvisible=false;}
              
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
         
  }
  onReportingEmployee(data)
  {
    this.ProfileImage='data:image/jpeg;base64,'+this.Reportingemployees[data].Image;
    this.ProfileName=this.Reportingemployees[data].Name;
    this.LedgerID=this.Reportingemployees[data].LedgerID;
    this.assessmentType='Manager';
    this.Profilevisible=true;
    this.getGoalsdetails();
  }
  
  openDialog(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      TID:item.TID,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(TrainingacessComponent,dialogConfig);
  }
  Post(item)
  {
     let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;

   let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      cid: this.CID,
      flag: "POST",
      TID:item.TID,
      TName:item.TName,
      Description:this.comment,
      SDate:this.datePipe.transform(item.EntryDate, 'yyy-MM-dd'),
      EDate:this.datePipe.transform(item.EntryDate, 'yyy-MM-dd'),
      EntryDate:this.datePipe.transform(item.EntryDate, 'yyy-MM-dd'),
      Status:"Open",
      UserName: this.CurLedgerID,
      CreateDate:currentdate
    };
 
    this.service.httpPost('/Training/SetTraining',paramsExtra)
    .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
      if (users.errno === 0){
        this.notificationService.success('Submitted successfully');
        this.Commentdetails(item.TID)
        this.comment=""
        this.commentvisible=true
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });

    
  }
  Cancel()
  {
    this.comment=""
  } 
  addItem()
  {
    // console.log("reporting",this.Reportingemployees);
    this.ProfileImage="";
    this.currentuserImage="";
    this.ProfileName="";
    this.Profilevisible=false;
    this.LedgerID=this.CurLedgerID;
    this.assessmentType='Employee';
    this.getGoalsdetails();
  }
  getGoalsdetails()
  {
       let params = {
       cid:this.CID,
       flag:'LOADGRID',
       TID:0,
       LedgerID:this.LedgerID,
       FromDate:this.datePipe.transform(this.date.value.startOf('week').toDate(), 'yyy-MM-dd')
        }
        const query = `cid=${params.cid}&flag=${params.flag}&TID=${params.TID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}`;
        this.service.httpGet('/Training/GetTraining?',query)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

              this.companies= result.respdata.Companylist;
               this.datasource= result.respdata.TrainingList;
               this.Commentdetails(0)

               this.datasource.forEach(e => {
                this.hideme[e.GoalID] = false;
              });
                
          }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }
}
