import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParamsOptions } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { BadInput } from './ErrorHandler/bad-input';
import { NotFoundError } from './ErrorHandler/not-found-error';
import { AppError } from './ErrorHandler/app-error';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpserviceService {
  baseURL:string;
  constructor(private http: HttpClient
    , public plt: Platform) { }

  httpGet(url: string, params: any){
    // if (this.plt.is('ios') || this.plt.is('android'))     {
    //   let objIPAddress={Protocol:'',ServerIP:'',APIPath:'',CompanyID:0};
    //   objIPAddress=JSON.parse(localStorage.getItem("apiObj"));
    //   this.baseURL=objIPAddress.Protocol+'://'+objIPAddress.ServerIP+objIPAddress.APIPath;
    // }        
    // else
        this.baseURL=environment.apiUrl;
    return this.http.get<any>(this.baseURL+url+params).pipe(catchError(this.handleError));     
  }

  httpPost(url: string, params: any){
        //if platform in ios or android   
    // if (this.plt.is('ios') || this.plt.is('android'))     {
    //   let objIPAddress={Protocol:'',ServerIP:'',APIPath:'',CompanyID:0};
    //   objIPAddress=JSON.parse(localStorage.getItem("apiObj"));
    //   this.baseURL=objIPAddress.Protocol+'://'+objIPAddress.ServerIP+objIPAddress.APIPath;
    // }        
    // else
        this.baseURL=environment.apiUrl;
   //else 
   //this.baseURL=this.environment(IPAddress)
    //return this.http.post<any>(this.baseURL+url,params);
    return this.http.post<any>(this.baseURL+url,params);
  }

  httpPost2(url: string, params: any){
      //if platform in ios or android   
  // if (this.plt.is('ios') || this.plt.is('android'))     {
  //   let objIPAddress={Protocol:'',ServerIP:'',APIPath:'',CompanyID:0};
  //   objIPAddress=JSON.parse(localStorage.getItem("apiObj"));
  //   this.baseURL=objIPAddress.Protocol+'://'+objIPAddress.ServerIP+objIPAddress.APIPath;
  // }        
  // else
      this.baseURL=environment.apiUrl;
  //else 
  //this.baseURL=this.environment(IPAddress)
  //return this.http.post<any>(this.baseURL+url,params);
  let headers = new HttpHeaders();  
    headers.append('Content-Type', 'application/json');  
    const httpOptions = {  
        headers: headers  
    };  
  return this.http.post<any>(this.baseURL+url,params,httpOptions).pipe(catchError(this.handleError)); 
  }
  httpPost3(url: string, params: any){
   
    this.baseURL=environment.apiUrl;

  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  return this.http.post(this.baseURL+url, params, {
    headers: headers,
    responseType: 'blob'  // Expecting the report as a PDF blob
  }).pipe(catchError(this.handleError));

//return this.http.post<any>(this.baseURL+url,params,httpOptions).pipe(catchError(this.handleError)); 
}

  
  httpGet2(url: string, params: any){
    // if (this.plt.is('ios') || this.plt.is('android'))     {
    //   let objIPAddress={Protocol:'',ServerIP:'',APIPath:'',CompanyID:0};
    //   objIPAddress=JSON.parse(localStorage.getItem("apiObj"));
    //   this.baseURL=objIPAddress.Protocol+'://'+objIPAddress.ServerIP+'angular_service';
    // }        
    // else
        this.baseURL=environment.apiUrl;

    //return this.http.get<any>(this.baseURL+url+params).pipe(catchError(this.handleError));        
    return this.http.get(this.baseURL+url+params, {  
          responseType: 'blob'  
      }).pipe(catchError(this.handleError));    
    //return this.http.get<any>('assets/SiteMaster.txt',params).pipe(catchError(this.handleError));  
  }

//  private handleError(error: Response){
//     // if (error.status === 400)
//     //     return Observable.throw(new BadInput(error.json()));

//     // if (error.status === 404)
//     // return Observable.throw (new NotFoundError());
    
//     // return Observable.throw(new AppError(error));

//     // const statusCode = error.status;
//     // const body = error.json();
//     // const error = {
//     //   statusCode: statusCode,
//     //   error: body.error
//     // };
//     console.log('error',error.status)
//     return throwError(error);
//   }

private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // Return an observable with a user-facing error message.
  return throwError(
    'Something bad happened; please try again later.');
}

}
