import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit} from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import * as _ from 'underscore/underscore-min.js';
import { FormGroup,  Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Inject } from '@angular/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';



export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

// class PickDateAdapter extends NativeDateAdapter {
//   format(date: Date, displayFormat: Object): string {
//       if (displayFormat === 'input') {
//           return formatDate(date,'dd-MM-yyyy',this.locale);;
//       } else {
//           return date.toDateString();
//       }
//   }
// }

@Component({
  selector: 'app-timesheetview',
  templateUrl: './timesheetview.component.html',
  styleUrls: ['./timesheetview.component.css']
  ,
  providers: [
    // {provide: DateAdapter, useClass: PickDateAdapter},
    // {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
]
})
export class TimesheetviewComponent implements OnInit {

  // flag: string;
  dataSource: any;
  dataSource1: any;
  Categorylist:any;
  Productlist:any;
  Projectlist:any;
  Clientlist:any;
  Companies:any;
  // date= new Date();
   CID: string;
   CurLedgerID:any;
   regex = '([01]?[0-9]|2[0-3]):[0-5][0-9]';
  TimeSheetViewFormGroup: FormGroup;
  date : any;
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder,
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService,
    public dialogRef: MatDialogRef<TimesheetviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
     this.CID=this.data.CID;
     this.CurLedgerID=this.data.LedgerID;
  
    //  console.log('open',this.CurLedgerID);
      this.TimeSheetViewFormGroup = this.formBuilder.group({  
        fcFromDate: ['', Validators.required] ,             
        fcToDate: ['', Validators.required]       
      });      
    }


  ngOnInit(): void {
    // let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    // this.CurLedgerID=curUser.ledgerid;
  }

  Refresh()
  {
    let params = {
      CID: this.CID,
      Flag: "EDITEFFORT",     
      FromDate:this.datePipe.transform(this.TimeSheetViewFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),
      ToDate:this.datePipe.transform(this.TimeSheetViewFormGroup.get('fcToDate').value,"dd-MM-yyyy"),
      LedgerID: this.CurLedgerID
      // LedgerID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid")
    };
    this.service.httpPost('/Timesheet/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {  
            // this.Companies = result.respdata.Companyid;                                       
            this.Categorylist = result.respdata.Category;
            this.Productlist=result.respdata.Product;
            this.Projectlist = result.respdata.Project;            
            this.Clientlist = result.respdata.Client;          
            this.dataSource=result.respdata.Datasource;  
            this.dataSource1=result.respdata.Datasource;                    
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
  Submit()
  {
    let params = {     
      CID: this.CID,
      Flag: "SETEDITEFFORT",           
      FromDate : this.datePipe.transform(this.TimeSheetViewFormGroup.get('fcFromDate').value,"dd-MM-yyyy"), 
      ToDate : this.datePipe.transform(this.TimeSheetViewFormGroup.get('fcToDate').value,"dd-MM-yyyy"),    
      LedgerID:  this.CurLedgerID,  
      // LedgerID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid") 
      EditListarray: this.dataSource1          
    };
    this.service.httpPost('/Timesheet/SetTimesheetEffort',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
  onClear()
  {
    this.TimeSheetViewFormGroup.reset();  
    this.dataSource='';
  }

  onCloseDialog()
  {
    this.TimeSheetViewFormGroup.reset();
    this.dialogRef.close();
  }

  logEvent(eventName, e)
  {
  }

}
