import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogformainform',
  templateUrl: './dialogformainform.component.html',
  styleUrls: ['./dialogformainform.component.css']
})
export class DialogformainformComponent {
  LayoutName:any='';
  flag:any='ADD';
  setDefault:boolean=false;
  lablname:any;

  constructor(public dialogRef: MatDialogRef<DialogformainformComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      //this.LayoutName = this.data.layoutname;
      this.lablname=this.data.lablname;
    }
    // event(value)
    // {
    //   this.flag=value
    //   this.LayoutName = this.flag=='ADD'? '': this.data.layoutname;  
    // }
    Submit()
    {
      this.dialogRef.close({event: this.flag,LayoutName:this.LayoutName,setDefault:this.setDefault});
    }

  onCloseDialog()
  {
    this.dialogRef.close({event:'CLEAR',LayoutName:this.LayoutName,setDefault:this.setDefault});
  }

}
