import { HttpserviceService } from './../../Services/httpServices/httpservice.service';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  EventEmitter,
  Output
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import {  Menu } from '../../LandingPageComponents/menu-items';
import { User } from '../../Modules/user';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  @Output() menuSelect = new EventEmitter<any>();

  mobileQuery: MediaQueryList;
  CurLedgerID:string;
  ledgerid:any;
  userid:any;
  private _mobileQueryListener: () => void;
  navItems: Menu[];
  // baseUrl = `${environment.apiUrl}/usermgt.asmx`;
  // baseUrl = `${environment.apiUrl}`;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private http: HttpserviceService,
    // public menuItems: MenuItems
  ) {
    // this.navItems = menuItems.getMenuitem();
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.username;
    this.ledgerid=curUser.ledgerid;
    this.userid=curUser.userid;
   

    this.getMenuitem();
    // console.log('navitems', this.navItems);
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

   
  }

  getMenuitem(){
  
    let result;
    const groupid = JSON.parse(sessionStorage.getItem('groupid'));
    const query = `cid=${JSON.parse(sessionStorage.getItem('cid'))}&uniqid=${'1'}&groupid=${groupid}&flag=${'MENUGROUPING'}&userid=${this.userid}&apptype=${'3'}`;
    this.http.httpGet('/usermgt/buildsidemenu?' , query)
    .subscribe(
      data => {
        result = data as any;
        result = data;
        removeEmptyProperties(result);
        this.navItems =  result.respdata.objmenulist;
        sessionStorage.setItem('menulist',JSON.stringify(this.navItems))
         //console.log('navitems : ', JSON.stringify(this.navItems));
      },
      error => {
        console.log(error);
      });
  }

  ClickEvent(){
    this.menuSelect.emit('item');
  }

  selected(item:any)
  {
  
    //console.log('sidebar ', JSON.stringify(item));
    this.menuSelect.emit(item);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}

function removeEmptyProperties(obj) {

  Object.keys(obj).forEach(function(key) {

      if (obj[key] && typeof obj[key] === 'object') removeEmptyProperties(obj[key]); //recursive for objects
      else if (obj[key] == null || obj[key] == '') delete obj[key];         //remove empty properties
        if (typeof obj[key] === 'object' && Object.keys(obj[key]).length == 0) delete obj[key]; //remove empty objects

    });
}



// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-sidebar',
//   templateUrl: './sidebar.component.html',
//   styleUrls: ['./sidebar.component.scss'],
// })
// export class SidebarComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {}

// }
