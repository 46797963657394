import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore';
import { DatePipe } from '@angular/common';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';



@Component({
  selector: 'app-advancerequestview',
  templateUrl: './advancerequestview.component.html',
  styleUrls: ['./advancerequestview.component.css']
})


export class AdvancerequestviewComponent implements OnInit {
  ApprovedBy:any;
  ApprovedDate:any;
  Flag:any;
  showSpinner:any;
  AdvanceRequestViewFormGroup: FormGroup;
  Companies:any;
  Employees: any;
  Types:any;
  Currency:any;
  selectedReqID: any;
  CurLedgerID:any;
  RequestID:any;
  Monthydedamt:number;
  myControl = new FormControl('', [Validators.required]);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  employeeid:string[]=[];
  selectedEmpID: any;
  advid:string[]=[];
  cid:any;
  visible:boolean=false;
  savebtnvisible:boolean=false;
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder, private datePipe: DatePipe,
    private dialog: MatDialog,private dialogService: DialogService,public dialogRef: MatDialogRef<AdvancerequestviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      if(this.data.Flag ==='VIEW' )      
        this.savebtnvisible=false;
      else
         this.savebtnvisible=true;

      this.Flag=this.data.Flag;

      this.cid = this.data.cid;   
      this.Employees = this.data.Employees;                           
      this.Types = this.data.Type;
      this.Currency=this.data.Currency;
      this.selectedReqID=this.data.RequestID; 

      this.employeeid= _.pluck(this.Employees, 'Name'); 

      this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); 

    
      this.AdvanceRequestViewFormGroup = this.formBuilder.group({  
        fcCompany: [''],     
       // fcReqID: ['', Validators.required],             
        fcType: ['', Validators.required],
        fcDescription: ['', Validators.required],
        fcAmtRequest: ['', Validators.required],
        fcCurrency: ['', Validators.required],
        myControl: [''],          
        fcApprovedAmt: [''],   
        fcFromDate: [''], 
        fcMaxMonth: [''], 
        fcMonthlyDeductAmt: [''],    
      });       
    }

  ngOnInit(): void {
    // this.cid=JSON.parse(sessionStorage.getItem('cid'));   
    if(this.Flag !=='ADD')
    {
      this.visible=true;
      this.onReqIDChange();
    }
    
    this.AdvanceRequestViewFormGroup.controls.fcCompany.setValue(this.cid);
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.username;

   
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  selectedemployee(event) {        
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;   
    // console.log('selectedEmpID Split', this.selectedEmpID.slice(0, str.indexOf('-')));
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
    // console.log('selectedEmpID', this.selectedEmpID);    
  }

  onReqIDChange() {
    // console.log('event change',event.option.value );

    // this.selectedReqID = event.option.value;
    let params = {
      CID: this.cid,
      Flag: "EDITDATA",
      Prefix:this.selectedReqID
    };
    this.service.httpPost('/AdvanceRequest/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);

          if (result.errno === 0) {                           
            this.RequestID=result.respdata.Editdata;
           
            // console.log('RequestID ', this.RequestID);
            // console.log('DeductionMonthCount ', this.RequestID[0].DeductionMonthCount);
            // console.log('MonthlyDeduct ', this.RequestID[0].MonthlyDeduct);
            this.selectedEmpID=this.RequestID[0].Name;
            this.myControl.setValue(this.RequestID[0].Name);
            this.AdvanceRequestViewFormGroup.controls.fcType.setValue(this.RequestID[0].Type);
            this.AdvanceRequestViewFormGroup.controls.fcDescription.setValue(this.RequestID[0].Description);
            this.AdvanceRequestViewFormGroup.controls.fcAmtRequest.setValue(this.RequestID[0].AmountRequested);
            this.AdvanceRequestViewFormGroup.controls.fcCurrency.setValue(this.RequestID[0].Currency);
            this.AdvanceRequestViewFormGroup.controls.fcApprovedAmt.setValue(this.RequestID[0].ApprovedAmount);
            this.AdvanceRequestViewFormGroup.controls.fcFromDate.setValue(this.RequestID[0].StartDate);
            this.AdvanceRequestViewFormGroup.controls.fcMaxMonth.setValue(this.RequestID[0].DeductionMonthCount);
            this.AdvanceRequestViewFormGroup.controls.fcMonthlyDeductAmt.setValue(this.RequestID[0].MonthlyDeduct);
            this.ApprovedBy=this.RequestID[0].ApprovedBy;
            this.ApprovedDate=this.RequestID[0].ApprovedDate;
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  Submit() {   
    if (this.AdvanceRequestViewFormGroup.invalid) {
      this.NotificationService.warn('Please fill the mandatory fields');     
      return;
      }  
    this.showSpinner=true;
    let sdate =Date()
    let params; 
    if(this.Flag=='ADD')
    {
      params = { 
        CID:this.cid,
        Flag: "ADD",           
        LedgerID:this.selectedEmpID,    
        Type:  this.AdvanceRequestViewFormGroup.get('fcType').value,   
        Description: this.AdvanceRequestViewFormGroup.get('fcDescription').value,
        AdvAmount: this.AdvanceRequestViewFormGroup.get('fcAmtRequest').value,
        Currency: this.AdvanceRequestViewFormGroup.get('fcCurrency').value,
        UserID:this.CurLedgerID
       }
  }
  else
  {
    params = {     
      CID:this.cid,
      Flag:this.Flag,           
      Prefix:this.selectedReqID,
      LedgerID:this.selectedEmpID,  
      Type:  this.AdvanceRequestViewFormGroup.get('fcType').value,   
      Description: this.AdvanceRequestViewFormGroup.get('fcDescription').value,
      AdvAmount: this.AdvanceRequestViewFormGroup.get('fcAmtRequest').value,
      Currency: this.AdvanceRequestViewFormGroup.get('fcCurrency').value,
      UserID:this.CurLedgerID,
      ApprovedAmt:this.AdvanceRequestViewFormGroup.get('fcApprovedAmt').value,
      MonthlyDeduct:this.AdvanceRequestViewFormGroup.get('fcMonthlyDeductAmt').value,
      DeductionMonthCount:this.AdvanceRequestViewFormGroup.get('fcMaxMonth').value,
      StartDate:this.datePipe.transform(this.AdvanceRequestViewFormGroup.get('fcMaxMonth').value,"dd-MM-yyyy"),
    }
  };
    this.service.httpPost('/AdvanceRequest/SetAdvanceRequest',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;   
        this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)    
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          this.onCloseDialog();
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
          this.onCloseDialog();
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });

  }
  onClear()
  {
    this.AdvanceRequestViewFormGroup.reset();  
    this.myControl.setValue('');
    this.AdvanceRequestViewFormGroup.controls.fcCompany.setValue(this.cid);
  }

  onCloseDialog()
  {
    // this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    // .afterClosed().subscribe(res => {
    //   if (res) {
          this.AdvanceRequestViewFormGroup.reset();
          this.dialogRef.close();
      //    }
      // });
  }


  onChangeEvent(event: any){
  //  console.log('month',event.target.value);
     this.Monthydedamt=this.AdvanceRequestViewFormGroup.get('fcApprovedAmt').value / event.target.value;
  //   console.log('monthlydedamkjfht',this.Monthydedamt);       
     this.AdvanceRequestViewFormGroup.controls.fcMonthlyDeductAmt.setValue(this.Monthydedamt);
  }
  
}
