import { User } from './../../../../Modules/user';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-currentyearcarryforward',
  templateUrl: './currentyearcarryforward.component.html',
  styleUrls: ['./currentyearcarryforward.component.scss'],
})
export class CurrentyearcarryforwardComponent implements OnInit {
  CID:any;
  Flag:any;
  GroupID:any;
  Year:any
  dataSource:any;
  Years:any;
  Annualleavepolicy:any;
  CurLedgerID:any;
  LeaveTypeID:any;
  LeaveType:any;
  
  constructor(private service:HttpserviceService,private NotificationService:NotificationService
    ,public dialogRef: MatDialogRef<CurrentyearcarryforwardComponent>,@Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    this.CID=this.data.CID;
    
  }

  ngOnInit() 
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;

    this.Flag='LOADGRADE';
    this.getddllist();
    this.Year=2021;
    this.GroupID=0;
    //this.LeaveTypeID=106;
  }
  refresh()
  {
    this.Flag='LOADGRID';
    
    
    this.getddllist();
  }
  getddllist()
  {
    let params = {
      CID: this.CID,
      Flag: this.Flag,
      GroupID:this.GroupID ,
      Year: this.Year,
      LeaveTypeID:this.LeaveTypeID       
    };
    this.service.httpPost('/HRPolicies/GetCurrentYrCarryForward',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;         
          console.log('data ', data);
          if (result.errno === 0) { 
                 
            if(this.Flag=='LOADGRADE')
            {
              this.Annualleavepolicy = result.respdata.Annualleavepolicy;                          
              this.Years=result.respdata.Year;
              this.LeaveType=result.respdata.LeaveType;
            }
            else
            {
              this.dataSource=result.respdata.DataSource; 
            }
           
                                        
                              
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
  Submit()
  {

  }
  onClear()
  {

  }
  onCloseDialog()
  {
    this.dialogRef.close();
  }
  logEvent(_flag, e)
  {
    console.log("logEvent",e.data.Carryforward)


    let slno = 0;
    let  paramsExtra;
    slno = e.data.SlNo;
    // const time = (this.datePipe.transform( e.data.Date, 'dd-MM-yyyy') + ' ' + e.data.Time);

  if (_flag == 'RowUpdated')
   {
    paramsExtra = {
      CID: this.CID,
      Flag: 'EDIT',
      Year:this.Year,
      HalfDay: e.data.LedgerID,
      WorkingHrs: e.data.Carryforward,
      UserID: this.CurLedgerID,
      LeaveTypeID:this.LeaveTypeID,       
      GroupID:sessionStorage.getItem('groupid')

    };
   }
  else if (_flag == 'RowRemoved')
  {

    paramsExtra = {
      CID: this.CID,
      Flag: 'DELETE',
      Year:this.Year,
      HalfDay: e.data.LedgerID,
      WorkingHrs: e.data.Carryforward,
      UserID: this.CurLedgerID,
      LeaveTypeID:this.LeaveTypeID,    
      GroupID:sessionStorage.getItem('groupid')

    };
  }
  this.service.httpPost('/HRPolicies/SetCurrentYrCarryForward',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
    
      if (users.errno === 0){
        this.NotificationService.success(users.errdesc);

      }
      else{
        this.NotificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }
  

}
