import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { EospostingComponent } from '../eosposting/eosposting.component';
import { PayslippaymentfilterComponent } from '../../EmpPayslipPayment/payslippaymentfilter/payslippaymentfilter.component';

@Component({
  selector: 'app-eosreport',
  templateUrl: './eosreport.component.html',
  styleUrls: ['./eosreport.component.scss'],
})
export class EosreportComponent implements OnInit {

  showSpinner:any;
  curUser: User= JSON.parse(sessionStorage.getItem('currentUser'));
  cid:any=JSON.parse(sessionStorage.getItem('cid'));
  fromdate:any=new Date() ;
  todate:any= new Date();
  dataSource:any;
  companies:any;
  Employeelist:any;
  Categorylist:any;
  Typelist:any;
  Prefixlist:any;
  AccountSource: any;
  Ledgerlist:any;
  data:any;
  MenuID:any;

  sumofearings:number=0;
  sumofdeduction:number=0;
  sumofnetamount:number=0;
  sumofpaidamount:number=0;
  DateTypeFlag:any='Today';

  displayedColumns =['PSRef','PSMonth','Earnings','Deductions','NetAmount','PaidAmount','Status','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service:HttpserviceService,private datePipe: DatePipe,public dialog: MatDialog,private NotificationService: NotificationService) 
  {
    this.MenuID=sessionStorage.getItem('SelectedMenuID')
    this.fromdate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
    this.todate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;

   }

  ngOnInit() 
  {
    this.LoadData();
  }
  Filter()
   {
    const dialogRef = this.dialog.open(PayslippaymentfilterComponent, {
      width: '25%', // Set the width of the dialog
      height:'85%',
      position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        LedgerID:0,
        EmpName:'',
       // Category:this.Category,     
        Type:'',
        DateTypeFlag:this.DateTypeFlag,
        fromdate:this.fromdate,
        todate:this.todate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
        // this.qtnstatus=result.Status
        this.cid=result.CID
        // this.EmpID=result.LedgerID
        // this.EmpName=result.Name
        //this.Category=result.Category
        // this.PSType=result.Type
        this.DateTypeFlag=result.DateTypeFlag
        this.fromdate=result.FromDate
      // const  dateObj= new Date(result.ToDate); 
        this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
        this.LoadData()
      }
     
    });
   }
  LoadData()
  {

    this.showSpinner=true;
    const params = {
      cid: this.cid,
      MenuID:this.MenuID,
      Date1:this.DateTypeFlag,
      FromDate:this.datePipe.transform(this.fromdate,"MM-dd-yyyy")  ,
      ToDate:this.datePipe.transform( this.todate,"MM-dd-yyyy")
    };   

    
    const query = `cid=${params.cid}&MenuID=${params.MenuID}&Date1=${params.Date1}&FromDate=${params.FromDate}&ToDate=${params.ToDate}`;


    this.service.httpGet('/Payslip/GetEmppaySlipmain?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("mm",result.respdata)
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
     
        this.data=result.respdata;
        this.dataSource= new MatTableDataSource(result.respdata.PayLedger);  
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        
        this.Employeelist= result.respdata.Employee; 
        this.Categorylist= result.respdata.Category; 
        this.Typelist= result.respdata.Type; 
        this.Prefixlist= result.respdata.Prefix; 

        // this.dataSource1 = result.respdata.Table; 
        // this.columnNames=result.respdata.columnNames;
        // console.log("columnNames",this.columnNames)
       // this.dataSource1 = this.getCurrentPageData();
        this.Ledgerlist= result.respdata.Ledgerlist; 
        this.AccountSource= result.respdata.AccountGrid; 

        console.log("Prefixlist",this.Prefixlist)

        //this.pr=this.Prefixlist[0].Prefix;


        this.sumofearings = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.Earnings}, 0);
        this.sumofdeduction = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.Deductions}, 0);
        this.sumofnetamount = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.NetAmount}, 0);
        this.sumofpaidamount = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.PaidAmount}, 0);
    
        this.sumofearings = +this.sumofearings.toFixed(2);
        this.sumofdeduction = +this.sumofdeduction.toFixed(2);
        this.sumofnetamount = +this.sumofnetamount.toFixed(2);
        this.sumofpaidamount = +this.sumofpaidamount.toFixed(2);
       
    });
  }
  add()
  {

    const dialogRef = this.dialog.open(EospostingComponent, {
      width: '90%', // Set the width of the dialog
      height:'85%',
      //position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        VouNo:'',
        flag:'ADD',
        datalist:this.data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
      //   // this.qtnstatus=result.Status
      //   this.cid=result.CID
      //   this.EmpID=result.LedgerID
      //   this.EmpName=result.Name
      //   this.Category=result.Category
      //   this.PSType=result.Type
      //   this.DateTypeFlag=result.DateTypeFlag
      //   this.fromdate=result.FromDate
      // // const  dateObj= new Date(result.ToDate); 
      //   this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
      //   this.LoadData()
      }
     
    });

  }

  edit(row)
  {

    const dialogRef = this.dialog.open(EospostingComponent, {
      width: '90%', // Set the width of the dialog
      height:'85%',
      //position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        VouNo:row.PSRef,
        flag:'EDIT',
        datalist:this.data
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
      //   // this.qtnstatus=result.Status
      //   this.cid=result.CID
      //   this.EmpID=result.LedgerID
      //   this.EmpName=result.Name
      //   this.Category=result.Category
      //   this.PSType=result.Type
      //   this.DateTypeFlag=result.DateTypeFlag
      //   this.fromdate=result.FromDate
      // // const  dateObj= new Date(result.ToDate); 
      //   this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
      //   this.LoadData()
      }
     
    });

  }

}
