import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatError, MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButton, MatButtonModule } from '@angular/material/button';


@Component({
  selector: 'app-link-leave-type-popup',
  templateUrl: './link-leave-type-popup.component.html',
  styleUrls: ['./link-leave-type-popup.component.css']
})

export class LinkLeaveTypePopupComponent implements OnInit {
  leaveGroupData: any[] = [];
  formGroup!: FormGroup;
  leaveTypeFilter = new FormControl<string>('');
  leaveTypeSelectValues: { value: string }[] = [];
  leaveTypeSelect: FormControl<string[]>;

  constructor(
    public dialogRef: MatDialogRef<LinkLeaveTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpService: HttpserviceService,
    private formBuilder: FormBuilder
  ) {
    this.leaveTypeSelect = new FormControl([]);
  }

  
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      leaveGroupName: new FormControl(this.data.LeaveGroupName, Validators.required),
      leaveTypeSelect: this.leaveTypeSelect,
    });

    if (Array.isArray(this.data.LeaveDisplayNames)) {
      this.leaveTypeSelectValues = this.data.LeaveDisplayNames.map((name: string) => ({ value: name }));
      this.leaveTypeSelect.setValue(this.leaveTypeSelectValues.map(item => item.value));
    } else {
      console.warn('LeaveDisplayNames is not defined or is not an array:', this.data.LeaveDisplayNames);
      this.leaveTypeSelectValues = []; 
    }
  

    this.httpService.httpGet(`/LeaveGroup/GetLeaveTypes?CID=${this.data.CID}`, '').subscribe(response => {
      if (response && response.respdata) {
        this.leaveGroupData = response.respdata.map(leaveType => ({
          LeaveTypeID: leaveType.LeaveTypeID,
          LeaveDisplayName: `${leaveType.LeaveTypeID} - ${leaveType.LeaveName}`
        }));
        console.log('Available Leave Types:', this.leaveGroupData); 
      } else {
        console.error('No leave group data found in response');
      }
    });
  
    console.log('Existing Leave Types:', this.leaveTypeSelectValues); 
  }

  remove(leaveType: string) {
    const index = this.leaveTypeSelectValues.findIndex(item => item.value === leaveType);
    if (index >= 0) {
      this.leaveTypeSelectValues.splice(index, 1);
      this.leaveTypeSelect.setValue(this.leaveTypeSelectValues.map(item => item.value));
    }
  }

  toggleSelection(leaveTypeDisplayName: string) {
    const index = this.leaveTypeSelectValues.findIndex(item => item.value === leaveTypeDisplayName);

    if (index >= 0) {
      this.leaveTypeSelectValues.splice(index, 1);
    } else {
      this.leaveTypeSelectValues.push({ value: leaveTypeDisplayName });
    }
    this.leaveTypeSelect.setValue(this.leaveTypeSelectValues.map(item => item.value));
    this.leaveTypeFilter.setValue('');
  }

  isSelected(leaveTypeDisplayName: string): boolean {
    return this.leaveTypeSelectValues.some(item => item.value === leaveTypeDisplayName);
  }

  filteredLeaveTypes() {
    const filterValue = this.leaveTypeFilter.value?.toLowerCase() || '';
    return this.leaveGroupData.filter(leaveType =>
      leaveType.LeaveDisplayName.toLowerCase().includes(filterValue)
    );
  }

  

  UpdateLeaveTypes() {

    if(this.formGroup.valid){

      if (this.leaveTypeSelect.value && this.leaveTypeSelect.value.length > 0) {
      const leaveTypeIds = this.leaveTypeSelectValues.map(leaveType => parseInt(leaveType.value.split(' - ')[0]));
      const leaveTypeIdsString = JSON.stringify(leaveTypeIds);


      const data = {
        Flag: 3, 
        CID: this.data.CID,
        LeaveGroupID: this.data.LeaveGroupID,
        LeaveGroupName: this.formGroup.get('leaveGroupName')?.value,
        LeaveTypeIDs: leaveTypeIdsString,
      };

      console.log('Data to be sent:', data);
      this.httpService.httpPost('/LeaveGroup/SetLeaveGroupConfiguration', data).subscribe(
        response => {
          console.log('Update successful:', response);
          this.onClosePopup(); 
        },
        error => {
          console.error('Error updating leave group configuration:', error);
        }
      );
    } else {
      console.log('Form is invalid');
      this.leaveTypeSelect.setErrors({ required: true }); 
      this.leaveTypeSelect.markAsTouched();
    }
  }else {
    console.log('Form is invalid');
    this.formGroup.markAllAsTouched();
      this.leaveTypeSelect.markAsTouched();
  }
  }

  onClosePopup() {
    this.dialogRef.close();
    this.dialogRef.close(this.leaveTypeSelectValues.map((leaveType) => leaveType.value)); // Pass the selected leave types as an argument
  }
}

