// import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { User } from './../../../../Modules/user';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as _ from 'underscore/underscore-min.js';
import { MatChipInputEvent } from '@angular/material/chips';


interface Category {
  id: string;
  name: string;
}
interface selectedemployee {
  Flag: string;
  LedgerID: string;
}
@Component({
  selector: 'app-emailconfiguration',
  templateUrl: './emailconfiguration.component.html',
  styleUrls: ['./emailconfiguration.component.scss'],
})
export class EmailconfigurationComponent implements OnInit {

  CID:any;
  flag:any;
  ID:any="0";
  categorytype:any='All';
  companies:any;
  employeelist:any;
  datasource:any;
  labelName:any='All';
  categoryvisible:boolean=true;
  listvisible:boolean=false;
  btnaddvisible:boolean=true;
  btndeletevisible:boolean=true;
  btnsavevisible:boolean=false;
  CurLedgerID:any;
  selectedemployee:selectedemployee[];
  selecttabindex:any=0;

  Categorylist: Category[] = [
    {id: 'All', name: 'All'},
    {id: 'Ct', name: 'Client'},
    {id: 'Pt', name: 'Product'},
    {id: 'Prj', name: 'Project'},
    {id: 'Br', name: 'Branch'}
    
  ];

  employeelistselected: any = [];
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  fruitCtrl1 = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: any = [];
  temp:any = [];
  autocompleteTagsOptionActivated = false;

  selectable1 = true;
  removable1 = true;
  addOnBlur1 = false;
  separatorKeysCodes1: number[] = [ENTER, COMMA];
  
  @ViewChild('fruitInput') fruitInput: ElementRef;
  @ViewChild('fruitInput1') fruitInput1: ElementRef;
  @ViewChild('autoInput', { read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;
  constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,
    private NotificationService:NotificationService)
     { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
       this.flag='PAGELOAD'
       this.Loaddetails();
       
  }
  onCompanySelect(event)
  {
    this.CID=event;
    this.flag='PAGELOAD'
    this.Loaddetails();

  }
  onCategorySelect(event)
  {
    this.listvisible=false;
    if(event!=='All')
    {
      this.listvisible=true;
      this.CID=this.CID;
    this.flag='Category';
    this.categorytype=event;
    this.Loaddetails();
     let temp=_.where(this.Categorylist, {id: this.categorytype});
     this.labelName=temp[0].name;
    }
  }
  ondataSelect()
  {
    this.CID=this.CID;
    this.flag='FilterData';
    this.Loaddetails();
  }
  Edit()
  {

    this.categoryvisible=false;
    this.btnaddvisible=false;
    this.btndeletevisible=false;
    this.btnsavevisible=true;
  }
  Delete()
  {
    this.btnaddvisible=false;
    this.btndeletevisible=false;
    this.btnsavevisible=true;
  }
  Clear()
  {
    this.categoryvisible=true;
    this.btnaddvisible=true;
    this.btndeletevisible=true;
    this.btnsavevisible=false;
    this.listvisible=false;
    // this.fruitCtrl.reset();
    this.employeelistselected=[];
    this.ID="0";
    this.categorytype="All";

  }
  tabChanged(event)
  {

    if(event.index==0)
    {
      this.Categorylist = [
        {id: 'All', name: 'All'},
        {id: 'Ct', name: 'Client'},
        {id: 'Pt', name: 'Product'},
        {id: 'Prj', name: 'Project'},
        {id: 'Br', name: 'Branch'}
        
      ];
    }
    else
    {
      this.Categorylist = [
        {id: 'All', name: 'All'},
        {id: 'Ct', name: 'Client'},
        {id: 'Pt', name: 'Product'},
        {id: 'Prj', name: 'Project'}
      
      ]; 
    }
    this.categoryvisible=true;
    this.listvisible=false;
    this.btnaddvisible=true;
    this.btndeletevisible=true;
    this.btnsavevisible=false;

    // this.fruitCtrl.reset();
    this.employeelistselected = [];
    this.ID="0";
    this.categorytype="All";

    this.selecttabindex=event.index;
    this.flag="PAGELOAD"
    this.Loaddetails();
   
    // console.log("selecttabindex",event.index);
  }
  Loaddetails()
     {
       let servicepath;
       let params = {
         cid:this.CID,
         flag:this.flag,
         CategoryType:this.categorytype,
         CategoryID:this.ID
          }
            //  const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
            if( this.selecttabindex==0)
            {
              servicepath='/EmailConfig/GetEmailConfig'
            }
            else
            {
              servicepath='/CommonIssueConfig/GetCommonIssueConfig'
            }
             this.service.httpPost(servicepath,params)
             .subscribe(data => {
               let result: any;
               result=data;
               if (result.errno === 0){
                
                 if(this.flag=='PAGELOAD')
                 {
                   this.companies=result.respdata.Companylist;
                   this.employeelist=result.respdata.Employeelist;

                  //  if (this.selecttabindex=0)
                   this.fruitCtrl.valueChanges.subscribe(search => {
                    this.filteredFruits = of(this.employeelist.filter(item =>
                    item.name.toLowerCase().includes(search)
                    ));
                    });

                    this.fruitCtrl1.valueChanges.subscribe(search => {
                      this.filteredFruits = of(this.employeelist.filter(item =>
                      item.name.toLowerCase().includes(search)
                      ));
                      });
                 }
                 else if(this.flag=='Category')
                 {
                   this.datasource=result.respdata.List;
                 }
                 else
                 {
                   let filterdata
                   this.employeelistselected=result.respdata.List;
                 }
                   
                   
               }
               else{
                 this.notificationService.success(result.errdesc); //':: Problem details');
               }
             }, 
             (error: AppError) => {
               if (error instanceof BadInput)           
                 console.log(error);
               else throw error;       
             });
     }
     Submit()
     {
      
       let servicepath;
       let temp =this.employeelistselected;
       this.selectedemployee=[];
      //  console.log("trr",temp);
      for (var i = 0; i < temp.length; i++) 
      {
        this.selectedemployee.push({Flag:'ADD',LedgerID:temp[i].LedgerID}) 
      }

      if(this.selectedemployee.length>0)
      {
        let params = { 
          CID:this.CID,
          Flag: 'ADD', 
          CategoryType: this.categorytype, 
          CategoryID:this.ID,   
          dt:this.selectedemployee,
          UserID: this.CurLedgerID,
          GroupID:sessionStorage.getItem('groupid')
          
         };
         if( this.selecttabindex==0)
         {
           servicepath='/EmailConfig/SetEmailConfig'
         }
         else
         {
           servicepath='/CommonIssueConfig/SetCommonIssueConfig'
         }
        this.service.httpPost(servicepath,params)
        .subscribe(
          (data) => {
            let result: any;
            result = data;       
            if (result.errno === 0) {
              this.NotificationService.success(result.errdesc);
              this.btnsavevisible=false;
            }
            else {
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)         
              console.log(error);
            else throw error;       
          });
      }
      

     }

     add(event: MatChipInputEvent): void {
      // debugger
      const input = event.input;
      const value = event.value;
      // Add our fruit
      if ((value || '').trim()) {
        this.employeelistselected.push({
          id:Math.random(),
          name:value.trim()
        });
  
        // this.employeelistselected = options.map(o => o.value);
      
      }
  
      // Reset the input value
      if (input) {
        input.value = '';
      }
  
      this.fruitCtrl.setValue(null);
    }
  
    remove(fruit, indx): void {
      this.employeelistselected.splice(indx, 1);
    }
  
    selected(event: MatAutocompleteSelectedEvent): void {
      this.employeelistselected.push(event.option.value);
      this.fruitInput.nativeElement.value = '';
      this.fruitCtrl.setValue(null);
      
    }
    selected1(event: MatAutocompleteSelectedEvent): void {
      this.employeelistselected.push(event.option.value);
      this.fruitInput1.nativeElement.value = '';
      this.fruitCtrl1.setValue(null);
      
    }

}
