import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe  } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as _ from 'underscore';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { LicensecreatesubComponent } from '../licensecreatesub/licensecreatesub.component';

@Component({
  selector: 'app-licensecreatemain',
  templateUrl: './licensecreatemain.component.html',
  styleUrls: ['./licensecreatemain.component.css']
})
export class LicensecreatemainComponent {


  dataSource:any;
  isSlNoVisible:boolean=false;
  displayedColumns: string[] = [ 'CompanyName', 'UserName','CreatedDate','ExpiryDate','Comment','Action'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private generic: GenericService,private formBuilder: FormBuilder, private service1: UserService, 
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService) 
    { 
     
    }

    ngOnInit(): void {
     this.getddllist();
    // this.getUserAgent();
    }

    getUserAgent() {
      const userAgent = navigator.userAgent;
      console.log('User-Agent:', userAgent);
    }

    getddllist()
    {
      let params = {  
        CID:101, 
        Flag: "LOADGRID"
      };
      this.service.httpPost('/LicenseCreate/Getdropdownvalues',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;            
            if (result.errno === 0) {                  
              this.dataSource = result.respdata.Griddata;  
              this.dataSource =  new MatTableDataSource(this.dataSource); 
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator; 
             
            }
            else {
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }
    
    Submit()
    {
      this.getddllist();
    }

    doFilter(filterValue: string) {
      // console.log(filterValue);
      this.dataSource.filter = filterValue;
    }

    exportAsExcel()
    {
      this.generic.excelExport(this.dataSource.data, 'LicenseList', 'LicenseList' );
    }  

  add()
    {
      const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {                 
            Flag:'ADD' ,        
          }
          const dialogRef = this.dialog.open(LicensecreatesubComponent, dialogConfig)
          dialogRef.afterClosed().subscribe(result => {     
            this.getddllist();     
          });

    }

    Edit(SlNo: string) {      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {              
        Flag:'EDIT' ,        
        Slno:SlNo     
      }
      const dialogRef = this.dialog.open(LicensecreatesubComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(result => {     
        this.getddllist();     
        });
    }

    View(SlNo: string) {      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {              
        Flag:'VIEW' ,        
        Slno:SlNo     
      }
      const dialogRef = this.dialog.open(LicensecreatesubComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(result => {     
        this.getddllist();     
        });
    }

    downloadTextFile(filename: string, text: string) {
      // Create a Blob with the text content and the correct MIME type (text/plain)
      const blob = new Blob([text], { type: 'text/plain' });
  
      // Create a link element
      const link = document.createElement('a');
  
      // Create an object URL from the Blob
      const url = window.URL.createObjectURL(blob);
  
      // Set the href of the link to the object URL
      link.href = url;
  
      // Set the download attribute to the desired file name
      link.download = filename;
  
      // Append the link to the body (it's hidden, but this is required for the click to work in some browsers)
      document.body.appendChild(link);
  
      // Programmatically click the link to trigger the download
      link.click();
  
      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
  
      // Revoke the object URL after download (to free memory)
      window.URL.revokeObjectURL(url);
  }

    
    Download(SlNo: string,CompanyName: string) { 
      let params = {  
        CID:101, 
        Flag:"DOWNLOADLIC",
        Slno:SlNo
      };
      this.service.httpPost('/LicenseCreate/Getdropdownvalues',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;            
            if (result.errno === 0) {       
              this.downloadTextFile(CompanyName +'_LIC.txt', result.respdata);                        
              this.NotificationService.success('License downloaded successfully');
            }
            else {
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }
    
 
}
