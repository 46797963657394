import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/Forms';
import { Observable } from 'rxjs';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';



@Injectable({
  providedIn: 'root'
})
export class DocumentcontrolValidate {
  date= new Date();
  constructor(private http: HttpserviceService) { }

  form: FormGroup = new FormGroup({
    postingdate: new FormControl('', Validators.required),
    TaskCategory: new FormControl('', Validators.required),
    desc1: new FormControl(''),
    desc2: new FormControl(''),
    desc3: new FormControl(''),
    desc4: new FormControl(''),
    comment: new FormControl(''),
    startdate: new FormControl(''),
    enddate: new FormControl(''),
    status: new FormControl(''),
    popupdays: new FormControl(''),
    notifyflag: new FormControl('')

  });

  
  initializeFormGroup(){
    this.form.setValue({
      postingdate: this.date,
      TaskCategory: '',
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    comment: '',
    startdate: this.date,
    enddate:this.date,
    status: 'Open',
    popupdays: '0',
    notifyflag: ''
    });
  }
  populateForm(data){
    let userdata;

    userdata = data;
    const user = {
      postingdate: userdata.PostDate,
      TaskCategory: userdata.TaskCategory,
    desc1: userdata.Desc1,
    desc2: userdata.Desc2,
    desc3: userdata.Desc3,
    desc4: userdata.Desc4,
    comment: userdata.Comment,
    startdate: userdata.StartDate,
    enddate:userdata.DueDate,
    status: userdata.Status,
    popupdays: userdata.PopUpDays,
    notifyflag: userdata.NotifyFlag

    };
    this.form.setValue(user);

  }
  getdocumentcontrol(params: any)
  {
    const query = `cid=${params.cid}&flag=${params.flag}&taskno=${params.taskno}`;
    return this.http.httpGet('/Documentcontrol/GetDocumentcontrol?' , query)
    // .catch(this.handleError);
  }
  setdocumentcontrol(data: any)
  {
    return this.http.httpPost('/Documentcontrol/SetDocumentcontrol?', data)
    // .catch(this.handleError);
  }
  // setdocumentcontrol1(data: any)
  // {
  //   return this.http.post(baseUrl + '/SetDocumentcontrol?', data)
  //   // .catch(this.handleError);
  // }

  public downloadFile(CID: number,Flag:string,Taskno:number): Observable < Blob > {  
    return this.http.httpGet2('/Documentcontrol/GetFile?cid=',  CID+'&flag='+Flag+'&taskno='+Taskno);  
  }  
  public downloadImage(image: string): Observable < Blob > {  
      return this.http.httpGet2( '/Documentcontrol/GetImage?image=', image); 
  }  

  AddFileDetails(data: FormData): Observable < string > {  
    let headers = new HttpHeaders();  
    headers.append('Content-Type', 'application/json');  
    const httpOptions = {  
        headers: headers  
    };  
    return this.http.httpPost( '/Documentcontrol/AddFileDetails/', data)
    // .catch(this.handleError); 
  }
  
  GetExpiryDetails(params: any)
  {
    const query = `cid=${params.cid}&username=${params.username}&flag=${params.flag}&hide=${params.hide}`;
    return this.http.httpGet( '/Documentcontrol/GetExpiryDetails?' , query)
    // .catch(this.handleError);
  }
  
  // private handleError(error: Response){
  //   if (error.status === 400)
  //       return Observable.throw(new BadInput(error.json()));

  //   if (error.status === 404)
  //   return Observable.throw (new NotFoundError());
    
  //   return Observable.throw(new AppError(error));
  // }
}

