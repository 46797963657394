import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { User } from './../../../Modules/user';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { DxDataGridModule, DxSelectBoxModule, DxDateBoxModule, DxButtonModule, DxSpeedDialActionModule } from 'devextreme-angular';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { AlternateempComponent } from '../alternateemp/alternateemp.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {Observable} from 'rxjs';
import {startWith, map, pluck} from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { UserService } from './../../Admin/Users/userValidate';
import { AnonymousSubject } from 'rxjs/internal/Subject';


@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
})
export class AttendanceComponent implements OnInit {

  showSpinner:any;
  isbioVisible:boolean=false;
  dataSource: any;
  // leavetype: LeaveType[];
  EffortCateg1:EffortCateg1[];
  EffortCateg2:any;
  viewbtnvisible: boolean = false;
  projectlist: any;
  projectname: string;
  leavetypes: LeaveType[];
  employeeleavelist: any;
  projectid: string;
  date: Date; //= new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  now: Date = new Date();
  startEditAction: 'click';
  selectTextOnEditStart: true; 
  selectedRowsData = [];
  selectedEffortSlno:any;
  onSelectionChange:boolean;
  //dx-selector attribute
  // searchModeOption = 'contains';
  // searchExprOption: any = 'ProjectName';
  // searchTimeoutOption = 200;
  // minSearchLengthOption = 0;

  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  saveenable: boolean = false; 

  projectids: string[]= [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  CurLedgerID:number;
  CurUserName:any;

  constructor(private service: HttpserviceService, private dialog: MatDialog,private service1: UserService,
    public notificationService: NotificationService,private generic: GenericService,
    private datePipe: DatePipe) {
      // this.dataSource = service.getEmployees();
      // this.states = service.getStates();
      this.showSpinner=false;        
    }

    ngOnInit(): void {
      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      this.CurUserName=curUser.username;
      this.CurLedgerID=curUser.ledgerid; 
      // console.log(' this.LedgerID', this.CurLedgerID);
      this.date= new Date();
      this.getprojectlist();
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
  
       
    }
    getUserList(){      
      let params = {
        cid: JSON.parse(sessionStorage.getItem('cid')),
        menuid: "STS_92",
        groupid: JSON.parse(sessionStorage.getItem('groupid'))
      }
      this.service1.getUserList(params)
      .subscribe((data) => {
        let result: any;
        result=data;
        let btnVisilityData;
        btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);      
        this.viewbtnvisible = btnVisilityData.viewflag;
     
      }, 
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  
    }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    // console.log('projectlist: ', this.projectlist);
    // console.log('filter vlaue: ', filterValue);
    // console.log('projectids: ', this.projectids);
    return this.projectids.filter(option => option.toLowerCase().includes(filterValue));

  }
  selectedproduct(event) {     
    this.projectid = JSON.parse(_.pluck(_.where(this.projectlist, { 'ProjectName': event.option.value }), 'ID'));
    this.dataSource = '';
    this.getprojectemployee();
  }

  openDialog() {
    //const dialogRef = this.dialog.open(AlternateempComponent);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;  
    dialogConfig.data = {       
    //  CID:this.HrPolicyFormGroup.get('fcCompany').value,
      project:this.projectid,
      date:this.date
    }
    const dialogRef = this.dialog.open(AlternateempComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {   
      this.dataSource = '';  
      this.getprojectemployee();     
    });
  }
  // used to get project list from project master
  getprojectlist(){
 
    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      ledgerid:this.CurLedgerID
    };
    const query = `cid=${params.cid}&ledgerid=${params.ledgerid}`;
    this.service.httpGet('/projecteffort/projectlist?',query)
    
    .subscribe(data => {
      let result: any;
      result = data;
        this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)

      if (result.errno === 0){
        this.projectlist = result.respdata;
        this.projectids = _.pluck(this.projectlist, 'ProjectName');
        
        this.getUserList();
      }
       
        // console.log('Project list: ', this.projectlist);
     
      else{
        this.notificationService.success(result.errdesc); //':: Problem details');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)
        // this.service.form.setErrors(error.originalError);
        console.log(error);
      else throw error;
    });
  }

  //used to get all employee of the specified project, get Leavetype from LeaveTypeMaster, Get employees for this given date and this project except who taken leave (get it by #Table)
  getprojectemployee(){
   
console.log('abcd',this.projectid)
    
if (this.projectid != undefined) {
    this.showSpinner=true;   
    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      projectUID: this.projectid,
      ledgerid: this.curUser.ledgerid,
      date: this.datePipe.transform(this.date, 'yyyy-MM-dd')
    };
    const query = `cid=${params.cid}&projectUID=${params.projectUID}&ledgerid=${params.ledgerid}&date=${params.date}`;
    this.service.httpGet('/projecteffort/projectemployee?',query)
    .subscribe(data => {
      let result: any;
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      500)
      result = data;
      // console.log('result: ', result);
      // tslint:disable-next-line: one-line
      if (result.errno === 0){
        this.leavetypes = result.respdata.LeaveTypes;
        // console.log('leavetypes: ', this.leavetypes);
        // this.employeelist = new MatTableDataSource<Employee>( result.respdata.EmployeeList);
        this.employeeleavelist = result.respdata.EmployeeLeaveList;
        this.dataSource = result.respdata.EmployeeList;
         
        this.EffortCateg1=result.respdata.Effortcateg1;
        this.EffortCateg2=result.respdata.Effortcateg2;

        // console.log('HI',this.EffortCateg1);
        // console.log('bye',this.EffortCateg2);


        this.selectedEffortSlno=_.pluck(_.where(this.dataSource, { 'ChkStatus': '1' }), 'LedgerID');

       // console.log('leavetypes: ',  this.selectedEffortSlno);
        // console.log('Effort: ', this.dataSource);
        // console.log('emplist: ', this.employeelist);
        // console.log('empleavelist: ', this.employeeleavelist);
      }
      // tslint:disable-next-line: one-line
      else{
        // tslint:disable-next-line: comment-format
        this.notificationService.success(result.errdesc); //':: Problem details');
      }
    },
        (error: AppError) => {
          // tslint:disable-next-line: curly
          if (error instanceof BadInput)
            // this.form.setErrors(error.originalError);
            console.log(error);
          // tslint:disable-next-line: curly
          else throw error;
        });
    }
   else
      this.notificationService.warn('Please select project');
  }

  // tslint:disable-next-line: one-line
  Submit(){
    // console.log('date: ', this.datePipe.transform(this.date,"dd-MM-yyyy"))
    // remove biometric column from object
    // const removebiometric = this.dataSource.map(({ BioMetric, ...item }) => item);
    //  // remove leavetype column from object
    //  const removeleavetype = removebiometric.map(({ LeaveType, ...item }) => item);
    // // console.log('params0 :', removebiometric);
    // const filteredcolumn = removeleavetype.map(({ Name, ...item }) => item);
    // // console.log('params1 :', filteredcolumn[0]);
 //   console.log('submit',this.dataSource);
    const filteredcolumn = this.dataSource.map(item => { return {
   
      Attendance: item.Attendance,
      Comment: item.Comment,
      Effort: item.Effort,
      EndTime: item.EndTime,
      LedgerID: item.LedgerID,
      OT: item.OT,
      StartTime: item.StartTime,
      ChkStatus: item.ChkStatus,
      EffortCat1:item.EffortCat1,
      EffortCat2:item.EffortCat2,
      Description:item.Description
    }});

     this.showSpinner=true;
    //  setTimeout(() =>{
    //    this.showSpinner=false;
    //  },
    //  800)
    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      effortdate: this.datePipe.transform(this.date, 'yyyy-MM-dd'),
      projectUID: this.projectid,
      effort: filteredcolumn,
      UserID:this.CurLedgerID,
      UserName:this.CurUserName,
      GroupID:sessionStorage.getItem('groupid'),
      ApplicationDetails:'Web'
    };
    // console.log('params :', params);
    this.service.httpPost('/projecteffort/projecteffortupdate',params)
    .subscribe(
      (data) => {
        // console.log('inside save');
        let users: any;
        users = data;
        this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)
        if (users.errno === 0){
          this.notificationService.success(users.errdesc);
        }
        else{
          this.notificationService.success(users.errdesc); //':: Problem updating user');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)
          // this.service.form.setErrors(error.originalError);
          console.log(error);
        else throw error;
      });

  }

  SearchClear()
  {
    this.myControl.setValue('');  
    this.projectid=undefined;
  }
  
  validateTime(e){
    // console.log('inside validate time: ', e.value.length);
    return e.value.length === 5 ? true : false;
  }

  //Calculate Effort based on Starttime changed
  calculateEffortStartTime(newData, value, currentRowData){
    const startTime = new Date(value);
    const endTime = new Date(currentRowData.EndTime);

    const stringDate =  value.getFullYear() +
                        '-' +
                        (1 + value.getMonth()).toString().padStart(2, '0') +
                        '-' +
                        value.getDate().toString().padStart(2, '0') +
                        'T' +
                        value.getHours().toString().padStart(2, '0') +
                        ':' +
                        value.getMinutes().toString().padStart(2, '0') +
                        ':' +
                        value.getSeconds().toString().padStart(2, '0');

    // console.log('stringDate', stringDate);
    newData.StartTime = stringDate;

    newData.Effort = currentRowData.Effort;

    if (startTime.getTime() < endTime.getTime()) {

      const difference = new Date(
        endTime.getTime() -
        startTime.getTime() +
        startTime.getTimezoneOffset() * 60000
      );

      newData.Effort =
                        difference.getHours().toString().padStart(2, '0') +
                        ':' +
                        difference.getMinutes().toString().padStart(2, '0');
    }


  }

  //Calculate Effort based on Endtime changed
  calculateEffortEndTime(newData, value, currentRowData) {
    const startTime = new Date(currentRowData.StartTime);
    const endTime = new Date(value);

    const stringDate =  value.getFullYear() +
                      '-' +
                      (1 + value.getMonth()).toString().padStart(2, '0') +
                      '-' +
                      value.getDate().toString().padStart(2, '0') +
                      'T' +
                      value.getHours().toString().padStart(2, '0') +
                      ':' +
                      value.getMinutes().toString().padStart(2, '0') +
                      ':' +
                      value.getSeconds().toString().padStart(2, '0');

    newData.EndTime = stringDate;

    const difference = new Date(
      endTime.getTime() -
      startTime.getTime() +
      startTime.getTimezoneOffset() * 60000
    );

    newData.Effort =
      difference.getHours().toString().padStart(2, '0') +
      ':' +
      difference.getMinutes().toString().padStart(2, '0');
  }

  addCustomItem(data){
    // console.log('project selction: ', data);
  }

  // onValueChanged(e) {
    // console.log("selected item : " + e);
    // const item = this.projectlist.filter(i => i.ID === e.value)[0];
    // this.projectname = item.ProjectName;
    // console.log(item.ID + ': ' + this.projectname);
// }

  generatePdf(action = 'open') {
    const documentDefinition = this.getDocumentDefinition();
    switch (action) {
      case 'open': pdfMake.createPdf(documentDefinition).open(); break;
      case 'print': pdfMake.createPdf(documentDefinition).print(); break;
      case 'download': pdfMake.createPdf(documentDefinition).download(); break;
      default: pdfMake.createPdf(documentDefinition).open(); break;
    }
  }

  exportAsExcel()
  {
    // , {header:['name', 'surname']}
    this.generic.excelExport(this.dataSource, 'Attendance', 'EffortList' );
  }


  onChangeEvent(){     
      this.dataSource = '';
      this.getprojectemployee();
    }

  getDocumentDefinition() {

    // sessionStorage.setItem('resume', JSON.stringify(this.resume));
    return {
      content: [
        {
          text: 'Project Attendance',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [{
              text: 'Project : ' + this.projectname,
              style: 'name'
            },
            {
              text: 'Date : ' + this.datePipe.transform(this.date, 'yyyy-MM-dd')
            },
            {
              text: 'Company : '+ JSON.parse(sessionStorage.getItem('cid')) ,
            }
            ]
          ]
        },
        {
          text: 'Effort',
          style: 'header'
        },
        this.getAttendance(),
        {
          text: 'Signature',
          style: 'sign'
        }
        ,
        {
          columns : [
              // { qr: this.curUser.userid , fit : 100 },
              {
              text: `(${this.curUser.username})`,
              alignment: 'right',
              }
          ]
        }
      ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
          },
          name: {
            fontSize: 16,
            bold: true
          },
          jobTitle: {
            fontSize: 14,
            bold: true,
            italics: true
          },
          sign: {
            margin: [0, 50, 0, 10],
            alignment: 'right',
            italics: true
          },
          tableHeader: {
            bold: true,
          }
        }
    };
  }
  onSelectionChanged(e) { // Handler of the "selectionChanged" event
  
       const currentSelectedRowKeys = e.currentSelectedRowKeys;
       const currentDeselectedRowKeys =e.currentDeselectedRowKeys;
       const allSelectedRowKeys = e.allSelectedRowKeys;
       const allSelectedRowsData = e.allSelectedRowsData;    
            
      if(currentSelectedRowKeys.length>0){
        var i=0;
          for (let key of Object.keys(this.dataSource)) {
            let keydata = this.dataSource[key];                          
            //console.log('keydata',keydata['SlNo']);
            if (currentSelectedRowKeys[i]=== keydata['LedgerID']){            
              keydata['ChkStatus'] ="1";               
              i=i+1;        
            }    
          }
        }
       else if(currentDeselectedRowKeys.length>0){        
          var j=0;
          for (let key of Object.keys(this.dataSource)) {
            let keydata = this.dataSource[key];               
            if (currentDeselectedRowKeys[j]=== keydata['LedgerID']) {              
              keydata['ChkStatus'] ="0";                     
              j=j+1;       
            } 
          }
        }
      //  console.log('selectedRowsData',e.selectedRowsData);
        //console.log('EffortApprovalDataGrids', this.dataSource);
      }
     
   

  onClear()
  {    
    this.myControl.setValue('');    
    this.employeeleavelist ='';
    this.dataSource = '';
    this.projectid=null;
  }

  getAttendance() {
    const result = this.dataSource.filter(value => value.StartTime !== value.EndTime);
    // console.log('result: ', result);
    return {
      table: {
        widths: ['*', '*', '*', '*', '*', '*'],
        body: [
          [{
            text: 'Name',
            style: 'tableHeader'
          },
          {
            text: 'StartTime',
            style: 'tableHeader'
          },
          {
            text: 'End Time',
            style: 'tableHeader'
          },
          {
            text: 'Effort',
            style: 'tableHeader'
          },
          {
            text: 'OT',
            style: 'tableHeader'
          },
          {
            text: 'Comment',
            style: 'tableHeader'
          },
          ]
          ,
          ...result.map(ed => {
            return [ed.Name, this.datePipe.transform(ed.StartTime, 'h:mm'), this.datePipe.transform(ed.EndTime, 'h:mm'), ed.Effort, ed.OT, ed.Comment];
          })
        ]
      }
    };
  }

}
// export class Employee {
//   Attendance: number;
//   LedgerID: number;
//   Name: string;
//   BioMetric: string;
//   StartTime: string;
//   EndTime: string;
//   Effort: string;
//   OT: string;
//   Comment: string;
//   LeaveType: number;
// }

export class LeaveType {
  LID: number;
  LeaveType: string;
}

export class EffortCateg1
{
  EntityID:number;
  Tag:string;
}