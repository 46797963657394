import { DialogService } from './../../../../Services/OtherServices/dialog.service';

import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-hrconfiguration',
  templateUrl: './hrconfiguration.component.html',
  styleUrls: ['./hrconfiguration.component.scss'],
})
export class HrconfigurationComponent implements OnInit {

  CID:any;
  flag:any;
  Condition:any;
  companies:any;
  entitytype:any;
  datasource:any;
  addflag= false;

 constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,private dialogService: DialogService,
    private NotificationService:NotificationService)
     { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();
  }

  onCompanySelect(event)
  {
    this.addflag= false;
    this.entitytype=null;
    this.datasource=null;
    this.CID=event;
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();

  }

  Loaddetails()
  {
    let params = {
      cid:this.CID,
      flag:this.flag,
      Condition:this.Condition
      
       }
          // const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
          this.service.httpPost('/HRConfig/GetHRConfig',params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.entitytype=result.respdata.HRConfigTag;
              }
              else
              {
                this.datasource=result.respdata.HRConfigList;
              }
                
                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }
  onConfiguration(event)
  {
    // console.log('LOADGRID',event)
    this.flag='LOADGRID'
    this.Condition=event.value;
    this.Loaddetails();
    this.addflag = true;
  }

  logEvent(_flag, e)
  {
    // console.log("logEvent",e.data.Tag)

    let slno = 0;
    let  paramsExtra;
    slno = e.data.SlNo;
    // const time = (this.datePipe.transform( e.data.Date, 'dd-MM-yyyy') + ' ' + e.data.Time);

  if (_flag == 'RowInserting')
   {
    paramsExtra = {
      CID: this.CID,
      Flag: 'ADD',
      ComboName:this.Condition,
      DisplayMember: e.data.Tag,
      UserID: '',
      GroupID:sessionStorage.getItem('groupid')

    };
   }
  else if (_flag == 'RowRemoved')
  {

    paramsExtra = {
      CID: this.CID,
      Flag: 'DELETE',
      ComboName:this.Condition,
      DisplayMember: e.data.Tag,
      UserID: '',
      GroupID:sessionStorage.getItem('groupid')

    };
  }
  this.service.httpPost('/HRConfig/SetHRConfig',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
    
      if (users.errno === 0){
        this.notificationService.success('Submitted successfully');

      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }

}
