import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss'],
})
export class TimezoneComponent implements OnInit {

  FormGroup: FormGroup;
  CID:any;
  Flag:any;
  ID:any;
  TimeZone:any;
  Diffenece:any;
  CultureInfo:any;
  Country:any;
  Currencycode:any;
  ExchangeRate:any;
  CurrencyFlag:any;

  Timezone=[{name:'Arabian Standard Time'},{name:'Arab Standard Time'},
            {name:'Bahrain Standard Time'},{name:'British Standard Time'},
            {name:'China Standard Time'},{name:'Central Standard Time'},
            {name:'French Standard Time'},{name:'Greek Standard Time'},
            {name:'Germany Standard Time'},{name:'Gulf Standard Time'},
            {name:'India Standard Time'},{name:'Italy Standard Time'},
            {name:'Japan Standard Time'},{name:'korea Standard Time'},
            {name:'Turkey Standard Time'},{name:'Qatar Standard Time'},
            {name:'South Africa Standard Time'}
          ];
  
CultureInfolist=[{name:'ar-AE'},{name:'ar-SA'},
          {name:'zh-SG'},{name:'en-US'},
          {name:'en-IN'},{name:'ar-BH'},
          {name:'el-GR'},{name:'fr-FR'},
          {name:'en-GB'},{name:'de-DE'},
          {name:'ja-JP'},
          {name:'it-IT'},{name:'ko-KR'},
          {name:'tr-TR'},{name:'ar-QA'},
          {name:'en-ZA'}
        ];  
Countrylist=[{name:'Australia'},{name:'Bahrain'},
        {name:'France'},{name:'Greece'},
        {name:'Cermany'},{name:'Italy'},
        {name:'India'},{name:'Japan'},
        {name:'Malaysia'},{name:'Singapore'},
        {name:'Korea'},{name:'Soudi Arabia'},
        {name:'Turkey'},{name:'UAE'},
        {name:'United Kingdom'},{name:'Qatar'},
        {name:'South Africa'},{name:'Oman'}
        
      ];             

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<TimezoneComponent>,
    private datePipe: DatePipe,@Inject(MAT_DIALOG_DATA) public data: any) 
     {
        this.CID=this.data.cid;
        this.Flag=this.data.Flag;
        this.ID=this.data.TZID;
        this.TimeZone=this.data.TimeZone;
        this.Diffenece=this.data.TimeDifference;
        this.CultureInfo=this.data.CultureInfo;
        this.Country=this.data.Country;
        this.Currencycode=this.data.Currencycode;
        this.ExchangeRate=this.data.ExchangeRate;
        this.CurrencyFlag=this.data.CurrencyFlag;
        

       
      this.FormGroup = this.formBuilder.group({ 
        fcTimeZone:['', Validators.required], 
        fcDiff:['', Validators.required], 
        fcCultureInfo:['', Validators.required], 
        fcCountry:['', Validators.required],    
        fcCurrencycode:[''],
        fcExchangeRate:[''], 
        fcCurrencyFlag:['']  
      }); 


      this.FormGroup.controls.fcTimeZone.setValue(this.TimeZone);
        this.FormGroup.controls.fcDiff.setValue(this.Diffenece);
        this.FormGroup.controls.fcCultureInfo.setValue(this.CultureInfo);
        this.FormGroup.controls.fcCountry.setValue(this.Country);
        this.FormGroup.controls.fcCurrencycode.setValue(this.Currencycode);
        this.FormGroup.controls.fcExchangeRate.setValue(this.ExchangeRate);
        this.FormGroup.controls.fcCurrencyFlag.setValue(this.CurrencyFlag);

      }

  ngOnInit() 
  {}
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }

  
  onSearchClear(){
    this.FormGroup.reset();        
   
 }

  Submit()
  {
  
    let params = { 
      cid:this.CID,
      flag: this.Flag, 
      TimeID:this.ID,
      timezone:this.FormGroup.get('fcTimeZone').value,
      TimeDifference:this.FormGroup.get('fcDiff').value,
      CultureInfo:this.FormGroup.get('fcCultureInfo').value, 
      Country:this.FormGroup.get('fcCountry').value, 
      CurCode:this.FormGroup.get('fcCurrencycode').value, 
      ExchangeRate:this.FormGroup.get('fcExchangeRate').value, 
      BaseCurFlag:this.FormGroup.get('fcCurrencyFlag').value, 
      UserID:'0',
      GroupID:101
      
     };
    this.service.httpPost('/TimeZone/SetTimeZone',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); 
          this.dialogRef.close({data:'data'});//':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
