import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-showdetails',
  templateUrl: './showdetails.component.html',
  styleUrls: ['./showdetails.component.css']
})
export class ShowdetailsComponent {

  approvallist:any;
  displayedColumns: string[] = ['RevNo','Level','ApprovedBy','ApprovedDate','Status','Comment'];
  constructor(
    

    public dialogRef: MatDialogRef<ShowdetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.approvallist=data.dt;


    }
    ngOnInit(): void {
      this.dialogRef.updateSize('50%');
      
    }
  onSubmit() {
    //  if(this.selectedRow!==undefined)
    //  {
    //   this.dialogRef.close(this.selectedRow);  // Return form data on submit
    //  }
    
    
  }

  onCancel(): void {
    this.dialogRef.close();  // Close dialog without saving
  }
}
