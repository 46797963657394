import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { ExcelImportDialogComponent } from './excel-import-dialog/excel-import-dialog.component';
import { EditEmailTagListComponent } from './edit-email-tag-list/edit-email-tag-list.component';

@Component({
  selector: 'app-ticketemailconfigexcelimport',
  templateUrl: './ticketemailconfigexcelimport.component.html',
  styleUrls: ['./ticketemailconfigexcelimport.component.scss'],
})
export class TicketemailconfigexcelimportComponent implements OnInit {

  CID:any;
  flag:any;
  ID:number=0;
  companies:any;
  entitytype:any;
  datasource:any;
  showSpinner:any;
  displayedColumns =['Type','TypeID','eMailTag','eMail','Action'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,
  public notificationService: NotificationService,private dialogService: DialogService)
  { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.Loaddetails();
  }

  onCompanySelect(event)
  {
    this.CID=event;
    this.flag='PAGELOAD'
    this.Loaddetails();   
  }

  Submit(){
    this.Loaddetails();
  }

  Loaddetails()
  {
    this.showSpinner=true;
    let params = {
    CID:this.CID,
    Flag:this.flag
      }         
          this.service.httpPost('/TicketConfiguration/GetTicketEmailConfigrationExcelImport',params)
          .subscribe(data => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
            let result: any;
            result=data;
            if (result.errno === 0){
            
              console.log('data',result);               
                this.companies=result.respdata.CompanyDetails;
                this.datasource=result.respdata.EmailTagConfig;                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
            this.notificationService.warn(error);
            else throw error;       
          });
  }
  
  ExcelImport()
  {
        const ExcelData = {cid: JSON.parse(sessionStorage.getItem('cid')),                                      
        flag:"Excel",                     
        // approvergroupid: this.approvergroupid,
        // projectid: this.projectid
        //,approvergroupname: f.value.approvegroupname
    };
    sessionStorage.setItem('Exceldata', JSON.stringify(ExcelData));
    const dialogRef = this.dialog.open(ExcelImportDialogComponent);
    dialogRef.afterClosed().subscribe(result => {   
    });
  }

  Edit(event)
  {
  
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'EditEmailTag' ,                            
          Type:event.Type,
          TypeID:event.TypeID,
          eMailTag:event.EmailTag,
          eMail:event.Email          
        }
        const dialogRef = this.dialog.open(EditEmailTagListComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='EditEmailTag'
          this.Loaddetails();
  
          });
  }

//   Delete(event){
  
//     let sdate =Date();
//     this.dialogService.openConfirmDialog('Are you sure to delete this product?')
//     .afterClosed().subscribe(res => {
//      if (res) {
//       let params = { 
//       CID:this.CID,
//       Flag: 'DELETE', 
//       ID: event.ProductID, 
//       Name:  event.ProductName,   
//       Description: event.Description,
//       Status:event.StatusID,
//       Register:event.RegisterSince,
//       UserID: 0,
//       GroupID:sessionStorage.getItem('groupid')
        
//        };
//        this.service.httpPost('/ProductConfig/SetProductConfig',params)
//     .subscribe(
//       (data) => {
//         let result: any;
//         result = data;       
//         if (result.errno === 0) {
//           this.NotificationService.warn("Product deleted Successfully");
//           //console.log('Project list: ', result.respdata);
//           this.flag='PAGELOAD'
//           this.Loaddetails();
//         }
//         else {
//           this.NotificationService.success(result.errdesc); //':: Problem details');
//         }
//       },
//       (error: AppError) => {
//         if (error instanceof BadInput)         
//           console.log(error);
//         else throw error;       
//       });
//     }
//   });
// }

}
