import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { User } from '../../../../Modules/user';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.scss'],
})
export class MerchantComponent implements OnInit {

  date: Date; //= new FormControl(new Date());  
  now: Date = new Date();
  MerchantFormGroup: FormGroup;
  dataSource:any;
  Flag:any;
  cid:any;
  Product:any;
  Priority:any;
  merchantid:any=0;
  Ledgerid:any=0;
  statusid:any;
  CurUserName:any;
  Statuslist=[
    {id: 0, name: 'Active'},
    {id: 1, name: 'InActive'}
    
  ];

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<MerchantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  { 
      this.cid = this.data.cid;   
      this.Flag=this.data.flag;
      this.dataSource=this.data.client;
      this.Product=this.data.product;
      this.Priority=this.data.priority;

    this.MerchantFormGroup = this.formBuilder.group({  
      fcClientName:['', Validators.required],     
      fcSince:['' , Validators.required],
      fcStatus:['' , Validators.required],
      fcAddress1:[''],
      fcAddress2:[''],
      fcPincode:[''],
      fcZone:[''],
      fcName:[''],
      fcEmail:[''],
      fcTelephone:[''],
      fcMobile:[''],
      fcSupportEmail:['']  
    }); 

    if(this.Flag=='EDIT')
    {
      this.merchantid=this.data.data.MerchantID;
      this.Ledgerid=this.data.data.LedgerID;
      this.date=this.data.data.MerchantSince
      this.statusid=this.data.data.CusActiveStatus==false?0:1;
      this.MerchantFormGroup.controls.fcClientName.setValue(this.data.data.MerchantName);
      this.MerchantFormGroup.controls.fcSince.setValue(this.date);
      this.MerchantFormGroup.controls.fcStatus.setValue(this.statusid);
      this.MerchantFormGroup.controls.fcAddress1.setValue(this.data.data.Address1);
      this.MerchantFormGroup.controls.fcAddress2.setValue(this.data.data.Address2);
      this.MerchantFormGroup.controls.fcPincode.setValue(this.data.data.PoBox);
      this.MerchantFormGroup.controls.fcZone.setValue(this.data.data.CusTimeZone);
      this.MerchantFormGroup.controls.fcName.setValue(this.data.data.Contact);
      this.MerchantFormGroup.controls.fcEmail.setValue(this.data.data.Email);
      this.MerchantFormGroup.controls.fcTelephone.setValue(this.data.data.Tel);
      this.MerchantFormGroup.controls.fcMobile.setValue(this.data.data.Mobile);
      this.MerchantFormGroup.controls.fcSupportEmail.setValue(this.data.data.SupportMailID);
    }
  }

  ngOnInit() 
  {
    this.Loaddetails();
    if(this.Flag!='EDIT')
    {
    this.date= new Date();
    this.MerchantFormGroup.controls.fcStatus.setValue(this.Statuslist[0].id);   
    }
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurUserName=curUser.username;
  }
  Loaddetails()
  {
    let params = {
      CID:this.cid,
      Flag:'GetValue',
      MerchantId:this.Ledgerid,
      Name:'""'
  
       }
          // const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
          this.service.httpPost('/Client/GetClient',params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
              // if(this.flag=='PAGELOAD')
              // {
              //   this.companies=result.respdata.Companylist;
              //   this.Clientlist=result.respdata.Clientsub;
              //   this.Product=result.respdata.Product;
              //   this.Priority=result.respdata.Priority;
              //   // this.datasource=result.respdata.Merchantlist;
              //   this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Merchantlist);
              //   this.datasource.sort = this.sort;
              //   this.datasource.paginator = this.paginator;
              // }
              // else
              // {
                this.dataSource=result.respdata.Clientsub;
              // }
                
                
            }
            else{
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
  Submit()
  {
   let count='0';
   if(this.dataSource.length>0 || this.dataSource.length==0)
   {
    count='1';
   }

  let params = { 
    cid:this.cid,
    flag: this.Flag, 
    MerchantId:this.merchantid,
    Name:this.MerchantFormGroup.get('fcClientName').value,
    Since:this.MerchantFormGroup.get('fcSince').value,
    Status:this.MerchantFormGroup.get('fcStatus').value,
    Address1:this.MerchantFormGroup.get('fcAddress1').value,
    Address2:this.MerchantFormGroup.get('fcAddress2').value,
    Pincode:this.MerchantFormGroup.get('fcPincode').value,
    TimeZone:this.MerchantFormGroup.get('fcZone').value,
    ContName:this.MerchantFormGroup.get('fcName').value,
    Email:this.MerchantFormGroup.get('fcEmail').value,
    Mobile:this.MerchantFormGroup.get('fcMobile').value,
    ConTel:this.MerchantFormGroup.get('fcTelephone').value,
    SupportID:this.MerchantFormGroup.get('fcSupportEmail').value,
    LedgerID:this.CurUserName,
    GroupID:sessionStorage.getItem('groupid'),
    dtClientSubStatus:count,
    dtExample:this.dataSource
    
   };
  this.service.httpPost('/Client/SetClient',params)
  .subscribe(
    (data) => {
      let result: any;
      result = data;       
      if (result.errno !== 0) {
        this.NotificationService.success(result.errdesc);
        //console.log('Project list: ', result.respdata);
        this.dialogRef.close({data:'data'});
      }
      else {
        this.NotificationService.success(result.errdesc); //':: Problem details');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

}
