import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/Forms';
import { observable } from 'rxjs';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  companies: any = [];
  allgroups: GroupALL[];

  constructor(private http: HttpserviceService) { }

  form: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.minLength(5)]),
    confirmpassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
    groupid: new FormControl(0, [Validators.required]),
    ledgerid: new FormControl(0, Validators.required),
    inactive: new FormControl(false),
    showpopup: new FormControl(false),
    timepunch: new FormControl(false),
    maincolor: new FormControl(''),
    secondarycolor: new FormControl(''),
    distance: new FormControl(0),
    lngcode: new FormControl(0),
    activedirectorypath: new FormControl(''),
    activedirectorydomain: new FormControl(''),
    activedirectoryuserid: new FormControl(''),
    companies: new FormControl(''),
    merchantid:new FormControl(''),
    templateid:new FormControl('',Validators.required),
    timezoneid:new FormControl('',Validators.required),
    usertype:new FormControl('',Validators.required),
    worklocation:new FormControl(0),
    ERP: new FormControl(false),
    CRM_WEB: new FormControl(false),
    CRM_MA: new FormControl(false),
    DASHBOARD: new FormControl(false)
  });

  jsonData = {"ERP": true,"CRM_WEB": true,"CRM_MA": true,"DASHBOARD": true}

  initializeFormGroup(){
    this.form.setValue({
      username: '',
      password: '',
      confirmpassword: '',
      groupid: 0,
      ledgerid: 0,
      inactive: false,
      showpopup: false,
      timepunch:false,
      maincolor: '',
      secondarycolor: '',
      lngcode: 0,
      activedirectorypath: '',
      activedirectorydomain: '',
      activedirectoryuserid: '',
      companies: [],
      merchantid:0,
      templateid:'',
      timezoneid:'',
      usertype:'',
      worklocation:0,
      distance:0,
      ERP:this.jsonData.ERP,
      CRM_WEB:this.jsonData.CRM_WEB,
      CRM_MA:this.jsonData.CRM_MA,
      DASHBOARD:this.jsonData.DASHBOARD
    });
  }

  erpValue:boolean;CRM_WEB:boolean;CRM_MA:boolean;DASHBOARD:boolean;

  populateForm(userID){
    this.companies = [];

    const params = {
      cid: '101',
      userid: userID
    };
    this.getUser(params)
    .subscribe(
      data => {
        let result: any;
        result = data;
        let userdata;

        for (const comp in result.respdata.companies)
        {
            const val = result.respdata.companies[comp];
            if (val.Approved == true)
            {
              this.companies.push(val.SiteID);
            }
        }
        userdata = result.respdata.userdata;

    //  console.log('userdata',userdata[0].ApplicationAccess)
      const config = JSON.parse(userdata[0].ApplicationAccess);
      this.erpValue = config.ERP;
      this.CRM_WEB=config.CRM_WEB;
      this.CRM_MA=config.CRM_MA;
      this.DASHBOARD=config.DASHBOARD;     

        const user = {
          username: userdata[0].UserName,
          password: userdata[0].Password,
          confirmpassword: userdata[0].Password,
          groupid: userdata[0].GroupID,
          ledgerid: userdata[0].LedgerID,
          inactive: userdata[0].InActive,
          showpopup: userdata[0].ShowPopUp,
          timepunch:userdata[0].SetGeoLocation,
          maincolor: userdata[0].ERPMainColor,
          secondarycolor: userdata[0].ERPSecondaryColor,
          lngcode: userdata[0].DefaultLngCode,
          activedirectorypath: userdata[0].ActiveDirectoryPath,
          activedirectorydomain: userdata[0].ActiveDirectoryDomain,
          activedirectoryuserid: userdata[0].ActiveDirectoryUserID,
          companies: this.companies,
          merchantid:userdata[0].LedgerID,
          templateid:userdata[0].TemplateGroupID,
          timezoneid:userdata[0].TZID,
          usertype:userdata[0].UserType,
          worklocation:userdata[0].WLID,
          distance:userdata[0].distance,
       //   ERP:this.jsonData.ERP,
          ERP:this.erpValue,
          CRM_WEB:this.CRM_WEB,
          CRM_MA:this.CRM_MA,
          DASHBOARD:this.DASHBOARD
        };
        this.form.setValue(user);
      },
      (error: AppError) => {
        if (error instanceof BadInput)
          this.form.setErrors(error.originalError);
        else throw error;
      });

  }

  getUserList(params: any) {
    const query = `cid=${params.cid}&menuid=${params.menuid}&groupid=${params.groupid}&applicationType=${3}`;
    return this.http.httpGet('/usermgt/userlist?', query)
  }

  setDefaultValues(){
    this.form.patchValue({ 'lngcode': 1 });
  }

  getUserDefaultLoads(params: any) {
    const query = `cid=${params.cid}&tablename=${params.tablename}&type=${params.type}&condition=${params.condition}&menuid=${params.menuid}&withinactive=${params.withinactive}`;
    return this.http.httpGet('/usermgt/loaddetails?' , query)
  
  }

  getUser(params: any) {
    const query = `cid=${params.cid}&userid=${params.userid}`;
    return this.http.httpGet('/usermgt/getuser?' , query)
  }

  create(data: any) {
    return this.http.httpPost('/usermgt/userupdate', data)
  }

  delete(params: any) {
    const query = `cid=${params.cid}&userid=${params.userid}`;
    return this.http.httpGet('/usermgt/userdelete?' , query)
  }

  // getphoto(params: any)
  // {
  //   const query = `cid=${params.cid}&ledgerid=${params.ledgerid}`;
  //   return this.http.httpGet('/usermgt/GetPhoto?' , query)
  
  // }
  
}
export interface GroupALL {
  GroupID: string;
  GroupName: string;
  UserID: string;
  UserName: string;
  Status: string;
}
