import { User } from './../../../../Modules/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/Forms';
import { Component, OnInit, Inject } from '@angular/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';

@Component({
  selector: 'app-expenseposting',
  templateUrl: './expenseposting.component.html',
  styleUrls: ['./expenseposting.component.css']
})
export class ExpensepostingComponent implements OnInit {
  showSpinner:any;
 
  Expenseposting : FormGroup;
  Ledgerlist:any;
  Prefixlist:any;
  flag:any;
  dataSource:any;
  dataSource1: any;
  username:any;
  documentno:any;
  date:any;
  prefix:any;
  rangeno:any
  CID:any;
  curdate= new Date();
  disabled:boolean = false;
  constructor(private formBuilder: FormBuilder,private datePipe: DatePipe, private service: HttpserviceService, private NotificationService: NotificationService
    ,public dialogRef: MatDialogRef<ExpensepostingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
   
      this.flag = this.data.flag;
      // this.temp = this.data.pid;
      // this.dataSource=this.data.entity;
      // this.dataSource1=this.data.entity;
      //console.log('DATA0',this.data.flag)
      this.Prefixlist = this.data.prefixlist;
      this.Ledgerlist = this.data.ledgerlist;
      this.dataSource = this.data.griddata;
      this.dataSource1 = this.data.griddata;
      this.documentno = this.data.documentno;
      this.date=this.data.date;
      this.prefix=this.data.Prefixlist;
      this.rangeno=this.data.RangeNo;
      this.CID=this.data.cid;


    this.Expenseposting = this.formBuilder.group({  
      // fcCompany: ['', Validators.required] ,             
      fcPrefix: [''],     
      fcDocumentNo: [''],     
      fcVouDate: ['']
       
  });
   }

  ngOnInit(): void {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    //console.log("curUser",this.documentno)
    //console.log('pageload',this.data.prefixlist[0].Prefix)
    this.username=curUser.username;
    this.Expenseposting.controls.fcVouDate.setValue(this.curdate);
    this.Expenseposting.controls.fcPrefix.setValue(this.data.prefixlist[0].Prefix);
    if(this.flag==="EDIT")
    {
      this.getExpenseposting();
      this.disabled=true;
      //console.log('EDIT PRefix',this.prefix)
      this.Expenseposting.controls.fcVouDate.setValue(this.date);
      this.Expenseposting.controls.fcPrefix.setValue(this.prefix);
      this.Expenseposting.controls.fcDocumentNo.setValue(this.rangeno);
    }
  }
  onCloseDialog()
  {
    // this.service.form.reset();
    // this.service.initializeFormGroup();
    this.Expenseposting.reset();
    this.dialogRef.close({event: 'CLEAR'});
  }
  onClose(model){
    this.Expenseposting.reset();
    // this.initializeFormGroup();
    this.dialogRef.close({event: this.flag, data: model});
  }
  onClear()
  {
    this.dataSource='';
    this.Expenseposting.controls.fcVouDate.setValue(this.curdate);
    this.Expenseposting.controls.fcPrefix.setValue(this.data.prefixlist[0].Prefix);
    this. getExpenseposting();
  }
  onSubmit()
  {
    this.showSpinner=true;

 // console.log("dataSource",this.dataSource)
  // console.log("dataSource1",this.dataSource1)
  this.Expenseposting.controls.fcDocumentNo.setValue(this.documentno);

  const params = {
    CID: this.CID,
    Flag:  this.flag,
    Prefix:this.Expenseposting.get('fcPrefix').value,
   //Prefix:'GEP/',
    DocNo:this.Expenseposting.get('fcDocumentNo').value,
    DocDate: this.datePipe.transform(this.Expenseposting.get('fcVouDate').value,"dd-MM-yyyy"),
    DtExpense:this.dataSource,
    TotalAmt:0,
    UserID:this.username,
    GroupID:sessionStorage.getItem('groupid')
      };

      console.log('PARAMS',params)
      this.service.httpPost('/ExpensePosting/SetExpensePosting',params)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
          // console.log('create:', users.errno);
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          if (users.errno === 0){
            this.NotificationService.success('Submitted successfully');
            this.onCloseDialog();
          }
          else{
            this.NotificationService.success(users.errdesc); //':: Problem updating user');
            this.onCloseDialog();
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });

  }

 
  getExpenseposting()
  {
    let date=Date();
    const params = {
      CID: this.CID,
      Flag: "EDIT",
      Status: this.documentno,
      Fromdate: this.datePipe.transform(date,"dd-MM-yyyy"),
      ToDate: this.datePipe.transform(date,"dd-MM-yyyy")
      
        };
        this.service.httpPost('/ExpensePosting/GetExpensePosting',params)
        .subscribe(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.dataSource = result.respdata.Table;
           console.log('BIND Data',this.dataSource)
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
}
