import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { User } from 'src/app/Modules/user';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { UserService } from './../../Admin/Users/userValidate';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { CrmdatecontrolComponent } from '../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';

@Component({
  selector: 'app-effortapproval',
  templateUrl: './effortapproval.component.html',
  styleUrls: ['./effortapproval.component.css']
})
export class EffortapprovalComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('stepper') stepper;

  dataSource: EffortApprovalDataGrid[];
  EffortApprovalDataGrids: any;
  SelectedCompanyID: number;
  projectdata: any;
  companies: any;
  Employees: any;
  projectlist: any;
  viewbtnvisible: boolean = false;
  selectedCID: number;
  selectedprojectid: any;
  selectedemployees: number;
  projectid: string[] = [];
  employeeidname: string[] = [];
  frmdate = new Date();
  ApprovalFormGroup: FormGroup;
  EffortDataFormGroup: FormGroup;
  isEditable = true;
  myControl1 = new FormControl();
  myControl = new FormControl('', [Validators.required]);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  cid: any;
  selectedProjectID: number;
  selectedEmpID: string = 'All';
  fromdate: any;
  todate: any;
  DateTypeFlag: any = "Today"
  date: Date; //= new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  now: Date = new Date();
  // mySimpleFormat = this.datePipe.transform(this.now, 'dd-MM-yyyy');
  LedgerID: number;
  // allMode: string;
  // checkBoxesMode: string;
  startEditAction: string = "click";
  selectTextOnEditStart: boolean = true;
  selectedRowsData = [];
  selectedEffortSlno: any;
  onSelectionChange: boolean;
  constructor(private service: HttpserviceService, private formBuilder: FormBuilder, private dialog: MatDialog, private generic: GenericService,
    private service1: UserService, public notificationService: NotificationService, private datePipe: DatePipe) {

    // this.allMode = 'allPages';
    // this.checkBoxesMode = 'onClick'

    this.ApprovalFormGroup = this.formBuilder.group({
      fcCompany: ['', Validators.required],
      //fcEmployee: [''] ,      
      // myControl: ['', Validators.required],      
      // fcFromDate: ['', Validators.required],
      // fcToDate: ['', Validators.required]
    });
    this.EffortDataFormGroup = this.formBuilder.group({
    });
  }


  ngOnInit(): void {

    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID = curUser.ledgerid;
    this.cid = JSON.parse(sessionStorage.getItem('cid'));
    this.PageLoad();
    this.ApprovalFormGroup.controls.fcCompany.setValue(this.cid);
    //this.ApprovalFormGroup.controls.fcFromDate.setValue(this.frmdate);
    //this.ApprovalFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.todate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'myControl'))
      );

    this.filteredOptions2 = this.myControl1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'myControl1'))
      );
    // this.getprojectlist(); 
  }

  getUserList() {
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_91",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
      .subscribe((data) => {
        let result: any;
        result = data;
        let btnVisilityData;
        btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
        this.viewbtnvisible = btnVisilityData.viewflag;

      },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log(error);
          else throw error;
        });

  }
  
  changesite(e) {
    this.cid = e.value;
    this.Employees ='';
    this.projectdata ='';
    this.projectid =[];
    this.employeeidname = [];
    this.PageLoad();
    this.myControl1.setValue('');
    this.myControl.setValue('');
  }

  onSearchClear() {
    this.ApprovalFormGroup.reset();
    this.EffortApprovalDataGrids = '';
    this.myControl1.setValue('');
    this.myControl.setValue('');
    this.stepper.selectedIndex = 0;
    this.ApprovalFormGroup.controls.fcCompany.setValue(this.cid);
    // this.ApprovalFormGroup.controls.fcFromDate.setValue(this.frmdate);
    //this.ApprovalFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.todate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.DateTypeFlag = "Today";
    this.selectedprojectid =undefined;
  }
  SearchClear() {
    this.myControl.setValue('');
    this.selectedprojectid=undefined;
  }
  private _filter(value: string, control: string): string[] {

    const filterValue = value.toLowerCase();
    //console.log('controlID',control);
    // console.log('FilterProjectID',this.employeeidname);
    // console.log('FilterProjectID',this.projectid);
    if (control === 'myControl') {
      //console.log('FilterProjectID',this.projectid);
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }
    else if (control === 'myControl1') {
      //console.log('FilterProjectID',this.employeeidname);
      return this.employeeidname.filter(option => option.toLowerCase().includes(filterValue));
    }

  }


  selectedEmployee(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
    // console.log('control', control);
    if (control === 'myControl') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.projectdata, { 'ProjectID': event.option.value }), 'ProjectUID'));
      //console.log('selectedprojectid', this.selectedprojectid);
    }
    else {
      this.selectedEmpID = event.option.value;
      // console.log('selectedEmpID Split', this.selectedEmpID.slice(0, str.indexOf('-')));
      this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
      //console.log('selectedEmpID', this.selectedEmpID);
    }

  }

  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID: this.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: "LOADDDL",
      EmployeeID: "0",
      DateType: "0",
      FromDate: "0",
      ToDate: "0",
      UserID: this.LedgerID,
      ProjectID: "0"

    };
    //this.service.getddllist(params)
    this.service.httpPost('/ProjectEffortApproval/Getdropdownvalues', params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {
            this.companies = curUser.dtsiteid;
            //this.SelectedCompanyID = 101;                  
            this.Employees = result.respdata.Employee;
            this.projectdata = result.respdata.Projectlist;
            this.projectid = _.pluck(this.projectdata, 'ProjectID');
            this.employeeidname = _.pluck(this.Employees, 'Name');
            this.onSelectionChange = false;
            this.getUserList();
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }
  Submit() {

    //console.log('hi',this.selectedprojectid)
   
    // if (this.ApprovalFormGroup.invalid) {

    //   this.notificationService.warn('Please select project');
    //   return;
    // }

    if (this.selectedprojectid != undefined) {
      let params = {
        CID: this.ApprovalFormGroup.get('fcCompany').value,
        Flag: "LOADGRID",
        ProjectID: this.selectedprojectid,
        EmployeeID: this.selectedEmpID,
        DateType: "ByDate",
        FromDate: this.fromdate,
        ToDate: this.todate,
        //   FromDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcFromDate').value, "dd-MM-yyyy"),      
        //  ToDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcToDate').value, "dd-MM-yyyy"),
        UserID: this.LedgerID
      };
      //  console.log('params :', params);
      //this.service.geteffortlist(params)
      this.service.httpPost('/ProjectEffortApproval/GetProjectEffortApproval', params)
        .subscribe(
          (data) => {
            let result: any;
            result = data;
            //result=JSON.parse(data);        
            //result=new Map(JSON.parse(data))

            //result=orderedJson.parse(data);

            if (result.errno === 0) {
              //console.log('result.respdata.table', JSON.stringify(result));
              this.EffortApprovalDataGrids = result.respdata.Table;
              this.dataSource = result.respdata.Table;
              // console.log('_.where(this.EffortApprovalDataGrids',_.where(this.EffortApprovalDataGrids, { 'ChkStatus': '1' }));
              // console.log('_.pluck',_.pluck(_.where(this.EffortApprovalDataGrids, { 'ChkStatus': '1' }), 'SlNo'));
              this.selectedEffortSlno = _.pluck(_.where(this.EffortApprovalDataGrids, { 'ChkStatus': '1' }), 'SlNo');
              this.stepper.selectedIndex = 1;
              // console.log('selectedEffortSlno: ', this.selectedEffortSlno);
              //console.log('effort list: ', result.respdata);
            }
            else {
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          },
          error => {
            console.log('Error : ', error);
          });
    }
    else
      this.notificationService.warn('Please select project');
  }


  Save() {

    if (this.ApprovalFormGroup.invalid) {
      // console.log('invalid project form group')
      return;
    }
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    // console.log('curuser',curUser);
    let removeApproved = this.EffortApprovalDataGrids.map(({ Approved, ...item }) => item);
    let removeIsHalfDay = removeApproved.map(({ IsHalfDay, ...item }) => item);
    let removeName = removeIsHalfDay.map(({ Name, ...item }) => item);

    let removecat1 = removeName.map(({ EffortCat1, ...item }) => item);
    let removecat2 = removecat1.map(({ EffortCat2, ...item }) => item);
    let removeDesc = removecat2.map(({ Description, ...item }) => item);

    let FinalData = removeDesc.map(({ Project, ...item }) => item);
    //this.EffortApprovalDataGrids=FinalData;
    let params = {
      CID: this.ApprovalFormGroup.get('fcCompany').value,
      Flag: "APPROVED",
      ProjectID: this.selectedprojectid,
      EmployeeID: this.selectedEmpID,
      DateType: "ByDate",
      Comment: "",
      FromDate: this.fromdate,
      ToDate: this.todate,
      // FromDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcFromDate').value, "dd-MM-yyyy"),     
      // ToDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcToDate').value, "dd-MM-yyyy"),
      UserID: this.LedgerID,
      jsondt: FinalData
    };
    // console.log('EffortApprovalDataGrids', FinalData);
    //this.service.updateProjectEffortApproval(params)
    this.service.httpPost('/ProjectEffortApproval/ApproveEfforts', params)
      .subscribe(
        (data) => {
          // console.log('inside save');
          var users: any;
          users = data;

          if (users.errno === 0) {
            this.notificationService.success('Submitted successfully');
          }
          else {
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        error => {
          console.log('Error : ', error);
        });

  }

  DatePicker() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      DateTypeFlag: this.DateTypeFlag,
      width: '500px',
      height: '500px',
      panelClass: 'my-panel-class'
    }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('RESULT', result)
      this.fromdate = result.frmdate;
      this.todate = result.todate;
      this.DateTypeFlag = result.datetype
    });
  }

  validateTime(e) {
    // console.log('inside validate time: ', e.value.length);
    return e.value.length === 5 ? true : false;
  }

  //Calculate Effort based on Starttime changed
  calculateEffortStartTime(newData, value, currentRowData) {
    let startTime = new Date(value);
    let endTime = new Date(currentRowData.EndTime);

    let stringDate = value.getFullYear() +
      "-" +
      (1 + value.getMonth()).toString().padStart(2, "0") +
      "-" +
      value.getDate().toString().padStart(2, "0") +
      "T" +
      value.getHours().toString().padStart(2, "0") +
      ":" +
      value.getMinutes().toString().padStart(2, "0") +
      ":" +
      value.getSeconds().toString().padStart(2, "0");

    // console.log('stringDate', stringDate);
    newData.StartTime = stringDate;

    newData.EffortHours = currentRowData.EffortHours;

    if (startTime.getTime() < endTime.getTime()) {

      let difference = new Date(
        endTime.getTime() -
        startTime.getTime() +
        startTime.getTimezoneOffset() * 60000
      );

      newData.EffortHours =
        difference.getHours().toString().padStart(2, "0") +
        ":" +
        difference.getMinutes().toString().padStart(2, "0");
    }


  }

  //Calculate Effort based on Endtime changed
  calculateEffortEndTime(newData, value, currentRowData) {
    var startTime = new Date(currentRowData.StartTime);
    var endTime = new Date(value);

    var stringDate = value.getFullYear() +
      "-" +
      (1 + value.getMonth()).toString().padStart(2, "0") +
      "-" +
      value.getDate().toString().padStart(2, "0") +
      "T" +
      value.getHours().toString().padStart(2, "0") +
      ":" +
      value.getMinutes().toString().padStart(2, "0") +
      ":" +
      value.getSeconds().toString().padStart(2, "0");

    newData.EndTime = stringDate;

    var difference = new Date(
      endTime.getTime() -
      startTime.getTime() +
      startTime.getTimezoneOffset() * 60000
    );

    newData.EffortHours =
      difference.getHours().toString().padStart(2, "0") +
      ":" +
      difference.getMinutes().toString().padStart(2, "0");
  }



  onSelectionChanged(e) { // Handler of the "selectionChanged" event
    //  if(this.onSelectionChange===true){
    const currentSelectedRowKeys = e.currentSelectedRowKeys;
    const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
    const allSelectedRowKeys = e.allSelectedRowKeys;
    const allSelectedRowsData = e.allSelectedRowsData;

    // console.log('currentSelectedRowKeys',currentSelectedRowKeys[0]);
    // console.log('currentSelectedRowKeys',currentSelectedRowKeys.length);
    // console.log('currentDeselectedRowKeys',currentDeselectedRowKeys);
    // console.log('AllselectedRowKeys',allSelectedRowKeys);
    // console.log('AllselectedRowsData',allSelectedRowsData);
    // console.log('selectedRowKeys',e.selectedRowKeys);

    // console.log('deselectedRowKeys',e.deselectedRowKeys);
    //console.log('deselectedRowsData',e.deselectedRowsData);


    if (currentSelectedRowKeys.length > 0) {
      var i = 0;
      for (let key of Object.keys(this.EffortApprovalDataGrids)) {
        let keydata = this.EffortApprovalDataGrids[key];
        //if (JSON.parse(currentSelectedRowKeys)=== keydata['SlNo']) 
        //console.log('keydata',keydata['SlNo']);
        if (currentSelectedRowKeys[i] === keydata['SlNo']) {
          //keydata['AuthStatus'] = "A";
          keydata['ChkStatus'] = "1";
          //keydata['Approved'] =101;
          i = i + 1;
        }
      }
    }
    else if (currentDeselectedRowKeys.length > 0) {
      //console.log('deselect');
      var j = 0;
      for (let key of Object.keys(this.EffortApprovalDataGrids)) {
        let keydata = this.EffortApprovalDataGrids[key];
        if (currentDeselectedRowKeys[j] === keydata['SlNo']) {
          //keydata['AuthStatus'] = "P";
          keydata['ChkStatus'] = "0";
          //keydata['Approved'] =101;
          j = j + 1;
        }
      }
    }

    //  console.log('selectedRowsData',e.selectedRowsData);




    // if(currentSelectedRowKeys.length>0){
    //   for (let key of Object.keys(this.EffortApprovalDataGrids)) {
    //     let keydata = this.EffortApprovalDataGrids[key];               
    //     //if (JSON.parse(currentSelectedRowKeys)=== keydata['SlNo']) 
    //     //console.log('keydata',keydata['SlNo']);
    //     if (JSON.parse(currentSelectedRowKeys)=== keydata['SlNo']){
    //       keydata['AuthStatus'] = "A";
    //       keydata['ChkStatus'] ="1";  
    //       keydata['Approved'] =101;        
    //     }    
    //   }
    // }
    // else if(currentDeselectedRowKeys.length>0){
    //   //console.log('deselect');
    //   for (let key of Object.keys(this.EffortApprovalDataGrids)) {
    //     let keydata = this.EffortApprovalDataGrids[key];               
    //     if (JSON.parse(currentDeselectedRowKeys)=== keydata['SlNo'] && keydata['AuthStatus']==='A') {
    //       keydata['AuthStatus'] = "P";
    //       keydata['ChkStatus'] ="0";        
    //       keydata['Approved'] =101;       
    //     } 
    //     else if (JSON.parse(currentDeselectedRowKeys)=== keydata['SlNo'] && keydata['AuthStatus']==='P' && keydata['ChkStatus']===0) {
    //       keydata['AuthStatus'] = "P";
    //       keydata['ChkStatus'] ="0";        
    //       keydata['Approved'] =101;       
    //     }  
    //     else if (JSON.parse(currentDeselectedRowKeys)=== keydata['SlNo'] && keydata['AuthStatus']==='O') {
    //       keydata['AuthStatus'] = "O";
    //       keydata['ChkStatus'] ="0";        
    //       keydata['Approved'] =101;       
    //     }  
    //     else if (JSON.parse(currentDeselectedRowKeys)=== keydata['SlNo'] && keydata['AuthStatus']==='P' && keydata['ChkStatus']==='1') {
    //       keydata['AuthStatus'] = "P";
    //       keydata['ChkStatus'] ="0";        
    //       keydata['Approved'] =101;       
    //     }          

    //   }
    // }


    // console.log('EffortApprovalDataGrids', this.EffortApprovalDataGrids);
  }




  // else{
  //   this.onSelectionChange=true;
  // }

  // }

  // selectAllRows () {
  //   this.dataGrid.instance.selectAll();
  //  }

  onRowPrepared(e) {

    if (e.rowType == 'data' && e.data.AuthStatus == "O") {
      e.rowElement.style.backgroundColor = '#FCD1C7';
    }
    else if (e.rowType == 'data' && e.data.AuthStatus == "P") {
      e.rowElement.style.backgroundColor = '#F7FC7F';
      //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");  
    }
    else if (e.rowType == 'data' && e.data.AuthStatus == "A") {
      e.rowElement.style.backgroundColor = '#D8D8D3';
      //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");  
    }
  }

}




export class EffortApprovalDataGrid {
  LedgerID: number
  UserID: number
  EmployeeID: number
  ProjectID: string
  FromDate: string
  ToDate: string

}


