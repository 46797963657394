import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { DxTreeViewComponent } from 'devextreme-angular';
import { dxTreeViewNode } from 'devextreme/ui/tree_view';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { User } from 'src/app/Modules/user';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;

  employees: Employee[];
  selectedEmployees: dxTreeViewNode[] = [];

  showCheckBoxesModes: string[] = ['normal', 'selectAll', 'none'];
  showCheckBoxesMode: string = this.showCheckBoxesModes[0];
  selectionModes: string[] = ['multiple', 'single'];
  selectionMode: string = this.selectionModes[0];

  selectNodesRecursive = true;
  selectByClick = false;

  isRecursiveDisabled = false;
  isSelectionModeDisabled = false;
  groupmgtdata = {cID: '', GroupID : '', GroupName : '', flag: ''};

  groupmgtsub: GroupMgtSub[];

  GroupNameFormGroup: FormGroup;
  TreeDataFormGroup: FormGroup;
  isEditable = true;

  flag: string;
  grpid: string;
  cid: number ;
  userid: number ;
  ledgerid: number;
  jsondt: any;

  constructor(
    private _formBuilder: FormBuilder,
    private menuformsettings: HttpserviceService,
    private _snackBar: MatSnackBar,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<GroupComponent>,
    private dialog: MatDialogRef<GroupComponent>) {
      this.GroupNameFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required]
      });
      this.TreeDataFormGroup = this._formBuilder.group({
        secondCtrl: ['']
      });

      this.groupmgtdata = JSON.parse(sessionStorage.getItem('groupMgtData'));
   //   console.log('groupdata', this.groupmgtdata);
      this.GroupNameFormGroup.controls['firstCtrl'].setValue(this.groupmgtdata.GroupName);
      this.flag = this.groupmgtdata.flag;
      this.grpid = this.groupmgtdata.GroupID;
      //this.loadMenuGrouping();
    }

  ngOnInit(): void {
  }

  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
            this.GroupNameFormGroup.reset();
            this.TreeDataFormGroup.reset();
            this.dialogRef.close();
          }
        });
  }

  loadMenuGrouping()
  {
    //this.groupmgtdata.cID, 'GROUPMGT', '1', this.groupmgtdata.GroupID
    // const paramsData = 'cid=' + cid + '&flag=' + flag + '&MenuGroupID=' + uniqid + '&groupid=' + groupid;
    // const options = { params: new HttpParams({fromString: paramsData}) }
    const query = `cid=${JSON.parse(sessionStorage.getItem('cid'))}&flag=${'GROUPMGT'}&MenuGroupID=${'1'}&groupid=${this.groupmgtdata.GroupID}&AppType=${'3'}`;
    this.menuformsettings.httpGet('/groupmgt/GetMenuGroup?',query)
    .subscribe(
      data => {
        //console.log('data',JSON.stringify(data));
        removeEmptyProperties(data);
        this.employees = data.respdata;

      },
      error => {
        console.log(error);
      });
  }

  treeViewSelectionChanged(e) {
      this.syncSelection(e.component);
  }

  treeViewContentReady(e) {
      this.syncSelection(e.component);
  }

  syncSelection(treeView) {
    const selectedEmployees = treeView.getSelectedNodes()

    .map((node: dxTreeViewNode) => {
      // console.log('node',node);
      // if(node.itemData.type==='Options'){
        return {
          options: node.itemData.options,
          menuid: node.itemData.menuid,
          parentid: node.itemData.parentid,
          type: node.itemData.type,
          selected: node.itemData.selected
        };
      // }

    });
    // console.log('selectedEmployees', JSON.stringify(selectedEmployees));
    this.selectedEmployees = selectedEmployees;
  }

  showCheckBoxesModeValueChanged(e) {
      this.showCheckBoxesMode = e.value;
      this.isSelectionModeDisabled = e.value === 'selectAll';
      if (e.value === 'selectAll') {
          this.selectionMode = 'multiple';
          this.isRecursiveDisabled = false;
      }
  }

  selectionModeValueChanged(e) {
      this.selectionMode = e.value;
      this.isRecursiveDisabled = e.value === 'single';
      if (e.value === 'single') {
          this.selectNodesRecursive = false;
          this.treeView.instance.unselectAll();
      }
  }

  bindSelectedData(){
    this.loadMenuGrouping();
  }

  SetGroupMgtSubDetails()
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    // console.log('this.selectedEmployees', this.selectedEmployees.length);
    if (this.selectedEmployees.length != 0)
    {
      const params = {
        Flag: this.flag,
        CID: JSON.parse(sessionStorage.getItem('cid')),
        GroupID: this.grpid,
        GroupName: this.GroupNameFormGroup.controls['firstCtrl'].value,
        DefaultSiteID: JSON.parse(sessionStorage.getItem('cid')),
        Username: curUser.username,
        dtGroupData: this.selectedEmployees,
        ApplicationType:3
      };

    this.menuformsettings.httpPost('/groupmgt/setgroup',params)
    .subscribe(
      data => {
        const result = data as any;
          // console.log('result', JSON.stringify(result));
        if (result.errno === 0){
          this._snackBar.open(result.errdesc, this.flag, {
            duration: 3000,
          });

          const GroupmgtData = {GroupID: result.respdata.groupid, GroupName: result.respdata.groupname};
          this.dialog.close(GroupmgtData); //(MenuformsettingsComponent);
        }
        else{
          this._snackBar.open('Problem updating user', result.errordesc, {
            duration: 3000,
          });
       }
      },
      error => {
        console.log(error);
      });
    }
    else{
      this._snackBar.open('Please select form access..', 'Select Menu', {
        duration: 3000,
      });
    }
  }

}

export class Employee {
  id: number;
  fullName: string;
  menuid: string;
  expanded?: boolean;
  selected?: boolean;
  items?: Employee[];
}

function removeEmptyProperties(obj) {
  // console.log("obj",obj)
  Object.keys(obj).forEach(function(key) {
    if (obj[key] && typeof obj[key] === 'object') removeEmptyProperties(obj[key]); //recursive for objects
    else if (obj[key] == null || obj[key] == '') delete obj[key];         //remove empty properties
      if (typeof obj[key] === 'object' && Object.keys(obj[key]).length == 0) delete obj[key]; //remove empty objects
    });
}

interface GroupMgtSub {
  menuid: string;
  options: string;
  type: string;
  parentid: string;
  selected?: boolean;
  items?: GroupMgtSub[];
}
