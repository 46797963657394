import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { User } from 'src/app/Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';


type AOA = any[][];


@Component({
  selector: 'app-projectallocationbyweek',
  templateUrl: './projectallocationbyweek.component.html',
  styleUrls: ['./projectallocationbyweek.component.css']
})
export class ProjectallocationbyweekComponent implements OnInit {

  ProjectFormGroup: FormGroup;
  clients = [];
  dataSource: ProjectAllocationDataGrid[];
  ProjectAllocationDataGrids2: any;
  myControl = new FormControl('', [
    Validators.required]);
  lblStartWeekNo:any;
  lblEndWeekNo:any;
  //options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  EmployeeData: string[] = [];
  EmployeeDetails: any;
  selectedEmpID: any;
  ProjectData: any;
  showSpinner:any;
  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';

 LedgerID: number;

  constructor(private formBuilder: FormBuilder, 
    private service: HttpserviceService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<ProjectallocationbyweekComponent>,
    public notificationService: NotificationService) {

  }

  ngOnInit(): void {

    const curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID = curUser.ledgerid;

    this.ProjectData = JSON.parse(sessionStorage.getItem('ProjectData'));
    // console.log('isExcel', this.ProjectData);
    if (this.ProjectData.flag === 'isExcel')
     {
      this.ProjectFormGroup = this.formBuilder.group({
        isExcel: false,
        isEmployee: true       
      });
      this.lblStartWeekNo=this.ProjectData.startweekno,
      this.lblEndWeekNo=this.ProjectData.endweekno
     }
    else{
      this.ProjectFormGroup = this.formBuilder.group({
        isEmployee: false,
        isExcel: true
      });

      this.PageLoad();
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    }



    //console.log('this.ProjectData', this.ProjectData);

    //this.EmployeeDetails=this.ProjectData.productid;
  }


  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
            this.ProjectFormGroup.reset();           
            this.dialogRef.close();
          }
        });
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    //console.log('FilterProjectID',this.projectid);
    // if(this.projectid!=undefined){
    //   this.PageLoad();
    return this.EmployeeData.filter(option => option.toLowerCase().includes(filterValue));
    //}
  }
  PageLoad() {
    const params = {
      CID: this.ProjectData.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: 'EmployeeData',
      EmployeeID: '',
      ProjectID: this.ProjectData.projectuid,
      RoleID: this.ProjectData.department,
      DepartmentID: 0,
      StartWeekNo: this.ProjectData.startweekno,
      EndWeekNo: this.ProjectData.endweekno,
      Year: this.ProjectData.year,
      SetWeekEnd: 0,
      SetHolidays:0
    };
    this.service.httpPost('/ProjectAllocationByWeek/GetProjectAllocationData',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          //console.log('data ', data);
          if (result.errno === 0) {
            // console.log('result.respdata.table', result.respdata.Table);
            this.EmployeeDetails = result.respdata.Table;
            this.EmployeeData = _.pluck(this.EmployeeDetails, 'EmpName');
            //  console.log('empuniqid',this.EmployeeDetails);
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }

  selectedEmployee(event) {   
    // this.selectedEmpID = event.option.value;
    // const str: string = this.selectedEmpID;    
    // this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
    

    const inputString = event.option.value;

// Split the input string into an array using the delimiter "-"
    const parts = inputString.split("-");

// Get the last element of the array
    const lastPart = parts.pop();
   // console.log('Last',lastPart)
// Join the parts back together with "-" as the delimiter
    this.selectedEmpID = parts.join("-");

    this.Submit();
  }

  Submit() {
    const params = {
      CID: this.ProjectData.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: 'SearchEmployeeLoadGrid',
      EmployeeID: this.selectedEmpID,
      ProjectID: this.ProjectData.projectuid,
      RoleID: this.ProjectData.department,
      DepartmentID: 0,
      StartWeekNo: this.ProjectData.startweekno,
      EndWeekNo: this.ProjectData.endweekno,
      Year: this.ProjectData.year,
      SetWeekEnd: this.ProjectData.SetWeekEnd,
      SetHolidays: this.ProjectData.SetHolidays,
    };
    this.service.httpPost('/ProjectAllocationByWeek/GetProjectAllocationData',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          //console.log('data ', data);
          if (result.errno === 0) {
            // console.log('result.respdata.table', result.respdata.Table);
            const removeLedgerID = result.respdata.Table.map(({ LedgerID, ...item }) => item);
            const removeResourceAllocationID = removeLedgerID.map(({ ResourceAllocationID, ...item }) => item);
            const removeName_Allotted = removeResourceAllocationID.map(({ Name_Allotted, ...item }) => item);
            this.ProjectAllocationDataGrids2 = removeName_Allotted;
            //this.EmployeeData= _.pluck(this.EmployeeDetails, 'EmpName');
            // console.log('pageloadProjectID', this.ProjectAllocationDataGrids2);
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }

  onSearchClear(){
    this.ProjectFormGroup.reset();
    this.myControl.setValue('');
    if (this.ProjectData.flag === 'isExcel')
     {
      this.ProjectFormGroup = this.formBuilder.group({
        isExcel: false,
        isEmployee: true
      });
     }
    else{
      this.ProjectFormGroup = this.formBuilder.group({
        isEmployee: false,
        isExcel: true
      });
    }
    this.ProjectAllocationDataGrids2='';
  }

  SearchClear()
  {
    this.myControl.setValue('');  
    this.ProjectAllocationDataGrids2='';
  }

  Save() {
    // console.log('ProjectAllocationDataGrids2', this.ProjectAllocationDataGrids2);
    this.showSpinner=true;
    const params = {
      cid: this.ProjectData.cid,
      Flag: this.ProjectData.flag,
      ProjectID: this.ProjectData.projectuid,
      EmployeeID: '',
      RoleID: this.ProjectData.department,
      StartWeekNo: this.ProjectData.startweekno,
      EndWeekNo: this.ProjectData.endweekno,
      Year: this.ProjectData.year,
      SetWeekEnd: this.ProjectData.SetWeekEnd,
      SetHolidays: this.ProjectData.SetHolidays,
      dtProjectAllocationData: this.ProjectAllocationDataGrids2,
      GroupID: 0,
      CreatedBy: this.LedgerID,
      CreatedDate: '2020-09-01',
      LastUpdatedBy: this.LedgerID,
      LastUpdatedDate: '2020-09-01'
    };
    // console.log('params :', params);
//    this.service.updateProjectAllocationByWeek(params)
    this.service.httpPost('/ProjectAllocationByWeek/SetProjectAllocationData',params)
      .subscribe(
        (data) => {
          // console.log('inside save');
          let users: any;
          users = data;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)          
          if (users.errno === 0) {
            this.notificationService.success('Submitted successfully');
          }
          else {
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        error => {
          console.log('Error : ', error);
        });
    // let removeTotalAllotMins = this.ProjectAllocationDataGrids2.map(({ TotalAllotMins, ...item }) => item);
    // let removeTotalWorkMins = removeTotalAllotMins.map(({ TotalWorkMins, ...item }) => item);
    // let removeType = removeTotalWorkMins.map(({ Type, ...item }) => item);
    // let removeName = removeType.map(({ Name_Allotted, ...item }) => item);
    // console.log('ProjectAllocationDataGrids2',removeName);
    // this.ProjectAllocationDataGrids2=removeName;
    // //const index = this.dataSource.data.indexOf(item.id);
    // this.ProjectAllocationDataGrids2.splice(1, 1);
    // console.log('ProjectAllocationDataGrids2',this.ProjectAllocationDataGrids2);

  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws));
      // console.log(this.data);
      this.ProjectAllocationDataGrids2 = this.data;
    };
    reader.readAsBinaryString(target.files[0]);
  }


  onCellPrepared(e) {
    if (e.rowType !== 'data' || e.columnIndex > 2 ) {
      return;
    }
    if (e.rowIndex % 2 === 0 && ( e.columnIndex == 0  ||  e.columnIndex == 1 ||  e.columnIndex == 2 )) {
      e.cellElement.rowSpan = 2;
      e.cellElement.innerHTML = '<div>' + e.value + '</div>';
    }
    else {
      e.cellElement.style.display = 'none';
    }
  //   if(e.rowType === 'header') {
  //     e.cellElement.css("backgroundColor", "blue");
  //  }
  }

}
export class ProjectAllocationDataGrid {
  LedgerID: number;
  ResourceAllocationID: number;
  Name_Allotted: string;
  TotalAllotMins: number;
  TotalWorkMins: number;
  Type: string;
}

