import { ProfileComponent } from './../../components/Admin/Profile/profile/profile.component';
import { PopupnotificationComponent } from './../../components/Employee/Popup/popupnotification/popupnotification.component';
import { ChangePasswordComponent } from './../../components/Admin/change-password/change-password.component';
import { MatDialogConfig,MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../Services/OtherServices/authentication.service';
// import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { User } from 'src/app/Modules/user';
import themes from "devextreme/ui/themes";
//import { theme } from '../menu-items';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { DocumentcontrolValidate } from 'src/app/components/Employee/Documents/documentcontrolvalidate';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  hidden = false;
  badge:any=0;
  // themes: string[] = [
	// 	"deeppurple-amber",
	// 	"indigo-pink",
	// 	"pink-bluegrey",
	// 	"purple-green",
	// ];

  themes = [       
    { themeindex : 0, color: '#2196f3',themename:"blue"},
    { themeindex : 1, color:  '#e91e63',themename:"pink"},
    //{ themeindex : 2, color:  '#ffc107',themename:"amber"},
   // { themeindex : 3, color:  '#8bc34a',themename:"lightGreen"},
    { themeindex : 2, color:  '#9b59b6',themename:"violet"},
    { themeindex : 3, color:  '#34495e',themename:"grey"},
    { themeindex : 4, color:  '#1abc9c',themename:"cyangreen"},
    { themeindex : 5, color:  '#2ecc71',themename:"seagreen"}
  ];

  //@Input() item: theme;  
  //@Output() themeSelect: EventEmitter<any> = new EventEmitter();

  CurLedgerID:string;
  ledgerid:any;
  loginphoto:any;  
  event: any;
  profiledetail:boolean=false;
  notificationdetail:boolean=false;
  darklight:any;
  showpop:any;
  color = "primary";
  protected _themes = ["theme-1", "theme-2","theme-5","theme-6","theme-7","theme-8"];
  protected _currentThemeIndex = 0;   
  isDark = false;
  
  

  constructor(
    private router: Router, 
    private service: AuthenticationService,
    private dialog: MatDialog ,
    private renderer: Renderer2,
    private httpservice:HttpserviceService,
    private notificationservice:NotificationService,
    public service1: DocumentcontrolValidate,) {   
    
      // var isDarkTemp =JSON.stringify(sessionStorage.getItem('isDark'));//localStorage.getItem('isDark');
      // if( isDarkTemp==="0")  
      // {
      //   this.isDark=false;
      //   this.renderer.removeClass(document.body, this.isDark ? "dark": "light");
      //   this.renderer.addClass(document.body, this.isDark ? "dark": "light");
      // }
      // else
      // {
      //   this.isDark=true;
      //   this.renderer.removeClass(document.body, this.isDark ? "dark": "light");
      //   this.renderer.addClass(document.body, this.isDark ? "dark": "light");
      // }
   
    //this.renderer.addClass(document.body, this._themes[0]);    
    var isDarkTemp = sessionStorage.getItem('isDark');
    if( isDarkTemp==="0")    
      this.darklight="Dark";    
    else
      this.darklight="Light";    

    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   // const str:string=curUser.empname;
   // console.log('CurLedgerID', curUser.empname.split(' - ')[1]);

    this.CurLedgerID=curUser.empname.split(' - ')[1]==undefined?curUser.username :curUser.empname.split(' - ')[1];
    //this.CurLedgerID=curUser.username;
    this.ledgerid=curUser.ledgerid;
    this.loginphoto='data:image/jpeg;base64,'+sessionStorage.getItem('photo');
   
  this.badge =sessionStorage.getItem('documentcount');
  this.hidden= this.badge==0 ? true : false;

//console.log('SHOWPOPUP',curUser.ShowPopUp);

  if(curUser.UserType==='External'){
    this.profiledetail=false;
    this.notificationdetail=false;
  }
  else
  {
    this.profiledetail=true;
    if(curUser.ShowPopUp===1)
      this.notificationdetail=true;
   else
      this.notificationdetail=false;
  }

   }

   getdocumentdetails()
  {
    let params = {
      cid:JSON.parse(sessionStorage.getItem('cid')),
      username:"''",
      flag:'LoadPopUp',
      hide:'false'
       }
          this.service1.GetExpiryDetails(params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){

              this.badge=result.respdata.Table2.length;
              this.hidden= this.badge==0 ? true : false;

           // console.log("tab",result.respdata.Table2.length) ;
             //  this.datasource1=result.respdata.Table1;
            }
            else{
             //  this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }
  signout(){
    // // console.log('sessionStorage');
    sessionStorage.clear();
    // console.log('sessionStorage',sessionStorage);
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['']);
    //     return false;
    // this.service.logout()
  }
  notification()
  {

    

		const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
   
    }
    const dialogRef = this.dialog.open(PopupnotificationComponent,dialogConfig);
  }

  // changeTheme() {
  //   this.event.publish('theme:toggle');
  // }

  // onClickDark(event:any){
  //   console.log('Dark')
  //   let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
  //   systemDark.addListener(this.colorTest);
  //   if(event.detail.checked){
  //     document.body.setAttribute('data-theme', 'dark');
  //   }
  //   else{
  //     document.body.setAttribute('data-theme', 'light');
  //   }
  // }

  //  colorTest(systemInitiatedDark) {
  //   if (systemInitiatedDark.matches) {
  //     document.body.setAttribute('data-theme', 'dark');		
  //   } else {
  //     document.body.setAttribute('data-theme', 'light');
  //   }
  // }

  ChangePassword()
  {
		const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
   
    }
    const dialogRef = this.dialog.open(ChangePasswordComponent,dialogConfig);
  }

  lightmode(){
    var isDarkTemp = sessionStorage.getItem('isDark');
    if( isDarkTemp==="0")
    {
      this.isDark=true;
      this.renderer.removeClass(document.body, this.isDark ? "light": "dark");
      this.renderer.addClass(document.body, this.isDark ? "dark": "light");
      themes.current("generic.dark");
      sessionStorage.setItem('isDark',"1");
      //console.log('isdark','istrue');
      this.updateUserTheme("DarkLight",1,"")
      this.darklight="Light"; 
    }
    else
    {
      this.isDark=false;
      this.renderer.removeClass(document.body, this.isDark ? "light": "dark");
      this.renderer.addClass(document.body, this.isDark ? "dark": "light");
      themes.current("generic.light");
      sessionStorage.setItem('isDark',"0");
      this.updateUserTheme("DarkLight",0,"")
      this.darklight="Dark"; 
      //console.log('isdark','isfalse');
    }

    
    //console.log('isdark',isDarkTemp);
  }



  theme(event){
    //console.log('event',event);
    var isthemeTemp = sessionStorage.getItem('theme');
      this.renderer.removeClass(document.body, this._themes[isthemeTemp=="0"?1:isthemeTemp]);
      this.renderer.addClass(document.body, this._themes[event.themeindex]);       
      sessionStorage.setItem('theme',event.themeindex);
      this.updateUserTheme("Theme",event.themeindex,event.themename)
      //localStorage.setItem('theme',event.name);      

    // if( isthemeTemp==="0")
    // {
    //   this.renderer.removeClass(document.body, this._themes[0]);
    //   this.renderer.addClass(document.body, this._themes[1]);       
    //   localStorage.setItem('theme',"1");      
    // }
    // else
    // {      
    //   this.renderer.removeClass(document.body, this._themes[1]);
    //   this.renderer.addClass(document.body, this._themes[0]);       
    //   localStorage.setItem('theme',"0");      
    // }
    //console.log('istheme',isthemeTemp,'event.name',event.themeindex);
  }


  updateUserTheme(flag:string,theme:number,themename:string) {  
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));      
    
    let params = {   
      Flag: flag,   
      CID: JSON.parse(sessionStorage.getItem('cid')),                
      LedgerID:curUser.ledgerid,    
      DarkLight:theme,
      ThemeIndex:theme,
      ThemeName:themename
    };
    this.httpservice.httpPost('/usermgt/userupdatetheme',params)
    .subscribe(
      (data) => {
        let result: any;
        //console.log('data ', data);
        result = data;       
        //console.log('result ', result);
        if (result.errno === 0) {
          //this.notificationservice.success(result.errdesc);          
        }
        else {
         // this.notificationservice.success(result.errdesc);
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });

  }

  // onClickDarkTheme(item:theme) {
  //   console.log('onClickDarkTheme');
  //   this.themeSelect.emit(item);
  // }

  // theme(){
  //   console.log('onClickDarkTheme');
  //   this.themeSelect.emit();
  // }
  // lightmode(){
  //   console.log('onClickDarkTheme');
  //   this.themeSelect.emit();
  // }
  Profile()
  {
		const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
   
    }
    const dialogRef = this.dialog.open(ProfileComponent,dialogConfig);
  }
  toggleBadgeVisibility() {
    this.hidden = true;
    this.notification();
  }
}
