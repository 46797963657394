import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { User } from 'src/app/Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { DialogService } from './../../../Services/OtherServices/dialog.service';
import { LeaveApprovalService } from '../../HR/LeaveApproval/leave-approvalValidate';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { UserService } from '../../Admin/Users/userValidate';
import { DatePipe } from '@angular/common';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';



@Component({
  selector: 'app-resourceallocationbyday',
  templateUrl: './resourceallocationbyday.component.html',
  styleUrls: ['./resourceallocationbyday.component.scss'],
})
export class ResourceallocationbydayComponent implements OnInit {
  dataSource: any;
  LeaveDataGrids: any;

  companies: any;
  Employees: any;
  Department: any;
  LeaveType: any;
  selectedemployees:number;
  employeeid:string[]=[];
  selectedEmpID: any;
  cid:any;
  AllocDayFormGroup: FormGroup;
  AllocDayDataFormGroup: FormGroup;
  isEditable = true; 
  showSpinner:any;
  myControl = new FormControl('', Validators.required);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  viewbtnvisible: boolean = false;
  date: Date; 
  serializedDate = new FormControl((new Date()).toISOString());
  now: Date = new Date();

 constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,
  private generic: GenericService, private service1: UserService,
  public notificationService: NotificationService, private datePipe: DatePipe) 
    {
      this.AllocDayFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] ,    
        // myControl: ['',Validators.required],        
        fcFromDate: ['', Validators.required],
        fcToDate: ['', Validators.required]       
      });
      this.AllocDayDataFormGroup = this.formBuilder.group({        
      });     
    }

    ngOnInit(): void {
      this.cid=JSON.parse(sessionStorage.getItem('cid'));   
      this.PageLoad();
      this.AllocDayFormGroup.controls.fcCompany.setValue(this.cid);
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );     
    }
  
    changesite(e) {
      this.cid=e.value;
      this.PageLoad();
    }
    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();         
        return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
    }
  
    selectedemployee(event) {       
      this.selectedEmpID = event.option.value;
      const str: string = this.selectedEmpID;
      this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));    
    }
  
    onSearchClear(){
       this.AllocDayFormGroup.reset();   
       this.myControl.setValue('');
       this.dataSource='';
       this.AllocDayFormGroup.controls.fcCompany.setValue(this.cid);
    }

    Submit()
    {
      if (this.AllocDayFormGroup.invalid) {
        return;
      }
      let params = {
        CID: this.cid,
        Flag: "LOAD",
        EmployeeID: this.selectedEmpID,  
        ResourceAllocationID: '',  
        FromDate: this.datePipe.transform(this.AllocDayFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
        ToDate: this.datePipe.transform(this.AllocDayFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
        this.datePipe.transform(this.AllocDayFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.AllocDayFormGroup.get('fcToDate').value,"dd-MM-yyyy")
      };
      this.service.httpPost('/ProjectAllocationByDay/GetResourceAllocationByday',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;
            if (result.errno === 0) {       
              this.dataSource = result.respdata.Table;    
              console.log('datasource',this.dataSource)  
            }
            else {
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }    
    logEvent(eventName, e)
    {
    }

    PageLoad() {
      // console.log('ledgerid',sessionStorage.getItem("groupid"));
      let params = {
        CID: this.cid,
        Flag: "LOADDDL",
        EmployeeID:"",              
        Department:"",
        LeaveType: "",
        FromDate:"",
        ToDate:"",
        RtnStatus:"",
        UserID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid")
      };
      this.service.httpPost('/EmpLeaveReport/GetEmpLeaveReport',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;
            if (result.errno === 0) {       
              this.companies = result.respdata.Companyid;                          
              this.Employees = result.respdata.Employee;              
              this.employeeid= _.pluck(this.Employees, 'Name');                
            }
            else {
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }
}
