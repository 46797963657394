import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FormGroup,FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-dialog-colorcode',
  templateUrl: './dialog-colorcode.component.html',
  styleUrls: ['./dialog-colorcode.component.css']
})
export class DialogColorcodeComponent implements OnInit {
  cid: any;
  leavelist: any;
  LID: any;
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;

  colorCtr: AbstractControl = new FormControl(null);

  public listColors = ['primary', 'accent', 'warn'];

  displayedColumns = ['LID', 'name', 'ColorCode'];
  constructor(
    public service: HttpserviceService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<DialogColorcodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.cid = this.data.cid;
      this.leavelist = this.data.leavelist;
     }

  ngOnInit(): void {
    // this.color="red";
  }

  onCloseDialog()
  {
    this.dialogRef.close({event: 'CLEAR'});
  }
  submit()
  {
    const param =
    {
     cid: this.cid,
     Flag: 'EDIT',
     LID: this.LID,
     ColorCode: '#' + this.colorCtr.value.hex,
     UserID: '',
     GroupID: sessionStorage.getItem('groupid')

    };
    this.service.httpPost('/AttendanceReport/setLeaveTypeColor',param)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
      
      if (users.errno === 0){
        this.notificationService.success(':: Submitted successfully');
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    error => {
      console.log('Error : ', error);
    });
  }

}
