import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { Component, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ProjectconfigurationService } from '../projectconfigurationValidate';
import { DatePipe, formatDate } from '@angular/common';
import {DxDataGridComponent} from 'devextreme-angular';
import { User } from 'src/app/Modules/user';


@Component({
  selector: 'app-projectconfiguration',
  templateUrl: './projectconfiguration.component.html',
  styleUrls: ['./projectconfiguration.component.css']
})
export class ProjectconfigurationComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  flag: string;
  step = 0;
  dayslist: any;
  merchantlist: any;
  locationlist: any;
  dataSource: any;
  dataSource1: any;
  ProjectStatuslist:any;
  Rolelist: any;
  model: any;
  temp= 0;
  // events: Array<string> = [];
  // dataSource2:any;
  hours: 0;
  dayhours: 0;
  CID:any;
  public filteredList5

  editUsr: any; oldUsr: any; editdisabled: boolean;
  Days = {
    store: {
        type: 'array',
        data: [
            { id: 0, name: 'Active' },
            { id: 1, name: 'Locked' },

            // ...
        ],
        key: 'id'
    }};

    displayedColumns = ['sno', 'Name', 'status', 'Action'];
  constructor(private datePipe: DatePipe,
    public service: ProjectconfigurationService,
    public notificationService: NotificationService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<ProjectconfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.flag = this.data.flag;
      this.temp = this.data.pid;
      // this.dataSource=this.data.entity;
      // this.dataSource1=this.data.entity;
      this.Rolelist = this.data.Rolelist;
      this.CID=this.data.cid;
     }

  ngOnInit(): void {

    this.dayslist = this.Days.store.data;
  // this.getmerchantlist();
  // this.getlocationlist();
  this.getprojectconfig();

  }
  public list: any[] = [];
addContrat(e,code: string, label: string) {
  if(e.source._selected==true)
  {
    this.list.push({ code, label });
  }
  else 
  {
    
    this.list.splice(this.list.findIndex(item => item.code === code), 1)
     
  }

}
removeContract(i: number) {
this.list.splice(i, 1);
}
  getprojectconfig()
  {
    const paramsFormValue = this.service.form.value;

    const params = {
      cid: this.CID,
      flag: 'PROJECTDETAILS',
      projectID: this.temp,
      dayshours: '0'

        };
        this.service.getprojectconfig(params)
        .subscribe(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.merchantlist = result.respdata.Table;
            this.locationlist = result.respdata.Table1;
            this.dataSource = result.respdata.Table3;
            this.dataSource1 = result.respdata.Table3;
            this.ProjectStatuslist =result.respdata.Table4;
           //  console.log("ggNNN",this.ProjectStatuslist);

             this.filteredList5= this.merchantlist.slice();
              //console.log('HII',this.filteredList5)
            // paramsFormValue.location=["101","102"]
            if (this.flag == 'EDIT')
            {
              const params = Object.assign(result.respdata.Table2);
             // console.log("params",params);
              this.service.populateForm(params);
            }

            // var user = {
            // location:["101","102"]
            // }
            // form.setValue(user);
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
  }
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  onCloseDialog()
  {
    // this.service.form.reset();
    // this.service.initializeFormGroup();
    // this.dialogRef.close({event: 'CLEAR'});

    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    .afterClosed().subscribe(res => {
      if (res) {
       this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close({event: 'CLEAR'});
      }
    });

  }
  onClose(model){
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close({event: this.flag, data: model});
  }
  onClear()
  {
    this.dataSource=[];
    this.service.form.reset();
    this.service.initializeFormGroup();
    
  }
  onSubmit()
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    
    if (this.service.form.valid){
      const curDate = new Date();
      const paramsFormValue = this.service.form.value;

      // var myObj=this.dataSource1

      // delete this.dataSource1.DaysHours;
      // delete this.dataSource1.__KEY__;

      // delete myObj.DaysHours;
      // delete myObj.__KEY__;
     
      if ( this.dataSource1.length == 0)
      {
        this.dataSource1 = [{Role: '', hours: ''}];
      }


      paramsFormValue.Sdate = this.datePipe.transform(paramsFormValue.Sdate, 'yyyy-MM-dd');
      paramsFormValue.Edate = this.datePipe.transform(paramsFormValue.Edate, 'yyyy-MM-dd');
      // console.log("sss",paramsFormValue );
      const  paramsExtra = {
        cid: this.CID,
        flag: this.flag,
        // LedgerID: this.ledgerid,

        UserID: curUser.username,
        GroupID: sessionStorage.getItem('groupid'),
        listarray: this.dataSource1
        // Year:this.requestyear,
        // Type:this.type

      };
      const params = Object.assign(paramsFormValue, paramsExtra);
      // console.log("save",params);
      this.service.setprojectconfig(params)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
          // console.log('create:', users.errno);

          if (users.errno === 0){
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.notificationService.success('Submitted successfully');
            this.onClose(this.model);

          }
          else{
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
    }
  }

  getmerchantlist(){

    const params = {
      cid: this.CID,
      tablename: 'MERCHANT',
      formtype: '\'\'',
      condition: '\'\'',
      menuid: 'default',
      withinactive: false
        };
        this.service.getemployeelist(params)
        .subscribe(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.merchantlist = result.respdata.employees;
            // console.log("hh", this.merchantlist);
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });

      }
      logEvent(eventName, e)
      {
        if (eventName == 'EditingStart')
        {
          // console.log("RowUpdating",e.data.Hours);
          if (e.data.Hours !== undefined)
          {
            this.hours = e.data.Hours;
          }

          if (e.data.DaysHours !== undefined)
          {
            this.dayhours = e.data.DaysHours;
          }


        }
        if (eventName == 'RowUpdated' || eventName == 'RowInserting')
        {
          const paramsFormValue = this.service.form.value;

          if (e.data.DaysHours !== undefined)
          {

          if ( e.data.DaysHours != 0)
          {
             if (e.data.DaysHours != this.dayhours )
             {
              e.data.Hours = e.data.DaysHours * paramsFormValue.DayHours ;
             }
             else{ e.data.DaysHours = e.data.Hours / paramsFormValue.DayHours  ; }

            // console.log("va", e.data);
            // e.data.Hours=e.data.DaysHours * 8 ;
          }
          else{
            e.data.Hours = e.data.Hours;
            e.data.DaysHours = e.data.Hours / paramsFormValue.DayHours  ;
          }
          }
         else
          {
            e.data.Hours = e.data.Hours;
            e.data.DaysHours = e.data.Hours / paramsFormValue.DayHours  ;
          }
        }
       
      }
  
  getlocationlist(){
        const params = {
          cid: this.CID,
          tablename: 'Location',
          formtype: '\'\'',
          condition: '\'\'',
          menuid: 'default',
          withinactive: false
            };
            this.service.getlocationlist(params)
            .subscribe(data => {
              let result: any;
              result = data;
              if (result.errno === 0){
                this.locationlist = result.respdata.employees;
                // console.log("ll", this.locationlist);
              }
              else{
                this.notificationService.success(result.errdesc); //':: Problem details');
              }
            },
            (error: AppError) => {
              if (error instanceof BadInput)
                console.log('Error: ', error);
                // this.service.form.setErrors(error.originalError);
              else throw error;
            });
          }

}


