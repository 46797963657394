import { DatePipe } from '@angular/common';
import { User } from '../../../../Modules/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';


@Component({
  selector: 'app-ticketlinkconfig',
  templateUrl: './ticketlinkconfig.component.html',
  styleUrls: ['./ticketlinkconfig.component.scss'],
})
export class TicketlinkconfigComponent implements OnInit {

  CID:any;
  TypeID:any;
  Type:any;
  OppType:any;
  TicketLinkFormGroup: FormGroup; 
  Flag:any;
  CurLedgerID:any;

  constructor(
    private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<TicketlinkconfigComponent>,private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any)
   {

      console.log('dialogdata',this.data)

      this.CID=this.data.cid;
      this.Flag=this.data.Flag;
      this.TypeID=this.data.TypeID;
      this.Type=this.data.Type;
      this.OppType=this.data.OppType;     

      this.TicketLinkFormGroup = this.formBuilder.group({  
        fcType:['', Validators.required],     
        fcOppType:['',Validators.required]   
      });  

      if(this.Flag !='ADD')
      {
        this.TicketLinkFormGroup.controls.fcType.setValue(this.Type);
        this.TicketLinkFormGroup.controls.fcOppType.setValue(this.OppType);    
      }
   }

  ngOnInit() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }


  onCloseDialog()
  {    
    this.dialogRef.close();
  }

  Submit()
  {
    let params = { 
      CID:this.CID,
      Flag: this.Flag, 
      TypeID:this.TypeID, 
      Type:this.TicketLinkFormGroup.get('fcType').value,   
      OppType:this.TicketLinkFormGroup.get('fcOppType').value,   
      UserID:this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')      
     };
    this.service.httpPost('/TicketLinkConfig/SeTicketLinkConfig',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
}
