import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { GenericService } from '../../../../Services/OtherServices/generic.service';
import { UserService } from '../../../../components/Admin/Users/userValidate';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { User } from '../../../../Modules/user';

@Component({
  selector: 'app-effortauthconfig',
  templateUrl: './effortauthconfig.component.html',
  styleUrls: ['./effortauthconfig.component.scss'],
})
export class EffortauthconfigComponent implements OnInit {

  isEditable = true; 
  @ViewChild('stepper') stepper;
  //AuthFormGroup:FormGroup;
  AuthViewFormGroup:FormGroup;
  Authorizer:any;
  public filteredList5;

  public allEngineers: Employee[] = [
  
  ];
  public chipSelectedEmployees: Employee[] = [];
  public filteredEmployees: Observable<String[]>;
  employee:any;
  Companies:any;
  cid:any;
  
  private allowFreeTextAddEngineer = false;

  public fcEmployee = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  CID:any;
  GoalID:any;
  @ViewChild('engineerInput') engineerInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,private generic: GenericService,
    public notificationService: NotificationService, private datePipe: DatePipe, private service1: UserService) 
    { 
      // this.AuthFormGroup = this.formBuilder.group({  
         
      //  });
         this.AuthViewFormGroup = this.formBuilder.group({    
          fcCompany:[''],       
          fcAuthorizer: ['', Validators.required]           
      });

    }


  ngOnInit() {
     this.cid=JSON.parse(sessionStorage.getItem('cid')); 

      this.getEffortAuthdetails();
      this.AuthViewFormGroup.controls.fcCompany.setValue(this.cid);
  }

  changesite(e) {
    this.cid=e.value;
    this.getEffortAuthdetails();
  }

  changeledger(e) {
   
   // this.getEffortAuthdetails();

   let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   let params = {
      cid:this.cid,
      flag:'Emplist',      
      LedgerID:this.AuthViewFormGroup.get('fcAuthorizer').value      
     // LedgerID:0
    }
   
    const query = `cid=${params.cid}&flag=${params.flag}&LedgerID=${params.LedgerID}`;
       this.service.httpGet('/EffortAuth/GetAuthList?',query)
       .subscribe(data => {
         let result: any;
         result=data;
         if (result.errno === 0){

          this.Companies = curUser.dtsiteid;    
         let emplist= result.respdata.Employeelist;
         this.chipSelectedEmployees= result.respdata.selectedEmployee;//_.pluck(result.respdata.selectedEmployee,'Name'); 
         
          this.Authorizer=result.respdata.Employeelist;
         

          this.filteredList5= this.Authorizer.slice();

          this.allEngineers= emplist//_.pluck(emplist, 'Name');    
          
          this.filteredEmployees = this.fcEmployee.valueChanges.pipe(
            startWith(null),
            map(engineerName => this.filterOnValueChange(engineerName))
          );

         }
         else{
           this.notificationService.success(result.errdesc); //':: Problem details');
         }
       }, 
       (error: AppError) => {
         if (error instanceof BadInput)           
           console.log(error);
         else throw error;       
       });
  }

  public addEngineer(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddEngineer) {     
     
      return;
    }

   
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || '').trim()) {
      this.selectEngineerByName(value.trim());
    }

    this.resetInputs();
  }

  public removeEngineer(engineer: Employee): void {
    const index = this.chipSelectedEmployees.indexOf(engineer);
    if (index >= 0) {
      this.chipSelectedEmployees.splice(index, 1);
      this.resetInputs();
    }
  }

  public engineerSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectEngineerByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {  
    this.engineerInput.nativeElement.value = '';   
    this.fcEmployee.setValue(null);
  
  }

  
  private filterOnValueChange(engineerName: string | null): String[] {
    let result: String[] = [];
   
    let allEngineersLessSelected = this.allEngineers.filter(
      (engineer) => this.chipSelectedEmployees.indexOf(engineer) < 0
    );
    if (engineerName) {
      result = this.filterEngineer(allEngineersLessSelected, engineerName);
    } else {
      result = allEngineersLessSelected.map((engineer) => engineer.Name);
    }
    return result;
  }

  private filterEngineer(
    engineerList: Employee[],
    engineerName: String
  ): String[] {
    let filteredEngineerList: Employee[] = [];
    const filterValue = engineerName.toLowerCase();
    let engineersMatchingEngineerName = engineerList.filter(
      (engineer) => engineer.Name.toLowerCase().indexOf(filterValue) === 0
    );
    if (engineersMatchingEngineerName.length || this.allowFreeTextAddEngineer) {
      
      filteredEngineerList = engineersMatchingEngineerName;
    } else {
      
      filteredEngineerList = engineerList;
    }
    
    return filteredEngineerList.map((engineer) => engineer.Name);
  }

  private selectEngineerByName(engineerName) {
    let foundEngineer = this.allEngineers.filter(
      (engineer) => engineer.Name == engineerName
    );
    if (foundEngineer.length) {
      
      this.chipSelectedEmployees.push(foundEngineer[0]);
    } else {
      
      let highestEmployeeId = Math.max(
        ...this.chipSelectedEmployees.map((engineer) => engineer.LedgerID),
      
      );
      this.chipSelectedEmployees.push({
        Name: engineerName,
        LedgerID: highestEmployeeId + 1,
      });
    }
  }

  getEffortAuthdetails()
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
       let params = {
          cid:this.cid,
          flag:'Emplist',      
        //  LedgerID:this.AuthViewFormGroup.get('fcAuthorizer').value      
          LedgerID:0
        }
        
        const query = `cid=${params.cid}&flag=${params.flag}&LedgerID=${params.LedgerID}`;
           this.service.httpGet('/EffortAuth/GetAuthList?',query)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

              this.Companies = curUser.dtsiteid;    
             let emplist= result.respdata.Employeelist;
             this.chipSelectedEmployees= result.respdata.selectedEmployee;//_.pluck(result.respdata.selectedEmployee,'Name'); 
             
              this.Authorizer=result.respdata.Employeelist;
             

              this.filteredList5= this.Authorizer.slice();

              this.allEngineers= emplist//_.pluck(emplist, 'Name');    
             
              this.filteredEmployees = this.fcEmployee.valueChanges.pipe(
                startWith(null),
                map(engineerName => this.filterOnValueChange(engineerName))
              );
 
             }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }

    onSearchClear(){
       this.AuthViewFormGroup.reset();       
       this.AuthViewFormGroup.controls.fcCompany.setValue(this.cid);
       this.chipSelectedEmployees=[];
    }

  Refresh()
  {
    this.getEffortAuthdetails();
  }

  onSubmit()
  {
    let employee=this.chipSelectedEmployees;
   
    this.chipSelectedEmployees=_.pluck(this.chipSelectedEmployees, 'Name');
    

    let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      cid: this.cid,
      flag:"ADD",
      LedgerID:this.AuthViewFormGroup.get('fcAuthorizer').value,
      emplist:this.chipSelectedEmployees
  
    };
    this.service.httpPost('/EffortAuth/SetEffortAuthorizer?',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;

      if (users.errno === 0){
        this.chipSelectedEmployees=employee;
        this.notificationService.success('Submitted successfully');       
        this.onSearchClear();   
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }

}

export interface Employee {
  Name: string;
  LedgerID: number;
}