import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';

@Component({
  selector: 'app-voucherfor-hr',
  templateUrl: './voucherfor-hr.component.html',
  styleUrls: ['./voucherfor-hr.component.scss'],
})
export class VoucherforHRComponent implements OnInit {

  Wageslist:any;
  WAGES_:any;
  total:any=0;
  constructor(private service:HttpserviceService,public dialogRef: MatDialogRef<VoucherforHRComponent>, @Inject(MAT_DIALOG_DATA) public data: any)
  { 
   console.log('data',data)
   this.WAGES_=  data.WAGES;

   

   const params = {
    cid: data.cid,
    LedgerID:data.LedgerID,
    _AdvType:data.AdvType,
    _FormType:data.FormType,
    objmain:{
      str_PSRef:'',
      int_BusinessPeriodID:105


    }
  };   
  
  
  this.service.httpPost('/Payslip/GetHRDetialsForVou?' ,params)   
  .subscribe(data =>{
    var result = data as any;
    result = data;      
  
    console.log("payslip",result.respdata)
   

     this.Wageslist=result.respdata.Payslipobj.dt_Wages;
  
  
     console.log(' data.WAGES', this.WAGES_)
     console.log('this.Wageslist',this.Wageslist)

     this.WAGES_.forEach(item1 => {
      const match = this.Wageslist.find(item2 => item1.LedgerID === item2.LedgerID && item1.ReqID === item2.ReqID);
      if (match) {
          console.log('match',match)
          // Update PayNow and AmountType
           match.PayNow= item1.PayNow;
          //item1.AmountType = match.AmountType;
      }
  });
   
       
      
  });
  
  }

  ngOnInit() {}

  onEditorPreparing(e) {
    if (e.parentType === "dataRow" && e.dataField === "PayNow") {
        e.editorOptions.onValueChanged = (args) => {
            console.log("New cell value:", args.value);
            console.log("Row data:", e.row.data);
            // Here, you can access the particular column value via e.row.data.YourColumnName
        };
    }
}


  onRowUpdating(event)
  {
    console.log("event", event);
    console.log("FullPay", event.oldData.FullPay);

    

    const jsonData: string =JSON.stringify(event.newData) ;
    const data: { [key: string]: any } = JSON.parse(jsonData);
    const columnName: string = Object.keys(data)[0];
    const value: any = data[columnName];

    
    if (value==true && columnName=='FullPay')
    {
      event.oldData.PayNow=event.oldData.BalAmt;
      console.log("this.Wageslist", this.Wageslist);

    }
    else if(value!=0 && columnName=='PayNow')
    {

      const sumOfPayNow = this.Wageslist.reduce((sum, item) => sum + item.PayNow, 0);

    console.log("sumOfPayNow", sumOfPayNow+value);
 

       this.total=this.total+value
    }
    else 
    {
      event.oldData.PayNow=0;
    }

 

  }
  applyFilter() {
    // Implement filter logic here
    // You may use a service to communicate with the parent component or emit an event
    let param={'Wageslist':this.Wageslist}
    this.dialogRef.close(param);
  }

  closeDialog() {
    let param={'Wageslist':this.Wageslist}
    this.dialogRef.close(param);
  }

}
