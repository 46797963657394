import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { User } from 'src/app/Modules/user';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { UserService } from '../../Admin/Users/userValidate';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';

@Component({
  selector: 'app-leavetypeconfiguration',
  templateUrl: './leavetypeconfiguration.component.html',
  styleUrls: ['./leavetypeconfiguration.component.scss'],
})
export class LeavetypeconfigurationComponent implements OnInit {
  dataSource:any;
  LeaveDetails:any;
  Companies:any;
  LeaveID:any;
  Status:any;
  carryForward:any;
  DayType:any;
  PaidLeave:any;
  checkBoxesMode: string;
  isTzidVisible: boolean ;
  isStatusVisible: boolean ;
  isALPIDVisible: boolean ;
  CurLedgerID:number;
  onSelectionChange:boolean;
  cid:any;
   rowcount:any;
   addbtnvisible: boolean = false;
   editbtnvisible: boolean = false;
   deletebtnvisible: boolean = false;
   viewbtnvisible: boolean = false;
  
   SelectedValue:string;
  LeaveTypeMaster:FormGroup;

  options: string[] = ['Paid', 'UnPaid'];
  
  //displayedColumns: string[] = ['AccrualType', 'Day','Month','NoofDays','AffectedYear','action'];
  dataSource2 = new MatTableDataSource<any>();
  VOForm: FormGroup;
  filteredOptions:Observable<string[]>;
  myControl = new FormControl();  
  isLoading = true;

  setWeekEnd: '1' | '0' = '0';
  setHolidays: '1' | '0' = '0';
  setEncash: '1' | '0' = '0';

  dynamicArray: Array<DynamicGrid> = [];  
  newDynamic: any = {}; 
  Condition2:boolean;
  Condition:boolean;

  constructor(
    private formBuilder: FormBuilder,
    private service:HttpserviceService,
    private NotificationService: NotificationService,    
    private service1: UserService, 
    private generic: GenericService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LeavetypeconfigurationComponent>,
    private dialogService: DialogService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    {
    this.LeaveTypeMaster = this.formBuilder.group
    ({  
       fcCompany: ['', Validators.required] ,             
       //fcLeaveID: ['', Validators.required] ,
       fcLeaveName:['',Validators.required],
       fcDescription:['',Validators.required],
       fcCarryForward:['',Validators.required],
       fcLeaveCode:['',Validators.required],
       fcEncashment:['',Validators.required],
       fcStatus:['',Validators.required],
       fcLeaveType:['',Validators.required],
       fcDayType:[''],
       fcCFNoOfDays:[''],
       fcCFExpiry:[''],
       fcWeekEnds:[''],
       fcHolidays:['']
      //  fcEnExpiry:['',Validators.required]
      //  fcEnNoOfDays:['',Validators.required],
   }); 
   
   //this.checkBoxesMode = 'always'
   }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.getddllist();  
    this.isTzidVisible=false;
    this.isStatusVisible=false;
    this.isALPIDVisible=false;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.LeaveTypeMaster.controls.fcCompany.setValue(this.cid);
    this.LeaveTypeMaster.controls.fcCarryForward.setValue('Limit');
    this.LeaveTypeMaster.controls.fcDayType.setValue('WorkingDays');
    this.LeaveTypeMaster.controls.fcLeaveType.setValue('Paid');
    this.LeaveTypeMaster.controls.fcStatus.setValue(1);

    this.newDynamic = {accrual: "StartOfYear", days: 0,NoOfMonthFromJoiningDate:1};  
    this.dynamicArray.push(this.newDynamic);  
    
  }

  onClear()
  {
    this.LeaveTypeMaster.reset();          
    //this.getddllist(); 
    //this.dataSource='';  
    this.LeaveDetails='';
    this.LeaveTypeMaster.controls.fcCompany.setValue(this.cid);
  }

  selectedLeaveName(event) {        
    // this.selectedEmpID = event.option.value;
    // const str: string = this.selectedEmpID;   
    //console.log('selected leave nae', event.option.value);
    //this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
    // console.log('selectedEmpID', this.selectedEmpID);    
    this.SelectedValue=event.option.value
    let params = {
      CID: this.cid,
      LeaveName:event.option.value,
      Flag: "SelectLeaveData",         
    };
 //console.log('params',params)
    this.service.httpPost('/leavetypemaster/GetLeaveTypeMaster',params)
      .subscribe (
        (data) => { 
          let result: any;
          result = data;         
      //    console.log('data ', data);
          if (result.errno === 0) {                                     
            //this.LeaveID = result.respdata.LeaveDetails; 
            this.LeaveTypeMaster.controls.fcLeaveName.setValue(result.respdata.LeaveDetails[0].LeaveName);    
            this.LeaveTypeMaster.controls.fcCarryForward.setValue(result.respdata.LeaveDetails[0].CarryForwardDays);
            this.LeaveTypeMaster.controls.fcDayType.setValue(result.respdata.LeaveDetails[0].LeaveDayType);
            this.LeaveTypeMaster.controls.fcLeaveType.setValue(result.respdata.LeaveDetails[0].LeaveType);
            this.LeaveTypeMaster.controls.fcDescription.setValue(result.respdata.LeaveDetails[0].LeaveDescription);
            this.LeaveTypeMaster.controls.fcLeaveCode.setValue(result.respdata.LeaveDetails[0].LeaveCode);
           
            this.LeaveTypeMaster.controls.fcCFNoOfDays.setValue(result.respdata.LeaveDetails[0].NoofDays);
            this.LeaveTypeMaster.controls.fcStatus.setValue(result.respdata.LeaveDetails[0].Status);
            //this.LeaveTypeMaster.controls.fcCFNoOfDays.setValue('Paid');
            this.setEncash=result.respdata.LeaveDetails[0].Encashment;
            this.setWeekEnd =result.respdata.LeaveDetails[0].SetWeekEnd;
            this.setHolidays=result.respdata.LeaveDetails[0].SetHolidays;
            this.dynamicArray=result.respdata.AccrualDat;                                         
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });

  }

  getddllist()
  {
    let params = {
      CID: this.cid,
      LeaveName:0,
      Flag: "DDL_ADD",         
    };
    this.service.httpPost('/leavetypemaster/GetLeaveTypeMaster',params)
      .subscribe (
        (data) => { 
          let result: any;
          result = data;         
         // console.log('data ', data);
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.LeaveID = result.respdata.LeaveType;
            // this.Leave =result.respdata.LeaveType;
            this.carryForward = result.respdata.CarryForward;  
            this.DayType= result.respdata.DayType;  
            this.Status = result.respdata.Status;
            this.dataSource=result.respdata.CarryForward;     
            this.LeaveDetails=result.respdata.GridData;        
            this.rowcount=result.respdata.Count;     
            this.getUserList();                           
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
          // this.service.form.reset();
          // this.service.initializeFormGroup();
          this.dialogRef.close({event: 'CLEAR'});
       }
      });
  }
  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_5",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;
      this.editbtnvisible = btnVisilityData.editflag;
      this.deletebtnvisible = btnVisilityData.deleteflag;
      this.viewbtnvisible = btnVisilityData.viewflag;
   
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }


  Submit()
  {
   // console.log('Row',this.newDynamic);

    let params = {
      CID: this.cid,      
      Flag: this.data.Flag,//"ADD",     
      LeaveTypeID: this.SelectedValue,
      LeaveName: this.LeaveTypeMaster.get('fcLeaveName').value,
      LeaveType: this.LeaveTypeMaster.get('fcLeaveType').value,
      LeaveCode: this.LeaveTypeMaster.get('fcLeaveCode').value,
      LeaveDescription: this.LeaveTypeMaster.get('fcDescription').value,
      CarryForward: this.LeaveTypeMaster.get('fcCarryForward').value,
      CarryForwardDays: this.LeaveTypeMaster.get('fcCFNoOfDays').value,
      //CarryForwardExpiry: parseInt(this.LeaveTypeMaster.get('fcCFExpiry').value),
      Encashment:parseInt(this.LeaveTypeMaster.get('fcEncashment').value),
      LeaveDayType: this.LeaveTypeMaster.get('fcDayType').value,
      SetWeekEnd: parseInt(this.LeaveTypeMaster.get('fcWeekEnds').value),
      SetHoliday: parseInt(this.LeaveTypeMaster.get('fcHolidays').value),
      Status : this.LeaveTypeMaster.get('fcStatus').value, 
      UserID :this.CurLedgerID,
      GroupID:"",
      DtAccrualLeaveData:this.dynamicArray
    };
   // console.log('param',params)
    this.service.httpPost('/leavetypemaster/SetLeaveTypeMaster',params)
      .subscribe (
        (data) => { 
          let result: any;
          result = data;         
         // console.log('data ', data);
          if (result.errno === 0) {       
            this.NotificationService.success(result.errdesc);     
          }
          else {
            this.NotificationService.warn(result.errdesc); 
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            this.NotificationService.warn(error);
          else throw this.NotificationService.warn(error);     
        });
  }

 


  addRow() {    
    this.newDynamic = {accrual: "", days: "",NoOfMonthFromJoiningDate:""};  
      this.dynamicArray.push(this.newDynamic);  
      //this.toastr.success('New row added successfully', 'New Row');  
      //this.NotificationService.success('New row added successfully');
      //console.log(this.dynamicArray);  
      return true;  
  }  

  deleteRow(index) {  
      if(this.dynamicArray.length ==1) {  
        this.NotificationService.warn("Can't delete the row when there is only one row");
       // this.toastr.error("Can't delete the row when there is only one row", 'Warning');  
          return false;  
      } else {  
          this.dynamicArray.splice(index, 1);  
          this.NotificationService.warn("Row deleted successfully");
          // this.toastr.warning('Row deleted successfully', 'Delete row');  
          return true;  
      } 
  }
 
}


export interface AccrualColumn {
  AccrualType: string;
  Day: number;
  Month: number;
  NoofDays: number;
  AffectedYear:string;
}

export class DynamicGrid{     
  accrual:string;  
  days:number;  
  NoOfMonthFromJoiningDate:number;  
}

const ELEMENT_DATA: AccrualColumn[] = [
  {AccrualType: 'Year', Day: 1, Month: 6, NoofDays: 15,AffectedYear:'Current'},  
 
];

