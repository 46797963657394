import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-aduitview',
  templateUrl: './aduitview.component.html',
  styleUrls: ['./aduitview.component.scss'],
})
export class AduitviewComponent implements OnInit {
  cid:any;
  showSpinner:any;
  datasource:any;
  // wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  // fileName: string = 'SheetJS.xlsx';
  
  constructor(private service: HttpserviceService,public notificationService: NotificationService ) { }

  ngOnInit() 
  {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
  }

  onFileChange(ev) {

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
  
    reader.onload = (event) => {
      const data = reader.result;
  
      workBook = XLSX.read(data, { type: 'binary', cellDates: true });
      console.log('workBook', workBook);
      const wsname: string = workBook.SheetNames[0];
      const ws: XLSX.WorkSheet = workBook.Sheets[wsname];
      jsonData = XLSX.utils.sheet_to_json(ws, { dateNF: 'yyyy-mm-dd', raw: false, });
  
      console.log('rowdata',jsonData)
      const dataString = JSON.stringify(jsonData)
      // const dataString = JSON.stringify(jsonData, (key, value) => {
      //     if (typeof value === 'string') {
            
      //         // Parse the string as a date
      //         const dateValue = new Date(value);
  
      //         // Check if it's a valid date
      //         if (!isNaN(dateValue.getTime())) {
      //           console.log('value', value);
      //             console.log('Original date:', dateValue);
                  
      //             // Assuming 5.5 hours is the time difference for Indian Standard Time (IST)
      //             const adjustedDate = new Date(dateValue.getTime() - 5.5 * 60 * 60 * 1000);
  
      //             return adjustedDate.toISOString();
      //         }
      //     }
      //     return value;
      // });
  
      //console.log(dataString);
    
      //document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      //this.setDownload(dataString);
      console.log('amjath',dataString)


       
    let params = {
      cid:101,
       JsonString:dataString,
       Flag:"Import",
      // _ErrString:"test"

    };
    this.showSpinner=true;
    console.log('parameters',params);
    this.service.httpPost('/AuditTableImport/Import1',params)
      .subscribe (
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;
          if (result.errno === 0) {       
                            
            this.notificationService.success(result.errdesc);
                           
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
    }
    reader.readAsBinaryString(file);
  }


  Submit(){
   
    this.showSpinner=true;
    let params = {
      cid:101,
       JsonString:"",
       Flag:"Load",
      // _ErrString:"test"

    };
    this.showSpinner=true;
    console.log('parameters',params);
    this.service.httpPost('/AuditTableImport/GetAuditTable',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;          
          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
            this.datasource = result.respdata.datasource;     
           
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
 

}
