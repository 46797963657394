import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/Modules/user';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';

@Component({
  selector: 'app-dialogforreports',
  templateUrl: './dialogforreports.component.html',
  styleUrls: ['./dialogforreports.component.css']
})
export class DialogforreportsComponent {

   curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   displayedColumns: string[] = ['Description'];
   datasource:any;
   selectedRow: any;

  constructor(
    
    private service:HttpserviceService, private NotificationService:NotificationService,
    public dialogRef: MatDialogRef<DialogforreportsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  this.Getstructure();

    }
    ngOnInit(): void {
      this.dialogRef.updateSize('50%');
      
    }
  onSubmit() {
     if(this.selectedRow!==undefined)
     {
      this.dialogRef.close(this.selectedRow);  // Return form data on submit
     }
    
    
  }

  onCancel(): void {
    this.dialogRef.close();  // Close dialog without saving
  }

  async Getstructure()
  {
    let params = {
      cid:101,
      flag:'Report',
      BusinessPeriodID:0,
      MenuID:'STS_203',
      GroupID:this.curUser.groupid
    
       }
       const query = `cid=${params.cid}&flag=${params.flag}&BusinessPeriodID=${params.BusinessPeriodID}&MenuID=${params.MenuID}&GroupID=${params.GroupID}`;
       this.service.httpGet('/Report/GetReports?',query)
          .toPromise().then(data => {
            let result: any;
            result=data;
            if (result.errno === 0){

             console.log('Employeelist',result.respdata)

             this.datasource=result.respdata;

             
              
            }
            else{
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
         
  }
  onRowClicked(row: any): void {
    console.log('Selected Row: ', row); // This logs the selected row's data
    this.selectedRow = row;  // Store the selected row
  }

}
