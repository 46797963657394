import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { User } from 'src/app/Modules/user';
import { FormGroup,FormControl,Validators, RequiredValidator, FormBuilder } from '@angular/forms';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck, filter } from 'rxjs/operators';
import { DatePipe, JsonPipe } from '@angular/common';
import * as moment from "moment"; 
import { MultiselectwithsearchComponent } from 'src/app/components/Control/multiselectwithsearch/multiselectwithsearch.component';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-kanbanboardsearch',
  templateUrl: './kanbanboardsearch.component.html',
  styleUrls: ['./kanbanboardsearch.component.css']
})
export class KanbanboardsearchComponent {
  CID: any;
  flag: any;
  checked = false;
  TicketID: any = 0;
  form_template: any;
  myFormGroup: FormGroup;

  companies: any;
  CurLedgerID: any
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  empid: string[] = [];
  employeelist: any;
  Tasklistsub: any;
  visible: boolean = false
  template: any;
  ActiviteID: any;
  LoginFlag: any;
  Comment: any = '';
  Commentvisible: boolean = true;
  isEditable = false;
  temp4: any;
  temp = [];
  TemplateGroupID: any
  TemplateID: any
  LoadCombo: any;
  ticketlistM1: any;
  eventID: any;
  public filteredList = "";

  showSpinner: any;
  UserID: any;
  valueContent: string;
  editorValueType: string;

  isDisabled: boolean = true;

  originalDate: string = "Oct 17 2023 12:00AM";
  convertedDate: string;
  sessionFilterData: any;

  multiselectinputvalue: any;

  LedgerID: any;
  keycolumn: any;
  TextDataFlag: any;
  BoardID: any;
  AddFilterColumnFromGroup: FormGroup;
  TemplateColumnDt: any;
  filteredList2: { tag: string, LabelName1: string }[] = [];

  constructor(private datePipe: DatePipe,
    public service: HttpserviceService, public notificationService: NotificationService, private httpClient: HttpClient,
    public dialogRef: MatDialogRef<KanbanboardsearchComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    //console.log('kanbansearchdata',this.data)
    this.template = this.data.template;
    console.log('this.template',this.template)
    this.TemplateID = this.data.TemplateID
    this.flag = this.data.flag;
    this.UserID = this.data.userid;
    this.LedgerID = this.data.ledgerid;
    this.CID = this.data.cid;
    this.keycolumn = this.data.keycolumn;
    this.TextDataFlag = this.data.textdataFlag;
    this.BoardID = this.data.BoardID;
    this.sessionFilterData = this.data.dtSelectedFilterData;
    const idSet = new Set(this.template.map(item => item.ID));
    this.TemplateColumnDt = this.data.templateColumns;
    this.filteredList2 = this.TemplateColumnDt.filter(item => !idSet.has(item.tag));
    this.LoadKanbanBoardFilterSelection();
    this.AddFilterColumnFromGroup = this.formBuilder.group({
      AddFilterColumnCtrl: [''],                
    });    
  }  


  LoadKanbanBoardFilterSelection() {
    let newList1 = [];
    let temp

    for (var i = 0; i < this.template.length; i++) {
      temp = { "type": this.template[i].ControlType, "ID": this.template[i].ID, "label": this.template[i].LabelName, 
        "Visibility": this.template[i].parent == "0" ? true : false, "parent": this.template[i].parent,
         "options": (JSON.parse(this.template[i].Value)) }
      newList1.push(temp);
    }
    
    this.form_template = newList1
    let group = {}
    this.form_template.forEach(input_template => {
      group[input_template.ID] = new FormControl('');
      if (input_template.type === 'multiselect')
        group[input_template.ID + 'ID'] = new FormControl('');
    })
    this.myFormGroup = new FormGroup(group);
  }

  ngOnInit() {
    this.sessoinfilterData();
  }  

  sessoinfilterData() {

    // console.log('this.sessionFilterData ',JSON.parse(this.sessionFilterData) )
    // console.log('this.myFormGroup ',this.myFormGroup )
    if (this.sessionFilterData != null ) {
      let TaskListSub = JSON.parse(this.sessionFilterData);
      for (var i = 0; i < TaskListSub.length; i++) {
        let ColumnName = TaskListSub[i].ColumnName

        let temp = this.form_template.findIndex(d => d.ID === ColumnName);
        if (TaskListSub[i].ControlType == 'multiselect') {
          // console.log('i:',i )
          // console.log('TaskListSub[i].ddlData[i].Displaymember',TaskListSub[i].ddlData[0].Displaymember)
          // console.log('TaskListSub[i].ddlData[i].Valuemember',TaskListSub[i].ddlData[0].Valuemember)
          this.myFormGroup.controls[ColumnName].setValue(TaskListSub[i].ddlData[0].Displaymember);
          this.myFormGroup.controls[ColumnName + 'ID'].setValue(TaskListSub[i].ddlData[0].Valuemember);

    //       const result = TaskListSub[i]
    // .filter(item => item.ColumnName === ColumnName)
    // .flatMap(item => item.ddlData.DisplayValue);
    // const result2 = TaskListSub[i]
    // .filter(item => item.ColumnName === ColumnName)
    // .flatMap(item => item.ddlData.Value2);
    // this.myFormGroup.controls[ColumnName].setValue(TaskListSub[i].DisplayValue);
    // this.myFormGroup.controls[ColumnName + 'ID'].setValue(TaskListSub[i].Value2);

          
        }
        else if (TaskListSub[i].ControlType == 'checkbox') {
          if (TaskListSub[i].Value2 == 1)
            this.myFormGroup.controls[ColumnName].setValue(true);
          else
            this.myFormGroup.controls[ColumnName].setValue(false);

        }
        else if (TaskListSub[i].ControlType == 'Date') {
          this.myFormGroup.controls[ColumnName].setValue(TaskListSub[i].Value2);
        }
        else {
          const defaultOption = this.form_template[temp].options.find(opt => opt.Displaymember === TaskListSub[i].DisplayValue);
          this.myFormGroup.controls[ColumnName].setValue(defaultOption)
        }
      }
    }
  }

  convertToDate(dateString: string): Date {
    const formattedDate = this.datePipe.transform(dateString, 'MMM dd yyyy hh:mm a', 'en-US');
    return new Date(formattedDate);
  }  
 
  onSubmit(flag: string) {
    if (this.myFormGroup.invalid) {
      this.notificationService.warn('Please fill all mandatory fields');
      return;
    }
    this.showSpinner = true;
    const paramvalue = this.myFormGroup.value;
    let assessmentvalue = [];
    let assessmentdisplay = [];
    let sessionassessment = [];
    var ColumnName
    var DisplayValue
    var ControlType
    let Value;
    let Value2
    for (var i = 0; i < this.form_template.length; i++) {

      if (this.form_template[i].type == 'multiselect') {

        ColumnName = this.form_template[i].ID;
        DisplayValue = this.myFormGroup.controls[ColumnName].value;
        Value = paramvalue[this.form_template[i].ID + 'ID']
      }
      else if (this.form_template[i].type == 'select') {
        ColumnName = this.form_template[i].ID;
        Value = paramvalue[ColumnName].Valuemember;
        DisplayValue = paramvalue[ColumnName].Displaymember;
      }
      else {
        if (this.form_template[i].ID == 'Private') {
          ColumnName = this.form_template[i].ID;
          Value = paramvalue[ColumnName];
          if (paramvalue[ColumnName] == true)
            Value = 1;
          else
            Value = 0;
        }
        else if (this.form_template[i].type == 'Date') {
          ColumnName = this.form_template[i].ID;
          Value = this.datePipe.transform(paramvalue[ColumnName], 'MM-dd-yyyy');
        }
        else {
          ColumnName = this.form_template[i].ID;
          Value = paramvalue[ColumnName]
          DisplayValue = this.myFormGroup.controls[ColumnName].value;
        }
      }

      if (Value != undefined) {
        ControlType = this.form_template[i].type.toString();
        Value2 = Value.toString();
        if (Value2 != '') {
          assessmentvalue.push({ ColumnName, Value2 });
          assessmentdisplay.push({ DisplayValue });
          if (this.form_template[i].type !== 'multiselect') {
            //ColumnName+=;
            sessionassessment.push({ ColumnName, Value2, DisplayValue, ControlType });
          }
          else {
            let ddlData = [{ 'Displaymember': DisplayValue, 'Valuemember': Value2 }]
            sessionassessment.push({ ColumnName, ddlData, DisplayValue, ControlType });
          }
          
        }
      }
    }

    //if (flag == 'Filter') {
      let paramsExtra;
      paramsExtra = {
        CID: this.CID,
        Flag: this.flag,
        TemplateID: this.TemplateID,
        CreatorFlag: 0,
        TicketID: this.TicketID,
        UserName: this.UserID,
        dt: assessmentvalue,
        dtdispalyvalue: assessmentdisplay,
        Activity: this.ActiviteID,
        Comment: this.Comment,
        ActiviteID: this.ActiviteID,
        sessionDt: sessionassessment,
        sessionmultiselectinputvalue: this.multiselectinputvalue
      };
      
    //}
    //else 
    if (flag === 'favourite' && JSON.stringify(assessmentvalue) != '') {

      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      let params = {
        CID: this.CID,
        UserID: curUser.userid,
        FilterDetails: JSON.stringify(assessmentvalue),
        FilterDetails1: JSON.stringify(assessmentdisplay),
        FilterDetails2: JSON.stringify(sessionassessment),
        BoardID: this.BoardID
      };
      //console.log('params',params)
      this.service.httpPost('/Kanbanboard/SetKanbanBoardFilter', params)
        .subscribe(data => {
          let result: any;
          result = data;     
          //this.LoadKanbanBoardFilterSelection();               
          //this.notificationService.success('Successfully Updated')          
        },
          error => {
            console.log('Error : ', error);
          });
    }

    this.dialogRef.close(paramsExtra);
  }

 getCurrentDateFormatted(): string {
  const currentDate = new Date();
 // return '15-03-2024'
  return this.datePipe.transform(currentDate, 'yyyy-MM-dd');
}

  async datasource() {
    let parent;
    let parentData;
    let parentID = this.CurLedgerID;
    parent = _.where(this.template, { ID: this.eventID })
    parentData = _.where(this.temp, { name: parent[0].parent })
    if (parentData.length > 0) {
      parentID = parentData[0].value
    }

    let params = {
      cid: this.CID,
      flag: "SelectedLoadCombo",
      //TicketID:parentID,
      TicketID: this.TicketID,
      TemplateID: this.TemplateID,
      UserName: this.CurLedgerID,
      TagID: this.eventID,
      ActiviteID: 'INT_EDIT',
      TemplateGroupID: 1

    }
    // const query = `cid=${params.cid}&flag=${params.flag}&TaskID=${params.TicketID}&TemplateID=${params.TemplateID}&UserID=${params.UserID}&LoginFlag=${params.LoginFlag}&ActiviteID=${params.ActiviteID}`;
    const dt = await this.service.httpPost('/TicketCreator/GetTicketCreator?', params)
      .toPromise().then(data => {
        let result: any;
        result = data;
        if (result.errno === 0) {          
          this.LoadCombo = result.respdata.LoadCombo;
        }
      },
        (_error: Error) => {
          // if (error instanceof BadInput)           
          //   console.log(error);
          // else throw error;       
        });

  }

  async onClick(event) {
    //console.log('onClick',event);
    event.options = [{ Valuemember: 1, Displaymember: '' }]
    this.eventID = event.ID
    await this.datasource();
    event.options = this.LoadCombo
  }

  async onKey(value, event) {
    this.eventID = event.ID
    await this.datasource();
    event.options = this.LoadCombo
    event.options = this.search(value, event.options)
  }


  AddFilterColumn() {
    let params = {
      CID: this.CID,
      Flag: 'ADD',
      UserID: this.UserID,
      TemplateID: this.TemplateID,
      Tag: this.AddFilterColumnFromGroup.get('AddFilterColumnCtrl').value,
      FilterString: '',
      ActvityStatus: '',
      MenuuID: ''
    };
    this.service.httpPost('/Kanbanboard/SetKanbanBoardFilterColumns', params)
      .subscribe(data => {
        let result: any;
        result = data;
        this.template = result.respdata;
        sessionStorage.setItem("templateFilter",JSON.stringify(result.respdata))
        const idSet = new Set(this.template.map(item => item.ID));
        
        //this.TemplateColumnDt = this.data.templateColumns;
        this.filteredList2 = this.TemplateColumnDt.filter(item => !idSet.has(item.tag));
        this.LoadKanbanBoardFilterSelection();
        this.AddFilterColumnFromGroup.controls.AddFilterColumnCtrl.setValue('');
        this.isDisabled = true
        this.notificationService.success('Successfully Updated')
      },
        error => {
          console.log('Error : ', error);
        });

  }


  async setFavourite() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID: this.CID,
      UserID: curUser.userid,
      FilterDetails: '',
      FilterDetails1: '',
      //dt:assessmentvalue,
      BoardID: this.BoardID
    };
    this.showSpinner = true;
    let result = await this.service.httpPost('/Kanbanboard/SetKanbanBoardFilter', params)
      .toPromise().then(
        (data) => {
          this.showSpinner = true;
          setTimeout(() => {
            this.showSpinner = false;
          },
            800)
          let result: any;
          result = data;
          if (result.errno === 0) {
            this.notificationService.success("success");
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log(error);
          else throw error;
        });
  }
 

 async onKeyfilterColumn(value)
 {
    this.filteredList2 = this.filteredList2.filter(item => item.LabelName1.toLowerCase().includes(value));      
 }

 changesite(){
  this.isDisabled=false
 }

  search(value: string, opt) {
    let filter = value.toLowerCase();
    return opt.filter(option =>
      option.Displaymember.toLowerCase().includes(filter)
    );
  }
  changeEvents(event) {
    let parent;
    let index1 = this.form_template.findIndex(d => d.parent === event.source.ngControl.name);
    if (index1 > -1) {
      this.form_template[index1].Visibility = true
    }
    let index = this.temp.findIndex(d => d.name === event.source.ngControl.name); //find index in your array
    if (index > -1) {
      this.temp.splice(index, 1);//remove element from array
      parent = _.where(this.template, { parent: event.source.ngControl.name })
      if (parent.length > 0) {
        this.myFormGroup.get(parent[0].ID).setValue('');
      }
    }
    let temp1 = { "name": event.source.ngControl.name, "value": event.value };
    this.temp.push(temp1)
  }

  onClear() {
    this.isDisabled = false;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.myFormGroup.reset();
    this.visible = false;
    this.TicketID = 0;
    this.myControl.reset();
    // this.myFormGroup.controls.Status.setValue('5')
    // this.myFormGroup.controls.Created.setValue(JSON.stringify(curUser.userid))
  }

  onCloseDialog() {
    this.dialogRef.close({ event: 'CLEAR' });
  }

  //=======================================
  // Multi select relavent functionality //
  //=======================================

  async onKeyPress(event) {

    console.log('event',event)

  
    let TagID ;
    let TagIdData ;
    TagID =this.myFormGroup.get(event.ID)
    TagIdData =this.myFormGroup.get(event.ID+'ID')  
    // console.log('this.myFormGroup',this.myFormGroup)
    // console.log('event.ID',event.ID,TagID.value)
    if(TagID.value!=null)
    {
      const displayMembers = TagID.value.split(',');
      const valueMembers = TagIdData.value.split(',').map(Number); // Convert string numbers to actual numbers
    // Combine displayMembers and valueMembers into an array of objects
    this.multiselectinputvalue= displayMembers.map((displayMember, index) => ({
      Displaymember: displayMember,
      Valuemember: valueMembers[index]
    }));
    }


    event.options = [{ Valuemember: 1, Displaymember: '' }]
    this.eventID = event.ID
    await this.datasource();
    event.options = this.LoadCombo

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      TicketID: this.TicketID,
      ActiviteID: this.ActiviteID,
      LoginFlag: 'INTERNAL',
      TemplateGroupID: this.TemplateGroupID,
      TemplateID: this.TemplateID,
      loadCombo: event.options,
      inputcomboValue: this.multiselectinputvalue,
      FormName:'Kanban'
    }    
    const dialogRef = this.dialog.open(MultiselectwithsearchComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'LOAD') {
        this.multiselectinputvalue = result.data;
        // Step 3: Extract the Displaymember values        
        const displayMembers = result.data.map(item => item.Displaymember);

        // Step 4: Join the values into a comma-separated string
        const result2 = displayMembers.join(',');

        // Step 3: Extract the Displaymember values
        const displayMembers2 = result.data.map(item => item.Valuemember);

        // Step 4: Join the values into a comma-separated string
        const result3 = displayMembers2.join(',');
        this.myFormGroup.controls[this.eventID].setValue(result2);
        this.myFormGroup.controls[this.eventID + 'ID'].setValue(result3);
      }

    });

  }

}
