import { DialogService } from './../../../Services/OtherServices/dialog.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { Component, OnInit,ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TicketprefixdialogComponent } from './../ticketprefixdialog/ticketprefixdialog.component';

@Component({
  selector: 'app-ticketprefixconfig',
  templateUrl: './ticketprefixconfig.component.html',
  styleUrls: ['./ticketprefixconfig.component.scss'],
})
export class TicketprefixconfigComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns =['PrefixID','PrefixText','StartingNumber','Description','Action'];
  companies:any;
  template:any;
  showSpinner:any;
  datasource:MatTableDataSource<GroupALL>;
  CID:any;
  flag:any;
  prefix:any;

  constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,private dialogService: DialogService,
    private NotificationService:NotificationService) 
    { }

  ngOnInit() {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.Loaddetails();
  }

  onCompanySelect(event)
  {
    this.CID=event;
    this.flag='PAGELOAD'
    this.Loaddetails();

  }

  Submit(){
    this.Loaddetails();
  }

  Loaddetails()
  {
    this.showSpinner=true;
    let params = {
      cid:this.CID,
      flag:this.flag
       }         
          this.service.httpPost('/TicketPrefixConfig/GetTicketPrefixConfig',params)
          .subscribe(data => {
           this.showSpinner=true;
           setTimeout(() =>{
             this.showSpinner=false;
           },
           800)
            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.prefix=result.respdata.PrefixText;
                this.template=result.respdata.Template;

                this.datasource=result.respdata.PrefixText;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.PrefixText);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
              }            
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }
  add()
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'ADD' ,                            
          PrefixText:this.prefix,
          Template:this.template         
        }
        const dialogRef = this.dialog.open(TicketprefixdialogComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Loaddetails();
  
          });
  }
  Edit(event)
  {
  
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'EDIT' ,                            
          PrefixID:event.PrefixID,
          PrefixText:event.PrefixText,
          StartingNumber:event.StartingNumber,
          Template:this.template,       
          TemplateID:event.TemplateID    
        }
        const dialogRef = this.dialog.open(TicketprefixdialogComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Loaddetails();
  
          });
  }

  Delete(event){  
    let sdate =Date();
    this.dialogService.openConfirmDialog('Are you sure to delete this prefix?')
    .afterClosed().subscribe(res => {
     if (res) {
      let params = { 
            CID:this.CID,
            Flag: 'DELETE', 
            PrefixID: event.PrefixID, 
            PrefixText:event.PrefixText,
            StartingNumber:event.StartingNumber,
            Template:this.template,       
            TemplateID:event.TemplateID,    
            UserID: 0,
            GroupID:sessionStorage.getItem('groupid')        
       };
       this.service.httpPost('/TicketPrefixConfig/SeTicketPrefixConfig',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {
            this.NotificationService.warn("Record deleted Successfully");
            //console.log('Project list: ', result.respdata);
            this.flag='PAGELOAD'
            this.Loaddetails();
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
}
  
}

export interface GroupALL {
  PrefixID: string;
  PrefixText: string; 
}