import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { GroupComponent } from '../group/group.component';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {
  GroupMgtForm: FormGroup;
  loading = false;
  submitted = false;
  dataSource: any ;
  displayedColumns: string[] = ['GroupID',  'GroupName', 'Action'];
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  groupid: string;
  groupname: string;
  Flag: string;
  pageSize: number = 7;

  constructor(
    private groupmgtService: HttpserviceService,private generic: GenericService,private dialogService: DialogService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.pageSize = 5;
    this.getGroupMgtdata();
    this.GroupMgtForm = this.formBuilder.group({
      groupid: ['', Validators.required],
      groupname: ['', Validators.required],
      cid : [JSON.parse(sessionStorage.getItem('cid'))],
      flag : [this.Flag],
      createddate: ['2020-05-15'],
      createdby: ['admin'],
      defaultsite: ['101'],
      modifiedby: ['admin'],
      modifieddate: ['2020-05-15'],
      grouplevel: ['1001'],
      username: ['admin'],
      group: ['101'],
      returnvalue: [''],
      errormessage: [''],
    });

   // console.log('SessiongrpID',sessionStorage.getItem('groupid'));
  }

  get f() { return this.GroupMgtForm.controls; }

  openDialog(CID: string, groupID: string, groupName: string, Flag: string) {

    const GroupmgtData = {cID: CID, GroupID: groupID, GroupName: groupName, flag: Flag};
    
    sessionStorage.setItem('groupMgtData', JSON.stringify(GroupmgtData));

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    const dialogRef = this.dialog.open(GroupComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // if (GroupmgtData.flag === 'Add' && result != undefined){
      //   const data = this.dataSource.data;
      //  data.push(result);
        this.getGroupMgtdata();
        // this.dataSource.data = data;
        // this.dataSource.data.push(result);
    //  }
    });
  }

  openBottomSheet(): void {
    // console.log();
  }

  getGroupMgtdata() {
    //JSON.parse(sessionStorage.getItem('cid')), 'LOADGRID', '101'
    // const paramsData = 'cid=' + cid + '&flag=' + flag + '&groupid=' + groupid;
    const query = `cid=${JSON.parse(sessionStorage.getItem('cid'))}&flag=${'LOADGRID'}&groupid=${JSON.parse(sessionStorage.getItem('groupid'))}`;
    // const options = { params: new HttpParams({fromString: paramsData}) };
  // console.log('QUERY',query);
    this.groupmgtService.httpGet('/groupmgt/getgroup?',query)
      .subscribe(
        data => {
         // console.log('data',data.Response.Groupmgtsub);
          const collecData = data;
          //  console.log('collec', collecData.respdata);
          //this.dataSource =  new MatTableDataSource(data.groupDetails); // as Task[];
          this.dataSource =  new MatTableDataSource(collecData.respdata); // as Task[];
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          // this.paginator.pageIndex = 0;
          // console.log('this.dataSource', this.dataSource);
          // console.log(tasks);
        },
        error => {
          console.log(error);
        });
  }

  exportAsExcel()
  {
    this.generic.excelExport(this.dataSource.data, 'GroupManagement', 'GroupList' );
  }

  deleteGroupMgt(GroupID){
    
    this.dialogService.openConfirmDialog('Are you sure to delete this group?')
          .afterClosed().subscribe(res => {
           if (res) {
          
            const params = {
              CID: JSON.parse(sessionStorage.getItem('cid')),
              GroupID: GroupID
            };
        
            const query = `cid=${JSON.parse(sessionStorage.getItem('cid'))}&groupid=${GroupID}&ApplicationType=${3}`;
            this.groupmgtService.httpGet('/groupmgt/deleteGroupMgt?',query)
            .subscribe(
              data => {
                const result = data as any;
                if (result.errno === 0) {
                  this._snackBar.open('Group deleted successfully', 'Delete', {
                    duration: 3000,
                  });
                  //console.log('result',result);
                  this.dataSource = this.dataSource.data.filter((value, key) => {
                    return value.GroupID != params.GroupID;
                  });
                  this.dataSource =  new MatTableDataSource(this.dataSource); 
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  //console.log('delete : ', this.dataSource);
                }
               
              },
              error => {
                console.log(error);
            });

          }
        });
  }

  editTask(GroupID){
    alert(GroupID);
  }

  doFilter(filterValue: string) {
    // console.log(filterValue);
    this.dataSource.filter = filterValue;
  }

}


export class GroupData {
  cid: string;
  flag: string;
  groupid: string;
  groupname: string;
  createddate: Date;
  createdby: string;
  defaultsite: string;
  modifiedby: string;
  modifieddate: Date;
  grouplevel: string;
  username: string;
  group: string;
  returnvalue: string;
  errormessage: string;
 }
