import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {

  StatusFormGroup: FormGroup;
  ststusid:any="0";
  tag:any;
  description:any
  Flag:any;
  cid:any;
  readonly:any;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<StatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
     { 
      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.ststusid=this.data.Statusid;
      this.tag=this.data.Tag;
      this.description=this.data.Description;
      this.readonly=this.data.readonly;

      this.StatusFormGroup = this.formBuilder.group({  
        fcTag:['', Validators.required],     
        fcDescription:[''],
        fcReadonlyTag:[false],    
      });  

      this.StatusFormGroup.controls.fcTag.setValue(this.tag);
      this.StatusFormGroup.controls.fcDescription.setValue(this.description);
      this.StatusFormGroup.controls.fcReadonlyTag.setValue(this.readonly);
     }

  ngOnInit() {}

  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
  Submit()
  {
    let params = { 
      cid:this.cid,
      flag: this.Flag, 
      StatusID:this.ststusid,            
      Tag:  this.StatusFormGroup.get('fcTag').value,   
      Description: this.StatusFormGroup.get('fcDescription').value,
      SLA:'1',
      ReadonlyTag:this.StatusFormGroup.get('fcReadonlyTag').value
      
     };
    this.service.httpPost('/Status/SetStatus',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
