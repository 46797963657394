import { Component, OnInit } from '@angular/core';
import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore';
import { DatePipe } from '@angular/common';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';

@Component({
  selector: 'app-leavemgtelog',
  templateUrl: './leavemgtelog.component.html',
  styleUrls: ['./leavemgtelog.component.scss'],
})
export class LeavemgtelogComponent implements OnInit {

  LeavepostingelogFormGroup: FormGroup;
  Flag:any;CID:any;LeaveID:string;
  LeaveDataGrids:any;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder, private datePipe: DatePipe,
    private dialog: MatDialog,private dialogService: DialogService,public dialogRef: MatDialogRef<LeavemgtelogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      console.log('e',this.data)

      this.Flag=this.data.flag;
      this.CID = this.data.cid;   
      this.LeaveID = this.data.leaveid;   

      this.LeavepostingelogFormGroup = this.formBuilder.group({  
          
      });       
    }

  ngOnInit() {
   this.onLeaveIDChange() ;
  }

  onLeaveIDChange() {   
    let params = {
      CID: this.CID,
      Flag: this.Flag,
      EmployeeID:"",              
      Department:"",
      LeaveType:"",
      FromDate:"",
      ToDate:"",
      RtnStatus:"",
      UserID:this.LeaveID
    };
    
    this.service.httpPost('/EmpLeaveReport/GetEmpLeaveReport',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;          

          if (result.errno === 0) {                           
            this.LeaveDataGrids=result.respdata.Leavehistory;
                       
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }


  onCloseDialog()
  {    
    this.LeavepostingelogFormGroup.reset();
    this.dialogRef.close();     
  }

}
