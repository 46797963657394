import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, finalize } from 'rxjs/operators';
import { AuthenticationService } from '../Services/OtherServices/authentication.service';
import { HttpClient } from '@angular/common/http';

import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {
  loginForm: FormGroup;
  serverForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    Companylist:any;
    ShowSettings:boolean=false;    
    selected:string;
    homebtn:boolean;
    showSpinner:any;
    hide: boolean = true;

    myFunction() {
      this.hide = !this.hide;
    }
   constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        public plt: Platform

    ) { 
        
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        CompanyName: ['', Validators.required]
    });
    this.serverForm = this.formBuilder.group({
      protocol: ['', Validators.required],
      ipaddress: ['', Validators.required],
      apipath: ['', Validators.required],
      //companyid: ['', Validators.required]
  });


  
         var currentapiObj = localStorage.getItem('apiObj');
        // if ((this.plt.is('ios') || this.plt.is('android')) ) {
          
        //     // This will only print when on iOS
        //     // console.log('I am an iOS device!');
        //     this.homebtn=true;
        //     if(currentapiObj===null)
        //       this.ShowSettings=true;
        //     //this.isOpenedLogin=false;
        //     this.selected='http';
        //     this.serverForm.controls.protocol.setValue(this.selected);
        //     this.serverForm.controls.apipath.setValue('/angular_service/api');
            
        //   }      
        //   else {
            // This will only print when on other          
            this.homebtn=false;
            this.ShowSettings=false;                         
          //}
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }


    // showSettings(){
    //   this.loading=false;
    //   this.ShowSettings=true; 
    // }

    OpenLogin()
    {
         // stop here if form is invalid
         if (this.serverForm.invalid) {
          return;
       }

        let apiObj={Protocol:'',ServerIP:'',APIPath:'',CompanyID:0}
        apiObj.Protocol=this.s.protocol.value;
        apiObj.ServerIP=this.s.ipaddress.value;
        apiObj.APIPath=this.s.apipath.value;
        //apiObj.CompanyID=this.s.companyid.value;
        //localStorage.removeItem('conObj');
        localStorage.setItem('apiObj',JSON.stringify(apiObj));
        // console.log('JSON.parse(localStorage.getItem("apiObj"))',JSON.parse(localStorage.getItem("apiObj")));
        this.ShowSettings=false; 
        this.SiteMasterData ();
       // this.isOpenedLogin=true;
    }
  
  
  
    ngOnInit() { 
      console.log('this.ShowSettings',this.ShowSettings)  
      if(this.ShowSettings===false)
      {      
        this.SiteMasterData();    
      }               
      
              // console.log('Platform',this.plt.platforms());
        // this.Companylist=this.Company.store.data;      
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      // console.log('return url', this.returnUrl);
      
      // console.log('return url :123');
    }

    SiteMasterData () {
    
      
      this.authenticationService.getSiteMaster('101').subscribe(
        data => {
          let result: any;
          result = data;
          // console.log("Companylist",result);
          if(result.err!=0){
            if(result.errdesc!='Success')
            this.error=result.errdesc;
            
          this.Companylist=result.respdata;             
         

          this.loginForm.controls.CompanyName.setValue(101);
          }
          else
            this.error=result.errdesc;
          
        },
        error => {
            this.error = error;
            this.loading = false;
        });
           
    }

    readCID(item:string)
    {
     
    }



    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    // convenience getter for easy access to form fields
    get s() { return this.serverForm.controls; }

    onSubmit() {
        // console.log('inside submit',this.loginForm.get('CompanyName').value);
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.showSpinner=true;
        // console.log('onsubmit url', this.returnUrl);
        this.authenticationService.login(this.f.username.value, this.f.password.value,this.f.CompanyName.value)
            .pipe(first())
            .subscribe(
                data => {
                    // console.log('login return url: ', this.returnUrl);
                    // this.router.navigate([this.returnUrl]);
                    this.showSpinner=true;
                    setTimeout(() =>{
                      this.showSpinner=false;
                    },
                    800)
                    this.f.password.setValue('');
                   // this.f.username.setValue('');
                    this.router.navigate(['Full']);
                },
                error => {
                    this.error = error;
                    this.showSpinner = false;
                });
    }

  //   getExpiryData () {
  //     this.http.get('assets/SiteMaster.txt', {responseType: 'text'})
  //       .subscribe(
  //         data => {
  //            this.Companylist=JSON.parse(data);
  //            var Default_site = this.Companylist.filter(x => x.default == 1);
  //            this.loginForm.controls.CompanyName.setValue(Default_site[0].CID);
  //         },
  //         err => {
  //           console.log(err)
  //         });
  // }
}
