import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Branch {
  BranchID: string;
  BranchName: string;
}

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent implements OnInit {

  cid:any;
  Flag:any;
  LocationID:any;
  LocationName:any;
  description:any;
  Status:any;
  CurLedgerID:any;
  datasource:any;
  branch:any;
 
  myFormGroup: FormGroup;

  Statuslist=[
    {id: 0, name: 'Active'},
    {id: 1, name: 'InActive'}
    
  ];

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<BranchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 

      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.LocationID=this.data.LocationID;
      this.LocationName=this.data.LocationName;
      this.Status=this.data.Status;
      this.description=this.data.Description


      this.myFormGroup = this.formBuilder.group({  
        fcLocationID:[''],
        fcLocationName:['', Validators.required], 
        fcStatus:['', Validators.required],  
        fcDescription:['']   
      });
      
      
      if(this.Flag=='EDIT')
      {
        this.myFormGroup.controls.fcLocationID.setValue(this.LocationID);
        this.myFormGroup.controls.fcLocationName.setValue(this.LocationName);
        this.myFormGroup.controls.fcStatus.setValue(this.Status);
        this.myFormGroup.controls.fcDescription.setValue(this.description);
      }
    }

  ngOnInit() 
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.Loaddetails()
    this.branch = [];
  }

  onSearchClear(){
    this.myFormGroup.reset();        
    this.datasource=''; 
 }

  Loaddetails()
  {
    let params = {
      CID:this.cid,
      flag:'Branch',
      LocationID:this.LocationID
      
       }
          // const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
          this.service.httpPost('/Branch/GetBranch',params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
                this.datasource=result.respdata.Branchlist;
                // this.branch=result.respdata.Branchlist;
              
            }
            else{
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
  logEvent(_flag, e)
  {

    // console.log("e",e.data.BranchName)
    // if (_flag == 'RowInserting')
    // {
    //   this.branch.push({BranchID:e.data.BranchID ==undefined? '' :e.data.BranchID ,BranchName:e.data.BranchName})
      
    // }
    // else if (_flag == 'RowRemoved')
    // {

    // }
    // console.log("this.branch",this.branch)

  }

  Submit()
  {
    if(this.datasource.length==0)
    {
      this.branch.push({BranchID:'' ,BranchName:''})
    }
    else
    {
      for (var i = 0; i < this.datasource.length; i++) 
                {
                  console.log("this.limit123",this.datasource[i]);
                  this.branch.push({BranchID:this.datasource[i].BranchID==undefined? 0: this.datasource[i].BranchID ,BranchName:this.datasource[i].BranchName})
                }
               
    }
 
    let params = { 
      cid:this.cid,
      flag: this.Flag, 
      LocationID:this.LocationID,   
      LocationName:this.myFormGroup.get('fcLocationName').value,         
      Status:  this.myFormGroup.get('fcStatus').value,   
      Description: this.myFormGroup.get('fcDescription').value,
      dt:this.branch,
      UserID:this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')
      
      
     };
    this.service.httpPost('/Branch/SetBranch',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno !== 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
