import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { KanbanboardconfigpopupComponent } from '../kanbanboardconfigpopup/kanbanboardconfigpopup.component';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { KanbankeyvaluejsondataviewComponent } from '../kanbankeyvaluejsondataview/kanbankeyvaluejsondataview.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-kanbanboardconfig',
  templateUrl: './kanbanboardconfig.component.html',
  styleUrls: ['./kanbanboardconfig.component.scss'],
})
export class KanbanboardconfigComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  KanbanBoardConfig:any =FormGroup;
  Companies:any;
  KeyColumn:any;
  Status:any;
  Priority:any;
  Employee:any;
  pCID:any;
  CID:any;
  CurLedgerID:any;
  UserID:any;
  TemplateGroupID:any;
  kanbanboarddatasource:any;
  pageSize: number = 7;
  IsFalse:boolean=false;
  displayedColumns = ['BoardName','KeyColumn','UserDetails','Action'];
  CardViewData:any;
  InticativeRelease:any;
  InticativeSprint:any;
  TemplateID:number;
  constructor(private service: HttpserviceService,
    //private formBuilder: FormBuilder,
    public notificationService: NotificationService,    
    private formBuilder: FormBuilder,    
    private router: Router, 
    private dialog: MatDialog) {


    this.KanbanBoardConfig= this.formBuilder.group({           
      fcCompany: ['']     ,
      fcTemplateID: ['']           
    });

   }

  ngOnInit() {
    this.pCID=sessionStorage.getItem('cid');
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserID=curUser.userid;
    this.TemplateGroupID=curUser.TemplateGroupID;
    this.CID=parseInt(this.pCID);
    this.KanbanBoardConfig.controls.fcCompany.setValue(this.CID);
    this.KanbanBoardConfig.controls.fcTemplateID.setValue(1);
    this.loadData();
  }


  loadData()
  {
      let params = {
      cid:this.CID,
      flag:"LoadDDL",     
      UserID:this.UserID   ,
      TemplateID:this.KanbanBoardConfig.get('fcTemplateID').value
      }
      console.log('params',params)
      this.service.httpPost('/Kanbanboard/GetKanbanBoardConfig?',params)
      .subscribe(data => {
        let result: any;
        result=data;
        
        if (result.errno === 0){
          console.log('result',result.respdata);
          this.kanbanboarddatasource=result.respdata.Kanbanboard;
          this.kanbanboarddatasource =  new MatTableDataSource(result.respdata.Kanbanboard);
          this.kanbanboarddatasource.paginator = this.paginator;

          this.Companies=result.respdata.CompanyID;
          this.TemplateID=result.respdata.TemplateID;
          this.Employee=result.respdata.Employee;
          this.Status=result.respdata.Status;
          this.Priority=result.respdata.Priority ;
          this.KeyColumn=result.respdata.KeyColumn ;
          this.CardViewData=result.respdata.CardViewData ;
          this.InticativeRelease=result.respdata.InticativeRelease;
          this.InticativeSprint=result.respdata.InticativeSprint;
        }
        else{
          this.notificationService.warn(result.errdesc); //':: Problem details');
        }
      }, 
      (error: AppError) => {
        if (error instanceof BadInput)           
        this.notificationService.warn(error);
        else throw error;       
      });
}


  onClear(){

  }

  onCloseDialog(){

  }

  jsondataView(element:any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    
    dialogConfig.data = {
      jsonValue:element.KeyValuesJSON
    }
    const dialogRef = this.dialog.open(KanbankeyvaluejsondataviewComponent, dialogConfig)
    dialogRef.afterClosed()
    .subscribe(res => {
    });
 
  }
  
  PopUp(Flag: string,element:any)
  {    
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus =  true;
     
     dialogConfig.data = {
       flag: Flag,
       userid: this.UserID,
       ledgerid: this.CurLedgerID,
       cid:this.CID,
       employee:this.Employee,
       priority:this.Priority,
       status:this.Status,
       keycolumn:this.KeyColumn,
       companydata:this.Companies,
       boardNmae:element.BoardName,
       CardViewData:this.CardViewData,
       InticativeRelease:this.InticativeRelease,
       InticativeSprint:this.InticativeRelease,
       templateid:this.KanbanBoardConfig.get('fcTemplateID').value
     }


     

     const dialogRef = this.dialog.open(KanbanboardconfigpopupComponent, dialogConfig)
     dialogRef.afterClosed()
     .subscribe(res => {this.loadData();
                  //console.log('res',res,this.KeyColumn[0].KeyColumn);
                  //   if(res.event!='CLEAR'){
                  //   this.selectedCompany=this.CID
                  //   this.selectedEmployee=res.LedgerID;
                  //   this.selectedCustomer=res.Client;
                  //   this.selectedProject=res.Project;
                  //   this.selectedStatus=res.Status;
                  //   this.selectedPriority=res.Priority;   
                  //  }
     });
  }

}

export class User {
  userid: number;
  ledgerid: number;
  groupid: number;
  username: string;
  token?: string;
  configparam: any;
  salesmanid: number;
  TemplateGroupID:number;
  UserType:string;
  dtsiteid:any;
  ds_dashboard:any;
  // elapsedtime?: number;
}
