import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';


@Component
({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})

export class ChangePasswordComponent implements OnInit 
{

  ChangePwd:FormGroup;
  fcFullDay=new FormControl();
  // CID:any;
  // EmpID:any;
  // LoginID:any;
  CurUsername:string;
  LedgerID:any;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder,private dialogService: DialogService, 
    public dialogRef: MatDialogRef<ChangePasswordComponent>)
     {
        //  this.ChangePwd = new FormGroup
        //  ({  
        //     fcUserID= new  ['', Validators.required] ,      
        //     // fcLedgerID: ['', Validators.required] ,    
        //     fcPassword: ['', Validators.required] ,             
        //     fcNewPassword:['',Validators.required],
        //     fcConfirmPassword:['',Validators.required]
        // }); 

        this.ChangePwd=new FormGroup(
          {
          fcPassword: new FormControl('', Validators.required),
          fcNewPassword: new FormControl('', Validators.required),
          fcConfirmPassword: new FormControl('', Validators.required)
         }
        );
 
     }

    //  initializeFormGroup(){
    //   this.ChangePwd.setValue({
    //     username: '',
    //     fcPassword: '',
    //     fcNewPassword:'',
    //     fcConfirmPassword: ''
    //   });
    // }


  
  ngOnInit() 
  {

    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurUsername=curUser.username;
    this.LedgerID=curUser.userid;
    console.log(this.CurUsername);
  }


  Submit()
  {
    console.log('submit test1',this.ChangePwd.controls.fcNewPassword.value);
    console.log('submit test2',this.ChangePwd.controls.fcConfirmPassword.value);
     
     if(this.ChangePwd.controls.fcNewPassword.value===this.ChangePwd.controls.fcConfirmPassword.value)
    {
     
      let params = 
      {     
        cid: JSON.parse(sessionStorage.getItem('cid')),
        UserID:this.CurUsername,
        LedgerID:this.LedgerID,
        Password:this.ChangePwd.get('fcPassword').value,
        NewPassword:this.ChangePwd.get('fcNewPassword').value
        // ConfirmPassword:this.ChangePwd.get('fcConfirmPassword').value,
      };
      // const query = `cid=${params.cid}&userid=${params.LedgerID}`;
      const query = `cid=${params.cid}&userid=${params.LedgerID}&password=${params.Password}&newpassword=${params.NewPassword}`;
       // this.service.httpGet('/Goals/GetGoals?',query)
    this.service.httpGet('/changepassword/getuser?',query)
    .subscribe(
      (data) => 
      {
        let result: any;
        console.log('data ', data);
        result = data;       
        console.log('result ', result);
        if (result.errno === 0) 
        {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
        }
        else 
        {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) =>
       {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });

    }
    else
    {
      this.NotificationService.success('Should be same new password and confirm password');
    }

    
  }


  onClear()
  {
    console.log('clear TEST');
    // this.ChangePwdFormGroup.reset();       
    // this.fcFullDay.reset();  
    this.ChangePwd.reset();
    // this.initializeFormGroup(); 

  }

  Quit()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
          this.ChangePwd.reset();
          this.ChangePwd.reset();
          this.dialogRef.close({});
      }
    });
  }
}

