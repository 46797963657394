import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore';
import { DatePipe } from '@angular/common';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';

@Component({
  selector: 'app-documentreqview',
  templateUrl: './documentreqview.component.html',
  styleUrls: ['./documentreqview.component.scss'],
})
export class DocumentreqviewComponent implements OnInit {
  date= new Date();
  Flag:any;
  showSpinner:any;
  DocumentRequestViewFormGroup: FormGroup;
  Companies:any;
  Employees: any;
  Types:any;
  Status:any;
  selectedReqID: any;
  CurLedgerID:any;
  RequestID:any;
  Monthydedamt:number;
  myControl = new FormControl('', [Validators.required]);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  employeeid:string[]=[];
  selectedEmpID: any;
  advid:string[]=[];
  cid:any;
  visible:boolean=false;
  empvisible:boolean=false;
  Menuid:any;
  ledgerid:any;
  emp:boolean=false;
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder, private datePipe: DatePipe,
    private dialog: MatDialog,private dialogService: DialogService,public dialogRef: MatDialogRef<DocumentreqviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {      
      //console.log('dialogdata',this.data)      
      this.Menuid=this.data.MenuID;
      
      if(this.Menuid=='STS_106')      
        this.empvisible=true;    
      else    
        this.empvisible=false;
  
      this.Flag=this.data.Flag;
      this.cid = this.data.cid;   
      this.Employees = this.data.Employees;                           
      this.Types = this.data.Type;
      this.Status=this.data.Status;
      this.selectedReqID=this.data.RequestID; 

     this.employeeid= _.pluck(this.Employees, 'Name'); 

     this.filteredOptions = this.myControl.valueChanges
     .pipe(
       startWith(''),
       map(value => this._filter(value))
     ); 

      this.DocumentRequestViewFormGroup = this.formBuilder.group({       
        myControl: [''],   
        fcType: ['', Validators.required],  
        fcStatus: ['', Validators.required],                
        fcDate: ['', Validators.required],
        fcDescription: ['']    
      });    
    }

  ngOnInit() {
    if(this.Flag !=='ADD')
    {
      this.visible=true;
      this.getddllist();
    }
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   // const str: string = this.CurLedgerID;   
    
    this.CurLedgerID=curUser.empname.split(' - ');
 //   console.log('curuser',this.CurLedgerID[0]);
    this.ledgerid=curUser.ledgerid;
    this.DocumentRequestViewFormGroup.controls.fcDate.setValue(this.date);
    this.DocumentRequestViewFormGroup.controls.fcStatus.setValue('Open');

    if(this.Menuid ==='STS_107')
       this.emp=true;
    else
      this.emp=false;   

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  selectedemployee(event) {        
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;   
    // console.log('selectedEmpID Split', this.selectedEmpID.slice(0, str.indexOf('-')));
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
    // console.log('selectedEmpID', this.selectedEmpID);    
  }

  getddllist()
  {
    let params = {
      CID: this.cid,
      Flag: "EDITDATA",
      MenuID:this.Menuid,
      RequestID:this.selectedReqID
    };
    this.service.httpPost('/DocumentRequest/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);
          if (result.errno === 0) {               
            this.RequestID=result.respdata.Editdata;

            this.selectedEmpID=this.RequestID[0].EmpName;
            this.myControl.setValue(this.RequestID[0].EmpName);
            this.DocumentRequestViewFormGroup.controls.fcType.setValue(this.RequestID[0].DocType);
            this.DocumentRequestViewFormGroup.controls.fcDescription.setValue(this.RequestID[0].DocComment);
            this.DocumentRequestViewFormGroup.controls.fcStatus.setValue(this.RequestID[0].DocStatus);
            this.DocumentRequestViewFormGroup.controls.fcDate.setValue(this.RequestID[0].ReqDate);
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
Submit() {   
  if (this.DocumentRequestViewFormGroup.invalid) {
    this.NotificationService.warn('Please fill madatory fields');     
    return;
    }   
    this.showSpinner=true;
    let sdate =Date()
    let params; 
    if(this.Flag=='ADD')
    {
      params = { 
        CID:this.cid,
        Flag: this.Flag,           
        LedgerID:this.Menuid=='STS_106'?this.selectedEmpID:this.CurLedgerID[0],    
        RequestDocType:  this.DocumentRequestViewFormGroup.get('fcType').value,   
        Description: this.DocumentRequestViewFormGroup.get('fcDescription').value,
        DocStatus: this.DocumentRequestViewFormGroup.get('fcStatus').value,
        RequestDate: this.DocumentRequestViewFormGroup.get('fcDate').value,
        CreatedBy:this.ledgerid,
        GroupID:JSON.parse(sessionStorage.getItem('groupid'))
       };  
    }
    else
    {
      params = { 
        CID:this.cid,
        Flag: this.Flag,           
        RequestID:this.selectedReqID,
        LedgerID:this.selectedEmpID,    
        RequestDocType:  this.DocumentRequestViewFormGroup.get('fcType').value,   
        Description: this.DocumentRequestViewFormGroup.get('fcDescription').value,
        DocStatus: this.DocumentRequestViewFormGroup.get('fcStatus').value,
        RequestDate: this.DocumentRequestViewFormGroup.get('fcDate').value,
        CreatedBy:this.ledgerid,
        GroupID:JSON.parse(sessionStorage.getItem('groupid'))
       };  
    }

    this.service.httpPost('/DocumentRequest/SetDocumentRequest',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;   
        this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)    
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          this.onCloseDialog();
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
          this.onCloseDialog();
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });

  }
  onClear()
  {
    this.DocumentRequestViewFormGroup.reset();  
    this.myControl.setValue('');    
    this.DocumentRequestViewFormGroup.controls.fcDate.setValue(this.date);
    this.DocumentRequestViewFormGroup.controls.fcStatus.setValue('Open');
  }

  onCloseDialog()
  {
    // this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    // .afterClosed().subscribe(res => {
    //   if (res) {
          this.DocumentRequestViewFormGroup.reset();
          this.dialogRef.close();
      //    }
      // });
  }


}
