import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
// import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
// import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { GenericService } from '../../../../Services/OtherServices/generic.service';
import { UserService } from 'src/app/components/Admin/Users/userValidate';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';

@Component({
  selector: 'app-consolidate-effort',
  templateUrl: './consolidate-effort.component.html',
  styleUrls: ['./consolidate-effort.component.scss'],
})
export class ConsolidateEffortComponent implements OnInit {
  @ViewChild('stepper') stepper;
  TotHours:any;
  TotOTHours:any;
  isEditable = true; 
  EffortFormGroup : FormGroup;
  EforrtDataFormGroup: FormGroup;
  EffortDataGrids:any;
  Companies:any;
  Employees:any;
  Projectlist:any;
  cid:any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  selectable = true;
  removable = true;
  fcEmployee = new FormControl();
  allEmployees:any
  frmdate= new Date();
  employees: string[] = [];
  filteredEmployees: Observable<string[]>;
  showSpinner:any;
  employeelistselected: any = [];
  autocompleteTagsOptionActivated = false;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  selectedProjectID: number;
  projectid: string[] = [];
  selectedprojectid: any;
  @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,private generic: GenericService,
    public notificationService: NotificationService, private datePipe: DatePipe, private service1: UserService) 
    { 
      this.EffortFormGroup = this.formBuilder.group({  
          fcCompany: ['', Validators.required] , 
          fcProject: [''] ,   
          fcEmployee: [''],                  
          fcFromDate: ['', Validators.required],
          fcToDate: ['', Validators.required]       
       });
         this.EforrtDataFormGroup = this.formBuilder.group({                 
      });

    }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid')); 
    this.PageLoad();
    this.EffortFormGroup.controls.fcCompany.setValue(this.cid);

    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, 'myControl'))
    );

    this.EffortFormGroup.controls.fcFromDate.setValue(this.frmdate);
    this.EffortFormGroup.controls.fcToDate.setValue(this.frmdate);
  }

  private _filter(value: string, control: string): string[] {
    
    const filterValue = value.toLowerCase();
    // console.log('controlID',control);
    this.selectedprojectid=''
    // console.log('FilterProjectID',this.employeeidname);
    // console.log('FilterProjectID',this.projectid);
    if (control === 'myControl') {
      //console.log('FilterProjectID',this.projectid);
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }

  }
  selectedEmployee(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
    if (control === 'myControl') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.Projectlist, { 'ProjectName': event.option.value }), 'ProjectID'));
      // console.log('selectedprojectid', this.selectedprojectid);
    }
   

  }

  changesite(e) {
    this.cid=e.value; 
    this.Projectlist=''
    this.projectid =[]    
    this.employees = []; // making the chipList empty
    this.fcEmployee.reset(); // resets the control
    this.fcEmployee.markAsPristine();
    this.fcEmployee.markAsUntouched();
    
    this.PageLoad();
    this.myControl.setValue('');  
    
  }
  

  add(event: MatChipInputEvent): void {   
    const input = event.input;
    const value = event.value;   
    if ((value || '').trim()) {
      this.employeelistselected.push({
        id:Math.random(),
        Name:value.trim()
      });         
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fcEmployee.setValue(null);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID:this.cid, 
      Flag: "LOADDDL"
    };
    this.showSpinner=true;
    console.log('parameters',params);
    this.service.httpPost('/ETSConsolidateEffortOT/GetEffortData',params)
      .subscribe (
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = curUser.dtsiteid;                           
            this.Employees = result.respdata.Employee;
            this.Projectlist=result.respdata.Project;
            this.projectid = _.pluck(this.Projectlist, 'ProjectName');
            this.allEmployees = result.respdata.Employee;                      

               this.fcEmployee.valueChanges.subscribe(search => {
                this.filteredEmployees = of(this.allEmployees.filter(item =>
                item.Name.toLowerCase().includes(search)
                ));
                });
                            
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  SearchClear()
  {
    this.myControl.setValue('');  
   
  }

  Submit(){
    if (this.EffortFormGroup.invalid) {
      this.notificationService.warn('Please select date range');     
      return;
      }  
    console.log("fcProject",this.selectedprojectid)
    let temp= [];
     temp.push({"Project":this.selectedprojectid })
    this.showSpinner=true;
      let params = {     
      CID: this.EffortFormGroup.get('fcCompany').value,
      Flag: "LOADGRID",               
      LedgerID:_.pluck(this.employees, 'LedgerID'),   //this.EffortFormGroup.get('fcEmployee').value,       
      dtProject:temp,
      FromDate: this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
      ToDate: this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
      this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy")
      
    };
    this.service.httpPost('/ETSConsolidateEffortOT/GetEffortData',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;          
          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
            this.EffortDataGrids = result.respdata.Effortdata;     
            this.TotHours = result.respdata.TotHrs;
            this.TotHours =this.TotHours[0].EffortHours;

            this.TotOTHours =result.respdata.TotOTHrs;
            this.TotOTHours =this.TotOTHours[0].OTHours;     
            this.stepper.selectedIndex = 1;      
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  
exportAsExcel()
    {
      // , {header:['name', 'surname']}
      this.generic.excelExport(this.EffortDataGrids, 'Effort','EffortList From ' + this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy")+' to '+this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy"));
    }

    
  onSearchClear(){
    this.myControl.setValue('');    
    this.EffortFormGroup.reset();     
    this.EffortDataGrids='';   
    this.stepper.selectedIndex = 0;   
    this.EffortFormGroup.controls.fcCompany.setValue(this.cid);
    this.EffortFormGroup.controls.fcFromDate.setValue(this.frmdate);
    this.EffortFormGroup.controls.fcToDate.setValue(this.frmdate);

    this.employees = []; // making the chipList empty
    this.fcEmployee.reset(); // resets the control
    this.fcEmployee.markAsPristine();
    this.fcEmployee.markAsUntouched();

  }
  addemployees(event1: MatChipInputEvent): void {
    const input = event1.input;
    const value = event1.value;
    if ((value || '').trim()) {
      this.employees.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.fcEmployee.setValue(null);
  }

  removeemployees(emp: string): void {
    const index = this.employees.indexOf(emp);
    if (index >= 0) {
      this.employees.splice(index, 1);
    }
  }
  optionActivated($event: MatAutocompleteActivatedEvent) {
    if ($event.option) {
      this.autocompleteTagsOptionActivated = true;
    }
  }
  selectedemployees(event1: MatAutocompleteSelectedEvent): void {
    this.employees.push(event1.option.value);
    this.empInput.nativeElement.value = '';
    this.fcEmployee.setValue(null);

  }
}
