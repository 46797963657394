import { DialogService } from '../../../../Services/OtherServices/dialog.service';
import { Component, OnInit, ViewChild} from '@angular/core';
import { Inject } from '@angular/core';
import {FormControl, FormBuilder,AbstractControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DxDataGridComponent,} from 'devextreme-angular';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith } from 'rxjs/operators';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import * as _moment from 'moment';
 import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

 
export interface User {
  Name: string;
}

@Component({
  selector: 'app-editot',
  templateUrl: './editot.component.html',
  styleUrls: ['./editot.component.scss'],
})
export class EditotComponent implements OnInit {
  employeelist: any;
  datasource: any;
  flag: any;
  LedgerID;
  cid:any;
  ledgerid1= 0;
  OTFormGroup:FormGroup;
  date= new Date();
  addflag= false;
  regex = '([01]?[0-9]|2[0-3]):[0-5][0-9]';
  temp:any;
  filteredOptions: Observable<string[]>;
  colorCtr: AbstractControl = new FormControl(null);
  myControl = new FormControl();
  empid:string[]=[];
  enabledate:boolean=true;
  myDateFilter:any;
  OT:any;
  dateRange = [new Date(new Date().getFullYear(), new Date(). getMonth(), new Date().getDate()-1),
    new Date(new Date().getFullYear(), new Date(). getMonth(), new Date().getDate()+1)]
         
    //   myDateFilter= (d: Date): boolean => {
    //   return (d >= this.dateRange[0] && d <= this.dateRange[1])
    // }

  Status = {
    store: {
        type: 'array',
        data: [
            { id: 1, name: 'In' },
            { id: 0, name: 'Out' },
        ],
        key: 'id'
    }};

    displayedColumns = ['sno', 'Name', 'status', 'Action'];
    constructor(private formBuilder: FormBuilder,private datePipe: DatePipe,
      public service: HttpserviceService,
      public notificationService: NotificationService,
      private dialogService: DialogService,
      public dialogRef: MatDialogRef<EditotComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {

        this.OTFormGroup = this.formBuilder.group({     
          fcDate: [''],     
          myControl: [''],    
          fcOT:[''],      
        });


        this.employeelist = this.data.employeelist;
      //  this.empid= _.pluck(this.employeelist, 'Name');
        this.flag = this.data.flag;
        this.LedgerID = this.data.ledgerid;
        this.date = this.data.date;
        this.temp= this.data.Name;
        this.cid=this.data.CID;
        this.OT=this.data.OT;
        this.myControl.setValue({Name: this.temp});

        this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith<string | User>(''),
          map(value => typeof value === 'string' ? value : value.Name),
          map(name => name ? this._filter(name) : this.empid.slice())
        );
       }

  ngOnInit(): void {
  
    this.myControl.disable()
   
    //console.log('dialogdata',this.data)
    // if (this.flag == 'EDIT')
    // {
    //  this.onvaluechanged();
    // }
    this.OTFormGroup.controls.fcDate.setValue(this.date);
    this.OTFormGroup.controls.fcOT.setValue(this.OT);
  }

  private _filter(name: string): string[] {

    const filterValue = name.toLowerCase();   
      return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
 
  }
  displayFn(empid?: User): string | undefined {
    return empid ? empid.Name : undefined;
  }
  selectedproduct(event) {    
    this.LedgerID=event.option.value;   
    this.myControl.setValue({Name: event.option.value }); 
    this.LedgerID=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));     
    
  }

  onCloseDialog()
  {
    // this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    // .afterClosed().subscribe(res => {
    //   if (res) {
    this.dialogRef.close({event: 'CLEAR'});
    //   }
    // });
  }
  onClose(model){
    this.dialogRef.close({event: this.flag, data: model});
  }
  
  Submit()
  {
   let params = {

      cid: this.cid,
      flag: 'ADD',
      Date: this.datePipe.transform(this.date, 'dd-MM-yyyy'),
      Time: this.OTFormGroup.get('fcOT').value,   
      ledgerid: this.LedgerID
    
    };

  this.service.httpPost('/timepunchreport/SetEmployeeOT',params)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
    //console.log('HI',users)
      if (users.errno === 0){

        this.notificationService.success('Updated successfully');
        this.onCloseDialog();
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }
  
}
