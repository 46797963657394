import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmiteventserviceService {

  private menuSelectSubject = new Subject<any>();

  menuSelect$: Observable<any> = this.menuSelectSubject.asObservable();

  emitMenuSelect(data: any) {
    this.menuSelectSubject.next(data);
  }
}
