import { Component, OnInit } from '@angular/core';
import { MatDialog,MatDialogConfig  } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { UserService } from './../../Admin/Users/userValidate';
import { ResourceallocationbydayComponent } from '../resourceallocationbyday/resourceallocationbyday.component';


@Component({
  selector: 'app-project-allocation-by-day',
  templateUrl: './project-allocation-by-day.component.html',
  styleUrls: ['./project-allocation-by-day.component.scss'],
})
export class ProjectAllocationByDayComponent implements OnInit {

  dataSource: ProjectAllocationDataGrid[];
  ProjectAllocationDataGrids: any;
  //projectdata:string[];
  SelectedCompanyID: number;
  projectdata: any;
  companies: any;
  Departments: any;
  setWeekEnd: number;
  setHolidays: number;

  selectedCID: number;
  selectedprojectid: any;
  selectedprojectname: any;
  selecteddepartments: number;
  projectid: string[]= [];

  ProjectDayFormGroup: FormGroup;
  ProjectDayDataFormGroup: FormGroup;
  isEditable = true;

  myControl = new FormControl('', [Validators.required]);
  //options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  cid:any;
  oneJan: any;
  numberOfDays: number;
  curMonth: any;
  count:any;
  addbtnvisible: boolean = false;
  viewbtnvisible: boolean = false;
  constructor(private service: HttpserviceService, private service1: UserService, 
    private formBuilder: FormBuilder, private dialog: MatDialog, private generic: GenericService,
    public notificationService: NotificationService, private datePipe: DatePipe) {

    this.ProjectDayFormGroup = this.formBuilder.group({
      fcCompany: ['', Validators.required] ,     
      fcDepartment: ['', Validators.required],
      fcFromDate: ['', Validators.required],
      fcToDate: ['', Validators.required]          
    });

    this.ProjectDayDataFormGroup = this.formBuilder.group({    
    });

  }

  ngOnInit(): void {   
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.PageLoad();
    this.ProjectDayFormGroup.controls.fcCompany.setValue(this.cid);
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  changesite(e) {
    // console.log('change',e.value);
    this.cid=e.value;
    this.PageLoad();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.projectid.filter(option => option.toLowerCase().includes(filterValue));

  }

  selectedproduct(event) {
    //this.selectedprojectid=event.option.value;
    this.selectedprojectname = event.option.value;
    this.selectedprojectid = JSON.parse(_.pluck(_.where(this.projectdata, { 'ProjectID': event.option.value }), 'ProjectUID'));
  }

  PageLoad() {
    const params = {
      CID: this.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: 'PageLoad',
      EmployeeID: 'BHE 067',
      ProjectID: 0,
      RoleID: 0
    };
    
   // this.service.getprojectallcoationlist(params)
   this.service.httpPost('/ProjectAllocationByWeek/GetProjectAllocationData',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {        
            this.companies = result.respdata.Table;
            this.SelectedCompanyID = this.cid;
            this.projectdata = result.respdata.Table1;            
            this.Departments = result.respdata.Table2;
            this.projectid = _.pluck(this.projectdata, 'ProjectID');                    
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });
  }

   Submit() {
    if (this.ProjectDayFormGroup.invalid) {
      this.notificationService.warn('Please select project and department');     
      return;
      }   
    const params = {
      CID: this.ProjectDayFormGroup.get('fcCompany').value, //101,
      Flag: 'LOAD',
      EmployeeID: '',
      ProjectID: this.selectedprojectid,
      RoleID: this.ProjectDayFormGroup.get('fcDepartment').value, //this.selecteddepartments,
      DepartmentID: this.ProjectDayFormGroup.get('fcDepartment').value,
      FromDate: this.datePipe.transform(this.ProjectDayFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
      ToDate: this.datePipe.transform(this.ProjectDayFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
      this.datePipe.transform(this.ProjectDayFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.ProjectDayFormGroup.get('fcToDate').value,"dd-MM-yyyy")   
    }; 
    //this.service.getprojectallcoationlist(params)
    this.service.httpPost('/ProjectAllocationByDay/GetProjectAllocationDayData',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {          
            // const removeLedgerID = result.respdata.Table.map(({ LedgerID, ...item }) => item);
            // const removeResourceAllocationID = removeLedgerID.map(({ ResourceAllocationID, ...item }) => item);
            // this.ProjectAllocationDataGrids = removeResourceAllocationID; //result.respdata.Table;
            this.dataSource = result.respdata.Table;           
            console.log('sfsgsd',this.dataSource)
           // this.count=result.respdata.Table.length/2;          
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });
  }

  onSearchClear(){
    this.ProjectDayFormGroup.reset(); 
    this.ProjectAllocationDataGrids='';   
    this.myControl.setValue('');   
    this.ProjectDayFormGroup.controls.fcCompany.setValue(this.cid);  
  }
  SearchClear()
  {
    this.myControl.setValue('');  
  }
  excelImport(){

  }

  startweekno:number;
  endweekno:number;
  weekdiff:number;
  
  openDialog(Flag: string) {

    if (this.ProjectDayFormGroup.invalid) {
      this.notificationService.warn('Please select project and department');    
      return;
      }
          
     // console.log('FLAG',Flag);
      //console.log('Weekdiff',this.weekdiff);     
        
        const ProjectDataByDay = {
                          cid: this.ProjectDayFormGroup.get('fcCompany').value,       
                          projectuid: this.selectedprojectid,           
                          department: this.ProjectDayFormGroup.get('fcDepartment').value,     
                          fromdate: this.datePipe.transform(this.ProjectDayFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
                          todate: this.datePipe.transform(this.ProjectDayFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
                          this.datePipe.transform(this.ProjectDayFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.ProjectDayFormGroup.get('fcToDate').value,"dd-MM-yyyy"),                           
                          flag: Flag,
            }; 
              sessionStorage.setItem('ProjectDataByDay', JSON.stringify(ProjectDataByDay))
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus =  true;
           //   this.dialog.open(ResourceallocationbydayComponent,dialogConfig);
              const dialogRef = this.dialog.open(ResourceallocationbydayComponent,dialogConfig);
              dialogRef.afterClosed();
       
  }
}

export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
export class ProjectAllocationDataGrid {
  LedgerID: number;
  ResourceAllocationID: number;
  Name_Allotted: string;
  TotalAllotMins: number;
  TotalWorkMins: number;
  Type: string;
}

