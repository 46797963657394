import { Component, OnInit } from '@angular/core';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatChip } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
//import { MatDialogModule } from '@angular/material/dialog';
import { AddLeaveGroupPopUpComponent } from './add-leave-group-pop-up/add-leave-group-pop-up.component';
import { LinkLeaveTypePopupComponent } from './link-leave-type-popup/link-leave-type-popup.component';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';

@Component({
  selector: 'app-leavegroupconfig',
  templateUrl: './leavegroupconfig.component.html',
  styleUrls: ['./leavegroupconfig.component.css']
})

export class LeaveGroupConfigComponent implements OnInit {
  selectedCID: string | null = null;
  //uniqueCidList: string[] = [];
  uniqueCidList: any[] = [];
  
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['LeaveGroupID', 'LeaveGroupName', 'LeaveDisplayNames', 'Action'];

  constructor(private httpService: HttpserviceService, private dialog: MatDialog, private dialogService: DialogService) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    this.fetchCompanyIDs();
  }

  fetchCompanyIDs(): void {
    this.httpService.httpGet('/LeaveGroup/GetDistinctCIDs', '').subscribe(
      (response) => {
        console.log('API Response:', response);
        
        // Ensure the response contains an array at response.respdata

        if (response && response.respdata && Array.isArray(response.respdata)) {
          this.uniqueCidList = response.respdata; // Assign the array to uniqueCidList
          console.log('API this.uniqueCid:', this.uniqueCidList);
       
          // Set the first CID as selectedCID if available
          if (this.uniqueCidList.length > 0) {
            this.selectedCID = this.uniqueCidList[0].CID;
            this.fetchLeaveGroupConfiguration(this.selectedCID); // Fetch leave group data
          }
        }
      },
      (error) => {
        console.error('Error fetching Company IDs:', error); // Handle error
      }
    );
  }
  
  onChangeCID(): void {
    if (!this.selectedCID) return;
    this.fetchLeaveGroupConfiguration(this.selectedCID);
  }

  fetchLeaveGroupConfiguration(cid: string): void {
    console.log('Fetching Leave Group Configuration for CID:', cid);
 
    const params = { CID: cid, Flag: 1, LeaveGroupID: null, LeaveTypeIDs: null };
   
   
    this.httpService.httpPost('/LeaveGroup/GetLeaveGroupConfiguration', params).subscribe(
      (response) => {

        console.log('Leave Group Configuration response:', response);

      
        if (response && response.respdata && Array.isArray(response.respdata.Table)) {
          const groupedData = this.groupLeaveTypes(response.respdata.Table);
          this.dataSource.data = groupedData; 
          console.log('Data source set:', this.dataSource.data);
            
      } else {
          this.dataSource.data = [];
          console.log('No data found, setting data source to empty array.');
      }
       
      },
      (error) => {
        console.error('Error fetching Leave Group Configuration:', error);
      }
    );
  }

  openAddLeaveGroupDialog(): void {
    const dialogRef = this.dialog.open(AddLeaveGroupPopUpComponent, {
      autoFocus: false,
      data: { CID: this.selectedCID } 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchLeaveGroupConfiguration(this.selectedCID!);
      }
    });
  }

  editElement(element: any): void {

    const dialogRef = this.dialog.open(LinkLeaveTypePopupComponent, {
      autoFocus: false,
      data: element
    }); 

    console.log(element);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchLeaveGroupConfiguration(this.selectedCID!);
      }
    });
  }

  deleteElement(element: any): void {
   
    this.dialogService.openConfirmDialog(`Are you sure you want to delete the entry : ${element.LeaveGroupName} ?`)
    .afterClosed().subscribe(res => {
       if (res) {
          const params = {
            CID: this.selectedCID,
            Flag: 4, 
            LeaveGroupID: element.LeaveGroupID,
            LeaveGroupName: null,
            LeaveTypeIDs: null
          };

          this.httpService.httpPost('/LeaveGroup/SetLeaveGroupConfiguration', params).subscribe(
            (response) => {
              console.log('Leave group deleted:', response);
              this.fetchLeaveGroupConfiguration(this.selectedCID!); // Refresh the table
            },
            (error) => {
              console.error('Error deleting Leave Group:', error);
            }
          );
       }
      });
      this.fetchLeaveGroupConfiguration(this.selectedCID!);
  
   }

  private groupLeaveTypes(data: any[]): any[] {
    const grouped = data.reduce((acc, curr) => {
        const existingGroup = acc.find(item => item.LeaveGroupID === curr.LeaveGroupID);
        
        const leaveDisplayName = `${curr.LeaveTypeID} - ${curr.LeaveName}`;
        

        if (existingGroup) {
            existingGroup.LeaveDisplayNames.push(leaveDisplayName); // Keep it as an array
        } else {
            acc.push({
                CID: curr.CID,
                LeaveGroupID: curr.LeaveGroupID,
                LeaveGroupName: curr.LeaveGroupName,
                LeaveDisplayNames: [leaveDisplayName] 
            });
        }
        return acc;
    }, []);

    return grouped; 
  }

}
