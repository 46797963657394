import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ElementRef, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/Modules/user';


@Component({
  selector: 'app-linkticketaschild',
  templateUrl: './linkticketaschild.component.html',
  styleUrls: ['./linkticketaschild.component.scss'],
})
export class LinkticketaschildComponent implements OnInit {

  ChildTicketAddFormGroup:FormGroup;

  TicketID:any;
  cid:any;
  TicketList:any;
  public filteredList5;
  showSpinner:any;
  CurLedgerID:any;

  constructor(private service:HttpserviceService,private dialogService: DialogService, 
    private formBuilder: FormBuilder,private NotificationService:NotificationService,
    public dialogRef: MatDialogRef<LinkticketaschildComponent>, 
     @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log('data',this.data);

      this.cid=this.data.cid;

      this.ChildTicketAddFormGroup = this.formBuilder.group
      ({  
        fcTicket: ['', Validators.required],      
     }); 
     }

  ngOnInit() {
    this.TicketID=this.data.TicketID;
    this.pageLoad();
  }


  Submit()
  {
    if (this.ChildTicketAddFormGroup.invalid) {
      this.NotificationService.warn('Please select the ticket');     
      return;
      }  
      this.showSpinner=true;    
    let params; 
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.userid;

    params = { 
      CID:this.cid,
      Flag: "ADDCHILD",                     
      UserName:this.CurLedgerID,
      TicketID:this.data.TicketID,
      Type:this.ChildTicketAddFormGroup.get('fcTicket').value,
     }
     this.service.httpPost('/TicketCreator/SetQuickAdd',params)
     .subscribe(
       (data) => {
         let result: any;
         result = data;   
         this.showSpinner=true;
         setTimeout(() =>{
           this.showSpinner=false;
         },
         800)    
         if (result.errno === 1) {
           this.NotificationService.success(result.errdesc);
           this.onCloseDialog();
        
         }
         else {
           this.NotificationService.success(result.errdesc); //':: Problem details');
           this.onCloseDialog();
         }
       },
       (error: AppError) => {
         if (error instanceof BadInput)         
           console.log(error);
         else throw error;       
       });
  }

  onClear()
  {

  }

  pageLoad()
  {
    let params = {
      CID: this.cid,
      Flag: "LOADCHILD",
      TicketID:this.data.TicketID      
    };
    this.service.httpPost('/TicketCreator/GetQuickAdd',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;        
          if (result.errno === 0) {                   
            this.TicketList = result.respdata.Tickets; 
            this.filteredList5= this.TicketList.slice();
         
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onCloseDialog()
  {
    this.dialogRef.close({event: 'CLEAR'});
  }
}
