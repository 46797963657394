import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { User } from 'src/app/Modules/user';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';

@Component({
  selector: 'app-edit-email-tag-list',
  templateUrl: './edit-email-tag-list.component.html',
  styleUrls: ['./edit-email-tag-list.component.scss'],
})
export class EditEmailTagListComponent implements OnInit {
  cid:any;
  Flag:any;
  Type:any;
  TypeID:any;
  eMailTag:any;
  eMail:any;

  CurLedgerID:any
  EmailTagFormGroup: FormGroup;


  constructor(private service:HttpserviceService,
    private NotificationService:NotificationService,
    private formBuilder: FormBuilder
    ,private dialog: MatDialog,
    public dialogRef: MatDialogRef<EditEmailTagListComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      console.log('data',this.data)
      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.Type=this.data.Type;
      this.TypeID=this.data.TypeID;
      this.eMailTag=this.data.eMailTag;
      this.eMail=this.data.eMail;

      this.EmailTagFormGroup = this.formBuilder.group({  
        fcType:['', Validators.required],     
        fcTypeID:['',Validators.required],
        fcEmailTag:['', Validators.required], 
        fcEmail:['', Validators.required]   
      });  

      this.EmailTagFormGroup.controls.fcType.setValue(this.Type);
      this.EmailTagFormGroup.controls.fcTypeID.setValue(this.TypeID);      
      this.EmailTagFormGroup.controls.fcEmailTag.setValue(this.eMailTag);
      this.EmailTagFormGroup.controls.fcEmail.setValue(this.eMail);
    }

  ngOnInit() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }

  Submit()
  {
    let params = { 
      CID:this.cid,
      Flag: 'EditEmailTag', 
      Type: this.EmailTagFormGroup.get('fcType').value, 
      TypeID:  this.EmailTagFormGroup.get('fcTypeID').value,   
      eMailTag: this.EmailTagFormGroup.get('fcEmailTag').value,
      eMail:this.EmailTagFormGroup.get('fcEmail').value,     
      UserName: this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')
      
     };
    this.service.httpPost('/TicketConfiguration/SetTicketEmailConfigrationExcelImport',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          //this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.warn(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
        this.NotificationService.warn(error);
        else throw error;       
      });
  }
}
