import { CdkDragDrop, moveItemInArray, transferArrayItem,DragDropModule } from '@angular/cdk/drag-drop';
import { ElementRef, Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';

@Component({
  selector: 'app-kanbanboardconfigpopup',
  templateUrl: './kanbanboardconfigpopup.component.html',
  styleUrls: ['./kanbanboardconfigpopup.component.scss'],
})
export class KanbanboardconfigpopupComponent implements OnInit {

  KanbanBoardConfigPopup:any =FormGroup;
  Companies:any;
  pCID:any;
  CID:any;
  CurLedgerID:any;
  UserID:any;
  TemplateGroupID:any;
  
  KeyColumn:any;
  Status:any;
  Priority:any;
  Employee:any;
  CardViewData:any;
  filterCardViewData:any;
  boardType:any;
  flag:any;
  BoardName:any;
  userData:any;
  InticativeRelease:any;
  InticativeSprint:any;

  isChecked:boolean = false;

  ActiveData = [];

  InActiveData = [];
  dataSource:any;
  myArray:any []=[];
  TemplateID:number;


  constructor(private service: HttpserviceService,
    //private formBuilder: FormBuilder,
    public notificationService: NotificationService,    
    private formBuilder: FormBuilder,    
    private router: Router, 
    private dialog: MatDialog,    
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<KanbanboardconfigpopupComponent>,  @Inject(MAT_DIALOG_DATA) public data: any  
    
    ) {
      console.log('data',this.data);
      this.Companies=this.data.companydata  ;
      this.KeyColumn=this.data.keycolumn;
      this.Status=this.data.status;
      this.Priority=this.data.priority;
      this.InActiveData=this.data.employee;
      this.dataSource=this.data.employee;
      this.Employee=this.data.employee;
      this.userData=this.data.employee.slice();
      this.CardViewData=this.data.CardViewData;
      this.InticativeRelease=this.data.InticativeRelease;
      this.InticativeSprint=this.data.InticativeSprint
      this.filterCardViewData=this.data.CardViewData;
      this.flag=this.data.flag;
      this.BoardName=this.data.boardNmae;
      this.TemplateID=this.data.templateid;

    this.KanbanBoardConfigPopup= this.formBuilder.group({           
      fcCompany: ['']   ,
      fcBoardType: ['']   ,
      fcBoardName: ['']   ,
      fcEmployee   : ['']   ,      
      fcAllowUser:[''],
      fcCardView:['']
    });



   }

  ngOnInit() {
    this.pCID=sessionStorage.getItem('cid');
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserID=curUser.userid;
    this.TemplateGroupID=curUser.TemplateGroupID;
    this.CID=parseInt(this.pCID);
    this.KanbanBoardConfigPopup.controls.fcCompany.setValue(this.CID);
    this.KanbanBoardConfigPopup.controls.fcBoardType.setValue(104);
    this.KanbanBoardConfigPopup.controls.fcEmployee.setValue(this.UserID);

   

    //console.log('flag',this.flag)
    if(this.flag==='EDIT')
      this.getBoardData()
    else if(this.flag==='DELETE'){      
      this.getBoardData();
      this.DeleteRecord();
    }      
  }


  onClear(){

  }

  getBoardData(){
    let params = {
      CID:this.CID,
      Flag:"getBoardData",     
      UserID:this.UserID ,
      BoardID:this.BoardName,
      TemplateID: this.TemplateID
      }

      console.log('getboard',params)

      this.service.httpPost('/Kanbanboard/GetKanbanBoardConfig?',params)
      .subscribe(data => {
        let result: any;
        result=data;
        
        if (result.errno === 0){
          //console.log('result',result.respdata);     
          
          if(result.respdata.BoardData[0].KeyColumnID===101){
            this.dataSource=this.Status;
            this.InActiveData=this.Status;
          }          
          else if(result.respdata.BoardData[0].KeyColumnID===102){
            this.dataSource=this.Priority;
            this.InActiveData=this.Priority;
          }          
        
          this.KanbanBoardConfigPopup.controls.fcCompany.setValue(this.CID);
          this.KanbanBoardConfigPopup.controls.fcBoardType.setValue(result.respdata.BoardData[0].KeyColumnID);
          this.KanbanBoardConfigPopup.controls.fcBoardName.setValue(result.respdata.BoardData[0].BoardName);
          this.KanbanBoardConfigPopup.controls.fcEmployee.setValue(result.respdata.BoardData[0].CreatedBy);  
          console.log('JSON.parse(result.respdata.BoardData[0].AllowUsers)',JSON.parse(result.respdata.BoardData[0].AllowUsers))
          this.KanbanBoardConfigPopup.controls.fcAllowUser.setValue(JSON.parse(result.respdata.BoardData[0].AllowUsers));   
          this.KanbanBoardConfigPopup.controls.fcCardView.setValue(JSON.parse(result.respdata.BoardData[0].CardViewData));                       
          
          this.ActiveData=JSON.parse(result.respdata.BoardData[0].KeyValuesJSON)
          this.InActiveData= this.removeDuplicateData(this.InActiveData, this.ActiveData);
          //console.log('filtedata',this.InActiveData)
          if(result.respdata.BoardData[0].DefaultValue===0)
              this.isChecked=false;
          else
            this.isChecked=true;

        }
        else{
          this.notificationService.warn(result.errdesc); //':: Problem details');
        }
      }, 
      (error: AppError) => {
        if (error instanceof BadInput)           
        this.notificationService.warn(error);
        else throw error;       
      });
  }

  onCloseDialog(){   
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {       
        
          this.dialogRef.close({event: 'CLEAR'});
      }
    });
  }


  DeleteRecord(){   
    this.dialogService.openConfirmDialog('Are you sure do you want to delete this data?')
     .afterClosed().subscribe(res => {
       if (res) {
          this.Save();        
          this.dialogRef.close({event: 'CLEAR'});
      }
    });
  }

  onCheckboxChange(event: any) {
    // You can use the 'isChecked' property to get the current state of the checkbox    
    this.isChecked=event.checked;

  }

//  async Save(){
//     // const filteredcolumn = this.myArray.map(item => { return {
//     //   TicketID: item.ticketID,
//     //   statusname: item.ID          
//     // }});
//     var def;
    
//     if(this.isChecked===true)
//         def=1;
//     else
//         def=0;

//     let params = {
//       CID:this.CID, 
//       Flag:this.flag,	
//       Default:def,
//       BoardName:this.KanbanBoardConfigPopup.get('fcBoardName').value,
//       KeyColumn:this.KanbanBoardConfigPopup.get('fcBoardType').value,
//       KeyJSONValue:JSON.stringify(this.ActiveData),
//       UserID	:this.KanbanBoardConfigPopup.get('fcEmployee').value,
//       JsonAllowUser:JSON.stringify(this.KanbanBoardConfigPopup.get('fcAllowUser').value),
//       //KeyColumn  :this.KeyColumn[0].KeyColumn
//     };
  
//     //console.log('params',params)
//     let data=await this.service.httpPost('/Kanbanboard/SetKanbanBoardConfig',params)
//       .toPromise ().then(data=> {            
//           let result: any;
//           result = data;
//           if (result.errno === 0) {        
//             if(this.flag!='DELETE')                      
//              this.notificationService.success('Successfully Updated')           
//           }
//           else {
//             this.notificationService.success(result.errdesc); 
//           }
//         },
//         (error: AppError) => {
//           if (error instanceof BadInput)           
//             console.log(error);
//           else throw error;       
//         });
//   }


  Save(){
    // const filteredcolumn = this.myArray.map(item => { return {
    //   TicketID: item.ticketID,
    //   statusname: item.ID          
    // }});
    var def;
    
    if(this.isChecked===true)
        def=1;
    else
        def=0;

    let params = {
      CID:this.CID, 
      Flag:this.flag,	
      Default:def,
      BoardName:this.KanbanBoardConfigPopup.get('fcBoardName').value,
      KeyColumn:this.KanbanBoardConfigPopup.get('fcBoardType').value,
      KeyJSONValue:JSON.stringify(this.ActiveData),
      UserID	:this.KanbanBoardConfigPopup.get('fcEmployee').value,
      JsonAllowUser:JSON.stringify(this.KanbanBoardConfigPopup.get('fcAllowUser').value),
      CardViewData:JSON.stringify(this.KanbanBoardConfigPopup.get('fcCardView').value)
      //KeyColumn  :this.KeyColumn[0].KeyColumn
    };

    
  
    console.log('params',params)
    this.service.httpPost('/Kanbanboard/SetKanbanBoardConfig',params)
    .subscribe(data =>  {            
          let result: any;
          result = data;
          if (result.errno === 0) {        
            if(this.flag!='DELETE')                      
             this.notificationService.success('Successfully Updated')           
          }
          else {
            this.notificationService.success(result.errdesc); 
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }

  selected(event) {
    let target = event.source.selected._element.nativeElement;
    this.boardType = target.innerText.trim();
    if(this.boardType==='Status'){
      this.dataSource=this.Status;
      this.InActiveData=this.Status;
      this.ActiveData=[];
    }      
    else if(this.boardType==='Priority'){
      this.dataSource=this.Priority;
      this.InActiveData=this.Priority;
      this.ActiveData=[];
    }      
    else if(this.boardType==='Assignee'){
      this.dataSource=this.Employee;
      this.InActiveData=this.Employee;
      this.ActiveData=[];
    }
    else if(this.boardType==='InticativeRelease'){
      this.dataSource=this.InticativeRelease;
      this.InActiveData=this.InticativeRelease;
      this.ActiveData=[];
    }
    else if(this.boardType==='InticativeSprint'){
      this.dataSource=this.InticativeSprint;
      this.InActiveData=this.InticativeSprint;
      this.ActiveData=[];
    }
      
    
  }
  
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      console.log('dropped Event', `> dropped '${event.item.data}' into '${event.container.id}'`);
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      console.log('dropped Event', `> dropped '${event.item.data}' into '${event.container.id}'`);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

      //     this.pre = `
      // activeCustomers:
      // ${JSON.stringify(this.activeCustomers, null, ' ')}

      // inactiveCustomers:
      // ${JSON.stringify(this.inactiveCustomers, null, ' ')}`;
    }

    doFilter(filterValue: string) {
            
      const result = this.dataSource.filter(option => option.ColumnName.toLowerCase().includes(filterValue)); 
      
      this.InActiveData=result;
      //console.log('search',result)
    }

     removeDuplicateData(array1: Column[], array2: Column[]): Column[] {
      const array2Ids = new Set(array2.map(item => item.ColumnID));
    
      // console.log('uniqueArray',array2Ids)
      // console.log('array1',array1)
      // console.log('array1',array2)
      const uniqueArray = array1.filter(item => !array2Ids.has(item.ColumnID));      
    
      // console.log('uniqueArray',uniqueArray)
      return uniqueArray;
    }

    

}

export class User {
  userid: number;
  ledgerid: number;
  groupid: number;
  username: string;
  token?: string;
  configparam: any;
  salesmanid: number;
  TemplateGroupID:number;
  UserType:string;
  dtsiteid:any;
  ds_dashboard:any;
  // elapsedtime?: number;
}

interface Column {
  ColumnID: number;
  ColumnName: string;
}
