import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import * as _ from 'underscore';
import { DatePipe } from '@angular/common';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';


@Component({
  selector: 'app-licensecreatesub',
  templateUrl: './licensecreatesub.component.html',
  styleUrls: ['./licensecreatesub.component.css']
})
export class LicensecreatesubComponent {

  LicenseSubFormGroup: FormGroup;
  Clients :any;
  curdate= new Date();
  
  PCName:any;
  showSpinner:any;
  Flag:any;
  public filteredList5;
  dataSource:any;
  savevisibile:boolean=false;
  downloadvisible:boolean=false;

  Status = [
    { name: 'False' },
    { name: 'True' }    
  ];


  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder, private datePipe: DatePipe,
    private dialog: MatDialog,private dialogService: DialogService,public dialogRef: MatDialogRef<LicensecreatesubComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {                
       //console.log('dialogdata',this.data);

       this.Flag=this.data.Flag;

       if(this.data.Flag==='VIEW')
       {
          this.savevisibile=false;
          this.downloadvisible=true;
       }
       else
       {
          this.savevisibile=true;
          this.downloadvisible=false;
       }

      this.LicenseSubFormGroup = this.formBuilder.group({  
        fcClient: ['', Validators.required],
        fcNoofUsers: ['', Validators.required],     
        fcNoofCompanies: ['', Validators.required],     
        fcExpiryDate: ['', Validators.required],     
        fcServerID: [''],           
        fcCreatedBy: ['', Validators.required],           
        fcCreatedDate: ['', Validators.required],   
        fcCreatedPC: [''],    
        fcRestriction: ['', Validators.required],           
        fcComment: [''],           
        fcBatch: [''],           
        fcBin: [''],           
        fcSerial: [''],           
      });       

    }

    ngOnInit(): void {     
     
      // console.log('curdate',this.curdate)
      // console.log('time',time)
      this.getddllist(); 
      if(this.Flag ==='EDIT' || this.Flag ==='VIEW') 
      {
        this.LicenseSubFormGroup.controls.fcCreatedPC.disable();
        this.LicenseSubFormGroup.controls.fcCreatedDate.disable();
        this.EditData();
      }
      else
      {
        let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
        this.LicenseSubFormGroup.controls.fcBatch.setValue('False');
        this.LicenseSubFormGroup.controls.fcBin.setValue('False');
        this.LicenseSubFormGroup.controls.fcSerial.setValue('False');   
        this.LicenseSubFormGroup.controls.fcCreatedBy.setValue(curUser.username);
        this.LicenseSubFormGroup.controls.fcCreatedDate.setValue(this.curdate);
        this.LicenseSubFormGroup.controls.fcExpiryDate.setValue(this.curdate);
        this.LicenseSubFormGroup.controls.fcCreatedPC.disable();
        this.LicenseSubFormGroup.controls.fcCreatedDate.disable();
        this.getddllist();
      }
    }

    changeclient(e) {    
      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      let params = {  
        CID:101, 
        Flag: "LOADEXISTDATA",
        Slno:e.value
      };
      this.service.httpPost('/LicenseCreate/Getdropdownvalues',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;            
            if (result.errno === 0) {                  
              this.dataSource = result.respdata.Existdata; 

             // console.log('hi',result.respdata.Existdata.length)
              
              if(this.dataSource.length > 0)       
              {
                  this.LicenseSubFormGroup.controls.fcClient.setValue(this.dataSource[0].Value);
                  this.LicenseSubFormGroup.controls.fcNoofUsers.setValue(this.dataSource[1].Value);
                  this.LicenseSubFormGroup.controls.fcNoofCompanies.setValue(this.dataSource[2].Value);          
                  this.LicenseSubFormGroup.controls.fcExpiryDate.setValue( new Date(this.dataSource[3].Value));
                  this.LicenseSubFormGroup.controls.fcServerID.setValue(this.dataSource[4].Value);
                  //this.LicenseSubFormGroup.controls.fcCreatedBy.setValue(this.dataSource[5].Value);
                  this.LicenseSubFormGroup.controls.fcCreatedBy.setValue(curUser.username);
                  this.LicenseSubFormGroup.controls.fcCreatedDate.setValue(this.curdate);
                  this.LicenseSubFormGroup.controls.fcCreatedPC.setValue(this.dataSource[7].Value);
                  this.LicenseSubFormGroup.controls.fcRestriction.setValue(this.dataSource[8].Value);
                  this.LicenseSubFormGroup.controls.fcComment.setValue(this.dataSource[9].Value);
                  this.LicenseSubFormGroup.controls.fcBatch.setValue(this.dataSource[10].Value);
                  this.LicenseSubFormGroup.controls.fcBin.setValue(this.dataSource[11].Value);
                  this.LicenseSubFormGroup.controls.fcSerial.setValue(this.dataSource[12].Value);
              }
              else
              {               
                  this.LicenseSubFormGroup.controls.fcNoofUsers.setValue('');
                  this.LicenseSubFormGroup.controls.fcNoofCompanies.setValue('');
                  this.LicenseSubFormGroup.controls.fcRestriction.setValue('');
                  this.LicenseSubFormGroup.controls.fcComment.setValue('');                     
                  this.LicenseSubFormGroup.controls.fcServerID.setValue('');
                  this.LicenseSubFormGroup.controls.fcCreatedBy.setValue(curUser.username);
                  this.LicenseSubFormGroup.controls.fcCreatedDate.setValue(this.curdate);
                  this.LicenseSubFormGroup.controls.fcExpiryDate.setValue(this.curdate);
                  this.LicenseSubFormGroup.controls.fcCreatedPC.disable();
                  this.LicenseSubFormGroup.controls.fcCreatedDate.disable();
              }
            }
            else {
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }


    EditData()
    {
      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      let params = {  
        CID:101, 
        Flag: "EDITDATA",
        Slno:this.data.Slno
      };
      this.service.httpPost('/LicenseCreate/Getdropdownvalues',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;            
            if (result.errno === 0) {                  
              this.dataSource = result.respdata.Editdata;   
            
              // if(this.dataSource.length > 0)       
              // {
                  this.LicenseSubFormGroup.controls.fcClient.setValue(this.dataSource[0].Value);
                  this.LicenseSubFormGroup.controls.fcNoofUsers.setValue(this.dataSource[1].Value);
                  this.LicenseSubFormGroup.controls.fcNoofCompanies.setValue(this.dataSource[2].Value);          
                  this.LicenseSubFormGroup.controls.fcExpiryDate.setValue( new Date(this.dataSource[3].Value));
                  this.LicenseSubFormGroup.controls.fcServerID.setValue(this.dataSource[4].Value);
                  //this.LicenseSubFormGroup.controls.fcCreatedBy.setValue(this.dataSource[5].Value);
                  this.LicenseSubFormGroup.controls.fcCreatedBy.setValue(curUser.username);
                  this.LicenseSubFormGroup.controls.fcCreatedDate.setValue(this.curdate);
                  this.LicenseSubFormGroup.controls.fcCreatedPC.setValue(this.dataSource[7].Value);
                  this.LicenseSubFormGroup.controls.fcRestriction.setValue(this.dataSource[8].Value);
                  this.LicenseSubFormGroup.controls.fcComment.setValue(this.dataSource[9].Value);
                  this.LicenseSubFormGroup.controls.fcBatch.setValue(this.dataSource[10].Value);
                  this.LicenseSubFormGroup.controls.fcBin.setValue(this.dataSource[11].Value);
                  this.LicenseSubFormGroup.controls.fcSerial.setValue(this.dataSource[12].Value);
              // }
              // else
              // {
              //   this.onClear();
              // }
            }
            else {
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }

    getddllist()
    {
      let params = {  
        CID:101, 
        Flag: "LOADDDL"
      };
      this.service.httpPost('/LicenseCreate/Getdropdownvalues',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;            
            if (result.errno === 0) {                  
              this.Clients = result.respdata.Clients;   
              this.PCName=result.respdata.Machine;

              this.filteredList5= this.Clients.slice();

              this.LicenseSubFormGroup.controls.fcCreatedPC.setValue(this.PCName[0].MachineName);
            }
            else {
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }

    

    Submit() {   
      if (this.LicenseSubFormGroup.invalid) {
        this.NotificationService.warn('Please fill the mandatory fields');     
        return;
        }  
      this.showSpinner=true;
      let params; 
      const curdatetime = new Date();
      const time = curdatetime.toLocaleTimeString('en-US', { hour12: false });

        params = { 
            CID:101,
            Flag: this.Flag,          
            Companyname:this.LicenseSubFormGroup.get('fcClient').value,   
            NumberofUsers:  this.LicenseSubFormGroup.get('fcNoofUsers').value,   
            NumberofCompanies: this.LicenseSubFormGroup.get('fcNoofCompanies').value,   
            ExpiryDate:  this.datePipe.transform(this.LicenseSubFormGroup.get('fcExpiryDate').value,"dd/MMM/yyyy"),           
            ServerID:this.LicenseSubFormGroup.get('fcServerID').value,   
            CreatedBy:this.LicenseSubFormGroup.get('fcCreatedBy').value,   
            CreatedDate: this.datePipe.transform(this.LicenseSubFormGroup.get('fcCreatedDate').value,"dd-MM-yyyy")+' '+time,  
            CreatedPC:this.LicenseSubFormGroup.get('fcCreatedPC').value,   
            Restriction:this.LicenseSubFormGroup.get('fcRestriction').value,   
            Comment:this.LicenseSubFormGroup.get('fcComment').value,   
            BatchTracking:this.LicenseSubFormGroup.get('fcBatch').value,   
            BinTracking:this.LicenseSubFormGroup.get('fcBin').value,   
            SerialTracking:this.LicenseSubFormGroup.get('fcSerial').value,   
         }
  
   
      this.service.httpPost('/LicenseCreate/SetLicense',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;   
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)    
          if (result.errno === 0) {
            this.NotificationService.success(result.errdesc);
            this.onCloseDialog();
            //console.log('Project list: ', result.respdata);
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
            this.onCloseDialog();
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)         
            console.log(error);
          else throw error;       
        });
  
    }


    onCloseDialog()
    {
        // this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
        // .afterClosed().subscribe(res => {
        //   if (res) {
              this.LicenseSubFormGroup.reset();
              this.dialogRef.close();
          //    }
          // });
    }
    downloadTextFile(filename: string, text: string) {
      // Create a Blob with the text content and the correct MIME type (text/plain)
      const blob = new Blob([text], { type: 'text/plain' });
  
      // Create a link element
      const link = document.createElement('a');
  
      // Create an object URL from the Blob
      const url = window.URL.createObjectURL(blob);
  
      // Set the href of the link to the object URL
      link.href = url;
  
      // Set the download attribute to the desired file name
      link.download = filename;
  
      // Append the link to the body (it's hidden, but this is required for the click to work in some browsers)
      document.body.appendChild(link);
  
      // Programmatically click the link to trigger the download
      link.click();
  
      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
  
      // Revoke the object URL after download (to free memory)
      window.URL.revokeObjectURL(url);
  }

    onDownloadLicense()
    {
      
      let params = {  
        CID:101, 
        Flag:"DOWNLOADLIC",
        Slno:this.data.Slno
      };
      this.service.httpPost('/LicenseCreate/Getdropdownvalues',params)
        .subscribe (
          (data) => {
            let result: any;
            result = data;            
            if (result.errno === 0) {   
            //  console.log('hi',result.respdata);     
              this.downloadTextFile(this.data.CompanyName+'_LIC.txt', result.respdata);
              this.NotificationService.success('License downloaded successfully');
            }
            else {
              this.NotificationService.success(result.errdesc); //':: Problem details');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
    }

    onClear()
    {
      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      this.LicenseSubFormGroup.reset();
      this.LicenseSubFormGroup.controls.fcBatch.setValue('False');
      this.LicenseSubFormGroup.controls.fcBin.setValue('False');
      this.LicenseSubFormGroup.controls.fcSerial.setValue('False');   
      this.LicenseSubFormGroup.controls.fcCreatedBy.setValue(curUser.username);
      this.LicenseSubFormGroup.controls.fcCreatedDate.setValue(this.curdate);
      this.LicenseSubFormGroup.controls.fcExpiryDate.setValue(this.curdate);
      this.LicenseSubFormGroup.controls.fcCreatedPC.disable();
      this.LicenseSubFormGroup.controls.fcCreatedDate.disable();
      this.getddllist();
    }
}
