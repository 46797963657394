import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit } from '@angular/core';
// import { FormGroup } from '@angular/forms';
 import { FormGroup,  Validators, FormBuilder } from '@angular/forms';
import * as _ from 'underscore/underscore-min.js';
import { MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';



@Component({
  selector: 'app-leavetypemasterview',
  templateUrl: './leavetypemasterview.component.html',
  styleUrls: ['./leavetypemasterview.component.css']
})
export class LeavetypemasterviewComponent implements OnInit {

  LeaveTypeMasterView:FormGroup;
  dataSource:any;
  LeaveDetails:any;
  Companies:any;
  LeaveID:any;
  Status:any;
  PaidLeave:any;
  checkBoxesMode: string;
  isTzidVisible: boolean ;
  isStatusVisible: boolean ;
  isALPIDVisible: boolean ;
  CurLedgerID:number;
  LeaveTypeDetails:any;
  onSelectionChange:boolean;
  Flag:string;
  btnvisible: boolean = false;
  CID:Number;
  selectedEffortSlno:any;
  startEditAction: string = "click";
  selectTextOnEditStart: boolean = true;
  cid:any;
  rowcount:any;
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private dialogService: DialogService,private formBuilder: FormBuilder,
    public digRef: MatDialogRef<LeavetypemasterviewComponent>,@Inject(MAT_DIALOG_DATA) public data: any)  
     {
      this.CID=this.data.CID;
      this.data.Flag=="View"?this.btnvisible=false:this.btnvisible=true;
      this.Flag=this.data.Flag=="View" ?"EDIT":this.data.Flag;
         this.LeaveTypeMasterView = this.formBuilder.group
         ({  
            fcCompany: ['', Validators.required] ,             
            fcLeaveID: ['', Validators.required] ,
            fcLeaveType:['',Validators.required],
            fcStatus:['',Validators.required],
            fcPaidLeave:['',Validators.required]
        }); 
        
        this.checkBoxesMode = 'always'
     }

  ngOnInit(): void 
  {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.getddllist();  
    this.isTzidVisible=false;
    this.isStatusVisible=false;
    this.isALPIDVisible=false;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.LeaveTypeMasterView.controls.fcCompany.setValue(this.cid);
  }

  showDetails(e)
  {
    // console.log('TEST',e);
  

  }
  getddllist()
  {
    let params = {
      CID: this.cid,
      LeaveID:0,
      Flag: "DDL_COMBO",         
    };
    this.service.httpPost('/leavetypemaster/GetLeaveTypeMaster',params)
      .subscribe (
        (data) => { 
          let result: any;
          result = data;         
          console.log('data ', data);
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.LeaveID = result.respdata.LeaveType;
            // this.Leave =result.respdata.LeaveType;
            this.Status = result.respdata.Status;  
            this.PaidLeave = result.respdata.PaidLeave;  
            this.dataSource=result.respdata.CategoryType;    
            this.LeaveDetails=result.respdata.GridData;   
            this.rowcount=result.respdata.Count;                                                             
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  changesite(e) {   
    this.cid=e.value;
    this.getddllist();
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.LeaveTypeMasterView.reset();         
    this.dataSource='';  
    this.LeaveDetails='';
    this.LeaveTypeMasterView.controls.fcCompany.setValue(this.cid);
  }

  selectedleavetype(event)
  {
    console.log('')
    let params = {
      CID: this.LeaveTypeMasterView.get('fcCompany').value,
      Flag: "EDITDATA", 
      // Timezone:event.value,
     // LeaveID:this.LeaveTypeMasterView.get('fcLeaveID').value 
     LeaveID:event.value      
    };
    this.service.httpPost('/leavetypemaster/GetLeaveTypeMaster',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;         
          console.log('data ', data);
          if (result.errno === 0) {     
              
            this.LeaveTypeDetails=result.respdata.LeaveTypeDetails;
            this.LeaveTypeMasterView.controls.fcLeaveType.setValue(this.LeaveTypeDetails[0].LeaveType);
            this.LeaveTypeMasterView.controls.fcStatus.setValue(this.LeaveTypeDetails[0].Status);
            this.LeaveTypeMasterView.controls.fcPaidLeave.setValue(this.LeaveTypeDetails[0].PaidLeave);
     
            this.dataSource=result.respdata.WeekEnd;            
            this.LeaveDetails = result.respdata.LeaveDetails;                              
            // this.dataSource=result.respdata.HoliDays;   

            // this.allWeekends= _.pluck(this.Days, 'Day');    
            // this.weekends= _.pluck(result.respdata.WeekEnd, 'WeekEnds');        
            this.selectedEffortSlno=_.pluck(_.where(this.dataSource, { 'IncludeWith': 1 }), 'CategoryType');         

            // if(this.WorkingHrs[0].WorkingHours != undefined)
            //   this.HrPolicyFormGroup.controls.fcWorkingHrs.setValue(this.WorkingHrs[0].WorkingHours);
            // else if(this.WorkingHrs[0].WorkingHours == undefined)
            //   this.HrPolicyFormGroup.controls.fcWorkingHrs.setValue('');
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  Submit() { 

    this.logEvent;
    let params = {     
      CID: this.LeaveTypeMasterView.get('fcCompany').value,
      Flag: this.Flag,           
      LeaveID:this.LeaveTypeMasterView.get('fcLeaveID').value,
      LeaveType:this.LeaveTypeMasterView.get('fcLeaveType').value,
      Status:this.LeaveTypeMasterView.get('fcStatus').value,
      PaidLeave:this.LeaveTypeMasterView.get('fcPaidLeave').value,
      UserID:this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid'),
      dt_Holidays: this.dataSource    ,
      dt_LeaveDetails: this.LeaveDetails        
    };
    this.service.httpPost('/leavetypemaster/SetLeaveTypeMaster',params)
    .subscribe(
      (data) => {
        let result: any;       
        result = data;           
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);         
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
  Edit(){


  }
  
  Delete()
  {

  }

  onClear()
  {
    this.LeaveTypeMasterView.reset();       
    // this.fc.reset();    
    this.getddllist(); 
    this.dataSource='';  
    this.LeaveDetails='';
    this.LeaveTypeMasterView.controls.fcCompany.setValue(this.cid);
  }

  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
            this.LeaveTypeMasterView.reset();
            this.digRef.close();
          }
        });
  }


  logEvent(eventName, e)
  {
    // console.log("kk",e)
  }

  onCellPrepared(e) {
    if (e.rowType !== 'data' || e.columnIndex > 0 ) {
      return;
      
    }
    if (e.rowIndex % this.rowcount.length === 0 && ( e.columnIndex == 0  )) {
      e.cellElement.rowSpan = this.rowcount.length;
      e.cellElement.innerHTML = '<div>' + e.value + '</div>';
      // console.log("b",e)
    } 
    else {
      e.cellElement.style.display = 'none';
      // console.log("c",e)
    }
  //   if(e.rowType === 'header') {
  //     e.cellElement.css("backgroundColor", "blue");
  //  }
  }
  
  onSelectionChanged(e) 
  { // Handler of the "selectionChanged" event
    // if(this.onSelectionChange===true)
    // {
      //console.log('hdgfd',"hi");
      const currentSelectedRowKeys = e.currentSelectedRowKeys;
      const currentDeselectedRowKeys =e.currentDeselectedRowKeys;

      if(currentSelectedRowKeys.length>0){
        
        for (let key of Object.keys(this.dataSource)) {
          let keydata = this.dataSource[key];     
          let curSelecvalue=currentSelectedRowKeys;
          console.log('keydata',JSON.stringify(keydata['CategoryType']));    
          console.log('hdgfduiouu',"hi",curSelecvalue[0]);      
        if (JSON.stringify(curSelecvalue[0])===JSON.stringify(keydata['CategoryType']))
         {
            keydata['IncludeWith'] = 1;
               console.log('hdgfd',this.dataSource);
          }    
        }
      }

      else if(currentDeselectedRowKeys.length>0){
        for (let key of Object.keys(this.dataSource)) {
          let keydata = this.dataSource[key];          
          let curdeSelecvalue=currentDeselectedRowKeys;   

          if (JSON.stringify(curdeSelecvalue[0])===JSON.stringify(keydata['CategoryType'])){
            keydata['IncludeWith'] =0;
              
          }   
          // else{
          //   keydata['IncludeWith'] = keydata['IncludeWith'];
          //   keydata['ChkStatus'] =0;     
          // } 
        }
      }
      
    // }
    // else
    // {
    //   this.onSelectionChange=true;
    // }
    
  }
  
}