import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';

@Component({
  selector: 'app-userandgrouppermission',
  templateUrl: './userandgrouppermission.component.html',
  styleUrls: ['./userandgrouppermission.component.css']
})
export class UserandgrouppermissionComponent {
  LayoutName:any='';
  flag:any;
  setDefault:boolean=false;
  lablname:any;
  grouplist:any;
  userlist:any;
  user:any=[];
  Group:any=[];
  MenuID:any;

  constructor(private datePipe: DatePipe,private sharedService: GenericService,private service:HttpserviceService, private NotificationService:NotificationService,public dialogRef: MatDialogRef<UserandgrouppermissionComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) 
  {
    this.LayoutName = this.data.LayoutName;
    this.user=this.data.userrights;
    this.Group=this.data.grouprights;
    this.flag=this.data.flag;
    this.MenuID=this.data.MenuID;
  }
  
  ngOnInit()
  {
    this.loaddata();
  }
  Submit()
  {
    this.dialogRef.close({event: this.flag,userrights:this.user,grouprights:this.Group,LayoutName:this.LayoutName});
  }

onCloseDialog()
{
  this.dialogRef.close({event:'CLEAR',userrights:this.user,grouprights:this.Group,LayoutName:this.LayoutName});
}


async  loaddata()
  {
    

    let params = {
      _CID: JSON.parse(sessionStorage.getItem('cid')),
      _MenuID:this.MenuID,
      _Flag:'Permission',
      _Condition:this.LayoutName,
      FromDate:new Date(),
      ToDate:new Date(),
      UserName:this.flag,
      GroupID:"",
      _FilterName:""

        }

       let t=await this.service.httpPost('/MainForm/GetGridLayout?',params)
        .toPromise().then(
          (data) => {
            let result: any;
            result=data;
    
            if (result.errno === 0){

  
                this.userlist =result.respdata.Table;
                this.grouplist= result.respdata.Table1;

               // console.log('result.respdata.Table2',result.respdata.Table2[0].UserRights)

                let arrayOfObjects =[];
                let  arrayOfObjects1 =[];

                if (result.respdata.Table2[0].UserRights !==undefined && result.respdata.Table2[0].UserRights !==null) {
                  try {
                    // Parse the JSON string if it is available
                    arrayOfObjects = JSON.parse(result.respdata.Table2[0].UserRights) ;
                    this.user  = arrayOfObjects.map(obj => obj.Name);
                  } catch (error) {
                  }
                } else {
                  arrayOfObjects1 = []; // Default to an empty array
                }
               
                if (result.respdata.Table2[0].GroupRights !==undefined && result.respdata.Table2[0].GroupRights !==null) {
                  try {
                    // Parse the JSON string if it is available
                    arrayOfObjects1 = JSON.parse(result.respdata.Table2[0].GroupRights);
                    this.Group  = arrayOfObjects1.map(obj => obj.Name);
                  } catch (error) {
                  }
                } else {
                  arrayOfObjects1 = []; // Default to an empty array
                }

                

                //this.Group  = arrayOfObjects1.map(obj => obj.Name);
                
            }
            else{
              this.NotificationService.success(result.errdesc); //':: Problem updating user');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });

  }

  

}
