import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../Modules/user';
import {FormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map, pluck} from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { HttpserviceService } from '../../../.././../Services/httpServices/httpservice.service';
import { AuthenticationService } from '../../../.././../Services/OtherServices/authentication.service';


interface Item {
  code: string;
  desc: string;
}

@Component({
  selector: 'app-itempricelist',
  templateUrl: './itempricelist.component.html',
  styleUrls: ['./itempricelist.component.css']
})
export class ItempricelistComponent implements OnInit {
  spinnervisible: boolean = false;
  decimalplace: number = 2;
  decimalformat: string = '1.2-2';

  itemcode: string; //='GISCLIN3';
  error = '';
  barcode: string='""';
  description: string;
  stock;
  wac;
  showSpinner:any;
  itemDetails: any;
  itemprice: any = [];
  Companylist:any;
  WacVisibility: boolean = false;
  PricelistVisibility:boolean = false;
  cid:any;
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

  //settings: User = JSON.parse(sessionStorage.getItem('configparam'));

  
  items: Item[];
  itemdesc: string[]= [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  constructor(private service:HttpserviceService,  private authenticationService: AuthenticationService) { }

  ngOnInit(): void {   
    this.cid=JSON.parse(sessionStorage.getItem('cid'));      
    //let configparam = this.curUser.configparam.find(e => e.tag === 'DecimalPlace')['value']  
    let configparam = this.curUser.CustomerSettings.DecimalPlace;
    this.decimalplace = configparam;
    this.decimalformat = '1.'+ this.decimalplace+'-' + this.decimalplace;   
    this.loadItems();    
    this.cid=JSON.parse(sessionStorage.getItem('cid'));      
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
  }

  // changesite(e) {
  //   this.cid=e;
  //   this.itemprice='';
  //   this.description = '';
  //   this.stock = '';
  //   this.wac = '';
  //   // this.loadItems();    
  // }
  onCompanySelect(e)
  {
    console.log('eve',e)
    this.cid=e;
    this.itemcode='';
    this.description = '';
     this.stock = '';
    this.wac = '';
    this.itemprice = [];
    this.myControl.setValue('');
    this.loadItems(); 
  }
  SiteMasterData () {   
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));     
    this.authenticationService.getSiteMaster('101').subscribe(
      data => {
        let result: any;
        result = data;       
        if(result.err!=0){
          if(result.errdesc!='Success')
          this.error=result.errdesc;          
          this.Companylist=curUser.dtsiteid;               
        }
        else
          this.error=result.errdesc;        
      },
      error => {
          this.error = error;        
      });         
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.itemdesc.filter(option => option.toLowerCase().includes(filterValue));
  }

  loadItems(){
    this.showSpinner=true;
    const params = {
      cid: this.cid,
      itemcode: this.itemcode
    };   
   const query = `cid=${params.cid}&itemcode=${params.itemcode}`;   
    this.service.httpGet('/salesorder/getitemlist?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)    
      this.items = result.respdata;      
      this.itemdesc = _.pluck(this.items, 'desc');

      this.SiteMasterData();
    });
  }

  selecteditem(event) {    
    this.itemcode =  _.pluck(_.where(this.items, { 'desc': event.option.value }), 'code')[0];   
    this.getItemPriceList();
  }

  getItemPriceList(){
    this.showSpinner=true;
    const params = {
      cid: this.cid,
      itemcode: this.itemcode,   
      salesmanid: this.curUser.salesmanid,
     // salesmanid:100031,
    //  barcode: this.barcode
    barcode:this.barcode,
    GroupID: JSON.parse(sessionStorage.getItem('groupid'))
    }; 
   // console.log('currentUser: ',  params);
    this.spinnervisible = true;
    const query = `cid=${params.cid}&itemcode=${params.itemcode}&salesmanid=${params.salesmanid}&barcode=${params.barcode}&GroupID=${params.GroupID}`;      
    this.service.httpGet('/salesorder/getitempricelist?' ,query)
    .subscribe(data => {
      let result: any;
      result = data;
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)    
      if(result.errno == 0){
        this.itemDetails = result.respdata.ItemMaster[0];
        this.itemprice = result.respdata.ItemPriceList;
        if(result.respdata.ShowWac.length >0)
        this.WacVisibility=true;
      else
        this.WacVisibility=false;  
      if(result.respdata.ShowPricelist.length > 0)
         this.PricelistVisibility=true;
      else
         this.PricelistVisibility=false;  
        this.spinnervisible = false;        
        this.itemStockDetails();
      }
      else{
        console.log('error');    
      }
    }, 
    )
  }

  itemStockDetails(){
    this.description = this.itemDetails['Description'];
    this.stock = this.itemDetails['Stock'];
    this.wac = this.itemDetails['Wac'];
  } 
}
