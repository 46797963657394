import { MatDialog, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Observable,of } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';;
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { Inject } from '@angular/core';
import { DialogService } from './../../../Services/OtherServices/dialog.service';
import { User } from 'src/app/Modules/user';

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

// class PickDateAdapter extends NativeDateAdapter {
//   format(date: Date, displayFormat: Object): string {
//       if (displayFormat === 'input') {
//           return formatDate(date,'dd-MM-yyyy',this.locale);;
//       } else {
//           return date.toDateString();
//       }
//   }
// }


@Component({
  selector: 'app-alternateemp',
  templateUrl: './alternateemp.component.html',
  styleUrls: ['./alternateemp.component.css']
  ,
//   providers: [
//     {provide: DateAdapter, useClass: PickDateAdapter},
//     {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
// ]
})
export class AlternateempComponent implements OnInit {

  SelectedCompanyID: number;
  companies: any;
  projectdata: any;
  ApprovalGroupID: any;
  AuthorizerID: any;  
  Employees: any;
  RoleID: any;
  cid:any;
  projectlist: any;
  selectedCID:number;  
  showSpinner:any;
  
  employeeid:string[]=[];

 

  AlternateEmpFormGroup: FormGroup;
  isEditable = true; 
  separatorKeysCodes: number[] = [ENTER, COMMA];
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  fcEmployee = new FormControl();
  filteredEmployees: Observable<string[]>;
  employees: string[] = [];
  allEmployees: string[] = [];
  selectable = true;
  removable = true;
  rtnprojectid:any;
  rtndate:any;
  rtngrpid:any;

  date: Date; //= new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  now: Date = new Date();

  @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,private dialogService: DialogService,
    public notificationService: NotificationService, private datePipe: DatePipe,public dialogRef: MatDialogRef<AlternateempComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      this.rtnprojectid=this.data.project;
      this.rtndate=this.data.date;

      
      this.AlternateEmpFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] ,                 
        fcProject: ['', Validators.required],      
        fcDate: ['', Validators.required],
        fcGroupID: ['', Validators.required],
        fcAuthorizer: ['', Validators.required],  
        fcEmployee: ['', Validators.required],  
        fcRole: ['', Validators.required] 
      });
      
      this.fcEmployee.valueChanges.subscribe(search => {
        this.filteredEmployees = of(this.allEmployees.filter(item =>
        item.toLowerCase().includes(search)
        ));
        });
    }

    private _filteremployees(value1: string): string[] {
      const filterprojectValue = value1.toLowerCase();
  
      return this.allEmployees.filter(value1 => value1.toLowerCase().indexOf(filterprojectValue) === 0);
    }
  
    addemployees(event1: MatChipInputEvent): void {
      const input = event1.input;
      const value = event1.value;
      if ((value || '').trim()) {
        this.employees.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.fcEmployee.setValue(null);
    }
  
    removeemployees(emp: string): void {
      const index = this.employees.indexOf(emp);
      if (index >= 0) {
        this.employees.splice(index, 1);
      }
    }
  
     selectedemployees(event1: MatAutocompleteSelectedEvent): void {
      this.employees.push(event1.option.value);
      this.empInput.nativeElement.value = '';
      this.fcEmployee.setValue(null);
    }


  ngOnInit(): void {
    this.cid=JSON.parse(sessionStorage.getItem('cid')); 
    this.PageLoad();
    //  this.AlternateEmpFormGroup.controls.fcCompany.setValue(this.cid);
    //  this.AlternateEmpFormGroup.controls.fcDate.setValue(this.rtndate);
    //  this.AlternateEmpFormGroup.controls.fcProject.setValue(this.rtnprojectid);
    // this.onprojectpageloadChange();
 
  }

  onprojectpageloadChange() {            
   let params = {
     CID:this.AlternateEmpFormGroup.get('fcCompany').value,
     Flag: "GROUPNAME",      
     UserID: "0",
     ApprovalGroupID: this.rtnprojectid,
     AllotDate : this.datePipe.transform(this.AlternateEmpFormGroup.get('fcDate').value,"dd-MM-yyyy")         
   };
   this.service.httpPost('/ProjectEffort/Getdropdownvalues',params)
     .subscribe (
       (data) => {        
         let result: any;
         result = data;        
         if (result.errno === 0) {                
           this.ApprovalGroupID=result.respdata.ApproveGroupID;                       
           this.AlternateEmpFormGroup.controls.fcGroupID.setValue(this.ApprovalGroupID[0].ApprovalGroupID);
          // this.AlternateEmpFormGroup.controls.fcGroupID.setValue(result.respdata.ApproveGroupID[0].ApprovalGroupID);
           this.Employees = result.respdata.Employee; 
           this.AuthorizerID=result.respdata.Authorizer;          
           this.allEmployees= _.pluck(this.Employees, 'Name');                         
          
         }
         else {
           this.notificationService.success(result.errdesc); //':: Problem details');
         }
       },
       (error: AppError) => {
         if (error instanceof BadInput)           
           console.log(error);
         else throw error;       
       });
 }  

  changesite(e) {    
    this.cid=e.value;
    this.PageLoad();
  }

  
  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID:this.cid,
      Flag: "LOADDDL",      
      UserID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid"),
      ApprovalGroupID: ""     
    };
    this.service.httpPost('/ProjectEffort/Getdropdownvalues',params)
      .subscribe (
        (data) => {         
          let result: any;
          result = data;       
          if (result.errno === 0) {       
            this.companies = curUser.dtsiteid; 
            this.projectdata = result.respdata.Projectlist;   
            this.ApprovalGroupID=result.respdata.ApproveGroupID;
         //   this.AuthorizerID=result.respdata.Authorizer;                      
         //   this.Employees = result.respdata.Employee;
            this.RoleID=result.respdata.Role;
 
           // this.employees= _.pluck(this.Employees, 'Name'); 
            this.allEmployees= _.pluck(this.Employees, 'Name');  
            
            
            this.AlternateEmpFormGroup.controls.fcCompany.setValue(this.cid);
            this.AlternateEmpFormGroup.controls.fcDate.setValue(this.rtndate);
            this.AlternateEmpFormGroup.controls.fcProject.setValue(this.rtnprojectid);
            this.onprojectpageloadChange();
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  
  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    .afterClosed().subscribe(res => {
      if (res) {
            this.AlternateEmpFormGroup.reset();
            // this.initializeFormGroup();
            this.dialogRef.close();
        }
      });
  }

  onprojectChange(ob) {      
    this.rtnprojectid = ob.value;    
    let params = {
      CID:this.AlternateEmpFormGroup.get('fcCompany').value,
      Flag: "GROUPNAME",      
      UserID: "0",
      ApprovalGroupID:  this.rtnprojectid,
      AllotDate : this.datePipe.transform(this.AlternateEmpFormGroup.get('fcDate').value,"dd-MM-yyyy")     
    };
    this.service.httpPost('/ProjectEffort/Getdropdownvalues',params)
      .subscribe (
        (data) => {        
          let result: any;
          result = data;         
          if (result.errno === 0) {                
            this.ApprovalGroupID=result.respdata.ApproveGroupID;    
            this.Employees = result.respdata.Employee;         
            this.AuthorizerID=result.respdata.Authorizer;  
            this.allEmployees= _.pluck(this.Employees, 'Name');   
            this.AlternateEmpFormGroup.controls.fcGroupID.setValue(this.ApprovalGroupID[0].ApprovalGroupID);                                  
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onSearchClear(){
    this.AlternateEmpFormGroup.reset();
    // this.selectedemployees";
    this.fcEmployee.reset();
    this.AlternateEmpFormGroup.controls.fcCompany.setValue(this.cid);
  }


  Submit() {
    // if (this.AlternateEmpFormGroup.invalid) {    
    //   return;
    //   } 
    this.showSpinner=true;
    let params = {     
      CID: this.AlternateEmpFormGroup.get('fcCompany').value,
      Flag: "ALTERNATEEMP",      
      ProjectUID: this.AlternateEmpFormGroup.get('fcProject').value,
      AllotDate : this.datePipe.transform(this.AlternateEmpFormGroup.get('fcDate').value,"dd-MM-yyyy"),    
      ApprovalGroupID: this.AlternateEmpFormGroup.get('fcGroupID').value,   
      Authorizer: this.AlternateEmpFormGroup.get('fcAuthorizer').value, 
      jsondt:this.employees,         
      Role: this.AlternateEmpFormGroup.get('fcRole').value,
      //Hours:this.AlternateEmpFormGroup.get('fcAllotHrs').value,    
      Hours:"",  
    };
    this.service.httpPost('/ProjectEffort/Setalternateemp',params)
    .subscribe(
      (data) => {
        let result: any;        
        result = data;  
        this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)          
        if (result.errno === 0) {
          this.notificationService.success(result.errdesc);   
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
}
