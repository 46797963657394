import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/Modules/user';

@Component({
  selector: 'app-approvalformfilter',
  templateUrl: './approvalformfilter.component.html',
  styleUrls: ['./approvalformfilter.component.scss'],
})
export class ApprovalformfilterComponent implements OnInit {

  today = new Date();
  priorDate = new Date(new Date().setDate(this.today.getDate() - 30));
   fromdate:any= this.priorDate;
   todate:any= new Date();
   dataSource: any[];;
   dataSource1:any;
   flag:any;
   qtnstatus="0"
   curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   companies:any= this.curUser.dtsiteid;
   CID:any;

constructor(public dialogRef: MatDialogRef<ApprovalformfilterComponent>, @Inject(MAT_DIALOG_DATA) public data: any)
 { 
  console.log('data',data)
  this.CID=data.cid
  this.qtnstatus=data.qtnstatus
  this.fromdate=data.fromdate
  this.todate=data.todate
 }


  ngOnInit() {}

  applyFilter() {
    // Implement filter logic here
    // You may use a service to communicate with the parent component or emit an event
    let param={'Status': this.qtnstatus,'FromDate':this.fromdate,'ToDate':this.todate,'CID':this.CID}
    this.dialogRef.close(param);
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
