import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder,FormArray,AbstractControl } from '@angular/forms';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-crmdatecontrol',
  templateUrl: './crmdatecontrol.component.html',
  styleUrls: ['./crmdatecontrol.component.scss'],
})
export class CrmdatecontrolComponent implements OnInit {
  BussinessDate:any;
  CRMDateControlFormGroup: FormGroup;
  Curisvisible=false;
  Bydateisvisible=false;
  curdate= new Date();
  DateTypeFlag:string;
  Bymonthisvisible=false;
  ByMonthYearvisible=false;
  CurrentYearvisible=false;
  yearlist:any;
  rtnfromdate:any;
  rtntodate:any;
  month:number;
  todaycheck:boolean=false;
  bydatecheck:boolean=false;
  lastsevencheck:boolean=false;
  bymonthcheck:boolean=false;
  byyearcheck:boolean=false;
  alldatecheck:boolean=false;
  Curmonthvalue=new Date().getMonth()+1
   value:boolean=true; 
  category = [
    { name: 'Today' },
    {  name: 'Last 7 Days' },
    // {  name: 'Current Year' },
    {  name: 'By Date' },
    {  name: 'By Month' } ,
    {  name: 'By Year' },
    {  name: 'All Date' }  
  ];

  favoriteSeason: string;
  seasons: string[] = ['Today', 'Last 7 Days', 'By Date', 'By Month','By Year','All Date'];

  //,public dialogRef: MatDialogRef<DatecontrolComponent>,@Inject(MAT_DIALOG_DATA) public data: any
  constructor(private formBuilder: FormBuilder,public notificationService: NotificationService,public service: HttpserviceService,private datePipe: DatePipe,
              public dialogRef: MatDialogRef<CrmdatecontrolComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 

    //  console.log('datetupe',this.data.DateTypeFlag)
     // this.DateTypeFlag=this.data.DateTypeFlag;

    this.CRMDateControlFormGroup = this.formBuilder.group({       
    // fcCategory: [''],  
      fcCurrentDate: ['', Validators.required], 
      fcFromDate: ['', Validators.required], 
      fcToDate: ['', Validators.required],     
      fcMonth: ['', Validators.required],
      fcYear:['',Validators.required],
      fcCurrentYear:['',Validators.required]
    });
  }

  ngOnInit() {
  //  console.log('casfk',this.Curmonthvalue)

    let year = new Date().getFullYear();
    //this.DateControlFormGroup.controls.fcCategory.setValue('Today');
    this.CRMDateControlFormGroup.controls.fcCurrentDate.setValue(this.curdate);
    this.CRMDateControlFormGroup.controls.fcCurrentDate.disable();
    this.CRMDateControlFormGroup.controls.fcFromDate.setValue(this.curdate);
    this.CRMDateControlFormGroup.controls.fcToDate.setValue(this.curdate);
    this.Curisvisible=true;
    this.CRMDateControlFormGroup.controls.fcMonth.setValue(this.Curmonthvalue.toString());
    this.getComboValue();
    this.CRMDateControlFormGroup.controls.fcYear.setValue(year);
    this.CRMDateControlFormGroup.controls.fcCurrentYear.setValue(year);

   // console.log('vig',this.data)

    if(this.data.DateTypeFlag===undefined )
    {
    //  console.log('hi')
      this.data.DateTypeFlag="Today";    
      this.radioChange(this.data.DateTypeFlag);
    }
    else
    {
    //  console.log('BYE')
      this.data.DateTypeFlag=this.data.DateTypeFlag;
    // console.log('datetype',this.DateTypeFlag)
      this.radioChange(this.data.DateTypeFlag);
    }

  }

  
  radioChange(event) {
   // console.log
   // this.filter['property'] = event.value;
    //  console.log('RADIO',event);
     //this.DateTypeFlag=event.value;
     this.data.DateTypeFlag=event.value;
    if(this.data.DateTypeFlag==='Today' || event ==='Today')
      {
        this.Curisvisible=true;
        this.Bydateisvisible=false;
        this.Bymonthisvisible=false;
        this.ByMonthYearvisible=false;
        this.CurrentYearvisible=false;
        this.todaycheck=true;       
        this.data.DateTypeFlag="Today";
      }
      else if(this.data.DateTypeFlag==='By Date' || event ==='By Date')   
      {
        this.value=false;        
        this.Curisvisible=false;
        this.Bydateisvisible=true;
        this.Bymonthisvisible=false;
        this.ByMonthYearvisible=false;
        this.CurrentYearvisible=false;
        this.CRMDateControlFormGroup.controls.fcFromDate.setValue(this.curdate);
        this.CRMDateControlFormGroup.controls.fcToDate.setValue(this.curdate);
        this.bydatecheck=true;        

        this.data.DateTypeFlag='By Date'   
      }
      else if(this.data.DateTypeFlag==='By Month' || event ==='By Month')   
      {
        this.Curisvisible=false;
        this.Bydateisvisible=false;
        this.CurrentYearvisible=false;
        this.Bymonthisvisible=true;
        this.ByMonthYearvisible=true;    
        this.bymonthcheck=true;    
        this.data.DateTypeFlag='By Month' 
      }
      else if(this.data.DateTypeFlag==='By Year' || event ==='By Year')   
      {
        this.Curisvisible=false;
        this.Bydateisvisible=false;
        this.Bymonthisvisible=false;
        this.ByMonthYearvisible=false;    
        this.CurrentYearvisible=true;
        this.byyearcheck=true;
        this.data.DateTypeFlag='By Year'   
      }
      else if(this.data.DateTypeFlag==='All Date' || event ==='All Date')   
      {      
        let params = {
                  cid:101,
                  Flag:"PAGELOAD",
                  EmpID:0,
                  Month:1,
                  TimeZone:1,
                  checkcode:"",
                  Year:2020,
                  Department:"select",
                  Ledger:0      
              }
              this.service.httpPost('/AttendanceReport/GetAttendanceReport',params)
              .subscribe(data => {
                let result: any;        
                result=data;
                if (result.errno === 0){                         
                    this.yearlist= result.respdata.Table1;
                    this.BussinessDate=result.respdata.Table7[0].StartDate;
                                        
                    this.alldatecheck=true;                   
                    this.value=true;                          
                    //console.log('BUSINESDATE',this.BussinessDate)
                    this.rtnfromdate=this.BussinessDate;                     
                    this.Curisvisible=false;
                    this.Bydateisvisible=true;
                    this.Bymonthisvisible=false;
                    this.ByMonthYearvisible=false;
                    this.CurrentYearvisible=false;
                    this.CRMDateControlFormGroup.controls.fcFromDate.setValue(this.rtnfromdate);
                    this.CRMDateControlFormGroup.controls.fcToDate.setValue(this.curdate);
                }
                else{
                  this.notificationService.success(result.errdesc); //':: Problem details');
                }
              }, 
              (error: AppError) => {
                if (error instanceof BadInput)           
                  console.log(error);
                else throw error;       
              });            
        this.data.DateTypeFlag='All Date'   

      }
      else if(this.data.DateTypeFlag==='Last 7 Days' || event ==='Last 7 Days')   
      {       
        this.value=false;        
        var myCurrentDate=new Date();
        var myPastDate=new Date(myCurrentDate);
        myPastDate.setDate(myPastDate.getDate() - 6);      
        this.Curisvisible=false;
        this.Bydateisvisible=true;
        this.Bymonthisvisible=false;
        this.ByMonthYearvisible=false;
        this.CurrentYearvisible=false;
        this.CRMDateControlFormGroup.controls.fcFromDate.setValue(myPastDate);
        this.CRMDateControlFormGroup.controls.fcToDate.setValue(this.curdate);
        this.lastsevencheck=true;
        this.data.DateTypeFlag='Last 7 Days'   

      }
  }
  Close()
  {      
    console.log('te',this.data)
    // console.log('abc',JSON.stringify(this.data))
    
      if(this.data.DateTypeFlag ==='Today' )
      {        
        this.rtnfromdate=this.datePipe.transform(this.CRMDateControlFormGroup.get('fcCurrentDate').value,"dd-MM-yyyy");
        this.rtntodate=this.datePipe.transform(this.CRMDateControlFormGroup.get('fcCurrentDate').value,"dd-MM-yyyy");
        this.data.DateTypeFlag="Today";      
      }
      
      else if( this.data.DateTypeFlag ==='By Date')
      { 
        this.rtnfromdate=this.datePipe.transform(this.CRMDateControlFormGroup.get('fcFromDate').value,"dd-MM-yyyy");
        this.rtntodate=this.datePipe.transform(this.CRMDateControlFormGroup.get('fcToDate').value,"dd-MM-yyyy");  
        this.data.DateTypeFlag='By Date'   
      }
      else if(this.data.DateTypeFlag ==='Last 7 Days')
      {
        this.rtnfromdate=this.datePipe.transform(this.CRMDateControlFormGroup.get('fcFromDate').value,"dd-MM-yyyy");
        this.rtntodate=this.datePipe.transform(this.CRMDateControlFormGroup.get('fcToDate').value,"dd-MM-yyyy"); 
       this.data.DateTypeFlag='Last 7 Days';      
      }
      else if(this.data.DateTypeFlag ==='By Month')   
      {      
         this.month =parseInt(this.CRMDateControlFormGroup.get('fcMonth').value)-1;     
         var d = new Date(this.CRMDateControlFormGroup.get('fcYear').value, this.month + 1, 0);
     
         this.rtnfromdate='01'+'-'+this.CRMDateControlFormGroup.get('fcMonth').value+'-'+this.CRMDateControlFormGroup.get('fcYear').value;
         this.rtntodate=this.datePipe.transform(d,"dd-MM-yyyy");      
         this.data.DateTypeFlag='By Month' 
      }
      else if(this.data.DateTypeFlag ==='By Year')   
      {
        this.rtnfromdate='01-01-'+this.CRMDateControlFormGroup.get('fcCurrentYear').value;
        this.rtntodate='31-12-'+this.CRMDateControlFormGroup.get('fcCurrentYear').value;   
        this.data.DateTypeFlag='By Year'  
      }
      else if(this.data.DateTypeFlag ==='All Date')   
      {       
        this.rtnfromdate=this.datePipe.transform(this.BussinessDate,"dd-MM-yyyy");           
        this.rtntodate='31-12-'+this.CRMDateControlFormGroup.get('fcYear').value;   
        this.data.DateTypeFlag='All Date'   
      }

      let data={
         frmdate: this.rtnfromdate,
         todate:this.rtntodate,
       //  datetype:this.DateTypeFlag ===undefined?this.data.DateTypeFlag:this.DateTypeFlag
         datetype:this.data.DateTypeFlag
      }
       this.dialogRef.close(data);
  }
  categoryselect(e)
  {
     
  }
  getComboValue()
  {       
    let params = {
    cid:101,
    Flag:"PAGELOAD",
    EmpID:0,
    Month:1,
    TimeZone:1,
    checkcode:"",
    Year:2020,
    Department:"select",
    Ledger:0

        }
        this.service.httpPost('/AttendanceReport/GetAttendanceReport',params)
        .subscribe(data => {
          let result: any;        
          result=data;
          if (result.errno === 0){                         
              this.yearlist= result.respdata.Table1;
              this.BussinessDate=result.respdata.Table7[0].StartDate;

              //console.log('BSDATE',this.BussinessDate);            
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
}
