import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { UserService } from './../../Admin/Users/userValidate';
import { User } from './../../../Modules/user';


export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

// class PickDateAdapter extends NativeDateAdapter {
//   format(date: Date, displayFormat: Object): string {
//       if (displayFormat === 'input') {
//           return formatDate(date,'dd-MM-yyyy',this.locale);;
//       } else {
//           return date.toDateString();
//       }
//   }
// }

@Component({
  selector: 'app-ledgerstatement',
  templateUrl: './ledgerstatement.component.html',
  styleUrls: ['./ledgerstatement.component.css']
  ,
  providers: [
    // {provide: DateAdapter, useClass: PickDateAdapter},
    // {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
]
})
export class LedgerstatementComponent implements OnInit {
  emp:boolean=true;
  CurLedgerID:any;  
  Companies: any;
  Employees: any;
  LedgerStatementFormGroup: FormGroup;
  dataSource: any;
  Type : string;
  cid:any;
  frmdate= new Date();
  showSpinner:any;
  refreshbtnvisible: boolean = false;
  filteredList5:any;
  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,private generic: GenericService,
    private service1: UserService, 
    public notificationService: NotificationService, private datePipe: DatePipe) 
    {
      this.LedgerStatementFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] ,                 
        fcEmployee: ['', Validators.required],             
        fcFromDate: ['', Validators.required],
        fcToDate: ['', Validators.required],
        fcLedger: ['']
      });
    }

  ngOnInit(): void {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));   
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.PageLoad();
    this.LedgerStatementFormGroup.controls.fcCompany.setValue(this.cid);
    this.LedgerStatementFormGroup.controls.fcLedger.setValue('STATEMENT');
    this.LedgerStatementFormGroup.controls.fcFromDate.setValue(this.frmdate);
    this.LedgerStatementFormGroup.controls.fcToDate.setValue(this.frmdate);
  //  this.LedgerStatementFormGroup.controls.fcEmployee.setValue(this.CurLedgerID);
   // console.log('control',this.CurLedgerID)
  }

  changesite(e) {
    this.cid=e.value;
    this.PageLoad();
  }


  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID:this.cid, 
      Flag: "LOADDDL"           
    };
    this.service.httpPost('/LedgerStatement/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          // console.log('params: ', params);
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies =curUser.dtsiteid;                            
            this.Employees = result.respdata.Employees;
            this.filteredList5= this.Employees.slice();
    
            this.LedgerStatementFormGroup.controls.fcEmployee.setValue([this.CurLedgerID]);
            this.getUserList();
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_23",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);   
      // console.log('visibilitydata',btnVisilityData)
      this.refreshbtnvisible = btnVisilityData.refreshflag;   
      this.emp=btnVisilityData.allempflag;
     // console.log('employee',this.emp)
      
      if(this.emp===true)
        this.emp=false
      else 
         this.emp=true  

    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  Submit(){
    if (this.LedgerStatementFormGroup.invalid) {
      this.notificationService.warn('Please select employee and date range');     
      return;
      }  
    this.showSpinner=true;
    let params = {     
      CID: this.LedgerStatementFormGroup.get('fcCompany').value, 
      FromDate:  this.datePipe.transform(this.LedgerStatementFormGroup.get('fcFromDate').value,"yyyy-MM-dd"),
      ToDate : this.datePipe.transform(this.LedgerStatementFormGroup.get('fcToDate').value,"yyyy-MM-dd")==null? 
      this.datePipe.transform(this.LedgerStatementFormGroup.get('fcFromDate').value,"yyyy-MM-dd"):this.datePipe.transform(this.LedgerStatementFormGroup.get('fcToDate').value,"yyyy-MM-dd"),    
      ReportName:this.LedgerStatementFormGroup.get('fcLedger').value,   //this.Type==undefined?"STATEMENT": this.Type    
      jsondt:this.LedgerStatementFormGroup.get('fcEmployee').value,  
      Flag: "LOADDATA"      
    };
    this.service.httpPost('/LedgerStatement/Getdropdownvalues',params)
    .subscribe(
      (data) => {
        let result: any;
        this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)
        result = data;       
        if (result.errno === 0) {
          this.dataSource = result.respdata.GridData;         
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });

  }

  onSearchClear(){
    this.LedgerStatementFormGroup.reset();    
    this.dataSource='';
    this.LedgerStatementFormGroup.controls.fcCompany.setValue(this.cid);
    this.LedgerStatementFormGroup.controls.fcFromDate.setValue(this.frmdate);
    this.LedgerStatementFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.LedgerStatementFormGroup.controls.fcLedger.setValue('STATEMENT');
     this.LedgerStatementFormGroup.controls.fcEmployee.setValue([this.CurLedgerID]);
  }

  updateEmployee(event: { isUserInput:any; 
    source:{value:any;
      selected:any}})
  {     
    if (event.isUserInput)
    {
      let empid = event.source.value;          
    }    
  }

}
