import { ProductconfigComponent } from './../productconfig/productconfig.component';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { Component, OnInit,ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';


@Component({
  selector: 'app-productconfiglist',
  templateUrl: './productconfiglist.component.html',
  styleUrls: ['./productconfiglist.component.scss'],
})
export class ProductconfiglistComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  CID:any;
  flag:any;
  ID:number=0;
  companies:any;
  entitytype:any;
  // datasource:any;
  showSpinner:any;
  datasource:MatTableDataSource<GroupALL>;
  displayedColumns =['ProductID','ProductName','Description','Status','RegisterSince','Action'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,
    private generic: GenericService,
    public notificationService: NotificationService,private dialogService: DialogService,
    private NotificationService:NotificationService)
     { }

     ngOnInit() 
     {
       this.CID=JSON.parse(sessionStorage.getItem('cid'));
       this.flag='PAGELOAD'
       this.Loaddetails();
     }
     onCompanySelect(event)
     {
       this.CID=event;
       this.flag='PAGELOAD'
       this.Loaddetails();
   
     }


     Submit(){
       this.Loaddetails();
     }
   
     doFilter(filterValue: string) {
      // console.log(filterValue);
      this.datasource.filter = filterValue;
    }

     Loaddetails()
     {
      this.showSpinner=true;
       let params = {
         cid:this.CID,
         flag:this.flag
          }
            //  const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
             this.service.httpPost('/ProductConfig/GetProductConfig',params)
             .subscribe(data => {
              this.showSpinner=true;
              setTimeout(() =>{
                this.showSpinner=false;
              },
              800)
               let result: any;
               result=data;
               if (result.errno === 0){
                
                 if(this.flag=='PAGELOAD')
                 {
                   this.companies=result.respdata.Companylist;
                   this.datasource=result.respdata.ProductConfiglist;
                   this.datasource = new MatTableDataSource<GroupALL>(result.respdata.ProductConfiglist);
                   this.datasource.sort = this.sort;
                   this.datasource.paginator = this.paginator;
                 }
                //  else
                //  {
                //    this.datasource=result.respdata.EntityList;
                //  }
                   
                   
               }
               else{
                 this.notificationService.success(result.errdesc); //':: Problem details');
               }
             }, 
             (error: AppError) => {
               if (error instanceof BadInput)           
                 console.log(error);
               else throw error;       
             });
     }
     exportAsExcel()
     {
      this.generic.excelExport(this.datasource.data, 'ProductList', 'ProductList');
     }
 
  add()
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'ADD' ,                            
          ProductID:0,
          ProductName:'',
          Description:'',
          Status:0 ,
          RegisterSince:''  
        }
        const dialogRef = this.dialog.open(ProductconfigComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Loaddetails();
  
          });
  }
  Edit(event)
  {
  
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'EDIT' ,                            
          ProductID:event.ProductID,
          ProductName:event.ProductName,
          Description:event.Description,
          Status:event.StatusID ,
          RegisterSince:event.RegisterSince    
        }
        const dialogRef = this.dialog.open(ProductconfigComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Loaddetails();
  
          });
  }

  Delete(event){
  
    let sdate =Date();
    this.dialogService.openConfirmDialog('Are you sure to delete this product?')
    .afterClosed().subscribe(res => {
     if (res) {
      let params = { 
      CID:this.CID,
      Flag: 'DELETE', 
      ID: event.ProductID, 
      Name:  event.ProductName,   
      Description: event.Description,
      Status:event.StatusID,
      Register:event.RegisterSince,
      UserID: 0,
      GroupID:sessionStorage.getItem('groupid')
        
       };
       this.service.httpPost('/ProductConfig/SetProductConfig',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.warn("Product deleted Successfully");
          //console.log('Project list: ', result.respdata);
          this.flag='PAGELOAD'
          this.Loaddetails();
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
}
}

export interface GroupALL {
  ProductID: string;
  ProductName: string;
  Status : string;
}