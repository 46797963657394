import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { User } from './../../../../Modules/user';
import { GoalaccessComponent } from './../goalaccess/goalaccess.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { DatePipe } from '@angular/common';
import { Goals2Component } from '../goals2/goals2.component';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit {

  flag:any
  GoalID:number=0;
  GoalName:any
  GoalDescription:any
  EntryDate:any
  groupenable:any=true
  CurLedgerID:any
  CID:any

  constructor(private dialog: MatDialog,private datePipe: DatePipe,public notificationService: NotificationService,public service: HttpserviceService,  public dialogRef: MatDialogRef<GoalsComponent>,
    private dialogService: DialogService,@Inject(MAT_DIALOG_DATA) public data: any) {
      this.flag = this.data.flag;
    this.GoalName=this.data.GoalName;
    this.GoalID=this.data.GoalID;
    this.GoalDescription=this.data.GoalDescription;
    this.EntryDate=this.data.EntryDate;
    this.CID=this.data.cid;
     }

  ngOnInit(): void {
    this.groupenable = this.GoalID != 0 ? false : true;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }
  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    .afterClosed().subscribe(res => {
      if (res) {
    this.dialogRef.close({event: 'CLEAR'});
      }
    });
  }
  onClose(model){
    this.dialogRef.close({event: this.flag, data: model});
  }
  onClear()
  {
    this.GoalName=''
    this.EntryDate=''
    this.GoalDescription=''
  }
  onSubmit()
  {
    let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      cid: this.CID,
      flag: this.flag,
      GoalID:this.GoalID,
      GoalName:this.GoalName,
      GoalDescription:this.GoalDescription,
      EntryDate:this.datePipe.transform(this.EntryDate, 'yyy-MM-dd'),
      GoalStatus:"Open",
      UserName: this.CurLedgerID,
      CreateDate:currentdate
     

    };
    this.service.httpPost('/Goals/SetGoals?',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
      if (users.errno === 0){
        this.notificationService.success(users.errdesc);
        this.GoalID=users.respdata;
        this.groupenable = this.GoalID !== 0 ? false : true;
        this.dialogRef.close({event: 'CLEAR'});
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
        this.dialogRef.close({event: 'CLEAR'});
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      width: '60%',
      GoalID:this.GoalID,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(Goals2Component,dialogConfig);
  }
}