import { User } from './../../../../Modules/user';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
type AOA = any[][];

@Component({
  selector: 'app-hierarchyexcelimport',
  templateUrl: './hierarchyexcelimport.component.html',
  styleUrls: ['./hierarchyexcelimport.component.css']
})
export class HierarchyexcelimportComponent implements OnInit {

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';

  ExcelFormGroup: FormGroup;
  ExcelImportData:any;
  LedgerID: number;

  constructor(private formBuilder: FormBuilder, private service: HttpserviceService,
    public notificationService: NotificationService) 
    { 

    }

  ngOnInit(): void {
    const curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID = curUser.ledgerid;
  }

  Save() {

    const params = {
      CID: JSON.parse(sessionStorage.getItem('cid')),
      Flag:"ExcelImport",    
      dt: this.ExcelImportData,
      AppGroupID:JSON.parse(sessionStorage.getItem('currentUser'))['groupid'],
      UserID:this.LedgerID
    };
    console.log('params :', params);
    this.service.httpPost('/projecthierarchy/SetExcelData',params)
      .subscribe(
        (data) => {
          // console.log('inside save');
          let users: any;
          users = data;

          if (users.errno === 0) {
            this.notificationService.success('Submitted successfully');
          }
          else {
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }

   onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws));
      console.log(this.data);
      this.ExcelImportData = this.data;
    };
    reader.readAsBinaryString(target.files[0]);
  }

}
