import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';
import { User } from '../../../../Modules/user';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { CrmdatecontrolComponent } from '../../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';


@Component({
  selector: 'app-effortcostreport',
  templateUrl: './effortcostreport.component.html',
  styleUrls: ['./effortcostreport.component.scss'],
})
export class EffortcostreportComponent implements OnInit {
  @ViewChild('stepper') stepper;

  CostReportFormGroup:FormGroup;
  CostReportDataFormGroup:FormGroup;
  myControl = new FormControl();
  myControl1 = new FormControl();
  filteredOptions: Observable<string[]>;
  filteredOptions1: Observable<string[]>;
  selectedprojectid: any;
  employeeid:string[]=[];
  projectid: string[] = [];
  selectedEmpID: any;
  Projectlist:any;
  Companies: any;
  Employees:any; 
  cid:any; 
  Clients:any;
  public filteredList5
  CurLedgerID:number;
  UserID:any;
  isEditable = true; 
  Category:any;
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"
  showSpinner:any;
  dataSource: any;

  GroupBy = [
    { name: 'Employee' },
    { name: 'Project' },
    { name: 'Client' },    
    { name: 'Category' }    

  ];

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog: MatDialog,
    public notificationService: NotificationService, private datePipe: DatePipe) { 
    this.CostReportFormGroup = this.formBuilder.group({  
      fcCompany: ['', Validators.required] , 
      myControl: [''],
      myControl1: [''],    
      fcClient:[''], 
      fcCategory:[''],
      fcGroupBy:['']   
    });
    this.CostReportDataFormGroup = this.formBuilder.group({                 
    });
  }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;   
    this.UserID=curUser.userid;
    this.PageLoad();

    this.CostReportFormGroup.controls.fcCompany.setValue(this.cid);
    this.CostReportFormGroup.controls.fcGroupBy.setValue('Employee');

    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); 
      
      this.filteredOptions1 = this.myControl1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter1(value, 'myControl1'))
      );  
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  private _filter1(value: string, control: string): string[] {    
    const filterValue = value.toLowerCase();
    // console.log('controlID',filterValue);
    this.selectedprojectid=''
    // console.log('FilterProjectID',this.employeeidname);
  //  console.log('FilterProjectID',this.projectid);
    if (control === 'myControl1') {
      //console.log('FilterProjectID',this.projectid);
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  selectedemployee(event) {             
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;   
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-')); 
  }
  
  selectedEmployee1(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
    if (control === 'myControl1') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.Projectlist, { 'ProjectName': event.option.value }), 'ProjectID'));
    }
  }

  PageLoad() {
    // console.log('ledgerid',sessionStorage.getItem("groupid"));
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",      
     
    };
    this.service.httpPost('/EffortCost/GetEffortCost',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = result.respdata.Table;                          
            this.Employees = result.respdata.Table1;           
            this.Projectlist=result.respdata.Table2;           
            this.Clients=result.respdata.Table3;
            this.Category=result.respdata.Table4;

            //console.log('Companies',result.respdata)    

            this.projectid = _.pluck(this.Projectlist, 'ProjectName');
            this.employeeid= _.pluck(this.Employees, 'Name');    

           this.filteredList5= this.Clients.slice();

          // console.log('test',this.filteredList5)
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  
  Submit(){
          this.showSpinner=true;
          let params = {     
          CID: this.CostReportFormGroup.get('fcCompany').value,
          Flag: "LOADGRID",  
          LedgerID:this.selectedEmpID,     
          Project:this.selectedprojectid,            
          Client:this.CostReportFormGroup.get('fcClient').value,
          CategoryType:"", 
          Category:this.CostReportFormGroup.get('fcCategory').value,   
          GroupBy:this.CostReportFormGroup.get('fcGroupBy').value,       
          DateType:'cs',               
          FromDate:this.fromdate,
          ToDate:this.todate   
        };
  //console.log('zfv',params)
  this.service.httpPost('/EffortCost/GetEffortCost',params)
  .subscribe(
    (data) => {
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)
      let result: any;
      this.stepper.selectedIndex = 1;   
      result = data;          
      if (result.errno === 0) {        
        this.dataSource = result.respdata.Table;       
      }
      else {
        this.notificationService.success(result.errdesc); //':: Problem details');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }

  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }

  onSearchClear(){
    this.CostReportFormGroup.reset();   
    this.myControl.setValue('');   
    this.myControl1.setValue('');
    this.CostReportFormGroup.controls.fcCompany.setValue(this.cid);
    this.stepper.selectedIndex = 0;   
    this.dataSource='';
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.CostReportFormGroup.controls.fcGroupBy.setValue('Employee');
    this.DateTypeFlag="Today";
  }

  changesite(e)
  {
    this.Employees = '';
    this.Projectlist=''; 
    this.projectid = [];
    this.employeeid= [];
    this.cid=e.value;
    this.PageLoad();
    this.myControl.setValue('');   
    this.myControl1.setValue('');
  }
}
