import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';

@Component({
  selector: 'app-kanbankeyvaluejsondataview',
  templateUrl: './kanbankeyvaluejsondataview.component.html',
  styleUrls: ['./kanbankeyvaluejsondataview.component.scss'],
})
export class KanbankeyvaluejsondataviewComponent implements OnInit {

  keyJSONvalue:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,private dialogService: DialogService,public dialogRef: MatDialogRef<KanbankeyvaluejsondataviewComponent> ) {
    this.keyJSONvalue=JSON.parse(this.data.jsonValue)  ;
   }

  ngOnInit() {


  }

  onCloseDialog(){       
     
        this.dialogRef.close({event: 'CLEAR'});
     
  }

}
