import { Component, OnInit } from '@angular/core';
import { MatDialog  } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import {  MatDialogConfig ,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';

@Component({
  selector: 'app-empprojectlist',
  templateUrl: './empprojectlist.component.html',
  styleUrls: ['./empprojectlist.component.scss'],
})
export class EmpprojectlistComponent implements OnInit {
  dataSource: any;
  SelectedCompanyID: number;
  selectedemployees: number;
  selectedEmpID: string = 'All';
  myControl1 = new FormControl('', Validators.required);
  filteredOptions2: Observable<string[]>;
  cid:any;
  employeeidname: string[] = [];
  EmpFormGroup:FormGroup;
  CID:String;
  Flag:String;
  Employees: any;

  constructor(private service: HttpserviceService, 
    private formBuilder: FormBuilder, private dialog: MatDialog,private dialogService: DialogService,
    public notificationService: NotificationService, private datePipe: DatePipe,
    public dialogRef: MatDialogRef<EmpprojectlistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.CID=this.data.CID;
      this.Flag=this.data.Flag;

      this.EmpFormGroup = this.formBuilder.group({
        myControl1: ['', Validators.required]
      });

      
     }

  ngOnInit() {
    this.PageLoad();
    this.filteredOptions2 = this.myControl1.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, 'myControl1'))
    );
  }
  private _filter(value: string, control: string): string[] {
    
    const filterValue = value.toLowerCase();    
    // if (control === 'myControl') {     
    //   return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    // }
    // else if (control === 'myControl1') {     
      return this.employeeidname.filter(option => option.toLowerCase().includes(filterValue));
    }

    selectedEmployee(event, control: string) {     
      let str: string = event.option.value;     
      this.selectedEmpID = event.option.value;      
      this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));        
    }

    onCloseDialog()
    {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
    //         this.HrPolicyFormGroup.reset();
            this.dialogRef.close();
          }
        });
    }

  PageLoad() {
    const params = {
      CID:  this.CID,
      Flag: 'DROPDOWN'     
    };
  
   this.service.httpPost('/EstimationWeeklyReport/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
         //console.log('data ', data);
          if (result.errno === 0) {              
            this.Employees = result.respdata.Employees;         
            this.employeeidname = _.pluck(this.Employees, 'Name');           
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });
  }

  Submit() {
    const params = {
      CID: this.CID,
      Flag: 'GETEMPPORJECT',
      EmployeeID:this.selectedEmpID=="All" ? "%" : this.selectedEmpID
    };
  
   this.service.httpPost('/EstimationWeeklyReport/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
         //console.log('data ', data);
          if (result.errno === 0) {
           // console.log('result.respdata.table', result.respdata);
            this.dataSource =result.respdata.Project;       
        
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });

  }


  onSearchClear(){
    this.EmpFormGroup.reset(); 
    this.dataSource='';      
    this.myControl1.setValue('');  
    this.selectedEmpID='All';
   
  }
}
