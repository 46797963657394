import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { User } from './../../../../Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import * as _ from 'underscore/underscore-min.js';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';

@Component({
  selector: 'app-projectcost',
  templateUrl: './projectcost.component.html',
  styleUrls: ['./projectcost.component.scss'],
})
export class ProjectcostComponent implements OnInit {

  ProjectCostFormGroup:any;
  cid:number;
  companies: any;
  projectdata: any;
  projectid: string[]= [];
  SelectedCompanyID: number;
  myControl = new FormControl('', [Validators.required]);
  filteredOptions: Observable<string[]>;
  selectedprojectid: any;
  selectedprojectname: any;
  Taglist:any;
  Emplist:any;
  Currencylist:any;
  dataSource:any;
  CurLedgerID:any;

  constructor(private formBuilder: FormBuilder,private service: HttpserviceService,
    public notificationService: NotificationService) {

      this.ProjectCostFormGroup = this.formBuilder.group({
        fcCompany: ['', Validators.required] ,     
      });
   }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.PageLoad();
    this.ProjectCostFormGroup.controls.fcCompany.setValue(this.cid);
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
  }

  selectedproduct(event) {    
    this.selectedprojectname = event.option.value;
    this.selectedprojectid = JSON.parse(_.pluck(_.where(this.projectdata, { 'ProjectID': event.option.value }), 'ProjectUID')); 
    const params = {
      CID: this.cid, 
      Flag: 'PageLoad',
      projectID:this.selectedprojectid
    };

   this.service.httpPost('/ProjectCostConfig/GetProjectCostConfig',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {        
            this.Taglist=result.respdata.Table3;
            this.Emplist = result.respdata.Table4;            
            this.Currencylist = result.respdata.Table5;     
            this.dataSource=result.respdata.Table6;
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });
  }

  changesite(e) {    
    this.cid=e.value;
    this.PageLoad();
  }

  Submit()
  {
    let params = {     
      CID: this.ProjectCostFormGroup.get('fcCompany').value,
      Flag: "ADD",             
      GroupID:101,
      UserID:this.CurLedgerID,
      projectID:this.selectedprojectid,
      dtProjectCost: this.dataSource          
    };
    this.service.httpPost('/ProjectCostConfig/SetProjectCostConfig',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.notificationService.success(result.errdesc);
          this.onClear();
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
          this.onClear();
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
  onClear(){
    this.myControl.setValue('');  
    this.dataSource='';
  }

  SearchClear()
  {
    this.myControl.setValue('');  
  }

  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    const params = {
      CID: this.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: 'PageLoad'
     
    };

   this.service.httpPost('/ProjectCostConfig/GetProjectCostConfig',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {        
            this.companies = curUser.dtsiteid;
            this.SelectedCompanyID = this.cid;
            this.projectdata = result.respdata.Table1;            
            this.Taglist=result.respdata.Table3;
            this.Emplist = result.respdata.Table4;            
            this.Currencylist = result.respdata.Table5; 
             this.dataSource=result.respdata.Table6;          

            this.projectid = _.pluck(this.projectdata, 'ProjectID');                      
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });
  }
}
