import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../Modules/user';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../OtherServices/notification.service';
import {HttpserviceService} from '../httpServices/httpservice.service'
//import { Platform } from '@ionic/angular';

const baseUrl = `${environment.apiUrl}/usermgt`;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    count:any=1;

    constructor(public notificationService: NotificationService
        ,private http:HttpserviceService,
        //public plt: Platform
        ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        // if (this.plt.is('ios')) {
        //     // This will only print when on iOS
        //     console.log('I am an iOS device!');
        //   }
        // else if (this.plt.is('android')) {
        //     // This will only print when on android
        //     console.log('I am an android device!');
        //   }
        //   else {
        //     // This will only print when on other
        //     console.log('I am an Other device!');
        //   }


      //  console.log('Platform',this.plt.platforms());
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string,cid: number) {
        // const cid = 101;
        const userid = 1001;
       const applicationaccess='CRM_WEB';
        //alert("url"+ environment.apiUrl + '/usermgt/auth'+ JSON.stringify(cid) + JSON.stringify(userid)+ JSON.stringify(username)+ JSON.stringify(password)); 
        // return this.http.post<any>(`${environment.apiUrl}/usermgt.asmx/auth`, { cid, userid, username, password })
        // console.log('logindetails : ', environment.apiUrl + '/usermgt/auth', { cid, userid, username, password });
        return this.http.httpPost(`/usermgt/auth_web`, { cid, userid, username, password,applicationaccess })
            .pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                // user.authdata = window.btoa(username + ':' + password);
               

                if (user.errno === 0){
                    // console.log('logindetails : ', user);
                    user.token = user.respdata.token;
                    user.groupid = user.respdata.groupid;
                    sessionStorage.clear();
                    // user.cid=cid;
                    // console.log('token:', user.token);
                    sessionStorage.setItem('cid', JSON.stringify(cid));
                    sessionStorage.setItem('groupid', JSON.stringify(user.groupid));
                    sessionStorage.setItem('currentUser', JSON.stringify(user.respdata));
                    sessionStorage.setItem('token', JSON.stringify(user.token));
                    // sessionStorage.setItem('configparam', JSON.stringify(user.token));
                    sessionStorage.setItem('photo',user.respdata.Photo);                    
                    sessionStorage.setItem('isDark',user.respdata.darklight);
                    sessionStorage.setItem('theme',user.respdata.themeindex);
                    sessionStorage.setItem('documentcount',user.respdata.documentcount);
                    sessionStorage.setItem('TemplateGroupID',user.respdata.TemplateGroupID);
                    sessionStorage.setItem('UserType',user.respdata.UserType);
                    sessionStorage.setItem('siteid',user.respdata.dtsiteid);  
                    sessionStorage.setItem('tabcount',this.count); 
                    // sessionStorage.setItem('dsdashboard',user.respdata.ds_dashboard);  
                    sessionStorage.setItem('CustomerSettings',user.respdata.CustomerSettings);
                    // sessionStorage.setItem('BusinessStartDate',user.respdata.BusinessStartDate);                   
                   
                    // let BSPeriod=_.where(user.respdata.dtBSPeriod,{ChoosedBSPeriod:true})[0].BusinessPeriodID
                    // sessionStorage.setItem('BSPeriod',BSPeriod);                      
                    // localStorage.setItem('token', user.respdata.token);                   
                    console.log('currentUser: ',  user.respdata);
                    // localStorage.setItem('token', user.respdata.token);
                    this.currentUserSubject.next(user);
                    return user;
                }
                else{
                    this.notificationService.success(user.errdesc);
                    this.logout();
                }
            }));
            
    }

    logout() {
        // remove user from local storage to log user out
        sessionStorage.clear();
        // localStorage.removeItem('token');
        this.currentUserSubject.next(null);
    }

  getphoto(params: any)
  {
    const query = `cid=${params.cid}&ledgerid=${params.ledgerid}`;
    return this.http.httpGet(baseUrl + '/GetPhoto?', query);
    // .catch(this.handleError);
  }

  getSiteMaster(item:string){
    return this.http.httpGet('/CompanyID/GetCompanyID','?cid=101&flag=text')
    // .subscribe(
    //   data => {
    //     //  this.Companylist=JSON.parse(data);
    //     console.log("Companylist",data);
    //     //  var Default_site = this.Companylist.filter(x => x.default == 1);
    //     //  this.loginForm.controls.CompanyName.setValue(Default_site[0].CID);
    //     return data;
    //   },
    //   err => {
    //     console.log(err)
    //   });
  }
}



// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthenticationService {

//   constructor() { }
// }
