import { Component, OnInit, ViewChild } from '@angular/core';
import { Employee, Service, TickettreeviewService } from './tickettreeview.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { User } from '../kanbanboard/kanbanboard.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CrmdatecontrolComponent } from '../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';
import { TaskbyviewComponent } from '../CustomsReport/taskbyview/taskbyview.component';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';

@Component({
  selector: 'app-tickettreeview',
  templateUrl: './tickettreeview.component.html',
  styleUrls: ['./tickettreeview.component.scss'],
})
export class TickettreeviewComponent implements OnInit {
  @ViewChild('stepper') stepper;
  tasksData: task[] = [];

  
  statusesData: string[];

  Company:any;
  ReqType:any;
  employeesData: any[];
  isEditable = true; 
  cid:any; 
  CurLedgerID:any;
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"
  TicketTreeViewFormGroup: FormGroup;  
  UserID:any;
  showSpinner:any;

  
  constructor(private service: HttpserviceService, private datePipe: DatePipe,private dialog: MatDialog,
    private generic: GenericService,public notificationService: NotificationService , private formBuilder: FormBuilder) {
    //this.tasksData = service.getTasksData();
    // this.employeesData = service.getEmployeesData();
    // this.statusesData = [
    //   'Not Started',
    //   'Need Assistance',
    //   'In Progress',
    //   'Deferred',
    //   'Completed',
    // ];

    this.TicketTreeViewFormGroup = this.formBuilder.group({  
      fcCompany: ['', Validators.required] ,   
      fcFromData: [''] ,   
      fcToData: [''] ,       
    });
    
  }
  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;   
    this.UserID=curUser.userid;
    this.PageLoad();
    this.TicketTreeViewFormGroup.controls.fcCompany.setValue(this.cid);
    this.TicketTreeViewFormGroup.controls.fcFromData.setValue(0);
    this.TicketTreeViewFormGroup.controls.fcToData.setValue(0);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
  }

  PageLoad() {
    // console.log('ledgerid',sessionStorage.getItem("groupid"));
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    let params = {
      CID: this.cid,
      Flag: "TreeViewData_PageLoad",
      UserID:this.CurLedgerID
     
    };
    //console.log('params',params)
    this.service.httpPost('/ITSCustomsReport/GetTicketData',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {      
            this.Company=result.respdata.Company;
            this.ReqType=result.respdata.ReqType;
            //this.tasksData=result.respdata.Taskdata;
            console.log('result.respdata.Taskdata', this.ReqType)
            // console.log('this.tasksData',this.tasksData)
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }

  Submit(){
    this.showSpinner=true;
    let params = {
      CID: this.cid,
      Flag: "TreeViewData",
      UserID:this.CurLedgerID,
      RequestType:this.TicketTreeViewFormGroup.get('fcFromData').value,
      Issues:this.TicketTreeViewFormGroup.get('fcToData').value,
      FilterBy:'Created Date',
      DateType:'CS',      
      FromDate:this.fromdate,
      ToDate:this.todate
     
    };
    //console.log('params',params)
    this.service.httpPost('/ITSCustomsReport/GetPlanData',params)
      .subscribe (
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;
          if (result.errno === 0) {      
            //this.Company=result.respdata.Company;
            //this.ReqType=result.respdata.ReqType;
            this.stepper.selectedIndex = 1;  
            this.tasksData=result.respdata.Taskdata;
            //console.log('result.respdata.Taskdata', this.ReqType)
            // console.log('this.tasksData',this.tasksData)
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  exportAsExcel()
  {
    // , {header:['name', 'surname']}
  //  this.generic.excelExport(this.EffortDataGrids, 'Effort','EffortList From ' + this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy")+' to '+this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy"));
     this.generic.excelExport(this.tasksData, 'Task','TaskList From ' +  this.fromdate +' to '+ this.todate);
  }


  onSearchClear(){
    // this.TicketTreeViewFormGroup.reset();   
    // this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    // this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    //
    // this.cid=JSON.parse(sessionStorage.getItem('cid'));  
  
    this.TicketTreeViewFormGroup.controls.fcCompany.setValue(this.cid);
    this.TicketTreeViewFormGroup.controls.fcFromData.setValue(0);
    this.TicketTreeViewFormGroup.controls.fcToData.setValue(0);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.DateTypeFlag="Today";
    this.stepper.selectedIndex = 0;   
    this.tasksData=[];
  }

  onFocusedRowChanged(e) {  
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;       
    dialogConfig.data = {                 
            pid:0,
            height: '350px',
            width: '70%',                            
            cid:this.cid,
            flag:"View",
            TicketID:e.data.Task_ID,
            TemplateID:1,
            UserName:this.UserID,
            LoginFlag:'External',
            ActiviteID:"INT_VIEW",
            TemplateGroupID:1,   
            Subject:e.data.Task_Subject
        }                   
        const dialogRef =  this.dialog.open(TaskbyviewComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
          this.Submit();     
        });
   
}

onCellPrepared (e) {
   
  if (e.rowType === "data") {
      if (e.column.dataField === "Task_Status") {        
         e.cellElement.style.cssText ="color: #000000; background-color: "+e.data.ColorCode+""; 
      // e.cellElement.style.backgroundColor = this.dataSource[e.rowIndex].ColorCode;          
       //  e.cellElement.style.backgroundColor = e.data.ColorCode;
        // e.cellElement.style.cssText ="color: #000000;"; 

      }
    //   if (e.column.dataField ==="ParentStatus") {        
    //     //e.cellElement.style.cssText ="color: block; background-color: "+this.dataSource[e.rowIndex].ColorCode+""; 
    //  // e.cellElement.style.backgroundColor = this.dataSource[e.rowIndex].ColorCode;          
    //     e.cellElement.style.backgroundColor = e.data.ParentColorCode;
    //  }
  }

}

}
export class task {

  Task_ID: string;
  Task_Parent_ID: number;
  Task_Owner_ID: string;
  Task_Assigned_Employee_ID: string;
  Task_Subject: string;
  Task_Status: string;
  //Task_Completion: number;
  Task_Priority: string;
  Task_ReqType: string;
  Task_Start_Date: string;
  Task_Due_Date: string;
}
