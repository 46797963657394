import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
//import { DialogRequestInfoComponent } from '../dialog-request-info/dialog-request-info.component';

@Component({
  selector: 'app-approvalform-posting',
  templateUrl: './approvalform-posting.component.html',
  styleUrls: ['./approvalform-posting.component.css']
})
export class ApprovalformPostingComponent {
     showSpinner:any;
     VouNo:any;
      Description:any;
      VouDate:any;
      ApprovedStatus:any;
      Amount:any;
      Comment:'';
      MenuID:any;
      RevNo:any;
      ApproveBy:any;
      LedgerID:any;
      RuleID:any;
      CID:any;
      dataSource:any;
      dataSource1:any;
      TCDiscountAmount:any;
      TCMiscAmount:any;
      TCItemTaxAmount:any;
  constructor(
   
    private NotificationService:NotificationService,
    private dialogService: DialogService,
   private service:HttpserviceService ,private router: Router,public dialog: MatDialog,public dialogRef: MatDialogRef<ApprovalformPostingComponent>, @Inject(MAT_DIALOG_DATA) public data: any ) 
  {

    console.log('dialogRef',data)
    console.log('dialogRef',data.VouNo)
//     if (this.router.getCurrentNavigation().extras.state !== undefined)
//     {
  
    // this.data=this.router.getCurrentNavigation().extras.state.data;
    this.VouNo=data.VouNo;
    this.Description=data.Description;
    this.VouDate=data.VouDate;
    this.ApprovedStatus=data.ApprovedStatus;
    this.Amount=data.Amount;
    this.MenuID=data.MenuID;
    this.RevNo=data.RevNo;
    this.ApproveBy=data.ApproveBy;
    this.LedgerID=data.LedgerID;
    this.RuleID=data.RuleID;
    this.CID=data.CID;


    this.LoadData()
//console.log(name); // 'Approval Form'
    // console.log("test1234",Description)
    //console.log("test",this.data.name)
//     }
    
   }
   Submit(value)
   {
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      BPID:101,
      MenuID:this.MenuID,
      VouNO:this.VouNo,
      RevNo:this.RevNo,
      userID:this.ApproveBy,
      ApproverStatus:1,
      ApproverComment:this.Comment,
      ht:"",
      LedgerID:this.LedgerID,
      ByPassApprove:0,
      JsonValue:"",
      ApprovedStatus:value,
      ProjectID:"N/A"
     
        }

        console.log("ts",params)
        // const query = `cid=${params.cid}&vouno=${params.vouno}&menuid=${params.menuid}&flag=${params.flag}`;   
        this.service.httpPost('/ApprovalForm/SetApprovalForm?',params)
        .subscribe(
          (data) => {
            let users: any;
            users = data; //result.users;
            if (users.errno === 0){
              this.NotificationService.success(':: Submitted sucessfully');
              this.dialogRef.close({event: 'CLEAR'});
              //this.router.navigate(['ApprovalForm']);
              // this.GoalID=users.respdata;
              // this.groupenable = this.GoalID !== 0 ? false : true;
            }
            else{
              this.NotificationService.success(users.errdesc); //':: Problem updating user');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
   }
  //  OpenDialog()
  //  {
  //   const dialogRef = this.dialog.open(DialogRequestInfoComponent, {
  //     data: {RuleID: this.RuleID,CID:JSON.parse(sessionStorage.getItem('cid'))},
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     //this.animal = result;
  //   });
  
  //  }

  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
          this.dialogRef.close({event: 'CLEAR'});
       }
      });
  }
  LoadData()
  {

    this.showSpinner=true;
    const params = {
      cid: this.CID,
      VouNo:this.VouNo,
      MenuID:this.MenuID
    
    };   
    this.service.httpPost('/ApprovalForm/GetApprovalFormItemDetails?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("mm",result.respdata)
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
     
        this.dataSource = result.respdata.Table;   
        //this.dataSource = result.respdata.Table1;  
        this.TCDiscountAmount= result.respdata.Table1[0].TCDiscountAmount
        this.TCItemTaxAmount= result.respdata.Table1[0].TCItemTaxAmount
        this.TCMiscAmount= result.respdata.Table1[0].TCMiscAmount
        // this.dataSource1 = result.respdata.Table; 
      
         
    
       
    });
  }
}
