import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/Forms';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'

@Injectable({
  providedIn: 'root'
})
export class ProjectconfigurationService {

  constructor(private http: HttpserviceService) { }

  getprojectconfig(params: any){
    const query = `cid=${params.cid}&flag=${params.flag}&projectID=${params.projectID}&dayshours=${params.dayshours}`;
    return this.http.httpGet('/ProjectConfig/GetProjectConfig?' , query)
  }
  getemployeelist(params: any){
    const query = `cid=${params.cid}&tablename=${params.tablename}&type=${params.formtype}&condition=${params.condition}&menuid=${params.menuid}&withinactive=${params.withinactive}`;
    return this.http.httpGet('/usermgt/loaddetails?' , query)
  }
  getlocationlist(params: any){
    const query = `cid=${params.cid}&tablename=${params.tablename}&type=${params.formtype}&condition=${params.condition}&menuid=${params.menuid}&withinactive=${params.withinactive}`;
    return this.http.httpGet('/usermgt/loaddetails?' , query)
  }
  setprojectconfig(data: any)
  {
    return this.http.httpPost('/ProjectConfig/SetProjectConfig?', data)
  }
  form: FormGroup = new FormGroup({
    ID: new FormControl(''),
    Name: new FormControl('', [Validators.required]),
    Client: new FormControl('', [Validators.required]),
    status: new FormControl('', Validators.required),
    ProjectStatus: new FormControl(''),
    Sdate: new FormControl('', [Validators.required]),
    Edate: new FormControl('', Validators.required),
    ContactDesignation: new FormControl(''),
    ContactEmail: new FormControl(''),
    ContactMobile: new FormControl(''),
    ContactPerson: new FormControl(''),
    ContactTelephone: new FormControl(''),
    DayHours: new FormControl('', Validators.required),
    description: new FormControl(''),
    location: new FormControl(''),
    list: new FormControl('')

  });

  initializeFormGroup(){
    this.form.setValue({
      ID: '',
      Name: '',
      Client: '',
      status: '',
      ProjectStatus:'',
      Sdate: '',
      Edate: '',
      ContactDesignation: '',
      ContactEmail: '',
      ContactMobile: '',
      ContactPerson: '',
      ContactTelephone: '',
      DayHours: '',
      description: '',
      location: '',
      list: ''
    });
  }

  populateForm(data){
    let userdata;

    userdata = data;
    let locationlist;
    if (data[0].location === 'undefined' ||  data[0].location === null)
    {
      locationlist = '';

    } else{ locationlist = data[0].location.split(','); }


 // console.log("arra",data[0].MerchantLedgerID);
    // console.log("5567557",data[0] );
    const user = {
    ID: data[0].ID,
    Name: data[0].Name,
    Client: data[0].MerchantLedgerID,
    description: data[0].description,
    status: data[0].ProjectStatus,
    ProjectStatus:data[0].PrjStatus,
    Sdate: data[0].Sdate,
    Edate: data[0].Edate,
    ContactDesignation: data[0].ContactDesignation,
    ContactEmail: data[0].ContactEmail,
    ContactMobile: data[0].ContactMobile,
    ContactPerson: data[0].ContactPerson,
    ContactTelephone: data[0].ContactTelephone,
    DayHours: data[0].DayHours,
    location: locationlist,
    list: ''
    };
    this.form.setValue(user);

  }
  
}

