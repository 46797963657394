import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { User } from 'src/app/Modules/user';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { DocumentcontrolValidate } from 'src/app/components/Employee/Documents/documentcontrolvalidate';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { PhotoService } from 'src/app/Services/OtherServices/photo.service';
//import { GeocodingService } from '../geocoding.service';
import {  Camera,CameraResultType, CameraSource } from '@capacitor/camera';
import { saveAs } from 'file-saver';
import { FileDownloadServiceService } from 'src/app/Services/OtherServices/file-download-service.service';
import { param } from 'jquery';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class attachmentComponent implements OnInit {

  // @Input() 
  // @ViewChild('fileUpload', { static: false }) fileUpload;
  isLinear = false;
  imageVisible=false;
  PhotoVisible=false;
  parentForm: FormGroup;
  fileUploadId: any="uploadpath";
  public files: File[] = [];
  CID:any;
  flag:any;
  TicketID:any=0;
  CurLedgerID:any;
  formData = new FormData();
  Comment:any
  TemplateID:any=1
  FileList:any;
  imageUrl:any;
  photoUrl: string="" ;
  location: string='';
   datetime: string='';

   currentLatitude: number;
  currentLongitude: number;
  currentLocationDetails: string;
  fileToUpload: File[] = [];
  geodetailsvisible:boolean=false;
  UploadImageMaxSize:number=0;
  curdate= new Date();
  UploadFileMaxSize:number=0;
dataSource:any;
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  fileuploadcount:any;
finalfilecount:any;
  displayedColumns =['ActivityDate','Comment','AttachmentsRef','Action']
  @Output() afterSelectionMethod: EventEmitter<any[]> = new EventEmitter();

  constructor(private fileDownloadService: FileDownloadServiceService, public service: HttpserviceService,public notificationService: NotificationService,private _snackBar: MatSnackBar, private modalRef: MatDialogRef<any>
    ,public DcValidate: DocumentcontrolValidate,private dialogService: DialogService,private datePipe: DatePipe,
    public dialogRef: MatDialogRef<attachmentComponent>,@Inject(MAT_DIALOG_DATA) public data: any,public photoService: PhotoService,private http: HttpClient) 
    
  {
    //console.log('ddd',this.curUser.CustomerSettings.UploadImageMaxSize)

    this.flag = this.data.flag;
    this.TicketID=this.data.TicketID
    this.geodetailsvisible=this.data.geovisible  
    this.UploadImageMaxSize= this.curUser.CustomerSettings.UploadImageMaxSize;
    this.UploadFileMaxSize= this.curUser.CustomerSettings.UploadFileMaxSize;

   }

  ngOnInit() {
    this.fileuploadcount =this.curUser.CustomerSettings.FileUploadCount;
   // console.log('count',this.fileuploadcount)

    this.getLocation();
    // this.dialogRef.updateSize('40%');
    // this.fileUploadId = Object.keys(this.parentForm.controls)[0];
    // this.parentForm.addControl(this.fileUploadId, new FormControl(''));
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.parentForm = new FormGroup({
      fileUploadId: new FormControl('')
    });

    this. GetFile();
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.currentLatitude = position.coords.latitude;
          this.currentLongitude = position.coords.longitude;
          this.getLocationDetails(this.currentLatitude, this.currentLongitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
  getLocationDetails(latitude: number, longitude: number) {
    // You can use reverse geocoding APIs like Google Maps API to get location details from latitude and longitude.
    // Here, we are just showing latitude and longitude.
   // this.currentLocationDetails = `Latitude: ${latitude}, Longitude: ${longitude}`;
   const YOUR_API_KEY = "AIzaSyAAINDRi9q2jr84DcwdX3ayB_DjPbAMZno";
   const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${YOUR_API_KEY}`;
  
   
    this.http.get(apiUrl).subscribe(
      (response: any) => {
        if (response && response.results && response.results.length > 0) {
          this.currentLocationDetails = response.results[0].formatted_address;
        //  console.log("response",response.results)
        } else {
          console.error('No address found for the given latitude and longitude.');
        }
      },
      (error) => {
        console.error('Error getting address:', error);
      }
    );
  
    //clocationonsole.log("apiUrl",apiUrl)
   
  //  console.log("address",this.currentLocationDetails)
  }

  // async takePhoto() {

  
  //   console.log('geodetailsvisible',this.geodetailsvisible)
  //   const date = new Date();
  //   const formattedDate = this.formatDate(date);
  //   const fileName = `photo_${formattedDate}.jpg`;

  //   const coordinates =`${this.currentLatitude},${this.currentLongitude}`
  //   this.location=`${this.currentLocationDetails}`
  //   const image = await Camera.getPhoto({
  //     quality: 50,
  //     allowEditing: false,
  //     resultType: CameraResultType.Uri,
  //     source: CameraSource.Camera,
  //     width:150,
  //     height:150,
  //   });

  //   // Draw watermark on the image
  //   const canvas = document.createElement('canvas');
  //   const context = canvas.getContext('2d');
  //   const img = new Image();
  //   img.onload = () => {
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     context.drawImage(img, 0, 0);
  //     if(this.geodetailsvisible==true)
  //       {
  //         context.font = '20px Arial';
  //         context.fillStyle = 'white';
  //         context.fillText(formattedDate, 10, canvas.height - 50);
  //         context.fillText(this.location, 10, canvas.height - 30);
  //         context.fillText(coordinates, 10, canvas.height - 10);
  //       }
      
        
  //     this.photoUrl = canvas.toDataURL('image/jpeg');
  //   };
  //   img.src = image.webPath;
  //   this.PhotoVisible=true;
  // }

  async takePhoto() {
   // console.log('geodetailsvisible', this.geodetailsvisible);
    const date = new Date();
    const formattedDate = this.formatDate(date);
    const fileName = `photo_${formattedDate}.jpg`;

    const coordinates = `${this.currentLatitude},${this.currentLongitude}`;
    this.location = `${this.currentLocationDetails}`;

    // Capture the image
    const image = await Camera.getPhoto({
        quality: 100,  // Adjust quality if needed
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
    });

    const img = new Image();
    img.src = image.webPath;

    img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set canvas size to match the image dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Add watermark if geodetailsvisible is true
        if (this.geodetailsvisible) {
            context.font = '20px Arial';
            context.fillStyle = 'white';
            context.fillText(formattedDate, 10, canvas.height - 50);
            context.fillText(this.location, 10, canvas.height - 30);
            context.fillText(coordinates, 10, canvas.height - 10);
        }

        // Compress the image to approximately 1 MB
        let quality = 0.9;  // Start with high quality
        let photoUrl = canvas.toDataURL('image/jpeg', quality);
        let fileSize = this.getBase64FileSize(photoUrl);

        //console.log('fileSize',fileSize)

        // Adjust quality to get the desired file size (around 1 MB)
        while (fileSize > 1 * 1024 * 1024 && quality > 0.1) {
            quality -= 0.05;
            photoUrl = canvas.toDataURL('image/jpeg', quality);
            fileSize = this.getBase64FileSize(photoUrl);
        }

        // Set the final photo URL
        this.photoUrl = photoUrl;
        this.PhotoVisible = true;
    };

    img.onerror = (error) => {
        console.error('Image load error:', error);
    };
}

// Helper function to calculate the size of a base64 string in bytes
getBase64FileSize(base64String: string): number {
    const padding = (base64String.endsWith('==') ? 2 : (base64String.endsWith('=') ? 1 : 0));
    return (base64String.length * 3 / 4) - padding;
}



  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  viewDocFile(data)
  {

    var DocFile = data.AttachmentsRef.split(".",4)
  if(DocFile[1]=='jpg' || DocFile[1]=='jpeg' || DocFile[1]=='png' || DocFile[1]=='PNG')
  {
    const slno = data.slno; 
    const docname=data.AttachmentsRef 
    var DocFile = docname.slice(0, -5);
    
    let params = {
      cid:this.CID,
      flag:"DownloadFile",
      TicketID:this.TicketID,
      TemplateID:this.TemplateID,
      UserName:this.CurLedgerID,
      LoginFlag:docname,
      ActiviteID:1,
      TemplateGroupID:1

      
       }
        const query = `cid=${params.cid}&flag=${params.flag}&TaskID=${params.TicketID}&TemplateID=${params.TemplateID}&UserID=${params.UserName}&LoginFlag=${params.LoginFlag}`;
        const dt = this.service.httpGet('/TicketCreator/GetFileDetails?', query)
          .subscribe((data) => {  
            this.imageUrl='data:image/jpeg;base64,'+data
            this.imageVisible=true
          }); 
        }
        else
        {
          this.imageUrl="";
          this.imageVisible=false;
        }
  }
  
  
  onDelete(data){
    const docname=data.AttachmentsRef 
    // console.log('TicketID',this.TicketID);
    // console.log('DocName',docname);
   
    let assessmentvalue = [];
    this.dialogService.openConfirmDialog('Are you sure to delete this file?')
    .afterClosed().subscribe(res => {
     if (res) {
      let params = {     
        CID:this.CID,
        TemplateID:this.TemplateID,
        CreatorFlag:0,
        TicketID:this.TicketID,
        UserName:this.CurLedgerID,
        dt:assessmentvalue,
        Activity:1,
        Comment:docname,
        ActiviteID:1,
        Flag: 'DELETE',
       
      };
      this.service.httpPost('/TicketCreator/SetTicketCreator',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {
            this.notificationService.success('Deleted successfully'); 
          //  this.dialogRef.close();                
          this. GetFile();    
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');          
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)         
            console.log(error);
          else throw error;       
        });
      }
    });
  }

  async GetFile()
  {
    let params = {
      cid:this.CID,
      flag:"GetFile",
      TicketID:this.TicketID,
      TemplateID:this.TemplateID,
      UserName:this.CurLedgerID,
      TagID:0,
      ActiviteID:1,
      TemplateGroupID:1
  
      
       }
          // const query = `cid=${params.cid}&flag=${params.flag}&TaskID=${params.TicketID}&TemplateID=${params.TemplateID}&UserID=${params.UserID}&LoginFlag=${params.LoginFlag}&ActiviteID=${params.ActiviteID}`;
        const dt = await this.service.httpPost('/TicketCreator/GetTicketCreator?', params)
          .toPromise().then(data => {
            let result: any;
            result = data;
            if (result.errno === 0) {
              // event.options= this.ticketlistM1
              this.FileList = result.respdata.FileList;
              console.log('existcount',this.FileList.length)
            }
            // else{
            //   this.notificationService.success(result.errdesc); //':: Problem details');
            // }
          },
            (_error: Error) => {
              // if (error instanceof BadInput)           
              //   console.log(error);
              // else throw error;       
            });
  }
  downloadDocFile(data) {  
    const slno = data.slno; 
    const docname=data.AttachmentsRef 
    var DocFile = docname.slice(0, -5);
  
    let params = {
      cid:this.CID,
      flag:"DownloadFile",
      TicketID:this.TicketID,
      TemplateID:this.TemplateID,
      UserName:this.CurLedgerID,
      LoginFlag:docname,
      ActiviteID:1,
      TemplateGroupID:1
  
      
       }

        const query = `cid=${params.cid}&flag=${params.flag}&TaskID=${params.TicketID}&TemplateID=${params.TemplateID}&UserID=${params.UserName}&LoginFlag=${params.LoginFlag}`;
       // console.log('query',query)
      
        const dt = this.service.httpGet2('/TicketCreator/GetDownload?', query)
          .subscribe((data) => {  
            saveAs(data, DocFile)  ;
          });  
}  

downloadall(data)
{
  // console.log('yyy',data)
  //   const slno = data.Slno; 
  //   const docname=data.Tag 
  //   const FileName=data.FileName 
  //   console.log('FileName',FileName)
  //   var DocFile = FileName.slice(0, -5);
  
    let params = {
      cid:this.CID,
      flag:'DownloadAllFile',
      TemplateID:this.TemplateID,
      TicketID:this.TicketID,     
    
       }
       const query = `cid=${params.cid}&flag=${params.flag}&TemplateID=${'1'}&TaskID=${params.TicketID}`;
       this.service.httpGet2('/TicketCreator/GetDownloadall?',query)
          .subscribe((data) => {  
             saveAs(data, params.TicketID+'-'+this.datePipe.transform(this.curdate,"dd-MM-yyyy"))  ;

           // this.downloadFilesAsZip(data);
            
          });  
}


  // onFilesChange(f){
  //   // debugger;
  //   // this.files = Object.keys(f).map(key => f[key]);
  //   let totalSizeInBytes = 0;
  //   this._snackBar.open(f.length + " File(s) selected!", null, {
  //       duration: 2000,
  //     });
  //     //console.log("dd",f[0].size)

  //     // totalSizeInBytes += f[0].size;

  //     // const totalSizeInKB = totalSizeInBytes / 1024;
  //     //   const totalSizeInMB = totalSizeInKB / 1024;
  //     // console.log("Total size:", totalSizeInMB, "bytes");

  //     for (var i = 0; i < f.length; i++) {
  //       this.files.push(f[i]);
  //     }

      
  //     console.log('amjath',this.files);
       
     
  // }
 
  onFilesChange(f,event: Event) {
    const input = event.target as HTMLInputElement;
    //console.log('S:', this.files);
    const maxImageSize = this.UploadImageMaxSize * 1024 * 1024 ; // 5MB for images
    const maxOtherFileSize = this.UploadFileMaxSize * 1024 * 1024; // 8MB for other files
    let  Maxfilesize;

    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Allowed image types

    let validFiles = [];
    let invalidFiles = [];

    for (let i = 0; i < f.length; i++) {
        const file = f[i];
        const fileType = file.type;

        // Determine the size limit based on file type
        let maxSize = 0;

        if (allowedImageTypes.includes(fileType)) {
            maxSize = maxImageSize;
        } else {
            maxSize = maxOtherFileSize;
           
        }
        Maxfilesize=maxSize;
        // Check if the file size exceeds the allowed limit
        if (file.size > maxSize) {
            invalidFiles.push(file);
        } else {
            validFiles.push(file);
        }
    }

    if (invalidFiles.length > 0) {

       this._snackBar.open(`File size exceeds ${Maxfilesize / (1024 * 1024)} MB. Please select a smaller file.`,
    null,
    {
        duration: 4000, // You can adjust the duration as needed
    }
);
    }

    if (validFiles.length > 0) {
        this._snackBar.open(validFiles.length + " File(s) selected!", null, {
            duration: 2000,
        });
    }

    // Add valid files to the files array
    this.files.push(...validFiles);

    input.value = '';

    //console.log('Selected valid files:', this.files);
}



  // check(){
  //   return this.parentForm.get("uploadFormId").value;
  // }

  Submit()
  {
    //for (let i = 0; i <  this.photoService.photos.length; i++) {

      // const byteCharacters = atob(this.photoService.photos[0].data);
      // const base64Data = this.photoUrl.split(',')[1];
      // const byteCharacters = atob(base64Data);
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      //   }
      //   const byteArray = new Uint8Array(byteNumbers);
      //   const blob = new Blob([byteArray], {type: 'image/jpeg'});

      //   this.fileToUpload.push(new File([blob], new Date().getTime() + '.jpeg', { type: 'image/jpeg' }));
      // // }
      // const formData: FormData = new FormData();
      // let index: number = 1;
      // this.fileToUpload.forEach(function (value) {
      //   formData.append('Image' + index.toString(), value, value.name);
      //  // console.log(value);
      //   index = index + 1;
      // });

      // // let params = {     
        
      // //   location: this.location, 
      // //   datetime: this.datetime     
      // // };

      // formData.append("CID", this.CID);
      // formData.append("Flag", 'Attachment');
      // formData.append("TemplateID", '1');
      // formData.append("CreatorFlag",'0');
      // formData.append("TicketID", this.TicketID);
      // formData.append("UserName", this.CurLedgerID);
      // formData.append("Activity", 'Attachment');
      // formData.append("Comment", this.Comment);

   
    // let body = JSON.stringify({ image:this.photoUrl, params })
    // console.log("tttt",body)
    // const query = `photo=${body}`;
    // this.service.httpPost('/Projectphotocontroller/SetProjectphoto' ,formData)  
    //  .subscribe(res =>{
    //   let result: any;
    //   result = res;  
    //   // this.showSpinner=true;
    //   // setTimeout(() =>{
    //   //   this.showSpinner=false;
    //   // },
    //   // 800)
    //   // this.buttonvisible = true;
    //   //alert('Neworder created');
    //   if (result.errno === 0) {
    //     //this.NotificationService.success('Neworder created');
    //     // this.customername='';
    //     // this.onCloseDialog();
    //   }
  
    //   //this.imageUrl = "/assets/images/default-image.png";
    //   // this.imageUrl =  "assets/image/default-image.png";
      
    // });
  }

onSubmit()
{
  
// console.log('FileListlength',this.FileList.length)
// console.log('fileslength',this.files.length)
  
this.finalfilecount=this.files.length+this.FileList.length;

// console.log('finalfilecount',this.finalfilecount)
  

    if(this.finalfilecount<= this.fileuploadcount)
    {
      const formData = new FormData();
      if(this.files.length > 0 )
        {
         // console.log('this.files.length',this.files.length)

            for (var i = 0; i < this.files.length; i++) { 
              formData.append("file"+i, this.files[i]);
            }
        } 
        else
        {
          const base64Data = this.photoUrl.split(',')[1];
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'image/jpeg'});

            this.fileToUpload.push(new File([blob], new Date().getTime() + '.jpeg', { type: 'image/jpeg' }));
          // }
          // const formData: FormData = new FormData();
          let index: number = 0;
          this.fileToUpload.forEach(function (value) {
            formData.append('file' + index.toString(), value, value.name);
          // console.log(value);
            index = index + 1;
          });
        }            
        formData.append("CID", this.CID);
        formData.append("Flag", 'Attachment');
        formData.append("TemplateID", '1');
        formData.append("CreatorFlag",'0');
        formData.append("TicketID", this.TicketID);
        formData.append("UserName", this.CurLedgerID);
        formData.append("Activity", 'Attachment');
        formData.append("Comment", this.Comment);

      this.AddFileDetails(formData).subscribe(result => {
        let users: any
        users=result;
        if (users.errno === 0){
          this.notificationService.success('Upload successfully');

          this.dialogRef.close({event: 'CLEAR'});
        }
      });
    }
    else
       this.notificationService.success('you can upload maximum of '+this.fileuploadcount+' files');
 }

 AddFileDetails(data: FormData): Observable < string > {  
  let headers = new HttpHeaders();  
  headers.append('Content-Type', 'application/json');  
  const httpOptions = {  
      headers: headers  
  };  
  // return this.http.httpPost( '/Documentcontrol/AddFileDetails/', data)
  return this.service.httpPost('/TicketCreator/AddFileDetails',data)
  // .catch(this.handleError); 
}
 
  onClear()
  {
    this.parentForm.reset(); 
    this.parentForm.disable();  
    // this.visible=false;
    this.TicketID=0;
    this.Comment='';
  }
  onCloseDialog()
  {
    // this.service.form.reset();
    // this.service.initializeFormGroup();
    this.dialogRef.close({event: 'CLEAR'});
  }


  // onSelect(event) {
  //   console.log(event);
  //   this.files.push(...event.addedFiles);

  //   const formData = new FormData();

  //   for (var i = 0; i < this.files.length; i++) { 
  //     formData.append("file[]", this.files[i]);
  //   }

    // this.http.post('http://localhost:8001/upload.php', formData)
    // .subscribe(res => {
    //    console.log(res);
    //    alert('Uploaded Successfully.');
    // })
// }

onRemove(event) {

    // console.log(event);
    // console.log('this.files',this.files);
    this.files.splice(event, 1);
    // this.files.splice(this.files.indexOf(event), 1);
}



}
