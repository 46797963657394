import { MatDialogRef } from '@angular/material/dialog';
import { User } from './../../../../Modules/user';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import {Subject,Observable} from 'rxjs';
import {WebcamImage} from 'ngx-webcam';
import { PhotoService } from 'src/app/Services/OtherServices/photo.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import * as _ from 'underscore/underscore-min.js';
import {startWith, map, pluck} from 'rxjs/operators';

interface Customer {
  id: number;
  name: string;
}

@Component({
  selector: 'app-webcamera',
  templateUrl: './webcamera.component.html',
  styleUrls: ['./webcamera.component.scss'],
})
export class WebcameraComponent implements OnInit{
  @ViewChild('fileInput') fileInput;

  customerControl = new FormControl('', Validators.required);
  salesmanControl = new FormControl('', Validators.required);

  VouNo:any;
  ledgerid: string;
  customername: string;
  userid: string;
  showSpinner:any;
  AliasName:any;
  customers: Customer[];
  salesman: Customer[];
  fileToUpload: File[] = [];

  customernames: string[]= [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  constructor(public photoService: PhotoService,private service:HttpserviceService,private NotificationService:NotificationService,
    private generic: GenericService, private service1: UserService,public dialogRef: MatDialogRef<WebcameraComponent>,
    private http: HttpserviceService) {}

  ngOnInit(): void 
  {
    this.loadCustomerAndSalesman();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.customernames.filter(option => option.toLowerCase().includes(filterValue));
  }
  selectedcustomer(event) {
    // console.log('items: ', this.customers);
    this.ledgerid = JSON.parse(_.pluck(_.where(this.customers, { 'name': event.option.value }), 'id'));
    this.customername = event.option.value;

    console.log("ledgerid",this.ledgerid,this.customername)
  }

  addPhotoToGallery(event) {
    this.photoService.addNewToGallery();  
 
  }
  loadCustomerAndSalesman(){
    const query = `cid=${JSON.parse(sessionStorage.getItem('cid'))}&salesmanid=${this.curUser.salesmanid}`;
    this.service.httpGet('/salesorder/getcustomersalesman?' ,query)  
  //  this.newOrderService.getCustomerAndSalesman(101)
    .subscribe(data =>{
      var result = data as any;
      result = data;      
      this.customers = result.respdata.Customers;
      this.customernames = _.pluck(this.customers, 'name');

    });
  }
  setphotogallery()
    {
      this.postFile(this.AliasName, this.fileToUpload, this.ledgerid, this.customername, this.curUser.salesmanid, this.curUser.username)
    }
    postFile(caption: string, fileToUpload: File[], customerLedger: string, customername: string, salesmanid: number, username: string){
      // console.log("ds", fileToUpload)
      const formData: FormData = new FormData();
      let index: number = 1;
      fileToUpload.forEach(function (value) {
        formData.append('Image' + index.toString(), value, value.name);
       // console.log(value);
        index = index + 1;
      });
     
      // formData.append('Image', fileToUpload, fileToUpload.name);
      formData.append('ImageCaption', caption);
      formData.append('CustomerLedger', customerLedger);
      formData.append('CustomerName', customername);
      formData.append('SalesmanID', salesmanid.toString());
      formData.append('UserName', username);
      formData.append('Flag',  'newOrder');
      formData.append('cid',  JSON.parse(sessionStorage.getItem('cid')));
    
     // return this.http.post(this.baseUrl+'/salesmanorder', formData)
     this.service.httpPost('/salesorder/salesmanorder' ,formData)  
     .subscribe(res =>{
      let result: any;
      result = res;  
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)
      // this.buttonvisible = true;
      //alert('Neworder created');
      if (result.errno === 0) {
        this.NotificationService.success('Neworder created');
        this.customername='';
        this.onCloseDialog();
      }
  
      //this.imageUrl = "/assets/images/default-image.png";
      // this.imageUrl =  "assets/image/default-image.png";
      
    });
            //.catch(this.handleError);
    }
    onCloseDialog()
    {     
       this.dialogRef.close();  
       this.photoService.photos=[];       
    }
    onSearchClear(){
      this.myControl.setValue('');
    //   let index = this.photoService.photos.indexOf(0,1);
      // if(index > -1){
      //   this.photoService.photos.splice(index, 1);
      // }
     // this.photoService.photos.splice(index,1)
    }
    removeImage(i) {
      console.log('index',i)
      console.log('photoService',this.photoService.photos)

      let index = this.photoService.photos.findIndex(x => x.filepath === i.filepath);
console.log(index);
    this.photoService.photos.splice(index,1)
    

  //    this.photoService.photos.splice(i, 1);
    //   let index = this.photoService.photos.indexOf(i,1);
    //   if(index > -1){
    //     this.photoService.photos.splice(index, 1);
    //   }
    //  this.photoService.photos.splice(index,1)
    }

  Submit() { 
    console.log("save")
    console.log('amjath',this.photoService.photos)
      for (let i = 0; i <  this.photoService.photos.length; i++) {

      const byteCharacters = atob(this.photoService.photos[i].data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/jpeg'});

        this.fileToUpload.push(new File([blob], this.photoService.photos[i].filepath, { type: 'image/jpeg' }));
      }
    this. setphotogallery();
  //   this.showSpinner=true;
  //   const formData: FormData = new FormData();
  //   let index: number = 1;
  //   // fileToUpload.forEach(function (value) {
  //   //   formData.append('Image' + index.toString(), value, value.name);
  //   //  // console.log(value);
  //   //   index = index + 1;
  //   // });

  //   // formData.append('Image', fileToUpload, fileToUpload.name);
  //   // formData.append('ImageCaption', caption);
  //   formData.append('CustomerLedger', this.ledgerid);
  //   formData.append('CustomerName', this.customername);
  //   formData.append('SalesmanID','0');
  //   formData.append('UserName',  this.curUser.username);
  //   formData.append('Flag',  'Camera');
  //  // return this.http.post(this.baseUrl+'/salesmanorder', formData)
  //  this.service.httpPost('/salesorder/salesmanorder' ,formData)  
  //  .subscribe(res =>{
  //   this.showSpinner=true;
  //   setTimeout(() =>{
  //     this.showSpinner=false;
  //   },
  //   800)
  //   let result: any;
  //   result = res;   
  //   console.log("ds",result)
    
  //   if(result.errdesc=='success')
  //   {
  //     this.VouNo=result.respdata
  //     this. setphotogallery();
  //     this.NotificationService.success('Neworder created');
  //     //this.dialogRef.close();        
  //   }
  //   // this.buttonvisible = true;
  //   //alert('Neworder created');
  
  //   this.customername='';

  //   //this.imageUrl = "/assets/images/default-image.png";
  //   // this.imageUrl =  "assets/images/default-image.png";
    
  // });
    
    // this. setphotogallery();
    
   
  //   let params = {             
  //   // CameraPicByte: this.photoService.photos[0].data,
  //   // FileName:this.photoService.photos[0].filepath
    
  //   dt:this.photoService.photos
  //  };
  //  console.log('parameter',params);
  //   this.service.httpPost('/Documentcontrol/SetCameraPic',params)
  //   this.photoService.setphotogallery(params)
  //   .subscribe(
  //     (data) => {
  //       let result: any;
  //       result = data;       
  //       if (result.errno === 0) {
  //         this.NotificationService.success(result.errdesc);
  //         //console.log('Project list: ', result.respdata);
  //       }
  //       else {
  //         this.NotificationService.success(result.errdesc); //':: Problem details');
  //       }
  //     },
  //     (error: AppError) => {
  //       if (error instanceof BadInput)         
  //         console.log(error);
  //       else throw error;       
  //     });
  }

}
