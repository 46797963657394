import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User } from './../../../../Modules/user';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';

type AOA = any[][];

@Component({
  selector: 'app-excel-import-dialog',
  templateUrl: './excel-import-dialog.component.html',
  styleUrls: ['./excel-import-dialog.component.scss'],
})
export class ExcelImportDialogComponent implements OnInit {

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';

  ExcelFormGroup: FormGroup;
  ExcelImportData:any;
  LedgerID: string;

  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  EmailTagList = [
   {
     Type: 'Project',
     TypeID: "project-1",
     eMailTag: 'mgt',
     eMailId: 'Syed@aspen.com;hr@aspen.com;support@aspen.com'   
   },
   {
    Type: 'Project',
    TypeID: "project-2",
    eMailTag: 'mgt',
    eMailId: 'sales@aspen.com;support@aspen.com'   
   },
   {
    Type: 'Project',
    TypeID: "project-3",
    eMailTag: 'mgt',
    eMailId: 'Syed@aspen.com;mgt@aspen.com'   
   }
  ]


  constructor(private formBuilder: FormBuilder, private service: HttpserviceService,
    public notificationService: NotificationService,private dialogService: DialogService,
    public dialogRef: MatDialogRef<ExcelImportDialogComponent>,) 
    { 

    }

  ngOnInit(): void {
    const curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID = curUser.ledgerid.toString();
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet =   
    XLSX.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'SampleData.xlsx');
   }

   onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
      //  if (res) {
      //     this.service.form.reset();
      //     this.service.initializeFormGroup();
          this.dialogRef.close({event: 'CLEAR'});
      //  }
      });
  }

  Save() {

    const params = {
      CID: JSON.parse(sessionStorage.getItem('cid')),
      Flag:"ExcelImport",    
      dt: this.ExcelImportData,   
      Type:""  ,
      TypeID:"",
      eMailTag:"",
      eMail:"",   
      UserName:this.LedgerID
    };
    console.log('params :', params);
    this.service.httpPost('/TicketConfiguration/SetTicketEmailConfigrationExcelImport',params)
      .subscribe(
        (data) => {
          // console.log('inside save');
          let users: any;
          users = data;

          if (users.errno === 0) {
            this.notificationService.success('Submitted successfully');
          }
          else {
            this.notificationService.warn(users.errdesc); //':: Problem updating user');
          }
        },
        error => {
          this.notificationService.warn(error);
        });
  }

   onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws));
      console.log(this.data);
      this.ExcelImportData = this.data;
    };
    reader.readAsBinaryString(target.files[0]);
  }

}
