import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { MatDialog,MatDialogConfig,MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable,of } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';
import { User } from './../../../../Modules/user';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { DialogleaveapprovalComponent } from '../../LeaveApproval/dialogleaveapproval/dialogleaveapproval.component';
import { LeaveApprovalService } from 'src/app/components/HR/LeaveApproval/leave-approvalValidate';

@Component({
  selector: 'app-leave-management',
  templateUrl: './leave-management.component.html',
  styleUrls: ['./leave-management.component.scss'],
})
export class LeaveManagementComponent implements OnInit {

  LeaveMgtFormGroup:FormGroup;
  LeaveMgtDataFormGroup:FormGroup;
  AvailLeave:any;
  TakenLeave:any;
  Companies:any;
  Employees:any;
  LeaveType:any;
  dataSource1: any;
  dataSource2: any;
  isLeaveIDVisible: boolean;
  leavedetails:any;
  History:any;
  showSpinner:any;
  optionApprovalBy = [
    { id : 0, ApprovalByName: '-- Me --'},
    { id : 1, ApprovalByName: '-- All --'}
  ];

  // optionStatus = [   
  //   // { id : 4, StatusName: '-- All --'},
  //   { id : 0, StatusName: 'Requested'},
  //   { id : 1, StatusName: 'Approved'},
  //   { id : 2, StatusName: 'Declined'},
  //   { id : 3, StatusName: 'Pending'}
  // ];
  
  optionStatus:any;
  cid:any;
  Leavelist:any;
 
  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  employeeid:string[]=[];
  selectedEmpID: any;
  CurLedgerID:any;
  isEditable = true; 
  displayedColumns = ['Name', 'FromDate', 'ToDate', 'CalendarDay', 'LeaveType', 'Status', 'Comments', 'Action'];

  @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,private dialogService: DialogService,private service1: LeaveApprovalService,
    public notificationService: NotificationService, private datePipe: DatePipe) 
    { 
      this.LeaveMgtFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required],    
        fcStatus: [''],
       // fcApproval: [''],        
        fcRequestType: [''],      
        fcYear: [''],
        myControl: ['']                 
    });
       this.LeaveMgtDataFormGroup = this.formBuilder.group({                 
    });   
  }
         

  ngOnInit() {
    this.isLeaveIDVisible=false;
    const date = new Date();
    this.LeaveMgtFormGroup.get('fcYear').setValue(date.getFullYear());
    this.cid=JSON.parse(sessionStorage.getItem('cid')); 
    this.PageLoad();
    this.LeaveMgtFormGroup.controls.fcCompany.setValue(this.cid);
    this.LeaveMgtFormGroup.controls.fcStatus.setValue(-1);
    //this.LeaveMgtFormGroup.controls.fcApproval.setValue(0);

    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;    

    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    ); 

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();       
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  changesite(e) {  
    this.cid=e.value;
    this.Employees = '';                    
    this.employeeid= [];
    this.PageLoad();
         
    this.selectedEmpID=null;
    this.myControl.setValue('');       
    this.LeaveMgtFormGroup.controls.fcStatus.setValue(0);
    //this.LeaveMgtFormGroup.controls.fcApproval.setValue(0);
    
    
  }

  PageLoad() {
    this.showSpinner=true;
    let params = {
      CID:this.cid, 
      Flag: "LOADDDL"           
    };    
    this.service.httpPost('/LeaveManagement/GetLeaveMgt',params)
      .subscribe (
        (data) => {         
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result = data;   
          
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                           
            this.Employees = result.respdata.Employee;
            this.optionStatus= result.respdata.Status
            //this.LeaveType=result.respdata.LeaveType;
                          
          //  this.allEmployees= _.pluck(this.Employees, 'Name');        
          this.employeeid= _.pluck(this.Employees, 'Name');
          
            this.getLeaveData();
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getLeaveData() {
    this.showSpinner=true;
    let params = {
      CID:this.cid, 
      Flag: "PageLoad",
      LeaveType:"%",
      LeaveID:"%",
      LeaveStatus:"-1",
      AuthorisedBy:this.CurLedgerID,
      EmployeeID:"%",
      Year:"",           
    };    
    this.service.httpPost('/LeaveManagement/GetLeaveMgt',params)
      .subscribe (
        (data) => {        
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result = data;    
          console.log('result',result)      
          if (result.errno === 0) {       
              this.dataSource1 = result.respdata.PageLoadLeavedata;
              this.dataSource1 = new MatTableDataSource<GroupALL>(result.respdata.PageLoadLeavedata);
              this.dataSource1.sort = this.sort;
              this.dataSource1.paginator = this.paginator;
              this.leavedetails=result.respdata.Table6;
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  selectedemployee(event) {        
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;       
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));  
  }

  Submit(){
    this.showSpinner=true;
    let params = {
      CID:this.cid, 
      Flag: "PageLoad",
      LeaveType:this.LeaveMgtFormGroup.get('fcRequestType').value==""?"%":this.LeaveMgtFormGroup.get('fcRequestType').value,
      LeaveID:"%",
      LeaveStatus:this.LeaveMgtFormGroup.get('fcStatus').value, 
      AuthorisedBy:this.CurLedgerID,
      EmployeeID:this.selectedEmpID==undefined ?"%":this.selectedEmpID,
      Year:this.LeaveMgtFormGroup.get('fcYear').value           
    };      
    this.service.httpPost('/LeaveManagement/GetLeaveMgt',params)
      .subscribe (
        (data) => {         
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result = data;
                  
          if (result.errno === 0) {       
              this.dataSource1 = result.respdata.PageLoadLeavedata;
              this.dataSource1 = new MatTableDataSource<GroupALL>(result.respdata.PageLoadLeavedata);
              this.dataSource1.sort = this.sort;
              this.dataSource1.paginator = this.paginator;
              this.leavedetails=result.respdata.Table6;
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
    }

    onApprove(data, _flag){     
      data.RtnDate=data.ToDate;
      let data1=data;
     this.dialogService.openConfirmDialog('Are you sure do you want to approve this leave?')
     .afterClosed().subscribe(res => {
       if (res) {
        this.showSpinner=true;
         let params = {
           CID: this.cid,
           Flag: "setLeaveDays",
           GridLeaveID:data.LeaveID,
           ApprovalLeaveType:"Approved",
           AuthorisedBy:this.CurLedgerID,
           FromDate:data.FromDate,
           ToDate:data.ToDate,          
           UserID:this.CurLedgerID,
           GroupID:sessionStorage.getItem("groupid"),
           Comment:data.Comments
         }               
        this.service.httpPost('/LeaveManagement/SetLeaveMgt',params)
           .subscribe( 
             data => {
               var result: any;
               this.showSpinner=true;
               setTimeout(() =>{
                 this.showSpinner=false;
               },
               800)
               result = data;
               this.notificationService.success(result.errdesc);
               this.Submit();
             }, 
             (error: AppError) => {
              if (error instanceof BadInput)
                console.log('Error: ', error);               
              else throw error;
            });
       }
     });
     
    } 

    onDecline(data, _flag){
      data.RtnDate=data.ToDate;
      let data1=data;
     this.dialogService.openConfirmDialog('Are you sure do you want to decline this leave?')
     .afterClosed().subscribe(res => {
       if (res) {
        this.showSpinner=true;
         let params = {
           CID: this.cid,
           Flag: "setLeaveDays",
           GridLeaveID:data.LeaveID,
           ApprovalLeaveType:"102",
           AuthorisedBy:this.CurLedgerID,
           FromDate:data.FromDate,
           ToDate:data.ToDate,          
           UserID:this.CurLedgerID,
           GroupID:sessionStorage.getItem("groupid")
         }                  
         this.service.httpPost('/LeaveManagement/SetLeaveMgt',params)
           .subscribe( 
             data => {
               var result: any;
               this.showSpinner=true;
               setTimeout(() =>{
                 this.showSpinner=false;
               },
               800)
               result = data;
               this.notificationService.success(result.errdesc);
               this.Submit();
             }, 
             (error: AppError) => {
              if (error instanceof BadInput)
                console.log('Error: ', error);
                // this.service.form.setErrors(error.originalError);
              else throw error;
            });
        }
       });     
    } 

    logEvent(_flag, e)
     {     
        if (_flag == 'RowUpdated')
        {        

          this.dialogService.openConfirmDialog('Are you sure do you want to approve this leave?')
            .afterClosed().subscribe(res => {
              if (res) {
                let params = {
                  CID: this.cid,
                  Flag: "setLeaveDays",
                  GridLeaveID:e.data.LeaveID,
                  ApprovalLeaveType:e.data.LID,
                  AuthorisedBy:this.CurLedgerID,
                  FromDate:e.data.FromDate,
                  ToDate:e.data.ToDate,          
                  UserID:this.CurLedgerID,
                  GroupID:sessionStorage.getItem("groupid"),
                  Comment:e.data.Comments
                }                        
             this.service.httpPost('/LeaveManagement/SetLeaveMgt',params)
                    .subscribe( 
                      data => {
                        var result: any;
                        result = data;
                        this.notificationService.success(result.errdesc);
                        this. getLeaveData();
                      }, 
                      (error: AppError) => {
                        if (error instanceof BadInput)
                          console.log('Error: ', error);                        
                        else throw error;
                      });
                }
            });
        }
     }


    onView(data, _flag){    
          let params = {
            CID:this.cid, 
            Flag: "View",
            LeaveType:this.LeaveMgtFormGroup.get('fcRequestType').value==""?"%":this.LeaveMgtFormGroup.get('fcRequestType').value,
            LeaveID:data.LeaveID,
            LeaveStatus:"%", 
            AuthorisedBy:this.CurLedgerID,
           // EmployeeID:this.selectedEmpID==undefined ?"%":this.selectedEmpID,
           EmployeeID:data.EmpID,
            Year:this.LeaveMgtFormGroup.get('fcYear').value           
          };        
         this.service.httpPost('/LeaveManagement/GetLeaveMgt',params)
         .subscribe (
           (data) => {          
             let result: any;
             result = data;         
             if (result.errno === 0) {       
                 this.dataSource2 = result.respdata.PageLoadLeavedata;
                 this.Leavelist = result.respdata.LeaveType;    
                   //console.log('leavelist',result.respdata.Table6)
                //  this.TakenLeave = result.respdata.TakenLeave;
                //  this.AvailLeave = result.respdata.AvailLeave; 
                 this.History = result.respdata.History;

                 this.leavedetails=result.respdata.LeaveDetails;
             }
             else {
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           },
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
     }

     
 
    onSearchClear(){
      const date = new Date();
      this.LeaveMgtFormGroup.reset(); 
      //this.dataSource1='';   
      this.selectedEmpID=null;
      this.myControl.setValue('');       
      this.LeaveMgtFormGroup.controls.fcStatus.setValue(0);
     // this.LeaveMgtFormGroup.controls.fcApproval.setValue(0);
      this.LeaveMgtFormGroup.controls.fcCompany.setValue(this.cid);
      this.LeaveMgtFormGroup.get('fcYear').setValue(date.getFullYear());
      this.PageLoad();
    }
}


export interface GroupALL {
  EmpName: string;
}