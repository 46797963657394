import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import * as _ from 'underscore/underscore-min.js';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe  } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { TimesheetviewComponent } from '../../../../components/HR/TimeSheet/timesheetview/timesheetview.component';
import { User } from '../../../../Modules/user';
import { json } from '@angular-devkit/core';
//import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { DxiConstantLineModule } from 'devextreme-angular/ui/nested';
import { TimesheetticketComponent } from '../timesheetticket/timesheetticket.component';
import { ContentObserver } from '@angular/cdk/observers';
import { TaskbyviewComponent } from 'src/app/components/ITS/CustomsReport/taskbyview/taskbyview.component';

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@Component({
  selector: 'app-emp-timesheet',
  templateUrl: './emp-timesheet.component.html',
  styleUrls: ['./emp-timesheet.component.scss'],
})
export class EmpTimesheetComponent implements OnInit {
  step = 0;
  flag: string;
  dataSource: any;
  UserID:any;
  dataSource1: any;
  Categorylist:any;
  Rolelist:any;
  Productlist:any;
  Projectlist:any;
  Clientlist:any;
  Companies:any;
  HomePage:any;
  CommonTask:any;  
  Visiblelist:any;
  PreviousEffort:any;
  TimeSheetFormGroup: FormGroup;
  CurLedgerID:any;
  selectedEmpID: any;
  Employees: any;
  regex = '([01]?[0-9]|2[0-3]):[0-5][0-9]';
  cid:any;
  focusedRowKey = 117;
  myControl = new FormControl('', [Validators.required]);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  employeeid:string[]=[];
  Table9:any;
  Table10:any;
  Table11:any;
  Table12:any;
  Table13:any;
  Table14:any;
  Table15:any;
  date : any;
  TaskCount:any;
  isTicketVisible: boolean ;
  isProductVisible: boolean;
  isProjectVisible: boolean;
  isClientVisible: boolean;
  isRoleVisible: boolean;

  addbtnvisible: boolean = false;
  editbtnvisible: boolean = false;
  ticketdetails:any;
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private formBuilder: FormBuilder,private generic: GenericService, private service1: UserService,
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService) 
  {
    this.setStateValue = this.setStateValue.bind(this);
      this.TimeSheetFormGroup = this.formBuilder.group({  
      fcCompany: ['', Validators.required] ,             
      fcDate: ['', Validators.required] 
    });       
  }

  ngOnInit(): void {        
    this.cid=JSON.parse(sessionStorage.getItem('cid'));   
    this.getddllist();
    this.TimeSheetFormGroup.controls.fcCompany.setValue(this.cid);  
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserID=curUser.userid;
    //console.log('CurLedgerID', this.CurLedgerID);
  }

  onChangeEvent(){     
    this.dataSource = '';
    this.getddllist();
  }

  getddllist()
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",     
      Date : this.datePipe.transform(this.TimeSheetFormGroup.get('fcDate').value,"dd-MM-yyyy"),    
      MenuID:'STS_103',
      LedgerID:curUser.ledgerid
    };
    this.service.httpPost('/Timesheet/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.Categorylist = result.respdata.Category;
            this.Productlist=result.respdata.Product;
            this.Projectlist = result.respdata.Project;            
            this.Clientlist = result.respdata.Client;
             this.HomePage=result.respdata.HomePage;
             this.Rolelist=result.respdata.Role; 
            this.dataSource=result.respdata.Datasource;  
            this.dataSource1=result.respdata.Datasource;   
            this.Employees = result.respdata.Employees;   
            this.Visiblelist = result.respdata.Visible;         
            this.isClientVisible=this.Visiblelist[0].Visibility;
            this.isProductVisible = this.Visiblelist[1].Visibility;
            this.isProjectVisible=this.Visiblelist[2].Visibility;
            this.isRoleVisible=this.Visiblelist[3].Visibility;
            this.isTicketVisible = this.Visiblelist[4].Visibility;         
   
          
            for (var i = 0; i < result.respdata.HomePage.length; i++) 
            {
              if(i==0)
              {
               // console.log('RESULT',result.respdata.HomePage)
                 this.Table11=result.respdata.Table11           
              }
              else if(i==1)
              {
                this.Table12=result.respdata.Table12               
              }
              else if(i==2)
              {
                this.Table13=result.respdata.Table13
              }
              else if(i==3)
              {
                this.Table14=result.respdata.Table14
              }
              else if(i==3)
              {
                this.Table15=result.respdata.Table15
              }

              
            }
            
            this.PreviousEffort=result.respdata.PrevEffort;

            this.employeeid= _.pluck(this.Employees, 'Name');   

            this.getUserList();
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_103",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;   
      this.editbtnvisible = btnVisilityData.editflag;                     
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  // Edit(){
  
  //     const dialogConfig = new MatDialogConfig();
  //     dialogConfig.disableClose = true;
  //     dialogConfig.autoFocus =  true;
  //     dialogConfig.data = {       
  //       CID:this.TimeSheetFormGroup.get('fcCompany').value,
  //       LedgerID:this.CurLedgerID  
  //     }
  //     const dialogRef = this.dialog.open(TimesheetviewComponent, dialogConfig)
  //     dialogRef.afterClosed()
  //     // const dialogConfig = new MatDialogConfig();
  //     // dialogConfig.disableClose = true;
  //     // dialogConfig.autoFocus =  true;
  //     // dialogConfig.data = {       
  //     //   CID:this.TimeSheetFormGroup.get('fcCompany').value,
  //     //   TaskList:this.Table9  
  //     // }
  //     // const dialogRef = this.dialog.open(TimesheetticketComponent, dialogConfig)
  //     // dialogRef.afterClosed()

  // }
//   handlePropertyChange(e) {
//     console.log('eventchange')
//     if(e.name === "changedProperty") { 
//          if(e.rowType === "data" && e.column.dataField === "Ticket") {
           
//             const dialogConfig = new MatDialogConfig();
//             dialogConfig.disableClose = true;
//             dialogConfig.autoFocus =  true;
//             dialogConfig.data = {       
//               CID:this.TimeSheetFormGroup.get('fcCompany').value,
//               TaskList:this.HomePage  
//             }
//             const dialogRef = this.dialog.open(TimesheetticketComponent, dialogConfig)
//             dialogRef.afterClosed()
//       }
//     }
// }

// setStep(index: number) {
//   this.step = index;
// }
//   onCellPrepared(e) {
//      //if(e.rowType === "data" && e.column.dataField === "Ticket") {
//     //   const dialogConfig = new MatDialogConfig();
//     //   dialogConfig.disableClose = true;
//     //   dialogConfig.autoFocus =  true;
//     //   dialogConfig.data = {       
//     //     CID:this.TimeSheetFormGroup.get('fcCompany').value,
//     //     TaskList:this.HomePage  
//     //   }
//     //   const dialogRef = this.dialog.open(TimesheetticketComponent, dialogConfig)
//     //   dialogRef.afterClosed()
//     // }
// }
  onClear()
  {
    this.TimeSheetFormGroup.reset();     
    this.dataSource='';
    this.PreviousEffort=''
    this.CommonTask='';
    this.TimeSheetFormGroup.controls.fcCompany.setValue(this.cid);
    this.getddllist();
  }

  changesite(e) {
    this.cid=e.value;
    this.getddllist();
  }
  
  // SetJobCode(eventName, e)
  // {
  //   console.log('SetJobCode',eventName)
  // }
  async setStateValue(rowData: any, value: any) {  
    
   
    rowData.Ticket = value;
     
    
    await this.GetStrucure(value,"Get")
    
    //console.log('ticketdetaisl',this.ticketdetails)
     if(this.ticketdetails !=undefined && this.TaskCount > 0){
          if(parseInt(this.ticketdetails[0].Field4) >0)
          {
            rowData.Product = parseInt(this.ticketdetails[0].Field4) ;
          }
          if(this.ticketdetails[0].ProjectUID >0)
          {
            rowData.Project = this.ticketdetails[0].ProjectUID;
          }
          if(this.ticketdetails[0].MerchantLedgerID >0)
          {
            rowData.Client = this.ticketdetails[0].MerchantLedgerID;
      }
  }
  else
  {

  }
}  

  // logEvent(eventName, e)
  // {

  //   // console.log('sabithumar9',this.Table9)
  //   // console.log('sabithumar10',this.Table10)
  //   // console.log('sabithumar11',this.Table11)
  //   // console.log('sabithumar12',this.Table12)
  //   //  console.log('EDITSTART',eventName)
  //   // if(eventName =='onCellClick')
  //   // {
  //   //   console.log('umar',e)
  //   //   // e.data.Product =5023;
  //   //   // e.values[2]=5023
  //   //   // e.row.Cells[2].Value = 5023;
  //   //   // e.event.data.Product ="fgdg";
  //   //     e.row.cells=[{value:'fgdg'},{value:'433'},{value:'5023'}];
  //   // }
  //   // //if(e.rowType === "data" && e.column.dataField === "Ticket") 
  //   // {
  //   //   const dialogConfig = new MatDialogConfig();
  //   //   dialogConfig.disableClose = true;
  //   //   dialogConfig.autoFocus =  true;
  //   //   dialogConfig.data = {       
  //   //     CID:this.TimeSheetFormGroup.get('fcCompany').value,
  //   //     TaskList:this.HomePage  
  //   //   }
  //   //   const dialogRef = this.dialog.open(TimesheetticketComponent, dialogConfig)
  //   //   dialogRef.afterClosed()
  //   // }
  // }
  
  // cellsetvalue(newData, value, currentRowData){
  //   console.log('EDITSTART')
  //   // if(eventName =='EditingStart')
  //   // //if(e.rowType === "data" && e.column.dataField === "Ticket") 
  //   // {
  //     const dialogConfig = new MatDialogConfig();
  //     dialogConfig.disableClose = true;
  //     dialogConfig.autoFocus =  true;
  //     dialogConfig.data = {       
  //       //CID:this.TimeSheetFormGroup.get('fcCompany').value,
  //       TaskList:this.HomePage  
  //     }
  //     const dialogRef = this.dialog.open(TimesheetticketComponent, dialogConfig)
  //     dialogRef.afterClosed()
  //  // }

  // }

 
  Submit() {   
  
    if (this.TimeSheetFormGroup.invalid) {
      this.NotificationService.warn('Please select effort date');     
      return;
      }   

    let params = {     
      CID: this.TimeSheetFormGroup.get('fcCompany').value,
      Flag: "SETEFFORT",           
      Date : this.datePipe.transform(this.TimeSheetFormGroup.get('fcDate').value,"dd-MM-yyyy"),    
      //Date : this.TimeSheetFormGroup.get('fcDate'),    
      MenuID:'STS_103',  
       LedgerID:this.CurLedgerID,
      Listarray: this.dataSource1 ,
      ApplicationDetails:'Web'         
    };
    this.service.httpPost('/Timesheet/SetTimesheetEffort',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          this.onClear();
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
          this.onClear();
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
 async GetStrucure(ledgerid:any,flag1:any)
  {
    let params = {
      CID: this.cid,
      Flag: flag1,     
      Date : this.datePipe.transform(this.TimeSheetFormGroup.get('fcDate').value,"dd-MM-yyyy"),    
      Ticket:ledgerid,
    };
    
    const dt=await this.service.httpPost('/Timesheet/Getdropdownvalues' ,params)   
    .toPromise().then(data => {
    // this.service.httpPost('/Timesheet/Getdropdownvalues',params)
    //   .subscribe (
    //     (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);
          if (result.errno === 0) {    
            this.TaskCount   =  result.respdata.Ticketdetails.length;
         //   console.log('taskcount',this.TaskCount)
           if(this.TaskCount  > 0)                
             this.ticketdetails= result.respdata.Ticketdetails;   
            else                  
             this.NotificationService.success('Not possible to post effort for this ticket');
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
  
 
  onFocusedRowChanged(e) {  
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;   
    dialogConfig.data = {                 
            pid:0,
            height: '350px',
            width: '70%',                            
            cid:this.TimeSheetFormGroup.get('fcCompany').value,
            flag:"View",
            TicketID:e.data.Ticket,
            TemplateID:1,
            UserName:this.UserID,
            LoginFlag:'INTERNAL',
            ActiviteID:"INT_VIEW",
            TemplateGroupID:1,
            Subject:e.data.Subject                   
        }                
        //console.log('DIALOGDATA',dialogConfig.data)   
        const dialogRef =  this.dialog.open(TaskbyviewComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
          this.Submit();     
        });
      }
}
