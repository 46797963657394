import { Component, OnInit,ViewChild } from '@angular/core';
import { MatDialog  } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
//import { NullTemplateVisitor } from '@angular/compiler';
import { ResourceallocationbyweeklistComponent } from '../resourceallocationbyweeklist/resourceallocationbyweeklist.component';

@Component({
  selector: 'app-resourceallocationbyweek',
  templateUrl: './resourceallocationbyweek.component.html',
  styleUrls: ['./resourceallocationbyweek.component.scss'],
})
export class ResourceallocationbyweekComponent implements OnInit {

  @ViewChild('stepper') stepper;
  dataSource: any;
  SelectedCompanyID: number;
  projectdata: any;
  Companies: any;
  Roles: any;
  Employees: any;
  showSpinner:any
  selectedemployees: number;
  selectedCID: number;
  selectedprojectid: any;
  selectedprojectname: any;
  selecteddepartments: number;
  projectid: string[]= [];
  selectedEmpID: string = 'All';
  EstimationFormGroup: FormGroup;
  EstimationDataFormGroup: FormGroup;
  isEditable = true;
  myControl1 = new FormControl('', [Validators.required]);
 // myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  cid:any;
  oneJan: any;
  numberOfDays: number;
  curMonth: any;
  count:any;
  onSelectionChange:boolean;
  employeeidname: string[] = [];

  constructor(private service: HttpserviceService, 
    private formBuilder: FormBuilder, private dialog: MatDialog,
    public notificationService: NotificationService, private datePipe: DatePipe) 
    { 
      this.EstimationFormGroup = this.formBuilder.group({
        fcCompany: ['', Validators.required],       
        fcRole: ['', Validators.required],
        fcStartWeekNo: ['', Validators.required],
        fcEndWeekNo: ['', Validators.required],
        fcYear: ['', Validators.required]     
      });
    
      this.EstimationDataFormGroup = this.formBuilder.group({        
      });
    }

  ngOnInit() {
    this.getWeekNo();
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.PageLoad();
    this.EstimationFormGroup.controls.fcCompany.setValue(this.cid);
    

     this.filteredOptions2 = this.myControl1.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, 'myControl1'))
    );
  }

  PageLoad() {
    const params = {
      CID: this.cid,
      Flag: 'DROPDOWN'     
    };
  
   this.service.httpPost('/EstimationWeeklyReport/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
         //console.log('data ', data);
          if (result.errno === 0) {
           // console.log('result.respdata.table', result.respdata.Table);
            this.Companies = result.respdata.Companyid;          
            this.Employees = result.respdata.Employees;
            this.projectdata = result.respdata.Project;
            this.Roles = result.respdata.Role;
            this.projectid = _.pluck(this.projectdata, 'ProjectID');
            this.employeeidname = _.pluck(this.Employees, 'Name');
            this.onSelectionChange=false;
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });
  }


  getWeekNo()  {
    const date = new Date();
    this.curMonth =   new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.oneJan =   new Date(date.getFullYear(), 0, 1);
    this.numberOfDays =  Math.floor((this.curMonth - this.oneJan) / (24 * 60 * 60 * 1000));
    this.EstimationFormGroup.get('fcStartWeekNo').setValue(Math.ceil((date.getDay() + 1 + this.numberOfDays) / 7));
    this.EstimationFormGroup.get('fcEndWeekNo').setValue(Math.ceil((date.getDay() + 1 + this.numberOfDays) / 7) + 6);
    this.EstimationFormGroup.get('fcYear').setValue(date.getFullYear());
    console.log(this.EstimationFormGroup.value.fc);
  }
  private _filter(value: string, control: string): string[] {
    
    const filterValue = value.toLowerCase();    
     if (control === 'myControl1') {     
      return this.employeeidname.filter(option => option.toLowerCase().includes(filterValue));
    }

  }

  
  SearchClear1()
  {
    this.myControl1.setValue('');  
  }
  
  
  selectedEmployee(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
   // console.log('control', control);
    if (control === 'myControl') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.projectdata, { 'ProjectID': event.option.value }), 'ProjectUID'));
      //console.log('selectedprojectid', this.selectedprojectid);
    }
    else {
      this.selectedEmpID = event.option.value;
     // console.log('selectedEmpID Split', this.selectedEmpID.slice(0, str.indexOf('-')));
      this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
      //console.log('selectedEmpID', this.selectedEmpID);
    }

  }
  startweekno:number;
  endweekno:number;
  weekdiff:number;
  openDialog()
  {
    if (this.EstimationFormGroup.invalid) {
      this.notificationService.warn('Please select employee and role');    
      return;
      }
     
      this.startweekno=this.EstimationFormGroup.get('fcStartWeekNo').value
      this.endweekno=this.EstimationFormGroup.get('fcEndWeekNo').value;
      this.weekdiff=this.endweekno-this.startweekno+1;     
  
     // console.log('FLAG',Flag);
      //console.log('Weekdiff',this.weekdiff);
     
        const ResourceData = {cid: this.EstimationFormGroup.get('fcCompany').value,
                      //cidname:this.ProjectFormGroup.get('fcCompany').value,
                     // projectname:this.selectedprojectName,
                      empid: this.selectedEmpID,
                      //departmentname:this.selecteddepartmentname,
                      department: this.EstimationFormGroup.get('fcRole').value,
                      startweekno: this.EstimationFormGroup.get('fcStartWeekNo').value,
                      endweekno: this.EstimationFormGroup.get('fcEndWeekNo').value,
                      year: this.EstimationFormGroup.get('fcYear').value,
                      flag: 'ADD'
                      // ,
                      // SetWeekEnd: this.EstimationFormGroup.get('fcWeekEnd').value==true?1:0,
                      // SetHolidays: this.EstimationFormGroup.get('fcHolidays').value==true?1:0
                    }; 
              sessionStorage.setItem('ResourceData', JSON.stringify(ResourceData));
              const dialogRef = this.dialog.open(ResourceallocationbyweeklistComponent);
              dialogRef.afterClosed().subscribe(result => {                 
            });
        
  }

  Submit() {
    if (this.EstimationFormGroup.invalid) {
      this.notificationService.warn('Please select employee and role');    
      return;
      }

    this.showSpinner=true;
    const params = {
      CID: this.cid,
      Flag: 'LOAD',
      EmployeeID:this.selectedEmpID=="All" ? "%" : this.selectedEmpID, 
      DepartmentID:"",
      ProjectID: this.selectedprojectid,
      RoleID:this.EstimationFormGroup.get('fcRole').value==""?"%":this.EstimationFormGroup.get('fcRole').value, 
      FromDate:this.EstimationFormGroup.get('fcStartWeekNo').value, 
      ToDate:this.EstimationFormGroup.get('fcEndWeekNo').value, 
      Year:this.EstimationFormGroup.get('fcYear').value
    };
  
   this.service.httpPost('/ResourceAllocationByWeek/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result = data;
         //console.log('data ', data);
          if (result.errno === 0) {
           // console.log('result.respdata.table', result.respdata);
            this.dataSource = result.respdata;          
            this.stepper.selectedIndex = 1;
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });

  }

  onSearchClear(){
    this.EstimationFormGroup.reset(); 
    this.dataSource='';   
   
    this.myControl1.setValue('');  
    this.selectedprojectid=null;
    this.selectedEmpID='All'
    this.stepper.selectedIndex = 0;
    this.ngOnInit();
  }

  changesite(e) {
    console.log('change',e.value);
    this.cid=e.value;
    this.ngOnInit();
  }

}
