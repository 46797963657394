import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ElementRef, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/Modules/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-quickadd',
  templateUrl: './quickadd.component.html',
  styleUrls: ['./quickadd.component.scss'],
})
export class QuickaddComponent implements OnInit {
  QuickAddFormGroup :FormGroup;
  cid:any;
  Types:any;
  Projects:any;
  datasource: MatTableDataSource<GroupALL>;
  CurLedgerID:any;
  showSpinner:any;
  TaskPrefix:any;
  TicketID:any;
  isDisabled: boolean = false;

  TicketIDSubject:any;
  displayedColumns =['TicketID','Type','Subject','Project','Action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  splittedString: string[];
  constructor(private service:HttpserviceService,private dialogService: DialogService, 
    private formBuilder: FormBuilder,private NotificationService:NotificationService,
    public dialogRef: MatDialogRef<QuickaddComponent>, 
     @Inject(MAT_DIALOG_DATA) public data: any) 
     { 

      
       this.cid=this.data.cid;

       this.QuickAddFormGroup = this.formBuilder.group
       ({  
        fcType: ['', Validators.required],
        fcDescription: ['', Validators.required],
        fcProject:['',Validators.required],
        fcPrefix:['',Validators.required]       
      }); 
     }

  ngOnInit() {
    this.pageLoad();
    this.splittedString = this.data.TicketID.split('-'); 
     this.TicketID=this.data.TicketID ; 
     this.TicketIDSubject=this.data.TicketIDSubject ;
  }

  pageLoad()
  {
    let params = {
      CID: this.cid,
      Flag: "PageLoad",
      TicketID:this.data.TicketID      
    };
    this.service.httpPost('/TicketCreator/GetQuickAdd',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;        
          if (result.errno === 0) {                   
            this.Types = result.respdata.Type; 
            this.Projects = result.respdata.Project;

            this.datasource=result.respdata.Tickets;
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Tickets);
            this.datasource.sort = this.sort;

            this.TaskPrefix=result.respdata.TaskPrefix;
            let rtnprefix = this.TaskPrefix.filter(book => book.PrefixText == this.splittedString[0]+'-'  );           
            this.QuickAddFormGroup.controls.fcPrefix.setValue(rtnprefix[0].PrefixID);

          }
          else {
            this.NotificationService.success(result.errdesc); 
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
  
  Submit()
  {
    if (this.QuickAddFormGroup.invalid) {
      this.NotificationService.warn('Please fill the mandatory fields');     
      return;
      }  
      this.isDisabled = true;
    this.showSpinner=true;    
    let params; 
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.userid;
    params = { 
      CID:this.cid,
      Flag: "QUICKADD",                  
      Type:  this.QuickAddFormGroup.get('fcType').value,   
      Project: this.QuickAddFormGroup.get('fcProject').value,  
      Subject: this.QuickAddFormGroup.get('fcDescription').value,   
      UserName:this.CurLedgerID,
      TicketID:this.data.TicketID,
      Prefix: this.QuickAddFormGroup.get('fcPrefix').value,   

     }
     this.service.httpPost('/TicketCreator/SetQuickAdd',params)
     .subscribe(
       (data) => {
         let result: any;
         result = data;   
         this.showSpinner=true;
         setTimeout(() =>{
           this.showSpinner=false;
         },
         800)    
         if (result.errno === 1) {
           this.NotificationService.success(result.errdesc);
          // this.onCloseDialog();
          this.pageLoad()
          this.onClear();
          this.QuickAddFormGroup.controls.fcPrefix.setValue(1);

           //console.log('Project list: ', result.respdata);
         }
         else {
           this.NotificationService.success(result.errdesc); //':: Problem details');
          // this.onCloseDialog();
         }
       },
       (error: AppError) => {
         if (error instanceof BadInput)         
           console.log(error);
         else throw error;       
       });
  }

  onCloseDialog()
  {
    this.dialogRef.close({event: 'CLEAR'});
  }
  onClear()
  {
    this.QuickAddFormGroup.reset();  
  //  this.QuickAddFormGroup.controls.fcPrefix.setValue(1);
    this.isDisabled = false;
    let rtnprefix = this.TaskPrefix.filter(book => book.PrefixText == this.splittedString[0]+'-'  );           
    this.QuickAddFormGroup.controls.fcPrefix.setValue(rtnprefix[0].PrefixID);
  }

  Delete(event){
   
    this.dialogService.openConfirmDialog('Are you sure do you want to remove this ticket from parent?'+'  '+ event.TicketID )
    .afterClosed().subscribe(res => {
     if (res) {
    let params = {     
      CID:this.cid,
      Flag:"REMOVELINK",    
      TicketID:event.TicketID                  
    };
    this.service.httpPost('/TicketCreator/SetQuickAdd',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 1) {
          this.NotificationService.success(result.errdesc);          
          this.pageLoad()
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
          
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
}
}

export interface GroupALL {
  TicketID: string;
  Type: string;
  Subject: string;
}