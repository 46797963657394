// import { User } from 'src/app/Modules/user';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface GroupALL {
  
  Type: string; 
}

@Component({
  selector: 'app-assementgroup',
  templateUrl: './assementgroup.component.html',
  styleUrls: ['./assementgroup.component.scss'],
})
export class AssementgroupComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  CID:any;
  flag:any;
  ID:any=0;
  CurLedgerID:any;
  Condition:any;
  companies:any;
  employeelist:any;
  datasource:MatTableDataSource<GroupALL>;
  cardvisible:boolean=false;
  btnsavevisible:boolean=false;
  disableflag:boolean=true;

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  empid:string[]=[];
  LedgerID:any=0;


  selectable = true;
  removable = true;
  addOnBlur = false;
  employeelistselected: any = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: any = [];

  displayedColumns =['ID','Name','Level','Action'];
  @ViewChild('fruitInput') fruitInput: ElementRef;
  @ViewChild('autoInput', { read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,private dialogService: DialogService)
     { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    // // let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    // this.CurLedgerID=curUser.ledgerid;
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );  

      this.fruitCtrl.valueChanges.subscribe(search => {
        this.filteredFruits = of(this.employeelist.filter(item =>
        item.Name.toLowerCase().includes(search)
        ));
        });

  }
  private _filter(value: string): string[] {

    const filterValue = value.toLowerCase();   
      return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
  } 
  selectedproduct(event) {    
    this.LedgerID=event.option.value;    
    this.LedgerID=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));     
    if(this.employeelistselected.length>0 && this.LedgerID !=0)
    {
      this.disableflag=false;
    }
  }

  add(event: MatChipInputEvent): void {
    debugger
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.employeelistselected.push({
        id:Math.random(),
        Name:value.trim()
      });

      // this.employeelistselected = options.map(o => o.value);
    
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit, indx): void {
    this.employeelistselected.splice(indx, 1);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.employeelistselected.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);

    console.log("ddf", this.employeelistselected)
    if(this.employeelistselected.length>0 && this.LedgerID !=0)
    {
      this.disableflag=false;
    }
    
    
  }

  Loaddetails()
  {
    let params = {
      cid:this.CID,
      flag:this.flag,
      ID:this.ID
       }
          const query = `cid=${params.cid}&flag=${params.flag}&ID=${params.ID}`;
          this.service.httpGet('/AssementGroup/GetAssementGroup?',query)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.employeelist=result.respdata.Employeelist;
                this.datasource=result.respdata.AssementGrouplist;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.AssementGrouplist);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;

                this.empid= _.pluck(this.employeelist, 'Name');  
              }
              else
              {
                this.employeelistselected=result.respdata.list;
              }
                
                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  addRow()
  {
    this.cardvisible=true;
    this.btnsavevisible=true;
    this.myControl.reset();
    // this.fruitCtrl.reset();
    this.employeelistselected = [];
    this.flag='ADD';
    this.ID=0;
    this.LedgerID=0;
  }
  Edit(event)
  {
    this.cardvisible=true;
    this.btnsavevisible=true;
    this.flag='EDIT';
    this.ID=event.ID;
    this.myControl.setValue(event.Name);
    this.LedgerID=event.LedgerID;
    this.Loaddetails();
    this.disableflag=false;

  }
  Delete(event)
  {
    this.dialogService.openConfirmDialog('Are you sure to delete this level?')
      .afterClosed().subscribe(res => {
       if (res) {
    let params = { 
      cid:this.CID,
      flag: 'DELETE', 
      ID:event.ID,   
      LedgerID:this.LedgerID,         
      Level: "L1" ,   
      UserID:this.CurLedgerID,
      dt: [{'LedgerID':'','Name':''}] //this.employeelistselected
      
     };
    this.service.httpPost('/AssementGroup/SetAssementGroup',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno == 0) {
          this.notificationService.success('Deleted Successfully');
          //console.log('Project list: ', result.respdata);
          // this.dialogRef.close({data:'data'});
          this.cardvisible=false;
          this.btnsavevisible=false;
          this.disableflag=true;
          this.Loaddetails();
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
          this.  Loaddetails();
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
  }
  onClear()
  {
    this.cardvisible=false;
    this.btnsavevisible=false;
    // this.myControl.reset();
    // this.fruitCtrl.reset();
    this.employeelistselected = [];
    this.flag='ADD';
    this.ID=0;
    this.LedgerID=0;
    this.disableflag=true;
  }

  Submit()
  {
   
    let params = { 
      cid:this.CID,
      flag: this.flag, 
      ID:this.ID,   
      LedgerID:this.LedgerID,         
      Level: "L1" ,   
      UserID:this.CurLedgerID,
      dt:this.employeelistselected
      
     };
    this.service.httpPost('/AssementGroup/SetAssementGroup',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno == 0) {
          this.notificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          // this.dialogRef.close({data:'data'});
          this.cardvisible=false;
          this.btnsavevisible=false;
          this.disableflag=true;
          this.flag='PAGELOAD';
          this.Loaddetails();
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
