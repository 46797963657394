import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { NotFoundError } from './../../../../Services/httpServices/ErrorHandler/not-found-error';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { UserService } from './../userValidate';
import { User } from './../../../../Modules/user';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { UserComponent } from '../user/user.component';
import { WorklocationComponent } from '../worklocation/worklocation.component';
import { EmiteventserviceService } from 'src/app/Services/OtherServices/emiteventservice.service';
import { distinctUntilChanged, take } from 'rxjs/operators';




@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  listData: MatTableDataSource<GroupALL>;
  displayedColumns = ['UserID','UserName','GroupID','GroupName','Status','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ViewChild('TABLE') table: ElementRef;
  
  searchKey: string;
  addbtnvisible: boolean = false;
  editbtnvisible: boolean = false;
  deletebtnvisible: boolean = false;
  viewbtnvisible: boolean = false;

  data: any;
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  pageSize: number = 5;
  emitData:any='';
  subscription: any;


  constructor(
    private service: UserService, 
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private generic: GenericService) { 
    this.data = history.state.data;


    }

  ngOnInit(): void {
   // this.pageSize = this.curUser.configparam.find(e => e.tag === 'gridpagesize')['value'];
  // console.log('hi',this.curUser.CustomerSettings('gridpagesize'))
    this.pageSize =this.curUser.CustomerSettings.gridpagesize ;
    this.getUserList();

  
    if (sessionStorage.getItem('ManinformrowData')!=='' && sessionStorage.getItem('ManinformrowData')!==null)
    {
      //console.log('emitData1',JSON.parse(sessionStorage.getItem('ManinformrowData')))
      this.emitData=JSON.parse(sessionStorage.getItem('ManinformrowData'))
  
     // console.log('emitData',this.emitData.otherForm)
      if (this.emitData.otherForm==true)
      {
        this.onEdit(this.emitData.rowValue.UserID, this.emitData.flag)
        sessionStorage.setItem('ManinformrowData','')
      }
  
    }
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "ERP_103",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;
      this.editbtnvisible = btnVisilityData.editflag;
      this.deletebtnvisible = btnVisilityData.deleteflag;
      this.viewbtnvisible = btnVisilityData.viewflag;

      this.listData = result.groupall;
      this.listData = new MatTableDataSource<GroupALL>(result.respdata.groupall);
      this.listData.sort = this.sort;
      this.listData.paginator = this.paginator;     

    
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)
        this.service.form.setErrors(error.originalError);
      else throw error;       
    });

  }
 
 
  applyFilter(filterValue: string) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.listData.filter = filterValue;
    //console.log('hi',filterValue)
    this.listData.filter = filterValue;
  }

  onSearchClear(){
    this.searchKey="";
    this.listData.filter="";
  }

  onCreate(){
    this.service.initializeFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    // dialogConfig.width = "60%";
    dialogConfig.data = {
      flag: 'ADD',
      userid: 0,
      useridold: 0
    }
    const dialogRef = this.dialog.open(UserComponent, dialogConfig)
    dialogRef.afterClosed()
    .subscribe(res => {
      // if (res.event == 'ADD'){
      //   this.listData.data.push(res.data);
      // }else if(res.event == 'EDIT'){
      //   this.updateRowData(res.data);
      // }
      this.getUserList();
    });
  }
 
  updateRowData(data){
    this.listData.data = this.listData.data.filter((value,key)=>{
      if(value.UserID == data.UserID){
        value.GroupID = data.GroupID;
        value.GroupName = data.GroupName;
        value.UserName = data.UserName;
        value.Status = data.Status;
      }
      return true;
    });
  }

  openUserComponent(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    // dialogConfig.width = "60%";
    dialogConfig.data = {
      flag: 'ADD',
      userid: 0,
      useridold: 0
    }
    return this.dialog.open(UserComponent, dialogConfig)      
  }

  onEdit(userID, _flag){
    this.service.populateForm(userID);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    // dialogConfig.width = "60%";
    dialogConfig.data = {
      flag: _flag,
      userid: userID,
      useridold: userID
    }
    const dialogRef = this.dialog.open(UserComponent, dialogConfig);      
    dialogRef.afterClosed().subscribe(result => {     
      this.getUserList();     
    });  
  }
  
  onDelete(userID){
    this.dialogService.openConfirmDialog('Are you sure to delete this user?')
    .afterClosed().subscribe(res => {
      if (res) {
        let params = {
          cid: JSON.parse(sessionStorage.getItem('cid')),
          userid: userID
        }
        this.service.delete(params)
          .subscribe( 
            data => {
              var result: any;
              result = data;
              if (result.errno === 0) 
                this.notificationService.success('User deleted successfully');
                this.getUserList();
              // else throw error;
            }, 
            (error: AppError) => {
              if (error instanceof NotFoundError)
                alert('This user already been deleted');
              else throw error;
          });
      }
    });
    
  }  
  
  exportAsExcel()
  {
    this.generic.excelExport(this.listData.data, 'UserData', 'userlist' );
  }
  onsetting()
  {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    // dialogConfig.width = "60%";
    dialogConfig.data = {
      flag: 'ADD',
      userid: 0,
      useridold: 0
    }
    const dialogRef = this.dialog.open(WorklocationComponent, dialogConfig)
    dialogRef.afterClosed()
    .subscribe(res => {
      if (res.event == 'ADD'){
        //this.listData.data.push(res.data);
      }else if(res.event == 'EDIT'){
        //this.updateRowData(res.data);
      }
    });
  }

}


export interface GroupALL {
  UserID : string;
  UserName: string;
  GroupID: string;
  GroupName: string; 
  Status: string;  
}