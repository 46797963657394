import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { EntityComponent } from './../entity/entity.component';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-entityconfig',
  templateUrl: './entityconfig.component.html',
  styleUrls: ['./entityconfig.component.scss'],
})
export class EntityconfigComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  CID:any;
  flag:any;
  Condition:any;
  companies:any;
  entitytype:any;
  datasource:MatTableDataSource<GroupALL>;
  addbtnvisible:boolean=false;


  displayedColumns =['EntityID','Tag','Description','Action'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,private dialogService: DialogService,
    private NotificationService:NotificationService)
     { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();
  }

  Submit()
  {
    this.Loaddetails();
  }

  onCompanySelect(event)
  {
    this.CID=event;
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();

  }

  Loaddetails()
  {
    let params = {
      cid:this.CID,
      flag:this.flag,
      Condition:this.Condition
      
       }
          const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
          this.service.httpGet('/Entity/GetEntity?',query)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.entitytype=result.respdata.EntityType;
                
              }
              else
              {
                this.datasource=result.respdata.EntityList;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.EntityList);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
              }
                
                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  onConfiguration(event)
  {
    // console.log('LOADGRID',event)
    this.flag='LOADGRID'
    this.Condition=event.value;
    this.Loaddetails();
    this.addbtnvisible=true;
  }
  add()
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'ADD' ,                            
          Type:this.Condition,
          entityid:'0',
          Tag:'',
          Description:''   
        }
        const dialogRef = this.dialog.open(EntityComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
          this.Submit();     
        });
  }
  Edit(event)
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'EDIT' ,                            
          Type:this.Condition,
          entityid:event.EntityID,
          Tag:event.Tag,
          Description:event.Description,
          ReadonlyTag:event.ReadOnlyTag,
          AllowEffort:event.AllowEffort   
        }
        const dialogRef = this.dialog.open(EntityComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
          this.Submit();     
        });
  }
  setting()
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'Config' ,                            
          Type:this.Condition,
          entityid:'0',
          Tag:'',
          Description:''   
        }
        const dialogRef = this.dialog.open(EntityComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
        this.CID=this.CID;
        this.flag='PAGELOAD'
        this.Condition="''";
        this.Loaddetails();

        });
  }

  Delete(event){
  
    let sdate =Date();
    this.dialogService.openConfirmDialog('Are you sure to delete ?')
    .afterClosed().subscribe(res => {
     if (res) {
      let params = { 
        cid:this.CID,
        flag:'DELETE', 
        EntityID:event.EntityID,
        Type:event.Type,             
        Tag:  event.Tag,   
        Description: event.Description,
        ReadonlyTag:event.ReadonlyTag
        
       };
       this.service.httpPost('/Entity/SetEntity',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.warn("Deleted Successfully");
          //console.log('Project list: ', result.respdata);
          this.Submit();     
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
}

}

export interface GroupALL {
  
  Type: string; 
}
