import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadServiceService {

  constructor(private http: HttpClient) { }

  // downloadFile(fileUrl: string, fileName: string) {
  //   console.log('servicesuccess')
  //   return this.http.get(fileUrl, {
  //     responseType: 'blob', // Expect binary data
  //   }).subscribe((blob) => {
  //     this.saveFile(blob, fileName);
  //   });
  // }

  // // Save the file (uses file-saver for browser compatibility)
  // saveFile(blob: Blob, fileName: string) {
  //   saveAs(blob, fileName); // You can also implement your custom logic here without file-saver
  // }
  downloadFile(fileUrl: string) {
    console.log('servicesuccess')
    return this.http.get(fileUrl, {
      responseType: 'blob', // Expect a binary file
      observe: 'response',
    });
  }

  saveFile(blob: Blob, filename: string) {
    saveAs(blob, filename);
  }
}
