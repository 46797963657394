import { OnboardapprovalComponent } from './../onboardapproval/onboardapproval.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { DatePipe } from '@angular/common';

import { User } from './../../../../Modules/user';
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { EmployeeOnBoardComponent } from '../employee-on-board/employee-on-board.component';
import { EmpRegComponent } from 'src/app/components/HR/EmployeeRegistration/emp-reg/emp-reg.component';

@Component({
  selector: 'app-employee-on-boardlist',
  templateUrl: './employee-on-boardlist.component.html',
  styleUrls: ['./employee-on-boardlist.component.scss'],
})
export class EmployeeOnBoardlistComponent implements OnInit {
  showSpinner:any;
  companies:any;
  Templatelist:any;
  CID:any;
  TemplateID:any=0;
  dataset:any;
  flag:any;
  dataSource:any;
  ApprovalDetails:any;
  LedgerID:any;
  Statuslist=[{'Status':'0','Tag':'InProcess'},{'Status':'1','Tag':'Completed'}]
  constructor(private datePipe: DatePipe,public notificationService: NotificationService,private service:HttpserviceService,
    private dialog: MatDialog,@Inject(LOCALE_ID) public locale: string,private dialogService: DialogService) 
    { 

    }

  ngOnInit() 
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.companies=curUser.dtsiteid;
    this.LedgerID=curUser.ledgerid;
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag="PAGELOAD"
    this.GetStrucure();
  }
  async GetStrucure()
  {
    this.showSpinner=true;
    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      flag:this.flag,
      OnBoardTemplateID:this.TemplateID,
      LedgerID:this.LedgerID
    };    
    const query = `cid=${params.cid}&flag=${params.flag}&OnBoardTemplateID=${params.OnBoardTemplateID}&LedgerID=${params.LedgerID}`;
   await this.service.httpGet('/EmployeeOnBoard/GetEmployeeOnBoard?' ,query)   
    .toPromise().then(data => {
      let result: any;
      result = data;
      this.showSpinner=true;
        setTimeout(() =>{
          this.showSpinner=false;
        },
        800)  
      if(result.errno == 0){
        if(this.flag=='PAGELOAD')
        {
          this.Templatelist=result.respdata.TemplateList;
          this.dataset=result.respdata;
        }
        else if(this.flag=='LOADGRID')
        { 
          this.dataSource=""
          this.dataSource=result.respdata.dataSource 
        }
        else if(this.flag=='APPROVAL')
        { 
          this.ApprovalDetails=""
          this.ApprovalDetails=result.respdata.ApprovalDetails 

          console.log('hh33',this.ApprovalDetails)
        }
         
         
        }
      else{
        console.log('error');        
      }
    },
    )
  }
  onTemplateSelect(e)
  {
    this.TemplateID=e
    console.log('this.TemplateID', e);  
  }

  addnewEmp()
  {
    const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus =  true;
     dialogConfig.data = {
       flag: 'ADD',
       ledgerID:0,
       cid: this.CID
      // Rolelist:this.Rolelist,
     }
     
     const dialogRef = this.dialog.open(EmpRegComponent, dialogConfig)
     dialogRef.afterClosed()
  }

  addRow()
  {
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        cid:this.CID,
        flag: "ADD",
        list:this.dataset,
      }
      const  dialogRef =  this.dialog.open(EmployeeOnBoardComponent, dialogConfig)
      dialogRef.afterClosed().toPromise().then(result => {
      
      });
  }

  Refresh()
  {
    this.flag="LOADGRID"
    this.GetStrucure();
  }
  async Approval()
  {
    this.flag="APPROVAL"
    await this.GetStrucure();
    console.log('hh',this.ApprovalDetails)
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        cid:this.CID,
        flag: "Approval",
        TemplateID:this.TemplateID,
        list:this.ApprovalDetails,
        LedgerID:this.LedgerID
      }
      const  dialogRef =  this.dialog.open(OnboardapprovalComponent, dialogConfig)
      dialogRef.afterClosed().toPromise().then(result => {
      
      });
  }
  onRowPreparing(e)
  {
   
    if (e.rowType === "data" &&  e.column.dataField !='Name') {  
      e.cellElement.style.color = e.value=="Completed" ? "green" :"red";
  }  
  }


}
