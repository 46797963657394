import { TicketactivityviewComponent } from './../ticketactivityview/ticketactivityview.component';
import { json } from '@angular-devkit/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TicketComponent } from '../ticket/ticket.component';
import { MatStepper } from '@angular/material/stepper';
import * as _ from 'underscore/underscore-min.js';
import { attachmentComponent } from '../attachment/attachment.component';
import { Platform } from '@ionic/angular';
import { CommentComponent } from '../comment/comment.component';
import { AppSidebarComponent } from './../../../../LandingPageComponents/sidebar/sidebar.component'
import { environment } from 'src/environments/environment';
import { TaskbyviewComponent } from '../../CustomsReport/taskbyview/taskbyview.component';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { EmiteventserviceService } from 'src/app/Services/OtherServices/emiteventservice.service';
import { QuickaddComponent } from '../quickadd/quickadd.component';
import { LinkticketaschildComponent } from '../linkticketaschild/linkticketaschild.component';
import { ReportRequest } from 'src/app/Modules/report';
import { DialogforreportsComponent } from 'src/app/components/dialogforreports/dialogforreports.component';
import { DatePipe } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-ticketmanager',
  templateUrl: './ticketmanager.component.html',
  styleUrls: ['./ticketmanager.component.css']
})
export class TicketmanagerComponent {

  @ViewChild(AppSidebarComponent) AppSidebarComponent;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('contextMenu1', { static: false }) contextMenu1: any;

  id: number = 10;
  row: number = this.id / 2;
  row1 = Array(this.row).fill(1);
  row2 = Array(3).fill(1).map((_, index) => index + 1);
  matrix = []
  CIDisvisible: boolean = false;
  CID: any;
  flag: any;
  Activityflag: any;
  TicketID: any = '0';
  datasource: any;
  TaskTemplate: any;
  TicketActivity: any;
  companies: any
  Templatelist: any
  CurLedgerID: any
  selectedValue: any
  isEditable = false;
  TicketActivitylist: any;
  ticketlist: any
  ticketlist1: any
  ticketlist2: any
  ticketlist3: any
  ticketlist4: any
  ticketlist5: any
  ActiviteID: any
  menus1: any;
  TemplateGroupID: any;
  UserType: any;
  TemplateID: any = 0;
  AddTemplate: any;
  addbtnvisible: boolean = false
  firstFormGroup: FormGroup;
  steppercount: number = 0
  devicevisble: boolean = false
  mobileplatform: boolean = false
  showSpinner: any;
  menuListItems: MatMenuListItem[];

  tabgroups: any
  homepage1: any
  homepage2: any
  homepage3: any
  homepage4: any
  homepage5: any
  UserID: any;
  linkscount: any = 0;
  childcount: any = 0;
  data1: any;
  count:any;
  TicketLink: any;
  ChildIssues: any;
  geovisible:boolean;
  emitData:any='';
  imagelist:any;
  commentlist:any;
  ticketdetails:any;
  selectedRowData: any;
  actioncolumnvisible:boolean=false;
  isContextMenuVisible = false;
  menuVisible = false;
  contextMenuPosition = {
    my:"center",
    at:"center",
    of:"#someElement"
  };
  contextMenuPosition1 = {
    my:"left",
    at:"left",
    of:"#target"
  };
  target: any; 
  someElement: any; 
  eventPosition: any;
  menuItems = [
    { text: 'Edit',icon: 'edit' },
    { text: 'View',icon: 'visibility' },
    // Add more menu items here
  ];

  @ViewChild('stepper') stepper;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns = ['CreatedDate',  'TicketID', 'Subject','Type', 'Priority', 'Status', 'Action'];
  constructor(private dialog: MatDialog, public service: HttpserviceService, private dialogService: DialogService, private eventService: EmiteventserviceService,
    public notificationService: NotificationService, public plt: Platform,private cdr: ChangeDetectorRef,private datepipe :DatePipe) {

    var chunk_size = 2;
    var arr = this.row2;
    var groups = arr.map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
    })
      .filter(function (e) { return e; });
    this.matrix = groups;

    this.firstFormGroup = new FormGroup({

      Name: new FormControl('', [Validators.required])
    });

  }

  ngAfterViewInit() {
   
    // Now you can use this.stepper safely
    // if (this.stepper) {
    //   this.stepper.next();
    //   console.error('done');
    //   this.cdr.detectChanges();
    // } else {
    //   console.error('MatStepper is not initialized.');
    // }
  }

  ngOnInit() {

    this.CID = JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID = curUser.ledgerid;
    this.UserID = curUser.userid;
    this.TemplateGroupID = curUser.TemplateGroupID;
    if (curUser.UserType === 'External') {
      this.CIDisvisible = false;
    }
    else {
      this.CIDisvisible = true;
    }
   // console.log("TicketID", sessionStorage.getItem('TicketID'))

    //if (JSON.parse(sessionStorage.getItem('TicketID')) !== null){
      if (sessionStorage.getItem('TicketID') !== null && sessionStorage.getItem('TicketID') !== 'null'){     
      this.CID = 101
      let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
      this.CurLedgerID = curUser.ledgerid;
      this.flag = 'View'
      this.ActiviteID = 'INT_VIEW'
      this.Activityflag = 'EDIT'
      this.TemplateGroupID = 1;
      this.TemplateID = 1;
      this.TicketID = sessionStorage.getItem('TicketID')

      this.firstFormGroup.controls.Name.setValue('test');
      this.isEditable = true
      this.Loaddetails();          
    }
    else {     
      this.flag = 'PAGELOAD'
      this.ActiviteID = 'EDIT'
      this.Loaddetails();          

    }
    sessionStorage.setItem('TicketID', null)
    

  //   if (sessionStorage.getItem('ManinformrowData')!=='' && sessionStorage.getItem('ManinformrowData')!==null)
  //   {
  //     this.emitData=JSON.parse(sessionStorage.getItem('ManinformrowData'))
  //   }
    

  //   this.CID = JSON.parse(sessionStorage.getItem('cid'));
  //   let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  //   this.CurLedgerID = curUser.ledgerid;
  //   this.UserID = curUser.userid;
  //   this.TemplateGroupID = curUser.TemplateGroupID;
  //   if (curUser.UserType === 'External') {
  //     this.CIDisvisible = false;
  //   }
  //   else {
  //     this.CIDisvisible = true;
  //   }
    
  // // console.log("TicketID", sessionStorage.getItem('TicketID'))

  //   //if (JSON.parse(sessionStorage.getItem('TicketID')) !== null){
  //     if (sessionStorage.getItem('TicketID') !== null && sessionStorage.getItem('TicketID') !== 'null')
  //     {     
  //      // console.log('hi')
    
  //       // this.count=JSON.parse(sessionStorage.getItem('tabcount'))
    
  //       //   sessionStorage.setItem('tabcount',this.count)

  //           this.CID = 101
  //           let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  //           this.CurLedgerID = curUser.ledgerid;
  //           this.flag = 'View'
  //           this.ActiviteID = 'INT_VIEW'
  //           this.Activityflag = 'EDIT'
  //           this.TemplateGroupID = 1;
  //           this.TemplateID = 1;
  //           this.TicketID = sessionStorage.getItem('TicketID')

  //           this.firstFormGroup.controls.Name.setValue('test');
  //           this.isEditable = true
  //           this.Loaddetails();                            
  //     }
  //     else if (this.emitData.otherForm !==null && this.emitData.otherForm==true && this.emitData.flag=='View')
  //     {

  //           this.CID = 101
  //           let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  //           this.CurLedgerID = curUser.ledgerid;
  //           this.flag = this.emitData.flag
  //           this.ActiviteID ='INT_VIEW'
  //           this.Activityflag = 'EDIT'
  //           this.TemplateGroupID = 1;
  //           this.TemplateID = 1;
  //           this.TicketID = this.emitData.rowValue.TicketID

  //           this.firstFormGroup.controls.Name.setValue('test');
  //           this.isEditable = true
  //           this.Loaddetails();  
            
  //           sessionStorage.setItem('ManinformrowData','')

  //     }
  //     else if (this.emitData.otherForm !==null && this.emitData.otherForm==true && this.emitData.flag=='Edit')
  //       {
  
  //             this.CID = 101
  //             let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  //             this.CurLedgerID = curUser.ledgerid;
  //             this.flag =  'ACTIVITY'
  //             this.ActiviteID ='INT_EDIT'
  //             this.Activityflag = 'EDIT'
  //             this.TemplateGroupID = 1;
  //             this.TemplateID = 1;
  //             this.TicketID = this.emitData.rowValue.TicketID
  
  //             this.firstFormGroup.controls.Name.setValue('test');
  //             this.isEditable = false
  //             this.Loaddetails();  
              
  //             sessionStorage.setItem('ManinformrowData','')
  
  //       }
  //     else 
  //     {     
  //       this.flag = 'PAGELOAD'
  //       this.ActiviteID = 'EDIT'
  //       this.Loaddetails();     
       
  //     }
  //   sessionStorage.setItem('TicketID', null)
    

  }
  activeview(value: any) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {

      dataview: value,


    }
    const dialogRef = this.dialog.open(TicketactivityviewComponent, dialogConfig)
    dialogRef.afterClosed()

  }
  onCompanySelect(event) {
    this.CID = event;
    this.Loaddetails();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  onTemplateSelect(event) {
    this.TemplateID = event;
    this.flag = 'HomePage'
    this.Loaddetails();
  }

  convertNewLines(comment: string): string {
    return comment.replace(/(\r\n|\n|\r)/g, '<br>');
  }

  
  
  clickMenuItem(menuItem: MatMenuListItem, Value,subject,stepper: MatStepper) {

    //console.log('item',menuItem)
    this.TicketID = Value
    //var Subject:any=subject;
    this.ActiviteID = menuItem.ActivityID
    const actvityflag = menuItem.ActivityID.split("_", 4)
    console.log('this.ActiviteID ',this.ActiviteID )
    this.flag = 'ACTIVITY'
    if (actvityflag[1] == 'VIEW') {  
      // this.flag = 'View'
      // this.firstFormGroup.controls.Name.setValue('test');
      // this.isEditable = true
      // this.steppercount = 1
      // stepper.next();
      //console.log('hi')
      sessionStorage.setItem('activityTicketID', this.TicketID);
      sessionStorage.setItem('TaskSubject', subject);
      sessionStorage.setItem('TemplateID', this.TemplateID);
      sessionStorage.setItem('ActivityID', this.ActiviteID);
      //sessionStorage.setItem('TemplateIDGroupID', this.TemplateGroupID);
      let item: any = { "name": this.TicketID, "type": "FORM", "icon": "arrow_right", "menuid": "STS_134", "parentid": "54", "visible": true };
      this.eventService.emitMenuSelect(item);
    }
    else if (actvityflag[1] == 'ATTACHMENT') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid: 0,

        template: this.TaskTemplate,
        TicketID: this.TicketID,
        geovisible:this.geovisible

      }
      const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
      dialogRef.afterClosed()
    }
    else if (actvityflag[1] == 'COMMENT') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        flag: 'EDIT',
        pid: 0,

        template: this.TaskTemplate,
        TicketID: this.TicketID,
        TemplateID: this.TemplateID

      }
      const dialogRef = this.dialog.open(CommentComponent, dialogConfig)
      dialogRef.afterClosed()
    }
    else if (actvityflag[1]=='Report')
      {
     
        const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        flag: 'Report',
        pid: 0,

        template: this.TaskTemplate,
        TicketID: this.TicketID,
        geovisible:this.geovisible

      }
      const dialogRef = this.dialog.open(DialogforreportsComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {  
      console.log('result',result)
      if(result!==undefined)
      {
        this.printvalue(result); 
      }
         
    });


        

      }
      else{
        this.Activityflag = 'EDIT'
        this.Loaddetails();
      }
    //var temp=actvityflag[1] ;
    //console.log('actvityflag[1].toString()',actvityflag[1].toString())
    // if (actvityflag[1].toString() !=='ATTACHMENT' || actvityflag[1].toString()!=='COMMENT')
    // {
    //   this.Activityflag='EDIT'
    //   this.Loaddetails();
    // }

    // if (actvityflag[1] !=='COMMENT')
    // {
    //   this.Activityflag='EDIT'
    //   this.Loaddetails();
    // }

    // console.log('amjath', actvityflag);

    // if (actvityflag[1] != 'COMMENT' && actvityflag[1] != 'ATTACHMENT' && actvityflag[1] != 'Report') {
    //   //  console.log('Comment');
    // this.Activityflag = 'EDIT'
    // this.Loaddetails();
    // }
    // else if (actvityflag[1] !='ATTACHMENT')
    // {
    //   console.log('ATTACHMENT');
    //   this.Activityflag='EDIT'
    //   this.Loaddetails();
    // }    
  }

  async GetReportValue()
  {
    let params = {
      cid: this.CID,
      flag:'ReportDetails',
      TicketID: this.TicketID,
      TemplateID: this.TemplateID,
      UserName: this.UserID,
      TagID: 'External',
      ActiviteID: this.ActiviteID,
      TemplateGroupID: this.TemplateGroupID

    }
    //console.log('vigi',params)

   await this.service.httpPost('/TicketCreator/GetTicketCreator?', params)
      .toPromise().then(data => {
            let result: any;
            result=data;
            if (result.errno === 0){

         
              this.imagelist=result.respdata.DynImage;
              this.commentlist=result.respdata.Comment;
              this.ticketdetails=result.respdata.Ticket;
              //console.log('TE',this.imagelist)

              // this.downloadFilesAsZip(this.datasource);
             
              
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
         
  }

 async printvalue(row)
  {
   await this. GetReportValue();
  // console.log('row',row)
    const reportRequest: ReportRequest = {
      CID:this.CID,
      HeaderBanner:row.HeaderBanner,
      reportName: row.FileName,
      TableName:'DynImage',
      dataSet: {
        "imagelist":this.imagelist,
        "TicketComment":this.commentlist,
        "test":[
          {"test1":2},
          {"test1":3}
        ]
      },
      parameters: {
        'pReportName': '12345',
        'pSiteName': '12345',
    'pContractRefNo': this.ticketdetails[0].Field5,
    'pTicketNo':this.ticketdetails[0].Field11==null?"":this.ticketdetails[0].Field11,
		'pTicketDate':this.datepipe.transform(this.ticketdetails[0].CreatedDate,"dd/MM/yyy hh:mm"),
		'pContactonsite': "",
		'pContact1': "",
		'pVisitDate': this.datepipe.transform(this.ticketdetails[0].CreatedDate,"dd/MM/yyy hh:mm"),
		'pTel': "",
		'pComplaint': this.ticketdetails[0].Subject,
		'pComment1': 'Button box remove',
		'pComment2': 'COmment2',
		'pComment3': 'COmment3',
		'pComment4': 'COmment4',
		'pComment5': 'COmment5',
		'pComment6': 'COmment6',
		'pComment7': 'COmment7',
		'pComment8': 'COmment8',
		'pComment9': 'COmment9',
		'pComment10': 'COmment10',
		'pLocation': this.ticketdetails[0].Field4,
		'pEndUserStaffID':"",
		'pEndUserDate': "",
		'pEndUserContact':"",
		'pRTFEngineer': "",
		'pRTFEngineerDate': "",
		'pManagerApproval': "",
		'pManagerApprovalDate': "",
      },
      sendMailFlag: false,
      fromMail: 'example@example.com',
      toMail: 'example@example.com',
      flag: 'EMPLOYEE',
      attachFileName: 'Report.pdf'
    };
    console.log('reportRequest',reportRequest)
    this.service.httpPost3('/Report/GenerateReport',reportRequest).subscribe((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
       window.open(url); 
    });
  }
  addLink() {
    //this.Activityflag='EDIT'
    this.flag = 'ACTIVITY'
    this.ActiviteID = 'INT_LINK'
    this.Loaddetails();

  }

  baseURL: any;
  viewissue() {
    this.baseURL = environment.TicketapiUrl;
    // console.log('baseurl',this.baseURL)
    //window.open("http://localhost:55121/Controllers/Admin/WebForm1.aspx?issueid=" + 1001 );
    window.open(this.baseURL + "/Controllers/Admin/WebForm1.aspx?issueid=" + 1001);
  }

  RemoveLink(event: MouseEvent, buttonText: string, ticketno: string) {
    this.dialogService.openConfirmDialog('Are you sure do you want remove this ticket link?' + "  " + ticketno)
      .afterClosed().subscribe(res => {
        if (res) {
          const query = `cid=${this.CID}&slno=${buttonText}&userID=${this.UserID}`;
          this.service.httpGet('/TicketCreator/SetRemoveLink?', query)
            .subscribe(
              data => {
                let result: any;
                result = data;
                if (result.errno === 0) {
                  this.notificationService.success(result.errdesc);
                  this.Loaddetails();
                  //this.stepper.selectedIndex = 1;
                }
                else {
                  this.notificationService.success(result.errdesc); //':: Problem details');        
                }
              },
              error => {
                console.log(error);

              });
        }
      });
  }

    
  Loaddetails() {
    this.showSpinner = true;
    let params = {
      cid: this.CID,
      flag: this.flag,
      TicketID: this.TicketID,
      TemplateID: this.TemplateID,
      UserName: this.UserID,
      TagID: 'External',
      ActiviteID: this.ActiviteID,
      TemplateGroupID: this.TemplateGroupID

    }
    //console.log('vigi',params)

    this.service.httpPost('/TicketCreator/GetTicketCreator?', params)
      .subscribe(data => {
        let result: any;
        result = data;
        this.showSpinner = true;
        setTimeout(() => {
          this.showSpinner = false;
        },
          800)
        if (result.errno === 0) {

          if (this.flag == 'PAGELOAD') {
            this.companies = result.respdata.Companylist
            this.Templatelist = result.respdata.TaskTemplate

            // console.log('temp',this.Templatelist)
            this.TemplateID = this.Templatelist[0].TemplateID;
            this.onTemplateSelect(this.TemplateID);

            this.geovisible=result.respdata.FormPropertySettings==undefined? false: JSON.parse( result.respdata.FormPropertySettings[0].TagValue)
             // console.log('temp', result.respdata.FormPropertySettings)

          }
          else if (this.flag == 'LOADGRID') {
           
            this.menuListItems = result.respdata.TaskTemplate
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.TicketList);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
            this.AddTemplate = result.respdata.AddTemplate

            this.addbtnvisible = this.AddTemplate.length > 0 ? true : false

          }
          else if (this.flag == 'HomePage') {

          
             this.menuListItems = result.respdata.TaskTemplate
          
            this.AddTemplate = result.respdata.AddTemplate
            this.tabgroups = result.respdata.HomePage
   
            for (var i = 0; i < result.respdata.HomePage.length; i++) {
              if (i == 0) {
                this.homepage1 = result.respdata.Table4
                //console.log('homepage1',this.homepage1)
              
              }
              else if (i == 1) {
                this.homepage2 = result.respdata.Table5
               
              }
              else if (i == 2) {
                this.homepage3 = result.respdata.Table6
              }
              else if (i == 3) {
                this.homepage4 = result.respdata.Table7
              }
              else if (i == 3) {
                this.homepage5 = result.respdata.Table8
              }


            }
           
            this.addbtnvisible = this.AddTemplate.length > 0 ? true : false

          }
          
          else {
            this.TaskTemplate = result.respdata.TaskTemplate
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
              flag: this.Activityflag,
              pid: 0,
              height: '350px',
              width: '60%',
              template: this.TaskTemplate,
              TicketID: this.TicketID,
              ActiviteID: this.ActiviteID,
              TagID: 'INTERNAL',
              TemplateGroupID: this.TemplateGroupID,
              TemplateID: this.TemplateID

            }

            const dialogRef = this.dialog.open(TicketComponent, dialogConfig)
            dialogRef.afterClosed().subscribe(result => {
              this.flag = 'HomePage'
              if (this.steppercount == 0 || this.steppercount == 1) {
                if (this.steppercount == 0) {
                  this.Loaddetails();
                
                }
                else if (this.steppercount == 1) {
                  this.flag = 'View';
                  this.ActiviteID = "INT_VIEW"
                  this.Loaddetails();
                
                }

              }


            });

          }




        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log(error);
          else throw error;
        });
  }

  addRow() {
    // console.log("TaskTemplate",this.AddTemplate[0].ActivityID)
    this.ActiviteID = this.AddTemplate[0].ActivityID
    this.flag = 'ADD'
    this.Activityflag = 'ADD'
    this.TicketID = '0'
    this.Loaddetails();


  }
  createView() {
    this.isEditable = false
   // console.log("reset")
    this.flag = 'HomePage'
    this.ActiviteID = 'EDIT'
    this.Loaddetails();
    this.steppercount = 0
    this.firstFormGroup.controls.Name.setValue('');
  }
  onAttachment(menuItem: MatMenuListItem, Value) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      flag: 'EDIT',
      pid: 0,
      height: '350px',
      width: '60%',
      template: this.TaskTemplate,
      TicketID: this.TicketID,
      geovisible:this.geovisible

    }
    const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
    dialogRef.afterClosed()
  }

  Refresh() {
    this.TemplateID = this.TemplateID;
    //  console.log('fdsgds',this.TemplateID);
    this.flag = 'HomePage'
    this.Loaddetails();
  }

  ChildQuickAdd()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.width = '500px'; // Set the width
    dialogConfig.height = '200px';
    dialogConfig.data = {
     flag: 'QuickAdd',    
     cid:this.CID,           
     TicketID:this.TicketID
    }
    const dialogRef = this.dialog.open(LinkticketaschildComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      this.Loaddetails();     
    });
  }
  
  QuickAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      flag: 'QuickAdd',
      cid: this.CID,
      TicketID: this.TicketID
    }
    const dialogRef = this.dialog.open(QuickaddComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.Loaddetails();
    });

  }

  RemoveChildissues(event: MouseEvent, ticketno: string) {
    this.dialogService.openConfirmDialog('Are you sure do you want remove this ticket from parent?' + "  " + ticketno)
      .afterClosed().subscribe(res => {
        if (res) {
          let params = {
            CID: this.CID,
            Flag: "REMOVELINK",
            TicketID: ticketno
          };
          this.service.httpPost('/TicketCreator/SetQuickAdd', params)
            .subscribe(
              (data) => {
                let result: any;
                result = data;
                if (result.errno === 1) {
                  this.notificationService.success(result.errdesc);
                  this.Loaddetails();
                 // this.stepper.selectedIndex = 1;
                }
                else {
                  this.notificationService.success(result.errdesc); //':: Problem details');

                }
              },
              (error: AppError) => {
                if (error instanceof BadInput)
                  console.log(error);
                else throw error;
              });
        }
      });
  }

  ArrowBack() {
    this.createView();
    //this.stepper.selectedIndex = 0;
  }

  onRowPrepared(e) {

    if (e.rowType === 'data' && e.data.Status) {
      // Find the Status cell within the row element
      const statusCellIndex = e.component.columnOption("Status", "index");
      const statusCell = e.rowElement.querySelectorAll("td")[statusCellIndex];
  
      // Apply background color to the Status cell
      if (statusCell) {
        statusCell.style.backgroundColor = e.data.Colorcode;
      }
    }
  }
  // onActionClick(event: Event, data: any) {
  //   console.log('Action clicked', event, data);
  //   // this.selectedRowData=data;

  //   // You can control the menu programmatically if needed
  //   this.menuTrigger.openMenu();
  //   this.cdr.detectChanges(); 
  // }
  onActionClick(event: any, cellData: any) {
    this.target = event.currentTarget;
   // console.log('yyy',cellData)
    this.eventPosition = event.element;
    this.menuVisible = true;
    this.selectedRowData=cellData;
  }
  onFocusedRowChanged(e) {  

    // sessionStorage.setItem('activityTicketID',e.data.TicketID);
    // sessionStorage.setItem('TaskSubject', e.data.Subject);
    // sessionStorage.setItem('TemplateID', this.TemplateID);
    // sessionStorage.setItem('ActivityID', this.ActiviteID);
    // let item: any = { "name": e.data.TicketID, "type": "FORM", "icon": "arrow_right", "menuid": "STS_134", "parentid": "54", "visible": true };
    // this.eventService.emitMenuSelect(item);    
  }


}
export class MatMenuListItem {
  ActivityID: string;
  LabelName1: string;
  MenuIcon: string;

}
export interface GroupALL {
  TicketID: string;
  Subject: string;
}
export type Menu = {
  name: string,
  submenu: { name: string }[]
}

