import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe  } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore';
import { AdvancerequestviewComponent } from './../advancerequestview/advancerequestview.component';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';

@Component({
  selector: 'app-advancerequest',
  templateUrl: './advancerequest.component.html',
  styleUrls: ['./advancerequest.component.css']
})
export class AdvancerequestComponent implements OnInit {

  flag: string;
  Companies:any;
  Employees: any;
  Types:any;
  Currency:any;
  datasource:MatTableDataSource<GroupALL>;
  AdvanceRequestFormGroup: FormGroup;
  selectedEmpID: any;
  CurLedgerID:any;
  searchKey: string;
  addbtnvisible: boolean = false;
  editbtnvisible: boolean = false;
  deletebtnvisible: boolean = false;
  exportbtnvisible:boolean=false;
  approvebtnvisible: boolean = false;
  myControl = new FormControl('', [Validators.required]);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  employeeid:string[]=[];
  cid:any;
siteid:any;
  displayedColumns =['ReqID','AliasName1','Description','AmountRequested','ApprovedAmount','StartDate','AmountPaid','AmountReturned','Status','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private generic: GenericService,private formBuilder: FormBuilder, private service1: UserService, 
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService) 
    { 
      this.AdvanceRequestFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required]                  
      });       
    }

  ngOnInit(): void {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));    
    //this.getddllist();
    this.AdvanceRequestFormGroup.controls.fcCompany.setValue(this.cid);
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.username;
    this.siteid=curUser.dtsiteid; 
    this.getddllist();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); 
  }

  Submit()
  {
    this.getddllist();
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  changesite(e) {
    this.cid=e;
    this.getddllist();
  }

  getddllist()
  {
    let params = {
      CID: this.cid,
      Flag: "LOADDDL"
    };
    this.service.httpPost('/AdvanceRequest/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);
          if (result.errno === 0) {       
          //  this.Companies = result.respdata.Companyid;   
            this.Companies = this.siteid;   
            this.Employees = result.respdata.Employees;                           
            this.Types = result.respdata.Type;
            this.Currency=result.respdata.Currency; 
  
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.AdvanceRequestlist);
               this.datasource.sort = this.sort;
               this.datasource.paginator = this.paginator;

               this. getUserList();
            // this.employeeid= _.pluck(this.Employees, 'Name');   
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_18",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;
      this.editbtnvisible = btnVisilityData.editflag;
      this.deletebtnvisible = btnVisilityData.deleteflag;
      this.approvebtnvisible = btnVisilityData.approveflag;
      this.exportbtnvisible = btnVisilityData.viewflag;
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  selectedemployee(event) {        
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;   
    // console.log('selectedEmpID Split', this.selectedEmpID.slice(0, str.indexOf('-')));
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
    // console.log('selectedEmpID', this.selectedEmpID);    
  }

  add()
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.cid,
          Flag:'ADD' , 
          Employees:this.Employees,                           
          Type:this.Types,
          Currency:this.Currency,
          RequestID:''   
        }
        const dialogRef = this.dialog.open(AdvancerequestviewComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
          this.getddllist();     
        });

  }

  exportAsExcel()
  {
    this.generic.excelExport(this.datasource.data, 'Advance Request', 'Advance Request' );
  }

  Edit(event){   
      if(event.Status !='Closed')
      {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {       
            cid:this.cid,
            Flag:'EDIT' , 
            Employees:this.Employees,                           
            Type:this.Types,
            Currency:this.Currency,
            RequestID:event.ReqID   
          }
          const dialogRef = this.dialog.open(AdvancerequestviewComponent, dialogConfig)
          dialogRef.afterClosed().subscribe(result => {     
            this.getddllist();     
          });
        }
        else
           this.NotificationService.success('Edit option not applicable for closed status');
    }

    View(event){   
     
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {       
            cid:this.cid,
            Flag:'VIEW' , 
            Employees:this.Employees,                           
            Type:this.Types,
            Currency:this.Currency,
            RequestID:event.ReqID   
          }
          const dialogRef = this.dialog.open(AdvancerequestviewComponent, dialogConfig)
          dialogRef.afterClosed()
       
    }
    Delete(event){
  
          let sdate =Date();
          this.dialogService.openConfirmDialog('Are you sure to delete this advance?')
          .afterClosed().subscribe(res => {
           if (res) {
          let params = {     
            CID:this.cid,
            Flag:"DELETE",           
            Prefix:event.ReqID,
            LedgerID:'0',  
            Type:  '0',   
            Description: '0',
            AdvAmount: 0,
            Currency: '0',
            UserID:this.CurLedgerID,
            ApprovedAmt:'0',
            MonthlyDeduct:'0',
            DeductionMonthCount:'0',
            StartDate:this.datePipe.transform(sdate,"dd-MM-yyyy")
          };
          this.service.httpPost('/AdvanceRequest/SetAdvanceRequest',params)
          .subscribe(
            (data) => {
              let result: any;
              result = data;       
              if (result.errno === 0) {
                this.NotificationService.success(result.errdesc);
                //console.log('Project list: ', result.respdata);
                this.Submit();
              }
              else {
                this.NotificationService.success(result.errdesc); //':: Problem details');
                this.Submit();
              }
            },
            (error: AppError) => {
              if (error instanceof BadInput)         
                console.log(error);
              else throw error;       
            });
          }
        });
      }

      Approve(event){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.cid,
          Flag:'APPROVE', 
          Employees:this.Employees,                           
          Type:this.Types,
          Currency:this.Currency,
          RequestID:event.ReqID      
        }
        const dialogRef = this.dialog.open(AdvancerequestviewComponent, dialogConfig)
         dialogRef.afterClosed().subscribe(result => {     
          this.getddllist();     
        });
    }
  onClear()
  {
    this.AdvanceRequestFormGroup.reset();  
    this.myControl.setValue('');   
    this.AdvanceRequestFormGroup.controls.fcCompany.setValue(this.cid);
  }

}
export interface GroupALL {
  ReqID: string;
  Description: string;
}
