import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticketactivityview',
  templateUrl: './ticketactivityview.component.html',
  styleUrls: ['./ticketactivityview.component.scss'],
})
export class TicketactivityviewComponent implements OnInit {
 
  dataview:any
  public list: any[] = [];
  constructor( private modalRef: MatDialogRef<any>
    ,public dialogRef: MatDialogRef<TicketactivityviewComponent>,@Inject(MAT_DIALOG_DATA) public data: any) 
    
  {

    
    this.dataview=this.data.dataview
    var data=JSON.parse(this.dataview)
    for (let key of Object.keys(data)) {
      let keydata = data[key];  
      for (let Tag of Object.keys(keydata)) {
        let Value=keydata[Tag]
         this.list.push({Tag,Value}); 
        
      }
         
      } 
   }

  ngOnInit() 
  {
    console.log("amjath", this.list)
  }
  onCloseDialog()
  {
    this.dialogRef.close({event: 'CLEAR'});
  }


}
export interface GroupALL {
  Tag: string;
  Value:string;
}
