import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { GenericService } from './../../../Services/OtherServices/generic.service';

// import { Observable } from 'rxjs/Observable';


// import { AppError } from '../common/app-error';
// import { NotFoundError } from '../common/not-found-error';
// import { BadInput } from '../common/bad-input';
// const baseUrl = `${environment.apiUrl}/quotation`;

@Injectable({
  providedIn: 'root'
})
export class NeworderService {
  baseUrl: string;
  // baseUrl = `${environment.apiUrl}/quotation`;  
  

  constructor(private http: HttpClient, private genericService: GenericService) { 
   // this.baseUrl = this.genericService.getWeblink() + '/salesorder';
  }

  // getCustomerAndSalesman(cid: number){
  //   const query = `cid=${cid}`;
  //   // console.log('getCustomer: ', this.baseUrl + '/getcustomersalesman?' + query);
  //   return this.http.get(this.baseUrl + '/getcustomersalesman?' + query);
  //         //.catch(this.handleError);
  // }

  postFile(caption: string, fileToUpload: File[], customerLedger: string, customername: string, salesmanid: number, username: string){
    const formData: FormData = new FormData();
    let index: number = 1;
    fileToUpload.forEach(function (value) {
      formData.append('Image' + index.toString(), value, value.name);
      console.log(value);
      index = index + 1;
    });

    // formData.append('Image', fileToUpload, fileToUpload.name);
    formData.append('ImageCaption', caption);
    formData.append('CustomerLedger', customerLedger);
    formData.append('CustomerName', customername);
    formData.append('SalesmanID', salesmanid.toString());
    formData.append('UserName', username);
    return this.http.post(this.baseUrl+'/salesmanorder', formData)
          //.catch(this.handleError);
  }

  // getSalesmanQuotation(params: any){
  //   //(cid: number, salesmanid: number, fromdate: Date, todate: Date){
  //   // const query = `cid=${cid}`;
  //   const query = `cid=${params.cid}&salesmanid=${params.salesmanid}&fromdate=${params.fromdate}&todate=${params.todate}&qtnstatus=${params.qtnstatus}`;
  //   console.log('getSalesmanQuotation: ', this.baseUrl + '/getsalesmanquotation?' + query);
  //   // alert('service : '+ this.baseUrl + '/getsalesmanquotation?' + query);
  //   return this.http.get(this.baseUrl + '/getsalesmanquotation?' + query)
  //         .catch(this.handleError);
  // }

  // getSalesmanOrder(params: any){
   
  //   const query = `cid=${params.cid}&salesmanid=${params.salesmanid}&qtnstatus=${params.qtnstatus}`;
  //   // console.log('getSalesmanQuotation1: ', this.baseUrl + '/getsalesmanquotation1?' + query);
  //   // alert('service : '+ this.baseUrl + '/getsalesmanquotation?' + query);
  //   return this.http.get(this.baseUrl + '/getsalesmanorder?' + query)
  //         .catch(this.handleError);
  // }

  // private handleError(error: Response){
  //   if (error.status === 400)
  //       return Observable.throw(new BadInput(error.json()));

  //   if (error.status === 404)
  //   return Observable.throw (new NotFoundError());

  //   return Observable.throw(new AppError(error));
  // }
}
