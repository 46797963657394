import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { DatePipe } from '@angular/common';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import * as _ from 'underscore/underscore-min.js';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-goals2',
  templateUrl: './goals2.component.html',
  styleUrls: ['./goals2.component.scss'],
})

export class Goals2Component implements OnInit {  
  public allEngineers: Employee[] = [
    // { Name: 'A.N. Engineer', LedgerID: 1 },
    // { Name: 'Some Other', LedgerID: 2 },
    // { Name: 'Prof. Engineering', LedgerID: 3 },
  ];
  public chipSelectedEmployees: Employee[] = [];
  public filteredEmployees: Observable<String[]>;
  employee:any;
  //
  // Set this to false to ensure engineers are from allEngineers list only.
  // Set this to true to also allow 'free text' engineers.
  //
  private allowFreeTextAddEngineer = false;

  public fcEmployee = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  CID:any;
  GoalID:any;
  @ViewChild('engineerInput') engineerInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private datePipe: DatePipe,public service: HttpserviceService,public notificationService: NotificationService,
    private dialogService: DialogService,@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<Goals2Component>) 
    {
      this.GoalID=data.GoalID;
      this.CID=data.cid;
     }

  ngOnInit() {    
    this.getGoalsdetails();

    
  }

  public addEngineer(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddEngineer) {
      // only allowed to select from the filtered autocomplete list
      console.log('allowFreeTextAddEngineer is false');
      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || '').trim()) {
      this.selectEngineerByName(value.trim());
    }

    this.resetInputs();
  }

  public removeEngineer(engineer: Employee): void {
    const index = this.chipSelectedEmployees.indexOf(engineer);
    if (index >= 0) {
      this.chipSelectedEmployees.splice(index, 1);
      this.resetInputs();
    }
  }

  public engineerSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectEngineerByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    // clear input element
    this.engineerInput.nativeElement.value = '';
    // clear control value and trigger fcEmployee.valueChanges event
    this.fcEmployee.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(engineerName: string | null): String[] {
    let result: String[] = [];
    //
    // Remove the engineers we have already selected from all engineers to
    // get a starting point for the autocomplete list.
    //
    let allEngineersLessSelected = this.allEngineers.filter(
      (engineer) => this.chipSelectedEmployees.indexOf(engineer) < 0
    );
    if (engineerName) {
      result = this.filterEngineer(allEngineersLessSelected, engineerName);
    } else {
      result = allEngineersLessSelected.map((engineer) => engineer.Name);
    }
    return result;
  }

  private filterEngineer(
    engineerList: Employee[],
    engineerName: String
  ): String[] {
    let filteredEngineerList: Employee[] = [];
    const filterValue = engineerName.toLowerCase();
    let engineersMatchingEngineerName = engineerList.filter(
      (engineer) => engineer.Name.toLowerCase().indexOf(filterValue) === 0
    );
    if (engineersMatchingEngineerName.length || this.allowFreeTextAddEngineer) {
      //
      // either the engineer name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete engineer names to be entered
      //
      filteredEngineerList = engineersMatchingEngineerName;
    } else {
      //
      // the engineer name didn't match the autocomplete list
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      //
      filteredEngineerList = engineerList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer
    // name strings and return it
    //
    return filteredEngineerList.map((engineer) => engineer.Name);
  }

  private selectEngineerByName(engineerName) {
    let foundEngineer = this.allEngineers.filter(
      (engineer) => engineer.Name == engineerName
    );
    if (foundEngineer.length) {
      //
      // We found the engineer name in the allEngineers list
      //
      this.chipSelectedEmployees.push(foundEngineer[0]);
    } else {
      //
      // Create a new engineer, assigning a new higher employeeId
      // This is the use case when allowFreeTextAddEngineer is true
      //
      let highestEmployeeId = Math.max(
        ...this.chipSelectedEmployees.map((engineer) => engineer.LedgerID),
        0
      );
      this.chipSelectedEmployees.push({
        Name: engineerName,
        LedgerID: highestEmployeeId + 1,
      });
    }
  }


  getGoalsdetails()
  {
       let params = {
       cid:this.CID,
       flag:'Emplist',
       GoalID:this.GoalID,
       LedgerID:0,
       FromDate:this.datePipe.transform(new Date(), 'yyy-MM-dd'),
       AssessmentType:"''"
        }
        const query = `cid=${params.cid}&flag=${params.flag}&GoalID=${params.GoalID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}&AssessmentType=${params.AssessmentType}`;
           this.service.httpGet('/Goals/GetGoals?',query)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

             let emplist= result.respdata.Employeelist;
             this.chipSelectedEmployees= result.respdata.selectedEmployee;//_.pluck(result.respdata.selectedEmployee,'Name'); 
              console.log('this.employees',this.employee)
              this.allEngineers= emplist//_.pluck(emplist, 'Name');    
              console.log('this.allEmployees',this.allEngineers)
              this.filteredEmployees = this.fcEmployee.valueChanges.pipe(
                startWith(null),
                map(engineerName => this.filterOnValueChange(engineerName))
              );
 
             }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }

    onSubmit()
    {
      let employee=this.chipSelectedEmployees;
      console.log('employee',employee);
      this.chipSelectedEmployees=_.pluck(this.chipSelectedEmployees, 'Name');
      console.log('this.chipSelectedEmployees', this.chipSelectedEmployees);

      let  paramsExtra;
     let currentdate = new Date();
      paramsExtra = {
        cid: this.CID,
        flag:"ADD",
        GoalID:this.GoalID,
        emplist:this.chipSelectedEmployees
    
      };
      this.service.httpPost('/Goals/SetGoalAccessGroup?',paramsExtra)
    .subscribe(
      (data) => {
        let users: any;
        users = data; //result.users;
  
        if (users.errno === 0){
          this.chipSelectedEmployees=employee;
          this.notificationService.success(users.errdesc);          
        }
        else{
          this.notificationService.success(users.errdesc); //':: Problem updating user');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });
    }
  
    onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    .afterClosed().subscribe(res => {
      if (res) {
    this.dialogRef.close({event: 'CLEAR'});
      }
    });

  }

}

export interface Employee {
  Name: string;
  LedgerID: number;
}