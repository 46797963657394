import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';

@Component({
  selector: 'app-eosposting',
  templateUrl: './eosposting.component.html',
  styleUrls: ['./eosposting.component.scss'],
})
export class EospostingComponent implements OnInit {

  @ViewChild(DxDataGridComponent, { static: false }) gridContainer: DxDataGridComponent;

  cid:any=101;
  Categorylist:any;
  VouDate:any=new Date();
  Employeelist:any;
  LedgerID:any;
  Category:any=0;
  Prefixlist:any;
  Prefix:any;
  VouNo:any;
  flag:any;
  payslipmonth:any=new Date();
  Comment:any;
  dataSource:Details[]=[];
  step = 0;
  panelExpanded = false;
  AccountSource:any;
  ExcludeEmergency:Boolean=true;
  ExcludeUnPaidLeave:boolean=true;
  MenuID:any;
  Ledgerlist:any;
  // lblGratuityBasicSal:any;
  // lblGratuityPerDay:any;

  leavedays:number=0;
  workdays:number=0;
  perday:number=0;
  totaldays:number=0;

  frmdate:any;
  todate:any;
  daysinmonth:number=0;
  daysinyear:number=0;
  noofdays:number=0;

  monthleaveamt:number=0;
  perdayleaveamt:number=0;
  finalleaveamt:number=0;


  EmpPassage:number=0;
  PerDayPassage:number=0;
  FinalPassage:number=0;

  Basicsalary:any=0;
  PerDayGratuity:any=0;
  TotDayGratuity:any=0;
  gratuityvisible:boolean=false;


  _CalcLeaveAmt?: string;
  _ChagLeaveAmt?: string;
  _CalcGratuity?: string;
  _ChangeGratuity?: string;
  _CalcLastMonthSalary?: string;
  _ChangeLastMonthSalary?: string;
  _CalcEosAdvAmount?: string;
  _ChagEosAdvAmount?: string;
  _NetAmt?: string;
  _LessAmt?: string;
  _TotalAmt?: string;
  _ChagPassageAmt?: string;

   objcsls:any;
   Daysinmonth1:any;
   ContractType:any;
   daysinyear1:any;

   isforceNo = false;





  constructor(private service:HttpserviceService,private datePipe: DatePipe,private dialog: MatDialog,private NotificationService: NotificationService, @Inject(MAT_DIALOG_DATA) public data: any)
  {
   //this.LoadData()
   console.log('dialogRef',data)

   this.Categorylist=data.datalist.Category;
   this.Employeelist=data.datalist.Employee;
   this.Prefixlist=data.datalist.Prefix;
   this.AccountSource= data.datalist.AccountGrid; 
   this.Prefix=this.Prefixlist[0].Prefix;
   this.Ledgerlist=data.datalist.Ledgerlist; 

   this.flag=data.flag
   if(this.flag=='EDIT')
     {
       let parts = data.VouNo.split('/');
       this.Prefix=parts[0]+'/'
       this.VouNo=parts[1]
       this.Getstructure()
     }
   
  }

  // calculateSerialNumber = (rowData: any, rowIndex: number): number => {
  //   if (typeof rowIndex !== 'number' || isNaN(rowIndex)) {
  //     console.error('Invalid rowIndex:', rowIndex);
  //     return NaN;
  //   }

  //   return rowIndex + 1;
  // }

  ngOnInit() 
  {
    this.MenuID=sessionStorage.getItem('SelectedMenuID')
    this.gratuityvisible=this.MenuID=='ERP_242_1'?true:false;

  }
  Getstructure()
  {
    const params = {
      _SiteID: this.cid,
      _RefNo:this.Prefix+this.VouNo

     };   
 
     

 
     this.service.httpPost('/EOS/GetLeaveSalary?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      
 
       console.log("mm",result.respdata)

       this.objcsls=result.respdata.obj;
       this.Daysinmonth1=result.respdata.Daysinmonth;
       this.daysinyear1=result.respdata.Daysinyear;

       this.Basicsalary =this.objcsls._BasicSalary;
       this.PerDayGratuity =this.objcsls._PerDayGratuity;
       this.TotDayGratuity=this.objcsls._TotalDaysGratuity;

       this.LedgerID=this.objcsls._LedgerID;
       this.Comment=this.objcsls._Comment;
      //  this.ContractType=result.respdata.ContractType;

     this. Assignlabels();

      
     });
  }

  Populate()
  {

    //this.showSpinner=true;
    const params = {
      _SiteID: this.cid,
      _LedgerID:this.LedgerID,
      _PostDate:this.VouDate,
      _ExcludeEmergency:this.ExcludeEmergency,
      _MenuID:this.MenuID
  
     };   
 
     

 
     this.service.httpPost('/EOS/GetCalcLeaveSalary?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      
 
       console.log("mm",result.respdata)

       this.objcsls=result.respdata.obj;
       this.Daysinmonth1=result.respdata.Daysinmonth;
       this.daysinyear1=result.respdata.Daysinyear;
       this.ContractType=result.respdata.ContractType;

     this. Assignlabels();

      
     });

  }
  Assignlabels()
  {
    this.leavedays=this.objcsls._LeaveDays;
    this. workdays=this.objcsls._NoofDays - this.objcsls._LeaveDays
    this.perday=this.objcsls._PerDay;
    this. totaldays=this.objcsls. _TotalLeaveDays;

    this.frmdate= this.datePipe.transform(this.objcsls._FromDate,'dd-MM-yyyy');
    this.todate= this.datePipe.transform(this.payslipmonth,'dd-MM-yyyy');
    this.daysinmonth=this.Daysinmonth1
    this.daysinyear=this.daysinyear1;
    this.noofdays=this.objcsls._NoofDays ;

    this. monthleaveamt=this.objcsls._MonthlyLeaveAmount ;
    this.perdayleaveamt=this.objcsls._PerDayLeaveAmount ;
    this.finalleaveamt=this.objcsls._ChagLeaveAmt ;

    this.EmpPassage=this.objcsls._PassageAmt ;
    this.PerDayPassage=this.objcsls._PerDayPassageAmt ;
    this.FinalPassage=this.objcsls._ChagPassageAmt ;


    // this. Basicsalary=this.objcsls._ChagPassageAmt ;
    // this.PerDayGratuity=this.objcsls._ChagPassageAmt ;
    // this.TotDayGratuity=this.objcsls._ChagPassageAmt ;
    
    
    this.dataSource=[];

    //let temp={'SNo':1,'Description':'Salary For','CalcAmount':1,'Amount':2};
    if (this.MenuID=='ERP_242_1')
    {
     this.dataSource.push({'Sno':this.dataSource.length+1,'Description':'Salary For'+" " + this.datePipe.transform(this.payslipmonth,"MMM/yyyy") ,'CalcAmount':this.objcsls._CalcLastMonthSalary,'Amount':this.objcsls._ChangeLastMonthSalary});
    }

    this.dataSource.push({'Sno':this.dataSource.length+1,'Description':'Leave Salary For'+" " + (this.objcsls._NoofDays - this.objcsls._LeaveDays) + " Days",'CalcAmount':this.objcsls._CalcLeaveAmt,'Amount':this.objcsls._ChagLeaveAmt});
    this.dataSource.push({'Sno':this.dataSource.length+1,'Description':'Air Ticket Fare','CalcAmount':this.objcsls._CalcPassageAmt,'Amount':this.objcsls._ChagPassageAmt});
    
    
  
     const _Year = { value: 0 };
     const _Month = { value: 0 };
     const _Day = { value: 0 };
     let totalGratuityDays: number;

     if (this.MenuID === "ERP_242_1") {

       if (this.flag=='ADD') 
        {
          
       let postDate = new Date(this.objcsls._PostDate);
       let twoDate;

       // Check if the post date is valid
           if (isNaN(postDate.getTime())) {
           return; // Exit the function early if the date is invalid
           }

           // Check if unPaidLeaveDays is defined and a number
           let unpaidLeaveDays = this.objcsls.unPaidLeaveDays;
           if (isNaN(unpaidLeaveDays)) {
               //console.error("Invalid unPaidLeaveDays value:", unpaidLeaveDays);
               unpaidLeaveDays = 0; // Default to 0 if invalid
           }

           // Determine the number of days to add
           let adjustedDays = this.ExcludeUnPaidLeave ? unpaidLeaveDays + 1 : 1;
           // Add days to the post date
           twoDate = this.addDays(postDate, adjustedDays);

             // Check if the result is a valid date
             if (isNaN(twoDate.getTime())) {
                // console.error("Invalid result after adding days:", twoDate);
             } else {
                // console.log("Valid adjusted date:", twoDate);
             }

           this.Y_M_D_Diff(this.objcsls._JoiningDate,twoDate, _Year, _Month, _Day);

          
   
           this.Basicsalary = this.formatNumber(this.objcsls._BasicSalaryforGratuity , 2);
   
           this.PerDayGratuity = this.formatNumber( this.objcsls._BasicSalaryforGratuity / 30,2);
   
           if (this.ContractType === "Limited") {
               totalGratuityDays = this.dateDiffInDays(this.objcsls._JoiningDate, this.objcsls._PostDate) ;

               //console.log('totalGratuityDays',totalGratuityDays)

               totalGratuityDays = _Year.value * 365 + _Month.value * 30 + _Day.value;  
               if(totalGratuityDays > 1825)
               {
                 let _TotDays =0;
                 _TotDays = (21 * 5);
                 totalGratuityDays = (((totalGratuityDays - 1825)) * 0.082) + _TotDays

               }
               else
               {
                 totalGratuityDays = (21 * _Year.value) + (1.75 * _Month.value) + (0.06 * _Day.value)
               }
                 
         
               this.objcsls._BasicSalaryforGratuity = (this.PerDayGratuity * totalGratuityDays);
               this.objcsls._TotalDaysGratuity = totalGratuityDays;

               
               this.TotDayGratuity= totalGratuityDays
               this.objcsls._CalcGratuity = this.objcsls._BasicSalaryforGratuity
               this.objcsls._ChangeGratuity = this.objcsls._BasicSalaryforGratuity
               this.objcsls._TotalDaysGratuity = totalGratuityDays

              
               
           }
           
   
           // Set the gratuity details in the grid row
           // gratuityRow.description = `Gratuity For ${this.objLS.totalDaysGratuity} Days`;
           // gratuityRow.calcAmount = this.objLS.calcGratuity;
           // gratuityRow.amount = this.objLS.changeGratuity;
       
          } else {
           // this.objLS.basicSalaryForGratuity = 0;
       }
       this.dataSource.push({'Sno':this.dataSource.length+1,'Description': 'Gratuity For ' + this.objcsls._TotalDaysGratuity + 'Days','CalcAmount':JSON.parse(this.objcsls._CalcGratuity.toFixed(2)),'Amount':JSON.parse(this.objcsls._ChangeGratuity.toFixed(2))});
   
   }
   
   this.dataSource.push({'Sno':this.dataSource.length+1,'Description':'Salary/Advance OutStanding','CalcAmount': this.flag=='ADD'? this.objcsls._AdvOutStanding: this.objcsls._CalcEosAdvAmount,'Amount': this.flag=='ADD'? this.objcsls._AdvOutStanding: this.objcsls._ChagEosAdvAmount});
   this.dataSource.push({'Sno':this.dataSource.length+1,'Description':'Total Amount','CalcAmount':JSON.parse( (this.objcsls._CalcLastMonthSalary + this.objcsls._PSAmt + this.objcsls._CalcLeaveAmt + this.objcsls._CalcPassageAmt + this.objcsls._CalcGratuity - this.objcsls._PensionAmt).toFixed(2)),
    'Amount':JSON.parse( ( this.objcsls._ChangeLastMonthSalary + this.objcsls._PSAmt + this.objcsls._ChagLeaveAmt + this.objcsls._ChagPassageAmt + this.objcsls._ChangeGratuity - this.objcsls._PensionAmt).toFixed(2))});
   this.dataSource.push({'Sno':this.dataSource.length+1,'Description':'Less Amount','CalcAmount':0,'Amount':this.objcsls._LessAmt});
   this.dataSource.push({'Sno':this.dataSource.length+1,'Description':'Net Amount','CalcAmount':JSON.parse( (this.objcsls._CalcLastMonthSalary + this.objcsls._PSAmt + this.objcsls._CalcLeaveAmt + this.objcsls._CalcPassageAmt + this.objcsls._CalcGratuity - this.objcsls._PensionAmt).toFixed(2)),
    'Amount':JSON.parse( (this.objcsls._ChangeLastMonthSalary + this.objcsls._PSAmt + this.objcsls._ChagLeaveAmt + this.objcsls._ChagPassageAmt - this.objcsls._LessAmt + this.objcsls._ChangeGratuity - this.objcsls._PensionAmt).toFixed(2))});

    this.AccountSource.map(item=> item.TCDebit=this.objcsls._ChangeLastMonthSalary + this.objcsls._PSAmt + this.objcsls._ChagLeaveAmt + this.objcsls._ChagPassageAmt - this.objcsls._LessAmt + this.objcsls._ChangeGratuity - this.objcsls._PensionAmt);
     
  }
  onRowInserted(event: any) {
    if (event.dataIndex === 0) return; // If it's the first row, do nothing
  
    // Assuming the TCDebit value of the first row is stored in a variable named firstRowTCDebit
    const firstRowTCDebit = this.AccountSource[0].TCDebit;
  
    // Subtract the TCDebit value of the newly added row from the TCDebit value of the first row
    if(firstRowTCDebit>=event.data.TCDebit)
    {
      this.AccountSource[0].TCDebit = firstRowTCDebit - event.data.TCDebit;
    }
    else 
    {
      alert('Overmatching');
  
      this.AccountSource.pop();
    }
   
  }

  formatNumber(value: number, decimalPlaces: number): string {
    console.log('value',value)
    return value.toFixed(decimalPlaces); // Example formatting
}
  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

calculateYearMonthDayDifference(joiningDate: Date, postDate: Date, unPaidLeaveDays: number): void {
    // Implement your date difference logic here
}
dateDiffInDays(startDate: Date, endDate: Date): number {
  // Ensure both dates are Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const diffTime = end.getTime() - start.getTime();
  
  // Convert milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

// Y_M_D_Diff(dateOne: Date, dateTwo: Date, _Year: { value: number }, _Month: { value: number }, _Day: { value: number }): void {
//   let year = 0;
//   let month = 0;
//   let day = 0;


//   console.log('dateOne',dateOne)
//   console.log('dateTwo',dateTwo)
//   if (dateOne.getTime() !== dateTwo.getTime()) { // If both dates are not the same
//       // Years
//       if (dateTwo.getFullYear() > dateOne.getFullYear()) {
//           year = dateTwo.getFullYear() - dateOne.getFullYear();
//       }

//       // Months
//       month = dateTwo.getMonth() - dateOne.getMonth();
//       if (month < 0) {
//           year--;
//           month += 12;
//       }

//       // Days
//       day = dateTwo.getDate() - dateOne.getDate();
//       if (day < 0) {
//           month--;
//           const previousMonth = new Date(dateTwo.getFullYear(), dateTwo.getMonth(), 0);
//           day += previousMonth.getDate();
//       }

//       if (dateOne.getDate() === dateTwo.getDate()) {
//           day = 0;
//           month++;
//       }

//       // Corrections
//       if (month === 12) {
//           month = 0;
//           year++;
//       }
//       if (dateOne.getFullYear() === dateTwo.getFullYear() && dateOne.getMonth() === dateTwo.getMonth()) {
//           year = 0;
//       }
//   }

//   _Year.value = year;
//   _Month.value = month;
//   _Day.value = day;
// }

Y_M_D_Diff(dateOne: any, dateTwo: any, _Year: { value: number }, _Month: { value: number }, _Day: { value: number }): void {
  // Convert inputs to Date objects if they are not already
  let dateOneObj = new Date(dateOne);
  let dateTwoObj = new Date(dateTwo);


    let years = 0;
  let months = 0;
  let days = 0;

  console.log('dateOneObj',dateOneObj);
  console.log('dateTwoObj',dateTwoObj);
  // Check if the dates are valid
  if (isNaN(dateOneObj.getTime()) || isNaN(dateTwoObj.getTime())) {
    console.error("Invalid date provided. DateOne:", dateOne, "DateTwo:", dateTwo);
    // return { years: 0, months: 0, days: 0 };
}

// Ensure DateOne is always earlier than DateTwo
if (dateOneObj > dateTwoObj) {
    [dateOneObj, dateTwoObj] = [dateTwoObj, dateOneObj]; // Swap dates if needed
}

years = dateTwoObj.getFullYear() - dateOneObj.getFullYear();
months = dateTwoObj.getMonth() - dateOneObj.getMonth();
days = dateTwoObj.getDate() - dateOneObj.getDate();

// Adjust days and months if necessary
if (days < 0) {
    months--;
    const previousMonth = new Date(dateTwoObj.getFullYear(), dateTwoObj.getMonth(), 0);
    days += previousMonth.getDate();
}

if (months < 0) {
    years--;
    months += 12;
}
   _Year.value = years;
  _Month.value = months;
  _Day.value = days;

  console.log(`Difference: ${years} years, ${months} months, ${days} days`);
}



  Save()
  {
    const priceDecimalPoint =2; //siteDefaults.CustomerSettings('cp_PriceDecimalPoint')

   //let objLS: ObjectLS;

    for (var i = 0; i < this.dataSource.length; i++) 
      {
        console.log('datasource',this.dataSource[i].Description)




        if (this.MenuID === "ERP_242_1") {
          this._CalcLeaveAmt = this.formatNumber(this.dataSource[1].CalcAmount, priceDecimalPoint);
          this._ChagLeaveAmt = this.formatNumber(this.dataSource[1].Amount, priceDecimalPoint);
  
          if (this.dataSource[i].Description.includes("Gratuity") ) {
            this._CalcGratuity = this.formatNumber(this.dataSource[i].CalcAmount, priceDecimalPoint);
            this._ChangeGratuity = this.formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
          }
  
          //const strCondition = `Salary For ${+" " + this.datePipe.transform(this.payslipmonth,"MMM/yyyy")}`;
          //console.log('3434',strCondition)
          //console.log('434',this.dataSource[i].Description)
          if (this.dataSource[i].Description.includes(this.datePipe.transform(this.payslipmonth,"MMM/yyyy"))) {
            this._CalcLastMonthSalary = this.formatNumber(this.dataSource[i].CalcAmount, priceDecimalPoint);
            this._ChangeLastMonthSalary = this.formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
          }
  
          if (this.dataSource[i].Description === "Salary/Advance OutStanding") {
            this._CalcEosAdvAmount = this.formatNumber(this.dataSource[i].CalcAmount, priceDecimalPoint);
            this._ChagEosAdvAmount = this.formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
          }
      } else {
        this._CalcLeaveAmt = this.formatNumber(this.dataSource[0].CalcAmount, priceDecimalPoint);
        this._ChagLeaveAmt = this.formatNumber(this.dataSource[0].Amount, priceDecimalPoint);
  
          if (this.dataSource[i].Description === "Salary/Advance OutStanding") {
            this._CalcEosAdvAmount = this.formatNumber(this.dataSource[i].CalcAmount, priceDecimalPoint);
            this._ChagEosAdvAmount =this. formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
          }
      }
  
      const description = this.dataSource[i].Description;
    
      if (description === "Net Amount") {
        this._NetAmt = this.formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
      } else if (description === "Less Amount") {
        this._LessAmt = this.formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
      } else if (description === "Total Amount") {
        this._TotalAmt = this.formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
      } else if (description == "Air Ticket Fare") {
        this._ChagPassageAmt = this.formatNumber(this.dataSource[i].Amount, priceDecimalPoint);
          // Note: If you need to break out of a loop, you'd use a return or break depending on the loop context
      }

      }

      let name=this.Employeelist.filter(item => item.LedgerID==this.LedgerID)

      console.log('objLS',this.isforceNo)

      let RefNo='';

      if (this.flag=='ADD' && this.isforceNo==true)
      {RefNo=this.Prefix+this.VouNo}
      else if(this.flag=='ADD'){RefNo=''}
      else{RefNo=this.Prefix+this.VouNo}

      let dateStr = this.frmdate;
      let parts = dateStr.split('-');
      let formattedDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);


      const params = {
       

    csLS:{
      _SiteID: this.cid,
      _Flag:this.flag,
      _MenuID:this.MenuID,
      _FormPrefix:this.Prefix,
      _BusinessPeriodID:101,
      _RefNo:RefNo,
      _LedgerID:this.LedgerID,
      //_Alias:name[0].AliasName1,
      _PostDate:this.VouDate, //this.datePipe.transform(this.VouDate,'dd-MM-yyyy'),
      _FromDate:formattedDate,
      _NoofDays:this.objcsls._NoofDays,
      _LeaveDays:this.objcsls._LeaveDays,
      _PerDay:this.objcsls._PerDay,
      _TotalLeaveDays:this.objcsls._TotalLeaveDays,
      _PSAmt:this.objcsls._PSAmt,

      _MonthlyLeaveAmount:this.objcsls._MonthlyLeaveAmount,
      _PerDayLeaveAmount:this.objcsls._PerDayLeaveAmount,

       _CalcLeaveAmt:this._CalcLeaveAmt,
      _ChagLeaveAmt:this._ChagLeaveAmt,

      _PassageAmt:this.objcsls._PassageAmt,
      _PerDayPassageAmt:this.objcsls._PerDayPassageAmt,
      _CalcPassageAmt:this.objcsls._CalcPassageAmt,
      _ChagPassageAmt:this._ChagPassageAmt,
      _PensionAmt:this.objcsls._PensionAmt,

      _CalcLastMonthSalary:this._CalcLastMonthSalary==undefined?"0":this._CalcLastMonthSalary,
      _ChangeLastMonthSalary:this._ChangeLastMonthSalary==undefined?"0":this._ChangeLastMonthSalary,

      _CalcGratuity:this._CalcGratuity==undefined?"0":this._CalcGratuity,
      _ChangeGratuity:this._ChangeGratuity==undefined?"0":this._ChangeGratuity,

      _BasicSalary:this.Basicsalary,
      _PerDayGratuity:this.PerDayGratuity,
      _TotalDaysGratuity:this.objcsls._TotalDaysGratuity,

      _NetAmt:this._NetAmt,
      _LessAmt:this._LessAmt,
      _TotalAmt:this._TotalAmt,
      _Comment:this.Comment==undefined?"":this.Comment,

      _ExcludeEmergency:this.objcsls._ExcludeEmergency,
     
      _CalcEosAdvAmount:this._CalcEosAdvAmount,
      _ChagEosAdvAmount:this._ChagEosAdvAmount,
     
    },
    DT_AccountLedger:this.AccountSource,
    AliasName:name[0].AliasName1

      };   
  
      console.log("filter",params)
      this.service.httpPost('/EOS/Update_LeaveSalary?' ,params)   
      .subscribe(data =>{
        var result = data as any;
        result = data;      
  
        if (result.errno === 0)
          {
            this.NotificationService.success("Posting Successfully");
          }
          else
          {
            this.NotificationService.warn(result.errdesc);
          }
           
      
         
      });




  }
  // formatNumber(value: number, decimalPoints: number): string {
  //   return value.toFixed(decimalPoints);
  // }

  // refreshGrid() {
  //   const dataSource = this.gridContainer.instance.getDataSource();
  //   if (dataSource) {
  //     dataSource.reload().done(() => {
  //       console.log('Grid data reloaded successfully.');
  //     }).fail((error) => {
  //       console.error('Error reloading grid data:', error);
  //     });
  //   } else {
  //     console.warn('DataSource not available');
  //   }
  // }
  // updateRowInGrid(key, updatedData) {
  //   const gridInstance = this.gridContainer.instance;
  //   const dataSource = gridInstance.getDataSource();
  
  //   // Update data source directly
  //   dataSource.store().update(key, updatedData)
  //     .done(() => {
  //       console.log('Data updated successfully.');
  //       gridInstance.refresh(); // Ensure the grid UI reflects changes
  //     })
  //     .fail((error) => {
  //       console.error('Error updating data:', error);
  //     });
  // }
  onRowUpdating(e)
  {
    // console.log('onRowUpdating',e)

    // const updatedData = e.newData;
    // const key = e.key;

    // this.dataSource = this.dataSource.map(row => {
    //   if (row.Sno === key.Sno && row.Description === key.Description) {
    //     // Apply the updates to the relevant row
    //     return { ...row, ...updatedData };
    //   }
    //   return row;
    // });


    // this.refreshGrid();

    // this.gridContainer.instance.getDataSource().reload();

    // if (this.gridContainer) {
    //   this.gridContainer.instance.refresh(); // Refreshes the grid to ensure the UI reflects changes
    // }


    // this.recalculateAmounts();
  }
  onEditorPreparing(e)
  {
   
  }
  OnCellPrepared(e)
  {
    console.log('OnCellPrepared',e)
  }
  onRowPrepared(e) {

     if (e.rowType == 'data' && (e.data.Description == "Total Amount" || e.data.Description == "Net Amount")) {
      e.rowElement.style.backgroundColor = '#eae9e7';
      e.rowElement.style.fontWeight = 500;
      e.rowElement.style.color='#0f0902'
    
    }
  }

   recalculateAmounts() {
    
   let totalAmount:number=0;
   let lessAmount :number=0;
    // console.log('recalculateAmounts',this.dataSource[0].Amount)
    
    //let temp=this.dataSource.
    // console.log('temp',temp)

    // Calculate the total amount
    this.dataSource.forEach(row1 => {
      if (!['Total Amount', 'Less Amount', 'Net Amount', 'Salary/Advance OutStanding'].includes(row1.Description)) {
        console.log('row.Amount',row1)
        totalAmount += row1.Amount; // Ensure you're using the correct field (e.g., `Amount`)
        console.log('totalAmount',totalAmount)
      }
    });

    // Update the rows for Total Amount, Less Amount, and Net Amount
    this.dataSource = this.dataSource.map(row => {
      if (row.Description === 'Total Amount') {
        row.Amount = JSON.parse((totalAmount).toFixed(2));
      } else if (row.Description === 'Less Amount') {
        lessAmount = JSON.parse((row.Amount).toFixed(2)); // Capture the existing less amount if it's already set
      } else if (row.Description === 'Net Amount') {
        row.Amount =JSON.parse((totalAmount -lessAmount).toFixed(2));

        this.AccountSource.map(item=> item.TCDebit=row.Amount);
  
      }
      return row;
    });

   
  }

  onCellValueChanged(event) {
    console.log('Cell value changed:', event);
    // Implement your logic here
  }

  onRowUpdated(e)
  {
    console.log('onRowUpdated:', e);
    console.log('onRowUpdated33',this.dataSource[0].Amount)
    this.recalculateAmounts();
  }

  changeCategory()
  {
    this.Employeelist = this.Category=='0'?this.data.datalist.Employee: this.data.datalist.Employee.filter(item => item.Category == this.Category);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  togglePanel(event: MouseEvent) {
    event.stopPropagation(); // Prevent expansion panel from toggling
    this.panelExpanded = !this.panelExpanded;
  }
  onTabClick(e)
  {

  }

}
export interface Details
{
  Sno:number,
  Description:string,
  CalcAmount:number,
  Amount:number

}

