import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
// import { FormGroup } from '@angular/forms';
 import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
 import { DatePipe} from '@angular/common';
import * as _ from 'underscore/underscore-min.js';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LeavetypemasterviewComponent } from './../leavetypemasterview/leavetypemasterview.component';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LeavetypeconfigurationComponent } from 'src/app/components/HR/leavetypeconfiguration/leavetypeconfiguration.component';



@Component({
  selector: 'app-leavetypemaster',
  templateUrl: './leavetypemaster.component.html',
  styleUrls: ['./leavetypemaster.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class LeavetypemasterComponent implements OnInit {
  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<AccrualType>>;
  dataSource: MatTableDataSource<LeaveDetails>;
  LeaveDatas: LeaveDetails[] = [];
  columnsToDisplay = ['LeaveTypeID', 'LeaveName', 'LeaveType','LeaveCode','LeaveDescription',
  'CarryForwardDays','Encashment','LeaveDayType','SetWeekEnd','SetHolidays'];
  innerDisplayedColumns = ['AccrualType', 'NoofDays'];
  expandedElement: LeaveDetails | null;
  


  LeaveTypeMaster:FormGroup;
  //dataSource:any;
  LeaveDetails:any;
  Companies:any;
  LeaveID:any;
  Status:any;
  PaidLeave:any;
  checkBoxesMode: string;
  isTzidVisible: boolean ;
  isStatusVisible: boolean ;
  isALPIDVisible: boolean ;
  CurLedgerID:number;
  onSelectionChange:boolean;
  cid:any;
   rowcount:any;
   addbtnvisible: boolean = false;
   editbtnvisible: boolean = false;
   deletebtnvisible: boolean = false;
   viewbtnvisible: boolean = false;

  constructor(
    private service:HttpserviceService,
    private NotificationService:NotificationService,
    private formBuilder: FormBuilder,
    private service1: UserService, 
    private generic: GenericService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    
    
    private cd: ChangeDetectorRef
    )  
     {
         this.LeaveTypeMaster = this.formBuilder.group
         ({  
            fcCompany: ['', Validators.required] ,             
            //fcLeaveID: ['', Validators.required] ,
            // fcLeaveType:['',Validators.required],
            // fcStatus:['',Validators.required],
            // fcPaidLeave:['',Validators.required]
        }); 
        
        //this.checkBoxesMode = 'always'
     }

 async ngOnInit()
  {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.LeaveDatas=[];
    this.LeaveDatas.length = 0;
    await this.getddllist();  
    this.isTzidVisible=false;
    this.isStatusVisible=false;
    this.isALPIDVisible=false;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.LeaveTypeMaster.controls.fcCompany.setValue(this.cid);

    //console.log('Leavedata2',LeaveData)
    LeaveData.forEach(user => {
      if (user.AccrualTypes && Array.isArray(user.AccrualTypes) && user.AccrualTypes.length) {
         this.LeaveDatas = [...this.LeaveDatas, {...user, AccrualTypes: new MatTableDataSource(user.AccrualTypes)}];
      } else {        
        this.LeaveDatas = [...this.LeaveDatas, user];
      }
    });
    
    this.dataSource = new MatTableDataSource(this.LeaveDatas);
    this.dataSource.sort = this.sort;
    console.log('this.dataSource',this.LeaveDatas)
    //this.dataSource.sort = this.sort;

  }

  doFilter(filterValue: string) {
    // console.log(filterValue);
    this.dataSource.filter = filterValue;
  }

  changesite(e) {
    console.log('change',e.value);
    this.cid=e.value;
    this.getddllist();
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.LeaveTypeMaster.reset();             
    //this.dataSource='';  
    this.LeaveDetails='';
    this.LeaveTypeMaster.controls.fcCompany.setValue(this.cid);
  } 

  LoadLeaveLetails(LeaveData1:any,accuredData:any)
  {
      //  console.log('leavedata',LeaveData1,LeaveData1.length)
      //  console.log('accuredData',accuredData,accuredData.length)
      //LeaveData

       //this.board=new Board('Test Board', []);

       let leaveTypeData:LeaveDetails[]=[];
       //console.log('leaveTypeData2',leaveTypeData) 
       leaveTypeData=LeaveData1;   
       console.log('LeaveData1',LeaveData,LeaveData.length) 
       
       for (let i = 0; i < LeaveData.length;i++) {  
       
        LeaveData.splice(i,LeaveData.length)
        //console.log('ttt',LeaveData) 
        //i--

       }

       //var innerArrayLength = leaveData.length;          
       let AccrualTypes:AccrualType[] = []; 
       //let LeaveTypeDetails:LeaveDetails[] = []; 
       //let getdata:LeaveDetails[]=[];       
       //LeaveTypeDetails=[];
       
       for (let i = 0; i < leaveTypeData.length; i++) {                
         // loop the inner array               
         let k = 0;   
         AccrualTypes=[];
         
         for (let j = 0; j < accuredData.length; j++) {
             if(accuredData[j].LeaveTypeID===leaveTypeData[i].LeaveTypeID)
             {
               let taskData={'AccrualType':accuredData[j].AccrualType,'NoofDays':accuredData[j].NoofDays,'NoOfMonthsFromJoingDate':accuredData[j].NoOfMonthFromJoiningDate}                      
               AccrualTypes.push(taskData);    
               k++;                
             }          
         }
         //console.log('subj',subj)      
        // let getdata = {LeaveTypeID:0,
        //             'LeaveName':'',
        //             'LeaveType':'',
        //             'LeaveCode':'',
        //             'LeaveDescription':'',
        //             'CarryForwardDays':'',
        //             'Encashment':'',
        //             'LeaveDayType':'',
        //             'SetWeekEnd':'', 
        //             'SetHolidays':'',
        //             AccrualTypes}

         let getdata={'LeaveTypeID':leaveTypeData[i].LeaveTypeID,
                    'LeaveName':leaveTypeData[i].LeaveName,
                    'LeaveType':leaveTypeData[i].LeaveType,
                    'LeaveCode':leaveTypeData[i].LeaveCode,
                    'LeaveDescription':leaveTypeData[i].LeaveDescription,
                    'CarryForwardDays':leaveTypeData[i].CarryForwardDays,
                    'Encashment':leaveTypeData[i].Encashment,
                    'LeaveDayType':leaveTypeData[i].LeaveDayType,
                    'SetWeekEnd':leaveTypeData[i].SetWeekEnd,    
                    'SetHolidays':leaveTypeData[i].SetHolidays, 
          AccrualTypes}

          //console.log('getdata',getdata)
         // LeaveData.push(new this.LeaveDetails())         
        
         LeaveData.push(getdata);
                  
         //this.board.columns.push(this.datastatus);
        // console.log('this.board',this.board.columns[0].tasks.length)
       }
       console.log('LeaveData2',LeaveData)     
   }

  showDetails(e)
  {
    console.log('TEST',e);
  }

  Refresh()
  {
    this.ngOnInit();
  }
 async getddllist()
  {
    let params = {
      CID: this.cid,
      LeaveName:0,
      Flag: "DDL_COMBO",         
    };
    let data=await this.service.httpPost('/leavetypemaster/GetLeaveTypeMaster',params)
    .toPromise ().then(data=> { 
          let result: any;
          result = data;         
          console.log('data ', data);
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.LeaveID = result.respdata.LeaveType;
            // this.Leave =result.respdata.LeaveType;
           // this.Status = result.respdata.Status;  
           let LeaveTypeData=result.respdata.LeaveData
           let LeaveTypeAccrualData=result.respdata.LeaveAccuredData
           console.log('LeaveTypeData',LeaveTypeData)
           this.LoadLeaveLetails(LeaveTypeData,LeaveTypeAccrualData)
           //this.LeaveData=JSON.parse(result.respdata.CategoryType);     
           // this.LeaveDetails=result.respdata.GridData;        
            //this.rowcount=result.respdata.Count;     
            //console.log('leavedata',this.LeaveData);
            this.getUserList();                           
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_5",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;
      this.editbtnvisible = btnVisilityData.editflag;
      this.deletebtnvisible = btnVisilityData.deleteflag;
      this.viewbtnvisible = btnVisilityData.viewflag;   
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  Submit() { 

    this.logEvent;
    let params = {     
      CID: this.LeaveTypeMaster.get('fcCompany').value,
      Flag: "ADD",           
     // LeaveID:this.LeaveTypeMaster.get('fcLeaveID').value,
      LeaveType:this.LeaveTypeMaster.get('fcLeaveType').value,
      Status:this.LeaveTypeMaster.get('fcStatus').value,
      PaidLeave:this.LeaveTypeMaster.get('fcPaidLeave').value==""?0:1,
      UserID:this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid'),
      dt_Holidays: this.dataSource,
      dt_LeaveDetails: this.LeaveDetails        
    };
    console.log('params',params)
    this.service.httpPost('/leavetypemaster/SetLeaveTypeMaster',params)
    .subscribe(
      (data) => {
        let result: any;
        console.log('data ', data);
        result = data;       
        console.log('result ', result);
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }
  Edit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      CID:this.LeaveTypeMaster.get('fcCompany').value,
      Flag:'EDIT'
    }
    const dialogRef = this.dialog.open(LeavetypeconfigurationComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      this.ngOnInit();
    });
  }

  Add(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      CID:this.LeaveTypeMaster.get('fcCompany').value,
      Flag:'Add'
    }
    const dialogRef = this.dialog.open(LeavetypeconfigurationComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
       this.ngOnInit();     
    });
  }

  View(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      CID:this.LeaveTypeMaster.get('fcCompany').value,
      Flag:'View'
    }
    const dialogRef = this.dialog.open(LeavetypeconfigurationComponent, dialogConfig)
    dialogRef.afterClosed()
  }
  
  Delete()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      CID:this.LeaveTypeMaster.get('fcCompany').value,
      Flag:'DELETE'
    }
    const dialogRef = this.dialog.open(LeavetypeconfigurationComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      this.ngOnInit();
    });
  }

  onClear()
  {
    this.LeaveTypeMaster.reset();          
    //this.getddllist(); 
    //this.dataSource='';  
    this.LeaveDetails='';
    this.LeaveTypeMaster.controls.fcCompany.setValue(this.cid);
  }

  

  logEvent(eventName, e)
  {
    console.log("kk",e)
  }

  onCellPrepared(e) {
    if (e.rowType !== 'data' || e.columnIndex > 0 ) {
      return;
      
    }
    if (e.rowIndex % this.rowcount.length === 0 && ( e.columnIndex == 0  )) {
      e.cellElement.rowSpan = this.rowcount.length;
      e.cellElement.innerHTML = '<div>' + e.value + '</div>';
      console.log("b",e)
    }
    else {
      e.cellElement.style.display = 'none';
      console.log("c",e)
    }
  //   if(e.rowType === 'header') {
  //     e.cellElement.css("backgroundColor", "blue");
  //  }
  }

  onSelectionChanged(e) 
  { // Handler of the "selectionChanged" event
    // if(this.onSelectionChange===true)
    // {
      //console.log('hdgfd',"hi");
      const currentSelectedRowKeys = e.currentSelectedRowKeys;
      const currentDeselectedRowKeys =e.currentDeselectedRowKeys;

      if(currentSelectedRowKeys.length>0){
        
        for (let key of Object.keys(this.dataSource)) {
          let keydata = this.dataSource[key];     
          let curSelecvalue=currentSelectedRowKeys;
          console.log('keydata',JSON.stringify(keydata['CategoryType']));    
          console.log('hdgfduiouu',"hi",curSelecvalue[0]);      
        if (JSON.stringify(curSelecvalue[0])===JSON.stringify(keydata['CategoryType']))
         {
            keydata['IncludeWith'] = 1;
               console.log('hdgfd',this.dataSource);
          }    
        }
      }

      else if(currentDeselectedRowKeys.length>0){
        for (let key of Object.keys(this.dataSource)) {
          let keydata = this.dataSource[key];          
          let curdeSelecvalue=currentDeselectedRowKeys;   

          if (JSON.stringify(curdeSelecvalue[0])===JSON.stringify(keydata['CategoryType'])){
            keydata['IncludeWith'] =0;
              
          }   
          // else{
          //   keydata['IncludeWith'] = keydata['IncludeWith'];
          //   keydata['ChkStatus'] =0;     
          // } 
        }
      }
      
    // }
    // else
    // {
    //   this.onSelectionChange=true;
    // }
    
  }


  toggleRow(element: LeaveDetails) {
    element.AccrualTypes && (element.AccrualTypes as MatTableDataSource<AccrualType>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<AccrualType>).sort = this.innerSort.toArray()[index]);
  }

  applyFilter(filterValue: string) {
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<AccrualType>).filter = filterValue.trim().toLowerCase());
  }
  
}


export interface LeaveDetails {
  LeaveTypeID: number;
  LeaveName: string;
  LeaveType: string;
  LeaveCode: string;
  LeaveDescription: string;
  CarryForwardDays: string;
  Encashment: string;
  LeaveDayType: string;
  SetWeekEnd: string;
  SetHolidays: string;
  AccrualTypes?: AccrualType[] | MatTableDataSource<AccrualType>;

 
}


// const ELEMENT_DATA: LeaveDetails[] = [
 
// ];

export interface AccrualType {
  AccrualType: string;
  NoofDays: Number;  
  NoOfMonthsFromJoingDate:Number
}

const LeaveData: LeaveDetails[] =[
  // {
  //   LeaveTypeID: 101,
  //   LeaveName: "Test101",
  //   LeaveType: "Test101",
  //   LeaveCode:  "Test101",
  //   LeaveDescription:  "Test101",
  //   CarryForwardDays:  "Test101",
  //   Encashment:  "Test101",
  //   LeaveDayType:  "Test101",
  //   SetWeekEnd:  "Test101",
  //   SetHolidays:  "Test101",
  //   AccrualTypes: [
  //     {
  //       AccrualType:'asdasdasd',
  //       NoofDays:15
  //     }
  //   ]
  // }
];



export class Column {
  constructor(public LeaveTypeID: Number, 
    public LeaveName: string,
    public LeaveType: string,
    public LeaveCode: string,
    public LeaveDescription: string,
    public CarryForwardDays: string,
    public Encashment: string,
    public LeaveDayType: string,
    public SetWeekEnd: string,    
    public SetHolidays: string, 
    public AccrualType: AccrualType[]) {}
}

export class AccrualType {
  constructor(public AccrualType: string, public NoofDays: Number,public NoOfMonthsFromJoingDate:Number) {}
}
