import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Inject } from '@angular/core';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable,of } from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'app-goalaccess',
  templateUrl: './goalaccess.component.html',
  styleUrls: ['./goalaccess.component.css']
})
export class GoalaccessComponent implements OnInit {

  emplist:any;
  selectedOptions: string[];
  lastSelectedOptions: string[];
  GoalID:any
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fcEmployee = new FormControl();
  filteredEmployees: Observable<string[]>;
  employees: string[] = [];
 
  allEmployees: string[] = [];
  selectable = true;
  removable = true;
  CID:any;

  private allowFreeTextAddEngineer = false;

  @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  
  
  constructor(private datePipe: DatePipe,public service: HttpserviceService,public notificationService: NotificationService, public dialogRef: MatDialogRef<GoalaccessComponent>,
  private dialogService: DialogService,@Inject(MAT_DIALOG_DATA) public data: any) 
  { 
  this.GoalID=data.GoalID;
  this.CID=data.cid;

  // this.filteredEmployees = this.fcEmployee.valueChanges.pipe(
  //   startWith(null),
  //   map((value: string | null) => value ? this._filteremployees(value) : this.allEmployees.slice()));
  
    this.fcEmployee.valueChanges.subscribe(search => {      
      this.filteredEmployees = of(this.allEmployees.filter(item =>
      item.toLowerCase().includes(search)
      ));
      });
      // this.fcEmployee.valueChanges.pipe(
      //   startWith(null),
      //   map(search => this.filterOnValueChange(search))
      // );
  }

  // private filterOnValueChange(engineerName: string | null): String[] {
  //   let result: String[] = [];
  //   //
  //   // Remove the engineers we have already selected from all engineers to
  //   // get a starting point for the autocomplete list.
  //   //
  //   let allEngineersLessSelected = this.allEmployees.filter(
  //     (engineer) => this.employees.indexOf(engineer) < 0
  //   );
  //   if (engineerName) {
  //     result = this.filterEngineer(allEngineersLessSelected, engineerName);
  //   } else {
  //     result = allEngineersLessSelected.map((engineer) => engineer.);
  //   }
  //   return result;
  // }

  
  // private filterEngineer(
  //   engineerList: employees[],
  //   engineerName: String
  // ): String[] {
  //   let filteredEngineerList: employees[] = [];
  //   const filterValue = engineerName.toLowerCase();
  //   let engineersMatchingEngineerName = engineerList.filter(
  //     (engineer) => engineer.fullName.toLowerCase().indexOf(filterValue) === 0
  //   );
  //   if (engineersMatchingEngineerName.length || this.allowFreeTextAddEngineer) {
  //     //
  //     // either the engineer name matched some autocomplete options
  //     // or the name didn't match but we're allowing
  //     // non-autocomplete engineer names to be entered
  //     //
  //     filteredEngineerList = engineersMatchingEngineerName;
  //   } else {
  //     //
  //     // the engineer name didn't match the autocomplete list
  //     // and we're only allowing engineers to be selected from the list
  //     // so we show the whjole list
  //     //
  //     filteredEngineerList = engineerList;
  //   }
  //   //
  //   // Convert filtered list of engineer objects to list of engineer
  //   // name strings and return it
  //   //
  //   return filteredEngineerList.map((engineer) => engineer.fullName);
  // }



  addemployees(event1: MatChipInputEvent): void {
    const input = event1.input;
    const value = event1.value;
    if ((value || '').trim()) {
      this.employees.push(value.trim());
      
    }
    if (input) {
      input.value = '';
    }
    this.fcEmployee.setValue(null);
  }

  removeemployees(emp: string): void {
    const index = this.employees.indexOf(emp);
    if (index >= 0) {
      this.employees.splice(index, 1);
    }
  }

   selectedemployees(event1: MatAutocompleteSelectedEvent): void {
    this.employees.push(event1.option.value);
    this.empInput.nativeElement.value = '';
    this.fcEmployee.setValue(null);
  }

  private _filteremployees(value1: string): string[] {
    const filterprojectValue = value1.toLowerCase();

    return this.allEmployees.filter(value1 => value1.toLowerCase().indexOf(filterprojectValue) === 0);
  }


  ngOnInit(): void {
    this.dialogRef.updateSize('70%');
    this.getGoalsdetails();
  }
  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    .afterClosed().subscribe(res => {
      if (res) {
    this.dialogRef.close({event: 'CLEAR'});
      }
    });

  }
  onClose(model){
    this.dialogRef.close({event: 'CLEAR', data: model});
  }
  
  onSubmit()
  {
    let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      cid: this.CID,
      flag:"ADD",
      GoalID:this.GoalID,
      emplist:this.employees
  
    };
    this.service.httpPost('/Goals/SetGoalAccessGroup?',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;

      if (users.errno === 0){
        this.notificationService.success('Submitted successfully');
  
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }
getGoalsdetails()
  {
       let params = {
       cid:this.CID,
       flag:'Emplist',
       GoalID:this.GoalID,
       LedgerID:0,
       FromDate:this.datePipe.transform(new Date(), 'yyy-MM-dd'),
       AssessmentType:"''"
        }
        const query = `cid=${params.cid}&flag=${params.flag}&GoalID=${params.GoalID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}&AssessmentType=${params.AssessmentType}`;
           this.service.httpGet('/Goals/GetGoals?',query)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

              this.emplist= result.respdata.Employeelist;
             this.employees= _.pluck(result.respdata.selectedEmployee, 'Name'); 
              //console.log('this.employees',this.employees)
              this.allEmployees= _.pluck(this.emplist, 'Name');    
              //console.log('this.allEmployees',this.allEmployees)
 
             }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }


    add(event: any): void {
      if (event) {
        let newChip: any;
        if (event.option) {
          newChip = event.option.value;
        }
        if (event.value) {
          this.allEmployees.forEach((c, i) => {
            if (c.toLowerCase() === event.value.toLowerCase()) {
              newChip = c;
            }
            if (event.input) {
              event.input.value = '';
            }
          });
        }
        if (newChip) {
          this.employees.push(newChip);
          this.allEmployees.forEach((c, i) => {
            if (c === newChip) {
              this.allEmployees.splice(i, 1);
            }
          });
        }
      }
    }
  
    remove(chip: any): void {
      this.employees.forEach((c, i) => {
        if (c === chip) {
          this.employees.splice(i, 1);
        }
      });
      this.allEmployees.push(chip);
    }


    
}
