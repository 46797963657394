import { TicketlistComponent } from './../../components/ITS/Ticket/ticketlist/ticketlist.component';
import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  Renderer2
} from '@angular/core';
//import { MenuItems } from '../../LandingPageComponents/menu-items';
// import { AppHeaderComponent } from './header/header.component';
// import { AppSidebarComponent } from './sidebar/sidebar.component';
import { environment } from '../../../environments/environment';
import { TabsComponent } from '../../LandingPageComponents/tabs/tabs.component';
//import { EmpAttReportViewComponent } from '../../../app/components/EmpAttReportview/emp-att-report-view/emp-att-report-view.component';
import { MenuidmappingService } from '../menuidmapping.service'
import themes from 'devextreme/ui/themes';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { EmiteventserviceService } from 'src/app/Services/OtherServices/emiteventservice.service';
// import { TicketlistComponent } from'src/app/components/ITS/Ticket/ticketlist/ticketlist.component'
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.css'],

})
export class FullComponent implements OnDestroy, AfterViewInit {
  backgroundColor = environment.navBarBackgroundColor;
  mobileQuery: MediaQueryList;
  selectedTheme = '';
  @ViewChild(TabsComponent) tabsComponent;
  @ViewChild(DashboardComponent) DashboardComponent;

  private _mobileQueryListener: () => void;


  color = "primary";
  protected _themes = ["theme-1", "theme-2", "theme-5", "theme-6", "theme-7", "theme-8"];
  protected _currentThemeIndex = 0;
  isDark = false;
  tabvisible: boolean = true;

  public icon = 'menu';



  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    //public menuItems: MenuItems,
    public menuIDMap: MenuidmappingService,
    private renderer: Renderer2,
    private eventService: EmiteventserviceService



  ) {
    this.lightmode();
    this.theme();
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.eventService.menuSelect$.subscribe(data => {
      //this.receivedData = data;
     // console.log("emitservicecall",data)
      this.menuSelect(data);
    
    });

  }

  public changeIcon() {
    if (this.icon == 'menu')
      this.icon = 'menu_open';
    else if (this.icon == 'menu_open')
      this.icon = 'menu';
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.tabvisible = true;
  }
  ngAfterViewInit() { this.tabvisible = true; }

  addTab() {
    //this.tabsComponent.openTab2('Person', EmpAttReportViewComponent, {}, true);
  }

  menuSelect(item: any) {

    this.tabvisible = false;
    if (item.menuid != '') {
    //  console.log("testamjath",item) 

      let menuid_
      if(item.menuid.length>7)
      {
        menuid_ = item.menuid.replace(/_[0-9]+$/, '');
       
      }
      else
      {
        menuid_=item.menuid
      }

     // console.log("menuid_454",menuid_) 
      this.tabsComponent.openTab2(item.name,item.menuid, this.menuIDMap.getComponent(menuid_), {}, true);
    }


  }

  lightmode() {
    var isDarkTemp = sessionStorage.getItem('isDark');//localStorage.getItem('isDark');
    //console.log('DARKTEM',isDarkTemp)
    if (isDarkTemp == "0" && isDarkTemp != null) {

      this.isDark = false;
      this.renderer.removeClass(document.body, this.isDark ? "dark" : "light");
      this.renderer.addClass(document.body, this.isDark ? "dark" : "light");
      themes.current("generic.light");
      //localStorage.setItem('isDark',"1");
      sessionStorage.setItem('isDark', "0");
    }
    else if (isDarkTemp != null) {
      //console.log('ISDARK')
      this.isDark = true;
      this.renderer.removeClass(document.body, this.isDark ? "dark" : "light");
      this.renderer.addClass(document.body, this.isDark ? "dark" : "light");
      themes.current("generic.dark");
      sessionStorage.setItem('isDark', "1");

    }

  }

  theme() {
    var isthemeTemp = sessionStorage.getItem('theme');//localStorage.getItem('theme');
    this.renderer.removeClass(document.body, this._themes[isthemeTemp == "0" ? 1 : isthemeTemp]);
    this.renderer.addClass(document.body, this._themes[isthemeTemp]);
    //localStorage.setItem('theme',event.name);
    sessionStorage.setItem('theme', isthemeTemp);
    //localStorage.setItem('theme',event.name);      

    // if( isthemeTemp==="0")
    // {
    //   this.renderer.removeClass(document.body, this._themes[0]);
    //   this.renderer.addClass(document.body, this._themes[1]);       
    //   localStorage.setItem('theme',"1");      
    // }
    // else
    // {      
    //   this.renderer.removeClass(document.body, this._themes[1]);
    //   this.renderer.addClass(document.body, this._themes[0]);       
    //   localStorage.setItem('theme',"0");      
    // }
  }


}
