import { User } from './../../../../Modules/user';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-updatefingerprint',
  templateUrl: './updatefingerprint.component.html',
  styleUrls: ['./updatefingerprint.component.scss'],
})
export class UpdatefingerprintComponent implements OnInit {
  CID:any;

  dataSource:any;
 
  CurLedgerID:any;
  date= new Date();
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private datePipe: DatePipe,
    public dialogRef: MatDialogRef<UpdatefingerprintComponent>,@Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    this.CID=this.data.CID;
    
  }

  ngOnInit() 
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;

   
    this.getddllist();    
  }
  
  getddllist()
  {
    const params = {
      cid: this.data.CID,
      flag: 'LOADEMPLIST',
      ledgerid: this.CurLedgerID,
      date:  this.datePipe.transform(this.date, 'dd-MM-yyyy')

        };
        const groupid = JSON.parse(sessionStorage.getItem('groupid'));

        const query = `cid=${params.cid}&flag=${params.flag}&ledgerid=${params.ledgerid}&date=${params.date}&groupid=${groupid}`;
        this.service.httpGet('/timepunchreport/GetTimePunch?',query)
        .subscribe(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.dataSource = result.respdata.Table;                               
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
 
  onCloseDialog()
  {
    this.dialogRef.close();
  }
  logEvent(_flag, e)
  {
    console.log("logEvent",e.data.FingerPrintID)
 
    let  paramsExtra;
   
  if (_flag == 'RowUpdated')
   {
    paramsExtra = {
      CID: this.CID,
      Flag: 'UPDATEFINGERPRINT', 
      Year:0,    
      HalfDay: e.data.LedgerID,
      WorkingHrs: e.data.FingerPrintID,
      UserID: this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')
    };
   }
  else if (_flag == 'RowRemoved')
  {
    paramsExtra = {
      CID: this.CID,
      Flag: 'DELETE',     
      HalfDay: e.data.LedgerID,
      WorkingHrs: e.data.Carryforward,
      UserID: this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')

    };
  }
  this.service.httpPost('/HRPolicies/SetCurrentYrCarryForward',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
    
      if (users.errno === 0){
        this.NotificationService.success(users.errdesc);
        this.getddllist(); 
      }
      else{
        this.NotificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }
}
