import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { map, startWith, pluck } from 'rxjs/operators';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import * as _ from 'underscore';
@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss'],
})
export class SampleComponent implements OnInit {
  temp1 :string="";
  temp3 :any
  TicketJson:any[];
  ConditionJson:any[];
  EmailJson:any[];

  Ticket=[
  {
    "Client":"A.P.C.C PILING & CONTRACTING L.L.C",
    "Priority":"P3",
    "Product":"STS",
    "Project":"BH-EM-CD-010"
  }]
              
   EmailConfig= [
                  {
                    "Client":"A.P.C.C PILING & CONTRACTING L.L.C",
                    "Priority":"P3",
                    "Product":"STS",                                  
                  //  ,"Project":"BH-EM-CD-010"
                  },
                  {
                    "Client":"ANT",
                    "Priority":"P3",
                    "Project":"BH-EM-CD-010",                                  
                  //  ,"Project":"BH-EM-CD-010"
                  }]
               


  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private http: HttpClient) 
  { 

  }

  ngOnInit() {
      //console.log('Ticketjson',this.Ticket);
     // console.log('Email',this.EmailConfig);
      this.GetEmail();
  }

  GetEmail(){
    let params = {     
         CID:101,
         Flag:"EMAIL",         
      };
   // console.log('Params',params)
    this.service.httpPost('/TicketCreator/GetTicketEmail',params)
    .subscribe (
      (data) => {
        let result: any;
        result = data;
        console.log('resultfhfg', result);

        if (result.errno === 0) {                           
          this.ConditionJson=result.respdata.ConditionJson;  
          this.EmailJson=result.respdata.EmailJson;  
          this.TicketJson=result.respdata.Ticket;  
          console.log('ConditionJson ', this.ConditionJson);       
          console.log('EmailJson ', this.EmailJson);    
          console.log('TicketJson ', this.TicketJson);       
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });
  }

  Submit() {   
    
    let temp5 =[]
    
    for (const key of Object.keys(this.EmailConfig)) {
      console.log('key: ', key);
      const keydata = this.EmailConfig[key];
       console.log('keydata: ', keydata);
       for (const key1 of Object.keys(keydata)) {
        console.log('key: ', key1);
        const keydata1 = keydata[key1];
        let temp
        temp= _.where(this.Ticket, {[key1]:keydata1})
      //temp=_.where( this.Ticket, {key:this.temp1});
      // console.log('temp: ', temp);
       if (temp.length>0)
       {
        console.log('temp: ', temp);
        let temp6 ={'tag':key1,'Value':keydata1,'Condtion':'true'}
        temp5.push(temp6);
       }
       }      
    }
    console.log('temp6: ', temp5);
  }
}
