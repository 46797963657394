
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { timer, Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  populationData: any;
  PriorityData: any;
  StatusData: any;
  leavedetails: any;
  EffortData: any;
  LeaveData: any;
  Leaveisvisible: boolean = false;
  Effortisvisible: boolean = false;
  GGBCvisible:boolean=false;
  Commonvisible:boolean=false;
  //everyFiveSeconds: Observable<number> = timer(0, 20000);
  subscription: any;
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  constructor(public service: HttpserviceService) {

  }

  ngOnInit() {

    let CID = JSON.parse(sessionStorage.getItem('cid'));
    //this.subscription = this.everyFiveSeconds.subscribe(() => {
     //this.RefreshDashboard();
     
   // });

    this.GetDashboard();

  }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  // RefreshDashboard() {
  //   let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  //   const query = `cid=${JSON.parse(sessionStorage.getItem('cid'))}&UserID=${curUser.userid}`;
  //   this.service.httpGet('/usermgt/GetDashboard?', query)
  //     .subscribe(data => {
  //       let result: any;
  //       result = data;
  //       if (result.errno === 0) {
  //         this.PriorityData = result.respdata.Table;
  //         this.LeaveData = result.respdata.Table2;
  //         this.StatusData = result.respdata.Table1;
  //         this.EffortData = result.respdata.Table3;
  //         this.leavedetails = result.respdata.Table4;
        
  //         if(result.respdata.Table5[0].Tag =='1' )         
  //           this.Commonvisible=true;
  //         if(result.respdata.Table6[0].Tag =='1' )     
  //            this.GGBCvisible=true;

  //         if (this.curUser.UserType === 'External') {
  //           this.Leaveisvisible = false;
  //           this.Effortisvisible = false;
  //         }
  //         else {
  //           this.Leaveisvisible = true;
  //           this.Effortisvisible = true;
  //         }
  //       }

  //     },
  //       (error) => {
  //         //  if (error instanceof BadInput)           
  //         console.log(error);
  //         //  else throw error;       
  //       });
  // }

  GetDashboard() {

    this.PriorityData = this.curUser.ds_dashboard.Table;
    this.LeaveData = this.curUser.ds_dashboard.Table2;
    this.StatusData = this.curUser.ds_dashboard.Table1;
    this.EffortData = this.curUser.ds_dashboard.Table3;
    this.leavedetails =this.curUser.ds_dashboard.Table4;
    if(this.curUser.ds_dashboard.Table5[0].Tag =='1' )
      {
        this.Commonvisible=true;
      } 
      else  
      {
        this.GGBCvisible=true;
      }  

    if (this.curUser.UserType === 'External') {
      this.Leaveisvisible = false;
      this.Effortisvisible = false;
    }
    else {
      this.Leaveisvisible = true;
      this.Effortisvisible = true;
    }

  }


}
