import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import * as _ from 'underscore/underscore-min.js';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe  } from '@angular/common';
import { TimesheetviewComponent } from './../timesheetview/timesheetview.component';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { User } from '../../../../Modules/user';

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
  ,
  providers: [
    ]
})
export class TimesheetComponent implements OnInit {
  TaskCount:any;
  flag: string;
  dataSource: any;
  dataSource1: any;
  Categorylist:any;
  Productlist:any;
  Projectlist:any;
  Clientlist:any;
  Companies:any;
  MyTask:any;
  CommonTask:any;  
  Visiblelist:any;
  TimeSheetFormGroup: FormGroup;
  CurLedgerID:string;
  selectedEmpID: any;
  Employees: any;
  regex = '([01]?[0-9]|2[0-3]):[0-5][0-9]';
  cid:any;
  Table9:any;
  Table10:any;
  Table11:any;
  Table12:any;
  Table13:any;
  Table14:any;
  Table15:any;
  PreviousEffort:any;
  Rolelist:any;
  HomePage:any;
  ticketdetails:any;
  myControl = new FormControl('', [Validators.required]);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  employeeid:string[]=[];
  
  date : any;

  isTicketVisible: boolean ;
  isProductVisible: boolean;
  isProjectVisible: boolean;
  isClientVisible: boolean;
  addbtnvisible: boolean = false;
  editbtnvisible: boolean = false;
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private formBuilder: FormBuilder,private generic: GenericService, private service1: UserService,
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService) 
  {
    this.setStateValue = this.setStateValue.bind(this);
      this.TimeSheetFormGroup = this.formBuilder.group({  
      fcCompany: ['', Validators.required] ,             
      fcDate: ['', Validators.required] ,
      myControl: ['', Validators.required],      
    });       
  }

  ngOnInit(): void {    
    // let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    // this.CurLedgerID=curUser.username;
    // console.log('CurLedgerID', this.CurLedgerID);
    
    this.cid=JSON.parse(sessionStorage.getItem('cid'));   
    this.getddllist();
    this.TimeSheetFormGroup.controls.fcCompany.setValue(this.cid);

   // this.hideEmails();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );   
  }

  onChangeEvent(){     
    this.dataSource = '';
    this.getddllist();
  }

  async setStateValue(rowData: any, value: any) {  
    rowData.Ticket = value;
     
    
    await this.GetStrucure(value,"Get")
    
    if(parseInt(this.ticketdetails[0].Field4) >0)
    {
      rowData.Product = parseInt(this.ticketdetails[0].Field4) ;
    }
    if(this.ticketdetails[0].ProjectUID >0)
    {
      rowData.Project = this.ticketdetails[0].ProjectUID;
    }
    if(this.ticketdetails[0].MerchantLedgerID >0)
    {
      rowData.Client = this.ticketdetails[0].MerchantLedgerID;
    }
     
}  
async GetStrucure(ledgerid:any,flag1:any)
  {
    let params = {
      CID: this.cid,
      Flag: flag1,     
      Date : this.datePipe.transform(this.TimeSheetFormGroup.get('fcDate').value,"dd-MM-yyyy"),    
      Ticket:ledgerid,
    };
    
    const dt=await this.service.httpPost('/Timesheet/Getdropdownvalues' ,params)   
    .toPromise().then(data => {
    // this.service.httpPost('/Timesheet/Getdropdownvalues',params)
    //   .subscribe (
    //     (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);
          if (result.errno === 0) {    
            this.TaskCount   =  result.respdata.Ticketdetails.length;
            
           if(this.TaskCount  > 0)                
             this.ticketdetails= result.respdata.Ticketdetails;   
            else                  
             this.NotificationService.success('Not possible to post effort for this ticket');
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getddllist()
  {    
    console.log('selectempid',this.selectedEmpID)
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",     
      Date:this.datePipe.transform(this.TimeSheetFormGroup.get('fcDate').value,"dd-MM-yyyy"),
      MenuID:'STS_24',
      LedgerID:this.selectedEmpID==undefined ?curUser.ledgerid : this.selectedEmpID
      
    };
    this.service.httpPost('/Timesheet/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.Categorylist = result.respdata.Category;
            this.Productlist=result.respdata.Product;
            this.Projectlist = result.respdata.Project;            
            this.Clientlist = result.respdata.Client;
          //  this.MyTask=result.respdata.MyTask;
            //this.CommonTask=result.respdata.CommonTask; 
            this.HomePage=result.respdata.HomePage;
            this.Rolelist=result.respdata.Role; 

            this.dataSource=result.respdata.Datasource;  
            this.dataSource1=result.respdata.Datasource;   
            this.Employees = result.respdata.Employees;   
            this.Visiblelist = result.respdata.Visible;
           // console.log('visible list ', this.Visiblelist);
           this.isClientVisible=this.Visiblelist[0].Visibility;
            this.isProductVisible = this.Visiblelist[1].Visibility;
            this.isProjectVisible=this.Visiblelist[2].Visibility;
           // this.isRoleVisible=this.Visiblelist[3].Visibility;
            this.isTicketVisible = this.Visiblelist[4].Visibility;     
           // console.log('Client visible value ', this.isClientVisible);
           for (var i = 0; i < result.respdata.HomePage.length; i++) 
           {
             if(i==0)
             {
               //console.log('RESULT',result.respdata)
                this.Table11=result.respdata.Table11           
             }
             else if(i==1)
             {
               this.Table12=result.respdata.Table12              
             }
             else if(i==2)
             {
               this.Table13=result.respdata.Table13
             }
             else if(i==3)
             {
               this.Table14=result.respdata.Table14
             }
             else if(i==4)
             {
               this.Table15=result.respdata.Table15
             }

             
           }
           
           this.PreviousEffort=result.respdata.PrevEffort;
           
            this.employeeid= _.pluck(this.Employees, 'Name');   

            this.getUserList();
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_24",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;   
      this.editbtnvisible = btnVisilityData.editflag;                     
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  selectedemployee(event) {    
    // this.selectedemployees=event.option.value;    
    // this.selectedemployees=JSON.parse(_.pluck(_.where(this.Employees, { 'Name': event.option.value }),'LedgerID')); 
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;
    // this.selectedEmpID=str.split('-',1);
    // console.log('selectedEmpID Split',this.selectedEmpID.substr(0,this.selectedEmpID.length));
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
    this.getddllist();
  }


  Edit(){
  //  const dialogRef = this.dialog.open(TimesheetviewComponent);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {       
        CID:this.TimeSheetFormGroup.get('fcCompany').value,
        LedgerID:this.selectedEmpID     
      }
      const dialogRef = this.dialog.open(TimesheetviewComponent, dialogConfig)
      dialogRef.afterClosed()
  }

  onClear()
  {
    this.TimeSheetFormGroup.reset();  
    this.myControl.setValue('');
    this.dataSource='';
    this.MyTask='';
    this.CommonTask='';
    this.TimeSheetFormGroup.controls.fcCompany.setValue(this.cid);
  }

  changesite(e) {
    this.cid=e.value;
    this.getddllist();
  }
  SearchClear()
  {
    this.myControl.setValue('');  
  }
  logEvent(eventName, e)
  {
  }

  Submit() {   
    // if (this.TimeSheetFormGroup.invalid) {
    //   this.NotificationService.warn('Please select employee & effort date');     
    //   return;
    //   }  
    let params = {     
      CID: this.TimeSheetFormGroup.get('fcCompany').value,
      Flag: "SETEFFORT",           
      Date : this.datePipe.transform(this.TimeSheetFormGroup.get('fcDate').value,"dd-MM-yyyy"),    
      //Date : this.TimeSheetFormGroup.get('fcDate'),    
       MenuID:'STS_24',
       LedgerID:this.selectedEmpID,
      Listarray: this.dataSource1 ,
      ApplicationDetails:'Web'             
    };
    this.service.httpPost('/Timesheet/SetTimesheetEffort',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

  
}
