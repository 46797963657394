import { UserService } from './../../../Admin/Users/userValidate';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { FormGroup,FormControl } from '@angular/Forms';
import { Component, OnInit, Pipe, PipeTransform, ViewChild, NgModule, enableProdMode } from '@angular/core';
import { LeaveApprovalService, Employee } from '../leave-approvalValidate';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DxPopupModule, DxButtonModule, DxTemplateModule } from 'devextreme-angular';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { User } from './../../../../Modules/user';
import { NewpopupleavedirectapprovalComponent } from '../newpopupleavedirectapproval/newpopupleavedirectapproval.component';


@Component({
  selector: 'app-newleaverequest',
  templateUrl: './newleaverequest.component.html',
  styleUrls: ['./newleaverequest.component.scss'],
})
export class NewleaverequestComponent implements OnInit {

  employeelist: any;
  ledgerid= '';
  approverdetails:any;
  dataSource1: any;
  leavedetails: any;
  otherdetails: any;
  requestyear: any= 0;
  popupVisible = false;
  CID: any;
  CurLedgerID:any;
  navbarOpen = true;
  companies: any;
  isLinear = false;
  addbtnvisible: boolean = false;
  viewbtnvisible: boolean = false;
  toggle= 'filter_list';
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  empid:string[]=[];
  showSpinner:any;
  displayedColumns = ['FromDate', 'ToDate', 'DaysRequested', 'LeaveType', 'Status', 'Comments', 'Action'];
  displayedColumns1 = ['CategoryName', 'LeaveCount'];
  displayedColumns2 = ['CategoryName', 'Available', 'Taken'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service: LeaveApprovalService, private NotificationService: NotificationService, 
    private generic: GenericService, private service1: UserService,
    private dialog: MatDialog, private dialogService: DialogService) { }

  ngOnInit(): void {
    // this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.getemployeelist();
    this.requestyear = new Date().getFullYear();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );    
  }
  onCompanySelect(event)
  {
    this.employeelist='';
    this.empid=[];
    this.CID=event;
    this.myControl.setValue(''); 
    this.requestyear = new Date().getFullYear();
    this.leavedetails=[];
    this.dataSource1 = [];
    this.approverdetails=[];
    this.getemployeelist();

  }
  selectedproduct(event) {    
    this.ledgerid=event.option.value;    
    this.ledgerid=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));    
    // this.getleaveapproval(); 
  }
  private _filter(value: string): string[] {

    const filterValue = value.toLowerCase();   
      return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    if (this.navbarOpen == false)
    { this.toggle = 'close'; }
    else{this.toggle = 'filter_list'; }
  }
  // show()
  // {
  //   this.popupVisible = true;
  // }
  // showInfo(eventName) {
  //   this.popupVisible = true;
  // }
  async refresh()
  {
    await this.getleaveapproval();
  }
  clear()
  {
    this.myControl.setValue(''); 
    this.requestyear = new Date().getFullYear();
    this.leavedetails=[];   
    this.dataSource1 = [];
    this.approverdetails=[];
    this.ledgerid="0";
  }
  // onvaluechanged() {
  //   this.getleaveapproval();
  //  }
  addRow() {
    if (this.ledgerid != '')
    {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        flag: 'ADD',
        ledgerid: this.ledgerid,
        requestyear: this.requestyear,
        type: 0,
        cid:this.CID
      };
      const dialogRef = this.dialog.open(NewpopupleavedirectapprovalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {     
          this.getleaveapproval();     
        });

      }
  }
  onEdit(data, _flag){
     this.service.populateForm(data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: _flag,
      ledgerid: this.ledgerid,
      requestyear: this.requestyear,
      type: 0,
      daystatus:data.DayStatus,
      cid:this.CID
    };
    const dialogRef = this.dialog.open(NewpopupleavedirectapprovalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {     
      this.getleaveapproval();     
    });
  }
  onView(data, _flag){
  
    const params = {
      cid: this.CID,
      flag: _flag,
      ledgerid: data.LedgerID,
      LeaveID: data.LeaveID,
      year: 0

        };
        this.service.GetLeaveDetails(params)
        .subscribe(data => {
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result = data;
          if (result.errno === 0){
            this.approverdetails = result.respdata.Table;          
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }
  onDelete(data, _flag){
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;

     data.RtnDate = data.ToDate;
     const data1 = data;
    this.dialogService.openConfirmDialog('Are you sure do you want to delete this leave?')
    .afterClosed().subscribe(res => {
      if (res) {
        const params = {
          cid: this.CID,
      flag: 'DELETE',
      LedgerID: this.ledgerid,
      LeaveID: data.LeaveID,
      LeaveType: data.CategoryName,
      Comment: data.Comments,
      DayStatus: data.DayStatus,
      FromDate: data.FromDate,
      ToDate: data.ToDate,
      ReturnDate: data.ToDate,
      Session: data.SessionType,
      UserID: '',
      EmpUniqID	: this.CurLedgerID,
      GroupID: sessionStorage.getItem('groupid'),
      Year: this.requestyear,
      Type: 0
        };
        const params1 = Object.assign(params);
        this.service.SetLeaveDetails(params1)
          .subscribe(
            data => {
              let result: any;
              result = data;
              this.NotificationService.warn('Leave deleted successfully');
              this.getleaveapproval();   
            },
            error => {
              console.log(error);
        });
      }
    });

  }

  SearchClear()
  {
    this.myControl.setValue('');  
  }
  
  getemployeelist(){
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
        const params = {
          cid: this.CID,
          flag: 'PAGELOAD',
          ledgerid: 0,
          LeaveID: 0,
          year: this.requestyear

            };
        this.service.GetLeaveDetails(params)
        .subscribe(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.companies = curUser.dtsiteid;
            this.employeelist = result.respdata.Table1;
            this.empid= _.pluck(this.employeelist, 'Name');
            this.getUserList();
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
      }

      getUserList(){
        let params = {
          cid: JSON.parse(sessionStorage.getItem('cid')),
          menuid: "STS_11",
          groupid: JSON.parse(sessionStorage.getItem('groupid'))
        }
        this.service1.getUserList(params)
        .subscribe((data) => {
          let result: any;
          result=data;
          let btnVisilityData;
          btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
          this.addbtnvisible = btnVisilityData.addflag;   
          this.viewbtnvisible = btnVisilityData.viewflag;                     
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)         
            console.log(error);
          else throw error;       
        });
    
      }

   getleaveapproval(){
    this.showSpinner=true;
        const params = {
          cid: this.CID,
          flag: 'LOADLEAVE',
          ledgerid: this.ledgerid,
          LeaveID: 0,
          year: this.requestyear

            };
            this.service.GetLeaveDetails(params)
            .subscribe(data => {
              let result: any;
              this.showSpinner=true;
              setTimeout(() =>{
                this.showSpinner=false;
              },
              800)
              result = data;
              if (result.errno === 0){
                this.dataSource1 = result.respdata.Table1;
                this.leavedetails = result.respdata.Table;
              //  this.otherdetails = result.respdata.Table;
              }
              else{
                this.NotificationService.success(result.errdesc); //':: Problem details');
              }
            },
            error => {
              console.log('Error : ', error);
            });
          }

}

