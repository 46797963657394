import { Component, OnInit,ViewChild } from '@angular/core';
import { MatDialog,MatDialogConfig  } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { ProjectallocationbyweekComponent } from '../projectallocationbyweek/projectallocationbyweek.component';
import { AbstractControl } from '@angular/forms';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { User } from './../../../../Modules/user';

@Component({
  selector: 'app-projectallocationbyweek-list',
  templateUrl: './projectallocationbyweek-list.component.html',
  styleUrls: ['./projectallocationbyweek-list.component.css']
})
export class ProjectallocationbyweekListComponent implements OnInit {
  //ProjectAllocation: FormGroup;
  @ViewChild('stepper') stepper;

  dataSource: ProjectAllocationDataGrid[];
  ProjectAllocationDataGrids: any;
  //projectdata:string[];
  SelectedCompanyID: number;
  projectdata: any;
  companies: any;
  Departments: any;
  setWeekEnd: number;
  setHolidays: number;

  selectedCID: number;
  selectedprojectid: any;
  selectedprojectname: any;
  selecteddepartments: number;
  projectid: string[]= [];

  ProjectFormGroup: FormGroup;
  ProjectDataFormGroup: FormGroup;
  isEditable = true;

  myControl = new FormControl('', [Validators.required]);
  //options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  cid:any;
  oneJan: any;
  numberOfDays: number;
  curMonth: any;
  count:any;
  addbtnvisible: boolean = false;
  viewbtnvisible: boolean = false;

  constructor(private service: HttpserviceService, private service1: UserService, 
    private formBuilder: FormBuilder, private dialog: MatDialog, private generic: GenericService,
    public notificationService: NotificationService, private datePipe: DatePipe) {
    //this.ProjectAllocationDataGrids;

    this.ProjectFormGroup = this.formBuilder.group({
      fcCompany: ['', Validators.required] ,
      //fcProject: ['', Validators.required]   ,
      fcDepartment: ['', Validators.required],
      fcStartWeekNo: ['', Validators.required] ,
      fcEndWeekNo: ['', Validators.required]   ,
      fcYear: ['', Validators.required],
      fcWeekEnd:[''],
      fcHolidays:['']
      // ,
      // fcproject:new FormControl('', [Validators.required, RequireMatch])

    });
    this.ProjectDataFormGroup = this.formBuilder.group({
      //company: ['', Validators.required]
     // fcProjectDepartment:['']
    });

  }

  // displayWith(obj?: any): string | undefined {
  //   return obj ? obj.name : undefined;
  // }

  ngOnInit(): void {
    this.getWeekNo();
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.PageLoad();
    this.ProjectFormGroup.controls.fcCompany.setValue(this.cid);
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_63",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;     
      this.viewbtnvisible = btnVisilityData.viewflag;
   
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  changesite(e) {
    // console.log('change',e.value);
    this.cid=e.value;
    this.PageLoad();
  }

  //get f() { return this.ProjectAllocation.controls; }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.projectid.filter(option => option.toLowerCase().includes(filterValue));

  }

  selectedproduct(event) {
    //this.selectedprojectid=event.option.value;
    this.selectedprojectname = event.option.value;
    this.selectedprojectid = JSON.parse(_.pluck(_.where(this.projectdata, { 'ProjectID': event.option.value }), 'ProjectUID'));
  }

  // changeDepartment(data){
  //   this.selecteddepartments=JSON.parse(data);
  //   console.log("selected --->"+this.selecteddepartments);
  // }


  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    const params = {
      CID: this.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: 'PageLoad',
      EmployeeID: 'BHE 067',
      ProjectID: 0,
      RoleID: 0,
      DepartmentID: 0,
      StartWeekNo: 0,
      EndWeekNo: 0,
      Year: 0,
      SetWeekEnd: 0,
      SetHolidays: 0
    };
    
   // this.service.getprojectallcoationlist(params)
   this.service.httpPost('/ProjectAllocationByWeek/GetProjectAllocationData',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {        
            this.companies = curUser.dtsiteid;
            this.SelectedCompanyID = this.cid;
            this.projectdata = result.respdata.Table1;            
            this.Departments = result.respdata.Table2;
            this.projectid = _.pluck(this.projectdata, 'ProjectID');
          
            this.getUserList();
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });
  }


  getWeekNo()  {
    const date = new Date();
    this.curMonth =   new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.oneJan =   new Date(date.getFullYear(), 0, 1);
    this.numberOfDays =  Math.floor((this.curMonth - this.oneJan) / (24 * 60 * 60 * 1000));
    // console.log('curMonth',this.curMonth );
    // console.log('oneJan',this.oneJan);
    // console.log('number of days',this.numberOfDays);
    // console.log('date',date.getDay());
    if(date.getDay() <6){
      this.ProjectFormGroup.get('fcStartWeekNo').setValue(Math.ceil((this.numberOfDays) / 7)+0);
      this.ProjectFormGroup.get('fcEndWeekNo').setValue(Math.ceil((this.numberOfDays) / 7) + 4);
    }
    else{
      this.ProjectFormGroup.get('fcStartWeekNo').setValue(Math.ceil((this.numberOfDays) / 7));
      this.ProjectFormGroup.get('fcEndWeekNo').setValue(Math.ceil((this.numberOfDays) / 7) + 4);
    }
    // this.ProjectFormGroup.get('fcStartWeekNo').setValue(Math.ceil((date.getDay() +1 + this.numberOfDays) / 7));
    // this.ProjectFormGroup.get('fcEndWeekNo').setValue(Math.ceil((date.getDay()  +1+ this.numberOfDays) / 7) + 4);
    this.ProjectFormGroup.get('fcYear').setValue(date.getFullYear());   
  }



   Submit() {
    if (this.ProjectFormGroup.invalid) {
      this.notificationService.warn('Please select project and department');     
      return;
      }   
    const params = {
      CID: this.ProjectFormGroup.get('fcCompany').value, //101,
      Flag: 'LoadGrid',
      EmployeeID: 'BHE 067',
      ProjectID: this.selectedprojectid,
      RoleID: this.ProjectFormGroup.get('fcDepartment').value, //this.selecteddepartments,
      DepartmentID: this.ProjectFormGroup.get('fcDepartment').value,
     // StartWeekNo: this.ProjectDataFormGroup.controls['StartWeekNo'].value,
      StartWeekNo: this.ProjectFormGroup.get('fcStartWeekNo').value, //this.ProjectDataFormGroup.value.StartWeekNo,
      EndWeekNo: this.ProjectFormGroup.get('fcEndWeekNo').value, //this.ProjectDataFormGroup.value.EndWeekNo,
      Year: this.ProjectFormGroup.get('fcYear').value, //this
      SetWeekEnd: this.ProjectFormGroup.get('fcWeekEnd').value==true?1:0,
      SetHolidays: this.ProjectFormGroup.get('fcHolidays').value==true?1:0
    }; 
    //this.service.getprojectallcoationlist(params)
    this.service.httpPost('/ProjectAllocationByWeek/GetProjectAllocationData',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {          
            const removeLedgerID = result.respdata.Table.map(({ LedgerID, ...item }) => item);
            const removeResourceAllocationID = removeLedgerID.map(({ ResourceAllocationID, ...item }) => item);
            this.ProjectAllocationDataGrids = removeResourceAllocationID; //result.respdata.Table;
            this.dataSource = result.respdata.Table;           

            this.count=result.respdata.Table.length/2;   
            this.stepper.selectedIndex = 1;       
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          this.notificationService.warn(error);
          console.log('Error : ', error);
        });


  }

  onSearchClear(){
    this.ProjectFormGroup.reset(); 
    this.ProjectAllocationDataGrids='';   
    this.myControl.setValue('');   
    this.count=0;
    this.stepper.selectedIndex = 0;  
    this.ProjectFormGroup.controls.fcCompany.setValue(this.cid);
    this.getWeekNo();
  }
  SearchClear()
  {
    this.myControl.setValue('');  
  }
  excelImport(){

  }

  startweekno:number;
  endweekno:number;
  weekdiff:number;
  //openDialog(CID:number,ProjectUID:number,Department:number,StartWeekNo:number,EndWeekNo:number,Year:number,Flag:string) {
  openDialog(Flag: string) {

    if (this.ProjectFormGroup.invalid) {
      this.notificationService.warn('Please select project and department');    
      return;
      }
     
      this.startweekno=this.ProjectFormGroup.get('fcStartWeekNo').value
      this.endweekno=this.ProjectFormGroup.get('fcEndWeekNo').value;
      this.weekdiff=this.endweekno-this.startweekno+1;     
  
     // console.log('FLAG',Flag);
      //console.log('Weekdiff',this.weekdiff);
      if(this.weekdiff <=40 && Flag !='isEmployee')
      {
        const ProjectData = {cid: this.ProjectFormGroup.get('fcCompany').value,
                      //cidname:this.ProjectFormGroup.get('fcCompany').value,
                     // projectname:this.selectedprojectName,
                      projectuid: this.selectedprojectid,
                      //departmentname:this.selecteddepartmentname,
                      department: this.ProjectFormGroup.get('fcDepartment').value,
                      startweekno: this.ProjectFormGroup.get('fcStartWeekNo').value,
                      endweekno: this.ProjectFormGroup.get('fcEndWeekNo').value,
                      year: this.ProjectFormGroup.get('fcYear').value,
                      flag: Flag,
                      SetWeekEnd: this.ProjectFormGroup.get('fcWeekEnd').value==true?1:0,
                      SetHolidays: this.ProjectFormGroup.get('fcHolidays').value==true?1:0}; 
              sessionStorage.setItem('ProjectData', JSON.stringify(ProjectData));
              const dialogRef = this.dialog.open(ProjectallocationbyweekComponent);
              dialogRef.afterClosed().subscribe(result => {                 
            });
         }
         else if(Flag ==='isEmployee')
          {
            const ProjectData = {cid: this.ProjectFormGroup.get('fcCompany').value,
            //cidname:this.ProjectFormGroup.get('fcCompany').value,
           // projectname:this.selectedprojectName,
            projectuid: this.selectedprojectid,
            //departmentname:this.selecteddepartmentname,
            department: this.ProjectFormGroup.get('fcDepartment').value,
            startweekno: this.ProjectFormGroup.get('fcStartWeekNo').value,
            endweekno: this.ProjectFormGroup.get('fcEndWeekNo').value,
            year: this.ProjectFormGroup.get('fcYear').value,
            flag: Flag,
            SetWeekEnd: this.ProjectFormGroup.get('fcWeekEnd').value==true?1:0,
            SetHolidays: this.ProjectFormGroup.get('fcHolidays').value==true?1:0}; 
            sessionStorage.setItem('ProjectData', JSON.stringify(ProjectData));

            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus =  true;
            const dialogRef = this.dialog.open(ProjectallocationbyweekComponent,dialogConfig);
            dialogRef.afterClosed().subscribe(result => {   

          });
        }
       else
       {
        this.notificationService.warn('Invalid week selection.import option only for five weeks.');    
        return;
       }
  }

  onCellPrepared(e) {
    if (e.rowType !== 'data' || e.columnIndex > 3 ) {
      return;
    }
    if (e.rowIndex % 2 === 0 && ( e.columnIndex == 0  ||  e.columnIndex == 1 ||  e.columnIndex == 2 ||  e.columnIndex == 3)) {
      e.cellElement.rowSpan = 2;
      e.cellElement.innerHTML = '<div>' + e.value + '</div>';
    }
    else {
      e.cellElement.style.display = 'none';
    }
  //   if(e.rowType === 'header') {
  //     e.cellElement.css("backgroundColor", "blue");
  //  }
  }

}

export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  if (typeof selection === 'string') {
      return { incorrect: true };
  }
  return null;
}
export class ProjectAllocationDataGrid {
  LedgerID: number;
  ResourceAllocationID: number;
  Name_Allotted: string;
  TotalAllotMins: number;
  TotalWorkMins: number;
  Type: string;
}

