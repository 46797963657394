import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { PstemplateComponent } from '../pstemplate/pstemplate.component';

@Component({
  selector: 'app-pstemplatelist',
  templateUrl: './pstemplatelist.component.html',
  styleUrls: ['./pstemplatelist.component.css']
})
export class PstemplatelistComponent {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  CID:any;
  flag:any;
  companies:any;
  datasource:any;
  dataSource: any[];
  columns: any[];

  displayedColumns =['VouNo','Description','Interval','Comment','Action'];

  constructor(public service: HttpserviceService,public notificationService: NotificationService,
    private datePipe: DatePipe,private dialogService: DialogService,private dialog: MatDialog,private cdr: ChangeDetectorRef)
   { 

    this.dataSource = [
      { id: 1, name: 'John Doe', age: 30,email:'test' },
      { id: 2, name: 'Jane Smith', age: 25,email:'test' },
      // Add more data as needed
    ];
    this.loadUserPreferences();
   }

   loadUserPreferences() {
    const savedColumns = sessionStorage.getItem('userColumns');
    console.log('savedColumns',savedColumns)
    if (savedColumns) {
      this.columns = JSON.parse(savedColumns);
    } else {
      this.columns = [
        { dataField: 'id', caption: 'ID', visible: true },
        { dataField: 'name', caption: 'Name', visible: true },
        { dataField: 'age', caption: 'Age', visible: true },
        { dataField: 'email', caption: 'Email', visible: true }
      ];
    }
  }

  saveUserPreferences() {
    console.log('userColumns',this.columns)
    sessionStorage.setItem('userColumns', JSON.stringify(this.columns));
  }

  onOptionChanged(e: any) {
    console.log('onOptionChanged event:', e);
    
    // Check if the change is related to column visibility
    if (e.name === 'columns' && e.fullName.startsWith('columns[')) {
      const columnIndex = parseInt(e.fullName.match(/\d+/)[0], 10);
      const isVisible = e.value;
      
      // Update the visibility in your columns array
      if (!isNaN(columnIndex) && columnIndex >= 0 && columnIndex < this.columns.length) {
        this.columns[columnIndex].visible = isVisible;
        console.log('Updated columns:', this.columns);
        
        // Save user preferences after updating visibility
        this.saveUserPreferences();
      }
    }
  }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
       this.Loaddetails();
  }

  Refresh()
  {
    this.Loaddetails();
  }

  Loaddetails()
     {
       let servicepath;
       const params = {
        cid: this.CID,
        Flag:this.flag,
        VouNo:'0'
      };   
      const query = `cid=${params.cid}&Flag=${params.Flag}&VouNo=${params.VouNo}`;
  
      this.service.httpGet('/PSTemplate/GetPSTemplate?' ,query)   
             .subscribe(data => {
               let result: any;
               result=data;
               if (result.errno === 0){
                
                 if(this.flag=='PAGELOAD')
                 {
                   this.companies=result.respdata.Table;
                   //this.datasource=result.respdata.Table1;
                   this.datasource = new MatTableDataSource(result.respdata.Table1);
                   this.datasource.sort = this.sort;
                   this.datasource.paginator = this.paginator;
    
                 }
                //  else if(this.flag=='Category')
                //  {
                //    this.datasource=result.respdata.List;
                //  }
                //  else
                //  {
                //    let filterdata
                //    this.employeelistselected=result.respdata.List;
                //  }
                   
                   
               }
               else{
                 this.notificationService.success(result.errdesc); //':: Problem details');
               }
             }, 
             (error: AppError) => {
               if (error instanceof BadInput)           
                 console.log(error);
               else throw error;       
             });
     }

     Add()
     {
      const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.width="95%";
        dialogConfig.height="85%";
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'ADD' ,                            
          VouNo:'0'
            
        }
        const dialogRef = this.dialog.open(PstemplateComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Loaddetails();
  
          });
     }
     Edit(event)
     {
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.width="95%";
      dialogConfig.height="85%";
      dialogConfig.data = {       
        cid:this.CID,
        Flag:'EDIT' ,                            
        VouNo:event.VouNo,
      
      }
      const dialogRef = this.dialog.open(PstemplateComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(result => {
        this.CID=this.CID;
        this.flag='PAGELOAD'
        this.Loaddetails();

        }); 

     }
    //  Delete(event)
    //  {

    //     let temp = [];
    //       temp.push({Value:'',Value1:''});
    
      
    //   let params = { 
    //     cid:this.CID,
    //     flag:'DELETE', 
    //     ID:event.ID,
    //     Questions:event.Questions,
    //     ControlType:event.ControlType,
    //     Type:event.Type, 
    //     DatePeriod: this.datePipe.transform(event.DatePeriod, 'dd-MM-yyyy')  ,         
    //     dt:temp,
    //     UserID:'0',
    //     GroupID:101
        
    //    };
    //   this.service.httpPost('/Question/SetQuestion',params)
    //   .subscribe(
    //     (data) => {
    //       let result: any;
    //       result = data;       
    //       if (result.errno === 0) {
    //         this.notificationService.warn('Deleted Successfully');
            
            
    //       }
    //       else {
    //         this.notificationService.success(result.errdesc); //':: Problem details');
    //       }
    //     },
    //     (error: AppError) => {
    //       if (error instanceof BadInput)         
    //         console.log(error);
    //       else throw error;       
    //     });
    //  }

}
