import { DialogService } from '../../../../Services/OtherServices/dialog.service';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TicketprefixdialogComponent } from '../../ticketprefixdialog/ticketprefixdialog.component';
import { TicketlinkconfigComponent } from '../ticketlinkconfig/ticketlinkconfig.component';


@Component({
  selector: 'app-ticketlinklist',
  templateUrl: './ticketlinklist.component.html',
  styleUrls: ['./ticketlinklist.component.scss'],
})
export class TicketlinklistComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['TypeID', 'Type', 'OppType', 'Action'];

  companies: any;
  showSpinner: any;
  datasource: MatTableDataSource<GroupALL>;
  CID: any;
  flag: any;

  constructor(private dialog: MatDialog, public service: HttpserviceService,
    public notificationService: NotificationService, private dialogService: DialogService,
    private NotificationService: NotificationService) { }

  ngOnInit() {
    this.CID = JSON.parse(sessionStorage.getItem('cid'));
    this.flag = 'PAGELOAD'
    this.Loaddetails();
  }

  onCompanySelect(event) {
    this.CID = event;
    this.flag = 'PAGELOAD'
    this.Loaddetails();
  }

  Submit() {
    this.Loaddetails();
  }

  Loaddetails() {
    this.showSpinner = true;
    let params = {
      cid: this.CID,
      flag: this.flag
    }
    this.service.httpPost('/TicketLinkConfig/GetTicketLinkConfig', params)
      .subscribe(data => {
        this.showSpinner = true;
        setTimeout(() => {
          this.showSpinner = false;
        },
          800)
        let result: any;
        result = data;
        if (result.errno === 0) {

          if (this.flag == 'PAGELOAD') {
            this.companies = result.respdata.Companylist;
            this.datasource = result.respdata.TicketType;
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.TicketType);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
          }
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log(error);
          else throw error;
        });
  }

  add() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      cid: this.CID,
      Flag: 'ADD',
    }
    const dialogRef = this.dialog.open(TicketlinkconfigComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.CID = this.CID;
      this.flag = 'PAGELOAD'
      this.Loaddetails();

    });
  }

  Edit(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      cid: this.CID,
      Flag: 'EDIT',
      TypeID: event.TypeID,
      Type: event.Type,
      OppType: event.OppType,
    }
    const dialogRef = this.dialog.open(TicketlinkconfigComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.CID = this.CID;
      this.flag = 'PAGELOAD'
      this.Loaddetails();

    });
  }

  Delete(event) {
    let sdate = Date();
    this.dialogService.openConfirmDialog('Are you sure to delete this type?')
      .afterClosed().subscribe(res => {
        if (res) {
          let params = {
            CID: this.CID,
            Flag: 'DELETE',
            TypeID: event.TypeID,
            Type: event.Type,
            OppType: event.OppType,
            UserID: 0,
            GroupID: sessionStorage.getItem('groupid')
          };
          this.service.httpPost('/TicketLinkConfig/SeTicketLinkConfig', params)
            .subscribe(
              (data) => {
                let result: any;
                result = data;
                if (result.errno === 0) {
                  this.NotificationService.warn("Record deleted Successfully");
                  this.flag = 'PAGELOAD'
                  this.Loaddetails();
                }
                else {
                  this.NotificationService.success(result.errdesc); //':: Problem details');
                }
              },
              (error: AppError) => {
                if (error instanceof BadInput)
                  console.log(error);
                else throw error;
              });
        }
      });
  }
}

export interface GroupALL {
  ID: string;
  Type: string;
}