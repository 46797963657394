import { User } from './../../../../Modules/user';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { Component, OnInit } from '@angular/core';
import { UploadprofilepicComponent } from '../../../../components/HR/EmployeeRegistration/uploadprofilepic/uploadprofilepic.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  CurLedgerID:string;
  ledgerid:any;
  loginphoto:any;
  empdetails:any;
  actualleavedetails:any;
  leavedetails:any;

  nationality:any;
  mobileno:any;
  emailid:any;
  currentaddress:any;
  permentaddress:any;
  joindate:any;
  designation:any;

  constructor(private service:HttpserviceService, public dialogRef: MatDialogRef<ProfileComponent>
    ,public dialogRef1: MatDialogRef<UploadprofilepicComponent>,private dialog: MatDialog) 
  { 

    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    // this.CurLedgerID=curUser.username;
    this.ledgerid=curUser.ledgerid;
    this.loginphoto='data:image/jpeg;base64,'+sessionStorage.getItem('photo');
  }

  ngOnInit() 
  {
    this.getdocumentdetails();
  }

  Quit()
  {
   
    this.dialogRef.close({});
  }

  getdocumentdetails()
  {
      // console.log("dddd",this.LedgerID)
       let params = {
       cid:101,
       flag:'LOADEMPDASHBOARD',
       ledgerid:this.ledgerid
        }
        const query = `cid=${params.cid}&flag=${params.flag}&LedgerID=${params.ledgerid}`;
        this.service.httpGet('/General/GetProfileDetails?',query)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){
              this.empdetails=result.respdata.EmployeeDetails;
              this.actualleavedetails=result.respdata.AcutalLeaveDetails;
              this.leavedetails=result.respdata.LeaveDetails;

              this.CurLedgerID=this.empdetails[0].EmpID + ' - ' +this.empdetails[0].AliasName1;
              this.nationality=this.empdetails[0].Nationality;
              this.mobileno=this.empdetails[0].MobileNo;
              this.emailid=this.empdetails[0].EMail;
              this.currentaddress=this.empdetails[0].CurrentAddress;
              this.permentaddress=this.empdetails[0].PermenantAddress;
              this.joindate=this.empdetails[0].JoiningDate;
              this.designation=this.empdetails[0].Designation;

             // console.log("this.empdetails",this.empdetails)
             }
             else{
              //  this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error) => {       
               console.log(error);       
           });
    }

    ChangeProfilePic()
    {     
      const dialogConfig = new MatDialogConfig();    
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {              
        ledgerID:this.ledgerid,
        cid: JSON.parse(sessionStorage.getItem('cid'))      
      }
      const dialogRef1 = this.dialog.open(UploadprofilepicComponent, dialogConfig); 
      dialogRef1.afterClosed().subscribe(result => {     
        this.getdocumentdetails();     
       
        });

    }

}
