import { WebcameraComponent } from './../webcamera/webcamera.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/Forms';
// import { NeworderService } from '../neworder.service';
import { User } from './../../../../Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { AuthenticationService } from 'src/app/Services/OtherServices/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NeworderComponent } from './../neworder/neworder.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';

interface Quotation{
  OrderDate: string,
  Name: string,
  LedgerID: number,
  OrderNo: string,
  Status: string
}

@Component({  
  selector: 'app-neworderlist',
  templateUrl: './neworderlist.component.html',
  styleUrls: ['./neworderlist.component.css']
})
export class NeworderlistComponent implements OnInit {  
  showSpinner:any;

  displayedColumns: string[] = ['OrderDate', 'Name', 'OrderNo', 'Status'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dataSource:MatTableDataSource<GroupALL>;
  fromdate = new Date().toLocaleDateString();
  todate = new Date().toLocaleDateString();
  qtnstatus: string = 'Open';
  addbtnvisible: boolean = false;
  viewbtnvisible: boolean = false;
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

  constructor(private service:HttpserviceService,  private authenticationService: AuthenticationService,
    private dialog: MatDialog, private generic: GenericService, private service1: UserService
    ) { }
  ngOnInit(): void {
    this.getSalesmanOrder();
  }
 
  refresh(){
    this.getSalesmanOrder();
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "ERP_156",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;
      this.viewbtnvisible = btnVisilityData.viewflag;        
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }
  // getQuoationlist(){    
  //   const params = {
  //     cid: JSON.parse(sessionStorage.getItem('cid')),
  //     salesmanid: this.curUser.salesmanid,
  //     fromdate: this.fromdate,
  //     todate: this.todate,
  //     qtnstatus: this.qtnstatus
  //   };   
  //   const query = `cid=${params.cid}&salesmanid=${params.salesmanid}&fromdate=${params.fromdate}&todate=${params.todate}&qtnstatus=${params.qtnstatus}`; 
  //  this.service.httpGet('/salesorder/getsalesmanquotation?' ,query)   
  //   .subscribe(data => {
  //     let result: any;
  //     result = data;
  //     if(result.errno == 0){
  //       this.dataSource = result.respdata;     
  //     }
  //     else{
  //       console.log('error');       
  //     }
  //   },   
  //   )
  // }
  addRow() 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid: JSON.parse(sessionStorage.getItem('cid'))
      // Flag:'ADD' , 
      // Employees:this.Employees,                           
      // Type:this.Types,
      // Currency:this.Currency,
      // RequestID:''   
    }
    const dialogRef = this.dialog.open(NeworderComponent,dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
      console.log('refreshbutton')
    this.getSalesmanOrder();     
    });
  }
  addPhoto() 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid: JSON.parse(sessionStorage.getItem('cid'))
      // Flag:'ADD' , 
      // Employees:this.Employees,                           
      // Type:this.Types,
      // Currency:this.Currency,
      // RequestID:''   
    }
    const dialogRef = this.dialog.open(WebcameraComponent,dialogConfig)
    dialogRef.afterClosed().subscribe(result => {     
    this.getSalesmanOrder();     
    });
  }
  
  getSalesmanOrder(){  
    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      salesmanid: this.curUser.salesmanid,
      qtnstatus: this.qtnstatus
    };    
    this.showSpinner=true;
    const query = `cid=${params.cid}&salesmanid=${params.salesmanid}&qtnstatus=${params.qtnstatus}`;
   this.service.httpGet('/salesorder/GetSalesmanOrder?' ,query)   
    .subscribe(data => {
      let result: any;
      result = data;
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)
      if(result.errno == 0){
        this.dataSource = result.respdata;    
        
        this.dataSource = new MatTableDataSource<GroupALL>(result.respdata);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.getUserList();
      }
      else{
        console.log('error');        
      }
    },
    )
  }
}
export interface GroupALL {
  ReqID: string;
  Description: string;
}