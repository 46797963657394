import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { PayslippaymentfilterComponent } from '../payslippaymentfilter/payslippaymentfilter.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table'; 
import { Console } from 'console';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';

@Component({
  selector: 'app-emppayslippayment',
  templateUrl: './emppayslippayment.component.html',
  styleUrls: ['./emppayslippayment.component.css']
})
export class EmppayslippaymentComponent {

  showSpinner:any;
  data1:any;
  Salesmanlist:any;
  UserID:any;
  cid:any=101;
  LedgerID:any=0;
  fromdate:any;
  todate:any;
  dataSource:any;
  Mode: string='CASH';
  LedgerType:any;
  Type:any='';
  Prefix:any;
  VouDate:any=new Date();
  VoucherNo:any='';
  Prefixlist:any;
  fullpayCheckboxValue: boolean = true; // Default value of the checkbox
  flag:any;
  EmpID:any=0;
  EmpName:any='';
  Category:string='--ALL--'
  PSType:string='--ALL--'
  DateTypeFlag:any='Today';
  NetAmt:any=0;
  PaidAmt:any=0;


  displayedColumns =['VouNo','Date','LedgerType','NetAmount','PaidAmount','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private service:HttpserviceService,private datePipe: DatePipe,private dialog: MatDialog,private NotificationService:NotificationService) 
  {
    this.fromdate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
    this.todate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
   }

   ngOnInit()
   {
    this.PageLoad()
    this.changeMode()
    //this.LoadData();
   }
   selectionChange(e)
   {
    this.flag='ADD'
    this.LedgerID=0;
    this.EmpID=0;
   }
   handleCheckboxChange() {
    // Update the dataSource based on the checkbox value
    this.dataSource.forEach(item => {
      item.FullPay = this.fullpayCheckboxValue;
    });
  }
   onEdit(data, _flag,stepper: MatStepper){

    this.flag=_flag;
    this.LedgerID=0;
    this.EmpID=0;
    let parts = data.split('/');
    this.VoucherNo=parts[1]
    console.log('data',data);
     this.LoadData();
     stepper.next();
    
    
  }

  onDelete(data, _flag){

  }
   changeMode() {
    console.log('Mode changed:');
    // Your logic here
    let query;
    if (this.Mode!='WPS')
      {
        query= `cid=${101}&TableName=${'LedgerMaster'}&_FormType=${'LedgerAccount'}&_Condition=${"Category='"+this.Mode+"'"}&_MenuID=${'""'}&_WithInActive=${'true'}`;
        
      }
      else{
       query = `cid=${101}&TableName=${'WPSMaster'}&_FormType=${'""'}&_Condition=${'""'}&_MenuID=${'""'}&_WithInActive=${'false'}`;
        
      }
    // console.log("filter",params)
    this.service.httpGet('/General/LoadMCCBWithLedger?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("LedgerMaster",result.respdata)
      
       this.LedgerType=result.respdata
      // this.showSpinner=true;
      // setTimeout(() =>{
      //   this.showSpinner=false;
      // },
      // 800)  
     
        //this.dataSource = result.respdata.Table;   
        //this.dataSource1 = result.respdata.Table; 
      
         
    
       
    });
  }

   Filter()
  {


    const dialogRef = this.dialog.open(PayslippaymentfilterComponent, {
      width: '35%', // Set the width of the dialog
      height:'100%',
      position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        //qtnstatus:this.qtnstatus,
        fromdate:this.fromdate,
        todate:this.todate,
        typevisible:false,
        categoryvisible:false,
        employeevisible:false,
        DateTypeFlag:this.DateTypeFlag
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
        // this.qtnstatus=result.Status
        this.cid=result.CID
        this.fromdate=result.FromDate
        this.todate=result.ToDate
        this.DateTypeFlag=result.DateTypeFlag,
        this.PageLoad()
      }
     
    });
   // this.LoadData();
  }
  Populate()
  {
    
    const dialogRef = this.dialog.open(PayslippaymentfilterComponent, {
      width: '30%', // Set the width of the dialog
      height:'85%',
      position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        LedgerID:this.EmpID,
        EmpName:this.EmpName,
        Category:this.Category,     
        Type:this.PSType,
        DateTypeFlag:this.DateTypeFlag,
        fromdate:this.fromdate,
        todate:this.todate,
        typevisible:true,
        categoryvisible:true,
        employeevisible:true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
        // this.qtnstatus=result.Status
        this.cid=result.CID
        this.EmpID=result.LedgerID
        this.EmpName=result.Name
        this.Category=result.Category
        this.PSType=result.Type
        this.DateTypeFlag=result.DateTypeFlag
        this.fromdate=result.FromDate
      // const  dateObj= new Date(result.ToDate); 
        this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
        this.LoadData()
      }
     
    });
    //this.LoadData();
  }

  Submit()
  {

    let dt =this.dataSource.filter(item => item.FullPay==true)

    let dt1 = dt.map(item => {
      const { ID, ...rest } = item;
      return rest;
  });

    const params = {
      int_CID: this.cid,
      dt_Main:  dt1, //this.dataSource,
      str_Type:'PSP-'+this.Mode,
      str_VoucherNo:this.Prefix +this.VoucherNo,
      str_FormPrefix:this.Prefix,
      str_MenuID:'ERP_224',
      str_LedgerID:this.LedgerID,
      int_RevNo:0,
      str_Flag:this.flag,
      dtp_VoucherDate:this.datePipe.transform(this.VouDate,"MM-dd-yyyy") ,
      str_Comment:'',
      int_BusinessPeriodID:105,
      str_CreatedBy:'AMJATH',
      dtp_CreatedDate:this.datePipe.transform(new Date(),"MM-dd-yyyy HH:mm:ss"),
      str_LastUpdatedBy:'AMJATH',
      dtp_LastUpdatedDate:this.datePipe.transform(new Date(),"MM-dd-yyyy HH:mm:ss")
      
      
    };   

    console.log("filter",params)
    this.service.httpPost('/Emppayslippayment/SetEmpPaySlipPayment?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      if (result.errno === 0)
        {
          this.NotificationService.success("Posting Successfully");
        }
        else
        {
          this.NotificationService.warn(result.errdesc);
        }
         
    
       
    });
  }

  LoadData()
  {

    this.dataSource=''
    this.showSpinner=true;
    const params = {
      int_CID: this.cid,
      str_Flag:this.flag,
      str_VoucherNo:this.Prefix+this.VoucherNo,
      str_EmpID: this.EmpID,
      str_Category:this.Category,
      str_PSType:this.PSType,
      str_FrmDate:this.datePipe.transform(this.fromdate,"MM-dd-yyyy")  ,
      //str_ToDate:this.todate
      // str_FrmDate:this.datePipe.transform('2023-01-01',"MM-dd-yyyy")  ,
       str_ToDate:this.datePipe.transform( this.todate,"MM-dd-yyyy")
      
    };   

    console.log("filter",params)
    this.service.httpPost('/Emppayslippayment/GetEmpPaySlipPayment?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("mm",result.respdata)
      if (result.respdata!==null)
        {
          this.dataSource=result.respdata.dt_Main
          if(this.flag=='EDIT')
           {
             this.LedgerID=result.respdata.str_LedgerID;
             if(result.respdata.str_Type=='BANKTRANSACTION')
               {
                 this.Mode='BANK';
               }
               else{this.Mode=result.respdata.str_Type;}
               this.changeMode();
           
             this.VouDate=result.respdata.dtp_VoucherDate;
           }
        }
       
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
     
        //this.dataSource = result.respdata.Table;   
        //this.dataSource1 = result.respdata.Table; 
      
         
    
       
    });
  }

  PageLoad()
  {

    this.showSpinner=true;
    const params = {
      cid: this.cid,
      MenuID:'ERP_223',
      Date1:this.DateTypeFlag,
      FromDate:this.datePipe.transform(this.fromdate,"MM-dd-yyyy")  ,
      ToDate:this.datePipe.transform( this.todate,"MM-dd-yyyy")
    };   

    
    const query = `cid=${params.cid}&MenuID=${params.MenuID}&Date1=${params.Date1}&FromDate=${params.FromDate}&ToDate=${params.ToDate}`;


    this.service.httpGet('/Emppayslippayment/GetPaymentPosting?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("mm",result.respdata)
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
     
        this.data1 = new MatTableDataSource(result.respdata.PaymentLedger);  
        this.data1.sort = this.sort;
        this.data1.paginator = this.paginator;
        this.Prefixlist= result.respdata.Prefix; 

        this.NetAmt = result.respdata.PaymentLedger.reduce((acc, obj) => acc + obj.NetAmount, 0).toFixed(2);;
        this.PaidAmt= result.respdata.PaymentLedger.reduce((acc, obj) => acc + obj.PaidAmount, 0).toFixed(2);;



        // this.dataSource1 = result.respdata.Table; 
        // this.columnNames=result.respdata.columnNames;
        // console.log("columnNames",this.columnNames)
       

        console.log("Prefixlist",this.Prefixlist)
        //this.Type=this.Typelist[0].VouNo;
        this.Prefix=this.Prefixlist[0].Prefix;
    
       
    });
  }

  // dataSource: any[] = [
  //   { id: 1, name: 'John', age: 30 },
  //   { id: 2, name: 'Jane', age: 25 },
  //   { id: 3, name: 'Doe', age: 40 }
  // ];

  columns: any[] = [
    { dataField: 'id', caption: 'ID' },
    { dataField: 'name', caption: 'Name' },
    { dataField: 'age', caption: 'Age' }
  ];

  handleRowUpdating(event: any) {
    // Handle row updating logic here
    console.log(event);
  }

}
