import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-typeofissue',
  templateUrl: './typeofissue.component.html',
  styleUrls: ['./typeofissue.component.scss'],
})
export class TypeofissueComponent implements OnInit {
  cid:any;
  Flag:any;
  TOIID:any;
  CurLedgerID:any;
  clientlist:any;
  description:any;
  Status:any;
  ID:any;
  
  typeFormGroup: FormGroup;

  Statuslist=[
    {id: 0, name: 'Active'},
    {id: 1, name: 'Locked'}
    
  ];

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<TypeofissueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.TOIID=this.data.TOID;
      this.ID=this.data.ID;
      this.Status=this.data.Status;
      this.description=this.data.Description
      this.clientlist=this.data.client;

      this.typeFormGroup = this.formBuilder.group({  
        fcClient:['', Validators.required],
        fcStatus:['', Validators.required],   
        fcDescription:['']   
      }); 

      if(this.Flag=='EDIT')
      {
        this.typeFormGroup.controls.fcClient.setValue(this.ID);
        this.typeFormGroup.controls.fcStatus.setValue(this.Status);
        this.typeFormGroup.controls.fcDescription.setValue(this.description);
      }


    }

  ngOnInit() 
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
  Submit()
  {
    let params = { 
      cid:this.cid,
      flag: this.Flag, 
      TOIID:this.TOIID,   
      Client:this.typeFormGroup.get('fcClient').value,         
      Status:  this.typeFormGroup.get('fcStatus').value,   
      Description: this.typeFormGroup.get('fcDescription').value,
      UserID:this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')
      
      
     };
    this.service.httpPost('/TypeOfIssue/SetTypeOfIssue',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno !== 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
