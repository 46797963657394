import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FullComponent } from './LandingPageComponents/full/full.component';
import { LogInComponent } from './log-in/log-in.component';

const routes: Routes = [
  // {
  //   path: '',
  //   //loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  //   loadChildren: () => import('./log-in/log-in.module').then(m => m.LogInComponentModule),
    
  // }
  {
    path: '',
    component: LogInComponent
  },
  {
    path: 'Full',
    component: FullComponent    
  },
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
