import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild,EventEmitter  } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


export interface TableColumn {
  header: string;
  field: string;
  width?: string;
  readOnly?: boolean;
}
export interface ActionEvent {
  icon: string;
  tooltip: string;
  flag: string;
  color:string;
}

export class Group {
  level = 0;
  parent: Group;
  expanded = false;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-dynamic-table-component',
  templateUrl: './dynamic-table-component.component.html',
  styleUrls: ['./dynamic-table-component.component.scss'],
})

export class DynamicTableComponentComponent implements AfterViewInit {

  @Input() columns: TableColumn[] = [];
  @Input() dataSource: any[] = [];
  @Input() Actionlist:ActionEvent[]=[];
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
  tableDataSource: MatTableDataSource<any | Group> = null;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = [];
  groupByColumns: string[] = [];

 
  




  // dataSource = [
  //   { name: 'John', age: 30 },
  //   { name: 'Doe', age: 25 }
  //   // Add more data as needed
  // ];
  constructor(private changeDetectorRef: ChangeDetectorRef) {
   
    console.log('dunamic55',this.dataSource)
    // this.tableDataSource = new MatTableDataSource<any>(this.dataSource);
    // // this.EmployeeList = new MatTableDataSource(result.respdata.Table);
    // console.log('dunamic',this.tableDataSource)
    // this.tableDataSource.sort = this.sort;
    // this.tableDataSource.paginator = this.paginator;
  }
  ngAfterViewInit() {
    console.log('dunamic44',this.dataSource)

    this.tableDataSource = new MatTableDataSource<any | Group>(this.dataSource);

    this.tableDataSource.paginator = this.paginator;
    this.tableDataSource.sort = this.sort;

    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges() {
    if (this.tableDataSource) {
      this.tableDataSource.data=[];
      this.tableDataSource.data = this.dataSource;
      this.getColumnIds()
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    }
  }
  getColumnIds() {
    this.displayedColumns=[];
    //  console.log('dunamic',[this.columns.map(column => column.field)])
     this.displayedColumns.push(...this.columns.map(column => column.field));
     this.displayedColumns.push('Action');
    // return this.columns.map(column => column.field);
   // return [this.columns.map(column => column.field)];
  }
  
  getColumnIds1() {
    this.displayedColumns=[];
    //  console.log('dunamic',[this.columns.map(column => column.field)])
     this.displayedColumns.push('Name');
    //  this.displayedColumns.push('Action');
    // return this.columns.map(column => column.field);
   // return [this.columns.map(column => column.field)];
  }

  onClick(value,flag)
  {
    console.log('value',flag)
    this.buttonClicked.emit({ flag, value });
   
  }


  
  // groupBy(column: string) {
    
  //   // Implement your logic to group data by selected column
  //   // This is just a placeholder, you need to implement your own logic
  //   const groupedData = this.groupDataByColumn(this.dataSource, column);

  //   // Assign the grouped data to the MatTableDataSource
  //   this.tableDataSource.data=[];
  //   this.getColumnIds()
  //   this.tableDataSource = new MatTableDataSource<any>(groupedData);
  //   // this.tableDataSource.data = groupedData;
   

  //   console.log('groupedData',this.tableDataSource.data )

  //   // this.tableDataSource.paginator = this.paginator;
  //   // // Ensure the sort is updated to reflect the new data
  //   // this.tableDataSource.sort = this.sort;
  //   // Trigger change detection to update the view
  //   this.changeDetectorRef.detectChanges();
  // }

  // private groupDataByColumn(data: any[], column: string): any[] {
  //   console.log('data',data)
  //   // Implement your logic to group data by selected column
  //   // This is just a placeholder, you need to implement your own logic
  //   const groupedData = data.reduce((acc, curr) => {
  //     const key = curr[column];
  //     acc[key] = acc[key] || [];
  //     acc[key].push(curr);
  //     return acc;
  //   }, {});



  //   // return Object.values(groupedData);
  //   return Object.keys(groupedData).map(key => ({ key, values: groupedData[key] }));
  // }

  groupBy(event, column: any[]) {
    //event.stopPropagation();
    console.log('column.field',column[0].field)
    this.checkGroupByColumn(column[0].field, true);
    console.log('this.groupByColumns',this.groupByColumns)
     this.tableDataSource.data = this.addGroups(this.dataSource, this.groupByColumns); 
     this.tableDataSource.filterPredicate = this.customFilterPredicate.bind(this);
    //console.log('amjath', this.tableDataSource )
    //console.log('this.dataSource.data', this.tableDataSource);
    this.tableDataSource.filter = performance.now().toString();
    //this.tableDataSource.data = [...this.tableDataSource.data];
    // this.changeDetectorRef.detectChanges();
  }
  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }
  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }
  getSublevel(
    data: any[],
    level: number,
    groupByColumns: string[],
    parent: Group
  ): any[] {
    //console.log('groupByColumns',groupByColumns)
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
       // console.log('data33',result)
        return result;
      }),
      JSON.stringify
    );
    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach((group) => {
      const rowsInGroup = data.filter(
        (row) => group[currentColumn] === row[currentColumn]
      );
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(
        rowsInGroup,
        level + 1,
        groupByColumns,
        group
      );
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
    
  }
    uniqueBy(a, key) {
      const seen = {};
      return a.filter((item) => {
        const k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
      });
    }

    groupHeaderClick(row: any | Group) {
      console.log("Before toggle:", row);
      row.expanded = !row.expanded;
      console.log("After toggle:", row);
  
      // Trigger a refresh of the MatTableDataSource
      this.tableDataSource.data = [...this.tableDataSource.data];
      console.log("Updated data:", this.tableDataSource.data);
    }
    

    customFilterPredicate(data: any | Group, filter: string): boolean {
      return data instanceof Group ? data.visible : this.getDataRowVisible(data);
    }
  
    getDataRowVisible(data: any): boolean {
      const groupRows = this.tableDataSource.data.filter((row) => {
        if (!(row instanceof Group)) {
          return false;
        }
        let match = true;
        this.groupByColumns.forEach((column) => {
          if (!row[column] || !data[column] || row[column] !== data[column]) {
            match = false;
          }
        });
        return match;
      });
  
      if (groupRows.length === 0) {
        return true;
      }
      const parent = groupRows[0] as Group;
      return parent.visible && parent.expanded;
    }
  
    // customFilterPredicate(data: any | Group, filter: string): boolean {
    //   return data instanceof Group ? data.visible : this.getDataRowVisible(data);
    // }
  
    // getDataRowVisible(data: any): boolean {
    //   const groupRows = this.tableDataSource.data.filter((row) => {
    //     if (!(row instanceof Group)) {
    //       return false;
    //     }
    //     let match = true;
    //     this.groupByColumns.forEach((column) => {
    //       if (!row[column] || !data[column] || row[column] !== data[column]) {
    //         match = false;
    //       }
    //     });
    //     return match;
    //   });
  
    //   if (groupRows.length === 0) {
    //     return true;
    //   }
    //   const parent = groupRows[0] as Group;
    //   return parent.visible && parent.expanded;
    // }

  isGroup(index, item): boolean {
    return item.level;
  }
}

