
import { TimepunchreportComponent } from '../../timepuchreport/timepunchreport/timepunchreport.component'
import { Component, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder,AbstractControl } from '@angular/forms';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GenericService } from '../../../../Services/OtherServices/generic.service';
import { UserService } from '../../../Admin/Users/userValidate';
import { DatecontrolComponent } from '../../DateControl/datecontrol/datecontrol.component';
import { DialogtimepunchleavepostingComponent } from '../../LeaveApproval/dialogtimepunchleaveposting/dialogtimepunchleaveposting.component';


export interface User {
  Name: string;
}

@Component({
  selector: 'app-timepunchleaveposting',
  templateUrl: './timepunchleaveposting.component.html',
  styleUrls: ['./timepunchleaveposting.component.scss'],
})
export class TimepunchleavepostingComponent implements OnInit {
  @ViewChild('stepper') stepper;
  employeelist: any;
  companies: any;
  category: any;
  SubCategory: any;
  AbsentGridData:any;
  datasource: MatTableDataSource<GroupALL>;
  ledgerid= 0;
  Categoryid= 0;
  CID: any;
  searchKey: string;
  mobileQuery: MediaQueryList;
  navbarOpen = true;
   date= new Date();
  frmdate= new Date();
  todate1= new Date();
  DateType= 'CurrentDate';
  filterOption: any;
  frmtext= 'Current Date';
  totext: any;
  todatevisible= false;
  fromdatevisible= true;
  flag:any;
  filteredOptions: Observable<string[]>;
  colorCtr: AbstractControl = new FormControl(null);
  myControl = new FormControl();
  empid:string[]=[];
  isEditable = true; 
  TimepunchGroup : FormGroup;
  TimepunchDataGroup: FormGroup;
  AbsentDataGroup: FormGroup;
  cid:any;
  showSpinner:any;
  toggle= 'filter_list';
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"
  addbtnvisible: boolean = false;
  viewbtnvisible: boolean = false;
  displayedColumns =['Date','EmpID','EmpName','Department','Category','SubCategory','LeaveType','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private formBuilder: FormBuilder,private datePipe: DatePipe, private service: HttpserviceService, 
    private generic: GenericService, private service1: UserService,
    private NotificationService: NotificationService, private dialog: MatDialog)
   {

    this.TimepunchGroup = this.formBuilder.group({  
          fcCompany: ['', Validators.required] ,             
          fcDateType: [''],     
          fcCategory: [''], 
          fcSubCategory: [''],     
          fcDate: ['', Validators.required]
           
      });
         this.TimepunchDataGroup = this.formBuilder.group({                 
      });
   }

   ngOnInit(): void {
  
  this.cid=JSON.parse(sessionStorage.getItem('cid'));   
    
    this.flag="PAGELOAD";
    this.gettimepunchreport(); 
    this.TimepunchGroup.controls.fcCompany.setValue(this.cid);    
    this.TimepunchGroup.controls.fcDateType.setValue("CurrentDate");
    
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.myControl.setValue({Name: 'ALL'});
    this.TimepunchGroup.controls.fcCategory.setValue(0);
    this.TimepunchGroup.controls.fcSubCategory.setValue(0);

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | User>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) : this.empid.slice())
      );
  
  }
 
  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(DatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }
  



  changesite(e) {
    this.cid=e.value;

    this.flag="PAGELOAD"
    this.employeelist='';
    this.empid=[];
    this.gettimepunchreport();
    this.TimepunchGroup.controls.fcCompany.setValue(this.cid);    
    this.TimepunchGroup.controls.fcDateType.setValue("CurrentDate");
   // this.TimepunchGroup.controls.fcFromDate.setValue(this.frmdate);
   this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
   this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.myControl.setValue({Name: 'ALL'});
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | User>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) : this.empid.slice())
      );
      this.DateTypeFlag="Today";

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  displayFn(empid?: User): string | undefined {
    return empid ? empid.Name : undefined;
  }
  selectedproduct(event) {    
    this.ledgerid=event.option.value;   
    this.myControl.setValue({Name: event.option.value }); 
    this.ledgerid=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));     
  }
  private _filter(name: string): string[] {

    const filterValue = name.toLowerCase();   
      return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  Sync(){
    let params = {
      cid: this.cid     
    }
    this.service.httpPost('/timepunchreport/TimepunchSync',params)
    .subscribe (
      (data) => {
        let result: any;
        result = data;         
        if (result.errno === 0) {       
          this.NotificationService.success(result.errdesc);        
          this.Refresh();      
        }
        else {
          this.NotificationService.warn(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });
  }

  addRow()
  {
  
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus =  true;
     dialogConfig.data = {
       flag: 'ADD',
       ledgerid: 0,
       date: this.date,
       employeelist: this.employeelist,
       CID:this.CID
     };
     const dialogRef = this.dialog.open(TimepunchreportComponent, dialogConfig);
     dialogRef.afterClosed().subscribe(result => {     
      this.Refresh();   
    });
  }
 
  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_111",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;   
      this.viewbtnvisible = btnVisilityData.viewflag;                     
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }
  
  onFocusedRowChanged(e)
  {   
     // console.log('grid',e.data)
      if(e.data.LeaveType=="" ){
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus =  true;
            dialogConfig.data = {
              flag: "ADD",
              ledgerid:e.data.LedgerID,              
              cid: this.cid,
              //date:this.datePipe.transform(this.TimepunchGroup.get('fcDate').value,"MM/dd/yyyy"),     
              date:this.TimepunchGroup.get('fcDate').value,     
              type: 1,
              requestyear: new Date().getFullYear(),
          }
            const dialogRef = this.dialog.open(DialogtimepunchleavepostingComponent, dialogConfig); 
            dialogRef.afterClosed().subscribe(result => {     
              this.Refresh();     
            });   
    }   
  }
  
  PostLeave(event)
  {
    //console.log('HI',event)
        if(event.LeaveType=="" ){
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {
            flag: "ADD",
            ledgerid:event.LedgerID,              
            cid: this.cid,       
            date:this.TimepunchGroup.get('fcDate').value,     
            type: 1,
            requestyear: new Date().getFullYear(),
        }
          const dialogRef = this.dialog.open(DialogtimepunchleavepostingComponent, dialogConfig); 
          dialogRef.afterClosed().subscribe(result => {     
            this.Refresh();     
          });   
       }   
  }

  PostTimepunch(event)
  {
   // console.log('HI',event)
    if(event.LeaveType=="" ){
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {
            flag: 'EDIT',
            ledgerid: event.LedgerID,
            date: this.date,
            employeelist: this.employeelist,
            Name: event.EmpID +' - '+ event.EmpName,
            CID:this.CID
          };
          const dialogRef = this.dialog.open(TimepunchreportComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {     
          this.Refresh();   
        });
    }
  }
  
  Refresh()
  {
    if (this.TimepunchGroup.invalid) {
      this.NotificationService.warn('Please select timepunch date');     
      return;
      }   
    this.showSpinner=true;
      let params = {     
        cid:this.TimepunchGroup.get('fcCompany').value,
        Flag: 'LOADABSENTEES',      
        LedgerID: this.ledgerid,
        Category: this.TimepunchGroup.get('fcCategory').value,
        SubCategory: this.TimepunchGroup.get('fcSubCategory').value,
        FromDate:  this.datePipe.transform(this.TimepunchGroup.get('fcDate').value,"dd-MM-yyyy"),
        ToDate:  this.datePipe.transform(this.TimepunchGroup.get('fcDate').value,"dd-MM-yyyy"),   
    };
    this.service.httpPost('/timepunchleaveposting/GetTimePuchLeavePosting',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;          
          if (result.errno === 0) {
            this.stepper.selectedIndex = 1;    
          //  this.AbsentGridData = result.respdata.Table;

            this.AbsentGridData = new MatTableDataSource<GroupALL>(result.respdata.Table);
            this.AbsentGridData.sort = this.sort;
            this.AbsentGridData.paginator = this.paginator;
            
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  exportAsExcel()
  {
    this.generic.excelExport(this.AbsentGridData.data, 'Missing Time Punch', 'Missing Time Punch' );
  }

  applyFilter1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.AbsentGridData.filter = filterValue.trim().toLowerCase();
  }

  gettimepunchreport(){

    let  params
    this.showSpinner=true;
  if(this.flag=="PAGELOAD")  
  {
    params= {
         
       cid: this.cid,
     Flag: this.flag,
     Date: this.datePipe.transform(this.frmdate, 'dd-MM-yyyy'),
     LedgerID: this.ledgerid,
     Category: this.Categoryid,
     SubCategory: '',
     DateType: this.DateType,
    CurrentMonth: this.datePipe.transform(this.frmdate, 'MM'),
     CurrentYear: this.datePipe.transform(this.frmdate, 'yyyy'),
     Month: '',
    FromDate: this.datePipe.transform(this.frmdate, 'dd-MM-yyyy'),
     ToDate: this.datePipe.transform(this.todate1, 'dd-MM-yyyy'),
     MonthYear: '',
     JoinType: '',
     LeaveID: '0'
    }
  }
  else
  {
    params=
    {            
        cid: this.cid,
        Flag: this.flag,
         Date: this.fromdate,
        LedgerID: this.ledgerid,
        Category: this.TimepunchGroup.get('fcCategory').value,
        SubCategory: this.TimepunchGroup.get('fcSubCategory').value,
        DateType: "ByDate",
        CurrentMonth:this.fromdate,
        CurrentYear: this.fromdate,
        Month: '',
        FromDate: this.fromdate,
        ToDate: this.todate, 
       // this.datePipe.transform(this.TimepunchGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.TimepunchGroup.get('fcToDate').value,"dd-MM-yyyy"),
        MonthYear: '',
        JoinType: '',
        LeaveID: '0'
  }

 };
        this.service.httpPost('/timepunchreport/GetTimePuchOTReport',params)
        .subscribe(data => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;
          if (result.errno === 0){
            if (this.flag=="PAGELOAD")
            {
              this.companies = result.respdata.Table;
              this.CID = this.cid;
              this.category = result.respdata.Table1;
              this.employeelist = result.respdata.Table2;
              this.empid= _.pluck(this.employeelist, 'Name');

              this.SubCategory = result.respdata.Table5;
              // this.datasource = result.respdata.Table3;
              // this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Table3);
              // this.datasource.sort = this.sort;
              // this.datasource.paginator = this.paginator;
                
              this.getUserList();
            }
            else
            {
              this.datasource = result.respdata.Table;
              this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Table);
              this.datasource.sort = this.sort;
              this.datasource.paginator = this.paginator;
              this.stepper.selectedIndex = 1;       
              this.getUserList();
            }
           
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
      }

      onSearchClear()
      {
        this.flag="PAGELOAD";
        this.TimepunchGroup.reset(); 
        this.myControl.setValue({Name: 'ALL'});
        this.TimepunchGroup.controls.fcCompany.setValue(this.cid);        
        this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
        this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
        this.DateTypeFlag="Today";
        this.stepper.selectedIndex = 0; 
        this.datasource = new MatTableDataSource<GroupALL>([])
        this.TimepunchGroup.controls.fcCategory.setValue(0);
        this.TimepunchGroup.controls.fcSubCategory.setValue(0);
        this.ledgerid=0;
      }



}

export interface GroupALL {
  EMPID: string;
  EmpName: string;
  Category: string;


}