import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { FormGroup, FormControl, Validators, FormBuilder,AbstractControl } from '@angular/forms';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/Modules/user';
import { CrmdatecontrolComponent } from '../../CRMDateControl/crmdatecontrol/crmdatecontrol.component';

export interface User1 {
  Name: string;
}
@Component({
  selector: 'app-payslippaymentfilter',
  templateUrl: './payslippaymentfilter.component.html',
  styleUrls: ['./payslippaymentfilter.component.css']
})
export class PayslippaymentfilterComponent {


  ledgerid= 0;
  empname:any;
  employeelist: any;
  categorylist: any;
  Typelist:any;
  empid:string[]=[];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  companies:any= this.curUser.dtsiteid;
  CID:any=JSON.parse(sessionStorage.getItem('cid'));
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"
  Category:any;
  Type:any;
  typevisible:boolean=false;
  categoryvisible:boolean=false;
  employeevisible:boolean=false;

  constructor(public dialogRef: MatDialogRef<PayslippaymentfilterComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private service:HttpserviceService,private datePipe: DatePipe,private dialog: MatDialog) 
  {

    console.log('data',data)

    this.CID=this.data.cid;
    this.Type=this.data.Type;
    this.typevisible=this.data.typevisible;
    this.categoryvisible=this.data.categoryvisible;
    this.employeevisible=this.data.employeevisible;

    this.Category=this.data.Category;
    this.ledgerid=this.data.LedgerID;
    this.DateTypeFlag=this.data.DateTypeFlag;
    this.fromdate=this.data.fromdate;
    this.todate=this.data.todate;
    this.myControl.setValue(this.data.EmpName)

    this.LoadData()
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | User1>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) : this.empid.slice())
      );
   }


   DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.position={ right: '15'};
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        
      }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      

      const parts = result.todate.split('-');
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // JavaScript months are 0-based
      const year = parseInt(parts[2]);

      const dateObj = new Date(year, month, day);
      const formattedDate = this.datePipe.transform(dateObj, 'yyyy-MM-dd');


      const parts1 = result.frmdate.split('-');
      const day1 = parseInt(parts1[0]);
      const month1 = parseInt(parts1[1]) - 1; // JavaScript months are 0-based
      const year1 = parseInt(parts1[2]);

      const dateObj1 = new Date(year1, month1, day1);
      const formattedDate1 = this.datePipe.transform(dateObj1, 'yyyy-MM-dd');

      this.fromdate=formattedDate1;
      this.todate= formattedDate; 
      this.DateTypeFlag=result.datetype
    });
  }

  LoadData()
  {

    // const params = {
    //   int_CID: this.cid,
      
      
    // };   

    const query = `cid=${101}&flag=${'tds'}`;
   // console.log("filter",params)
    this.service.httpGet('/General/GetHRMSFilter?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("mm",result.respdata)
      this.employeelist=result.respdata.Employeelist;
      this.categorylist=result.respdata.Categorylist;
      this.Typelist=result.respdata.PSTType;
      this.employeelist = _.reject(result.respdata.Employeelist, { 'Name': 'ALL' });
      this.empid= _.pluck(this.employeelist, 'Name');

      // this.showSpinner=true;
      // setTimeout(() =>{
      //   this.showSpinner=false;
      // },
      // 800)  
     
        //this.dataSource = result.respdata.Table;   
        //this.dataSource1 = result.respdata.Table; 
      
         
    
       
    });
  }

  selectedproduct(event) {    
    console.log('sele',{Name: event.option.value })
    this.ledgerid=event.option.value;   
    this.myControl.setValue({Name: event.option.value }); 
    this.empname=event.option.value ;
    this.ledgerid=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));  
    
    console.log('leee',this.ledgerid)
  }
  private _filter(name: string): string[] {

    const filterValue = name.toLowerCase();   
    console.log('empid',this.empid)
      return this.empid.filter(option => option.toLowerCase().includes(filterValue)); 
        
  }
  displayFn(empid?: User1): string | undefined {
    return empid ? empid.Name : undefined;
  }

  applyFilter() {

   // console.log('displayFn',this.myControl.value)
    // Implement filter logic here
    // You may use a service to communicate with the parent component or emit an event
    let param={'Type': this.Type,'Category':this.Category,'LedgerID':this.ledgerid,'Name':this.myControl.value,'DateTypeFlag': this.DateTypeFlag,'FromDate':this.fromdate,'ToDate':this.todate,'CID':this.CID}
    this.dialogRef.close(param);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
