import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { PayslippaymentfilterComponent } from '../../EmpPayslipPayment/payslippaymentfilter/payslippaymentfilter.component';
import { TableColumn } from 'src/app/components/HR/dynamicTable/dynamic-table-component/dynamic-table-component.component';
import { EmpsalaryhikepostingComponent } from '../empsalaryhikeposting/empsalaryhikeposting.component';

@Component({
  selector: 'app-empsalaryhike',
  templateUrl: './empsalaryhike.component.html',
  styleUrls: ['./empsalaryhike.component.scss'],
})
export class EmpsalaryhikeComponent implements OnInit {

  @Input() flag_:any;
  columnsData: TableColumn[] = [];
  //   { header: 'Name', field: 'Name', width: '100px', readOnly: true },
  //   { header: 'EmpID', field: 'EmpID', width: '50px' },
  //   { header: 'Category', field: 'Category', width: '300px' }
  // ];

  tableData:any;
  Actionlist=[{icon:'edit_outline',tooltip:'edit',flag:'EDIT',color:'primary'},
  {icon:'delete',tooltip:'Delete',flag:'DELETE',color:'warn'},
  //{icon:'add_photo_alternate',tooltip:'VIEW',flag:'View',color:'primary'}
  ]

  // tableData = [
  //   { name: 'John', age: 30 ,address:'chennai'},
  //   { name: 'Doe', age: 25,address:'chennai' },
  //   { name: 'Doe1', age: 25 ,address:'chennai'},
  //   { name: 'Doe2', age: 25,address:'chennai' },
  //   { name: 'John', age: 30 ,address:'chennai'},
  //   { name: 'Doe', age: 25 ,address:'chennai'},
  //   { name: 'Doe1', age: 25 ,address:'chennai'},
  //   { name: 'Doe2', age: 25 ,address:'chennai'},
  //   { name: 'John', age: 30 ,address:'chennai'},
  //   { name: 'Doe', age: 25 ,address:'chennai'},
  //   { name: 'Doe1', age: 25 ,address:'chennai'},
  //   { name: 'Doe2', age: 25 ,address:'chennai'}
  // ];

  showSpinner:any;
  cid:any=101;
  flag:any="ADD";
  LedgerID:any=0;
  fromdate:any;
  todate:any;
  dataSource:any;
  TotEaring:any=0;
  TotDeduct:any=0;
  TotAmount:any=0;
  TotAbsAmt:any=0;
  EmpID:any=0;
  EmpName:any='';
  Category:string='--ALL--'
  PSType:string='PST/1001'
  DateTypeFlag:any;
  displayMode:any = "compact";
 allowedPageSizes = [5, 10, 'all'];
  showPageSizeSelector = true;
  constructor(private service:HttpserviceService,private datePipe: DatePipe,private dialog: MatDialog,private NotificationService: NotificationService) 
  { 
    this.fromdate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
    this.todate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
  }

  ngOnInit() 
  {}
  add()
  {

    const dialogRef = this.dialog.open(EmpsalaryhikepostingComponent, {
      width: '90%', // Set the width of the dialog
      height:'85%',
      //position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        VouNo:'',
        flag:'ADD',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
      //   // this.qtnstatus=result.Status
      //   this.cid=result.CID
      //   this.EmpID=result.LedgerID
      //   this.EmpName=result.Name
      //   this.Category=result.Category
      //   this.PSType=result.Type
      //   this.DateTypeFlag=result.DateTypeFlag
      //   this.fromdate=result.FromDate
      // // const  dateObj= new Date(result.ToDate); 
      //   this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
      //   this.LoadData()
      }
     
    });

  }
  Filter()
   {
    const dialogRef = this.dialog.open(PayslippaymentfilterComponent, {
      width: '30%', // Set the width of the dialog
      height:'85%',
      position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        LedgerID:this.EmpID,
        EmpName:this.EmpName,
        Category:this.Category,     
        Type:this.PSType,
        DateTypeFlag:this.DateTypeFlag,
        fromdate:this.fromdate,
        todate:this.todate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
        // this.qtnstatus=result.Status
        this.cid=result.CID
        this.EmpID=result.LedgerID
        this.EmpName=result.Name
        this.Category=result.Category
        this.PSType=result.Type
        this.DateTypeFlag=result.DateTypeFlag
        this.fromdate=result.FromDate
      // const  dateObj= new Date(result.ToDate); 
        this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
        this.LoadData()
      }
     
    });
   }

   LoadData()
   {
 
     this.showSpinner=true;
     const params = {
      int_CID: this.cid,
      str_VouNo:'ERP_225',
      dtp_VoucherDate:this.datePipe.transform(this.fromdate,"MM-dd-yyyy")  ,
      CreatedDate:this.datePipe.transform( this.todate,"MM-dd-yyyy"),
      str_Tag:this.DateTypeFlag ,// this.DateTypeFlag,
      str_Flag:'PageLoad'
      
     };   
 
     
    // const query = `cid=${params.cid}&MenuID=${params.MenuID}&Date1=${params.Date1}&FromDate=${params.FromDate}&ToDate=${params.ToDate}`;
 
 
     this.service.httpPost('/EmpSalaryHike/GetLoadCombo?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      
 
       console.log("mm",result.respdata)

      //  let LastRow = result.respdata.Table.filter(item => item.PSNo=='10000001');

      //  this.TotEaring=LastRow[0].TOTEAR;
      //  this.TotDeduct=LastRow[0].TOTDEDUT;
      //  this.TotAmount=LastRow[0].NSAL;
      //  this.TotAbsAmt=LastRow[0].AbsAmt;


      //  console.log("total",LastRow)

       this.showSpinner=true;
       setTimeout(() =>{
         this.showSpinner=false;
       },
       800)  
      
         this.dataSource = result.respdata.dt_Main; 

         console.log('dataSource',this.dataSource)
         this.columnsData=result.respdata.columnNames;
         //this.columnsData.push({ header: 'actions', field: 'actions', width: '100px' })
         this.tableData= result.respdata.dt_Main; 
        
         //this.handleChildClick();
        
        
     });
   }

   onButtonClicked(event: any) {
    console.log('Button clicked:', event.flag, event.value);
    // Here, you can handle the emitted event data as needed

    const dialogRef = this.dialog.open(EmpsalaryhikepostingComponent, {
      width: '90%', // Set the width of the dialog
      height:'85%',
      //position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        VouNo:event.value.VouNo,
        flag:event.flag,
      
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
      //   // this.qtnstatus=result.Status
      //   this.cid=result.CID
      //   this.EmpID=result.LedgerID
      //   this.EmpName=result.Name
      //   this.Category=result.Category
      //   this.PSType=result.Type
      //   this.DateTypeFlag=result.DateTypeFlag
      //   this.fromdate=result.FromDate
      // // const  dateObj= new Date(result.ToDate); 
      //   this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
      //   this.LoadData()
      }
     
    });
  }

}
