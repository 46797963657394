export class Column {
    constructor(public name: string, public id: string,public rCount:number, public tasks: Tasks[]) {}
  }

  export class Tasks {
    constructor(public subject: string, public ticketid: string,public Assignee:Assignee[]) {}
  }

  export class Assignee{
    constructor(public Assignee: string,public FirstName:string,public LastName:string) {}
  }