import { Component, OnInit } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {  Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-timesheetticket',
  templateUrl: './timesheetticket.component.html',
  styleUrls: ['./timesheetticket.component.scss'],
})
export class TimesheetticketComponent implements OnInit {

  TimeSheetTicketFormGroup:FormGroup;
   MyTask:any;

  constructor(public dialogRef: MatDialogRef<TimesheetticketComponent>,private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      console.log('open',this.data);
      this.MyTask=this.data.TaskList;

      this.TimeSheetTicketFormGroup = this.formBuilder.group({  
          
      });  
    }

  ngOnInit() {}


  onCloseDialog()
  {
    this.TimeSheetTicketFormGroup.reset();
    this.dialogRef.close();
  }

}
