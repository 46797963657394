import {Component, EventEmitter, HostBinding, Input, OnInit,Output,ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Menu } from '../../LandingPageComponents/menu-items';
import { TabsComponent } from '../../LandingPageComponents/tabs/tabs.component';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit{
  expanded: boolean=false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: Menu;
  @Input() depth: number;
  @Output() menuSelect: EventEmitter<any> = new EventEmitter();
  isMenuOpen=false;
 
  status = "Enable";
  //@ViewChild(TabsComponent) tabsComponent;
  tabcomponentObj:TabsComponent;


  constructor(public router: Router) {
    //  console.log('navitems menu :', this.item);
    if (this.depth === undefined) {
      this.depth = 0;
    }
    
  }

  ngOnInit() {
   
  }

  onClickChild(item:Menu) {
    this.menuSelect.emit(item);
}
toggleMenu() {
  this.isMenuOpen = !this.isMenuOpen; 
  this.status = this.isMenuOpen ? "Enable" : "Disable";
}

  onItemSelected(item: Menu) {
    if (!item.children || !item.children.length) {
       const menuIDMapping = [
        {menuid: 'ERP_103', path: 'usermanagement'},
        {menuid: 'ERP_104', path: 'groupmanagement'},
        {menuid: 'STS_92', path: 'projecteffort'},
        {menuid: 'STS_90', path: 'employeehierarchy'},
        {menuid: 'ERP_322', path: 'dashboardhook'},
        {menuid: 'STS_11', path: 'LeaveRequest'},
        {menuid: 'STS_21', path: 'LeaveApproval'},
        {menuid: 'ERP_128', path: 'Projectconfiguration'},
        {menuid: 'STS_54', path: 'TimePunchReport'},
        {menuid: 'STS_91', path: 'EffortApproval'},
        {menuid: 'STS_63', path: 'ProjectAllocationByWeek'},
        {menuid: 'STS_15', path: 'AttendanceReport'},
        {menuid: 'STS_77', path: 'empleavereport'},
        {menuid: 'STS_24', path: 'timesheet'},
        {menuid: 'STS_18', path: 'advancerequest'},
        {menuid: 'STS_23', path: 'ledgerstatement'},
        {menuid: 'STS_29', path: 'customsreport'},
        {menuid: 'STS_31', path: 'ticketreport'},
        {menuid: 'STS_27', path: 'monthlyreport'},
        {menuid: 'STS_13', path: 'hrpolicies'},
        {menuid: 'STS_6', path: 'documentcontrol'},
        {menuid: 'STS_86', path: 'goalscomponent'},
        {menuid: 'STS_8', path: 'EmpRegistration'},
        {menuid: 'STS_20', path: 'expenseposting'},
         {menuid: 'STS_94', path: 'empleaverequest'},
         {menuid: 'STS_95', path: 'training'},
         {menuid: 'STS_96', path: 'empattendancedetails'},
         {menuid: 'STS_5', path: 'LeaveTypemaster'},
         {menuid: 'STS_22', path: 'LeaveManagement'},
         {menuid: 'STS_70', path: 'ConfigParam'},
         {menuid: 'STS_62', path: 'EstimationWeeklyReport'},
         {menuid: 'STS_12', path: 'AuthMaster'},
         {menuid: 'STS_74', path: 'Type Of Issue'},
         {menuid: 'STS_72', path: 'Branch'},
         {menuid: 'STS_9', path: 'TimeZone'},
         {menuid: 'ERP_291', path: 'Itempricelist'},
         {menuid: 'ERP_156', path: 'neworderlist'},
         {menuid: 'STS_100', path: 'AssementGroup'},
         {menuid: 'STS_34', path: 'Status'},
         {menuid: 'STS_4', path: 'LeaveGroup'},
         {menuid: 'STS_45', path: 'Client'},
         {menuid: 'STS_30', path: 'EffortReport'},
         {menuid: 'STS_101', path: 'ConsolidateEffort'},
         {menuid: 'STS_26', path: 'EffortAuthorize'},
         {menuid: 'STS_203', path: 'TaskCreator'},
         {menuid: 'STS_41', path: 'Taskby'},
         {menuid: 'STS_206', path: 'EmployeeOnBoard'},
         {menuid: 'STS_207', path: 'ProjectAllocationByDay'},
      ]
      // console.log('menuid :', item.menuid.substring(0,7));
      var compName = menuIDMapping.filter(
        book => book.menuid === item.menuid.substring(0,7));
      
      // console.log('Compname :', compName);
        // if (item.menuid.substring(0,7) === 'ERP_322'){
        //   this.router.navigate(['dashboardhook'], {state: {compname: compName[0]['path'], dsname: item.parameters}});
        // }
        // else {
          //this.router.navigate([compName[0]['path']], {state: {data: item}});
          this.menuSelect.emit(item);
          
         //this.tabcomponentObj.openTab2('TEst',EmpAttReportViewComponent, {}, true);
        // }
        
      // this.router.navigate([compName[0]['path']], { queryParams: { order: item.parameters } });
      // this.router.navigate([compName[0]['path']], {state: {data: item.parameters}});
     
    } 
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
