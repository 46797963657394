import { DatePipe } from '@angular/common';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

interface ControlType {
  name: string;
}
interface Type {
  name: string;
}
interface Datasource {
  Value: string;
}
interface Datasource1 {
  Value: string;
  Value1: string;
}
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {

  FormGroup: FormGroup;
  visible:boolean=false;
  CID:any;
  Flag:any;
  Question:any;
  controltype:any;
  type:any;
  dateperiod:any;
  ID:any=0;

  dataSource: Datasource [];
  dataSource1: Datasource1 [];
  

  Controllist: ControlType[] = [
    {name: 'textBox'},
    {name: 'select'}
  ];
  Typelist: Type[] = [
    {name: 'Employee'},
    {name: 'Manager'}
  ];

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<QuestionComponent>,
    private datePipe: DatePipe,@Inject(MAT_DIALOG_DATA) public data: any) 
    {
        this.CID=this.data.cid;
        this.Flag=this.data.Flag;
        this.Question=this.data.question;
        this.controltype=this.data.controltype;
        this.type=this.data.type;
        this.dateperiod=this.data.dateperiod+'-01-01';
        this.ID=this.data.ID;

      this.FormGroup = this.formBuilder.group({  
        fcQuestions:['', Validators.required],     
        fcControlType:[''],
        fcDatePeriod:[''], 
        fcType:['']  
      }); 

      if (this.Flag=='ADD')
      {
        this.FormGroup.controls.fcControlType.setValue('textBox');
        this.FormGroup.controls.fcType.setValue('Employee');
        this.FormGroup.controls.fcDatePeriod.setValue(Date);
      }
      else
      {
        this.FormGroup.controls.fcQuestions.setValue(this.Question);
        this.FormGroup.controls.fcControlType.setValue(this.controltype);
        this.FormGroup.controls.fcType.setValue(this.type);
        this.FormGroup.controls.fcDatePeriod.setValue(this.dateperiod);
      }
     
     }

  ngOnInit()
   {
     this.dataSource =[];
     this.dataSource1 =[];
     if(this.Flag=='EDIT')
     {
      this.Loaddetails()
     }
     
   }
   onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
  logEvent(_flag,e)
  {
  }
  ControlType(event)
  {
    console.log("e",event);
    if(event.value=='select')
    {
      this.visible=true;
    }
    else{this.visible=false;}
  }
  Loaddetails()
     {
       let servicepath;
       let params = {
         cid:this.CID,
         flag:this.Flag,
         ID:this.ID,
         DatePeriod:this.datePipe.transform(this.dateperiod, 'dd-MM-yyyy')
          }
          
             this.service.httpPost('/Question/GetQuestion',params)
             .subscribe(data => {
               let result: any;
               result=data;
               if (result.errno === 0){
                
                   this.dataSource=result.respdata.List;
                  //  this.dataSource1=result.respdata.List;

                   if(this.dataSource.length>0)
                   {
                     this.visible=true;
                   }

    
               }
               else{
                 this.NotificationService.success(result.errdesc); //':: Problem details');
               }
             }, 
             (error: AppError) => {
               if (error instanceof BadInput)           
                 console.log(error);
               else throw error;       
             });
     }

  Submit()
  {
    

    if(this.dataSource.length>0)
    {
      for (var i = 0; i < this.dataSource.length; i++)
      {
        this.dataSource1.push({Value:this.dataSource[i].Value,Value1:this.dataSource[i].Value})
      }
    }
    else
    {
      this.dataSource1.push({Value:'',Value1:''});
    }
    
    let params = { 
      cid:this.CID,
      flag: this.Flag, 
      ID:this.ID,
      Questions:this.FormGroup.get('fcQuestions').value,
      ControlType:this.FormGroup.get('fcControlType').value,
      Type:this.FormGroup.get('fcType').value, 
      DatePeriod: this.datePipe.transform(this.FormGroup.get('fcDatePeriod').value, 'dd-MM-yyyy')  ,         
      dt:this.dataSource1,
      UserID:'0',
      GroupID:101
      
     };
    this.service.httpPost('/Question/SetQuestion',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
