import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { Board } from './board.model';
import { Column, Tasks ,Assignee} from './column.models';
import * as _ from 'underscore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { MatStepper } from '@angular/material/stepper';
import { TicketComponent } from '../Ticket/ticket/ticket.component';
import { CommentComponent } from '../Ticket/comment/comment.component';
import { KanbanboardfilterComponent } from '../kanbanboardfilter/kanbanboardfilter.component';
import { reduce } from 'rxjs/operators';
import { attachmentComponent } from '../Ticket/attachment/attachment.component';
import { KanbanboardsearchComponent } from '../kanbanboardsearch/kanbanboardsearch.component';
import { TaskbyviewComponent } from '../CustomsReport/taskbyview/taskbyview.component';


@Component({
  selector: 'app-kanbanboard',
  templateUrl: './kanbanboard.component.html',
  styleUrls: ['./kanbanboard.component.scss'],
})
export class KanbanboardComponent implements OnInit {
  
  public board: Board = new Board('Test Board', [

    //  new Column('Open', '31', ,[
    //     'Some random idea',
    //     'This is another random idea',
    //     'This is some data not display'
    //   ]),
    //   new Column('Schedule', '32', [
    //     'Lorem ipsum',
    //     'foo'
    //   ]),
    //   new Column('Tested', '33', [
    //     'testing erp purchase module',
        
    //   ]),
    //   new Column('Closed', '34', [
    //     'ma ios released',
    //     'ma google play store console released',
    //     'crm site checking funger print data',
    //     'leave mgt mail checking ',
    //     'ticket creator form changed status '
    //   ])
    
    
      ]);
    
     
      //columnID:any[]=['1', '5', '8','9']
     columnID:any[]=['3010','3011','3012','3049']
     Assignee:any[]=['Syed Rilwan Al Ameen','Ahmed M Y']
      sessiondata:any;
      KeyColumn:any;
      selectedEmployee: any;
      selectedCompany:Number;
      selectedCustomer: any;
      selectedProject: any;
      selectedStatus: any;
      selectedPriority: any;
      Fromdate:any;
      Todate:any;
      DateType:any;
      setDefault:boolean=false;

      UserBoardData:any;
    
      dEmployee:any;
      dCompany:any;
      dCustomer:any;
      dTicket:any;
    
      vCID:any;
      cid:number=0;
      statuses: any;
      lists: any[] = [];
    
      item:any[];
    
      datastatus :any;
      finaldata :any;
      myArray:any []=[];
      containerData:any;
      previousContData:any;
      previousContUserID:any;
      CurrentContUserID:any;
    
      KanbanFormGroup:any =FormGroup;
    
    
      flag:any;
      TicketID:any;
      ActiviteID:any;
      TaskTemplate:any;
      menuListItems:any;
      showSpinner:any;
      TemplateID:any;
      CurLedgerID:any;
      TemplateGroupID:any;
      Activityflag:any;
      UserID:any;
      statusid:any;
      boardID:any;
      TaskData:any;
      
      ticketassigneedata:any;

      tempStatus = [];
      tempPriority = [];
      tempProject = [];
      tempClient = [];
      tempAssignee= [];
      templateFilter:any;
      
      templateCoulmns:any;

      isdefaultfilter:boolean=true
    
      constructor(
        private service: HttpserviceService,
        //private formBuilder: FormBuilder,
        public notificationService: NotificationService,    
        private formBuilder: FormBuilder,    
        private router: Router, 
        private dialog: MatDialog,        
      ){
    
        this.KanbanFormGroup = this.formBuilder.group({           
          fcBoard: [''],
          fcFilterDetails:[''],
          // fcCustomer:['']            
        });
    
      }
    
      async  ngOnInit() {
        this.vCID=sessionStorage.getItem('cid');
        let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
        this.CurLedgerID=curUser.ledgerid;
        this.UserID=curUser.userid;
        this.TemplateGroupID=curUser.TemplateGroupID;
        this.cid=parseInt(this.vCID);    
        sessionStorage.setItem("FilterSelection", "0");    
        this.Pageload('','','1','');
        //this.setDefault=true;
        
      }
    
      public dropGrid(event: CdkDragDrop<string[]>): void {
        //console.log('Error')
        moveItemInArray(this.board.columns, event.previousIndex, event.currentIndex);
      }
    
      public drop(event: CdkDragDrop<string[]>): void {
        
        if (event.previousContainer === event.container) {
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
          transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex);          
        }
       
        let previousContainerData=event.previousContainer.data;
        this.previousContData=previousContainerData[event.previousIndex]
        let currentData=event.container.data;
        this.containerData=currentData[event.currentIndex]
        this.previousContUserID=event.previousContainer.id;
        this.CurrentContUserID=event.container.id


        // console.log(' this.previousContUserID', this.previousContUserID);
        // console.log(' this.CurrentContUserID', this.CurrentContUserID);
        
        if(event.previousContainer.id!=event.container.id){
          this.bindData();
          this.save();
        }       
      }
    
      // async Pageload(customer:any,employee:any,flag:any){
       Pageload(customer:any,employee:any,flag:any,boardid:any){
    
        this.sessiondata=sessionStorage.getItem('currentUser');
        this.showSpinner=true;
        let params = {
          CID:this.cid, 
          Flag: flag ,   
          UserID	:this.UserID   ,
          BoardID:   boardid    
        };
      
          this.service.httpPost('/Kanbanboard/GetKanbanBoardData',params)
          .subscribe(
            (data) => {   
              let result: any;
              result = data;
              this.showSpinner=true;
              setTimeout(() =>{
                this.showSpinner=false;
              },
              800)
              if (result.errno === 0) {       
                if(flag=='1'){      
                  sessionStorage.removeItem("FilterData");           
                  sessionStorage.removeItem("multiselectinputvalue"); 
                  this.KeyColumn=result.respdata.KeyColumn ;                 
                  this.ticketassigneedata=result.respdata.TicketAssigneeData ;                   
                  this.menuListItems=result.respdata.Action ;                   
                  this.statuses=_.pluck(result.respdata.BoardData, 'ColumnName');
                  this.statusid=_.pluck(result.respdata.BoardData, 'ColumnID');
                  this.UserBoardData=result.respdata.UserBoard ;     
                  this.TaskData=result.respdata.Task;              
                  this.columnID=this.statusid;
                  this.boardID=this.KeyColumn[0].BoardID;
                  this.KanbanFormGroup.controls.fcBoard.setValue(JSON.parse(this.boardID));   
                  this.templateFilter=result.respdata.TemplateFilter;
                  this.templateCoulmns=result.respdata.TemplateSubData;
                  // if(this.boardID==result.respdata.DefaultView)    
                  //   this.setDefault=true;         
                  // console.log('this.BoardData', this.templateFilter);
                 
                  let ticketID=_.pluck(result.respdata.Task, 'TicketID'); 
                  this.LoadKanbanBoard(result.respdata.Task ,this.statusid);       
              
                }              
                else if (flag=='Refresh')                  
                 this.LoadKanbanBoard(result.respdata.Task ,this.statusid);
                 //console.log('taskpageload',result.respdata.Task ,this.statusid)
              }
              else {
                this.notificationService.success(result.errdesc); 
              }
            },
            (error: AppError) => {
              if (error instanceof BadInput)           
                console.log(error);
              else throw error;       
            });
       }    
    
    
       LoadKanbanBoard(data:any,statusid:any)
       {
            this.board=new Board('Test Board', []);
            let Ticket=data;                         
            var innerArrayLength = Ticket.length;          
            let subj:Tasks[] = []; 
            let assign:Assignee[]=[];
            //console.log('kanban load',this.statuses)
            for (let i = 0; i < this.statuses.length; i++) {                
              // loop the inner array               
              let k = 0;   
              subj=[];
              for (let j = 0; j < innerArrayLength; j++) {
                  //if(this.statuses[i]===data[j].Status)                  
                  var keyColumnData;
              
                   keyColumnData=data[j].KeyColumn;
                 // console.log('data[j].Sys_Assignee', data[j].Sys_Assignee)
                  if(this.statuses[i]===keyColumnData)
                  {
                    if(data[j].Sys_Assignee!=null && data[j].Sys_Assignee.length!=0)
                    {
                      let assData=JSON.parse(data[j].Sys_Assignee);
                      let fnData=JSON.parse(data[j].Sys_FirstName);
                      let lsData=JSON.parse(data[j].Sys_LastName);
                      //console.log('assData',assData.length)
                      for(let l=0;l<assData.length;l++)
                      {
                        //console.log('assDat222a',assData)
                        let ass={'Assignee':assData[l],'FirstName':fnData[l],'LastName':lsData[l]}
                        assign.push(ass);                        
                      }                      
                    } 
                    
                    //console.log(';assign',assign)

                    let taskData={'subject':data[j].Subject,'ticketid':data[j].TicketID,'PriorityColor':data[j].PriorityColor,
                    
                    'photo':data[j].Photo,'Customer':data[j].MerchantLedgerID,
                    'Field3':data[j].Field3,'requeston':data[j].Assignee,'RequestTypeColor':data[j].RequestTypeColor,
                    'statusTypeColor':data[j].statusTypeColor,
                    'statusdata':data[j].StatusID,'ProjectData':data[j].ProjectUID,
                    'Field1':data[j].Field1,'Field2':data[j].Field2,'Field4':data[j].Field4,'Field5':data[j].Field5,'Field6':data[j].Field6,
                    'Field7':data[j].Field7,'Field8':data[j].Field8,'Field9':data[j].Field9,'Field10':data[j].Field10,
                    'Field11':data[j].Field11,'Field12':data[j].Field12,'Field13':data[j].Field13,'Field14':data[j].Field14,
                    'Field15':data[j].Field15,'Field16':data[j].Field16, 'Field17':data[j].Field17, 'Field18':data[j].Field18,
                    'Field19':data[j].Field19,'Field20':data[j].Field20,'Date1':data[j].Date1,'Date2':data[j].Date2,
                    'Date4':data[j].Date3,'Date3':data[j].Date4 ,'Assignee':assign ,'keycolumn':data[j].KeyColumn
                   
                  }              
                    assign=[]          
                    subj.push(taskData);
                    k++;                                    
                  }          
              }
              //console.log('subj',subj)      
              this.datastatus = new Column (this.statuses[i],statusid[i],subj.length,subj)  
              //console.log('datastatus',this.datastatus)               
              this.board.columns.push(this.datastatus);
             // console.log('this.board',this.board.columns[0].tasks.length)
            }
            //console.log('this.board.columns',this.board)
        }
    
    
       Filter()
       {
          //this.Pageload('','',"Refresh");    
         // this.service.initializeFormGroup();
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.width= '30%';
          dialogConfig.height='85%',
          dialogConfig.position= { right: '0' }, 
          // dialogConfig.width = "60%";
          dialogConfig.data = {
            flag: 'Filter',
            userid: this.UserID,
            ledgerid: this.CurLedgerID,
            cid:this.vCID,
            keycolumn:this.KeyColumn[0].KeyColumn,
            //textdataFlag:this.KanbanFormGroup.get('fcFilterDetails').value==''?'0':'1'
            textdataFlag:this.isdefaultfilter==true?'0':'1',
            BoardID:   this.KanbanFormGroup.get('fcBoard').value,
            template:this.templateFilter,
            TemplateID:1,
            dtSelectedFilterData:sessionStorage.getItem("FilterData"),
            multiselectinputData:sessionStorage.getItem("multiselectinputvalue"),
            templateColumns:this.templateCoulmns
          }
          const dialogRef = this.dialog.open(KanbanboardsearchComponent,          
            dialogConfig)
          dialogRef.afterClosed()
          .subscribe(res => {
           //console.log('res',res,this.KeyColumn[0].KeyColumn);
           if(res.event!='CLEAR'){
        
          sessionStorage.setItem("FilterData", JSON.stringify(res.sessionDt));  
          sessionStorage.setItem("SearchData", JSON.stringify(res.dt));  
          sessionStorage.setItem("multiselectinputvalue", JSON.stringify(res.sessionmultiselectinputvalue));  
          //let selectdData=res.dt;
          this.KanbanFormGroup.controls['fcFilterDetails'].setValue(res.dtdispalyvalue.map(item => item.DisplayValue).join(', '))
          
          this.statusid=res.statusid;
          

          let params = {
            CID:this.cid, 
            Flag: 'Filter' ,   
            UserID	:this.UserID   ,
            BoardID:   this.boardID ,   
            dtRequestOn: res.dt
          };
          //console.log('params',params)
       
            this.service.httpPost('/Kanbanboard/GetKanbanBoardData',params)
            .subscribe(
              (data) => {   
                let result: any;
                result = data;
                this.showSpinner=true;
                setTimeout(() =>{
                  this.showSpinner=false;
                },
                800)  
  
                if (result.errno === 0) {  
                    this.KeyColumn=result.respdata.KeyColumn ;                 
                    this.ticketassigneedata=result.respdata.TicketAssigneeData ;                   
                    this.menuListItems=result.respdata.Action ;                   
                    this.statuses=_.pluck(result.respdata.BoardData, 'ColumnName');
                    this.statusid=_.pluck(result.respdata.BoardData, 'ColumnID');
                    this.UserBoardData=result.respdata.UserBoard ;     
                    this.TaskData=result.respdata.Task;              
                    this.columnID=this.statusid;
                    this.boardID=this.KeyColumn[0].BoardID;
                    this.KanbanFormGroup.controls.fcBoard.setValue(JSON.parse(this.boardID));   
                    //this.templateFilter=result.respdata.TemplateFilter;                 
                    this.LoadKanbanBoard(result.respdata.Task ,this.statusid);                 
                }
                else {
                  this.notificationService.success(result.errdesc); 
                }
              },
              (error: AppError) => {
                if (error instanceof BadInput)           
                  console.log(error);
                else throw error;       
              });            
            // Remove all saved data from sessionStorage           
           let assigneedata="",prioritydata="",statusdata="",customerdata="",projectdata="";
           let tempSta,temppri,tempproj,tempcli,tempassign          
           this.tempProject = [];this.tempStatus = [];this.tempPriority = [];this.tempAssignee = [];this.tempClient = [];
          }
          });
       }

       
    Refresh(){
      this.showSpinner=true;
      // Get saved data from sessionStorage
      let tempAssignee = sessionStorage.getItem("tempAssignee");
      let tempProject = sessionStorage.getItem("tempProject");
      let tempClient = sessionStorage.getItem("tempClient");
      let tempStatus = sessionStorage.getItem("tempStatus");
      let tempPriority = sessionStorage.getItem("tempPriority");
      let Fromdate = sessionStorage.getItem("Fromdate");
      let Todate = sessionStorage.getItem("Todate");

      let params = {  
      Flag:"LoadFilterData",
      CID:this.cid,
      dtRequestOn:JSON.parse(tempAssignee),          
      dtProject:JSON.parse(tempProject),
      dtClient:JSON.parse(tempClient),      
      dtStatus:JSON.parse(tempStatus),
      dtPriority:JSON.parse(tempPriority),
      FromDate:Fromdate,
      ToDate:Todate,
      DateType:"cs",
      UserID	:this.UserID   ,
      BoardID:   this.KanbanFormGroup.get('fcBoard').value
      }


      //console.log('param',params);
      this.service.httpPost('/kanbanboard/GetKanbanBoardData',params)
      .subscribe(
        (data) => {   
          let result: any;
          result = data;

          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          if (result.errno === 0) {                         
              this.statuses=_.pluck(result.respdata.BoardData, 'ColumnName');
              this.statusid=_.pluck(result.respdata.BoardData, 'ColumnID');
              this.TaskData=result.respdata.Task;               
              this.LoadKanbanBoard(result.respdata.Task ,this.statusid); 
          }
          else {
            this.notificationService.success(result.errdesc); 
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
      }  
    
    
       filterBoardData(){
        let params = {
          CID:this.cid, 
          Flag: "FilterData" ,   
          UserID	:this.UserID,          
          dtRequestOn:this.selectedEmployee,
          dtClient:this.selectedCustomer,
          dtProject:this.selectedProject,
          Status:this.selectedStatus,
          Priority:this.selectedPriority,
          FromDate:this.Fromdate,
          Todate:this.Todate,
          DateType:this.DateType   
        };     
          this.service.httpPost('/ITSCustomsReport/GetKanbanBoardData',params)
          .subscribe(
            (data) => {   
              let result: any;
              result = data;
              if (result.errno === 0) {     
                 let filterData;
                filterData=_.where(this.TaskData,'')
                 this.LoadKanbanBoard(result.respdata.Task ,this.statusid);                 
              }
              else {
                this.notificationService.success(result.errdesc); 
              }
            },
            (error: AppError) => {
              if (error instanceof BadInput)           
                console.log(error);
              else throw error;       
            });

       }

       clickMenuItem(menuItem ,Value, stepper: MatStepper){        
        this.TicketID=Value.ticketid;
        this.ActiviteID=menuItem.ActivityID;
        this.TemplateID=menuItem.TemplateID;
        let actvityflag=menuItem.ActivityID.split("_",4)
        this.flag='ACTIVITY'
        if (actvityflag[1]=='COMMENT')
        {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {
            flag: 'EDIT',
            pid:0,            
           template:this.TaskTemplate,
           TicketID:this.TicketID,
           TemplateID:this.TemplateID
     
          }
          const dialogRef = this.dialog.open(CommentComponent, dialogConfig)
          dialogRef.afterClosed()
        }
        else if (actvityflag[1]=='ATTACHMENT')
        {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;
          dialogConfig.data = {
            flag: 'EDIT',
            pid:0,            
          template:this.TaskTemplate,
          TicketID:this.TicketID
    
          }
          const dialogRef = this.dialog.open(attachmentComponent, dialogConfig)
          dialogRef.afterClosed()
        }
        else if (actvityflag[1]=='VIEW')
        {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus =  true;       
          dialogConfig.data = {                 
                pid:0,
                height: '350px',
                width: '70%',                            
                cid:this.cid,
                flag:"View",
                TicketID:this.TicketID,
                TemplateID:this.TemplateID,
                UserName:this.UserID,
                TagID:'External',
                ActiviteID:"INT_VIEW",
                TemplateGroupID:1,   
                Subject:Value.subject
            }                   
            const dialogRef =  this.dialog.open(TaskbyviewComponent, dialogConfig)
            dialogRef.afterClosed().subscribe(result => {     
              //this.Submit();     
            });
        }   
        else if (actvityflag[1] !=='ATTACHMENT')
        {  
          //console.log('Other')    ;
          this.Activityflag='EDIT'
          this.Loaddetails();
        }       
    }
    
    
    Loaddetails()
      {
        this.showSpinner=true;
        let params = {
        cid:this.cid,
        flag:this.flag,
        TicketID:this.TicketID,
        TemplateID:this.TemplateID,
        UserName:this.CurLedgerID,
        TagID:'INTERNAL',
        ActiviteID:this.ActiviteID,
        TemplateGroupID:this.TemplateGroupID        
        }
        this.service.httpPost('/TicketCreator/GetTicketCreator?',params)
        .subscribe(data => {
              let result: any;
                result=data;
                this.showSpinner=true;
                setTimeout(() =>{this.showSpinner=false;},800)
                if (result.errno === 0){    
                    this.TaskTemplate=result.respdata.TaskTemplate
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.autoFocus =  true;
                    dialogConfig.data = {
                      flag: this.Activityflag,
                      pid:0,
                      height: '350px',
                      width: '60%',
                     template:this.TaskTemplate,
                     TicketID:this.TicketID,
                     ActiviteID:this.ActiviteID,
                     TagID:'INTERNAL',
                     TemplateGroupID:this.TemplateGroupID,
                     TemplateID:this.TemplateID           
                    }
                    const dialogRef = this.dialog.open(TicketComponent, dialogConfig)
                    dialogRef.afterClosed().subscribe(result => {
                      this.flag='HomePage'                   
                    });
                }
                else{
                  this.notificationService.success(result.errdesc); //':: Problem details');
                }
              }, 
              (error: AppError) => {
                if (error instanceof BadInput)           
                  console.log(error);
                else throw error;       
              });
      }

      setDefaultView()
      {
          this.showSpinner=true;          
          let params = {        
            CID:this.cid,      
            Flag:'ADD',
            UserID	:this.UserID  ,
            KeyColumn:'Kanban',
            BoardID:   this.KanbanFormGroup.get('fcBoard').value
          }
        this.service.httpPost('/Kanbanboard/SetDefaultView',params)
        .subscribe(
        (data) => {   
          let result: any;
          result = data;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)

          if (result.errno === 0) {  
            this.notificationService.success(result.errdesc); 
          }
          else {
            this.notificationService.warn(result.errdesc); 
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
      }
    
       async bindData(){      
          for (var j = 0; j < this.board.columns.length; j++)
          {       
              for (var k = 0; k < this.board.columns[j].tasks.length; k++)
              {
               
                if(this.containerData==this.board.columns[j].tasks[k]){          
                  let finaldata = {'Name':this.board.columns[j].name,'ID':this.board.columns[j].id,'ticketID':this.containerData.ticketid,'indexorder':0}  
                  this.myArray.push(finaldata) ;
                  k=this.board.columns[j].tasks.length;              
                }      
                if(this.KeyColumn[0].Column3=='multiselect'){                 
                  if(this.previousContData==this.board.columns[j].tasks[k]){          
                    let finaldata = {'Name':this.board.columns[j].name,'ID':this.previousContUserID,'ticketID':this.containerData.ticketid,'indexorder':1}  
                    this.myArray.push(finaldata) ;
                    k=this.board.columns[j].tasks.length;                   
                  }
                }              
              }
          }          
       }


       getAssigneesForTicket(ticketId: string): string[] {        
        const ticketData = this.ticketassigneedata.find(entry => entry.TicketID === ticketId);              
        if (ticketData) {
          // Split the comma-separated assignee values into an array
          return ticketData.Assignee.split(',').map(assignee => assignee.trim());
        }              
        return [];
      }
       
      async save(){     
        this.showSpinner=true;        
        const filteredcolumn:any[]= this.myArray.map(item => { return {
          TicketID: item.ticketID,
          statusname: item.ID ,
          IndexValue:item.indexorder
        }});   
        const assigneesForTicket = this.getAssigneesForTicket(filteredcolumn[0].TicketID);
        let assessmentvalue = [];
        var ColumnName=this.KeyColumn[0].KeyColumn=='Assignee'?'Assignee':this.KeyColumn[0].KeyColumn        
        let Value2;//=filteredcolumn[0].statusname
        console.log('filteredcolumn',filteredcolumn)
        console.log('filtassigneesForTicketeredcolumn',assigneesForTicket)
        if(this.KeyColumn[0].Column3=='multiselect'){           
          const UserID = filteredcolumn.find(item => item.IndexValue === 1);          
          const newArray: string[] = assigneesForTicket.filter(value => value !== UserID.statusname);          
          const NewUserID:any=filteredcolumn.find(item => item.IndexValue === 0);
          newArray.push(NewUserID.statusname);              
          const resultString: string = newArray.join(",");      
          assessmentvalue.push({ColumnName,resultString});                   
        }          
        else{
          Value2=filteredcolumn[0].statusname
          assessmentvalue.push({ColumnName,Value2});
        }    
        let params={
          CID: this.cid,
          Flag: 'EDIT',
          TemplateID:this.KeyColumn[0].TemplateID,
          CreatorFlag:0,
          TicketID:filteredcolumn[0].TicketID,
          UserName: this.UserID,
          dt:assessmentvalue,
          Activity:this.KeyColumn[0].ActivityID,
          Comment:'KanbanBoard',
          ActiviteID:this.KeyColumn[0].ActivityID          
        };

        let data=await this.service.httpPost('/TicketCreator/SetTicketCreator',params)
          .toPromise ().then(data=> {            
              let result: any;
              result = data;
              this.showSpinner=true;
              setTimeout(() =>{
                this.showSpinner=false;
              },
              800)

              if (result.errno === 0) {                                              
                this.myArray=[];              
              }
              else {
                this.notificationService.success(result.errdesc); 
              }
            },
            (error: AppError) => {
              if (error instanceof BadInput)           
                console.log(error);
              else throw error;       
            }); 
    
      }

      BoardRefresh(){      
        this.KanbanFormGroup.controls.fcFilterDetails.setValue('');
        this.Pageload('','',"1",this.KanbanFormGroup.get('fcBoard').value);
      }
    
    }
     
    
export class User {
  // auth: boolean;
  //cid: number;
  userid: number;
  ledgerid: number;
  groupid: number;
  username: string;
  token?: string;
  configparam: any;
  salesmanid: number;
  TemplateGroupID:number;
  UserType:string;
  dtsiteid:any;
  ds_dashboard:any;
  // elapsedtime?: number;
}

export interface assignee{
  ledgerID:any;  
}