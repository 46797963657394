import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { LeaveApprovalService, Employee } from '../leave-approvalValidate';
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import {FormGroup, FormControl} from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DatePipe, formatDate } from '@angular/common';
import { User } from './../../../../Modules/user';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';

@Component({
  selector: 'app-dialogleaveapproval',
  templateUrl: './dialogleaveapproval.component.html',
  styleUrls: ['./dialogleaveapproval.component.css']
})
export class DialogleaveapprovalComponent implements OnInit {
  fromdate = new FormControl(new Date());

  _frmdate: any;
  _todate: any;

  model: any;
  panelOpenState = false;
  step = 0;
  ledgerid: string;
  flag: string;
  type: any;
  requestyear: any;
  leavelist: any;
  dayslist: any;
  sessionlist: any;
  isDivVisible= false;
  formname: string;
  CID:any;
  CurLedgerID:any;
  showSpinner:any;
  error: any= {isError: false, errorMessage: ''};
  isValidDate: any;

  languages: Language[];
  Days = {
    store: {
        type: 'array',
        data: [
            { id: 1, name: 'Full day' },
            { id: 0.5, name: 'Half day' },

            // ...
        ],
        key: 'id'
    }};

    Session = {
      store: {
          type: 'array',
          data: [
              { id: 'M', name: 'Morning' },
              { id: 'E', name: 'Evening' },

              // ...
          ],
          key: 'id'
      }};

  sessionVisible = false;
  returnVisible = false;

  constructor(private datePipe: DatePipe,private dialogService: DialogService,
    public service: LeaveApprovalService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<DialogleaveapprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.flag = this.data.flag;
      this.ledgerid =  this.data.ledgerid;
      this.requestyear = this.data.requestyear;
      this.type = this.data.type;
      this.CID=this.data.cid;
      //console.log('dialog data',this.data)
    }

  ngOnInit(): void {
    this.dayslist = this.Days.store.data;
    this.sessionlist = this.Session.store.data;
    this.service.form.controls.daystatus.setValue(this.data.daystatus);
    this.sessionVisible = this.data.daystatus == 0.5 ? true : false;
    if(this.flag=="ADD")
        this.service.form.controls.daystatus.setValue(1);
   
    if (this.type == 1)
    { 
      this.formname = 'Leave Approval';
     if(this.flag =="RETURN") 
          this.returnVisible = true;
     else
          this.returnVisible = false;
    }
    else{
      this.formname = 'Leave Request';
      this.returnVisible = false;
    }
    this.getleavelist();

     let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }
  // public dateOptions: any = {
  //   locale: {format: "DD/MM/YYYY"},
  //   alwaysShowCalendars: false,
  //   singleDatePicker: true
  // };
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  getleavelist(){
    const params = {
      cid: this.CID,
      flag: 'LEAVETYPEMASTER',
      ledgerid: 0,
      LeaveID: 0,
      year: this.requestyear

        };
    this.service.getleaveapproval(params)
        .subscribe(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            this.leavelist = result.respdata.Table;
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
      }
  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
          this.service.form.reset();
          this.service.initializeFormGroup();
          this.dialogRef.close({event: 'CLEAR'});
       }
      });
  }
  onClose(model){
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close({event: this.flag, data: model});
  }

  validateDates(sDate: string, eDate: string){
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)){
      this.isValidDate = false;
    }
    return this.isValidDate;

  }

  onSubmit()
  {
    if (this.service.form.valid){
      const curDate = new Date();
      const paramsFormValue = this.service.form.value;
      this.showSpinner=true;
      paramsFormValue.fromdate = this.datePipe.transform(paramsFormValue.fromdate, 'yyyy-MM-dd');
      paramsFormValue.todate = this.datePipe.transform(paramsFormValue.todate, 'yyyy-MM-dd');
      if (this.flag == 'RETURN')
      {
        paramsFormValue.returndate = this.datePipe.transform(paramsFormValue.returndate, 'yyyy-MM-dd');
      }
      else{
        paramsFormValue.returndate = this.datePipe.transform(paramsFormValue.todate, 'yyyy-MM-dd');
      }


      this._frmdate = this.datePipe.transform(paramsFormValue.fromdate, 'yyyy-MM-dd');
      this._todate = this.datePipe.transform(paramsFormValue.todate, 'yyyy-MM-dd');

      this.isValidDate = this.validateDates( this._frmdate , this._todate);
  if (this.isValidDate){
      const  paramsExtra = {
        cid: this.CID,
        flag: this.flag,
        LedgerID: this.ledgerid,

        UserID: '',
        EmpUniqID	:this.CurLedgerID,
        GroupID: sessionStorage.getItem('groupid'),
        Year: this.requestyear,
        Type: this.type

      };
      const params = Object.assign(paramsFormValue, paramsExtra);
      this.service.setleaveapproval(params)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
         
          if (users.errno === 0){
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.notificationService.success(users.errdesc);
            this.onClose(this.model);

          }
          else{
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        error => {
          console.log('Error : ', error);
        });

    }
  else
  {
    this.notificationService.success('To date should be greater then from date');
  }
  }

  }
  onClear()
  {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.service.form.controls.daystatus.setValue(1);
    this.onDaysSelect(1);
  }

  onDaysSelect(e){
    const dayvalue = e.value;
    this.sessionVisible = e.value == 0.5 ? true : false;
  }

}
export interface GroupALL {
  GroupID: string;
  GroupName: string;
  UserID: string;
  UserName: string;
  Status: string;
}

interface Language {
  LngCode: string;
  Description: string;
}
