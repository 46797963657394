import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe  } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { DocumentreqviewComponent } from '../documentreqview/documentreqview.component';
import { UploadprofilepicComponent } from '../../../../components/HR/EmployeeRegistration/uploadprofilepic/uploadprofilepic.component';
import { UploaddocrequestComponent } from '../../../../components/Employee/DocumentRequest/uploaddocrequest/uploaddocrequest.component';
import { DownloaddocrequestComponent } from '../../../../components/Employee/DocumentRequest/downloaddocrequest/downloaddocrequest.component';

@Component({
  selector: 'app-documentreqlist',
  templateUrl: './documentreqlist.component.html',
  styleUrls: ['./documentreqlist.component.scss'],
})
export class DocumentreqlistComponent implements OnInit {

  flag: string;
  Companies:any;
  Employees: any;
  Types:any;
  Currency:any;
  datasource:MatTableDataSource<GroupALL>;
  DocumentRequestFormGroup: FormGroup;
  selectedEmpID: any;
  CurLedgerID:any;
  searchKey: string;
  addbtnvisible: boolean = false;
  editbtnvisible: boolean = false;
  deletebtnvisible: boolean = false;
  approvebtnvisible: boolean = false;
  myControl = new FormControl('', [Validators.required]);
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  employeeid:string[]=[];
  cid:any;
  siteid:any;
  Status : any;
   displayedColumns =['ReqDate','EmpName','DocType','DocComment','DocStatus','Action'];
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

   constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private generic: GenericService,private formBuilder: FormBuilder, private service1: UserService, 
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService) 
    { 
      
    }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));        
    // this.DocumentRequestFormGroup.controls.fcCompany.setValue(this.cid);
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.username;
    this.siteid=curUser.dtsiteid; 
    this.getddllist();
    
  }

  Submit()
  {
    this.getddllist();
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  changesite(e) {
    this.cid=e;
    this.getddllist();
  }

  getddllist()
  {
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",
      MenuID:"STS_106",
      RequestID:sessionStorage.getItem('groupid')
    };
    this.service.httpPost('/DocumentRequest/Getdropdownvalues',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          // console.log('params ', params);
          if (result.errno === 0) {               
            this.Companies =result.respdata.Companyid;   
            this.Employees = result.respdata.Employees;                           
            this.Types = result.respdata.Type;
            this.Status=result.respdata.Status; 
  
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.DocumentRequestlist);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;

            this. getUserList();
            // this.employeeid= _.pluck(this.Employees, 'Name');   
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_106",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;
      this.editbtnvisible = btnVisilityData.editflag;
      this.deletebtnvisible = btnVisilityData.deleteflag;
      this.approvebtnvisible = btnVisilityData.approveflag;
   
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  // selectedemployee(event) {        
  //   this.selectedEmpID = event.option.value;
  //   const str: string = this.selectedEmpID;   
  //   // console.log('selectedEmpID Split', this.selectedEmpID.slice(0, str.indexOf('-')));
  //   this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
  //   // console.log('selectedEmpID', this.selectedEmpID);    
  // }

  add()
  {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.cid,
          Flag:'ADD' , 
          Employees:this.Employees,                           
          Type:this.Types,
          Status:this.Status,
          MenuID:'STS_106'
        }
        const dialogRef = this.dialog.open(DocumentreqviewComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
          this.getddllist();     
          });

  }

  Edit(event){   
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.cid,
          Flag:'EDIT' , 
          Employees:this.Employees,                                                            
          Type:this.Types,
          Status:this.Status ,
          RequestID:event.CDID ,
          MenuID:'STS_106' 
        }
        const dialogRef = this.dialog.open(DocumentreqviewComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
          this.getddllist();     
          });
    }
    
    exportAsExcel()
    {
      this.generic.excelExport(this.datasource.data, 'Document Request', 'Document Request' );
    }

    Delete(event){
  
          let sdate =Date();
          this.dialogService.openConfirmDialog('Are you sure to delete this document?')
          .afterClosed().subscribe(res => {
           if (res) {
          let params = {     
            CID:this.cid,
            Flag:"DELETE",           
            RequestID:event.CDID,        
          };
          this.service.httpPost('/DocumentRequest/SetDocumentRequest',params)
          .subscribe(
            (data) => {
              let result: any;
              result = data;       
              if (result.errno === 0) {
                this.NotificationService.success(result.errdesc);
                //console.log('Project list: ', result.respdata);
                this.Submit();
              }
              else {
                this.NotificationService.success(result.errdesc); //':: Problem details');
                this.Submit();
              }
            },
            (error: AppError) => {
              if (error instanceof BadInput)         
                console.log(error);
              else throw error;       
            });
          }
        });
      }

      DocumentView(event){
       // this.DcValidate.populateForm(data);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {
          cid:this.cid,
          Flag:'VIEW' , 
          RequestID:event.CDID   
        }
       this.dialog.open(DownloaddocrequestComponent, dialogConfig);        
      }

      onUpload(data, _flag,ledgerid){
        const dialogConfig = new MatDialogConfig();
        console.log('data',data,_flag,ledgerid);
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {
          flag: _flag,
          cdid:data,
          ledgerID:ledgerid,
          cid: this.cid
         
        }
        const dialogRef =this.dialog.open(UploaddocrequestComponent, dialogConfig); 
        dialogRef.afterClosed()       
      }


  //     Approve(event){
  //       const dialogConfig = new MatDialogConfig();
  //       dialogConfig.disableClose = true;
  //       dialogConfig.autoFocus =  true;
  //       dialogConfig.data = {       
  //         cid:this.cid,
  //         Flag:'APPROVE', 
  //         Employees:this.Employees,                           
  //         Type:this.Types,
  //         Currency:this.Currency,
  //         RequestID:event.ReqID      
  //       }
  //       const dialogRef = this.dialog.open(DocumentreqviewComponent, dialogConfig)
  //       dialogRef.afterClosed()
  //   }
  // onClear()
  // {
  //   this.DocumentRequestFormGroup.reset();  
  //   this.myControl.setValue('');   
  //   this.DocumentRequestFormGroup.controls.fcCompany.setValue(this.cid);
  // }

}
export interface GroupALL {
  ReqID: string;
  Description: string;
}
