import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { ExpensepostingComponent } from './../expenseposting/expenseposting.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { User } from './../../../../Modules/user';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatecontrolComponent } from 'src/app/components/HR/DateControl/datecontrol/datecontrol.component';

@Component({
  selector: 'app-expensepostinglist',
  templateUrl: './expensepostinglist.component.html',
  styleUrls: ['./expensepostinglist.component.css']
})
export class ExpensepostinglistComponent implements OnInit {
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"
  todatevisible= false;
  fromdatevisible= true;
  DateType= 'CurrentDate';
  filterOption: any;
  frmtext= 'Current Date';
  companies: any;
  frmdate= new Date();
  datasource:MatTableDataSource<GroupALL>;
  totext: any;
  ExpensepostingGroup : FormGroup;
 // datasource:any;
  Ledgerlist:any;
  Prefixlist:any;
  datasource1:any;
  CID:any;
  showSpinner:any;
  Statuslist=['All','Approved','UnApproved'];
  datefilter = {
    store: {
        type: 'array',
        data: [
            { name: 'CurrentDate' },
            // { name: 'CurrentMonth' },
            { name: 'ByDate' },
            // { name: 'ByMonth' },
            // { name: 'ByYear' },
        ],
        key: 'name'
    }};
    displayedColumns = ['DocumentNo', 'Date', 'Amount', 'ApproveStatus','Action'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private formBuilder: FormBuilder,private datePipe: DatePipe, 
    private generic: GenericService,
    private service: HttpserviceService, private NotificationService: NotificationService, 
    private dialog: MatDialog,private dialogService: DialogService) 
  { 
    this.ExpensepostingGroup = this.formBuilder.group({  
      fcCompany: ['', Validators.required] ,             
      fcStatus: [''],     
     // fcFromDate: [''],     
     // fcToDate: ['']
       
  });
  }

  ngOnInit(): void {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.filterOption = this.datefilter.store.data;
    // this.getemployeelist();
    // this.flag="PAGELOAD";    
    // this.ExpensepostingGroup.controls.fcDateType.setValue("CurrentDate");
   // this.ExpensepostingGroup.controls.fcFromDate.setValue(this.frmdate);
    //this.ExpensepostingGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.ExpensepostingGroup.controls.fcStatus.setValue('All');
    this.getExpenseposting();
    this.ExpensepostingGroup.controls.fcCompany.setValue(this.CID);
  }
  onCompanySelect(event)
  {
    this.CID=event.value;
    this.ExpensepostingGroup.reset();
    this.ExpensepostingGroup.controls.fcStatus.setValue('All');
    // this.ExpensepostingGroup.controls.fcFromDate.setValue(this.frmdate);
    // this.ExpensepostingGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    
    this.getExpenseposting();
    this.ExpensepostingGroup.controls.fcCompany.setValue(this.CID);

  }

  exportAsExcel()
  {
    this.generic.excelExport(this.datasource.data, 'Expense Posting', 'Expense Posting' );
  }

  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(DatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }
  
  Refresh()
  {
    // this.flag="LOADGRID";
    this.getExpenseposting();
  }
  addRow()
  {
  
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus =  true;
     dialogConfig.data = {
       flag: 'ADD',
      //  ledgerid: 0,
      //  date: this.date,
       ledgerlist: this.Ledgerlist,
       prefixlist: this.Prefixlist,
       griddata:this.datasource1,
       cid:this.CID
     };
     const dialogRef = this.dialog.open(ExpensepostingComponent, dialogConfig);
     dialogRef.afterClosed().subscribe(result => {     
      this.getExpenseposting();     
      });;
  
  }
  onEdit(data, _flag){
    console.log('type', data.ApproveStatus);
    if(data.ApproveStatus==='UnApproved')
    {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {
          flag: "EDIT",
          ledgerlist: this.Ledgerlist,
          prefixlist: this.Prefixlist,
          griddata:this.datasource1,
          documentno:data.DocumentNo,
          date:data.Date,
          RangeNo:data.RangeNo,
          Prefix:data.Prefix,
          cid:this.CID
        }
        const dialogRef =  this.dialog.open(ExpensepostingComponent, dialogConfig);   
        dialogRef.afterClosed().subscribe(result => {     
          this.getExpenseposting();     
          });;
    }  
    else
        this.NotificationService.warn('Edit option not applicable for approved expenses');
  }
  onDelete(data, _flag)
  {
    this.dialogService.openConfirmDialog('Are you sure to delete this expense?')
   .afterClosed().subscribe(res => {
   if (res) {
    const params = {
      CID:this.CID,
      Flag:  _flag,
      Prefix:"",
      DocNo:data.DocumentNo,
      DocDate: this.datePipe.transform(data.Date,"dd-MM-yyyy"),
      DtExpense:this.datasource1,
      TotalAmt:0,
      UserID:"",
      GroupID:sessionStorage.getItem('groupid')
        };
        this.service.httpPost('/ExpensePosting/SetExpensePosting',params)
        .subscribe(
          (data) => {
            let users: any;
            users = data; //result.users;
            // console.log('create:', users.errno);
  
            if (users.errno === 0){
              this.NotificationService.warn('Record deleted successfully');
              this.getExpenseposting();     
            }
            else{
              this.NotificationService.success(users.errdesc); //':: Problem updating user');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)
              console.log('Error: ', error);
              // this.service.form.setErrors(error.originalError);
            else throw error;
          });
        }
      });
  }
 
  getExpenseposting()
  {
    this.showSpinner=true;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    const params = {
      CID:this.CID,
      Flag: "PAGELOAD",
      Status: this.ExpensepostingGroup.get('fcStatus').value,
      FromDate:this.fromdate,
      ToDate:this.todate
      //Fromdate: this.datePipe.transform(this.ExpensepostingGroup.get('fcFromDate').value,"dd-MM-yyyy"),
     // ToDate: this.datePipe.transform(this.ExpensepostingGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
     // this.datePipe.transform(this.ExpensepostingGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.ExpensepostingGroup.get('fcToDate').value,"dd-MM-yyyy")
      
        };
        this.service.httpPost('/ExpensePosting/GetExpensePosting',params)
        .subscribe(data => {
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result = data;
          if (result.errno === 0){
            this.companies = curUser.dtsiteid;
            this.Ledgerlist= result.respdata.Table1;
            this.Prefixlist= result.respdata.Table2;
            //this.datasource = result.respdata.Table3;
            this.datasource1 = result.respdata.Table4;

            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Table3);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

}
export interface GroupALL {
  ReqID: string;
  Description: string;
}
