import {  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef, 
  Output} from '@angular/core';
import { DynamictabsDirective } from '../dynamictabs.directive';
import { EventEmitter } from 'stream';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
//import { templateJitUrl } from '@angular/compiler';


export class tabObj{
  title: string;
  active:boolean;
  isClosable:boolean;
  isDirty: boolean;
  component:any;
  MenuID:string;
}


@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @Output() menuID_:string;
  // tabObj:{title: string, 
  //   active:boolean,
  //   isClosable:boolean,
  //   component:any};
  //dynamicTabs: TabComponent[] = [];
  dynamicTabs: tabObj[] = []; 
  
  
  @ContentChildren(tabObj) tabs: QueryList<tabObj>;

  @ViewChild(DynamictabsDirective) dynamicTabPlaceholder: DynamictabsDirective;

  /*
    Alternative approach of using an anchor directive
    would be to simply get hold of a template variable
    as follows
  */
  // @ViewChild('container', {read: ViewContainerRef}) dynamicTabPlaceholder;
   isDarkTemp:any;
  constructor(private _componentFactoryResolver: ComponentFactoryResolver,private sharedService: GenericService) 
  {
    
  }
  count:any;
  // contentChildren are set
  ngAfterContentInit() {
    this.isDarkTemp = sessionStorage.getItem('isDark');
    // get all active tabs
    //const activeTabs = this.tabs.filter(tab => tab.active);

    // if there is no active tab set, activate the first
    // if (activeTabs.length === 0) {
    //   this.selectTab(this.tabs.first);
    // }
  }


  openTab2(title: string,menuid:string, component, data, isCloseable = false) {
    
    //this.sharedService.updateComponentValue(menuid);
    sessionStorage.setItem('SelectedMenuID',menuid)
   

    // get a component factory for our TabComponent
    if(component != undefined)
    {
      
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(
        component
      );  
  
  

    // fetch the view container reference from our anchor directive
    const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

    // alternatively...
    // let viewContainerRef = this.dynamicTabPlaceholder;
    if(this.dynamicTabs.length>0){
      viewContainerRef.detach();
    }
    
    // create a component instance  
    const componentRef = viewContainerRef.createComponent(componentFactory);

    // set the according properties on our component instance
    // const instance: TabComponent = componentRef.instance as TabComponent;
    // instance.title = title;
    // instance.template = template;
    // instance.dataContext = data;
    // instance.isCloseable = isCloseable;

    // remember the dynamic component for rendering the
    // tab navigation headers
    
    
    var temp=new tabObj
    temp.active=true;
    temp.component=componentRef;
    temp.title=title;
    temp.isClosable=true;
    temp.MenuID=menuid
    this.dynamicTabs.push(temp);

    // set it active
    //this.selectTab2(temp);
    this.dynamicTabs.forEach(tab => (tab.active = false));

    // activate the tab the user has clicked on.
    temp.active = true;

    // this.menuID_.emit(menuid);
   
  }
  }




  selectTab2(tab:tabObj) {
 
   
    sessionStorage.setItem('SelectedMenuID',tab.MenuID)
    // deactivate all tabs
    //this.tabs.toArray().forEach(tab => (tab.active = false));
    this.dynamicTabs.forEach(tab => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
    
     // fetch the view container reference from our anchor directive
     const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

     // alternatively...
     // let viewContainerRef = this.dynamicTabPlaceholder;
     viewContainerRef.detach();
     viewContainerRef.insert(tab.component.hostView);

  } 

  closeTab(tab: tabObj) {

    // console.log('tabcount',JSON.parse(sessionStorage.getItem('tabcount')))
     this.count=JSON.parse(sessionStorage.getItem('tabcount'))
     this.count=this.count-1;
     sessionStorage.setItem('tabcount',this.count)
   //  console.log('finaltabcount',this.count)


    // console.log('length',this.dynamicTabs.length);
    for (let i = 0; i < this.dynamicTabs.length; i++) {
      if (this.dynamicTabs[i].component === tab.component) {
        // remove the tab from our array
        this.dynamicTabs[i].component.hostView.destroy;
        this.dynamicTabs.splice(i, 1);

        // destroy our dynamically created component again
        this.dynamicTabPlaceholder.viewContainer.detach;
        // let viewContainerRef = this.dynamicTabPlaceholder;
        //console.log('viewContainerRef.length',viewContainerRef.length);

        // set tab index to 1st one
         if(this.dynamicTabs.length>0){
           this.selectTab2(this.dynamicTabs[i-1]);           
         }
         else{
          this.dynamicTabPlaceholder.viewContainer.clear();
         }
         break;
      }
    }
  }

  closeActiveTab() {
    // const activeTabs = this.dynamicTabs.filter(tab => tab.active);
    // if (activeTabs.length > 0) {
    //   // close the 1st active tab (should only be one at a time)
    //   this.closeTab(activeTabs[0]);
    // }
  }
}

