import { DatePipe } from '@angular/common';
import { User } from './../../../Modules/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';

@Component({
  selector: 'app-ticketprefixdialog',
  templateUrl: './ticketprefixdialog.component.html',
  styleUrls: ['./ticketprefixdialog.component.scss'],
})
export class TicketprefixdialogComponent implements OnInit {

  CID:any;
  PrefixID:any;
  PrefixText:any;
  StartingNum:any;
  TemplateID:any;
  TicketPrefixFormGroup: FormGroup;
  Template:any;
  Flag:any;
  CurLedgerID:any;
  
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<TicketprefixdialogComponent>,private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
     // console.log('dialogdata',this.data)
      this.CID=this.data.cid;
      this.Flag=this.data.Flag;
      this.PrefixID=this.data.PrefixID;
      this.PrefixText=this.data.PrefixText;
      this.StartingNum=this.data.StartingNumber;
      this.Template=this.data.Template;
      this.TemplateID=this.data.TemplateID;

     // console.log('template',this.TemplateID)

      this.TicketPrefixFormGroup = this.formBuilder.group({  
        fcPrefixText:['', Validators.required],     
        fcStartNumber:['',Validators.required],
        fcTemplate:['', Validators.required]     
      });  

      if(this.Flag !='ADD')
        {
         this.TicketPrefixFormGroup.controls.fcPrefixText.setValue(this.PrefixText);
         this.TicketPrefixFormGroup.controls.fcStartNumber.setValue(this.StartingNum);
         this.TicketPrefixFormGroup.controls.fcTemplate.setValue(this.TemplateID);     
        }
    }

  ngOnInit() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }

  onCloseDialog()
  {    
    this.dialogRef.close();
  }

  Submit()
  {
    let params = { 
      CID:this.CID,
      Flag: this.Flag, 
      PrefixID: this.PrefixID, 
      PrefixText:  this.TicketPrefixFormGroup.get('fcPrefixText').value,   
      StartingNumber: this.TicketPrefixFormGroup.get('fcStartNumber').value,
      TemplateID:this.TicketPrefixFormGroup.get('fcTemplate').value,     
      UserID: this.CurLedgerID,
      GroupID:sessionStorage.getItem('groupid')
      
     };
    this.service.httpPost('/TicketPrefixConfig/SeTicketPrefixConfig',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
