import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { DocumentcontrolValidate } from './../../Documents/documentcontrolvalidate';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef,MatDialogConfig,MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { DocumentcontrolComponent } from 'src/app/components/Employee/Documents/documentcontrol/documentcontrol.component';



@Component({
  selector: 'app-popupnotification',
  templateUrl: './popupnotification.component.html',
  styleUrls: ['./popupnotification.component.css']
})
export class PopupnotificationComponent implements OnInit {
  datasource:any
  isLedgeridVisible:boolean=false;
  constructor(public service: DocumentcontrolValidate, public dialogRef: MatDialogRef<PopupnotificationComponent>,
    public DcValidate: DocumentcontrolValidate,
    private dialog: MatDialog,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.getdocumentdetails();
  }
  Refresh()
  {
    this.getdocumentdetails();
  }
  onCloseDialog()
  {
    this.dialogRef.close({event: 'CLEAR'});
  }
  getdocumentdetails()
  {
      // console.log("dddd",this.LedgerID)
       let params = {
       cid:JSON.parse(sessionStorage.getItem('cid')),
       username:"''",
       flag:'LoadPopUp',
       hide:'false'
        }
           this.service.GetExpiryDetails(params)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

         
               this.datasource= result.respdata.Table2;
              //  this.datasource1=result.respdata.Table1;
             }
             else{
              //  this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }

    onFocusedRowChanged(e) {
      console.log('rowclickevent',e.data)
      this.DcValidate.populateForm(e.data);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
        flag: "EDIT",
        Name:e.data.EmpID +" - "+e.data.EmpName,      
        TaskID:e.data.TaskID,
        LedgerID:e.data.LedgerID,
        cid:JSON.parse(sessionStorage.getItem('cid'))
    }
    const dialogRef = this.dialog.open(DocumentcontrolComponent, dialogConfig); 
    dialogRef.afterClosed().subscribe(result => {     
      this.getdocumentdetails();     
    });      
    }

}
