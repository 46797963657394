import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisableDataService {

  constructor() { }
  getFederalHolidays() : Date[] {
    return disabledate;
  }
}


const disabledate: Date[] = [
  new Date(2023, 2, 1),
  new Date(2023, 2, 2),
  new Date(2023, 2, 3),
  new Date(2023, 2, 4),
  new Date(2023, 2, 5),
  new Date(2023, 2, 6),
  new Date(2023, 2, 7),
  new Date(2023, 2, 13),
  new Date(2023, 2, 14),
  new Date(2023, 2, 15),
  new Date(2023, 2, 16),
  new Date(2023, 2, 17),
  new Date(2023, 2, 18),
  new Date(2023, 2, 19),
  new Date(2023, 2, 20),
  new Date(2023, 2, 21),
  new Date(2023, 2, 22),
  new Date(2023, 2, 23),
  new Date(2023, 2, 24),
  new Date(2023, 2, 25),
  new Date(2023, 2, 26),
  new Date(2023, 2, 27),
];

