import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'; //MatPaginator, MatSort, MatTableDataSource
import { MatPaginator} from '@angular/material/paginator'; 
import { MatSort} from '@angular/material/sort'; 
import { MatTableDataSource} from '@angular/material/table'; 
import { AppError } from '../../../../../app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from '../../../../../app/Services/httpServices/ErrorHandler/bad-input';
import { NotificationService } from '../../../../../app/Services/OtherServices/notification.service';
import {  EmpRegValidate } from '../emp-reg-Validate';
import {EmpRegComponent} from './../emp-reg/emp-reg.component'
import { UploadprofilepicComponent } from '../../../../components/HR/EmployeeRegistration/uploadprofilepic/uploadprofilepic.component';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { User } from '../../../../../app/Modules/user';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-emp-reg-list',
  templateUrl: './emp-reg-list.component.html',
  styleUrls: ['./emp-reg-list.component.css']
})
export class EmpRegListComponent implements OnInit {

  EmployeeList:any;//MatTableDataSource<GroupALL>;

  displayedColumns =['EmpID','Name','Designation','joindate','Status','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  searchKey: string;
  popupVisible = false;
  Companies:any;
  CID:any;
  username:string;

  constructor(private service:EmpRegValidate,private generic: GenericService,private datePipe: DatePipe,
    private NotificationService:NotificationService,private dialogService: DialogService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.username=curUser.username;
    this.CID=JSON.parse(sessionStorage.getItem('cid')); 
    this.getEmployeeDetails();
    //console.log('ciddfsdfg',this.CID);
    
  }

  onCompanySelect(event)
  {
    this.CID=event;
    this.getEmployeeDetails();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.EmployeeList.filter = filterValue.trim().toLowerCase();
  }

  doFilter(filterValue: string) {
    console.log(filterValue);
    this.EmployeeList.filter = filterValue;
  }

  onSearchClear(){
    this.searchKey="";
    this.EmployeeList.filter="";
  }

  Refresh(){
    this.getEmployeeDetails();
  }
  exportAsExcel()
  {
    this.generic.excelExport(this.EmployeeList.data, 'Employee List', 'Employee List' );
  }
  getEmployeeDetails()
  {
    let params = {
      cid: this.CID,
      flag:"LOADGRID",
      ledgerid:"0",
      empid:"0"     
        }
        this.service.getEmployeeconfig(params)
        .subscribe(data => {
          let result: any;
          result=data;
          if (result.errno === 0){
            //this.companies = result.respdata.Table2;            
            this.EmployeeList = result.respdata.Table;
            this.Companies=result.respdata.Table1;
            this.EmployeeList = new MatTableDataSource(result.respdata.Table);
            this.EmployeeList.sort = this.sort;
            this.EmployeeList.paginator = this.paginator;
          }
          else{
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
  }


  showInfo(data){

  }


  AddNewEmployee()
  {
    
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus =  true;
     dialogConfig.data = {
       flag: 'ADD',
       ledgerID:0,
       cid: this.CID
      // Rolelist:this.Rolelist,
     }
     const dialogRef = this.dialog.open(EmpRegComponent, dialogConfig)
     dialogRef.afterClosed().subscribe(result => {     
      this.getEmployeeDetails();     
    });
  
}

onUpload(data, _flag,ledgerid){
  const dialogConfig = new MatDialogConfig();
//  console.log('data',data,_flag,ledgerid);
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus =  true;
  dialogConfig.data = {
    flag: _flag,
    empid:data,
    ledgerID:ledgerid,
    cid: this.CID
    // entity:this.entity,
    // Rolelist:this.Rolelist 
  }
  this.dialog.open(UploadprofilepicComponent, dialogConfig);        
}

  onEdit(data, _flag,ledgerid){
    const dialogConfig = new MatDialogConfig();
    //console.log('data',data);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: _flag,
      empid:data,
      ledgerID:ledgerid,
      cid: this.CID
      // entity:this.entity,
      // Rolelist:this.Rolelist 
    }
    const dialogRef =this.dialog.open(EmpRegComponent, dialogConfig);        
    dialogRef.afterClosed().subscribe(result => {     
      this.getEmployeeDetails();     
    });
  }

  onDelete(data, _flag){
    const curDate = new Date();
    console.log('data',data)
    this.dialogService.openConfirmDialog('Are you sure to inactive this employee?')
   .afterClosed().subscribe(res => {
   if (res) {
    const params = {
      CID:this.CID,
      Flag:'DELETE',
      EmployeeID:data,
      UserID:"",
      CostPerHour:0,        
      FingerPrintID:'',
      UserName:this.username,
      Image:"",
      CurDate: this.datePipe.transform(curDate, 'dd-MM-yyyy'),
      CreateLogin:0,
      GroupID:sessionStorage.getItem('groupid')
        };
        this.service.setprojectconfig(params)
        .subscribe(
          (data) => {
            let users: any;
            users = data; //result.users;
            // console.log('create:', users.errno);
  
            if (users.errno === 0){
              this.NotificationService.warn('Employee inactive successfully');
              this.getEmployeeDetails();     
            }
            else{
              this.NotificationService.success(users.errdesc); //':: Problem updating user');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)
              console.log('Error: ', error);
              // this.service.form.setErrors(error.originalError);
            else throw error;
          });
        }
      });
  }

}

// export interface GroupALL {
//   ID: string;
//   Name: string;
//   status : string;
  

// }
