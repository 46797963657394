import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  btnvisibility;

  constructor() { }

  assignButtonVisibility(formRights){
    let addflag = false; let editflag = false; let deleteflag = false; let viewflag = false;let approveflag = false;let refreshflag = false;
    let allempflag = false;

    for (const inv in formRights)
    {
        const val = formRights[inv];
       //  console.log('flag : ', val.Options.toUpperCase());
        if (val.Options.toUpperCase() === 'ADD'){
          addflag = true;
        }else if (val.Options.toUpperCase() === 'EDIT'){
          editflag = true;
        }else if (val.Options.toUpperCase() === 'DELETE'){
          deleteflag = true;
        }else if (val.Options.toUpperCase() === 'VIEW'){
          viewflag = true;
        }
        else if (val.Options.toUpperCase() === 'APPROVE'){
          approveflag = true;
        }
        else if (val.Options.toUpperCase() === 'REFRESH'){
          refreshflag = true;        
        }
        else if (val.Options.toUpperCase() === 'LOADALLEMP'){
          allempflag = true;        
        }
    }

    // console.log('add: ', addflag, ' edit: ', editflag, ' deleteflag: ', deleteflag, ' view: ', viewflag);
    return {addflag: addflag, editflag: editflag, deleteflag: deleteflag, viewflag: viewflag, approveflag: approveflag, refreshflag :refreshflag ,allempflag :allempflag};
  }

  buttonVisibility(formRights){
    this.btnvisibility = Observable.create((observer) => {
      let addflag: boolean; let editflag: boolean; let deleteflag: boolean; let viewflag: boolean;

      for (const inv in formRights)
      {
          const val = formRights[inv];
          if (val.Options.toUpperCase() === 'ADD'){
            addflag = true;
          }else if (val.Options.toUpperCase() === 'EDIT'){
            editflag = true;
          }else if (val.Options.toUpperCase() === 'DELETE'){
            deleteflag = true;
          }else if (val.Options.toUpperCase() === 'VIEW'){
            viewflag = true;
          }
      }

      observer.next({addflag: addflag, editflag: editflag, deleteflag: deleteflag, viewflag: viewflag});
    });
  }

  excelExport(data: any, sheetname: string, filename: string){
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetname);
    XLSX.writeFile(workBook, filename+'.xlsx');
  }

  getWeblink(){
    let conObj = JSON.parse( localStorage.getItem('conObj'));
    return conObj.webLink || '';
  }
}
