import { CurrentyearcarryforwardComponent } from './../currentyearcarryforward/currentyearcarryforward.component';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'underscore/underscore-min.js';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe} from '@angular/common';
import { HrpoliciesviewComponent } from './../hrpoliciesview/hrpoliciesview.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';

@Component({
  selector: 'app-hrpolicies',
  templateUrl: './hrpolicies.component.html',
  styleUrls: ['./hrpolicies.component.css']
})
export class HrpoliciesComponent implements OnInit {

  HrPolicyFormGroup:FormGroup;
  dataSource:any;
  Companies:any;
  Timezone:any;
  Years:any;
  Days:any;
  fcFullDay=new FormControl();
  removable = true;
  cid:any;
  addbtnvisible: boolean = false;
  editbtnvisible: boolean = false;
  deletebtnvisible: boolean = false;
  viewbtnvisible: boolean = false;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private generic: GenericService,private formBuilder: FormBuilder, private service1: UserService, 
  private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService,
  public dialogRef: MatDialogRef<HrpoliciesviewComponent>) 
   {
       this.HrPolicyFormGroup = this.formBuilder.group
       ({  
          fcCompany: ['', Validators.required] ,             
          fcTimezone: ['', Validators.required] ,
          fcYear:['',Validators.required],
          fcFullDay:['',Validators.required],
          fcHalfDay:[''],
          fcWorkingHrs:['',Validators.required]
      });       
   }

  ngOnInit(): void {    
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.getddllist();  
    this.HrPolicyFormGroup.controls.fcCompany.setValue(this.cid);
    let year = new Date().getFullYear();
    this.HrPolicyFormGroup.controls.fcYear.setValue(year);   
  }

  remove(full: string) {
    const toppings = this.fcFullDay.value as string[];
    this.removeFirst(toppings, full);
    this.fcFullDay.setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  changesite(e) {
    this.cid=e.value;
    this.getddllist();
  }

  getddllist()
  {
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",         
    };
    this.service.httpPost('/HRPolicies/GetHRPolicies',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;         
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.Timezone = result.respdata.Timezone;
            this.Years=result.respdata.Year;
            this.Days = result.respdata.Days;                              
            this.dataSource=result.respdata.Datasource; 
            this. getUserList();                   
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_13",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag;
      this.editbtnvisible = btnVisilityData.editflag;
      this.deletebtnvisible = btnVisilityData.deleteflag;
      this.viewbtnvisible = btnVisilityData.viewflag;
   
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  Submit() { 
    const filteredcolumn = this.dataSource.map(item => { return {
      Date: this.datePipe.transform(item.Date, 'dd-MM-yyyy'),
      Description: item.Description
    }});

    let params = {     
      CID: this.HrPolicyFormGroup.get('fcCompany').value,
      Flag: "ADD",           
      Timezone:this.HrPolicyFormGroup.get('fcTimezone').value,
      Year:this.HrPolicyFormGroup.get('fcYear').value,
      WeekEnd:this.fcFullDay.value,
      HalfDay:this.HrPolicyFormGroup.get('fcHalfDay').value,
      WorkingHrs:this.HrPolicyFormGroup.get('fcWorkingHrs').value,
      dtHolidays: filteredcolumn          
    };
    this.service.httpPost('/HRPolicies/SetHRPolicies',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

  Edit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      CID:this.HrPolicyFormGroup.get('fcCompany').value,
      Flag:'EDIT'
    }
    const dialogRef = this.dialog.open(HrpoliciesviewComponent, dialogConfig)
    dialogRef.afterClosed()
  }

  View(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      CID:this.HrPolicyFormGroup.get('fcCompany').value,
      Flag:'View'
    }
    const dialogRef = this.dialog.open(HrpoliciesviewComponent, dialogConfig)
    dialogRef.afterClosed()
  }

  Delete(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;  
    dialogConfig.data = {       
      CID:this.HrPolicyFormGroup.get('fcCompany').value,
      Flag:'DELETE'
    }
    const dialogRef = this.dialog.open(HrpoliciesviewComponent, dialogConfig)
    dialogRef.afterClosed()
  }
  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
            this.HrPolicyFormGroup.reset();
            this.dialogRef.close();
          }
        });
  }

  onClear()
  {
    this.HrPolicyFormGroup.reset();       
    this.fcFullDay.reset();   
    this.dataSource='';
    this.HrPolicyFormGroup.controls.fcCompany.setValue(this.cid); 
    let year = new Date().getFullYear();
    this.HrPolicyFormGroup.controls.fcYear.setValue(year);   
  }

  logEvent(eventName, e)
  {
  }
  onDialog()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;  
    dialogConfig.data = {       
      CID:this.HrPolicyFormGroup.get('fcCompany').value,
      Flag:'edit'
    }
    const dialogRef = this.dialog.open(CurrentyearcarryforwardComponent, dialogConfig)
    dialogRef.afterClosed()
  }

}
