import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { DatePipe  } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { LicensecreatesubComponent } from '../licensecreatesub/licensecreatesub.component';

@Component({
  selector: 'app-licensereport',
  templateUrl: './licensereport.component.html',
  styleUrls: ['./licensereport.component.css']
})


export class LicensereportComponent {

  dataSource:any;
  LicenseReportFormGroup:FormGroup;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private generic: GenericService,private formBuilder: FormBuilder, private service1: UserService, 
    private datePipe: DatePipe,private dialog: MatDialog,private dialogService: DialogService) 
    { 

      this.LicenseReportFormGroup = this.formBuilder.group({  
              
      });       
    }

    ngOnInit(): void {
      this.Pageload();
     }

     Submit()
     {
      this.Pageload();
     }

     Pageload()
     {
       let params = {  
         CID:101, 
         Flag: "LICREPORT"
       };
       this.service.httpPost('/LicenseCreate/Getdropdownvalues',params)
         .subscribe (
           (data) => {
             let result: any;
             result = data;            
             if (result.errno === 0) {                  
               this.dataSource = result.respdata.Licensedata;             
             }
             else {
               this.NotificationService.success(result.errdesc); //':: Problem details');
             }
           },
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
     }
     
     onFocusedRowChanged(e) {  
       const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;       
        dialogConfig.data = {                 
                pid:0,
                height: '350px',
                width: '70%',                            
                Flag:'VIEW' ,        
                Slno:e.data.SlNo,     
                CompanyName:e.data.CompanyName
            }                   
            const dialogRef =  this.dialog.open(LicensecreatesubComponent, dialogConfig)
            dialogRef.afterClosed().subscribe(result => {     
              this.Pageload();     
            });
    }

      
    onRowPrepared (e) {
   
      if (e.rowType === "data") {       
          if (e.data.Expired=== 0 ) {                   
             e.rowElement.style.backgroundColor ='#ffcccc';  
          }
          if (e.data.Shortly ===0) {        
              e.rowElement.style.backgroundColor ='#FFE8A8';        
         }
      }
    }


}
