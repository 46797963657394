import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';

@Component({
  selector: 'app-pstemplate',
  templateUrl: './pstemplate.component.html',
  styleUrls: ['./pstemplate.component.scss'],
})
export class PstemplateComponent implements OnInit {
  form: FormGroup;
  cid:any=101;
  flag:any;
  prefixlist:any;
  particularslist:any;
  VouNo:any;
  Description:any;
  Interval:any;
  Comment:any;
  Prefix:any;
  VoucherNo:any;
  Revno:any;
  flag_:any;
  



  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,private service:HttpserviceService,private NotificationService:NotificationService,public dialogRef: MatDialogRef<PstemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.cid=this.data.cid;
      this.flag_=this.data.Flag;
      this.VouNo=this.data.VouNo;

    this.form = this.fb.group({
      items: this.fb.array([])
    });
  }

  ngOnInit(): void {
    if (this.flag_=='ADD')
      {
       this.flag='LOADCOMBO'
      }
      else
      {
        let parts = this.data.VouNo.split('/');
        this.Prefix=parts[0]+'/'
        this.VoucherNo=parts[1]
         this.flag='GET'
      }
   
    this.LoadData();
    this.addItem('',0); // Add one initial item in ngOnInit
  }

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  createItem(item: any = { Slno:'',Tag: '', CustomText: '', Formula: '', Parameter: false, AffectNetAmount: false, Visibility: false, Readonly: false, PSMainTag: '' },index: number): FormGroup {
    const formGroup = this.fb.group({
      Slno: [index + 1 , Validators.required],
      Tag: [item.Tag, Validators.required],
      CustomText: [{ value: item.CustomText, disabled: true }, Validators.required],
      Formula: [item.Formula, Validators.required],
      Parameter: [item.Parameter],
      AffectNetAmount: [item.AffectNetAmount],
      Visibility: [item.Visibility],
      Readonly: [item.Readonly],
      PSMainTag: [item.PSMainTag, Validators.required]

    });

    formGroup.get('Tag')!.valueChanges.subscribe((newValue) => {
      formGroup.get('CustomText')!.setValue(newValue);
    });

    return formGroup;
  }

  addItem(item: any = { Slno:'',Tag: '', CustomText: '', Formula: '', Parameter: false, AffectNetAmount: false, Visibility: false, Readonly: false, PSMainTag: '' }, index: number = this.items.length): void {
    this.items.push(this.createItem(item,index));
    this.cd.markForCheck(); // Use markForCheck to update the view
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
    this.cd.markForCheck(); // Use markForCheck to update the view
  }

  Submit(): void {
    console.log('Form submitted:', this.form.value);
    const initialValues=this.form.value

 
      const params = {
         int_CID:this.cid,
         str_Flag:this.flag_,
         str_VouNo:this.Prefix+this.VoucherNo,
         int_RevNo :this.Revno==undefined?0:this.Revno,
         str_MenuID:'ERP_252',
         str_Prefix:this.Prefix,
         str_Interval :this.Interval,
         str_Description :this.Description,
         str_Comment:this.Comment,
         dt_PSTemplatesub:initialValues.items 
    };  
    console.log('params',params)
    this.service.httpPost('/PSTemplate/SetPSTemplate?',params)
    .subscribe(
      (data) => {
        let users: any;
        users = data; //result.users;
        if (users.errno === 0){
          this.NotificationService.success(':: Submitted sucessfully');
          // this.GoalID=users.respdata;
          // this.groupenable = this.GoalID !== 0 ? false : true;
        }
        else{
          this.NotificationService.success(users.errdesc); //':: Problem updating user');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });
    } 
    
  LoadData()
  {
    const params = {
      cid: this.cid,
      Flag:this.flag,
      VouNo:this.VouNo
      
    };   
    const query = `cid=${params.cid}&Flag=${params.Flag}&VouNo=${params.VouNo}`;

    this.service.httpGet('/PSTemplate/GetPSTemplate?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data; 
      
         if (this.flag=='LOADCOMBO')
          {
            this.particularslist=result.respdata.Table;
            this.prefixlist=result.respdata.Table1;
            
          }
          else if (this.flag=='GET')
          {

            console.log('amjath',this.flag)
            this.particularslist=result.respdata.Table;
            this.prefixlist=result.respdata.Table1;
            this.Description=result.respdata.Table2[0].Description;
            this.Interval=result.respdata.Table2[0].Interval;
            this.Comment=result.respdata.Table2[0].Comment;
            this.Revno=result.respdata.Table2[0].Revno;

            const initialValues = result.respdata.Table3;
            this.items.clear();

           initialValues.forEach(value => this.addItem(value,value.Slno-1));
           
          }

    
     
     
      
        
    });
  }
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
}
