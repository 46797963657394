import { ChangeDetectorRef, Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
interface ReferenceList {
  value?: string;
  display?: string;
  id?: any;
  checked?: boolean;
}

@Component({
  selector: 'app-empcategory',
  templateUrl: './empcategory.component.html',
  styleUrls: ['./empcategory.component.css']
})
export class EmpcategoryComponent {
  form: FormGroup;
  companies:any;
  CID:any;
  flag:any='';
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  isDarkTheme: boolean = false; // Toggle between themes
  cities = [];
  selectedCities = [];
 

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,private service:HttpserviceService,private NotificationService:NotificationService) 
    {
      this.form = this.fb.group({
        items: this.fb.array([])
      });


      this.cities = [
        { id: 1, display: 'Nagpur' },
        { id: 2, display: 'Hyderabad' },
        { id: 3, display: 'Wardha' },
        { id: 4, display: 'Pune' },
        { id: 5, display: 'Mumbai' },
        { id: 6, display: 'Bengaluru' },
        { id: 7, display: 'Delhi' },
        { id: 8, display: 'Indore' },
        { id: 9, display: 'Malegao' },
        { id: 10, display: 'Ahemdabad' },
        { id: 11, display: 'Goa' },
        { id: 12, display: 'Panaji' },
        { id: 13, display: 'Chandigarh' },
        { id: 14, display: 'Noida' },
        { id: 15, display: 'Ramnagar' },
      ];
      this.selectedCities = [];
    }

    onSelect(event) {
      console.log('event', event);
    }
    ngOnInit(): void {
      this.CID= JSON.parse(sessionStorage.getItem('cid'))
      this.companies = this.curUser.dtsiteid; 
      console.log('this.companies',this.companies)

     
      
    }
    get items(): FormArray {
      return this.form.get('items') as FormArray;
    }
    add()
    {
      if(this.flag=='')
        {
          this.addItem(); // Add one initial item in ngOnInit
        }
     
      this.flag='ADD';
    }
    edit()
    {
       this.flag='EDIT';
       this.LoadData();
    }
    delete()
    {
       this.flag='DELETE';
       this.LoadData();
    }

    createItem(item: any = { Category:'',LeaveType: '', DaysinMonth: '', LeaveSalary: '', Gratuity: '', DaysinYear: '', VacationDays: '', CarryForward: '', PassageAmount: '' }): FormGroup {
      const formGroup = this.fb.group({
        Category: [item.Category , Validators.required],
        LeaveType: [item.LeaveType, Validators.required],
        DaysinMonth: [item.DaysinMonth, Validators.required],
        LeaveSalary: [item.LeaveSalary, Validators.required],
        Gratuity: [item.Gratuity, Validators.required],
        DaysinYear: [item.DaysinYear, Validators.required],
        VacationDays: [item.VacationDays, Validators.required],
        CarryForward: [item.CarryForward, Validators.required],
        PassageAmount: [item.PassageAmount, Validators.required]
  
      });
  
      // formGroup.get('Tag')!.valueChanges.subscribe((newValue) => {
      //   formGroup.get('CustomText')!.setValue(newValue);
      // });
  
      return formGroup;
    }
  
    addItem(item: any = { Category:'',LeaveType: 'STATIC', DaysinMonth: '30', LeaveSalary: 'AUTOMATIC', Gratuity: 'AUTOMATIC', DaysinYear: '365', VacationDays: '30', CarryForward: '0', PassageAmount: '0' }): void {
      this.items.push(this.createItem(item));
      this.cd.markForCheck(); // Use markForCheck to update the view
    }
  
    removeItem(index: number): void {
      this.items.removeAt(index);
      this.cd.markForCheck(); // Use markForCheck to update the view
    }
    LoadData()
    {
      const params = {
        str_SiteID:this.CID,
         int_BusinessPeriodID:101,
         str_flag:this.flag,
       
    };  
    console.log('params',params)
    this.service.httpPost('/EmpCategory/GetEmpCategory?',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data; //result.users;
        if (result.errno === 0){

          console.log('ttt',result.respdata)
         // this.items=result.respdata;

         for (let j = 0; j < result.respdata.length; j++) {
          // if(accuredData[j].LeaveTypeID===leaveTypeData[i].LeaveTypeID)
          // {
            //let taskData={'AccrualType':accuredData[j].AccrualType,'NoofDays':accuredData[j].NoofDays,'NoOfMonthsFromJoingDate':accuredData[j].NoOfMonthFromJoiningDate}                      
            //AccrualTypes.push(taskData);    
                         
          // }       
          let item = { Category:result.respdata[j].Category,LeaveType:result.respdata[j].LeaveType, DaysinMonth:result.respdata[j].DaysinMonth, LeaveSalary:result.respdata[j].LeaveSalary, Gratuity: result.respdata[j].Gratuity, DaysinYear: result.respdata[j].DaysinYear, VacationDays:result.respdata[j].VacationDays, CarryForward:result.respdata[j].CarryForward, PassageAmount:result.respdata[j].PassageAmount }   
          this.items.push(this.createItem(item));
        }


        
      
          //this.NotificationService.success('Submitted sucessfully');
          // this.GoalID=users.respdata;
          // this.groupenable = this.GoalID !== 0 ? false : true;
        }
        else{
          this.NotificationService.success(result.errdesc); //':: Problem updating user');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });
    }

    Submit(): void {
      console.log('Form submitted:', this.form.value);
      const initialValues=this.form.value
  
   
        const params = {
          str_SiteID:this.CID,
           int_BusinessPeriodID:101,
           str_flag:this.flag,
           dt_empcategory :initialValues.items,
         
      };  
      console.log('params',params)
      this.service.httpPost('/EmpCategory/SetEmpCategory?',params)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
          if (users.errno === 0){
            this.NotificationService.success('Submitted sucessfully');
            // this.GoalID=users.respdata;
            // this.groupenable = this.GoalID !== 0 ? false : true;
          }
          else{
            this.NotificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
      } 
      
      toggleTheme(): void {
        this.isDarkTheme = !this.isDarkTheme;
      }
}
