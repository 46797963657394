import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';

@Component({
  selector: 'app-empsalaryhikeposting',
  templateUrl: './empsalaryhikeposting.component.html',
  styleUrls: ['./empsalaryhikeposting.component.scss'],
})
export class EmpsalaryhikepostingComponent implements OnInit {
  cid:any=101;
  VouNo:any='';
  LedgerID:any=0;
  Category:any=0;
  showSpinner:boolean=false
  dataSource:any;
  columns: Array<any> = [];
  Employeelist:any;
  Categorylist:any;
  Typelist:any;
  Prefixlist:any;
  Prefix:any
  VouDate:any=new Date() ;
  Tag:any;
  Percentage:any;
  flag:any="ADD";
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

  constructor(private service:HttpserviceService,private datePipe: DatePipe,private dialog: MatDialog,private NotificationService: NotificationService, @Inject(MAT_DIALOG_DATA) public data: any)
   {
    this.LoadData()
    console.log('dialogRef',data)
    this.flag=data.flag
    if(this.flag=='EDIT')
      {
        let parts = data.VouNo.split('/');
        this.Prefix=parts[0]+'/'
        this.VouNo=parts[1]
        this.Getstructure()
      }
    
   }

  ngOnInit() 
  {
  
  }
  Populate()
  {
    this.Getstructure()
  }
  LoadData()
  {
    //this.showSpinner=true;
     const params = {
      int_CID: this.cid,
      str_VouNo:'ERP_225',
      dtp_VoucherDate:this.datePipe.transform(new Date(),"MM-dd-yyyy")  ,
      CreatedDate:this.datePipe.transform(new Date(),"MM-dd-yyyy"),
      str_Tag:'',// this.DateTypeFlag,
      str_Flag:'LoadCombo'
      
     };   
 
     
    // const query = `cid=${params.cid}&MenuID=${params.MenuID}&Date1=${params.Date1}&FromDate=${params.FromDate}&ToDate=${params.ToDate}`;
 
 
     this.service.httpPost('/EmpSalaryHike/GetLoadCombo?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      
 
       console.log("mm",result.respdata)

     
       this.showSpinner=true;
       setTimeout(() =>{
         this.showSpinner=false;
       },
       800)  
      
       this.Employeelist= result.respdata.Employee; 
       this.Categorylist= result.respdata.Category; 
       this.Typelist= result.respdata.Taglist; 
       this.Prefixlist= result.respdata.Prefix; 
       this.Prefix=this.Prefixlist[0].Prefix;

         console.log('dataSource',this.dataSource)
        // this.columnsData=result.respdata.columnNames;
         //this.columnsData.push({ header: 'actions', field: 'actions', width: '100px' })
         //this.tableData= result.respdata.dt_Main; 
        
         //this.handleChildClick();
        
        
     });
  }
  Getstructure()
  {
    //this.showSpinner=true;
     const params = {
      int_CID: this.cid,
      str_VouNo:this.Prefix+this.VouNo,
      str_LedgerID:this.LedgerID,
      str_Flag:this.flag,
      str_Category:this.Category==0 ? '--ALL--':this.Category,
      dtp_VoucherDate:new Date(),
      int_BusinessPeriodID:105,
      str_Tag:'',
      dbl_Percentage:this.Percentage
  
     };   
 
     
    // const query = `cid=${params.cid}&MenuID=${params.MenuID}&Date1=${params.Date1}&FromDate=${params.FromDate}&ToDate=${params.ToDate}`;
 
 
     this.service.httpPost('/EmpSalaryHike/GetEmpSalHike?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      
 
       console.log("mm",result.respdata)

       

       this.showSpinner=true;
       setTimeout(() =>{
         this.showSpinner=false;
       },
       800)  
         this.dataSource=[];
         this.dataSource = result.respdata.dt_Main; 

         console.log('dataSource',this.dataSource)
        // this.columnsData=result.respdata.columnNames;
         //this.columnsData.push({ header: 'actions', field: 'actions', width: '100px' })
         //this.tableData= result.respdata.dt_Main; 
        
         //this.handleChildClick();
        
        
     });
  }
  onRowUpdating(e)
  {

  }
  onEditorPreparing(e)
  {

  }
  Save()
  {


    let dt2 =[] ;
    let Ledgerid; 

   
      for (let i = 0; i < this.dataSource.length; i++) {
          let drow = this.dataSource[i];
          for (let prop in drow) {
            if (drow.hasOwnProperty(prop)) {
                let value = drow[prop];
                if(`${prop}`=='LedgerID')
                  {
                    Ledgerid=value
                  }
                if(prop!='LedgerID'&& prop!='EmpID'&& prop!='Name'&& prop!='Category')
                  {
                    if(value!=0&&value!=undefined&&value!='')
                      {
                        dt2.push({'LedgerID':Ledgerid,'Tag':prop,'Value':value})
                      }
                    
                  }
            }
        }
      }
 


    const params = {
      int_CID: this.cid,
      str_VouNo:this.flag=='ADD'?'':this.Prefix+this.VouNo,
      str_FormPrefix:this.Prefix,
      
      // str_MenuID:"ERP_225",
      dt_EmpDocumnet:dt2,
      dtp_VoucherDate: this.datePipe.transform( this.VouDate,"MM-dd-yyyy"),
      str_Flag:this.flag,
      str_CreatedBy:this.curUser.username,
      dtp_CreatedDate:this.datePipe.transform(new Date(),"MM-dd-yyyy HH:mm:ss"),
      str_LastUpdatedBy:this.curUser.username,
      dtp_LastUpdatedDate:this.datePipe.transform(new Date(),"MM-dd-yyyy HH:mm:ss"),
      str_ApprovedBy:this.curUser.username,
      dtp_ApprovedDate:this.datePipe.transform(new Date(),"MM-dd-yyyy HH:mm:ss"),
      bool_ApprovedStatus:1
    
     
    };   
    
    
    this.service.httpPost('/EmpSalaryHike/SetEmpSalHike?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;   
     
    
      console.log('result',result)
      if (result.errno === 0)
      {
        this.NotificationService.success('Updated Successfully');
      }
      else
      {
        this.NotificationService.warn(result.errdesc);
      }
    
      
        
    });
  }
  onRowDoubleClick(event) {
    // Handle the double-click event here
    console.log('Row double-clicked:', event.data); // Access the data of the double-clicked row
    // Open your dialog or perform any action you want
  }
  customizeColumns(columns) {
    columns.push({
        type: "buttons",
        width: 100,
        buttons: [{
            hint: "Show Previous Hike",
            icon: "info",
            onClick: (e) => {
                // Handle button click event here
                console.log("Custom button clicked", e.row.data);

                //this.Getstructure()
            }
        }]
    });
}
}
