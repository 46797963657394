import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { NeworderService } from '../neworder.service';
import { User } from './../../../../Modules/user';
import {Observable} from 'rxjs';
import {startWith, map, pluck} from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { NgxImageCompressService } from 'ngx-image-compress';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialogRef} from '@angular/material/dialog';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';

interface Customer {
  id: number;
  name: string;
}

@Component({
  selector: 'app-neworder',
  templateUrl: './neworder.component.html',
  styleUrls: ['./neworder.component.css']
})
export class NeworderComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  customerControl = new FormControl('', Validators.required);
  salesmanControl = new FormControl('', Validators.required);
  
  imageUrl: string = "assets/images/default-image.png";
  //imageUrl: File;
  fileToUpload: File[] = [];
  showSpinner:any;
  ledgerid: string;
  customername: string;
  userid: string;

  customers: Customer[];
  salesman: Customer[];

  customernames: string[]= [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

  // imgResultBeforeCompress:string;
  imgResultAfterCompress:string;
 
  file: any;
  localUrl: any;
  // localCompressedURl:any;
  // sizeOfOriginalImage:number;
  sizeOFCompressedImage:number;

  spinnervisible: boolean = false;
  buttonvisible: boolean = true;

  selectedFiles: string[] = [];
  selectable = true;
  removable = true;

    
  constructor(private newOrderService: NeworderService,private NotificationService:NotificationService, private service:HttpserviceService,public dialogRef: MatDialogRef<NeworderComponent>,
    private imageCompress: NgxImageCompressService) { }

  ngOnInit(): void {
    this.loadCustomerAndSalesman();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.customernames.filter(option => option.toLowerCase().includes(filterValue));
  }


  loadCustomerAndSalesman(){
    const query = `cid=${JSON.parse(sessionStorage.getItem('cid'))}&salesmanid=${this.curUser.salesmanid}`;
    this.service.httpGet('/salesorder/getcustomersalesman?' ,query)  
  //  this.newOrderService.getCustomerAndSalesman(101)
    .subscribe(data =>{
      var result = data as any;
      result = data;      
      this.customers = result.respdata.Customers;
      this.customernames = _.pluck(this.customers, 'name');

    });
  }
 
  selectedcustomer(event) {
    // console.log('items: ', this.customers);
    this.ledgerid = JSON.parse(_.pluck(_.where(this.customers, { 'name': event.option.value }), 'id'));
    this.customername = event.option.value;
  }

  onCloseDialog()
  {
    // this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    // .afterClosed().subscribe(res => {
      //if (res) {
          // this.AdvanceRequestViewFormGroup.reset();
          this.dialogRef.close();
        //  }
      // });
  }

  customerSelection(customer){
    console.log(customer);
    this.ledgerid = customer.value['id'];
    this.customername = customer.value['name'];
    console.log('id: ',this.ledgerid);
    console.log('name: ',this.customername);
    
  }
  
  
  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  selectFile(event: any) {
    // this.fileToUpload= [];
        
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      this.spinnervisible = true;
      for (let i = 0; i < filesAmount; i++) {
        let  fileName : any;
        this.file = event.target.files[i];
        fileName = this.file['name'];
        this.selectedFiles.push(fileName);
        // console.log('filename inside selectfile: ', fileName);
        var reader = new FileReader();

        reader.onload = (event1:any) => {
          this.localUrl = event1.target.result;
          this.compressImage(this.localUrl,fileName)

        }

        reader.readAsDataURL(event.target.files[i]);
      } 
      
      this.spinnervisible = false;
    }

    console.log('Select: ', this.fileToUpload);
  }

  compressImage(image,fileName) {
    try{
      var orientation = -1;
      console.log("image",image)
      // this.sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
      // console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        async result => {
        this.imgResultAfterCompress = result;
        // console.log('imgResultAfterCompress: ', this.imgResultAfterCompress);
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024)
        // console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        console.log('after blob creation',imageBlob);
        //imageFile created below is the new compressed file which can be send to API in form 
        this.fileToUpload.push(new File([imageBlob], imageName, { type: 'image/jpeg' }));
        console.log('File to upload: ', this.fileToUpload);
        
      });
    }
    catch (Error) {
      alert(Error.message);
    }
    
  }
  
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  viewImage(file: string){
    const index = this.selectedFiles.indexOf(file);

    if (index >= 0) {

      Array.from(this.fileToUpload).forEach(async uploadedfile => {
         if (uploadedfile.name == file){
          let result1:string = await this.getBase64Image(uploadedfile);
          this.imageUrl = result1;
         }
      });

    }
  }

  remove(file: string): void {
    const index = this.selectedFiles.indexOf(file);

    if (index >= 0) {
      Array.from(this.fileToUpload).forEach(uploadedfile => {
         console.log(uploadedfile.name) 
         if (uploadedfile.name == file){
           this.fileToUpload.splice(index, 1);
         }
      });
      this.selectedFiles.splice(index, 1);
      this.imageUrl = "assets/images/default-image.png";
    }

    console.log('Remove: ', this.fileToUpload);
  }  

  async getBase64Image(imageBlob) {
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(imageBlob);
    });
    return reader.result.toString();//.replace(/^data:.+;base64,/, '');
  }
  
  OnSubmit(Caption, Image){
    this.showSpinner=true;
    this.buttonvisible = false;
    // console.log('final file list: ', this.fileToUpload);
    this.postFile(Caption.value, this.fileToUpload, this.ledgerid, this.customername, this.curUser.salesmanid, this.curUser.username)
    Caption.value = null;
    Image.value = null;
  }

  postFile(caption: string, fileToUpload: File[], customerLedger: string, customername: string, salesmanid: number, username: string){
    const formData: FormData = new FormData();
    let index: number = 1;
    fileToUpload.forEach(function (value) {
      formData.append('Image' + index.toString(), value, value.name);
     // console.log(value);
      index = index + 1;
    });

    // formData.append('Image', fileToUpload, fileToUpload.name);
    formData.append('ImageCaption', caption);
    formData.append('CustomerLedger', customerLedger);
    formData.append('CustomerName', customername);
    formData.append('SalesmanID', salesmanid.toString());
    formData.append('UserName', username);
    formData.append('Flag',  'newOrder');
    formData.append('cid',  JSON.parse(sessionStorage.getItem('cid')));

    console.log("dd",formData)
   // return this.http.post(this.baseUrl+'/salesmanorder', formData)
   this.service.httpPost('/salesorder/salesmanorder' ,formData)  
   .subscribe(res =>{
    let result: any;
    result = res;  
    console.log('response data',result) 
    this.showSpinner=true;
    setTimeout(() =>{
      this.showSpinner=false;
    },
    800)
    this.buttonvisible = true;
    //alert('Neworder created');
    if (result.errno === 0) {
      this.NotificationService.success('Neworder created');
      this.customername='';
      this.onCloseDialog();
    }

    //this.imageUrl = "/assets/images/default-image.png";
    this.imageUrl =  "assets/images/default-image.png";
    
  });
          //.catch(this.handleError);
  }
}
