import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-onboardapproval',
  templateUrl: './onboardapproval.component.html',
  styleUrls: ['./onboardapproval.component.scss'],
})
export class OnboardapprovalComponent implements OnInit {

  ApprovalDetails:any;
  flag:any;
  TemplateID:any;
  CID:any;
  LedgerID:any;
  Statuslist=[{'Status':'0','Tag':'InProgress'},{'Status':'1','Tag':'Completed'}]
  constructor(private datePipe: DatePipe,private dialogService: DialogService,
    public service: HttpserviceService,
    public notificationService: NotificationService,
    private dialog:MatDialog,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OnboardapprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      this.flag = this.data.flag;
      this.ApprovalDetails =  this.data.list;
      this.TemplateID = this.data.TemplateID;
      this.CID=this.data.cid;
      this.LedgerID=this.data.LedgerID

      console.log('HI',this.data.list)
     }


  ngOnInit() {}

  onCloseDialog()
  {
  
    this.dialogRef.close({event: this.flag});
  }

  logEvent(flag,e)
  {
    console.log('ttt',e)

    if(flag=='RowUpdated')
    {

      console.log('ttt',this.ApprovalDetails)
      const  params = {
        CID: JSON.parse(sessionStorage.getItem('cid')),
        Flag : this.flag,
        LedgerID :this.LedgerID,
        OnBoardTag:e.data.OnBoardTag,
        OnBoardTemplateID :e.data.OnBoardID,
        Status:e.data.Status,
        StartDate : this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss.s') ,
      }
  
      this.service.httpPost('/EmployeeOnBoard/SetEmployeeOnBoard?' ,params)   
      .subscribe(data => {
  
        let result: any;
        result = data;
        
        if(result.errno == 0){
    
          this.notificationService.success("Record inserted successfully");
         
          
        }
        else{
          this.notificationService.success(result.errdesc);        
        }
      })
     }
  }

}
