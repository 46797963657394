import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
 import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
 import { DatePipe} from '@angular/common';
import * as _ from 'underscore/underscore-min.js';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Observable, of } from 'rxjs';
import DataGrid from "devextreme/ui/data_grid";

interface EmployeeGroup {
  Level:number;
  Name: string;
  LedgerID: number;
}

@Component({
  selector: 'app-auth-master-list',
  templateUrl: './auth-master-list.component.html',
  styleUrls: ['./auth-master-list.component.scss'],
})
export class AuthMasterListComponent implements OnInit 
{
  AuthMasterlist:FormGroup;
  Companies:any;
  employeegrouplist:any;
  employeelist:any;
  cid:any;
  flag:any;
  strAuthID:any;
  strAuthName:any;
  level:any;
  dataSource:any;
  localdt:any;
  CurLedgerID:any;

  selectable = true;
  removable = true;
  savebtnvisible:boolean=true;
  fcEmployee = new FormControl();
  allEmployees:any
  employees: string[] = [];
  filteredEmployees: Observable<string[]>;
  autocompleteTagsOptionActivated = false;
  employeelistselected: any = [];
  isLedgerVisible:boolean=false;
  disabled:boolean=false;
  @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;
  constructor(private datePipe: DatePipe, 
    public service: HttpserviceService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<AuthMasterListComponent>,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
      this.cid=this.data.cid;
      this.flag = this.data.flag;
      this.strAuthID = this.data.strAuthID;
      this.strAuthName = this.data.strAuthName;

      if(this.flag==='VIEW')
          this.savebtnvisible=false;
      else
         this.savebtnvisible=true;
           
      this.AuthMasterlist = this.formBuilder.group
             ({  
                AuthorizeGroupID: [''] ,
                AuthorizeGroupname: ['', Validators.required] ,
                Level: ['', Validators.required] 
              }); 
     }

   ngOnInit() 
   {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
     this.cid=JSON.parse(sessionStorage.getItem('cid'));
     this.AuthMasterlist.controls.AuthorizeGroupID.setValue(this.strAuthID);
     this.AuthMasterlist.controls.AuthorizeGroupname.setValue(this.strAuthName);
     this.getemployeelist();
     console.log('Test',this.strAuthID)          
     

      if (this.flag !== "ADD")
      {
        this.geteditvalues();
      }
   }

   public list: any[] = [];
   addContrat(e,code: string, label: string)
    {
     if(e.source._selected==true)
     {
       this.list.push({ code, label });
     }
     else 
     {
         this.list.splice(this.list.findIndex(item => item.code === code), 1)
     }
   
   }
   removeContract(i: number) {
   this.list.splice(i, 1);
   }


   onSubmit()
   {   
    console.log("this.dataSource ",this.dataSource );
    let params = 
    {     
      CID: this.cid,
      Flag: this.flag,
      strAuthID:this.AuthMasterlist.get('AuthorizeGroupID').value,
      strAuthName:this.AuthMasterlist.get('AuthorizeGroupname').value,
      intLevel:this.AuthMasterlist.get('Level').value,
      dt_GroupList: this.dataSource ,
      strUserID:this.CurLedgerID,
      strGroupID:sessionStorage.getItem('groupid')
    };
    console.log("Flag :",params)  
    this.service.httpPost('/authmaster/SetAuthMaster',params)
    .subscribe(
      (data) => 
      {
        let result: any;
        console.log('data ', data);
        result = data;       
        console.log('result ', result);
        if (result.errno === 0) 
        {
          this.notificationService.success(result.errdesc);
        }
        else 
        {
          this.notificationService.success(result.errdesc); 
          
        }
      },
      (error: AppError) => 
      {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
   }

   onClear()
   {
    this.AuthMasterlist.reset();       
   }


   onCloseDialog()
   {
    this.AuthMasterlist.reset();
    this.dialogRef.close();
   }

   
   
  async getemployeelist(){
    const paramsFormValue = this.AuthMasterlist.value;
    const params = {
      cid: this.cid,
      strAuthID:0,
      Flag: "GETEMP"
        };
       let temp=await this.service.httpPost('/authmaster/GetAuthMaster',params)
        .toPromise().then(data => {
          let result: any;
          result = data;
          if (result.errno === 0){
            // this.employeelist = result.respdata.EmployeeList;
            
            this.allEmployees = result.respdata.EmployeeList;
          
            this.fcEmployee.valueChanges.subscribe(search => {
              this.filteredEmployees = of(this.allEmployees.filter(item =>
              item.EmployeeName.toLowerCase().includes(search)
              ));
              });

              this.dataSource = result.respdata.EmployeeGroup;

          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log('Error: ', error);
            // this.service.form.setErrors(error.originalError);
          else throw error;
        });
      }

      removeemployees(emp: string): void {
        const index = this.employees.indexOf(emp);
        if (index >= 0) {
          this.employees.splice(index, 1);
        }
      }
      optionActivated($event: MatAutocompleteActivatedEvent) {
        if ($event.option) {
          this.autocompleteTagsOptionActivated = true;
        }
      }
      selectedemployees(event1: MatAutocompleteSelectedEvent): void {
        this.employees.push(event1.option.value);
        this.empInput.nativeElement.value = '';
        this.fcEmployee.setValue(null);
    
       
      }

      add(event: MatChipInputEvent): void 
      {
        // debugger
        const input = event.input;
        const value = event.value;
        // Add our fruit
        if ((value || '').trim()) 
        {
          this.employeelistselected.push(
          {
            id:Math.random(),
            EmployeeName:value.trim()
          });       
        }
    
        // Reset the input value
        if (input) 
        {
          input.value = '';
        }
    
        this.fcEmployee.setValue(null);
      }
    

addRow()
{

  if(this.level !=='')
  {


    if(this.flag !=='ADD')
    {
      let temp = [];
   let intlevel = JSON.parse(this.AuthMasterlist.get('Level').value) 
    for( var i = 0; i < this.dataSource.length; i++){ 
        if ( this.dataSource[i].Level === intlevel) { 
          this.dataSource.splice(i, 1); 
            i--; 
        }
    }
    }   
    

  for (var i=0; i < this.employees.length; i++)
  {
    this.dataSource.push({Level:this.AuthMasterlist.get('Level').value, Name:_.pluck(this.employees, 'EmployeeName')[i],LedgerID:_.pluck(this.employees, 'EmployeeID')[i]});
  }

  this.AuthMasterlist.controls.Level.setValue("");
  this.employeelistselected= [];
  this.employees= [];

  }

}

logEvent(_flag, e)
{

}


async geteditvalues()
{
  const paramsFormValue = this.AuthMasterlist.value;
  const params = {
    cid: this.cid,
    strAuthID:this.strAuthID,
    Flag: this.flag
      };
      let temp=await this.service.httpPost('/authmaster/GetAuthMaster',params)
      .toPromise().then(data => 
        {
        let result: any;
        result = data;
        if (result.errno === 0)
        {
          this.dataSource = result.respdata.EmployeeGroup;
        }
        else{
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => 
      {
        if (error instanceof BadInput)
          console.log('Error: ', error);
          // this.service.form.setErrors(error.originalError);
        else throw error;
      });
    }





}
