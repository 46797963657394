import { Component, OnInit, ViewChild,Inject,ElementRef  } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
// import { NeworderService } from '../neworder.service';
import { User } from './../../../../Modules/user';
import {Observable} from 'rxjs';
import {startWith, map, pluck} from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { NgxImageCompressService } from 'ngx-image-compress';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';


@Component({
  selector: 'app-uploadprofilepic',
  templateUrl: './uploadprofilepic.component.html',
  styleUrls: ['./uploadprofilepic.component.scss'],
})
export class UploadprofilepicComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  customerControl = new FormControl('', Validators.required);
  salesmanControl = new FormControl('', Validators.required);
  
  imageUrl: string = "assets/images/default-image.png";
  //imageUrl: File;
  fileToUpload: File[] = [];
  showSpinner:any;
  ledgerid: string;
  customername: string;
  userid: string;

 

  customernames: string[]= [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

  // imgResultBeforeCompress:string;
  imgResultAfterCompress:string;
 
  file: any;
  localUrl: any;
  // localCompressedURl:any;
  // sizeOfOriginalImage:number;
  sizeOFCompressedImage:number;

  spinnervisible: boolean = false;
  buttonvisible: boolean = true;

  selectedFiles: string[] = [];
  selectable = true;
  removable = true;
  flag: string;
  Empid:string;
  ledgerID:string;
  cid:any;

  constructor(private NotificationService:NotificationService, private service:HttpserviceService,public dialogRef: MatDialogRef<UploadprofilepicComponent>,
    private imageCompress: NgxImageCompressService,  @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      this.flag = this.data.flag;
      this.Empid = this.data.empid;
      this.ledgerID=this.data.ledgerID;
      this.cid=this.data.cid;
      //console.log('dialogdata',this.data)
    }

  ngOnInit(): void {
   
  }
  
  
  onCloseDialog()
  {
    // this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    // .afterClosed().subscribe(res => {
      //if (res) {
          // this.AdvanceRequestViewFormGroup.reset();
          this.dialogRef.close();
        //  }
      // });
  }

  
  
  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  selectFile(event: any) {
    // this.fileToUpload= [];       
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      this.spinnervisible = true;
      for (let i = 0; i < filesAmount; i++) {
        let  fileName : any;
        this.file = event.target.files[i];
        fileName = this.file['name'];
        this.selectedFiles.push(fileName);
        // console.log('filename inside selectfile: ', fileName);
        var reader = new FileReader();

        reader.onload = (event1:any) => {
          this.localUrl = event1.target.result;
          this.compressImage(this.localUrl,fileName)

        }

        reader.readAsDataURL(event.target.files[i]);
      } 
      
      this.spinnervisible = false;
    }

   // console.log('Select: ', this.fileToUpload);
  }

  compressImage(image,fileName) {
    try{
      var orientation = -1;
     // console.log("image",image)
      // this.sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
      // console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        async result => {
        this.imgResultAfterCompress = result;
        // console.log('imgResultAfterCompress: ', this.imgResultAfterCompress);
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024)
        // console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
      //  console.log('after blob creation',imageBlob);
        //imageFile created below is the new compressed file which can be send to API in form 
        this.fileToUpload.push(new File([imageBlob], imageName, { type: 'image/jpeg' }));
       // console.log('File to upload: ', this.fileToUpload);
        
      });
    }
    catch (Error) {
      alert(Error.message);
    }
    
  }
  
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  viewImage(file: string){
    const index = this.selectedFiles.indexOf(file);

    if (index >= 0) {

      Array.from(this.fileToUpload).forEach(async uploadedfile => {
         if (uploadedfile.name == file){
          let result1:string = await this.getBase64Image(uploadedfile);
          this.imageUrl = result1;
         }
      });

    }
  }

  remove(file: string): void {
    const index = this.selectedFiles.indexOf(file);

    if (index >= 0) {
      Array.from(this.fileToUpload).forEach(uploadedfile => {
         //console.log(uploadedfile.name) 
         if (uploadedfile.name == file){
           this.fileToUpload.splice(index, 1);
         }
      });
      this.selectedFiles.splice(index, 1);
      this.imageUrl = "assets/images/default-image.png";
    }
   // this.selectedFiles = [];
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
   // console.log('Remove: ', this.fileToUpload);
  }  

  async getBase64Image(imageBlob) {
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(imageBlob);
    });
    return reader.result.toString();//.replace(/^data:.+;base64,/, '');
  }
  
  OnSubmit(Caption, Image){
    this.showSpinner=true;
    this.buttonvisible = false;
    // console.log('final file list: ', this.fileToUpload);
    this.postFile('Caption', this.fileToUpload, this.ledgerID,this.cid )
   // Caption.value = null;
    //Image.value = null;
  }

  postFile(caption: string, fileToUpload: File[], ledgerid: string,CID:any){
   // console.log('POSTFIELAPTH')
    const formData: FormData = new FormData();
    let index: number = 1;
    fileToUpload.forEach(function (value) {
      formData.append('Image' + index.toString(), value, value.name);
     // console.log(value);
      index = index + 1;
    });

    // formData.append('Image', fileToUpload, fileToUpload.name);
    formData.append('ImageCaption', caption);
    formData.append('Ledger', ledgerid);
    // formData.append('CustomerName', customername);
    // formData.append('SalesmanID', salesmanid.toString());
    // formData.append('UserName', username);
    formData.append('Flag',  'UploadProfile');
    formData.append('cid', CID);

   // console.log("dd",formData)
   // return this.http.post(this.baseUrl+'/salesmanorder', formData)
   this.service.httpPost('/EmployeeRegistration/changeprofilepic' ,formData)  
   .subscribe(res =>{
    let result: any;
    result = res;  
    //console.log('response data',result) 
    this.showSpinner=true;
    setTimeout(() =>{
      this.showSpinner=false;
    },
    800)
    this.buttonvisible = true;
    //alert('Neworder created');
    if (result.errno === 0) {
      this.NotificationService.success('Profile picture updated successfully');
      this.customername='';
      this.onCloseDialog();
    }

    //this.imageUrl = "/assets/images/default-image.png";
    this.imageUrl =  "assets/images/default-image.png";
    
  });
          //.catch(this.handleError);
  }

}
