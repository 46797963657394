import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { User } from 'src/app/Modules/user';
import { TrainingacessComponent } from './../trainingacess/trainingacess.component';
// import { GoalaccessComponent } from './../goalaccess/goalaccess.component';
import { Component, OnInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA, MatInputModule, MatFormFieldModule } from '@angular/material';
import { Inject } from '@angular/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { DatePipe, formatDate } from '@angular/common';
import { MatDialog, MatDialogConfig ,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
// import { User } from '../../../models';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  flag:any
  TID:number=0;
  TName:any
  Description:any
  EntryDate:any
  SDate:any
  EDate:any
  groupenable:any=true
  CurLedgerID:any
  CID:any;

  constructor(private dialog: MatDialog,private datePipe: DatePipe,public notificationService: NotificationService,public service: HttpserviceService,  public dialogRef: MatDialogRef<TrainingComponent>,
    private dialogService: DialogService,@Inject(MAT_DIALOG_DATA) public data: any) {
      this.flag = this.data.flag;
    this.TName=this.data.TName;
    this.TID=this.data.TID;
    this.Description=this.data.Description;
    this.EntryDate=this.data.EntryDate;
    this.SDate=this.data.StartDate;
    this.EDate=this.data.EndDate;
    this.CID=this.data.cid;
     }

  ngOnInit(): void {
    this.groupenable = this.TID != 0 ? false : true;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
  }
  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    .afterClosed().subscribe(res => {
      if (res) {
    this.dialogRef.close({event: 'CLEAR'});
      }
    });
  }
  onClose(model){
    this.dialogRef.close({event: this.flag, data: model});
  }
  onClear()
  {
    this.TName='';
    this.Description=''
    this.EntryDate=''
    this.SDate=''
    this.EDate=''
  }
  onSubmit()
  {
    let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      cid: this.CID,
      flag: this.flag,
      TID:this.TID,
      TName:this.TName,
      Description:this.Description,
      SDate:this.datePipe.transform(this.SDate, 'yyy-MM-dd'),
      EDate:this.datePipe.transform(this.EDate, 'yyy-MM-dd'),
      EntryDate:this.datePipe.transform(this.EntryDate, 'yyy-MM-dd'),
      Status:"Open",
      UserName: this.CurLedgerID,
      CreateDate:this.datePipe.transform(currentdate, 'yyy-MM-dd')
     

    };
    console.log('params',paramsExtra);
    this.service.httpPost('/Training/SetTraining',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
      if (users.errno === 0){
        this.notificationService.success(users.errdesc);
        this.TID=users.respdata;
        this.groupenable = this.TID !== 0 ? false : true;
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      width: '60%',
      TID:this.TID,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(TrainingacessComponent,dialogConfig);
  }


}
