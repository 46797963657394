import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import * as _ from 'underscore';
@Component({
  selector: 'app-ticketemailrulecreation',
  templateUrl: './ticketemailrulecreation.component.html',
  styleUrls: ['./ticketemailrulecreation.component.scss'],
})
export class TicketemailrulecreationComponent implements OnInit {
  CID:any;
  flag:any;
  ID:number=0;
  companies:any;
  Template:any;
  ActvitityData:any;
  TypeNameData:any;
  eMailTagData:any;
  CollectionData:any;
  ResultData:any;
  ConditionData:any;
  showSpinner:any;
  TypeName:any;
  NameData:any;
  ConditionName:any
  ConditionDetails:any
  CommentData:any;
  EmailRuleFormGroup: FormGroup;
  datasource:any;
  @ViewChild('stepper') stepper: MatStepper;
  RuleNamereadonly:boolean=false

  displayedColumns =['TemplateID','Description','Activity','RuleName','Condition','Comment','Action'];
  constructor(private dialog: MatDialog,
    public service: HttpserviceService,
    private formBuilder: FormBuilder,
    public notificationService: NotificationService    
    ) { 

      this.EmailRuleFormGroup = this.formBuilder.group({  
        fcCompany:['', Validators.required],     
        fcTemplate:['',Validators.required],
        fcActvitity:['', Validators.required], 
        fcCommentDetails:['', Validators.required] ,  
        fcConditionDetails:['', Validators.required],     
        fcType:['',Validators.required],
        fcTypeName:['', Validators.required], 
        fcCondition:['', Validators.required] ,
        fcRuleName:['', Validators.required] 
      });  
      this.EmailRuleFormGroup.controls.fcCondition.setValue('None')
      this.EmailRuleFormGroup.controls.fcCompany.setValue(JSON.parse(sessionStorage.getItem('cid')))
    }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='ADD'
    this.RuleNamereadonly=false;
    this.Loaddetails();
  }
  onCompanySelect(event)
  {
    this.CID=event;
    this.flag='PAGELOAD'
    this.Loaddetails();

  }

  Submit(){
    this.Loaddetails();
  }

  Clear(){
    if(this.flag==='ADD')
        this.EmailRuleFormGroup.controls.fcRuleName.setValue("")
    
    this.EmailRuleFormGroup.controls.fcTemplate.setValue("") 
    this.EmailRuleFormGroup.controls.fcActvitity.setValue("")      
    this.EmailRuleFormGroup.controls.fcConditionDetails.setValue("")
    this.EmailRuleFormGroup.controls.fcCommentDetails.setValue([])
  }

  StepperTwoOpen()
  {
    this.stepper.selectedIndex = 1;
    this.flag='ADD';
    this.Clear();
    this.RuleNamereadonly=false;
  }

  Loaddetails()
  {
   this.showSpinner=true;
    let params = {
     CID:this.CID,
     Flag:"PAGELOAD"
       }         
          this.service.httpPost('/TicketConfiguration/GetTicketEmailRuleCreation',params)
          .subscribe(data => {
           this.showSpinner=true;
           setTimeout(() =>{
             this.showSpinner=false;
           },
           800)
            let result: any;
            result=data;
            if (result.errno === 0){            
                         
                this.companies=result.respdata.CompanyDetails;
                this.Template=result.respdata.Template;
                this.ActvitityData=result.respdata.Actvitity;
                this.TypeNameData=result.respdata.TypeName;
                this.eMailTagData=result.respdata.eMailTag;
                this.CollectionData=result.respdata.NameData;
                this.ConditionData=result.respdata.Condition;
                this.CommentData=result.respdata.CommentData;
                this.datasource=result.respdata.EmailRuleData;
             
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
            this.notificationService.warn(error);
            else throw error;       
          });
  }

  onTypeSelect(event)
  {
    var selectedData=event;   
    const result = this.CollectionData.filter((obj) => {
      return obj.TypeData === selectedData;
    });
    this.ResultData=result;    
     
  }


More()
{
    if(this.EmailRuleFormGroup.get('fcCondition').value==="None")
      this.ConditionDetails=this.EmailRuleFormGroup.get('fcConditionDetails').value+' '+this.EmailRuleFormGroup.get('fcType').value+"='"+this.EmailRuleFormGroup.get('fcTypeName').value+"' "
    else
      this.ConditionDetails=this.EmailRuleFormGroup.get('fcConditionDetails').value+' '+this.EmailRuleFormGroup.get('fcType').value+"='"+this.EmailRuleFormGroup.get('fcTypeName').value +"' " +this.EmailRuleFormGroup.get('fcCondition').value   
      

      this.EmailRuleFormGroup.controls.fcConditionDetails.setValue(this.ConditionDetails)
}

Save(){

  let CommentDetail: string[] = this.EmailRuleFormGroup.get('fcCommentDetails').value
  var strComment  
  for(var i = 0; i < CommentDetail.length; i++)
  {     
    if(i==0)
      strComment=(CommentDetail[i]);  
    else
      strComment=strComment+','+(CommentDetail[i]);  
  }

    let params = { 
    CID:this.CID,
    Flag: this.flag, 
    RuleName: this.EmailRuleFormGroup.get('fcRuleName').value, 
    Template:  this.EmailRuleFormGroup.get('fcTemplate').value,   
    Activity: this.EmailRuleFormGroup.get('fcActvitity').value,
    CommentDetails: strComment,     
    ConditionDetails: this.EmailRuleFormGroup.get('fcConditionDetails').value
   };
  this.service.httpPost('/TicketConfiguration/SetTicketEmailRuleCreation',params)
  .subscribe(
    (data) => {
      let result: any;
      result = data;       
      if (result.errno === 0) {
        this.notificationService.success(result.errdesc);
        //console.log('Project list: ', result.respdata);
        //this.dialogRef.close({data:'data'});
      }
      else {
        this.notificationService.warn(result.errdesc); //':: Problem details');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)         
      this.notificationService.warn(error);
      else throw error;       
    });
}


  Edit(event)
  {
    this.stepper.selectedIndex = 1;
    //event.Comment.split(',').map(function(n) {return Number(n);});
    var res = event.Comment.split(",");
    this.RuleNamereadonly=true;
      this.EmailRuleFormGroup.controls.fcCompany.setValue(JSON.parse(sessionStorage.getItem('cid')))
      this.EmailRuleFormGroup.controls.fcRuleName.setValue(event.RuleName)
      this.EmailRuleFormGroup.controls.fcTemplate.setValue(event.TemplateID) 
      this.EmailRuleFormGroup.controls.fcActvitity.setValue(event.Activity)      
      this.EmailRuleFormGroup.controls.fcConditionDetails.setValue(event.Condition)
      this.EmailRuleFormGroup.controls.fcCommentDetails.setValue(res)
      this.flag='EDIT'
      //  const dialogConfig = new MatDialogConfig();
      //      dialogConfig.disableClose = true;
      //      dialogConfig.autoFocus =  true;
      //      dialogConfig.data = {       
      //        cid:this.CID,
      //        Flag:'EDIT' ,                            
      //        Type:event.Type,
      //        TypeID:event.TypeID,
      //        eMailTag:event.EmailTag,
      //        eMail:event.Email          
      //      }
          
    
  } 


}
