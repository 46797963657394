import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {

  CategoryFormGroup: FormGroup;
  catsid:any="0";
  catname:any;
  description:any
  Flag:any;
  cid:any;
  readonly:any;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<CategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.catsid=this.data.CategoryID;
      this.catname=this.data.CategoryName;
      this.description=this.data.Description; 

      this.CategoryFormGroup = this.formBuilder.group({  
        fcCategoryName:['', Validators.required],     
        fcDescription:['']   
      });  

      this.CategoryFormGroup.controls.fcCategoryName.setValue(this.catname);
      this.CategoryFormGroup.controls.fcDescription.setValue(this.description);
    
     }

  ngOnInit() {}

  Submit()
  {
    let params = { 
      cid:this.cid,
      flag: this.Flag, 
      CategoryID:this.catsid,            
      CategoryName:  this.CategoryFormGroup.get('fcCategoryName').value,   
      Description: this.CategoryFormGroup.get('fcDescription').value,
      UserID:0,
      GroupID:sessionStorage.getItem('groupid')             
     };
    this.service.httpPost('/Category/SetCategory',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
          //this.onCloseDialog();
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
          this.dialogRef.close({data:'data'});
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

  onCloseDialog()
  {    
    this.dialogRef.close();
  }

  

}
