import { Component, OnInit,ViewChild, } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder,FormArray,AbstractControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { UserService } from './../../../Admin/Users/userValidate';
import { User } from './../../../../Modules/user';

@Component({
  selector: 'app-empattendanceview',
  templateUrl: './empattendanceview.component.html',
  styleUrls: ['./empattendanceview.component.scss'],
})
export class EmpattendanceviewComponent implements OnInit {
  @ViewChild('stepper') stepper;
 
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;

  colorCtr: AbstractControl = new FormControl(null);

  public listColors = ['primary', 'accent', 'warn'];

  public codeColorPicker = `
  <mat-form-field>
    <input matInput [ngxMatColorPicker]="picker" [formControl]="colorCtr">
    <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
    <ngx-mat-color-picker #picker></ngx-mat-color-picker>
  </mat-form-field>`;

  isLinear = false;
  AttendanceReportFormGroup: FormGroup;
  AttendanceDataFormGroup: FormGroup;
  isEditable = true; 
  companies: any;
  Departments: any;
  timezone:any;
  showSpinner:any
  employeelist:any;
  yearlist:any;
  datasource:MatTableDataSource<GroupALL>;
  summarydetails:any;
  colorcode:any;
  leavelist:any;
  leavelist1:any;
  cols: MatTableDataSource<GroupALL>;
  leaveflag=0;
  LedgerID:number;
  searchKey: string;
  isvisible=false;
  empvisible=false;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  empid:string[]=[];
  leaveid:string;
  CID:any;
  viewbtnvisible: boolean = false;
  category = [
    { name: 'ALL' },
    {  name: 'By Employee' },
    {  name: 'All With LeaveData' } 
  ];

  columns = [
    { columnDef: 'Name', header: 'Name',    cell: (element: any) => `${element.Name}`, color: "red" },
    { columnDef: 'S',  header: 'S',   cell: (element: any) => `${element.S}`     },
    { columnDef: 'M',   header: 'M', cell: (element: any) => `${element.M}`   },
    { columnDef: 'T',   header: 'T', cell: (element: any) => `${element.T}`   },
    { columnDef: 'W',   header: 'W', cell: (element: any) => `${element.W}`   },
    { columnDef: 'T1', header: 'T',    cell: (element: any) => `${element.T1}` },
    { columnDef: 'F', header: 'F',    cell: (element: any) => `${element.F}` },
    { columnDef: 'S1', header: 'S',    cell: (element: any) => `${element.S1}` },
    { columnDef: 'S2', header: 'S',    cell: (element: any) => `${element.S2}` },
    { columnDef: 'M1', header: 'M',    cell: (element: any) => `${element.M1}` },
    { columnDef: 'T2', header: 'T',    cell: (element: any) => `${element.T2}` },
    { columnDef: 'W1', header: 'W',    cell: (element: any) => `${element.W1}` },
    { columnDef: 'T3', header: 'T',    cell: (element: any) => `${element.T3}` },
    { columnDef: 'F1', header: 'F',    cell: (element: any) => `${element.F1}` },
    { columnDef: 'S3', header: 'S',    cell: (element: any) => `${element.S3}` },
    { columnDef: 'S4', header: 'S',    cell: (element: any) => `${element.S4}` },
    { columnDef: 'M2', header: 'M',    cell: (element: any) => `${element.M2}` },
    { columnDef: 'T4', header: 'T',    cell: (element: any) => `${element.T4}` },
    { columnDef: 'W2', header: 'W',    cell: (element: any) => `${element.W2}` },
    { columnDef: 'T5', header: 'T',    cell: (element: any) => `${element.T5}` },
    { columnDef: 'F2', header: 'F',    cell: (element: any) => `${element.F2}` },
    { columnDef: 'S5', header: 'S',    cell: (element: any) => `${element.S5}` },
    { columnDef: 'S6', header: 'S',    cell: (element: any) => `${element.S6}` },
    { columnDef: 'M3', header: 'M',    cell: (element: any) => `${element.M3}` },
    { columnDef: 'T6', header: 'T',    cell: (element: any) => `${element.T6}` },
    { columnDef: 'W3', header: 'W',    cell: (element: any) => `${element.W3}` },
    { columnDef: 'T7', header: 'T',    cell: (element: any) => `${element.T7}` },
    { columnDef: 'F3', header: 'F',    cell: (element: any) => `${element.F3}` },
    { columnDef: 'S7', header: 'S',    cell: (element: any) => `${element.S7}` },
    { columnDef: 'S8', header: 'S',    cell: (element: any) => `${element.S8}` },
    { columnDef: 'M4', header: 'M',    cell: (element: any) => `${element.M4}` },
    { columnDef: 'T8', header: 'T',    cell: (element: any) => `${element.T8}` },
    { columnDef: 'W4', header: 'W',    cell: (element: any) => `${element.W4}` },
    { columnDef: 'T9', header: 'T',    cell: (element: any) => `${element.T9}` },
    { columnDef: 'F4', header: 'F',    cell: (element: any) => `${element.F4}` },
    { columnDef: 'S9', header: 'S',    cell: (element: any) => `${element.S9}` },
    { columnDef: 'S10', header: 'S',    cell: (element: any) => `${element.S10}` },
    { columnDef: 'M5', header: 'M',    cell: (element: any) => `${element.M5}` },
    { columnDef: 'T10', header: 'T',    cell: (element: any) => `${element.T10}` },
  ];

  displayedColumns = this.columns.map(c => c.columnDef);
  displayedColumns1=['Name','CarryForward','NoofDays','TotalLeave','TakenLeave','RemainingLeave','AnnualLeave']
  displayedColumns2=['Emergency','Leave','Maternity','SickLeave','UnpaidLeave','ExtendedLeave']
  // displayedColumns1=['Name','CarryForward','NoofDays','TotalLeave','TakenLeave','RemainingLeave','AnnualVacation','Emergency','Leave','Maternity','SickLeave','UnpaidLeave','ExtendedLeave']
 
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  get ordersFormArray() 
  {
    return this.AttendanceDataFormGroup.controls.orders as FormArray;
  }
  constructor(private formBuilder: FormBuilder,public service: HttpserviceService,
    private generic: GenericService, private service1: UserService,
    public notificationService: NotificationService,private dialog: MatDialog) {

    this.AttendanceReportFormGroup = this.formBuilder.group({  
      fcCompany: ['', Validators.required],     
      fcYear: ['', Validators.required]
    });

    this.AttendanceDataFormGroup = this.formBuilder.group({
     orders: new FormArray([]) 
    });
   
   }
   private addCheckboxes() 
   {
    this.leavelist.forEach(() => this.ordersFormArray.push(new FormControl('True')));
   }
  
  ngOnInit(): void {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.getComboValue();   
      let year = new Date().getFullYear();
      this.AttendanceReportFormGroup.controls.fcCompany.setValue(this.CID);    
      this.AttendanceReportFormGroup.controls.fcYear.setValue(year);      
  }
  onCompanySelect(event)
  {
    this.CID=event.value;
    this.AttendanceReportFormGroup.reset();
    this.myControl.setValue('');  
    this.AttendanceDataFormGroup = this.formBuilder.group({
      orders: new FormArray([]) 
     });
     this.datasource = new MatTableDataSource<GroupALL>([]);
     this.summarydetails = new MatTableDataSource<GroupALL>([]);
     this.getComboValue();      
     this.AttendanceReportFormGroup.controls.fcCompany.setValue(this.CID);   
  }
  
    
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
    this.summarydetails.filter = filterValue.trim().toLowerCase();
  }
 

  getcolor1(state)
  {
    switch (state.columnDef)
    {
      case "Name":
       return 'black';
    }
  }
  getcolor2(state)
  {
    switch (state.columnDef)
    {
      case "Name":
       return 'left';
    }
  }
  getcolor(state)
  {
    for (let key of Object.keys(this.leavelist1)) {
      let colorName = this.leavelist1[key];
      if(colorName.LID==state)
      {
        return colorName.ColorCode;
      }
    }

    switch (state){
        case '2':
          return '#E8DAEF'
        case '0':
          return '#B0C4DE'
        case 'null':
            return '#F9FAFA'  
    }
      
  }

  gettimepunch()
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.showSpinner=true;
    let params = {
    cid:this.CID,    
    EmpID:curUser.ledgerid,
    Month: 1 ,
    TimeZone:1,
    checkcode:"",   
    Flag:"By Employee",
    Year:this.AttendanceReportFormGroup.get('fcYear').value,
    Department:0,
    Ledger:0

        }
        this.service.httpPost('/AttendanceReport/GetAttendanceReport',params)
        .subscribe(data => {
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result=data;
          if (result.errno === 0){

            this.datasource= result.respdata.Table;
            this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Table);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;

            this.summarydetails=result.respdata.Table1;
           // console.log('asdf',this.summarydetails)
            const removeDisName = this.summarydetails.map(({ disName, ...item }) => item);
           // console.log('params0 :', removeDisName);
            const removeCarryForward = removeDisName.map(({ IsCarryForward, ...item }) => item);
           // console.log('params1 :', removeCarryForward);

            this.summarydetails=removeCarryForward;
          //  console.log('final :', this.summarydetails);

            // this.summarydetails.sort = this.sort;
            // this.summarydetails.paginator = this.paginator;
            this.stepper.selectedIndex = 1;
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getComboValue()
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
    cid:this.CID,
    Flag:"PAGELOAD",
    EmpID:0,
    Month:1,
    TimeZone:1,
    checkcode:"",
    Year:2020,
    Department:"select",
    Ledger:0

        }
        this.service.httpPost('/AttendanceReport/GetAttendanceReport',params)
        .subscribe(data => {
          let result: any;
          result=data;
          if (result.errno === 0){            
              this.companies= curUser.dtsiteid;
              this.yearlist= result.respdata.Table1;
              this.leavelist=result.respdata.Table2;
              this.leavelist1=result.respdata.Table2;
             
              this.addCheckboxes();
                             
              this. getUserList();
          }
          else{
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        }, 
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_105",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);     
      this.viewbtnvisible = btnVisilityData.editflag;                     
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }


  Submit() {
    
    const lid = this.AttendanceDataFormGroup.value.orders
      .map((checked, i) => checked ? this.leavelist[i].LID : null)
      .filter(v => v !== null);
 
      this.leaveid=lid.toString();
    let newList = [];
    for (let value of Object.values(lid))
    {
      for (var i = 0; i < this.leavelist.length; i++) {
        if (this.leavelist[i].LID === value ) {
            newList.push(this.leavelist[i]);
        }
    }
    }
    this.leavelist1=newList;

    this.gettimepunch();
    
  }

}
export interface GroupALL {
  Name: string;
}
