import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
// import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { HttpserviceService } from './../../../Services/httpServices/httpservice.service'
// import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { UserService } from 'src/app/components/Admin/Users/userValidate';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { User } from 'src/app/Modules/user';

@Component({
  selector: 'app-resourceallocationbyweeklist',
  templateUrl: './resourceallocationbyweeklist.component.html',
  styleUrls: ['./resourceallocationbyweeklist.component.scss'],
})
export class ResourceallocationbyweeklistComponent implements OnInit {
  ProjectFormGroup: FormGroup;
  myControl = new FormControl();
  ProjectAllocationDataGrids2: any;
  cid: any;
  filteredOptions: Observable<string[]>;
  projectid: string[] = [];
  selectedprojectid: any;
  showSpinner: any;
  Projectlist: any;
  ResourceData:any;
  LedgerID:any;

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder, private dialog: MatDialog, private generic: GenericService,
    public dialogRef: MatDialogRef<ResourceallocationbyweeklistComponent>,
    public notificationService: NotificationService, private datePipe: DatePipe, private service1: UserService) {
    this.ProjectFormGroup = this.formBuilder.group({
      fcProject: ['']
    });

  }

  ngOnInit() {
    const curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID = curUser.ledgerid;

    this.cid = JSON.parse(sessionStorage.getItem('cid'));
    this.PageLoad();

    this.ResourceData = JSON.parse(sessionStorage.getItem('ResourceData'));
     console.log('resourcedata', this.ResourceData);

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'myControl'))
      );
  }

  private _filter(value: string, control: string): string[] {
    const filterValue = value.toLowerCase();  
    this.selectedprojectid = '' 
    if (control === 'myControl') {    
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }
  }
  
  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID: 101,
      Flag: "LOADDDL"
    };
    this.showSpinner = true;
    console.log('parameters', params);
    this.service.httpPost('/ETSConsolidateEffortOT/GetEffortData', params)
      .subscribe(
        (data) => {
          this.showSpinner = true;
          setTimeout(() => {
            this.showSpinner = false;
          },
            800)
          let result: any;
          result = data;
          if (result.errno === 0) {

            this.Projectlist = result.respdata.Project;
            this.projectid = _.pluck(this.Projectlist, 'ProjectName');

          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)
            console.log(error);
          else throw error;
        });
  }
  selectedEmployee(event, control: string) {     
    let str: string = event.option.value;
    if (control === 'myControl') {     
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.Projectlist, { 'ProjectName': event.option.value }), 'ProjectID'));   
     // console.log('HI',this.selectedprojectid) 
      this.Submit();
    }   
  }

  Submit() {
    this.showSpinner = true;
    const params = {
      CID: this.ResourceData.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: 'Loadprojectgrid',
      EmployeeID: this.ResourceData.empid,
      ProjectID: this.selectedprojectid,
      RoleID: this.ResourceData.department,
      DepartmentID: 0,
      FromDate: this.ResourceData.startweekno,
      ToDate: this.ResourceData.endweekno,
      Year: this.ResourceData.year
    };
    this.service.httpPost('/ResourceAllocationByWeek/Getdropdownvalues',params)
      .subscribe(
        (data) => {
          this.showSpinner = true;
          setTimeout(() => {
            this.showSpinner = false;
          },
            800)
          let result: any;
          result = data;
          //console.log('data ', data);
          if (result.errno === 0) {
             console.log('result', result.respdata);
             this.ProjectAllocationDataGrids2=result.respdata;           
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }

  Save() {
    // console.log('ProjectAllocationDataGrids2', this.ProjectAllocationDataGrids2);
    this.showSpinner=true;
    const params = {
      cid: this.ResourceData.cid,
      Flag: 'ADD',
      ProjectID: this.selectedprojectid,
      EmployeeID:this.ResourceData.empid,
      RoleID: this.ResourceData.department,
      FromDate: this.ResourceData.startweekno,
      ToDate: this.ResourceData.endweekno,
      Year: this.ResourceData.year,   
      dtProjectAllocationData: this.ProjectAllocationDataGrids2,
      GroupID: 0,
      CreatedBy: this.LedgerID,
      CreatedDate: '2020-09-01',
      LastUpdatedBy: this.LedgerID,
      LastUpdatedDate: '2020-09-01'
    };
    // console.log('params :', params);
//    this.service.updateProjectAllocationByWeek(params)
    this.service.httpPost('/ResourceAllocationByWeek/SetProjectAllocationData',params)
      .subscribe(
        (data) => {
          // console.log('inside save');
          let users: any;
          users = data;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)          
          if (users.errno === 0) {
            this.notificationService.success('Submitted successfully');
          }
          else {
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        error => {
          console.log('Error : ', error);
        });
   
  }

  SearchClear() {
    this.myControl.setValue('');

  }
  onCloseDialog() {
    this.ProjectFormGroup.reset();
    this.dialogRef.close();
  }

  onSearchClear(){
    this.ProjectFormGroup.reset();
    this.myControl.setValue('');  
    this.ProjectAllocationDataGrids2='';
  }

  // onCellPrepared(e) {
  //   if (e.rowType !== 'data' || e.columnIndex > 2 ) {
  //     return;
  //   }
  //   if (e.rowIndex % 2 === 0 && ( e.columnIndex == 0  ||  e.columnIndex == 1 ||  e.columnIndex == 2 )) {
  //     e.cellElement.rowSpan = 2;
  //     e.cellElement.innerHTML = '<div>' + e.value + '</div>';
  //   }
  //   else {
  //     e.cellElement.style.display = 'none';
  //   }
  // }

}
