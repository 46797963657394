import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { MatDialog,MatDialogRef,MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { UserService } from '../../Admin/Users/userValidate';
import { User } from 'src/app/Modules/user';
import { DatecontrolComponent } from '../../HR/DateControl/datecontrol/datecontrol.component';


export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

// class PickDateAdapter extends NativeDateAdapter {
//   format(date: Date, displayFormat: Object): string {
//       if (displayFormat === 'input') {
//           return formatDate(date,'dd-MM-yyyy',this.locale);;
//       } else {
//           return date.toDateString();
//       }
//   }
// }


@Component({
  selector: 'app-customsreport',
  templateUrl: './customsreport.component.html',
  styleUrls: ['./customsreport.component.css']
  ,
  providers: [
    // {provide: DateAdapter, useClass: PickDateAdapter},
    // {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
]
})
export class CustomsreportComponent implements OnInit {
  @ViewChild('stepper') stepper;

  EffortFormGroup : FormGroup;
  EforrtDataFormGroup: FormGroup;
  OngoingDataGroup:FormGroup;
  EffortDataGrids:any;
  OngoingDataGrids:any;
  Companies:any;
  Employees:any;
  Products:any;
  Projects:any;
  Clients:any;
  Category:any;
  Status:any;
  isEditable = true; 
  TotHours:any;
  TotOTHours:any;
  Visiblelist:any;
  TaskCount:any=0;
  cid:any;
  frmdate= new Date();
  isTicketVisible: boolean;
  isCategoryVisible: boolean;
  isProductVisible: boolean;
  isProjectVisible: boolean;
  isClientVisible: boolean;
  isStarttimeVisible: boolean;
  isEndtimeVisible: boolean;
  iscatgoryhiding:number;
  istickethiding:number;
  isproducthiding:number;
  isprojecthiding:number;
  isclinethiding:number;
  public filteredList5;
  public filteredList6;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  selectable = true;
  removable = true;
  fromdate:any;
  todate:any;
  fcCategory = new FormControl();
  filteredOptions: Observable<string[]>;
  categories: string[] = [];
  allCategories: string[] = [];
  DateTypeFlag:any="Today"
  fcProject = new FormControl();
  filteredProjects: Observable<string[]>;
  projects: string[] = [];
  allProjects: string[] = [];
  selectable1 = true;
  removable1 = true;
  showSpinner:any;
  fcEmployee = new FormControl();
  allEmployees:any
  employees: string[] = [];
  filteredEmployees: Observable<string[]>;
  autocompleteTagsOptionActivated = false;
  employeelistselected: any = [];
  viewbtnvisible: boolean = false;
   @ViewChild('catInput') catInput: ElementRef<HTMLInputElement>;
   @ViewChild('prjInput') prjInput: ElementRef<HTMLInputElement>;
   @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;
   @ViewChild('auto') matAutocomplete: MatAutocomplete;
   tabIndex:number=0;
   
    constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,private generic: GenericService,
    public notificationService: NotificationService, private datePipe: DatePipe, private service1: UserService) 
    { 
      this.EffortFormGroup = this.formBuilder.group({  
          fcCompany: ['', Validators.required] ,    
          fcEmployee: [''],
          fcProduct: [''],
          fcProject: [''],
          fcClient: [''],      
          fcCategory: [''],      
          fcStatus: [''],      
          // fcFromDate: ['', Validators.required],
          // fcToDate: ['', Validators.required]       
      });
         this.EforrtDataFormGroup = this.formBuilder.group({                 
      });
        this.OngoingDataGroup = this.formBuilder.group({                 
      });
      
     
      this.filteredOptions = this.fcCategory.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this._filter(value) : this.allCategories.slice()));

        // this.filteredProjects = this.fcProject.valueChanges.pipe(
        //   startWith(null),
        //   map((value: string | null) => value ? this._filterprojects(value) : this.allProjects.slice()));
     
     
        this.fcProject.valueChanges.subscribe(search => {
          this.filteredProjects = of(this.allProjects.filter(item =>
          item.toLowerCase().includes(search)
          ));
          });
      }

     
  ngOnInit(): void {
    this.cid=JSON.parse(sessionStorage.getItem('cid')); 
    this.PageLoad();
    this.EffortFormGroup.controls.fcCompany.setValue(this.cid);
    this.iscatgoryhiding=1;
    // this.filteredEmployees = this.fcEmployee.valueChanges.pipe(
    //   startWith(null),
    //   map((value: string | null) => value ? this._filteremployees(value) : this.allEmployees.slice()));
  
    // this.fcEmployee.valueChanges.subscribe(search => {
    //   this.filteredEmployees = of(this.allEmployees.filter(item =>
    //   item.name.toLowerCase().includes(search)
    //   ));
    //   });

    // this.EffortFormGroup.controls.fcFromDate.setValue(this.frmdate);
    // this.EffortFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    
    //console.log('pageloaddate',this.fromdate)


  }
  
  changesite(e) {
    this.cid=e.value;
    // this.Employees='';
    // this.Projects='';
    // this.allEmployees='';
    // this.allProjects=[];
    this.projects = []; // making the chipList empty
    this.fcProject.reset(); // resets the control
    this.fcProject.markAsPristine();
    this.fcProject.markAsUntouched();

    this.employees = []; // making the chipList empty
    this.fcEmployee.reset(); // resets the control
    this.fcEmployee.markAsPristine();
    this.fcEmployee.markAsUntouched();
    this.PageLoad();
   
  }

  addcat(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.categories.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.fcCategory.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    // debugger
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.employeelistselected.push({
        id:Math.random(),
        Name:value.trim()
      });

      // this.employeelistselected = options.map(o => o.value);
    
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fcEmployee.setValue(null);
  }

  addprojects(event1: MatChipInputEvent): void {
    const input = event1.input;
    const value = event1.value;
    if ((value || '').trim()) {
      this.projects.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.fcProject.setValue(null);
  }

  // removecat(cat: string): void {
  //   const index = this.categories.indexOf(cat);
  //   if (index >= 0) {
  //     this.categories.splice(index, 1);
  //   }
  // }

  remove(cat: string) {
    const toppings = this.fcCategory.value as string[];
    this.removeFirst(toppings, cat);
    this.fcCategory.setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  removeprojects(prj: string): void {
    const index = this.projects.indexOf(prj);
    if (index >= 0) {
      this.projects.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.categories.push(event.option.value);
    this.catInput.nativeElement.value = '';
    this.fcCategory.setValue(null);
  }

  selectedprojects(event1: MatAutocompleteSelectedEvent): void {
    this.projects.push(event1.option.value);
    this.prjInput.nativeElement.value = '';
    this.fcProject.setValue(null);
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCategories.filter(value => value.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterprojects(value1: string): string[] {
    const filterprojectValue = value1.toLowerCase();

    return this.allProjects.filter(value1 => value1.toLowerCase().indexOf(filterprojectValue) === 0);
  }


  PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID:this.cid, 
      Flag: "LOADDDL",
      UserName:curUser.ledgerid           
    };
    this.showSpinner=true;
    this.service.httpPost('/ETSCustomsReport/GetEffortData',params)
      .subscribe (
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = curUser.dtsiteid;                       
            this.Employees = result.respdata.Employee;
            this.allEmployees = result.respdata.Employee;
            this.Products=result.respdata.Product;
            this.Projects=result.respdata.Project;
            this.Clients=result.respdata.Client;
            this.Category=result.respdata.Category;
            this.Status=result.respdata.Status;

               this.allCategories= _.pluck(this.Category, 'CategoryName');   
               this.allProjects= _.pluck(this.Projects, 'ProjectName'); 
              //  this.allEmployees= _.pluck(this.Employees, 'Name');      


               this.fcEmployee.valueChanges.subscribe(search => {
                this.filteredEmployees = of(this.allEmployees.filter(item =>
                item.Name.toLowerCase().includes(search)
                ));
                });
             
                this.filteredList5= this.Clients.slice();
                this.filteredList6= this.Category.slice();


                this.getUserList();
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }


  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_29",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);     
      this.viewbtnvisible = btnVisilityData.refreshflag;        
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  Submit(){
    this.tabIndex=1 ? 0 :1;
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

    if (this.EffortFormGroup.invalid) {
      this.notificationService.warn('Please select date range');     
      return;
      }  
    this.showSpinner=true;
      let params = {     
      CID: this.EffortFormGroup.get('fcCompany').value,
      Flag: "LOADGRID",               
      LedgerID:_.pluck(this.employees, 'LedgerID'),   //this.EffortFormGroup.get('fcEmployee').value,  
      Product:this.EffortFormGroup.get('fcProduct').value, 
      Project:this.projects,
      Client:this.EffortFormGroup.get('fcClient').value,
      Category:this.fcCategory.value,
      Status:this.EffortFormGroup.get('fcStatus').value,
      FromDate:this.fromdate,
      ToDate:this.todate,
      DateType:"cs",
      UserName:curUser.ledgerid

    //  FromDate: this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
    //  ToDate: this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
     // this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy")
      
    };
    this.service.httpPost('/ETSCustomsReport/GetEffortData',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;          
          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
            // this.stepper.selectedIndex = 1;  
            this.tabIndex=1;  
          //  console.log('STEEPER', this.stepper.selectedIndex)
            this.EffortDataGrids = result.respdata.Effortdata;
            this.TotHours = result.respdata.TotHrs;
            this.TotHours =this.TotHours[0].Hours;

            this.TotOTHours =result.respdata.TotOTHrs;
            this.TotOTHours =this.TotOTHours[0].Hours;
            //  this.EforrtDataFormGroup.controls.TotHours.setValue(this.TotHours[0].Hours);
            // console.log('effort list: ', result.respdata.Effortdata);

            this.Visiblelist = result.respdata.Visible;
            //  console.log('visible list ', this.Visiblelist);
             this.isTicketVisible = this.Visiblelist[8].Visibility;
             this.isCategoryVisible = this.Visiblelist[2].Visibility;          
             this.isProductVisible = this.Visiblelist[10].Visibility;
             this.isProjectVisible=this.Visiblelist[3].Visibility;
             this.isClientVisible=this.Visiblelist[4].Visibility;
             this.isStarttimeVisible=this.Visiblelist[5].Visibility;
             this.isEndtimeVisible=this.Visiblelist[6].Visibility;

             this.istickethiding = this.Visiblelist[8].Position;
             this.iscatgoryhiding = this.Visiblelist[2].Position;          
             this.isproducthiding = this.Visiblelist[10].Position;
             this.isprojecthiding=this.Visiblelist[3].Position;
             this.isclinethiding=this.Visiblelist[4].Position;

            
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }
  
  OngoingEffort()
  {
   // console.log('HI')
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));

    // if (this.EffortFormGroup.invalid) {
    //   this.notificationService.warn('Please select date range');     
    //   return;
    //   }  
    this.showSpinner=true;
      let params = {     
      CID: this.EffortFormGroup.get('fcCompany').value,
      Flag: "LOADGRID",               
      LedgerID:_.pluck(this.employees, 'LedgerID'),   //this.EffortFormGroup.get('fcEmployee').value,  
      Product:this.EffortFormGroup.get('fcProduct').value, 
      Project:this.projects,
      Client:this.EffortFormGroup.get('fcClient').value,
      Category:this.fcCategory.value,
      Status:this.EffortFormGroup.get('fcStatus').value,
      FromDate:this.fromdate,
      ToDate:this.todate,
      DateType:"ogt",
      UserName:curUser.ledgerid

    //  FromDate: this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
    //  ToDate: this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
     // this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy")
      
    };
    this.service.httpPost('/ETSCustomsReport/GetEffortData',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;          
          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
           // this.stepper.selectedIndex = 2;    
          //  console.log('STEEPER', this.stepper.selectedIndex)
            this.OngoingDataGrids = result.respdata.OngoingEffort;      
            this.TaskCount =result.respdata.OngoingEffort.length;               
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onExporting(e) {    
    // const workSheet = XLSX.utils.json_to_sheet(this.EffortDataGrids);
    // const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workBook, workSheet, 'Emplodgf');
    // XLSX.writeFile(workBook, 'gsg'+'.xlsx'); 
    // const workbook = new Workbook();    
    // const worksheet = workbook.addWorksheet('Main sheet');
    // exportDataGrid({
    //     component: e.component,
    //     worksheet: worksheet,
    //     customizeCell: function(options) {
    //         const excelCell = options;
    //         // excelCell.font = { name: 'Arial', size: 12 };
    //         // excelCell.alignment = { horizontal: 'left' };
    //     } 
    // }).then(function() {
    //     workbook.xlsx.writeBuffer()
    //         .then(function(buffer: BlobPart) {
    //             saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'gfdhdh.xlsx');
    //         });
    // });
    // e.cancel = true; 
       
}

exportAsExcel()
  {
    // , {header:['name', 'surname']}
  //  this.generic.excelExport(this.EffortDataGrids, 'Effort','EffortList From ' + this.datePipe.transform(this.EffortFormGroup.get('fcFromDate').value,"dd-MM-yyyy")+' to '+this.datePipe.transform(this.EffortFormGroup.get('fcToDate').value,"dd-MM-yyyy"));
  this.generic.excelExport(this.EffortDataGrids, 'Effort','EffortList From ' +  this.fromdate +' to '+ this.todate);
  }

  onSearchClear(){
   // console.log("mmmt",this.tabIndex)
    this.tabIndex =3;
    this.EffortFormGroup.reset(); 
    this.EffortDataGrids='';   
    this.tabIndex = 0;    
    this.EffortFormGroup.controls.fcCompany.setValue(this.cid);
    // this.EffortFormGroup.controls.fcFromDate.setValue(this.frmdate);
    // this.EffortFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
     this.DateTypeFlag="Today";
     this.projects = []; // making the chipList empty
     this.fcProject.reset(); // resets the control
     this.fcProject.markAsPristine();
     this.fcProject.markAsUntouched();

     this.employees = []; // making the chipList empty
     this.fcEmployee.reset(); // resets the control
     this.fcEmployee.markAsPristine();
     this.fcEmployee.markAsUntouched();

     this.categories =[];
     this.fcCategory.setValue(null);

      this.TaskCount=0;
  }

  addemployees(event1: MatChipInputEvent): void {
    const input = event1.input;
    const value = event1.value;
    if ((value || '').trim()) {
      this.employees.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.fcEmployee.setValue(null);
  }

  removeemployees(emp: string): void {
    const index = this.employees.indexOf(emp);
    if (index >= 0) {
      this.employees.splice(index, 1);
    }
  }
  optionActivated($event: MatAutocompleteActivatedEvent) {
    if ($event.option) {
      this.autocompleteTagsOptionActivated = true;
    }
  }
  selectedemployees(event1: MatAutocompleteSelectedEvent): void {
    this.employees.push(event1.option.value);
    this.empInput.nativeElement.value = '';
    this.fcEmployee.setValue(null);

  }
  // private _filteremployees(value1: string): string[] {
  //   console.log("_filteremployees",value1)
  //   const filterprojectValue = value1.toLowerCase();

  //   return this.allEmployees.filter(value1 => value1.toLowerCase().indexOf(filterprojectValue) === 0);
  // }
  //, {height:'43%',width:'20%'}
  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        height:'43%',
        width:'20%'
      }
    const dialogRef = this.dialog.open(DatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }

  onRowPrepared(e) {

    // if (e.rowType == 'data' && e.data.Approved == "0") {
    //   e.rowElement.style.backgroundColor = '#FCD1C7';
    // }
    // else if (e.rowType == 'data' && e.data.Approved != "0" && e.data.Approved != "1") {
    //   e.rowElement.style.backgroundColor = '#F7FC7F';      
    // }
     if (e.rowType == 'data' && e.data.Status == "Approved") {
      e.rowElement.style.backgroundColor = '#DAF9A9';
    
    }
  }

  // someFn()
  // {
  //   console.log("umar")
  // }
}
