import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { User } from 'src/app/Modules/user';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { Documentreqvalidate } from '../documentreqvalidate';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-uploaddocrequest',
  templateUrl: './uploaddocrequest.component.html',
  styleUrls: ['./uploaddocrequest.component.scss'],
})
export class UploaddocrequestComponent implements OnInit {
  showSpinner:any;
  parentForm: FormGroup;
  fileUploadId: any="uploadpath";
  public files: File[] = [];
  CID:any;
  flag:any;
  TicketID:any=0;
  CurLedgerID:any;
  formData = new FormData();
  Comment:any
  cdid:any;
  LedgerID:any;

  @Output() afterSelectionMethod: EventEmitter<any[]> = new EventEmitter();

  constructor(public service: HttpserviceService,public notificationService: NotificationService,private _snackBar: MatSnackBar, private modalRef: MatDialogRef<any>
    ,public DcValidate: Documentreqvalidate
    ,public dialogRef: MatDialogRef<UploaddocrequestComponent>,@Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    //console.log('jksdfhj',this.data)
    this.flag = this.data.flag;    
    this.cdid=this.data.cdid;
    this.LedgerID=this.data.ledgerID;
    this.CID=this.data.cid;
  
  }
  
  ngOnInit(){
    
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.parentForm = new FormGroup({
      fileUploadId: new FormControl('')
    });
  }
 
 
  onFilesChange(f){    
    this._snackBar.open(f.length + " File(s) selected!", null, {
        duration: 2000,
      });
     // console.log("dd",f)
      for (var i = 0; i < f.length; i++) {
        this.files.push(f[i]);
      }      
      //console.log('amjath',this.files);    
  }
  
    onSubmit()
    {    
      this.showSpinner=true;
        let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
        const formData = new FormData();

        for (var i = 0; i < this.files.length; i++) { 
          formData.append("file"+i, this.files[i]);
        }

          formData.append('LedgerID',this.LedgerID.toString());
          formData.append('CDID',this.cdid);
          formData.append('CID',this.CID);   
          formData.append('Flag','SAVEFILE');     
          formData.append('UserID',curUser.username);
          this.DcValidate.AddFileDetails(formData).subscribe(result => {
            this.showSpinner=true;
            setTimeout(() =>{
              this.showSpinner=false;
            },
            800)    
            let users: any
            users=result;
            if (users.errno === 0){
              this.notificationService.success('Upload successfully');
              this.onCloseDialog();
            }
        });
   }
  
    onCloseDialog()
    {      
      this.dialogRef.close({event: 'CLEAR'});
    }
  
    onRemove(event) {  
      console.log(event);
      console.log('this.files',this.files);
      this.files.splice(event, 1);
    }
}  
