import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { VoucherforHRComponent } from '../../voucherfor-hr/voucherfor-hr.component';

@Component({
  selector: 'app-leavesalaryencashposting',
  templateUrl: './leavesalaryencashposting.component.html',
  styleUrls: ['./leavesalaryencashposting.component.css']
})


export class LeavesalaryencashpostingComponent {

  cid:any=101;
  Categorylist:any;
  VouDate:any=new Date();
  Employeelist:any;
  LedgerID:any;
  LeaveID:any=120;
  Category:any=0;
  Prefixlist:any;
  Prefix:any;
  VouNo:any;
  flag:any;
  payslipmonth:any=new Date();
  Comment:any;
  dataSource:Details[]=[];
  step = 0;
  panelExpanded = false;
  AccountSource:any;
  ExcludeEmergency:Boolean=true;
  ExcludeUnPaidLeave:boolean=true;
  MenuID:any;
  Ledgerlist:any;
 
  CarryForward:number=0;
  AccuredLeave:number=0;
  EncashDays:number=0;
  LeaveToken:number=0;
  AvailableLeave:number=0;

  SalaryAmount:number=0;
  perdayleaveamt:number=0;
  CalenderDays:number=0;
  noofencashdays:number=0;

   objcsls:any;
   Daysinmonth1:any;
   ContractType:any;
   daysinyear1:any;

   isforceNo = false;
   HikeDetails:any;
   JoingDate:any;
   Yearlyleavetype:any;
   Leavelist:any;
   Total:number=0;
   wages_:any;

   constructor(private service:HttpserviceService,private datePipe: DatePipe,private dialog: MatDialog,private NotificationService: NotificationService, @Inject(MAT_DIALOG_DATA) public data: any)
  {
  
   this.Categorylist=data.datalist.Category;
   this.Employeelist=data.datalist.Employee;
   this.Prefixlist=data.datalist.Prefix;
   this.AccountSource= data.datalist.AccountGrid; 
   this.Prefix=this.Prefixlist[0].Prefix;
   this.Ledgerlist=data.datalist.Ledgerlist; 
   this.Leavelist= data.datalist.LeaveList; 

   this.flag=data.flag
   if(this.flag=='EDIT')
     {
       let parts = data.VouNo.split('/');
       this.Prefix=parts[0]+'/'
       this.VouNo=parts[1]
       this.Getstructure()
     }
   
  }
  ngOnInit() 
  {
    this.MenuID=sessionStorage.getItem('SelectedMenuID')

  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  togglePanel(event: MouseEvent) {
    event.stopPropagation(); // Prevent expansion panel from toggling
    this.panelExpanded = !this.panelExpanded;
  }
  SelectedEmployee()
  {
  
    const params = {
      _SiteID: this.cid,
      _LedgerID:this.LedgerID
     };   
 
     this.service.httpPost('/LeaveSalary/GetHikeDetails?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      

       this.HikeDetails=result.respdata.DT_SalaryTags
       
     });


  }
  Populate()
  {

    //this.showSpinner=true;
    const params = {
      _SiteID: this.cid,
      _LedgerID:this.LedgerID,
      _LeaveID:this.LeaveID,
      _TillDate:this.payslipmonth,
      DT_SalaryTags:this.HikeDetails.filter(item => item.Select===true),
      _MenuID:this.MenuID
  
     };   
 
     

 
     this.service.httpPost('/LeaveSalary/GetCalcLeaveSalary?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      

       this.objcsls=result.respdata.obj;
       this.JoingDate=this.datePipe.transform(this.objcsls._JoiningDate,'dd/MM/yyyy');
       this.Yearlyleavetype=this.objcsls._YearlyLeaveType;
     
      this.CarryForward=this.objcsls._CarryForward;
      this.AccuredLeave=this.objcsls._AccuredAnnualLeave;
     
      this.LeaveToken=this.objcsls._LeaveTaken;
      this.AvailableLeave=this.objcsls._AvailableLeave;

      this.SalaryAmount=this.objcsls._SalaryAmount;
      this.perdayleaveamt=this.objcsls._PerDayAmount;
      //this.CalenderDays=this.objcsls._CalendarDays;


      if (this.flag=='ADD')
      {
        this.noofencashdays=this.objcsls._AvailableLeave;
        this.CalenderDays = this.Yearlyleavetype == "Calendar Days"? this.objcsls._AvailableLeave: this.objcsls._AvailableLeave * 7 / 5

        this.dataSource=[];
        this.dataSource=this.objcsls.DT_EnCash
  
   
        const totalAmount = this.dataSource.reduce((sum, item) => sum + item.Total,0);
        this.AccountSource.map(item=> item.TCDebit=totalAmount);
        this.Total=totalAmount;
      }
     
     
    

     //this. Assignlabels();

      
     });

  }
 async Getstructure()
  {
    const params = {
      _SiteID: this.cid,
      _VouNo:this.Prefix+this.VouNo

     };   
 
     

 
    let t= await this.service.httpPost('/LeaveSalary/GetLeaveSalary?' ,params)   
     .toPromise().then(data =>{
       var result = data as any;
       result = data;      
 
       
       this.objcsls=result.respdata.obj;
 
       this.LeaveID=this.objcsls._LeaveID;
      this.LedgerID=this.objcsls._LedgerID;
      this.noofencashdays=this.objcsls._EnCashDays;
      this.CalenderDays=this.objcsls._CalendarDays;
      this.VouDate=this.objcsls._dtpPostDate;
      this.payslipmonth=this.objcsls._TillDate;

      this.AccuredLeave=this.objcsls._AccuredAnnualLeave;
    
      this.LeaveToken=this.objcsls._LeaveTaken;
      this.AvailableLeave=this.objcsls._AvailableLeave;

      this.SalaryAmount=this.objcsls._SalaryAmount;
      this.perdayleaveamt=this.objcsls._PerDayAmount;
     

      this.dataSource=[];
      this.dataSource=this.objcsls.DT_EnCash;
      this.HikeDetails=this.objcsls.DT_SalaryTags;
      this.AccountSource=result.respdata.InvoiceAccounts

      this.Populate();
      this.Get_MatchedInvoice()

      
     });
  }
  onRowUpdated(e)
  {
    // console.log('onRowUpdated33',this.dataSource[0].Amount)
    // this.recalculateAmounts();

    e.data.Total=e.data.NoOfLeavetoEnCash*e.data.PerDayLeaveAmount;

    const totalAmount = this.dataSource.reduce((sum, item) => sum + item.Total,0);
    this.AccountSource.map(item=> item.TCDebit=totalAmount);
    this.Total=totalAmount;

  }
  // onCellValueChanged(event) {
  //   console.log('Cell value changed:', event);
  //   // Implement your logic here
  // }
  // OnCellPrepared(e)
  // {
  //   console.log('OnCellPrepared',e)
  // }
  onRowInserted(e)
  {
    e.data.Total=e.data.NoOfLeavetoEnCash*e.data.PerDayLeaveAmount;

    const totalAmount = this.dataSource.reduce((sum, item) => sum + item.Total,0);
    this.AccountSource.map(item=> item.TCDebit=totalAmount);
    this.Total=totalAmount;

  }
  onTabClick(e)
  {

  }
  Save()
  {

    //let wages_ =  this.wages_.filter(item => item.LedgerID==this.LedgerID)

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
          cid:this.cid,
          WAGES:this.wages_,
          LedgerID:this.LedgerID,
          AdvType:'CR',
          FormType:'ELS'
      };
 
      const dialogRef = this.dialog.open(VoucherforHRComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {

        const sumOfPayNow = result.Wageslist.reduce((sum, item) => sum + item.PayNow, 0);

        const Totalamt =  this.dataSource.reduce((sum, item) => sum + item.Total,0)

        if (sumOfPayNow>Totalamt)
        {
         alert("Mismatch matched amount");
        }
        else
        {
          this.wages_=result.Wageslist.filter(Item=> Item.PayNow>0)


    let RefNo='';

      if (this.flag=='ADD' && this.isforceNo==true)
      {RefNo=this.Prefix+this.VouNo}
      else if(this.flag=='ADD'){RefNo=''}
      else{RefNo=this.Prefix+this.VouNo}

    let name=this.Employeelist.filter(item => item.LedgerID==this.LedgerID);
   // let dateStr = this.fr;
      // let parts = dateStr.split('-');
      // let formattedDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

    const params = {
       

      csLS:{
        _SiteID: this.cid,
        _Flag:this.flag,
        _MenuID:this.MenuID,
        _FormPrefix:this.Prefix,
        _BusinessPeriodID:101,
        _LeaveID:120,
        _VouNo:RefNo,
        _LedgerID:this.LedgerID,
        _Alias:name[0].AliasName1,
       _dtpPostDate:this.VouDate,  //this.datePipe.transform(this.VouDate,'dd-MM-yyyy'),
       _JoiningDate:this.JoingDate,
       _TillDate:this.payslipmonth,
        _Comment:"",
        // _YearlyLeave:"",
        _YearlyLeaveType:this.Yearlyleavetype,
        _CarryForward:this.CarryForward,
        _AccuredAnnualLeave:this.AccuredLeave,
        _LeaveTaken:this.LeaveToken,
        _AvailableLeave:this.AvailableLeave,
        _SalaryAmount:this.SalaryAmount,
        _PerDayAmount:this.perdayleaveamt,
        _TotalAmount:this.dataSource.reduce((sum, item) => sum + item.Total,0),
        _EnCashDays:this.noofencashdays,
        _CalendarDays:this.CalenderDays,
        CreatedBy:"",
        // CreatedDate:new Date(),
        // LastUpdatedBy:"",
        // LastUpdatedDate:new Date(),
        // ApprovedBy:"",
        // ApprovedDate:new Date(),
        DT_SalaryTags:this.HikeDetails,
        DT_EnCash:this.dataSource,
        DT_Wages:this.wages_
       
       
      },
      DT_AccountLedger:this.AccountSource
     
  
        };   
    
        //console.log("filter",params)
        this.service.httpPost('/LeaveSalary/Update_LeaveSalary?' ,params)   
        .subscribe(data =>{
          var result = data as any;
          result = data;      
    
          if (result.errno === 0)
            {
              this.NotificationService.success("Posting Successfully");
            }
            else
            {
              this.NotificationService.warn(result.errdesc);
            }
             
        
           
        });


      }

      // e.editorOptions.value =sumOfPayNow
      // e.setValue =sumOfPayNow
      // this.payslipgrid[e.row.rowIndex].ADVDeduct=sumOfPayNow

    //   this.wages_.forEach(item1 => {
    //     const match = result.Wageslist.find(item2 => item1.LedgerID === item2.LedgerID && item1.ReqID === item2.ReqID);
    //     if (match) {
    //         // Update PayNow and AmountType
    //         item1.PayNow = match.PayNow;
    //         item1.AmountType = match.AmountType;
    //     }
    // });

  });
  
  }

  onRowInserted1(event: any) {
    if (event.dataIndex === 0) return; // If it's the first row, do nothing
  
    // Assuming the TCDebit value of the first row is stored in a variable named firstRowTCDebit
    const firstRowTCDebit = this.AccountSource[0].TCDebit;
  
    // Subtract the TCDebit value of the newly added row from the TCDebit value of the first row
    if(firstRowTCDebit>=event.data.TCDebit)
    {
      this.AccountSource[0].TCDebit = firstRowTCDebit - event.data.TCDebit;
    }
    else 
    {
      alert('Overmatching');
  
      this.AccountSource.pop();
    }
   
  }
 async Get_MatchedInvoice()
  {
    const params = {
      cid: this.cid,
      LedgerID:this.LedgerID,
      _AdvType:"CR",
      _FormType:"ELS",
      objmain:{
        str_PSRef:this.Prefix+this.VouNo,
        int_BusinessPeriodID:105
  
  
      }
    };   
    
    
    let test=await this.service.httpPost('/Payslip/GetHRDetialsForVou?' ,params)   
    .toPromise().then(data =>{
      var result = data as any;
      result = data;      
  
       this.wages_=result.respdata.Payslipobj.dt_Wages;
    
        
    });
    
    
  }
}
export interface Details
{
  NoOfLeavetoEnCash:number,
  PerDayLeaveAmount:number,
  Total:number,
  Comment:string
}
