import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { VoucherforHRComponent } from '../voucherfor-hr/voucherfor-hr.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DxDataGridComponent } from 'devextreme-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { PayslippaymentfilterComponent } from '../EmpPayslipPayment/payslippaymentfilter/payslippaymentfilter.component';

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.scss'],
})
export class PayslipComponent implements OnInit {
  @ViewChild('gridContainer', { static: false }) gridContainer!: DxDataGridComponent;


  showSpinner:any;
  curUser: User= JSON.parse(sessionStorage.getItem('currentUser'));
  cid:any=101;
  flag:any="ADD";
  LedgerID:any=0;
  fromdate:any=new Date() ;
  todate:any= new Date();
  dataSource:any;
  dataSource1:any;
  Prefix:any;
  columnNames:any;
  currentPage = 1;
  pageSize = 17;
  companies:any;
  Employeelist:any;
  Categorylist:any;
  Typelist:any;
  Prefixlist:any;
  payslipmonth:any=new Date() ;
  VouDate:any=new Date() ;
  
  Category:any=0;
  Type:any=0;
  payslipgrid:any;
  payslipobj:any;
  AccountSource: any;
  Ledgerlist:any;
  DaysInMonth:any;
  DT_PaySlipMain=[];
  DT_PaySlipSub=[];
  _PSRef:any="";
  VouNo:any;
  isshowme:boolean=true;
  wages_:any;
  isDialogOpen: boolean = false;
  dynamicColumnNames: string[] = [];
  columns: Array<any> = [];
  filteredEmployeelist: BehaviorSubject<any[]>;
  employeeFilterCtrl = new FormControl();
  sumofearings:number=0;
  sumofdeduction:number=0;
  sumofnetamount:number=0;
  sumofpaidamount:number=0;
  DateTypeFlag:any='Today';
  selectedTabIndex = 0;

  // get rows(): any[] {
  //   const startIndex = (this.currentPage - 1) * this.pageSize;
  //   const endIndex = Math.min(startIndex + this.pageSize, this.dataSource.length);
  //   return this.dataSource.slice(startIndex, endIndex);
  // }

  // getCurrentPageData(): any[] {
  //   const startIndex = (this.currentPage - 1) * this.pageSize;
  //   const endIndex = Math.min(startIndex + this.pageSize, this.dataSource.length);
  //   return this.dataSource.slice(startIndex, endIndex);
  // }

  displayedColumns =['PSRef','PSMonth','Earnings','Deductions','NetAmount','PaidAmount','Status','Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;



  constructor(private service:HttpserviceService,private datePipe: DatePipe,public dialog: MatDialog,private NotificationService: NotificationService) 
  { 
    this.fromdate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
    this.todate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
  }


  ngOnInit() 
  {
    this.LoadData()
    this.companies=this.curUser.dtsiteid;

    this.employeeFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        map(filterText => this.filterEmployees(filterText))
      )
      .subscribe(filteredEmployees => {
        this.filteredEmployeelist.next(filteredEmployees);
      });
    console.log("eventtst")
  }
  onCompanySelect(event)
  {
    this.cid=event;
    this.LoadData();

  }
  selectionChange(event: StepperSelectionEvent) {
    console.log('action',event.selectedIndex);
    let stepLabel = event.selectedStep.label
    if (event.selectedIndex == 1) {
      this.flag="ADD";
      this.LedgerID=0;
      this.Category=0;

      
    }
    this.payslipgrid=[];
      this.AccountSource=[];
      this.LoadData()
  }

  Populate()
  {

    //this.AccountSource.push({'Ledger':186,'TCDebit':0,'TCCredit':0,'Comment':''})
    this.selectedTabIndex=0;
  

    const year = this.payslipmonth.getFullYear(); // Get the current year
    const month = this.payslipmonth.getMonth() + 1; // Get the current month
    const daysInMonth = this.getDaysInMonth(year, month);

    this.showSpinner=true;
    const params = {
      cid: '101',
      payslipmonth:this.datePipe.transform( this.payslipmonth,"MM-dd-yyyy"),
      Category:this.Category==0 ? '--ALL--':this.Category,
      LedgerID:this.LedgerID,
      TemplateID:this.Type,
      objmain:{
        str_PSRef:"",
        int_BusinessPeriodID:105


      }

     
    };   

  
    this.service.httpPost('/Payslip/BuildPayslip?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      
      this.columns=[];
      console.log("payslip",result.respdata)
      this.payslipgrid=result.respdata.PayslipTemplateGrid;
      this.payslipobj=result.respdata.Payslipobj;
      this.wages_=this.payslipobj.dt_Wages;
      // console.log("caption",this.payslipgrid.PDAYS)

      if (this.payslipgrid.length > 0) {
        this.dynamicColumnNames = Object.keys(this.payslipgrid[0]);
      }

      for (var i: number = 0; i < this.payslipobj.DT_PayslipTemplate.length; i++) {
        const keydata = this.payslipobj.DT_PayslipTemplate[i];

        this.columns.push({
          dataField: keydata.Tag,
          caption:keydata.CustomText,
          // allowSearch:false,
          // width :keydata.Width,
          // allowEditing:keydata.allowEditing,
          // visible:keydata.Visibility,
          // precision:2,
          // FormatString:2 ,
          // editorOptions:"{ onValueChanged:changeValueCodeCompteurCET, showClearButton:true}"
        })
  
      }
        
    
      

    console.log("caption", this.dynamicColumnNames)

      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
         
        
    });

  }
  edit(item,stepper: MatStepper)
  {
    stepper.next();
    console.log("edit",item)

    this.isDialogOpen=false

    this.flag="EDIT";
    this._PSRef=item.PSRef;
    this.VouNo=item.PSRef;
    const params = {
      cid: '101',
      // objmain.str_PSRef:this.datePipe.transform( this.payslipmonth,"MM-dd-yyyy"),
      Category:this.Category==0 ? '--ALL--':this.Category,
      LedgerID:0,
      TemplateID:this.Type,
      objmain:{
        str_PSRef:item.PSRef,
        int_BusinessPeriodID:105


      }

     
    };   

  
    this.service.httpPost('/Payslip/GetPaySlipDetails?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("payslip",result.respdata)
      this.payslipgrid=result.respdata.PayslipTemplateGrid;
      this.payslipobj=result.respdata.Payslipobj;
      this.wages_=result.respdata.Payslipobj.dt_Wages;


      // this.showSpinner=true;
      // setTimeout(() =>{
      //   this.showSpinner=false;
      // },
      // 800)  
         
        
    });
  }
  showme()
  {
    this.isshowme = !this.isshowme;
  }
  Save()
  {
 
    //debugger;
    this.Build_PaySlipMainDT();
    this.Build_PaySlipSubDT();
    let accot=this.AccountSource;

    console.log("DT_PaySlipMain",this.DT_PaySlipMain)
    console.log("dt_Wages", this.wages_)
   
    // Assuming _DTWages and ObjcsPaySlip.objPSMain.DT_PaySlipMain are arrays of objects

let both = this.wages_.filter(row1 =>
  this.DT_PaySlipMain.some(row2 =>
      row1.LedgerID === row2.LedgerID
  )
);
console.log("both",both)


const params = {
  CID: this.cid,
  str_Flag:this.flag,
  str_FormPrefix:this.Prefix,
  str_MenuID:"ERP_223",
  str_Type:this.Type,
  int_BusinessPeriodID:105,
  str_PSRef:this._PSRef,
  int_RevNo:0,
  date_PostDate: this.datePipe.transform( this.VouDate,"MM-dd-yyyy"),
  date_PSMonth:this.datePipe.transform( this.payslipmonth,"MM-dd-yyyy"), 
  dt_PaySlipMain:this.DT_PaySlipMain,
  dt_PaySlipSub:this.DT_PaySlipSub,
  dt_InvoiceAccounts:this.AccountSource,
  dt_Wages:both,
  TemplateID:this.Type,
  str_CurrencyCode:"AED"

 
};   


this.service.httpPost('/Payslip/UpdateMultiPayroll?' ,params)   
.subscribe(data =>{
  var result = data as any;
  result = data;   
 

  console.log('result',result)
  if (result.errno === 0)
  {
    this.NotificationService.success(result.errdesc);
  }
  else
  {
    this.NotificationService.warn(result.errdesc);
  }

  //console.log("payslip",result.respdata)
  // this.payslipgrid=result.respdata.PayslipTemplateGrid;
  // this.payslipobj=result.respdata.Payslipobj;


  // this.showSpinner=true;
  // setTimeout(() =>{
  //   this.showSpinner=false;
  // },
  // 800)  
     
    
});


  }
  // getColumnIndex(cellElement: HTMLElement): number {
  //   let columnIndex = -1;
  //   const cell = cellElement.closest('.dx-datagrid-cell');
  //   if (cell) {
  //     columnIndex = Array.from(cell.parentElement.children).indexOf(cell);
  //   }
  //   return columnIndex;
  // }

  // onCellClick(e: any) {
  //   // this.rowIndex = e.rowIndex;
  //   // this.columnIndex = e.columnIndex;

  //   console.log("event.key", e.rowIndex);
  //   console.log("ledgerID", e.columnIndex);
  // }

  // getColumnIndex(fieldName: string): number {
  //   const columns = this.dataGrid.instance.getVisibleColumns();
  //   for (let i = 0; i < columns.length; i++) {
  //     if (columns[i].dataField === fieldName) {
  //       return i;
  //     }
  //   }
  //   return -1; // Column not found
  // }

  onEditorPreparing(e) {
    if (e.parentType === "dataRow" && (e.dataField === "ADVDeduct" || e.dataField === "ADVGiven"  ) && !this.isDialogOpen) {
      // This is where you handle the beginning of editing for a cell
      console.log("Cell begin edit:", e);

      this.isDialogOpen = true;

      let wages_ =  this.wages_.filter(item => item.LedgerID==e.row.data.LedgerID)

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {
          cid:this.cid,
          WAGES:wages_,
          LedgerID:e.row.data.LedgerID,
          AdvType:e.dataField == "ADVDeduct" ? 'CR':'DR'
      };
 
      const dialogRef = this.dialog.open(VoucherforHRComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {

        console.log('dialog',result);


        const sumOfPayNow = result.Wageslist.reduce((sum, item) => sum + item.PayNow, 0);

        

        e.editorOptions.value =sumOfPayNow
        e.setValue =sumOfPayNow
        this.payslipgrid[e.row.rowIndex].ADVDeduct=sumOfPayNow

        this.wages_.forEach(item1 => {
          const match = result.Wageslist.find(item2 => item1.LedgerID === item2.LedgerID && item1.ReqID === item2.ReqID);
          if (match) {
              // Update PayNow and AmountType
              item1.PayNow = match.PayNow;
              item1.AmountType = match.AmountType;
          }
      });


// const indexToUpdate = this.wages_.findIndex(item => item.LedgerID === result.Wageslist.LedgerID && item.ReqID === result.Wageslist.ReqID);
// console.log('PayNow',indexToUpdate)


// If the object is found, update it
// if (indexToUpdate !== -1) {
//   console.log('PayNow1',result.Wageslist.PayNow)
//   this.wages_[indexToUpdate].PayNow = result.Wageslist.PayNow;

  
 
// }

const params = {
  cid: this.cid,
  payslipmonth:this.datePipe.transform( this.payslipmonth,"MM-dd-yyyy"),
  Category:this.Category==0 ? '--ALL--':this.Category,
  LedgerID:e.row.data.LedgerID,
  TemplateID:this.Type,
  dt_Employees:this.payslipobj.dt_Employee,
  dt_Absent:this.payslipobj.dt_Absent,
  dt_SalPartWithGrossAmount:this.payslipobj.dt_SalPartWithGrossAmount,
  dt_OTHours:this.payslipobj.dt_OTHours,
  dt_WeekEnd:this.payslipobj.dt_WeekEnd,
  DT_PayslipTemplate:this.payslipobj.DT_PayslipTemplate,
  rowindex:e.row.rowIndex,
  colindex:3,
  _DaysInMonth:this.payslipobj._DaysInMonth,
  dt_PayslipTemplateGrid:this.payslipgrid,
  Columnname:e.dataField,
  editValue:sumOfPayNow
 
};   


this.service.httpPost('/Payslip/Cellvaluechanged?' ,params)   
.subscribe(data =>{
  var result = data as any;
  result = data;      

  console.log("payslip",result.respdata)
   //this.payslipgrid=result.respdata;
   this.payslipgrid=result.respdata.PayslipTemplateGrid;
   this.payslipobj=result.respdata.Payslipobj;


 
     
    
});

// Output the updated array
console.log('wages_',this.wages_);

        this.isDialogOpen = false;
      });

    }
  }
//   onCellClick(e) {
//     // 'e' parameter contains information about the event
//     console.log('Cell clicked:', e);
    
//     // Example: Get data of clicked cell
//     const rowIndex = e.rowIndex;
//     const columnIndex = e.columnIndex;
//     const cellData = e.data[columnIndex]; // Assuming you want to access cell data based on column index

//     // Perform any actions you need based on the clicked cell
// }


  onRowUpdating(event: any) {
    const ledgerID = event.key; // Assuming 'LedgerID' is the primary key field name

    console.log("event", event);

    // Get the index of the row with the matching primary key value
    const rowIndex = this.payslipgrid.findIndex(row => row.LedgerID === ledgerID);

    if (rowIndex !== -1) {
      // Now you have the row index
     

      // Proceed with your logic
    } else {
      console.error("Row not found in the data source.");
    }


    // Now you have the row index and column index
    //console.log("Row Index:", rowIndex);
    //console.log("Column Index:", columnIndex);
    const jsonData: string =JSON.stringify(event.newData) ;
    const data: { [key: string]: any } = JSON.parse(jsonData);
    const columnName: string = Object.keys(data)[0];
const value: any = data[columnName];

   if (columnName !=='Updated')
   {
    const params = {
      cid: this.cid,
      payslipmonth:this.datePipe.transform( this.payslipmonth,"MM-dd-yyyy"),
      Category:this.Category==0 ? '--ALL--':this.Category,
      LedgerID:ledgerID,
      TemplateID:this.Type,
      dt_Employees:this.payslipobj.dt_Employee,
      dt_Absent:this.payslipobj.dt_Absent,
      dt_SalPartWithGrossAmount:this.payslipobj.dt_SalPartWithGrossAmount,
      dt_OTHours:this.payslipobj.dt_OTHours,
      dt_WeekEnd:this.payslipobj.dt_WeekEnd,
      DT_PayslipTemplate:this.payslipobj.DT_PayslipTemplate,
      rowindex:rowIndex,
      colindex:3,
      _DaysInMonth:this.payslipobj._DaysInMonth,
      dt_PayslipTemplateGrid:this.payslipgrid,
      Columnname:columnName,
      editValue:value
     
    };   
    
    
    this.service.httpPost('/Payslip/Cellvaluechanged?' ,params)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      
    
      console.log("payslip",result.respdata)
       //this.payslipgrid=result.respdata;
       this.payslipgrid=result.respdata.PayslipTemplateGrid;
       this.payslipobj=result.respdata.Payslipobj;
    
    
     
         
        
    });

  }
  else if (columnName =='Updated' && value==true)
  {
  //   debugger;
  //   console.log("total",this.payslipgrid)

   const t =this.payslipgrid[rowIndex].NSAL;
   

  //   const sum = this.payslipgrid.reduce((total, currentItem) => {
  //     // Assuming you want to sum NSAL values only for items where another property meets a condition
  //     if (currentItem.Updated === true) {
  //         // Replace someProperty and desiredValue with your actual property and condition
  //         return total + currentItem.NSAL;
  //     } else {
  //         return total;
  //     }
  // }, 0); // 0 is the initial value of the sum
  
       console.log('Sum of the column:', t);

    // this.AccountSource.map(item=> item.TCDebit=500);

    const sum = this.payslipgrid.reduce((total, currentItem) => {
      // Assuming you want to sum NSAL values only for items where another property meets a condition
      if (currentItem.Updated == true) {
          // Replace someProperty and desiredValue with your actual property and condition
          return total + currentItem.TOTEAR;            ;
      } else {
          return total;
      }
  }, 0); // 0 is the initial value of the sum
  
  console.log('this.payslipgrid', this.payslipgrid);

  this.AccountSource.map(item=> item.TCDebit=item.TCDebit+t);
  }
  console.log('AccountSource', this.AccountSource);
  }
  
  onTabClick(event: any): void {
    // Extract information about the selected tab
    const index = event.index;
    const tab = event.tab;

    // Log the information to the console or perform any other actions
    //console.log('Tab clicked:', index, tab.textLabel);

    if(index==1)
    {
      const sum = this.payslipgrid.reduce((total, currentItem) => {
        // Assuming you want to sum NSAL values only for items where another property meets a condition
        if (currentItem.Updated === true) {
            // Replace someProperty and desiredValue with your actual property and condition
            return total + currentItem.TOTEAR;            ;
        } else {
            return total;
        }
    }, 0); // 0 is the initial value of the sum
    
    console.log('Sum of the column:', sum);
    this.AccountSource.map(item=> item.TCDebit=sum);
    }
  }
  getDaysInMonth(year: number, month: number): number {
    // JavaScript months are 0-based, so we subtract 1 from the month
    // to get the correct index.
    return new Date(year, month, 0).getDate();
}

Filter()
   {
    const dialogRef = this.dialog.open(PayslippaymentfilterComponent, {
      width: '30%', // Set the width of the dialog
      height:'85%',
      position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        LedgerID:0,
        EmpName:'',
        Category:this.Category,     
        Type:'',
        DateTypeFlag:this.DateTypeFlag,
        fromdate:this.fromdate,
        todate:this.todate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
        // this.qtnstatus=result.Status
        this.cid=result.CID
        // this.EmpID=result.LedgerID
        // this.EmpName=result.Name
        this.Category=result.Category
        // this.PSType=result.Type
        this.DateTypeFlag=result.DateTypeFlag
        this.fromdate=result.FromDate
      // const  dateObj= new Date(result.ToDate); 
        this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
        this.LoadData()
      }
     
    });
   }
   
  LoadData()
  {

    this.showSpinner=true;
    const params = {
      cid: this.cid,
      MenuID:'ERP_223',
      Date1:'--ALL--',
      FromDate:this.datePipe.transform(this.fromdate,"MM-dd-yyyy")  ,
      ToDate:this.datePipe.transform( this.todate,"MM-dd-yyyy")
    };   

    
    const query = `cid=${params.cid}&MenuID=${params.MenuID}&Date1=${params.Date1}&FromDate=${params.FromDate}&ToDate=${params.ToDate}`;


    this.service.httpGet('/Payslip/GetEmppaySlipmain?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data;      

      console.log("mm",result.respdata)
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
     
      this.dataSource= new MatTableDataSource(result.respdata.PayLedger);  
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
        this.Employeelist= result.respdata.Employee; 
        this.Categorylist= result.respdata.Category; 
        this.Typelist= result.respdata.Type; 
        this.Prefixlist= result.respdata.Prefix; 

        this.filteredEmployeelist = new BehaviorSubject<any[]>(this.Employeelist);

        // this.dataSource1 = result.respdata.Table; 
        // this.columnNames=result.respdata.columnNames;
        // console.log("columnNames",this.columnNames)
       // this.dataSource1 = this.getCurrentPageData();
        this.Ledgerlist= result.respdata.Ledgerlist; 
        this.AccountSource= result.respdata.AccountGrid; 

        console.log("Prefixlist",result.respdata.PayLedger)
        this.Type=this.Typelist[0].VouNo;
        this.Prefix=this.Prefixlist[0].Prefix;


        this.sumofearings = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.Earnings}, 0);
        this.sumofdeduction = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.Deductions}, 0);
        this.sumofnetamount = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.NetAmount}, 0);
        this.sumofpaidamount = result.respdata.PayLedger.reduce((total, currentItem) => {return total + currentItem.PaidAmount}, 0);
    
        this.sumofearings = +this.sumofearings.toFixed(2);
        this.sumofdeduction = +this.sumofdeduction.toFixed(2);
        this.sumofnetamount = +this.sumofnetamount.toFixed(2);
        this.sumofpaidamount = +this.sumofpaidamount.toFixed(2);
       
    });
  }

//   nextPage(): void {
//     if (this.currentPage * this.pageSize < this.dataSource.length) {
//       this.currentPage++;
//     }
//   }

//   prevPage(): void {
//     if (this.currentPage > 1) {
//       this.currentPage--;
//     }
//   }
// }
private filterEmployees(filterText: string): any[] {
  if (!filterText) {
    return this.Employeelist;
  }
  return this.Employeelist.filter(employee =>
    employee.AliasName1.toLowerCase().includes(filterText.toLowerCase())
  );
}

 Build_PaySlipMainDT() {
  
  
  for (var i = 0; i < this.payslipgrid.length; i++) {
      if (this.payslipgrid[i].Updated == true) {
          
         let PTagName = this.payslipobj.DT_PayslipTemplate.filter(item => item.PSMainTag=='PresDays');
         let ATagName = this.payslipobj.DT_PayslipTemplate.filter(item => item.PSMainTag=='AbsDays');
         let HTagName = this.payslipobj.DT_PayslipTemplate.filter(item => item.PSMainTag=='OTHours');
         let ETagName = this.payslipobj.DT_PayslipTemplate.filter(item => item.PSMainTag=='Earnings');
         let DTagName = this.payslipobj.DT_PayslipTemplate.filter(item => item.PSMainTag=='Deductions');


         console.log('Comment',this.payslipgrid[i]['Comment']);
         let t1=PTagName[0].Tag
         console.log('PTagName',this.payslipgrid[i][t1]);

          this.DT_PaySlipMain.push({"PSNO":this.payslipgrid[i].PSNo,"LedgerID":this.payslipgrid[i].LedgerID,"WorkDays":this.payslipobj._DaysInMonth
                              ,"PresDays":this.payslipgrid[i][PTagName[0].Tag],"AbsDays":this.payslipgrid[i][ATagName[0].Tag],
                              "OTHours":this.payslipgrid[i][HTagName[0].Tag],"Earnings":ETagName.length>0 ? this.payslipgrid[i][ETagName[0].Tag]:0
                              ,"Deductions":DTagName.length>0  ? this.payslipgrid[i][DTagName[0].Tag]:0 ,"NetAmount":this.payslipgrid[i]['NSAL']
                              ,"Comment":this.payslipgrid[i]['Comment'] !==undefined? this.payslipgrid[i]['Comment'].length :'',"PaymentStatus":''});

          
          console.log('DT_PaySlipMain',this.DT_PaySlipMain);
      }
  }

  return this.DT_PaySlipMain;
}

Build_PaySlipSubDT() {
 
  
  for (var i = 0; i < this.payslipgrid.length; i++) {
      if (this.payslipgrid[i].Updated == true) {
          
        for (var j = 0; j < this.payslipobj.DT_PayslipTemplate.length; j++)
        {
          this.DT_PaySlipSub.push({"PSNO":this.payslipgrid[i].PSNo,"LedgerID":this.payslipgrid[i].LedgerID
          ,"Tag":this.payslipobj.DT_PayslipTemplate[j].Tag,"Value":this.payslipgrid[i][this.payslipobj.DT_PayslipTemplate[j].Tag],
         });
        }


          console.log('DT_PaySlipMain',this.DT_PaySlipSub);
      }
  }

  return this.DT_PaySlipSub;
}

// prevPage(): void {
//   if (this.currentPage > 1) {
//     this.currentPage--;
//     this.dataSource1 = this.getCurrentPageData(); // Update current page data
//   }
// }

// // Method to navigate to the next page
// nextPage(): void {
//   const lastPage = Math.ceil(this.dataSource.length / this.pageSize);
//   if (this.currentPage < lastPage) {
//     this.currentPage++;
//     this.dataSource1 = this.getCurrentPageData(); // Update current page data
//   }

  
// }

onRowInserted(event: any) {
  if (event.dataIndex === 0) return; // If it's the first row, do nothing

  // Assuming the TCDebit value of the first row is stored in a variable named firstRowTCDebit
  const firstRowTCDebit = this.AccountSource[0].TCDebit;

  // Subtract the TCDebit value of the newly added row from the TCDebit value of the first row
  if(firstRowTCDebit>=event.data.TCDebit)
  {
    this.AccountSource[0].TCDebit = firstRowTCDebit - event.data.TCDebit;
  }
  else 
  {
    alert('Overmatching');

    this.AccountSource.pop();
  }
 
}


}

