import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';
import { User } from '../../../../Modules/user';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { TicketComponent } from '../../../ITS/Ticket/ticket/ticket.component';
import { TaskbyviewComponent } from '../taskbyview/taskbyview.component';
import { CrmdatecontrolComponent } from '../../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { DependencyrptComponent } from '../../dependencyrpt/dependencyrpt.component';
import { EmiteventserviceService } from 'src/app/Services/OtherServices/emiteventservice.service';
import { exportDataGrid } from 'devextreme/pdf_exporter';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

@Component({
  selector: 'app-taskby',
  templateUrl: './taskby.component.html',
  styleUrls: ['./taskby.component.scss'],
})
export class TaskbyComponent implements OnInit {
  // @ViewChild('gridContainer', { static: false }) gridContainer: DxDataGridComponent;
  @ViewChild('stepper') stepper;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
 

  dataSource: any;
  dataSource1: any;
  CustomsReportFormGroup: FormGroup;
  CustomDataFormGroup:FormGroup;
  Companies: any;
  Employees:any;  
  employeeid:string[]=[];
  selectedEmpID: any;
  selectedEmpID1: any;
  frmdate= new Date();
  cid:any; 
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  myControl2 = new FormControl();
  filteredOptions2: Observable<string[]>;
  showSpinner:any;
  myControl1 = new FormControl();
  filteredOptions1: Observable<string[]>;
  selectedProjectID: number;
  projectid: string[] = [];
  selectedprojectid: any;
  Projectlist:any;
  isEditable = true; 
  RequestType:any;
  Clients:any;
  Issues:any;
  Location:any;
  Priority:any;
  Status:any;
  Branch:any;
  Product:any;
  CurLedgerID:number;
  isColorCodeVisible: boolean;
  eventID:any;
  public filteredList5
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"
  TaskTemplate:any;
  Activityflag:any;
  TicketID:any='0';
  ActiviteID:any
  TemplateGroupID:any;
  TemplateID:any=0;
  UserID:any;
  Dependencybtnvisible: boolean = false;
  FilterBy:any;
  Createddatevisible:boolean=true;
  Updateddatevisible:boolean=false;
  TaskBy = [
    { name: 'Created Date' },
    { name: 'Updated Date' }    
  ];
  TicketCategory = [
    { name: 'Internal' },
    { name: 'External' }    
  ];

  SLATime = [
    { id: 0,name: 'Inline SLA Tickets' },
    {  id: 1,name: 'Exceed SLA Tickets' },  
  ];
 

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog: MatDialog,
    private eventService: EmiteventserviceService,
    public notificationService: NotificationService, private datePipe: DatePipe) { 
      this.CustomsReportFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] , 
        myControl: [''],
        myControl1: [''],
        myControl2: [''],
        fcRequestType:[''],
        fcClient:[''],
        fcIssue:[''],
        fcLocation:[''],
        fcPriority:[''],
        fcStatus:[''],
        fcBranch:[''],
        fcProduct:[''],
        fcSubject:[''],
        fcTaskBy:[''],
        fcTicketCatgory:[''],
        fcInline:['']
      //  fcFromDate: ['', Validators.required],
      //  fcToDate: ['', Validators.required]
        , fcSLA:['']
      });
      this.CustomDataFormGroup = this.formBuilder.group({                 
      });
    }

  ngOnInit(): void  {

    // document.addEventListener('DOMNodeInserted', function () {
    //   const elements = document.querySelectorAll('[aria-owns]');

    //   elements.forEach(element => {
    //     element.removeAttribute('aria-owns');
    //   });
    // });

    this.CustomsReportFormGroup.controls.fcTaskBy.setValue('Created Date');
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;   
    this.UserID=curUser.userid;
    this.PageLoad();

    this.CustomsReportFormGroup.controls.fcCompany.setValue(this.cid);
  //  this.CustomsReportFormGroup.controls.fcFromDate.setValue(this.frmdate);
  //  this.CustomsReportFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); 
      
      this.filteredOptions1 = this.myControl1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter1(value, 'myControl1'))
      );  
      
      this.filteredOptions2 = this.myControl2.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter2(value))
      ); 

     
  }
  // ngAfterViewInit() {
  //   // The gridContainer is now initialized
  //   console.log('hi',this.gridContainer);
  // }
  // exportGrid() {
  //   if (this.gridContainer && this.gridContainer.instance) {
  //     const doc = new jsPDF();
  //     exportDataGrid({
  //       jsPDFDocument: doc,
  //       component: this.gridContainer.instance,
  //       autoTableOptions: {
  //         styles: { fontSize: 8 },
  //         margin: { top: 15 },
  //         // cellWidth: 'wrap',
  //         // cellPadding: 1,
  //       }
  //     }).then(() => {
  //       doc.save('grid-data.pdf');
  //     });
  //   } else {
  //     console.error("Grid container is not available");
  //   }
  // }

  // exportGrid() {
  //   const doc = new jsPDF({
  //     orientation: 'landscape', // Switch to landscape mode if necessary
  //     unit: 'pt',               // Set unit to points (recommended for PDF layout)
  //     format: 'a4'              // Use A4 paper size (adjust if needed)
  //   });
  
  //   exportDataGrid({
  //     jsPDFDocument: doc,
  //     component: this.gridContainer.instance,
  //     autoTableOptions: {
  //       styles: {
  //         fontSize: 8,
  //         cellPadding: 2, // Adjust padding to prevent text overlap
  //       },
  //       columnStyles: {
  //         0: { cellWidth: 50 },  // Adjust these values based on your columns
  //         1: { cellWidth: 50 },
  //         2: { cellWidth: 50 },
  //         // Repeat for other columns
  //       },
  //       margin: { top: 20, right: 10, bottom: 20, left: 10 },
  //       theme: 'striped', // Optional: Choose a theme like 'striped', 'grid', or 'plain'
  //     },
  //   }).then(() => {
  //     doc.save('grid-data.pdf');
  //   });
  // }
  
  taskbyselect(e)
  {
    this.FilterBy = this.CustomsReportFormGroup.get('fcTaskBy').value;
    console.log('FilterBy',this.FilterBy)
    if(this.FilterBy==='Created Date' )
    {
       this.Createddatevisible=true;
       this.Updateddatevisible=false;
    }
    else 
    {
       this.Createddatevisible=false;
       this.Updateddatevisible=true;
    }    
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();   
   //  console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  private _filter1(value: string, control: string): string[] {    
    const filterValue = value.toLowerCase();
    // console.log('controlID',filterValue);
    this.selectedprojectid=''
    // console.log('FilterProjectID',this.employeeidname);
  //  console.log('FilterProjectID',this.projectid);
    if (control === 'myControl1') {
      //console.log('FilterProjectID',this.projectid);
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  selectedemployee(event) {             
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;   
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-')); 
  }

  selectedemployee2(event) {             
    this.selectedEmpID1 = event.option.value;
    const str: string = this.selectedEmpID1;   
    this.selectedEmpID1 = this.selectedEmpID1.slice(0, str.indexOf('-')); 
  }


  selectedEmployee1(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
    if (control === 'myControl1') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.Projectlist, { 'ProjectName': event.option.value }), 'ProjectID'));
    }
  }

  PageLoad() {
    // console.log('ledgerid',sessionStorage.getItem("groupid"));
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",
      UserID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid")
     
    };
    this.service.httpPost('/ITSCustomsReport/GetTicketData',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.Employees = result.respdata.Employee;           
            this.Projectlist=result.respdata.Project;
            this.RequestType=result.respdata.RequestType;
            this.Clients=result.respdata.Client;
            this.Issues=result.respdata.Issue;
            this.Location=result.respdata.Location;
            this.Priority=result.respdata.Priority;
            this.Status=result.respdata.Status;
            this.Branch=result.respdata.Branch;
            this.Product=result.respdata.Product;
            this.projectid = _.pluck(this.Projectlist, 'ProjectName');
            this.employeeid= _.pluck(this.Employees, 'Name');    
           // console.log('employees',this.Employees)    
           
           
           this.filteredList5= this.Clients.slice();

          // console.log('test',this.filteredList5)
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  

  Submit() {  
    if (this.CustomsReportFormGroup.invalid) {
      this.notificationService.warn('Please select date range');     
      return;
      }  
        this.showSpinner=true;
        let params = {     
        CID: this.CustomsReportFormGroup.get('fcCompany').value,
        Flag: "LOADGRID",               
        ReqType:this.CustomsReportFormGroup.get('fcRequestType').value,   //this.EffortFormGroup.get('fcEmployee').value,  
        Client:this.CustomsReportFormGroup.get('fcClient').value,
        Product:this.CustomsReportFormGroup.get('fcProduct').value, 
        Priority:this.CustomsReportFormGroup.get('fcPriority').value,
        Project:this.selectedprojectid,
        Issues:this.CustomsReportFormGroup.get('fcIssue').value,
        Status:this.CustomsReportFormGroup.get('fcStatus').value,
        Location:this.CustomsReportFormGroup.get('fcLocation').value,
        Branch:this.CustomsReportFormGroup.get('fcBranch').value,
        AssignTo:this.selectedEmpID1,
        Reportby:this.selectedEmpID,
        DateType:'cs',  
        Subject:this.CustomsReportFormGroup.get('fcSubject').value,   
        UserID:this.CurLedgerID, 
        FilterBy:this.CustomsReportFormGroup.get('fcTaskBy').value,
        TicketCategory:this.CustomsReportFormGroup.get('fcTicketCatgory').value,      
        SLA:this.CustomsReportFormGroup.get('fcInline').value,      
        FromDate:this.fromdate,
        ToDate:this.todate
        //FromDate: this.datePipe.transform(this.CustomsReportFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
       // ToDate: this.datePipe.transform(this.CustomsReportFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
         //       this.datePipe.transform(this.CustomsReportFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.CustomsReportFormGroup.get('fcToDate').value,"dd-MM-yyyy")
       
    };
  //  console.log('zfv',params)
    this.service.httpPost('/ITSCustomsReport/GetTicketData',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          this.stepper.selectedIndex = 1;   
          result = data;          
          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
            this.dataSource = result.respdata.Taskdata;
           // console.log('datasource123',this.dataSource);
            this.isColorCodeVisible=false;
            this.Dependencybtnvisible=true;
            if(this.CustomsReportFormGroup.get('fcSLA').value==true)
            {
              // if (e.rowType == 'data' && e.data.ColorCode == "1" && e.data.Status != "Closed") {
               // console.log('SLA');
             //   this.dataSource=_.pluck(_.where(this.dataSource, { 'ColorCode': '1' }));                
                // this.dataSource =(_.where(this.dataSource, { 'ColorCode':"1" }));
                this.dataSource = this.dataSource.filter(book => book.SLAColorCode == 1 && book.Status !='Closed' );

                  //console.log('dataSource',this.dataSource );
              
              // console.log('filterdatasource',this.dataSource);
              // }
            }
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onSearchClear(){
    this.CustomsReportFormGroup.reset();   
    this.myControl.setValue('');   
    this.myControl1.setValue('');
    this.myControl2.setValue('');
    this.selectedEmpID=undefined;
    this.selectedEmpID1=undefined;
    this.selectedEmpID='';
    this.selectedEmpID1='';
    this.dataSource='';
    this.selectedprojectid=null;
    this.CustomsReportFormGroup.controls.fcCompany.setValue(this.cid);
    this.stepper.selectedIndex = 0;     
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.DateTypeFlag="Today";
    this.CustomsReportFormGroup.controls.fcTaskBy.setValue('Created Date');
    this.Createddatevisible=true;
    this.Updateddatevisible=false;
    this.Dependencybtnvisible=false;

  }

  changesite(e) {
    this.cid=e.value;
    this.Employees = '';  
    this.Projectlist= '';
    this.projectid = [];
    this.employeeid= [];        
    this.PageLoad();
    this.myControl.setValue('');   
    this.myControl1.setValue('');
    this.myControl2.setValue('');
  }

  CreatedbySearchClear()
  {
    this.myControl.setValue(''); 
    this.selectedEmpID=''; 
  }
  RequestonSearchClear()
  {
    this.myControl2.setValue('');
    this.selectedEmpID1='';
  }
  ProjectSearchClear()
  {
    this.myControl1.setValue('');
    this.selectedprojectid=null;
    this.selectedprojectid='';
  }

  

  async onKey(value,event)
  {
    // console.log('fff',event)
   this.eventID=event.ID
  //  await this.PageLoad();
  //  event.options =this.Clients
 
   // console.log('fff',opt.options.slice())
   event.options=this.search(value,event) 
    
  }

  search(value: string,opt) {
    let filter = value.toLowerCase();
    console.log('filter',filter)
    console.log('option',opt)
    console.log('option',opt.filter(option =>
      option.ClientName.toLowerCase().includes(filter)
    ))
    // return opt.filter(option =>option.ClientName.toLowerCase().includes(filter)
    // );

    return opt.filter(option =>
      option.ClientName.toLowerCase().includes(filter)
    );
  }

  

  onRowPrepared(e) {
//    console.log('hi',e.data.SLAColorCode,e.data.Status);
    if (e.rowType == 'data' && e.data.SLAColorCode == "1" && e.data.Status != "Closed") {
    //  console.log('Colorchange');
      e.rowElement.style.backgroundColor = '#FDE2DC';  
      //e.rowElement.style.cssText = "color: #000000; background-color: "+ '#FDE2DC'+""; 
     // e.rowElement.style.backgroundColor = 'yellow';  
      //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");   
    }
    // if(this.CustomsReportFormGroup.get('fcSLA').value==true)
    // {
    //   // if (e.rowType == 'data' && e.data.ColorCode == "1" && e.data.Status != "Closed") {
    //     console.log('SLA');
    //     this.dataSource1=_.pluck(_.where(this.dataSource, { 'ColorCode': '1' }));
    //     this.dataSource1 = JSON.parse(_.pluck(_.where(this.dataSource, { 'ColorCode': "1"}), 'Date'));
    //     console.log('filterdatasource',this.dataSource1);
    //   // }
    // }
   
  }  
  onCellPrepared (e) {
   
    if (e.rowType === "data") {
        if (e.column.dataField === "Status") {        
           e.cellElement.style.cssText ="color: #000000; background-color: "+e.data.ColorCode+""; 
        // e.cellElement.style.backgroundColor = this.dataSource[e.rowIndex].ColorCode;          
          // e.cellElement.style.backgroundColor = e.data.ColorCode;
        }
        if (e.column.dataField ==="ParentStatus") {        
          e.cellElement.style.cssText ="color: #000000; background-color: "+e.data.ParentColorCode+""; 
       // e.cellElement.style.backgroundColor = this.dataSource[e.rowIndex].ColorCode;          
        //  e.cellElement.style.backgroundColor = e.data.ParentColorCode;
       }
    }
}

// onContentReady() {
//   // Reapply cell customization when the content is re-rendered
//   if (this.dataGrid) {
//  console.log('ahflkja')
//       this.dataGrid.instance.refresh();
//   }
// }
  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
     // console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }


  onFocusedRowChanged(e) {  

    sessionStorage.setItem('activityTicketID',e.data.TicketNo);
    sessionStorage.setItem('TaskSubject', e.data.Subject);

    sessionStorage.setItem('TemplateID', '1');
    sessionStorage.setItem('ActivityID', 'INT_VIEW');
    
    let item: any = { "name": e.data.TicketNo, "type": "FORM", "icon": "arrow_right", "menuid": "STS_134", "parentid": "54", "visible": true };
    this.eventService.emitMenuSelect(item);

    // sessionStorage.setItem('TicketID', e.data.TicketNo);
    // let item: any = { "name": "Ticket Manager", "type": "FORM", "icon": "arrow_right", "menuid": "STS_203", "parentid": "54", "visible": true };
    // this.eventService.emitMenuSelect(item);
        // const dialogConfig = new MatDialogConfig();
        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus =  true;       
        // dialogConfig.data = {                 
        //         pid:0,
        //         height: '350px',
        //         width: '70%',                            
        //         cid:this.CustomsReportFormGroup.get('fcCompany').value,
        //         flag:"View",
        //         TicketID:e.data.TicketNo,
        //         TemplateID:1,
        //         UserName:this.UserID,
        //         LoginFlag:'External',
        //         ActiviteID:"INT_VIEW",
        //         TemplateGroupID:1,   
        //         Subject:e.data.Subject
        //     }                   
        //     const dialogRef =  this.dialog.open(TaskbyviewComponent, dialogConfig)
        //     dialogRef.afterClosed().subscribe(result => {     
        //       this.Submit();     
        //     });
       
  }

  
  Dependency(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;       
    dialogConfig.data = {                 
            CID: this.CustomsReportFormGroup.get('fcCompany').value,
            Flag: "LOADDEPENDENCY",               
            ReqType:this.CustomsReportFormGroup.get('fcRequestType').value,  
            Client:this.CustomsReportFormGroup.get('fcClient').value,
            Product:this.CustomsReportFormGroup.get('fcProduct').value, 
            Priority:this.CustomsReportFormGroup.get('fcPriority').value,
            Project:this.selectedprojectid,
            Issues:this.CustomsReportFormGroup.get('fcIssue').value,
            Status:this.CustomsReportFormGroup.get('fcStatus').value,
            Location:this.CustomsReportFormGroup.get('fcLocation').value,
            Branch:this.CustomsReportFormGroup.get('fcBranch').value,
            AssignTo:this.selectedEmpID1,
            Reportby:this.selectedEmpID,
            DateType:'cs',  
            Subject:this.CustomsReportFormGroup.get('fcSubject').value,   
            UserID:this.CurLedgerID, 
            FilterBy:this.CustomsReportFormGroup.get('fcTaskBy').value,
            TicketCategory:this.CustomsReportFormGroup.get('fcTicketCatgory').value,      
            SLA:this.CustomsReportFormGroup.get('fcInline').value,      
            FromDate:this.fromdate,
            ToDate:this.todate
        }                   
        const dialogRef =  this.dialog.open(DependencyrptComponent, dialogConfig)
        // dialogRef.afterClosed().subscribe(result => {     
        //   this.Submit();     
        // });
  }
  
}
