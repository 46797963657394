// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
// import { MatBadgeModule } from '@angular/material/badge';
// import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
// import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
// import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
// import { MatStepperModule } from '@angular/material/stepper';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
// import { MatGridListModule } from '@angular/material/grid-list';
// import { MatIconModule } from '@angular/material/icon';
// import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
// import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
// import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
// import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
// import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
// import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
// import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
// import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
// import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
// import { SlideToggleModule } from '@angular/material/slide-toggle';
// import { MatSnackBar} from '@angular/material/snack-bar';
// import { MatSortModule } from '@angular/material/sort';
// import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
// import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
// import { MatTreeModule } from '@angular/material/tree';
// import { CdkTableModule } from '@angular/cdk/table';
// import { A11yModule } from '@angular/cdk/a11y';
// import { BidiModule } from '@angular/cdk/bidi';
// import { CdkAccordionModule } from '@angular/cdk/accordion';
// import { ObserversModule } from '@angular/cdk/observers';
// import { OverlayModule } from '@angular/cdk/overlay';
// import { PlatformModule } from '@angular/cdk/platform';
// import { PortalModule } from '@angular/cdk/portal';
// import { DatePipe } from '@angular/common';


// import { DxFormModule, DxDataGridModule, DxDropDownBoxModule, DxSelectBoxModule, DxDateBoxModule, DxButtonModule, 
//   DxSpeedDialActionModule, DxListModule,DxPopupModule,DxTreeListModule, DxTreeViewModule,
//     DxTemplateModule,  DxCheckBoxModule, DxToolbarModule} from 'devextreme-angular';

// import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
// import { CKEditorModule } from 'ng2-ckeditor';
// import { MatSelectFilterModule } from 'mat-select-filter';



import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';

import { DxFormModule, DxDataGridModule, DxDropDownBoxModule, DxSelectBoxModule, DxDateBoxModule, DxButtonModule, 
  DxSpeedDialActionModule, DxListModule,DxPopupModule,DxTreeListModule, DxTreeViewModule,
    DxTemplateModule,  DxCheckBoxModule, DxToolbarModule,DxChartModule, 
    DxContextMenuModule,
    DxSortableModule,
    DxScrollViewModule} from 'devextreme-angular';

import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { CKEditorModule } from 'ng2-ckeditor';
import { DxPieChartModule } from 'devextreme-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NgxMatSelectSearchModule } from 'src/app/components/Control/mat-select-search/ngx-mat-select-search.module';
//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


//import { NgxMultipleSelectModule } from 'ngx-multiple-select';
import { ColorSketchModule } from 'ngx-color/sketch';




// import { CKEditorModule } from 'ckeditor4-angular';
//import { AngularEditorModule } from '@kolkov/angular-editor';

// import { 
//   DxButtonGroupModule,
//   DxHtmlEditorModule
// } from 'devextreme-angular';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatNativeDateModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxButtonModule,
    DxSpeedDialActionModule,
    DxFormModule,
    DxListModule,
    DxPopupModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxListModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    NgxMatColorPickerModule,
    MatBadgeModule,
    CKEditorModule,
   
    //DxHtmlEditorModule,
  //  DxButtonGroupModule,
    DxToolbarModule,
    MatSelectFilterModule,
    DragDropModule,
    MatSelectFilterModule,
    NgxMatSelectSearchModule,
    //NgxMultipleSelectModule,
    DxContextMenuModule,
    ColorSketchModule,
    DxSortableModule,
    DxScrollViewModule
    
    
    //AngularEditorModule
    

  ],
  providers: [DatePipe,{provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS} ],
  exports: [
    FormsModule, ReactiveFormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,   
    MatTreeModule,
    MatIconModule,
    MatNativeDateModule,
    MatBadgeModule,
    CdkTableModule,
    A11yModule,
    BidiModule,
    CdkAccordionModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxButtonModule,
    DxSpeedDialActionModule,
    DxFormModule,
    DxListModule,
    DxPopupModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxListModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    NgxMatColorPickerModule,
    CKEditorModule,
    //DxHtmlEditorModule,
    //DxButtonGroupModule,
    DxToolbarModule,
    MatSelectFilterModule,
    DragDropModule,
    //NgxMultipleSelectModule,
    DxContextMenuModule,
    ColorSketchModule,
   
    
   // AngularEditorModule
    
  ],
})
export class ControlsModule { }
