import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { PayslippaymentfilterComponent } from '../../EmpPayslipPayment/payslippaymentfilter/payslippaymentfilter.component';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridModule, DxCheckBoxModule, DxSelectBoxModule } from 'devextreme-angular';
@Component({
  selector: 'app-payslipreport',
  templateUrl: './payslipreport.component.html',
  styleUrls: ['./payslipreport.component.scss'],
})
export class PayslipreportComponent implements OnInit {


  showSpinner:any;
  cid:any=101;
  flag:any="ADD";
  LedgerID:any=0;
  fromdate:any;
  todate:any;
  dataSource:any;
  TotEaring:any=0;
  TotDeduct:any=0;
  TotAmount:any=0;
  TotAbsAmt:any=0;
  EmpID:any=0;
  EmpName:any='';
  Category:string='--ALL--'
  PSType:string='PST/1001'
  DateTypeFlag:any;
  displayMode:any = "compact";
 allowedPageSizes = [5, 10, 'all'];
  showPageSizeSelector = true;

  readonly displayModes = [{ text: "Display Mode 'full'", value: 'full' }, { text: "Display Mode 'compact'", value: 'compact' }];

  constructor(private service:HttpserviceService,private datePipe: DatePipe,private dialog: MatDialog,private NotificationService: NotificationService) 
  { 
    this.fromdate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
    this.todate=this.datePipe.transform(new Date(),'yyyy-MM-dd') ;
  }


  ngOnInit()
   {
     //this.LoadData()
   }
   get isCompactMode() {
    return this.displayMode === 'compact';
  }

   LoadData()
   {
 
     this.showSpinner=true;
     const params = {
      str_SiteID: this.cid,
      str_EmpName:this.EmpID,
      str_VouNo:this.PSType,
      str_Categotry:this.Category,
      dtp_FromDate:this.datePipe.transform(this.fromdate,"MM-dd-yyyy")  ,
      dtp_ToDate:this.datePipe.transform( this.todate,"MM-dd-yyyy"),
      dtp_Date:this.DateTypeFlag,
      str_PaymentStaus:'--ALL--'
      
     };   
 
     
    // const query = `cid=${params.cid}&MenuID=${params.MenuID}&Date1=${params.Date1}&FromDate=${params.FromDate}&ToDate=${params.ToDate}`;
 
 
     this.service.httpPost('/Payslip/GetPayslipLedger?' ,params)   
     .subscribe(data =>{
       var result = data as any;
       result = data;      
 
       console.log("mm",result.respdata)

       let LastRow = result.respdata.Table.filter(item => item.PSNo=='10000001');

       this.TotEaring=LastRow[0].TOTEAR;
       this.TotDeduct=LastRow[0].TOTDEDUT;
       this.TotAmount=LastRow[0].NSAL;
       this.TotAbsAmt=LastRow[0].AbsAmt;


       console.log("total",LastRow)

       this.showSpinner=true;
       setTimeout(() =>{
         this.showSpinner=false;
       },
       800)  
      
         this.dataSource = result.respdata.Table; 
        
 
        
        
     });
   }

   Filter()
   {
    const dialogRef = this.dialog.open(PayslippaymentfilterComponent, {
      width: '30%', // Set the width of the dialog
      height:'85%',
      position: { right: '0' }, // Set the position to right side
      data:{
        cid:this.cid,
        LedgerID:this.EmpID,
        EmpName:this.EmpName,
        Category:this.Category,     
        Type:this.PSType,
        DateTypeFlag:this.DateTypeFlag,
        fromdate:this.fromdate,
        todate:this.todate,
        typevisible:true,
        categoryvisible:true,
        employeevisible:true

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (result !== undefined)
      {
        // this.qtnstatus=result.Status
        this.cid=result.CID
        this.EmpID=result.LedgerID
        this.EmpName=result.Name
        this.Category=result.Category
        this.PSType=result.Type
        this.DateTypeFlag=result.DateTypeFlag
        this.fromdate=result.FromDate
      // const  dateObj= new Date(result.ToDate); 
        this.todate = result.ToDate //this.datePipe.transform(dateObj, 'yyyy-MM-dd');
        this.LoadData()
      }
     
    });
   }

}
