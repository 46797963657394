import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';
import { User } from '../../../../Modules/user';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { TicketComponent } from '../../../ITS/Ticket/ticket/ticket.component';
import { CrmdatecontrolComponent } from '../../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';


@Component({
  selector: 'app-helpdesktaskby',
  templateUrl: './helpdesktaskby.component.html',
  styleUrls: ['./helpdesktaskby.component.scss'],
})
export class HelpdesktaskbyComponent implements OnInit {

  @ViewChild('stepper') stepper;
  dataSource: any;
  dataSource1: any;
  CustomsReportFormGroup: FormGroup;
  CustomDataFormGroup:FormGroup;
  Companies: any;
  Employees:any;  
  employeeid:string[]=[];
  selectedEmpID: any;
  selectedEmpID1: any;
  frmdate= new Date();
  cid:any; 
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  myControl2 = new FormControl();
  filteredOptions2: Observable<string[]>;
  showSpinner:any;
  myControl1 = new FormControl();
  filteredOptions1: Observable<string[]>;
  selectedProjectID: number;
  projectid: string[] = [];
  selectedprojectid: any;
  Projectlist:any;
  isEditable = true; 
  RequestType:any;
  Clients:any;
  Issues:any;
  Location:any;
  Priority:any;
  Status:any;
  Branch:any;
  Product:any;
  CurLedgerID:number;
  isColorCodeVisible: boolean;
  eventID:any;
  public filteredList5
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"
  TaskTemplate:any;
  Activityflag:any;
  TicketID:any='0';
  ActiviteID:any
  TemplateGroupID:any;
  TemplateID:any=0;
  UserID:any;
  FilterBy:any;
  Createddatevisible:boolean=true;
  Updateddatevisible:boolean=false;
  CIDisvisible:boolean=false;
  Subisvisible:boolean=false;
  Createbyisvisible:boolean=false;
  Requestonisvisible:boolean=false;
  Projectisvisible:boolean=false;
  RequestTypeisvisible:boolean=false;
  Typeofissueisvisible:boolean=false;
  Locationisvisible:boolean=false;
  Branchisvisible:boolean=false;
  Statusisvisible:boolean=false;
  Priorityisvisible:boolean=false;
  Productisvisible:boolean=false;
  TicketCategoryisvisible:boolean=false;
  FilterByisvisible:boolean=false;
  Visiblelist:any;
  TaskBy = [
    { name: 'Created Date' },
    { name: 'Updated Date' }    
  ];
  TicketCategory = [
    { name: 'Internal' },
    { name: 'External' }    
  ];

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog: MatDialog,
    public notificationService: NotificationService, private datePipe: DatePipe) { 
      this.CustomsReportFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] , 
        myControl: [''],
        myControl1: [''],
        myControl2: [''],
        fcRequestType:[''],
        //fcClient:[''],
        fcIssue:[''],
        fcLocation:[''],
        fcPriority:[''],
        fcStatus:[''],
        fcBranch:[''],
        fcProduct:[''],
        fcSubject:[''],
        fcTaskBy:[''],
        fcTicketCatgory:['']
     
      });
      this.CustomDataFormGroup = this.formBuilder.group({                 
      });
    }

  ngOnInit(): void  {

    this.CustomsReportFormGroup.controls.fcTaskBy.setValue('Created Date');
    this.cid=JSON.parse(sessionStorage.getItem('cid'));  
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;   
    this.UserID=curUser.userid;
    console.log('ledgerid',curUser.UserType)
    this.PageLoad();
    this.CustomsReportFormGroup.controls.fcCompany.setValue(this.cid);
  //  this.CustomsReportFormGroup.controls.fcFromDate.setValue(this.frmdate);
  //  this.CustomsReportFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); 
      
      this.filteredOptions1 = this.myControl1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter1(value, 'myControl1'))
      );  
      
      this.filteredOptions2 = this.myControl2.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter2(value))
      ); 


     
  }

  
  taskbyselect(e)
  {
    this.FilterBy = this.CustomsReportFormGroup.get('fcTaskBy').value;
    console.log('FilterBy',this.FilterBy)
    if(this.FilterBy==='Created Date' )
    {
       this.Createddatevisible=true;
       this.Updateddatevisible=false;
    }
    else 
    {
       this.Createddatevisible=false;
       this.Updateddatevisible=true;
    }    
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  private _filter2(value: string): string[] {
    const filterValue = value.toLowerCase();   
   //  console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  private _filter1(value: string, control: string): string[] {    
    const filterValue = value.toLowerCase();
    // console.log('controlID',filterValue);
    this.selectedprojectid=''
    // console.log('FilterProjectID',this.employeeidname);
  //  console.log('FilterProjectID',this.projectid);
    if (control === 'myControl1') {
      //console.log('FilterProjectID',this.projectid);
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  selectedemployee(event) {             
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;   
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-')); 
  }

  selectedemployee2(event) {             
    this.selectedEmpID1 = event.option.value;
    const str: string = this.selectedEmpID1;   
    this.selectedEmpID1 = this.selectedEmpID1.slice(0, str.indexOf('-')); 
  }


  selectedEmployee1(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
    if (control === 'myControl1') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.Projectlist, { 'ProjectName': event.option.value }), 'ProjectID'));
    }
  }

  PageLoad() {
    // console.log('ledgerid',sessionStorage.getItem("groupid"));
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",
      UserID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid")
     
    };
    this.service.httpPost('/HelpdeskCustomsReport/GetHelpdeskTicketData',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = result.respdata.Companyid;                          
            this.Employees = result.respdata.Employee;           
            this.Projectlist=result.respdata.Project;
            this.RequestType=result.respdata.RequestType;
            this.Clients=result.respdata.Client;
            this.Issues=result.respdata.Issue;
            this.Location=result.respdata.Location;
            this.Priority=result.respdata.Priority;
            this.Status=result.respdata.Status;
            this.Branch=result.respdata.Branch;
            this.Product=result.respdata.Product;

            this.Visiblelist = result.respdata.FormFields; 
            this.Branchisvisible=this.Visiblelist[0].Tagvalue;
            this.Createbyisvisible=this.Visiblelist[1].Tagvalue;
            this.FilterByisvisible=this.Visiblelist[2].Tagvalue;
            this.Locationisvisible=this.Visiblelist[3].Tagvalue;
            this.Priorityisvisible=this.Visiblelist[4].Tagvalue;
            this.Productisvisible=this.Visiblelist[5].Tagvalue;
            this.Projectisvisible=this.Visiblelist[6].Tagvalue;
            this.Requestonisvisible=this.Visiblelist[7].Tagvalue;
            this.RequestTypeisvisible=this.Visiblelist[8].Tagvalue;
            this.CIDisvisible=this.Visiblelist[9].Tagvalue;
            this.Statusisvisible=this.Visiblelist[10].Tagvalue;
            this.Subisvisible=this.Visiblelist[11].Tagvalue;
            this.TicketCategoryisvisible=this.Visiblelist[12].Tagvalue;
            this.Typeofissueisvisible=this.Visiblelist[13].Tagvalue;

            this.projectid = _.pluck(this.Projectlist, 'ProjectName');
            this.employeeid= _.pluck(this.Employees, 'Name');    
           // console.log('employees',this.Employees)                          
           this.filteredList5= this.Clients.slice();

          // console.log('test',this.filteredList5)
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  

  Submit() {  
    if (this.CustomsReportFormGroup.invalid) {
      this.notificationService.warn('Please select date range');     
      return;
      }  
        this.showSpinner=true;
        let params = {     
        CID: this.CustomsReportFormGroup.get('fcCompany').value,
        Flag: "LOADGRID",               
        ReqType:this.CustomsReportFormGroup.get('fcRequestType').value,   //this.EffortFormGroup.get('fcEmployee').value,  
        Client:this.CurLedgerID,
        Product:this.CustomsReportFormGroup.get('fcProduct').value, 
        Priority:this.CustomsReportFormGroup.get('fcPriority').value,
        Project:this.selectedprojectid,
        Issues:this.CustomsReportFormGroup.get('fcIssue').value,
        Status:this.CustomsReportFormGroup.get('fcStatus').value,
        Location:this.CustomsReportFormGroup.get('fcLocation').value,
        Branch:this.CustomsReportFormGroup.get('fcBranch').value,
        AssignTo:this.selectedEmpID1,
        Reportby:this.selectedEmpID,
        DateType:'cs',  
        Subject:this.CustomsReportFormGroup.get('fcSubject').value,   
        UserID:this.CurLedgerID, 
        FilterBy:this.CustomsReportFormGroup.get('fcTaskBy').value,
        TicketCategory:this.CustomsReportFormGroup.get('fcTicketCatgory').value,      
        FromDate:this.fromdate,
        ToDate:this.todate
        //FromDate: this.datePipe.transform(this.CustomsReportFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
       // ToDate: this.datePipe.transform(this.CustomsReportFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
         //       this.datePipe.transform(this.CustomsReportFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.CustomsReportFormGroup.get('fcToDate').value,"dd-MM-yyyy")
       
    };
    console.log('zfv',params)
    this.service.httpPost('/HelpdeskCustomsReport/GetHelpdeskTicketData',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          this.stepper.selectedIndex = 1;   
          result = data;          
          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
            this.dataSource = result.respdata.Taskdata;
            this.isColorCodeVisible=false;
            if(this.CustomsReportFormGroup.get('fcSLA').value==true)
            {
              // if (e.rowType == 'data' && e.data.ColorCode == "1" && e.data.Status != "Closed") {
                console.log('SLA');
             //   this.dataSource=_.pluck(_.where(this.dataSource, { 'ColorCode': '1' }));                
                this.dataSource = JSON.parse(_.pluck(_.where(this.dataSource, { 'ColorCode':"1" }), 'Date'));
                console.log('filterdatasource',this.dataSource);
              // }
            }
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onSearchClear(){
    this.CustomsReportFormGroup.reset();   
    this.myControl.setValue('');   
    this.myControl1.setValue('');
    this.myControl2.setValue('');
    this.selectedEmpID=undefined;
    this.selectedEmpID1=undefined;
    this.dataSource='';
    this.selectedprojectid=null;
    this.CustomsReportFormGroup.controls.fcCompany.setValue(this.cid);
    this.stepper.selectedIndex = 0;   
  //  this.CustomsReportFormGroup.controls.fcFromDate.setValue(this.frmdate);
  //  this.CustomsReportFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.DateTypeFlag="Today";
    this.CustomsReportFormGroup.controls.fcTaskBy.setValue('Created Date');
    this.Createddatevisible=true;
    this.Updateddatevisible=false;
  }

  async onKey(value,event)
  {
    // console.log('fff',event)
   this.eventID=event.ID
  //  await this.PageLoad();
  //  event.options =this.Clients
 
   // console.log('fff',opt.options.slice())
   event.options=this.search(value,event) 
    
  }

  search(value: string,opt) {
    let filter = value.toLowerCase();
    console.log('filter',filter)
    console.log('option',opt)
    console.log('option',opt.filter(option =>
      option.ClientName.toLowerCase().includes(filter)
    ))
    // return opt.filter(option =>option.ClientName.toLowerCase().includes(filter)
    // );

    return opt.filter(option =>
      option.ClientName.toLowerCase().includes(filter)
    );
  }

  changesite(e) {
    this.cid=e.value;
    this.PageLoad();
  }

  onRowPrepared(e) {
   // console.log('hi',e.data.ColorCode,e.data.Status);
    if (e.rowType == 'data' && e.data.ColorCode == "1" && e.data.Status != "Closed") {
    //  console.log('Colorchange');
      e.rowElement.style.backgroundColor = '#FCD1C7';     
    }
    // if(this.CustomsReportFormGroup.get('fcSLA').value==true)
    // {
    //   // if (e.rowType == 'data' && e.data.ColorCode == "1" && e.data.Status != "Closed") {
    //     console.log('SLA');
    //     this.dataSource1=_.pluck(_.where(this.dataSource, { 'ColorCode': '1' }));
    //     this.dataSource1 = JSON.parse(_.pluck(_.where(this.dataSource, { 'ColorCode': "1"}), 'Date'));
    //     console.log('filterdatasource',this.dataSource1);
    //   // }
    // }
   
  }  

  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }

}
