import { Injectable } from '@angular/core';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { FormGroup, FormControl, Validators } from '@angular/Forms';



@Injectable({
  providedIn: 'root'
})
export class LeaveApprovalService {

  constructor(private http: HttpserviceService) {

   }
  getemployeelist(params: any){
    const query = `cid=${params.cid}&tablename=${params.tablename}&type=${params.formtype}&condition=${params.condition}&menuid=${params.menuid}&withinactive=${params.withinactive}`;
    return this.http.httpGet('/leaveapproval/loaddetails?' , query);
  }
  setleaveapproval(data: any)
  {
    return this.http.httpPost('/leaveapproval/setleaveapproval?', data);
  }
  getleaveapproval(data: any)
  {
    return this.http.httpPost('/leaveapproval/getleaveapproval', data);
  }
  GetLeaveDetails(data: any)
  {
    return this.http.httpPost('/leaveapproval/GetLeaveDetails', data);
  }
  SetLeaveDetails(data: any)
  {
    return this.http.httpPost('/leaveapproval/SetLeaveDetails?', data);
  }
    form: FormGroup = new FormGroup({
    fromdate: new FormControl('', [Validators.required]),
    todate: new FormControl('', Validators.required),
    returndate: new FormControl(''),
    leavetype: new FormControl('', Validators.required),
    daystatus: new FormControl('', Validators.required),
    session: new FormControl(''),
    comment: new FormControl(''),
    LeaveID: new FormControl('')

  });

  initializeFormGroup(){
    this.form.setValue({
      fromdate: '',
    todate: '',
    returndate: '',
    leavetype: '',
    daystatus: '',
    session: '',
    comment: '',
    LeaveID: ''
    });
  }

  populateForm(data){
        let userdata;

        userdata = data;
        const user = {

          fromdate: data.FromDate,
          todate: data.ToDate,
          returndate: data.RtnDate,
          leavetype: data.CategoryName,
          daystatus: data.DayStatus,
          session: data.SessionType,
          comment: data.Comments,
          LeaveID: data.LeaveID
        };
        this.form.setValue(user);

      }
}
export class Employee {
  LedgerID: number;
  CategoryName: string;
  BioMetric: string;
  StartTime: string;
  EndTime: string;
  Effort: string;
  OT: string;
  Comment: string;
  LeaveType: number;
}

