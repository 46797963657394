import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { User } from 'src/app/Modules/user';
import { GenericService } from '../../../../Services/OtherServices/generic.service';
import { UserService } from '../../../Admin/Users/userValidate';
import { BadInput } from '../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from '../../../../Services/httpServices/ErrorHandler/app-error';
import { CrmdatecontrolComponent } from '../../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';

@Component({
  selector: 'app-effortauthorize',
  templateUrl: './effortauthorize.component.html',
  styleUrls: ['./effortauthorize.component.scss'],
})
export class EffortauthorizeComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  dataSource: EffortApprovalDataGrid[];
  EffortApprovalDataGrids: any;
  SelectedCompanyID: number;
  projectdata: any;
  companies: any;
  Employees: any;
  projectlist: any;
  viewbtnvisible: boolean = false;
  selectedCID: number;
  selectedprojectid: any;
  selectedemployees: number;
  projectid: string[] = [];
  employeeidname: string[] = [];
  fromdate: any;
  todate: any;
  Status:any;
  DateTypeFlag: any = "Today"
  ApprovalFormGroup: FormGroup;
  EffortDataFormGroup: FormGroup;
  isEditable = true;
  myControl1 = new FormControl();
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  cid: any;
  selectedProjectID: number;
  selectedEmpID: string = 'All';

  date: Date; //= new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  now: Date = new Date();
  // mySimpleFormat = this.datePipe.transform(this.now, 'dd-MM-yyyy');
  LedgerID: number;
  // allMode: string;
  // checkBoxesMode: string;
  startEditAction: string = "click";
  selectTextOnEditStart: boolean = true;
  selectedRowsData = [];
  selectedEffortSlno: any;
  onSelectionChange: boolean;

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder, private dialog: MatDialog, private generic: GenericService,
    private service1: UserService, public notificationService: NotificationService, private datePipe: DatePipe) {

    this.ApprovalFormGroup = this.formBuilder.group({
      fcCompany: ['', Validators.required],
      fcStatus: [''],      
      // fcFromDate: ['', Validators.required],
      // fcToDate: ['', Validators.required]
    });
    this.EffortDataFormGroup = this.formBuilder.group({
    });
  }

  ngOnInit(): void {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID = curUser.ledgerid;
    this.cid = JSON.parse(sessionStorage.getItem('cid'));
    this.PageLoad();
    this.ApprovalFormGroup.controls.fcCompany.setValue(this.cid);
    this.ApprovalFormGroup.controls.fcStatus.setValue(2);
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'myControl'))
      );

    this.filteredOptions2 = this.myControl1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'myControl1'))
      );

    this.fromdate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.todate = this.datePipe.transform(new Date(), "dd-MM-yyyy");

  }
  changesite(e) {
    this.cid = e.value;
    this.Employees = '';
    this.employeeidname = [];
    this.projectdata = ''    
    this.projectid =[]
    this.PageLoad();
    this.myControl1.setValue('');
    this.myControl.setValue('');
  }

  onSearchClear() {
    this.ApprovalFormGroup.reset();
    this.EffortApprovalDataGrids = '';
    this.myControl1.setValue('');
    this.myControl.setValue('');
    this.selectedEmpID = 'All';
    this.ApprovalFormGroup.controls.fcCompany.setValue(this.cid);
    this.fromdate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.todate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
    this.ApprovalFormGroup.controls.fcStatus.setValue(2);
    this.DateTypeFlag = "Today";
  }

  private _filter(value: string, control: string): string[] {

    const filterValue = value.toLowerCase();
    //console.log('controlID',control);
    // console.log('FilterProjectID',this.employeeidname);
    // console.log('FilterProjectID',this.projectid);
    if (control === 'myControl') {
      //console.log('FilterProjectID',this.projectid);
      return this.projectid.filter(option => option.toLowerCase().includes(filterValue));
    }
    else if (control === 'myControl1') {
      //console.log('FilterProjectID',this.employeeidname);
      return this.employeeidname.filter(option => option.toLowerCase().includes(filterValue));
    }

  }


  selectedEmployee(event, control: string) {
    //console.log(event.option.value);    
    let str: string = event.option.value;
    // console.log('control', control);
    if (control === 'myControl') {
      //console.log('projectdata', this.projectdata);
      this.selectedprojectid = JSON.parse(_.pluck(_.where(this.projectdata, { 'ProjectID': event.option.value }), 'ProjectUID'));
      //console.log('selectedprojectid', this.selectedprojectid);
    }
    else {
      this.selectedEmpID = event.option.value;
      // console.log('selectedEmpID Split', this.selectedEmpID.slice(0, str.indexOf('-')));
      this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
      //console.log('selectedEmpID', this.selectedEmpID);
    }

  }

  PageLoad() {
    let params = {
      CID: this.cid, //this.ProjectFormGroup.get('fcCompany').value,//101,
      Flag: "LOADDDL",
      EmployeeID: "0",
      DateType: "0",
      FromDate: "0",
      ToDate: "0",
      UserID: this.LedgerID,
      ProjectID: "0"

    };
    //this.service.getddllist(params)
    // console.log('sfasf',params);
    this.service.httpPost('/EffortApproval/Getdropdownvalues', params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {
            this.companies = result.respdata.Companyid;
            //this.SelectedCompanyID = 101;                  
            this.Employees = result.respdata.Employee;
            this.projectdata = result.respdata.Project;
            this.Status=result.respdata.Status;
            this.projectid = _.pluck(this.projectdata, 'ProjectID');
            this.employeeidname = _.pluck(this.Employees, 'Name');
            this.onSelectionChange = false;

          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }
  Submit() {

    if (this.ApprovalFormGroup.invalid) {
      return;
    }

    let params = {
      CID: this.ApprovalFormGroup.get('fcCompany').value,
      Flag: "LOADGRID",
      ProjectID: this.selectedprojectid,
      EmployeeID: this.selectedEmpID,
      DateType: "ByDate",
      Status:this.ApprovalFormGroup.get('fcStatus').value,
      FromDate: this.fromdate,
      ToDate: this.todate,
      //   FromDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcFromDate').value, "dd-MM-yyyy"),      
      //  ToDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcToDate').value, "dd-MM-yyyy"),
      AuthorizerID: this.LedgerID
    };
    //  console.log('params :', params);
    //this.service.geteffortlist(params)
    this.service.httpPost('/EffortApproval/Getdropdownvalues', params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;
          //result=JSON.parse(data);        
          //result=new Map(JSON.parse(data))

          //result=orderedJson.parse(data);

          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
            this.EffortApprovalDataGrids = result.respdata.Datasource;
            this.dataSource = result.respdata.Datasource;
            // console.log('_.where(this.EffortApprovalDataGrids',_.where(this.EffortApprovalDataGrids, { 'ChkStatus': '1' }));
            // console.log('_.pluck',_.pluck(_.where(this.EffortApprovalDataGrids, { 'ChkStatus': '1' }), 'SlNo'));
            this.selectedEffortSlno = _.pluck(_.where(this.EffortApprovalDataGrids, { 'Approved': 1 }), 'SlNo');
            // console.log('selectedEffortSlno: ', this.selectedEffortSlno);
            //console.log('effort list: ', result.respdata);
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }

  DatePicker() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      DateTypeFlag: this.DateTypeFlag,
      height: '43%',
      width: '20%'
    }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('RESULT', result)
      this.fromdate = result.frmdate;
      this.todate = result.todate;
      this.DateTypeFlag = result.datetype
    });
  }

  Save() {

    if (this.ApprovalFormGroup.invalid) {
      // console.log('invalid project form group')
      return;
    }
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    // console.log('curuser',curUser);
    // let removeApproved = this.EffortApprovalDataGrids.map(({ Approved, ...item }) => item);
    let removeIsHalfDay = this.EffortApprovalDataGrids.map(({ IsHalfDay, ...item }) => item);
    let removeName = removeIsHalfDay.map(({ Name, ...item }) => item);
    let removeClient = removeName.map(({ Client, ...item }) => item);
    let removeTicket = removeClient.map(({ Ticket, ...item }) => item);
    let removeEffortDate = removeTicket.map(({ EffortDate, ...item }) => item);
    let removeStatus = removeEffortDate.map(({ Status, ...item }) => item);

    let removeStartTime = removeStatus.map(({ StartTime, ...item }) => item);
    let removeEndTime = removeStartTime.map(({ EndTime, ...item }) => item);
    let removeCategoryName = removeEndTime.map(({ CategoryName, ...item }) => item);
    let FinalData = removeCategoryName.map(({ Project, ...item }) => item);
    //this.EffortApprovalDataGrids=FinalData;
    let params = {
      CID: this.ApprovalFormGroup.get('fcCompany').value,
      Flag: "APPROVED",
      ProjectID: this.selectedprojectid,
      EmployeeID: this.selectedEmpID,
      DateType: "ByDate",
      Comment: "",
      //  FromDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcFromDate').value, "dd-MM-yyyy"),      
      // ToDate: this.datePipe.transform(this.ApprovalFormGroup.get('fcToDate').value, "dd-MM-yyyy"),
      UserID: this.LedgerID,
      AuthorizerID: this.LedgerID,
      dt: FinalData
    };
    // console.log('EffortApprovalDataGrids', FinalData);
    //this.service.updateProjectEffortApproval(params)
    this.service.httpPost('/EffortApproval/ApproveEfforts', params)
      .subscribe(
        (data) => {
          // console.log('inside save');
          var users: any;
          users = data;
          //console.log('errorno',users.errno);
          if (users.errno === 0) {
            // console.log('HI')
            this.notificationService.success(users.errdesc);
          }
          else {
            // console.log('Bye')
            this.notificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        error => {
          console.log('Error : ', error);
        });

  }

  validateTime(e) {
    // console.log('inside validate time: ', e.value.length);
    return e.value.length === 5 ? true : false;
  }

  //Calculate Effort based on Starttime changed
  calculateEffortStartTime(newData, value, currentRowData) {
    let startTime = new Date(value);
    let endTime = new Date(currentRowData.EndTime);

    let stringDate = value.getFullYear() +
      "-" +
      (1 + value.getMonth()).toString().padStart(2, "0") +
      "-" +
      value.getDate().toString().padStart(2, "0") +
      "T" +
      value.getHours().toString().padStart(2, "0") +
      ":" +
      value.getMinutes().toString().padStart(2, "0") +
      ":" +
      value.getSeconds().toString().padStart(2, "0");

    // console.log('stringDate', stringDate);
    newData.StartTime = stringDate;

    newData.EffortHours = currentRowData.EffortHours;

    if (startTime.getTime() < endTime.getTime()) {

      let difference = new Date(
        endTime.getTime() -
        startTime.getTime() +
        startTime.getTimezoneOffset() * 60000
      );

      newData.EffortHours =
        difference.getHours().toString().padStart(2, "0") +
        ":" +
        difference.getMinutes().toString().padStart(2, "0");
    }


  }

  //Calculate Effort based on Endtime changed
  calculateEffortEndTime(newData, value, currentRowData) {
    var startTime = new Date(currentRowData.StartTime);
    var endTime = new Date(value);

    var stringDate = value.getFullYear() +
      "-" +
      (1 + value.getMonth()).toString().padStart(2, "0") +
      "-" +
      value.getDate().toString().padStart(2, "0") +
      "T" +
      value.getHours().toString().padStart(2, "0") +
      ":" +
      value.getMinutes().toString().padStart(2, "0") +
      ":" +
      value.getSeconds().toString().padStart(2, "0");

    newData.EndTime = stringDate;

    var difference = new Date(
      endTime.getTime() -
      startTime.getTime() +
      startTime.getTimezoneOffset() * 60000
    );

    newData.EffortHours =
      difference.getHours().toString().padStart(2, "0") +
      ":" +
      difference.getMinutes().toString().padStart(2, "0");
  }



  onSelectionChanged(e) {
    const currentSelectedRowKeys = e.currentSelectedRowKeys;
    const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
    const allSelectedRowKeys = e.allSelectedRowKeys;
    const allSelectedRowsData = e.allSelectedRowsData;

    if (currentSelectedRowKeys.length > 0) {
      //console.log('select')
      var i = 0;
      for (let key of Object.keys(this.EffortApprovalDataGrids)) {
        let keydata = this.EffortApprovalDataGrids[key];
        //if (JSON.parse(currentSelectedRowKeys)=== keydata['SlNo']) 
        //console.log('keydata',keydata['SlNo']);
        if (currentSelectedRowKeys[i] === keydata['SlNo']) {
          //keydata['AuthStatus'] = "A";
          keydata['Approved'] = 1;
          //keydata['Approved'] =101;
          i = i + 1;
        }
      }
    }
    else if (currentDeselectedRowKeys.length > 0) {
      //console.log('deselect');
      var j = 0;
      for (let key of Object.keys(this.EffortApprovalDataGrids)) {
        let keydata = this.EffortApprovalDataGrids[key];
        if (currentDeselectedRowKeys[j] === keydata['SlNo']) {
          //keydata['AuthStatus'] = "P";
          keydata['Approved'] = 0;
          //keydata['Approved'] =101;
          j = j + 1;
        }
      }
    }



    //console.log('EffortApprovalDataGrids', this.EffortApprovalDataGrids);
  }


  onRowPrepared(e) {

    if (e.rowType == 'data' && e.data.Approved == "0") {
      e.rowElement.style.backgroundColor = '#FDE2DC';
    }
    else if (e.rowType == 'data' && e.data.Approved != "0" && e.data.Approved != "1") {
      e.rowElement.style.backgroundColor = '#F7FC7F';
      //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");  
    }
    else if (e.rowType == 'data' && e.data.Approved == "1") {
      e.rowElement.style.backgroundColor = '#D8D8D3';
      //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");  
    }
  }

}




export class EffortApprovalDataGrid {
  LedgerID: number
  UserID: number
  EmployeeID: number
  ProjectID: string
  FromDate: string
  ToDate: string

}
