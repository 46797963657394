import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CategoryComponent } from '../category/category.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.scss'],
})
export class CategorylistComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  CID:any;
  flag:any;
  LocationID:any;
  companies:any; 
  datasource:MatTableDataSource<GroupALL>;

  displayedColumns =['CategoryID','CategoryName','Description','Action'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,private dialogService: DialogService) 
    { 
      
    }

  ngOnInit() {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'   
    this.Loaddetails();
  }

  doFilter(filterValue: string) {
    // console.log(filterValue);
    this.datasource.filter = filterValue;
  }


  Loaddetails()
  {
    let params = {
            CID:this.CID,
            flag:this.flag
       }          
          this.service.httpPost('/Category/GetCategory',params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.datasource=result.respdata.Categorylist;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Categorylist);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
              }                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  add()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid:this.CID,
      Flag:'ADD'                        
     
    }
    const dialogRef = this.dialog.open(CategoryComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.CID=this.CID;
      this.flag='PAGELOAD'
      this.Loaddetails();

      });
  }

  Edit(event)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid:this.CID,
      Flag:'EDIT' ,    
      CategoryID:event.CategoryID,
      CategoryName:event.CategoryName,
      Description:event.Description
                               
    }
    const dialogRef = this.dialog.open(CategoryComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.CID=this.CID;
      this.flag='PAGELOAD'
      this.Loaddetails();

      });
  }

      Delete(event)
      {
            let branch = [];          
            branch.push({BranchID:'' ,BranchName:''})          
            this.dialogService.openConfirmDialog('Are you sure to delete this category?')
            .afterClosed().subscribe(res => {
            if (res) {
            let params = { 
            cid:this.CID,
            flag: 'Delete', 
            CategoryID:event.CategoryID,           
            UserID:0,
            GroupID:sessionStorage.getItem('groupid')                        
          };
          this.service.httpPost('/Category/SetCategory',params)
          .subscribe(
            (data) => {
              let result: any;
              result = data;       
              if (result.errno !== 0) {
                this.notificationService.success(result.errdesc);

                this.flag='PAGELOAD'             
              this.Loaddetails();
              }
              else {
                this.notificationService.success(result.errdesc); //':: Problem details');
              }
            },
            (error: AppError) => {
              if (error instanceof BadInput)         
                console.log(error);
              else throw error;       
            });
          }
        });
  }

  onCompanySelect(event)
  {
    this.CID=event;
    this.Loaddetails();
  }

}

export interface GroupALL {
  CategoryID: string;
  CategoryName: string; 
}
