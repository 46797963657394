import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { CrmdatecontrolComponent } from '../../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';

export interface User {
  Name: string;
}

@Component({
  selector: 'app-datewisereport',
  templateUrl: './datewisereport.component.html',
  styleUrls: ['./datewisereport.component.scss'],
})
export class DatewisereportComponent implements OnInit {

  CID:any;
  flag:any;
  Condition:any;
  companies:any;
  entitytype:any;
  datasource:any;
  frmdate= new Date();
  
  TimepunchGroup : FormGroup;
  filteredOptions: Observable<string[]>;
  myControl = new FormControl('', [Validators.required]);
  empid:string[]=[];
  ledgerid= 0;
  employeelist: any;
  filterOption: any;
  timezonelist:any;
  timezone:any=0;
  DateTypeFlag:any="Today";
  fromdate:any;
  todate:any;
  showSpinner:any;
  fieldvisible:boolean=false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns =['Name','EntryDate','InTime','OutTime','Tothours','Effort'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,
    public notificationService: NotificationService,private dialogService: DialogService,
    private generic: GenericService,private formBuilder: FormBuilder,private datePipe: DatePipe
    )
     { 
      this.TimepunchGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] , 
        //fcFromDate: [''],                 
        fcTimezone: [''],     
        //fcToDate: ['']
         
    });
   
     }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.TimepunchGroup.controls.fcCompany.setValue(this.CID);    
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    // this.TimepunchGroup.controls.fcFromDate.setValue(this.frmdate);
    // this.TimepunchGroup.controls.fcToDate.setValue(this.frmdate);
    this.Loaddetails();
    //this.myControl.setValue({Name: 'ALL'});
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | User>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) : this.empid.slice())
      );

      this.TimepunchGroup.controls.fcTimezone.setValue(0);
  }
  displayFn(empid?: User): string | undefined {
    return empid ? empid.Name : undefined;
  }
  selectedproduct(event) {    
    this.ledgerid=event.option.value;   
   // this.myControl.setValue({Name: event.option.value }); 
    this.ledgerid=JSON.parse(_.pluck(_.where(this.employeelist, { 'Name': event.option.value }),'LedgerID'));     
  }

  SearchClear()
  {
    this.myControl.setValue('');  
  }

  private _filter(name: string): string[] {

    const filterValue = name.toLowerCase();   
      return this.empid.filter(option => option.toLowerCase().includes(filterValue));   
  }
  changetimezone()
  {
    this.flag='LOADTZID'
    this.timezone=this.TimepunchGroup.get('fcTimezone').value;
    this.empid=[];
    this.employeelist="";
    this.Loaddetails();
  //  this.myControl.setValue({Name: 'ALL'});
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | User>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filter(name) : this.empid.slice())
      );

  }
  refresh()
  {
    this.flag='GETTIME'
    //this.Loaddetails();
    if(this.ledgerid !=0 &&  this.flag=='GETTIME')
      {
        this.showSpinner=true;
        let params = {
                cid:this.CID,
                Flag:this.flag,
                LedgerID:this.ledgerid,
                // FromDate: this.datePipe.transform(this.TimepunchGroup.get('fcFromDate').value,"dd-MM-yyyy"),
                // ToDate: this.datePipe.transform(this.TimepunchGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
                // this.datePipe.transform(this.TimepunchGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.TimepunchGroup.get('fcToDate').value,"dd-MM-yyyy"),
                FromDate:this.fromdate,
                ToDate:this.todate,
                TimeZone:this.timezone
           }
         //  console.log('afaf',params)
              // const query = `CID=${params.cid}&Flag=${params.Flag}&LedgerID=${params.LedgerID},
              // &FromDate=${params.FromDate},&ToDate=${params.ToDate},&TimeZone=${params.TimeZone}`;
              this.service.httpPost('/DateWiseReport/GetDateWiseReport',params)
              .subscribe(data => {
    
                this.showSpinner=true;
                setTimeout(() =>{
                  this.showSpinner=false;
                },
                800)
    
    
                let result: any;
                result=data;
                if (result.errno === 0){
                 
                  if(this.flag=='PAGELOAD')
                  {
                    this.companies=result.respdata.Companylist;
                    this.employeelist = result.respdata.Employeelist;
                    // this.datasource=result.respdata.Statuslist;
                    this.timezonelist = result.respdata.Timezonelist;
                    this.empid= _.pluck(this.employeelist, 'Name');
                    this.datasource=result.respdata.LoadData;
                  }
                  else if(this.flag=='LOADTZID')
                  {
                    this.employeelist=result.respdata.Employeelist;
                    this.empid= _.pluck(this.employeelist, 'Name');
                  }
                  else
                  {
                    this.datasource=result.respdata.list;
                    this.datasource = new MatTableDataSource<GroupALL>(result.respdata.list);
                    this.datasource.sort = this.sort;
                    this.datasource.paginator = this.paginator;
                  }
                    
                    
                }
                else{
                  this.notificationService.success(result.errdesc); //':: Problem details');
                }
              }, 
              (error: AppError) => {
                if (error instanceof BadInput)           
                  console.log(error);
                else throw error;       
              });
            }
            else{
              this.notificationService.success('Please select employee'); //':: Problem details');
            }
  }
  Loaddetails()
  {

    // if (this.TimepunchGroup.invalid) {
    //   this.notificationService.warn('Please select employee');    
    //   return;
    //   }
  // if(this.ledgerid !=0 &&  this.flag=='GETTIME')
  // {
    this.showSpinner=true;
    let params = {
            cid:this.CID,
            Flag:this.flag,
            LedgerID:this.ledgerid,
            // FromDate: this.datePipe.transform(this.TimepunchGroup.get('fcFromDate').value,"dd-MM-yyyy"),
            // ToDate: this.datePipe.transform(this.TimepunchGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
            // this.datePipe.transform(this.TimepunchGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.TimepunchGroup.get('fcToDate').value,"dd-MM-yyyy"),
            FromDate:this.fromdate,
            ToDate:this.todate,
            TimeZone:this.timezone
       }
       //console.log('afaf',params)
          // const query = `CID=${params.cid}&Flag=${params.Flag}&LedgerID=${params.LedgerID},
          // &FromDate=${params.FromDate},&ToDate=${params.ToDate},&TimeZone=${params.TimeZone}`;
          this.service.httpPost('/DateWiseReport/GetDateWiseReport',params)
          .subscribe(data => {

            this.showSpinner=true;
            setTimeout(() =>{
              this.showSpinner=false;
            },
            800)


            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.employeelist = result.respdata.Employeelist;
                // this.datasource=result.respdata.Statuslist;
                this.timezonelist = result.respdata.Timezonelist;
                this.empid= _.pluck(this.employeelist, 'Name');
                this.datasource=result.respdata.LoadData;
              }
              else if(this.flag=='LOADTZID')
              {
                this.employeelist=result.respdata.Employeelist;
                this.empid= _.pluck(this.employeelist, 'Name');
              }
              else
              {
                this.datasource=result.respdata.list;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.list);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
              }
                
                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
      //  }
  }

  View(event)
  {

  }

  onSearchClear(){
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.TimepunchGroup.reset(); 
    this.datasource='';   
    this.myControl.setValue('');      
    this.TimepunchGroup.controls.fcCompany.setValue(JSON.parse(sessionStorage.getItem('cid')));  
    this.TimepunchGroup.controls.fcTimezone.setValue(0);
    this.DateTypeFlag="Today";
    this.ledgerid=0;
  }

  onCompanySelect(event)
  {
    this.employeelist='';
    this.empid=[];
    this.CID=event;
    this.Loaddetails();
     this.myControl.setValue(''); 
   
  }


  exportAsExcel()
  {
    
  console.log('hi')
  this.generic.excelExport(this.datasource.data, 'Timepunch Vs Effort','Timepunch Vs Effort From ' +  this.fromdate +' to '+ this.todate);
  }


  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }


}

export interface GroupALL {
  Name: string;
  EntryDate: string;
  InTime: string;
  LastOut: string;
  Tothours: string;
  Effort: string; 

}
