import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';

// import {} from 'googlemaps';
@Component({
  selector: 'app-worklocation',
  templateUrl: './worklocation.component.html',
  styleUrls: ['./worklocation.component.css']
})
export class WorklocationComponent {
  constructor(private service:HttpserviceService,private NotificationService:NotificationService, public dialogRef: MatDialogRef<WorklocationComponent>,)
  {

  }
  
  lat :any;
  long:any;
  zoom: number =10;
  GeoID:any;
  WorkLocation:any;
  flag:any;
  flag_:any;
  addvisible:boolean=true;
  editvisible:boolean=false;
  WLID:any=0;
  WLList:any;
  isDisabled: boolean = true;
  cid:any=JSON.parse(sessionStorage.getItem('cid'));

  async locate()
  {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
      });

  }

  loadScript() {
  
    this.locate();

var latDes = this.lat;
var longDes = this.long;
var url = "https://www.google.com/maps/dir/?api=1";
var origin = "&origin=" + this.lat + "," + this.long;
var destination = "&destination=" + latDes + "," + longDes;
var newUrl = new URL(url + origin + destination);
console.log('url',newUrl)

var win = window.open(newUrl, '_blank');
 win.focus();

 }
 Add()
 {
   this.flag='ADD';
   this.addvisible=true;
   this.editvisible=false;
   this.isDisabled=false;
 }
 edit()
 {
   this.flag='EDIT';
   this.flag_='LoadCombo'
   this.addvisible=false;
   this.editvisible=true;
   this.isDisabled=false;
   this.LoadData();
 }
 LoadData()
 {
   const params = {
    cid:this.cid,
    flag:this.flag_,
    WLID:this.WLID
      
    
 };  
 const query = `cid=${params.cid}&Flag=${params.flag}&WLID=${params.WLID}`;
 this.service.httpGet('/usermgt/GetWorkLocation?',query)
 .subscribe(
   (data) => {
     let result: any;
     result = data; //result.users;
     if (result.errno === 0){

       console.log('ttt',result.respdata)

       if(this.flag_=='LoadCombo')
       this.WLList=result.respdata.Table;
      else
       this.GeoID=result.respdata.Table[0].GeoID;
       this.WorkLocation=result.respdata.Table[0].Location;
     
   
      
     }
     else{
       this.NotificationService.success(result.errdesc); //':: Problem updating user');
     }
   },
   (error: AppError) => {
     if (error instanceof BadInput)           
       console.log(error);
     else throw error;       
   });
 }
 locationchange()
 {
  this.flag_='Get'
  this.LoadData();
 }
 save()
 {
  let paramsExtra = {
    flag: this.flag,
    cid: JSON.parse(sessionStorage.getItem('cid')),
    Location:this.WorkLocation,
    WLID:this.WLID,
    GeoID:this.GeoID
  };

  this.service.httpPost('/usermgt/SetWorkLocation?',paramsExtra)
  .subscribe(
    (data) => {
      var users: any;
      users = data; //result.users;
      if (users.errno === 0){
  
        this.NotificationService.success('Updated successfully');
      
      }
      else{
        this.NotificationService.success(users.errdesc); //':: Problem updating user');
      }
    }, 
    (error: AppError) => {
      // if (error instanceof BadInput)
      //   this.service.form.setErrors(error.originalError);
      // else throw error;       
    });   
 }
 onCloseDialog(){
      this.dialogRef.close({event: 'CLEAR'});

}
clear()
{
  this.WorkLocation='';
  this.GeoID='';
  this.flag='';
  this.isDisabled=true;

}

}
