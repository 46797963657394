import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-leavegroup',
  templateUrl: './leavegroup.component.html',
  styleUrls: ['./leavegroup.component.scss'],
})
export class LeavegroupComponent implements OnInit {

  ActiveStatus = [
    { id : 1, Name: 'Active'},
    { id : 0, Name: 'InActive'}
  ];

  LeavegroupFormGroup: FormGroup;
  levegrpid:any="0";
  grpname:any;
  status:any
  Flag:any;
  cid:any;
  readonly:any;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<LeavegroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.levegrpid=this.data.ID;
      this.grpname=this.data.GroupName;
      this.status=this.data.Status; 
      this.LeavegroupFormGroup = this.formBuilder.group({  
        fcGroupName:['', Validators.required],     
        fcStatus:['']   
      });  

      this.LeavegroupFormGroup.controls.fcGroupName.setValue(this.grpname);
      this.LeavegroupFormGroup.controls.fcStatus.setValue(this.status);
    }

  ngOnInit() {}

  Submit()
  {
    let params = { 
      cid:this.cid,
      flag: this.Flag, 
      ID:this.levegrpid,            
      GroupName:this.LeavegroupFormGroup.get('fcGroupName').value,   
      Status:this.LeavegroupFormGroup.get('fcStatus').value,
      UserID:0,
      GroupID:sessionStorage.getItem('groupid')             
     };
    this.service.httpPost('/LeaveTypeMaster/SetLeaveGroup',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

  onCloseDialog()
  {    
    this.dialogRef.close();
  }

}
