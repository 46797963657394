import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { User } from 'src/app/Modules/user';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';

@Component({
  selector: 'app-empaccounts',
  templateUrl: './empaccounts.component.html',
  styleUrls: ['./empaccounts.component.scss'],
})
export class EmpaccountsComponent implements OnInit {
  cid:any=101;
  flag:any;
  LedgerID:any=0;
  dataSource: any[] = [];
  Employeelist:any;
  WPSlist:any;
  EmpTypeList:any;
  TypeList:any;
  myFormGroup:UntypedFormGroup;
  BankACNo:any='';
  IBan:any='';
  ChequeName:any='';
  WPSID:any='';
  BankName:any='';
  BeneficiaryCode:any='';
  WPSRoutingCode:any='';
  EmpType:any='';
  JoiningDate:any;
  curUser: User= JSON.parse(sessionStorage.getItem('currentUser'));
  twoColumnArray: { HRType: string, Amount: any }[] = [];
  HikeCount:number=0;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private datePipe: DatePipe) 
  {
    
   }

  ngOnInit() 
  {
    
    //this.initializeFormGroup();

    this.flag='PAGELOAD'
    this.LoadData();
    this.fetchDataSource(); //
  }

  fetchDataSource() {
    // Simulating async data fetching
    setTimeout(() => {
      this.dataSource = [
        { Particulars: 'Allowance'},
        { Particulars: 'Basicsalary'}
      ];
      this.initializeFormGroup();
    }, 1000);
  }

  initializeFormGroup() {
    let group = {};
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach(input_template => {
        group[input_template.Particulars] = new UntypedFormControl('');
      });
    } else {
      // If dataSource is empty, create a default control
      group['defaultControl'] = new UntypedFormControl('');
    }
    this.myFormGroup = new UntypedFormGroup(group);
  }

  onEmployeechanged(e)
  {
    this.flag='PARTICULARS'
    this.LoadData();
  }
  LoadData()
  {
    const params = {
      cid: this.cid,
      LedgerID:this.LedgerID,
      Flag:this.flag,
    };   
    const query = `cid=${params.cid}&LedgerID=${params.LedgerID}&Flag=${params.Flag}`;

    this.service.httpGet('/EmpAccounts/GetEmpAccounts?' ,query)   
    .subscribe(data =>{
      var result = data as any;
      result = data; 
      
         if (this.flag=='PAGELOAD')
          {
            this.Employeelist=result.respdata.Table;
            this.WPSlist=result.respdata.Table1;
            this.EmpTypeList=result.respdata.Table2;
            this.HikeCount=0;
         

            
          }
          else  (this.flag=='PARTICULARS')
          {

            console.log('ttt',result.respdata.Table2)
              this.BankACNo=result.respdata.Table[0].BankACNo
              this.IBan=result.respdata.Table[0].IBan
              this.BankName=result.respdata.Table[0].BankName
              this.BeneficiaryCode=result.respdata.Table[0].BeneficiaryCode
              this.WPSID=result.respdata.Table[0].WPSID
              this.WPSRoutingCode=result.respdata.Table[0].WPSRoutingCode
              this.ChequeName=result.respdata.Table[0].ChequeName,
              this.JoiningDate=result.respdata.Table[0].JoiningDate,
              this.HikeCount=result.respdata.Table2[0].HikeCount




            this.dataSource= result.respdata.Table1;  

            console.log("mm",result.respdata.Table)

            let group={}    
              this.dataSource.forEach(input_template=>{
               
                  group[input_template.Particulars]=new UntypedFormControl(input_template.Amount); 
                  
            
                 
              })
              this.myFormGroup = new UntypedFormGroup(group);

          }

    
     
     
      
        
    });
  }
  Save()
  {

    if (this.HikeCount>1)
      {
        this.NotificationService.warn('Cannot Edit Salary and OT Details');
      }
      else
      {

        const formValues = this.myFormGroup.value;

    this.twoColumnArray = Object.keys(formValues).map(key => ({
      HRType: key,
      Amount: formValues[key]
    }));

    console.log('yyyy', this.twoColumnArray)
    const params = {
      str_SiteID: this.cid,
      int_BusinessPeriodID:0,
      
      ObjEmpMain:{
      str_EmpID:this.LedgerID,
      dtp_JoiningDate:this.datePipe.transform(this.JoiningDate, 'MMM dd yyyy') ,
      str_BankACNo:this.BankACNo==null?'':this.BankACNo,
      str_IBan:this.IBan==null?'':this.IBan,
      str_BankName:this.BankName==null?'':this.BankName,
      str_BeneficiaryCode:this.BeneficiaryCode==null?'':this.BeneficiaryCode,
      str_ChequePrintName:this.ChequeName==null?'':this.ChequeName,
      dbl_MonthlyDeductable:0,
     
      int_DeductMonth:0,
      str_CreatedBy:this.curUser.userid,
      dtp_CreatedDate: this.datePipe.transform(new Date(), 'MMM dd yyyy hh:mm'),
      str_LastUpdatedBy:this.curUser.userid,
      dtp_LastUpdatedDate:this.datePipe.transform(new Date(), 'MMM dd yyyy hh:mm'),
      },
      ObjEmpWPSDetails:{
        str_WPSID:this.WPSID==null?'':this.WPSID,
        str_WPSType:this.EmpType==null?'':this.EmpType,
        str_WPSRoutingCode:this.WPSRoutingCode==null?'':this.WPSRoutingCode,
      },
      ObjEmpHR:{
        dt_EmpHR: this.twoColumnArray
      }
    };  
    console.log('params',params)
    this.service.httpPost('/EmpAccounts/SetEmpAccounts?',params)
    .subscribe(
      (data) => {
        let users: any;
        users = data; //result.users;
        if (users.errno === 0){
          this.NotificationService.success(':: Submitted sucessfully');
          // this.GoalID=users.respdata;
          // this.groupenable = this.GoalID !== 0 ? false : true;
        }
        else{
          this.NotificationService.success(users.errdesc); //':: Problem updating user');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)           
          console.log(error);
        else throw error;       
      });

      }

    
  }

}
