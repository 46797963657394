import { Component, OnInit,ViewChild } from '@angular/core';
import { MatDialog ,MatDialogConfig} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
// import 'devextreme/data/odata/store';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { UserService } from './../../Admin/Users/userValidate';
import { User } from './../../../Modules/user';
import { CrmdatecontrolComponent } from '../../HR/CRMDateControl/crmdatecontrol/crmdatecontrol.component';
import { LeavemgtelogComponent } from 'src/app/components/HR/LeaveApproval/leavemgtelog/leavemgtelog.component';


export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

// class PickDateAdapter extends NativeDateAdapter {
//   format(date: Date, displayFormat: Object): string {
//       if (displayFormat === 'input') {
//           return formatDate(date,'dd-MM-yyyy',this.locale);;
//       } else {
//           return date.toDateString();
//       }
//   }
// }

@Component({
  selector: 'app-empleaverpt',
  templateUrl: './empleaverpt.component.html',
  styleUrls: ['./empleaverpt.component.css'],  
  providers: [
    // {provide: DateAdapter, useClass: PickDateAdapter},
    // {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
]
})
export class EmpleaverptComponent implements OnInit {
  @ViewChild('stepper') stepper;
  dataSource: LeaveDataDataGrid[];
  LeaveDataGrids: any;
  frmdate= new Date();
  companies: any;
  Employees: any;
  Department: any;
  LeaveType: any;
  selectedemployees:number;
  employeeid:string[]=[];
  selectedEmpID: any;
  cid:any;
  EmpLeaveFormGroup: FormGroup;
  LeaveDataFormGroup: FormGroup;
  isEditable = true; 
  showSpinner:any;
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  viewbtnvisible: boolean = false;
  date: Date; 
  serializedDate = new FormControl((new Date()).toISOString());
  now: Date = new Date();
  fromdate:any;
  todate:any;
  DateTypeFlag:any="Today"

  constructor(private service: HttpserviceService, private formBuilder: FormBuilder,private dialog:MatDialog,
    private generic: GenericService, private service1: UserService,
    public notificationService: NotificationService, private datePipe: DatePipe) { 

      this.EmpLeaveFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] ,    
        myControl: [''],
        fcDepartment: [''],
        fcReturnStatus: [''],
        fcLeaveType: [''],      
       // fcFromDate: ['', Validators.required],
       // fcToDate: ['', Validators.required]       
      });
      this.LeaveDataFormGroup = this.formBuilder.group({        
      });     
    }

  ngOnInit(): void {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));   
    this.PageLoad();
    this.EmpLeaveFormGroup.controls.fcCompany.setValue(this.cid);
   // this.EmpLeaveFormGroup.controls.fcFromDate.setValue(this.frmdate);
  //  this.EmpLeaveFormGroup.controls.fcToDate.setValue(this.frmdate);
  this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
  this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );     
  }

  changesite(e) {
    this.cid=e.value;
    this.Employees = '';
    this.employeeid= [];
    this.PageLoad();
    this.myControl.setValue(''); 
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();   
    // console.log('Filter employeeid',this.employeeid);
      return this.employeeid.filter(option => option.toLowerCase().includes(filterValue));   
  }

  selectedemployee(event) {    
    // this.selectedemployees=event.option.value;    
    // this.selectedemployees=JSON.parse(_.pluck(_.where(this.Employees, { 'Name': event.option.value }),'LedgerID'));     
    this.selectedEmpID = event.option.value;
    const str: string = this.selectedEmpID;
    // this.selectedEmpID=str.split('-',1);
    // console.log('selectedEmpID Split',this.selectedEmpID.substr(0,this.selectedEmpID.length));
    this.selectedEmpID = this.selectedEmpID.slice(0, str.indexOf('-'));
  
  }

  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        width: '500px',
        height: '500px',
        panelClass: 'my-panel-class'
      }
    const dialogRef = this.dialog.open(CrmdatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }
  
  onSearchClear(){
     this.EmpLeaveFormGroup.reset();   
     this.myControl.setValue('');
     this.LeaveDataGrids='';
     this.stepper.selectedIndex = 0;  
     this.selectedEmpID='';
     this.EmpLeaveFormGroup.controls.fcCompany.setValue(this.cid);
     this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
     this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
   //  this.EmpLeaveFormGroup.controls.fcFromDate.setValue(this.frmdate);
    // this.EmpLeaveFormGroup.controls.fcToDate.setValue(this.frmdate);
    this.DateTypeFlag="Today";
  }

  PageLoad() {
    // console.log('ledgerid',sessionStorage.getItem("groupid"));
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID: this.cid,
      Flag: "LOADDDL",
      EmployeeID:"",              
      Department:"",
      LeaveType: "",
      FromDate:"",
      ToDate:"",
      RtnStatus:"",
      UserID:sessionStorage.getItem("ledgerid")==null?"":sessionStorage.getItem("ledgerid")
    };
    this.service.httpPost('/EmpLeaveReport/GetEmpLeaveReport',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.companies = curUser.dtsiteid;   
            this.Employees = result.respdata.Employee;
            this.Department = result.respdata.Department;
            this.LeaveType = result.respdata.LeaveType;

            this.employeeid= _.pluck(this.Employees, 'Name');   
            this.getUserList();           
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_77",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);      
      this.viewbtnvisible = btnVisilityData.viewflag;                     
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }

  Submit() {    
    if (this.EmpLeaveFormGroup.invalid) {
      this.notificationService.warn('Please select date range');     
      return;
      }  
    this.showSpinner=true;
    let params = {     
      CID: this.EmpLeaveFormGroup.get('fcCompany').value,
      Flag: "LOADGRID",               
      EmployeeID: this.selectedEmpID,  
      Department:this.EmpLeaveFormGroup.get('fcDepartment').value, 
      LeaveType:this.EmpLeaveFormGroup.get('fcLeaveType').value,
      RtnStatus:this.EmpLeaveFormGroup.get('fcReturnStatus').value,
      FromDate:this.fromdate,
      ToDate:this.todate,
     // FromDate: this.datePipe.transform(this.EmpLeaveFormGroup.get('fcFromDate').value,"dd-MM-yyyy"),    
     // ToDate: this.datePipe.transform(this.EmpLeaveFormGroup.get('fcToDate').value,"dd-MM-yyyy")==null? 
     // this.datePipe.transform(this.EmpLeaveFormGroup.get('fcFromDate').value,"dd-MM-yyyy"):this.datePipe.transform(this.EmpLeaveFormGroup.get('fcToDate').value,"dd-MM-yyyy"),
      UserID:""
    };
    this.service.httpPost('/EmpLeaveReport/GetEmpLeaveReport',params)
      .subscribe(
        (data) => {
          let result: any;
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          result = data;          
          if (result.errno === 0) {
            //console.log('result.respdata.table', JSON.stringify(result));
            this.LeaveDataGrids = result.respdata.Leavedata;
            this.dataSource = result.respdata.Leavedata;

            this.stepper.selectedIndex = 1;  
            // console.log('leave list: ', result.respdata.Leavedata);
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  onFocusedRowChanged(e) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
       
        dialogConfig.data = {                                
                height: '350px',
                width: '70%',                            
                cid:this.EmpLeaveFormGroup.get('fcCompany').value,
                flag:"View",
                leaveid:e.data.LeaveID,                          
            }                   
             this.dialog.open(LeavemgtelogComponent, dialogConfig)
            
  }

}
export class LeaveDataDataGrid {
  CID: number
  UserID: number
  EmployeeID : number
  Deparment:string
  LeaveType:string
  FromDate:string
  ToDate:string 
    
}
