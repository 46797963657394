import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { DatePipe } from '@angular/common';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { QuestionComponent } from '../question/question.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface GroupALL {  
  Type: string; 
}

@Component({
  selector: 'app-questionlist',
  templateUrl: './questionlist.component.html',
  styleUrls: ['./questionlist.component.scss'],
})


export class QuestionlistComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  CID:any;
  flag:any;
  companies:any;
  datasource:MatTableDataSource<GroupALL>;

  displayedColumns =['Questions','ControlType','DatePeriod','Type','Action'];

  constructor(public service: HttpserviceService,public notificationService: NotificationService,
    private datePipe: DatePipe,private dialogService: DialogService,private dialog: MatDialog,)
   { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
       this.Loaddetails();
  }

  Refresh()
  {
    this.Loaddetails();
  }

  Loaddetails()
     {
       let servicepath;
       let params = {
         cid:this.CID,
         flag:this.flag,
         ID:0,
         DatePeriod:this.datePipe.transform(Date(), 'dd-MM-yyyy')
          }
          
             this.service.httpPost('/Question/GetQuestion',params)
             .subscribe(data => {
               let result: any;
               result=data;
               if (result.errno === 0){
                
                 if(this.flag=='PAGELOAD')
                 {
                   this.companies=result.respdata.Companylist;
                   this.datasource=result.respdata.Questionlist;
                   this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Questionlist);
                   this.datasource.sort = this.sort;
                   this.datasource.paginator = this.paginator;
    
                 }
                //  else if(this.flag=='Category')
                //  {
                //    this.datasource=result.respdata.List;
                //  }
                //  else
                //  {
                //    let filterdata
                //    this.employeelistselected=result.respdata.List;
                //  }
                   
                   
               }
               else{
                 this.notificationService.success(result.errdesc); //':: Problem details');
               }
             }, 
             (error: AppError) => {
               if (error instanceof BadInput)           
                 console.log(error);
               else throw error;       
             });
     }

     Add()
     {
      const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'ADD' ,                            
          question:'',
          controltype:'',
          dateperiod:'',
          type:''   
        }
        const dialogRef = this.dialog.open(QuestionComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Loaddetails();
  
          });
     }
     Edit(event)
     {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {       
        cid:this.CID,
        Flag:'EDIT' ,                            
        question:event.Questions,
        controltype:event.ControlType,
        dateperiod:event.DatePeriod,
        type:event.Type,
        ID:event.ID
      }
      const dialogRef = this.dialog.open(QuestionComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(result => {
        this.CID=this.CID;
        this.flag='PAGELOAD'
        this.Loaddetails();

        }); 

     }
     Delete(event)
     {
      this.dialogService.openConfirmDialog('Are you sure to delete this question?')
      .afterClosed().subscribe(res => {
       if (res) {
        let temp = [];
          temp.push({Value:'',Value1:''});
    
      
      let params = { 
        cid:this.CID,
        flag:'DELETE', 
        ID:event.ID,
        Questions:event.Questions,
        ControlType:event.ControlType,
        Type:event.Type, 
        DatePeriod: this.datePipe.transform(event.DatePeriod, 'dd-MM-yyyy')  ,         
        dt:temp,
        UserID:'0',
        GroupID:101
        
       };
      this.service.httpPost('/Question/SetQuestion',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {
            this.notificationService.warn('Deleted Successfully');
            this.Loaddetails();
            
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
            this.Loaddetails();
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)         
            console.log(error);
          else throw error;       
        });
     
    }
  });

}
}
