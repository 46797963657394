import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BranchComponent } from '../branch/branch.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';

@Component({
  selector: 'app-branchlist',
  templateUrl: './branchlist.component.html',
  styleUrls: ['./branchlist.component.scss'],
})
export class BranchlistComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  CID:any;
  flag:any;
  LocationID:any;
  companies:any;
  Clientlist:any;
  datasource:MatTableDataSource<GroupALL>;

  displayedColumns =['LocationID','LocationName','Description','status','Action'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,private generic: GenericService,
    public notificationService: NotificationService,private dialogService: DialogService) 
    { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.LocationID='0';
    this.Loaddetails();
  }

  Loaddetails()
  {
    let params = {
      CID:this.CID,
      flag:this.flag,
      LocationID:this.LocationID
      
       }
          // const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
          this.service.httpPost('/Branch/GetBranch',params)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.datasource=result.respdata.Branchlist;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Branchlist);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
              }
                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  doFilter(filterValue: string) {
    // console.log(filterValue);
    this.datasource.filter = filterValue;
  }

  exportAsExcel()
  {
    this.generic.excelExport(this.datasource.data, 'Branch', 'Branchlist' );
  }

  add()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid:this.CID,
      Flag:'ADD' ,                            
      LocationID:0,
      LocationName:'',
      Status:'',
      Description:'' 
    }
    const dialogRef = this.dialog.open(BranchComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.CID=this.CID;
      this.flag='PAGELOAD'
      this.Loaddetails();

      });
  }
  Edit(event)
  {
  
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'EDIT' ,    
          LocationID:event.LocationID,
          LocationName:event.LocationName,
          Status:event.IsActive,
          Description:event.Description
                                   
        }
        const dialogRef = this.dialog.open(BranchComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Loaddetails();
  
          });
  }

  Delete(event)
  {

    let branch = [];
   
      branch.push({BranchID:'' ,BranchName:''})
    
      this.dialogService.openConfirmDialog('Are you sure to delete this Branch?')
      .afterClosed().subscribe(res => {
       if (res) {
    let params = { 
      cid:this.CID,
      flag: 'Delete', 
      LocationID:event.LocationID,  
      LocationName:'',         
      Status:  0,   
      Description:'',
      dt:branch,
      UserID:0,
      GroupID:sessionStorage.getItem('groupid')
      
      
     };
    this.service.httpPost('/Branch/SetBranch',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno !== 0) {
          this.notificationService.success(result.errdesc);

          this.flag='PAGELOAD'
        this.LocationID='0';
        this.Loaddetails();
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
  }

  onCompanySelect()
  {

  }

}

export interface GroupALL {
  
}
