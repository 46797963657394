import { StatusComponent } from './../status/status.component';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';

@Component({
  selector: 'app-statuslist',
  templateUrl: './statuslist.component.html',
  styleUrls: ['./statuslist.component.scss'],
})
export class StatuslistComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  CID:any;
  flag:any;
  Condition:any;
  companies:any;
  entitytype:any;
  datasource:MatTableDataSource<GroupALL>;
  addbtnvisible:boolean=false;

  displayedColumns =['StatusID','Tag','Description','Action'];
  constructor(private dialog: MatDialog,public service: HttpserviceService,private generic: GenericService,
    public notificationService: NotificationService,private dialogService: DialogService
    )
     { }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();
  }

  exportAsExcel()
  {
    this.generic.excelExport(this.datasource.data, 'Status', 'Statuslist' );
  }
  
  doFilter(filterValue: string) {
    // console.log(filterValue);
    this.datasource.filter = filterValue;
  }

  
  Loaddetails()
  {
    let params = {
      cid:this.CID,
      flag:this.flag,
      Condition:this.Condition
      
       }
          const query = `cid=${params.cid}&flag=${params.flag}&Condition=${params.Condition}`;
          this.service.httpGet('/Status/GetStatus?',query)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){
             
              if(this.flag=='PAGELOAD')
              {
                this.companies=result.respdata.Companylist;
                this.datasource=result.respdata.Statuslist;
                this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Statuslist);
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
              }
              else
              {
                this.datasource=result.respdata.EntityList;
              }
                
                
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }
  add()
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'ADD' ,                            
          Type:this.Condition,
          Statusid:'0',
          Tag:'',
          Description:'',
          readonly:false   
        }
        const dialogRef = this.dialog.open(StatusComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Condition="''";
          this.Loaddetails();
  
          });
  }
  Edit(event)
  {
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:this.CID,
          Flag:'EDIT' ,                            
          Type:this.Condition,
          Statusid:event.StatusID,
          Tag:event.Tag,
          Description:event.Description,
          readonly:event.ReadOnlyTag
        }
        const dialogRef = this.dialog.open(StatusComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
          this.CID=this.CID;
          this.flag='PAGELOAD'
          this.Condition="''";
          this.Loaddetails();
  
          });
  }
  Delete(event){
  
    let sdate =Date();
    this.dialogService.openConfirmDialog('Are you sure to delete this status?')
    .afterClosed().subscribe(res => {
     if (res) {
      let params = { 
        cid:this.CID,
        flag:'DELETE', 
        StatusID:event.StatusID,
        SLA:event.SLA,             
        Tag:  event.Tag,   
        Description: event.Description,
        ReadonlyTag:event.ReadonlyTag
        
       };
       this.service.httpPost('/Status/SetStatus',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.notificationService.warn("Record Deleted Successfully");
          //console.log('Project list: ', result.respdata);
          this.flag='PAGELOAD'
          this.Condition="''";
          this.Loaddetails();
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
}
  onCompanySelect(event)
  {
    this.CID=event;
    this.flag='PAGELOAD'
    this.Condition="''";
    this.Loaddetails();

  }

}

export interface GroupALL {
  
  Type: string; 
}

