import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Modules/user';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';

@Component({
  selector: 'app-itshomepage',
  templateUrl: './itshomepage.component.html',
  styleUrls: ['./itshomepage.component.scss'],
})
export class ITSHomepageComponent implements OnInit {
  cid:any;
  LedgerID:any;
  All:any;
  Pending:any;
  Ongoing:any;
  Completed:any;
  showSpinner:any;
  constructor(private service: HttpserviceService,public notificationService: NotificationService,) 
  { }

  ngOnInit() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.LedgerID=curUser.userid;
    this.cid=JSON.parse(sessionStorage.getItem('cid'));
    this.PageLoad();
  }
  
  Submit()
  {
    this.PageLoad();
  }

  PageLoad() {
    let params = {
      CID: this.cid, 
      Flag: "LOADTASK",
      UserID: this.LedgerID
    };
    //this.service.getddllist(params)
    this.showSpinner=true;
    this.service.httpPost('/ITSHomePage/GetTicketData',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;
          if (result.errno === 0) {
            this.All = result.respdata.All;           
            this.Pending = result.respdata.Pending;
            this.Ongoing = result.respdata.Ongoing;     
            this.Completed = result.respdata.Completed;                
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        error => {
          console.log('Error : ', error);
        });
  }

}
