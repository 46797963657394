import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { HttpserviceService } from './../../../../Services/httpServices/httpservice.service';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from './../../../../Modules/user';

import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-employee-on-board',
  templateUrl: './employee-on-board.component.html',
  styleUrls: ['./employee-on-board.component.scss'],
})
export class EmployeeOnBoardComponent implements OnInit {
  employeelist:any;
  Templatelist:any;
  CID:any;
  showSpinner:any;
  flag:any;
  GeneralFormGroup: FormGroup;
  constructor(private datePipe: DatePipe,private dialogService: DialogService,
    public service: HttpserviceService,
    public notificationService: NotificationService,
    private dialog:MatDialog,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EmployeeOnBoardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      this.flag = this.data.flag;
      this.employeelist =  this.data.list.EmployeeList;
      this.Templatelist = this.data.list.TemplateList;
      this.CID=this.data.cid;

      this.GeneralFormGroup = this._formBuilder.group({
        Name: ['', Validators.required],
        TemplateID: ['', Validators.required],
        Date: ['', Validators.required],
      });
    }

  ngOnInit() 
  {
    
  }
  onSubmit()
  {
    this.showSpinner=true;

    const  params = {
      CID: JSON.parse(sessionStorage.getItem('cid')),
      Flag : this.flag,
      LedgerID : this.GeneralFormGroup.get('Name').value,
      OnBoardTag:"",
      OnBoardTemplateID : this.GeneralFormGroup.get('TemplateID').value,
      Status:"0",
      StartDate : this.GeneralFormGroup.get('Date').value,
    }

    this.service.httpPost('/EmployeeOnBoard/SetEmployeeOnBoard?' ,params)   
    .subscribe(data => {

      let result: any;
      result = data;
      this.showSpinner=true;
      setTimeout(() =>{
        this.showSpinner=false;
      },
      800)  
      if(result.errno == 0){
  
        this.notificationService.success("Record inserted successfully");
       
        
      }
      else{
        this.notificationService.success(result.errdesc);        
      }
    })

  }
  onClear()
  {

  }
  onCloseDialog()
  {
    this.GeneralFormGroup.reset();
    this.dialogRef.close({event: this.flag});
  }

}
