import { UserService } from 'src/app/components/Admin/Users/userValidate';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { User } from './../../../../Modules/user';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';

import { Component, OnInit,ViewChild, } from '@angular/core';
import {GoalsComponent} from './../goals/goals.component';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {  MatSort } from '@angular/material/sort';
import {  MatPaginator } from '@angular/material/paginator';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import * as _ from 'underscore/underscore-min.js';
import { DatePipe, formatDate } from '@angular/common';
import { GoalaccessComponent } from './../goalaccess/goalaccess.component';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import { DomSanitizer } from '@angular/platform-browser';
import { Goals2Component } from '../goals2/goals2.component';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM/YYYY',
  },
  display: {
    dateInput: 'MMM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};




@Component({
  selector: 'app-goalslist',
  templateUrl: './goalslist.component.html',
  styleUrls: ['./goalslist.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class GoalslistComponent implements OnInit {
  
  myFormGroup:FormGroup;
  myFormGroup1:FormGroup;

  companies:any
  datasource:any;
  commentlist:any;
  Reportingemployees:any;
  managerquestions:any;
  selectedOptions:any;
  comment:any
  index:any
  extravisable:Boolean=false
  GoalID:any
  CurLedgerID:any
  UserName:any
  form_template:any;
  assessmentvisible:boolean=true;
  managervisible:boolean=false;
  assessmentType:string='Employee';
  LedgerID:any
  ProfileImage:any;
  Profilevisible:boolean=false;
  currentuserImage:any;
  ProfileName:any
  fd=new Date()
  animate = false;
  hideme = {};
  date = new FormControl(moment());
  limit:any=0;
  commentvisible:boolean=false;
  selectedValue: string;
  groupvisible:boolean=false;
  commentcount:Number=0;
  CID:any;
  addbtnvisible:boolean=false;
  editbtnvisible: boolean = false;
  deletebtnvisible: boolean = false;
  groupbtnvisible:boolean = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns =['GoalID','GoalName','GoalDescription','EntryDate','GoalStatus','EmployeePercent','ManagerPercent','Action'];
  constructor(private datePipe: DatePipe,private generic: GenericService, private service1: UserService, private sanitizer: DomSanitizer,public service: HttpserviceService,public notificationService: NotificationService,private dialog: MatDialog, private DialogService: DialogService) {
    this.hideme = {};
   }

  ngOnInit(): void {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.myFormGroup=new FormGroup(
      {}
    );

    this.myFormGroup1=new FormGroup(
      {}
    );
   
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.UserName=curUser.username;
    this.LedgerID=curUser.ledgerid;
    this.getGoalsdetails();
  }
  onCompanySelect(event)
  {
    this.CID=event;
    this.getGoalsdetails();

  }
  getUserList(){
    let params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      menuid: "STS_86",
      groupid: JSON.parse(sessionStorage.getItem('groupid'))
    }
    this.service1.getUserList(params)
    .subscribe((data) => {
      let result: any;
      result=data;
      let btnVisilityData;
      btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);
      this.addbtnvisible = btnVisilityData.addflag; 
      this.editbtnvisible = btnVisilityData.editflag;  
      this.deletebtnvisible = btnVisilityData.deleteflag; 
      this.groupbtnvisible = btnVisilityData.viewflag;                   
    }, 
    (error: AppError) => {
      if (error instanceof BadInput)         
        console.log(error);
      else throw error;       
    });

  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.getGoalsdetails()
  }

  onMangerSubmit()
  {
    const paramvalue=this.myFormGroup1.value;
    let assessmentvalue = [];
    var ColumnName
    let Value

      for (var i = 0; i < this.managerquestions.length; i++) {
        ColumnName= this.managerquestions[i].label;
        Value=paramvalue[ColumnName]
      assessmentvalue.push({ColumnName,Value});

      }

    let  paramsExtra;
    paramsExtra = {
      CID: this.CID,
      Description:"",
      LedgerID:this.LedgerID,
      AssessmentType:"Manager",
      EntryDate:this.datePipe.transform(this.date.value.startOf('week').toDate(), 'yyy-MM-dd'),
      UserName:this.UserName,
      emptable:assessmentvalue
    };
    this.service.httpPost('/Goals/SetAssessment',paramsExtra)
    .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
      if (users.errno === 0){
    
        this.notificationService.success('Submitted successfully');
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });

    
  }
  onSubmit(){
    const paramvalue=this.myFormGroup.value;
    let assessmentvalue = [];
    var ColumnName
    let Value

      for (var i = 0; i < this.form_template.length; i++) {
        ColumnName= this.form_template[i].label;
        Value=paramvalue[ColumnName]
      assessmentvalue.push({ColumnName,Value});

      }


    let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      CID: this.CID,
      Description:"",
      LedgerID:this.CurLedgerID,
      AssessmentType:"Employee",
      EntryDate:this.datePipe.transform(this.date.value.startOf('week').toDate(), 'yyy-MM-dd'),
      UserName:this.UserName,
      emptable:assessmentvalue
  
    };
    this.service.httpPost('/Goals/SetAssessment',paramsExtra)
    .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
      if (users.errno === 0){
    
        this.notificationService.success('Submitted successfully');
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });

  }
  
  addRow()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: 'ADD',
      GoalID:0,
      cid:this.CID,
      EntryDate:this.datePipe.transform(this.date.value.startOf('week').toDate(), 'yyy-MM-dd'),
     
    }
    const dialogRef = this.dialog.open(GoalsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {  
      this.getGoalsdetails(); 
    });
    // dialogRef.afterClosed();
    
  }
  onEdit(event, item){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: "Edit",
      GoalName:item.GoalName,
      GoalID:item.GoalID,
      GoalDescription:item.GoalDescription,
      EntryDate:item.EntryDate,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(GoalsComponent, dialogConfig);      
    dialogRef.afterClosed().subscribe(result => {  
      this.getGoalsdetails(); 
    });  
  }
  onDelete(item)
  {
    this.DialogService.openConfirmDialog('Are you sure to delete this goal?')
    .afterClosed().subscribe(res => {
      if (res) {
        const params = {
          cid:this.CID,
          flag:'DELETE',
          GoalID:item.GoalID,
          LedgerID:this.CurLedgerID,
          FromDate:this.datePipe.transform(this.fd, 'yyy-MM-dd'),
          AssessmentType:"Goals"
        };
        const query = `cid=${params.cid}&flag=${params.flag}&GoalID=${params.GoalID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}&AssessmentType=${params.AssessmentType}`;
        this.service.httpGet('/Goals/GetGoals?',query)
          .subscribe(
            data => {
              let result: any;
              result = data;
              this.notificationService.warn('Record deleted successfully');
              this.getGoalsdetails();
            },
            (error: AppError) =>{
              if (error instanceof BadInput)           
              console.log(error);
            else throw error;
        });
      }
    });

  }
  loadmore()
  {
    if(this.commentlist.length!==this.limit)
    {
      this.limit=this.commentlist.length;
      this.commentvisible=false
    }
  }
  onMore(event, item,rowindex)
  {
    // this.limit=1;
    this.comment=""
    item.Visibility=item.Visibility==false ? true : false;

    Object.keys(this.hideme).forEach(h => {
      this.hideme[h] = false;
     
    });
    
     if(this.GoalID !==item.GoalID)
     {

      this.GoalID=item.GoalID
      this.extravisable = this.extravisable == false ? true : false;
      this.hideme[item.GoalID] = true
     } 
     else if (this.GoalID ==item.GoalID)
     {
      this.GoalID=item.GoalID
      this.extravisable = this.extravisable == false ? true : false;
      this.hideme[item.GoalID] = this.extravisable
     }
     
       this.Commentdetails(item.GoalID);

  }
  Commentdetails(value)
  {
    let params = {
      cid:this.CID,
      flag:'GoalSub',
      GoalID:value,
      LedgerID:this.CurLedgerID,
      FromDate:this.datePipe.transform(this.fd, 'yyy-MM-dd'),
      AssessmentType:"employee"

       }
       const query = `cid=${params.cid}&flag=${params.flag}&GoalID=${params.GoalID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}&AssessmentType=${params.AssessmentType}`;
       this.service.httpGet('/Goals/GetGoals?',query)
          .subscribe(data => {
            let result: any;
            result=data;
            if (result.errno === 0){

             

              this.commentlist=result.respdata.CommentList;
              if(this.commentlist.length>=1)
              {
                this.limit=2;
                this.commentvisible=this.commentlist.length >= 3 ? true : false;
              }else{this.commentvisible=false;}
              
            }
            else{
              this.notificationService.success(result.errdesc); //':: Problem details');
            }
          }, 
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
         
  }
  onReportingEmployee(data)
  {
    this.ProfileImage='data:image/jpeg;base64,'+this.Reportingemployees[data].Image;
    this.ProfileName=this.Reportingemployees[data].Name;
    this.LedgerID=this.Reportingemployees[data].LedgerID;
    this.assessmentType='Manager';
    this.Profilevisible=true;
    this.getGoalsdetails();
  }
  // GetImage()
  // {
  //   let params = {
  //     cid:101,
  //     flag:'GetImage',
  //     GoalID:0,
  //     LedgerID:this.CurLedgerID,
  //     FromDate:this.datePipe.transform(this.date.value, 'yyy-MM-dd'),
  //     AssessmentType:this.assessmentType
  //      }
  //         this.service.GetGoals(params)
  //         .subscribe(data => {
  //           let result: any;
  //           result=data;
  //           if (result.errno === 0){

  //             console.log("get",result.respdata.Image)

  //            this.currentuserImage='data:image/jpeg;base64,' +result.respdata.Image[0].Image;
              
             
 
  //           }
  //           else{
  //             this.notificationService.success(result.errdesc); //':: Problem details');
  //           }
  //         }, 
  //         (error: AppError) => {
  //           if (error instanceof BadInput)           
  //             console.log(error);
  //           else throw error;       
  //         });
  // }
  
  openDialog(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      GoalID:item.GoalID,
      cid:this.CID
    }
    const dialogRef = this.dialog.open(Goals2Component,dialogConfig);
  }
  Post(item)
  {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;

    let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      cid: this.CID,
      flag: "POST",
      GoalID:item.GoalID,
      GoalName:item.GoalName,
      GoalDescription:this.comment,
      EntryDate:this.datePipe.transform(item.EntryDate, 'yyy-MM-dd'),
      GoalStatus:"Open",
      UserName: this.CurLedgerID,
      CreateDate:currentdate
    };
    this.service.httpPost('/Goals/SetGoals?',paramsExtra)
    .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;
      if (users.errno === 0){
        this.notificationService.success('Submitted successfully');
        this.Commentdetails(item.GoalID)
        this.comment=""
        this.commentvisible=true
        this.getGoalsdetails();
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });

    
  }
  Cancel()
  {
    this.comment=""
  } 
  addItem()
  {
    // console.log("reporting",this.Reportingemployees);
    this.ProfileImage="";
    this.currentuserImage="";
    this.ProfileName="";
    this.Profilevisible=false;
    this.LedgerID=this.CurLedgerID;
    this.assessmentType='Employee';
    this.getGoalsdetails();
  }
  getGoalsdetails()
  {
       let params = {
       cid:this.CID,
       flag:'LOADGRID',
       GoalID:0,
       LedgerID:this.LedgerID,
       FromDate:this.datePipe.transform(this.date.value.startOf('week').toDate(), 'yyy-MM-dd'),
       AssessmentType:this.assessmentType
        }
        const query = `cid=${params.cid}&flag=${params.flag}&GoalID=${params.GoalID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}&AssessmentType=${params.AssessmentType}`;
        this.service.httpGet('/Goals/GetGoals?',query)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

              this.companies= result.respdata.Companylist;
               this.datasource= result.respdata.GoalList;
              
               this.Commentdetails(0)

               this.datasource.forEach(e => {
                this.hideme[e.GoalID] = false;
              });
              
              let newList1 = [];
              let temp
                for (var i = 0; i < result.respdata.EmployeeQuestions.length; i++) 
                {
                  temp={"type":result.respdata.EmployeeQuestions[i].type,"label":result.respdata.EmployeeQuestions[i].label,"options":(JSON.parse(result.respdata.EmployeeQuestions[i].options) )}
                  newList1.push(temp);
                }

              this.form_template=newList1
              let group={}    
              this.form_template.forEach(input_template=>{
                group[input_template.label]=new FormControl('');  
              })
              this.myFormGroup = new FormGroup(group);

                  this.assessmentvisible=true
                  if(result.respdata.ReturnValue[0].NumberofRows!=0)
                  {
                  
                    let group123={} 
                    result.respdata.EmployeeQuestions.forEach(input_template=>{
                      group123[input_template.label]=input_template.Answer;  
                    })
                    this.myFormGroup.setValue(group123);
                    this.assessmentvisible=false
                  }
                  if(this.CurLedgerID==this.LedgerID)
                  {
                    this.Reportingemployees=result.respdata.ReportingEmployees
                    this.groupvisible=result.respdata.GroupLevel[0].Level!==0?true:false;
                  }
                  
                  let newList2 = [];
                  let temp2
            
                for (var i = 0; i < result.respdata.ManagerQuestions.length; i++) 
                {
                  temp2={"type":result.respdata.ManagerQuestions[i].type,"label":result.respdata.ManagerQuestions[i].label,"options":(JSON.parse(result.respdata.ManagerQuestions[i].options) )}
                  newList2.push(temp2);
                }

              this.managerquestions=newList2
              
              let group1={}    
              this.managerquestions.forEach(input_template=>{
                group1[input_template.label]=new FormControl('');  
              })
              this.myFormGroup1 = new FormGroup(group1);

              if(result.respdata.ReturnValue[0].NumberofRows==0)
              {
                let managervalue={} 
                result.respdata.ManagerQuestions.forEach(input_template=>{
                  managervalue[input_template.label]=input_template.Answer;  
                })
                this.myFormGroup1.setValue(managervalue);
                // this.assessmentvisible=false
                
              }

              if(result.respdata.ReturnValue[0].NumberofRows==2)
              {
                console.log("amjath2")
                let managervalue={} 
                result.respdata.ManagerQuestions.forEach(input_template=>{
                  managervalue[input_template.label]=input_template.Answer;  
                })
                this.myFormGroup1.setValue(managervalue);
                this.assessmentvisible=false
                
              }

              this.managervisible=result.respdata.ReturnValue[0].NumberofRows==1? true:false;
              this.getUserList();
          }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }
    refresh()
    {
      this.getGoalsdetails();
    }

}
export interface GroupALL {
  GoalID: string;
  GoalName: string;
}
