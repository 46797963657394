import { Component, OnInit } from '@angular/core';
import { FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, pluck } from 'rxjs/operators';
import {HttpserviceService} from './../../../Services/httpServices/httpservice.service'
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../Services/httpServices/ErrorHandler/app-error';
import { GenericService } from './../../../Services/OtherServices/generic.service';
import { DialogService } from './../../../Services/OtherServices/dialog.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { User } from './../../../Modules/user';

@Component({
  selector: 'app-authorizegroup',
  templateUrl: './authorizegroup.component.html',
  styleUrls: ['./authorizegroup.component.scss'],
})
export class AuthorizegroupComponent implements OnInit {
  SearchData:any;
  SearchData1:any;
  AuthFormGroup:FormGroup;
  cid:any;
  dataSource: any;
  dataSource1: any;
  Companies:any;
  GroupName:any
  grpid:any;
  Flag:any;
  CurLedgerID:any;

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,
    private formBuilder: FormBuilder,private generic: GenericService, 
    private dialogService: DialogService) { 
   
      this.AuthFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required],
        fcFrom:['',Validators.required],
        fcTo:['',Validators.required]
      });       
    }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));   
    this.getddllist();
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.CurLedgerID=curUser.ledgerid;
    this.AuthFormGroup.controls.fcCompany.setValue(this.cid);  
  }

  changesite(e) {
    this.cid=e.value;
   this.getddllist();
  }

  changefrom(e) {

    this.grpid=e.value;
    this.Flag="LOADFROM"
    this.getgroupempname();
  }

  changeto(e) {
    this.grpid=e.value;
    this.Flag="LOADTO"
    this.getgroupempname();
  }

  getgroupempname()
  {
    let params = {
      CID: this.cid,
      Flag: this.Flag,
      From:this.grpid          
    };
    this.service.httpPost('/authgroupmaster/GetAuthGroup',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;          
          if (result.errno === 0) {                
              if(this.Flag==="LOADFROM")   
              {
                  this.SearchData=result.respdata.Table;   
                  this.dataSource=result.respdata.Table;   
                //  console.log('datasource',this.SearchData);
              }
              if(this.Flag==="LOADTO")   
              {              
              //  this.SearchData1=result.respdata.Table;   
                this.dataSource1=result.respdata.Table;   
              }                          
            }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

  getddllist()
  {   
    let params = {
      CID: this.cid,
      Flag: "PAGELOAD",          
    };
    this.service.httpPost('/authgroupmaster/GetAuthGroup',params)
      .subscribe (
        (data) => {
          let result: any;
          result = data;         
          if (result.errno === 0) {       
            this.Companies = result.respdata.Table;      
            this.GroupName=result.respdata.Table1;                          
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }

 
  doFilter(filterValue: string) {            
    const result = this.SearchData.filter(option => option.EmpName.toLowerCase().includes(filterValue));     
    this.dataSource=result;     
  }

  Submit()
  { 
    if (this.AuthFormGroup.invalid) {
      this.NotificationService.warn('Please select mandatory fields');     
      return;
      }   

      let params = {     
        CID: this.AuthFormGroup.get('fcCompany').value,
        Flag: "ADD",          
        UserID:this.CurLedgerID,     
        To:this.AuthFormGroup.get('fcTo').value,
        dtAuth: this.dataSource1,          
        GroupID:JSON.parse(sessionStorage.getItem('groupid'))
      };
      this.service.httpPost('/authgroupmaster/SetAuthGroupMaster',params)
      .subscribe(
        (data) => {
          let result: any;
          result = data;       
          if (result.errno === 0) {
            this.NotificationService.success(result.errdesc);
         //   this.onClear();
            //console.log('Project list: ', result.respdata);
          }
          else {
            this.NotificationService.success(result.errdesc); //':: Problem details');
        //    this.onClear();
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)         
            console.log(error);
          else throw error;       
        });
  }
  onClear()
  {
    this.AuthFormGroup.reset();     
    this.dataSource=[];
    this.dataSource1=[];
    this.AuthFormGroup.controls.fcCompany.setValue(this.cid);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }  

}
