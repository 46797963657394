import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Documentreqvalidate } from '../documentreqvalidate';
import { NotificationService } from './../../../../../app/Services/OtherServices/notification.service';
import { DialogService } from './../../../../../app/Services/OtherServices/dialog.service';
import { AppError } from './../../../../../app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from './../../../../../app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';

@Component({
  selector: 'app-downloaddocrequest',
  templateUrl: './downloaddocrequest.component.html',
  styleUrls: ['./downloaddocrequest.component.scss'],
})
export class DownloaddocrequestComponent implements OnInit {

  datasource:MatTableDataSource<GroupALL>;
  companies:any;
  searchKey: string;
  RequestID:Number=0;
  CID:any;
  
  //displayedColumns =['TaskID','Name','TaskCategory','Desc1','StartDate','DueDate','NotifyFlag','PopUpDays','Status','Action'];
  displayedColumns =['slno','FileName','Action'];
  constructor(public DcValidate: Documentreqvalidate,public notificationService: NotificationService,private service:HttpserviceService,
    public dialogRef: MatDialogRef<DownloaddocrequestComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService) { 
      this.RequestID=this.data.RequestID;
      this.CID=this.data.cid;
    }

  ngOnInit(): void {
    this.getdocumentdetails();
  }

  onCloseDialog()
  {
    this.DcValidate.form.reset();
    this.DcValidate.initializeFormGroup();
    this.dialogRef.close({event: 'CLEAR'});
  }
  onClear()
  {
    this.DcValidate.form.reset();
    this.DcValidate.initializeFormGroup();
  }
  getdocumentdetails()
  {
      // console.log("dddd",this.LedgerID)
       let params = {
       cid:this.CID,
       flag:'DocumentView',
       taskno:this.RequestID
        }
           this.DcValidate.getdocumentcontrol(params)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

              //this.companies= result.respdata.Table;
               this.datasource= result.respdata.Table1;
               this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Table);
              //  this.datasource.sort = this.sort;
              //  this.datasource.paginator = this.paginator;
             }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }

    downloadDocFile(data) {  
      const slno = data.slno; 
      const docname=data.FileName 

      var DocFile = docname.slice(0, -5);  
      //var FileSaver ;//= 'file-saver';
      this.DcValidate.downloadFile(this.CID,'DownloadFile',data.slno).subscribe((data) => {  
        saveAs(data, DocFile)  ;

        console.log('download',data)
        console.log('download123',DocFile)

      });  
  }  

  onDelete(data, _flag)
  {
    this.dialogService.openConfirmDialog('Are you sure to delete this document?')
 .afterClosed().subscribe(res => {
   if (res) {
    const  paramsExtra = {
      cid: this.CID,
      flag: "DELETEFILE",
      RequestID: data.slno,
     // TaskID:data.slno,
      LedgerID: 0,
      UserName: 'admin',
      Comments:'',
      FileName:'',
      FilePath:'',
      Extension:'',
      AliasName:'',
      EmpName:'',
      TaskCategory:'',
      StartDate:'',
      enddate:'',
      Status:'',
      NotifyFlag:'',
      PostingDate:''
    };
    const params = Object.assign(paramsExtra);
    // console.log("save",params);
    this.service.httpPost('/DocumentRequest/SetDocumentRequest',params)
    .subscribe(
      (data) => {
        let users: any;
        users = data; //result.users;
        // console.log('create:', users.respdata);

        if (users.errno === 0){
          // this.DcValidate.form.reset();
          // this.DcValidate.initializeFormGroup();
          this.notificationService.warn('Deleted successfully');
          // this.onClose(this.model);
          this. onCloseDialog();

          
        }
        else{
          this.notificationService.success(users.errdesc); //':: Problem updating user');
          this. onCloseDialog();
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)
          console.log('Error: ', error);
          // this.DcValidate.form.setErrors(error.originalError);
        else throw error;
      });
    }
  });
  }

}
export interface GroupALL {
  Name: string;
}
