import { TimezoneComponent } from './../timezone/timezone.component';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { DatePipe } from '@angular/common';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';

@Component({
  selector: 'app-timezonelist',
  templateUrl: './timezonelist.component.html',
  styleUrls: ['./timezonelist.component.scss'],
})
export class TimezonelistComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  CID:any;
  flag:any;
  companies:any;
  datasource:MatTableDataSource<GroupALL>;

  displayedColumns =['TZID','TimeZone','TimeDifference','CultureInfo','Country','Action'];

  constructor(public service: HttpserviceService,public notificationService: NotificationService,
    private generic: GenericService,
    private datePipe: DatePipe,private dialogService: DialogService,private dialog: MatDialog) 
    { 

    }

  ngOnInit() 
  {
    this.CID=JSON.parse(sessionStorage.getItem('cid'));
    this.flag='PAGELOAD'
       this.Loaddetails();
  }

  doFilter(filterValue: string) {
    // console.log(filterValue);
    this.datasource.filter = filterValue;
  }

  exportAsExcel()
  {
    this.generic.excelExport(this.datasource.data, 'Timezone', 'Timezone' );
  }

  Loaddetails()
     {
       let servicepath;
       let params = {
         cid:this.CID,
         flag:this.flag,
         TimeID:0
          }
          
             this.service.httpPost('/TimeZone/GetTimeZone',params)
             .subscribe(data => {
               let result: any;
               result=data;
               if (result.errno === 0){
                
                 if(this.flag=='PAGELOAD')
                 {
                   this.companies=result.respdata.Companylist;
                   this.datasource=result.respdata.Timezonelist;
                   this.datasource = new MatTableDataSource<GroupALL>(result.respdata.Timezonelist);
                   this.datasource.sort = this.sort;
                   this.datasource.paginator = this.paginator;
    
                 }
                
                  
               }
               else{
                 this.notificationService.success(result.errdesc); //':: Problem details');
               }
             }, 
             (error: AppError) => {
               if (error instanceof BadInput)           
                 console.log(error);
               else throw error;       
             });
     }

  Add()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid:this.CID,
      Flag:'ADD' ,                            
      TZID:'',
      TimeZone:'',
      TimeDifference:'',
      CultureInfo:'',
      Country:'',
      Currencycode:'',
      ExchangeRate:'',
      CurrencyFlag:0   
    }
    const dialogRef = this.dialog.open(TimezoneComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.CID=this.CID;
      this.flag='PAGELOAD'
      this.Loaddetails();

      });
  }
  Edit(event)
     {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {       
        cid:this.CID,
        Flag:'EDIT' ,                            
        TZID:event.TZID,
        TimeZone:event.TimeZone,
        TimeDifference:event.TimeDifference,
        CultureInfo:event.CultureInfo,
        Country:event.Country,
        Currencycode:event.CurrencyCode,
        ExchangeRate:event.ExchangeRate,
        CurrencyFlag:0
      }
      const dialogRef = this.dialog.open(TimezoneComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(result => {
        this.CID=this.CID;
        this.flag='PAGELOAD'
        this.Loaddetails();

        }); 

  }
  Delete(event){
  
    let sdate =Date();
    this.dialogService.openConfirmDialog('Are you sure do you want delete?')
    .afterClosed().subscribe(res => {
     if (res) {
      let params = { 
        cid:this.CID,
      flag: 'DELETE', 
      TimeID:event.TZID,
      timezone:event.TimeZone,
      TimeDifference:event.TimeDifference,
      CultureInfo:event.CultureInfo,
      Country:event.Country, 
      CurCode:event.CurrencyCode,
      ExchangeRate:event.ExchangeRate,
      BaseCurFlag:0,
      UserID:'0',
      GroupID:101
        
       };
       this.service.httpPost('/TimeZone/SetTimeZone',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 1) {
          this.notificationService.warn("Record Deleted Successfully");
          //console.log('Project list: ', result.respdata);
          this.flag='PAGELOAD'
          this.Loaddetails();
        }
        else {
          this.notificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
    }
  });
}

}

export interface GroupALL {
  
  
}