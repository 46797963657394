import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { Inject } from '@angular/core';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/Modules/user';
import * as _ from 'underscore/underscore-min.js';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteActivatedEvent, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DatecontrolComponent } from '../../HR/DateControl/datecontrol/datecontrol.component';
import { DatePipe } from '@angular/common';
import { Session } from 'protractor';
@Component({
  selector: 'app-kanbanboardfilter',
  templateUrl: './kanbanboardfilter.component.html',
  styleUrls: ['./kanbanboardfilter.component.scss'],
})
export class KanbanboardfilterComponent implements OnInit {

  KanbanBoardFilterFormGroup:any=FormGroup;
  Flag:any;
  Companies:any;
  Assignee:any;
  Clients:any;
  Projects:any;
  Status:any;
  Priority:any;
  UserID:any;
  LedgerID:any;
  CID:any;
  allEmployees:any

  DateTypeFlag:any="Today"
  fromdate:any;
  todate:any;
  keycolumn:any;
  AssigneeVisible:boolean=true;
  StatusVisible:boolean=true;
  PiriorityVisible:boolean=true;  
  selectable = true;
  removable = true;
  TextDataFlag:any;

  projects: string[] = [];
  allProjects: any;
  selectable1 = true;
  removable1 = true;

  selectedValue: any ;

  showSpinner:any;
  public filteredList5;
  
  fcEmployee = new FormControl();
  fcProject = new FormControl();
  filteredProjects: Observable<string[]>;
  filteredEmployees: Observable<string[]>;
  autocompleteTagsOptionActivated = false;
  employeelistselected: any = [];
  employees: string[] = [];
  SelectData:any;
  
  @ViewChild('prjInput') prjInput: ElementRef<HTMLInputElement>;
  @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;
  
  tempp:any;
  BoardID:any;

  constructor(
    private service: HttpserviceService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<KanbanboardfilterComponent>,
    public notificationService: NotificationService,    
    private formBuilder: FormBuilder, 
    private dialog:MatDialog,
    private datePipe: DatePipe, 
     @Inject(MAT_DIALOG_DATA) public data: any) {
      this.Flag = this.data.flag;
      this.UserID =  this.data.userid;
      this.LedgerID =  this.data.ledgerid;
      this.CID =  this.data.cid;
      this.keycolumn=this.data.keycolumn;
      this.TextDataFlag=this.data.textdataFlag
      this.BoardID=this.data.BoardID
      //console.log('dailog',)
 
//console.log('checkprio',JSON.parse(localStorage.getItem('Prioritydata')));
      this.KanbanBoardFilterFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required] ,    
        fcEmployee: [''],        
        fcProject: [''],
        fcClient: [''],              
        fcStatus: [''],      
        fcPriority: [''],      
        // fcFromDate: ['', Validators.required],
        // fcToDate: ['', Validators.required]       
    });

    // this.fcProject.valueChanges.subscribe(search => {
    //   this.filteredProjects = of(this.allProjects.filter(item =>
    //   item.toLowerCase().includes(search)
    //   ));
    //   });


    }

  async ngOnInit() {
    
    //console.log('keycolumn',this.keycolumn)
    if(this.keycolumn=='Status')
      this.StatusVisible=false;
    else if(this.keycolumn=='Priority')
      this.PiriorityVisible=false;
    else if(this.keycolumn=='Assignee')
      this.AssigneeVisible=false;

    this.CID=JSON.parse(sessionStorage.getItem('cid')); 
    await this.PageLoad();
   // console.log('this.CID',this.CID)
    this.KanbanBoardFilterFormGroup.controls.fcCompany.setValue(this.CID);
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    if(this.TextDataFlag==1)
      this.FilterSessionDataLoad();
    else 
     this.GetDefault()
    
  }

  FilterSessionDataLoad(){
    console.log('Prioritydata',localStorage.getItem('FilterEmployee'))
    if(this.keycolumn!='Status' && localStorage.getItem('Status')!=null){
      console.log('stat',localStorage.getItem('Status'))
      const tempStatus:any  =JSON.parse(localStorage.getItem('Status'));
      this.KanbanBoardFilterFormGroup.controls.fcStatus.setValue(tempStatus);
    }

    if(this.keycolumn!='Priority' && localStorage.getItem('Prioritydata')!=''){
      const tempPriority:any  =JSON.parse(localStorage.getItem('Prioritydata'));
      this.KanbanBoardFilterFormGroup.controls.fcPriority.setValue(tempPriority);
      //console.log('tempPriority',tempPriority)
    }

    
    const tempProject:any  = JSON.parse(localStorage.getItem('Projectdata'))     
    const tempClient:any  =JSON.parse(localStorage.getItem('Client'));
    const tempEmployee:any  =JSON.parse(localStorage.getItem('FilterEmployee'));

    this.employees=tempEmployee;   
    this.projects=tempProject;  

    if(tempClient!='')
      this.KanbanBoardFilterFormGroup.controls.fcClient.setValue(tempClient); 

  }


   async PageLoad() {
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    let params = {
      CID:this.CID, 
      Flag: "FilterDDLData",
      UserID:curUser.ledgerid  ,         
    };
    this.showSpinner=true;
   let result= await this.service.httpPost('/Kanbanboard/GetKanbanBoardData',params)
      .toPromise().then(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;
          if (result.errno === 0) {       
            this.Companies = curUser.dtsiteid;                       
            this.Assignee = result.respdata.Assignee;
            this.allEmployees = result.respdata.Assignee;            
            this.Projects=result.respdata.Project;
            this.Clients=result.respdata.Client;            
            this.Status=result.respdata.Status;    
            this.Priority=result.respdata.Priority;              
           // this.allProjects= _.pluck(this.Projects, 'ProjectName');              
            this.allProjects=this.Projects;

      //console.log('Priority',this.Priority)

//const tempPriority:any [] =JSON.parse(localStorage.getItem('FilterPriority'));
    //let Fromdate = sessionStorage.getItem("Fromdate");
    //let Todate = sessionStorage.getItem("Todate");
    // console.log('tempAssignee',JSON.parse(tempAssignee));
    // console.log('tempAssignee',JSON.parse(tempProject));
    // console.log('tempAssignee',JSON.parse(tempClient));
    // const tempPriority1:any = [
    //   { EntityID: "3010", Tag:"P1" }
    // ];
    
    // this.KanbanBoardFilterFormGroup.controls.fcClient.setValue(JSON.parse(tempClient))
    // this.KanbanBoardFilterFormGroup.controls.fcEmployee.setValue(JSON.parse(tempAssignee))
    
    // this.SelectData=[tempPriority1];
    // console.log('this.SelectData',this.SelectData)
     //this.KanbanBoardFilterFormGroup.controls.fcPriority.setValue('');
     //console.log('tempAssignee',this.KanbanBoardFilterFormGroup.controls.fcPriority.getItem);

               this.fcEmployee.valueChanges.subscribe(search => {
                this.filteredEmployees = of(this.allEmployees.filter(item =>
                item.Name.toLowerCase().includes(search)
                ));
                });        

                this.fcProject.valueChanges.subscribe(search => {
                  this.filteredProjects = of(this.allProjects.filter(item =>
                  item.ProjectName.toLowerCase().includes(search)
                  ));
                  });

                this.filteredList5= this.Clients.slice();

                console.log('this.employees',this.employees);
               
          }
          else {
            this.notificationService.success(result.errdesc); //':: Problem details');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
  }


  getDefaultValues() {

    const tempPriority1:any = [
      { EntityID: 3010, Tag:"P1" },
      { EntityID: 3011, Tag:"P1" }
    ];
    // In this example, we're setting default values for objects with id 1 and 2
    return [tempPriority1[0], tempPriority1[1]];
  }

  add(event: MatChipInputEvent): void {
    // debugger
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.employeelistselected.push({
        id:Math.random(),
        Name:value.trim()
      });
    }
        // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fcEmployee.setValue(null);
  }

  addprojects(event1: MatChipInputEvent): void {
    const input = event1.input;
    const value = event1.value;
    if ((value || '').trim()) {
      this.projects.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.fcProject.setValue(null);
  }


  removeprojects(prj: string): void {
    const index = this.projects.indexOf(prj);
    if (index >= 0) {
      this.projects.splice(index, 1);
    }
  }


  selectedprojects(event1: MatAutocompleteSelectedEvent): void {
    this.projects.push(event1.option.value);
    //console.log('projects',this.projects)
    this.prjInput.nativeElement.value = '';
    this.fcProject.setValue(null);
  }


  // private _filterprojects(value1: string): string[] {
  //   const filterprojectValue = value1.toLowerCase();

  //   return this.allProjects.filter(value1 => value1.toLowerCase().indexOf(filterprojectValue) === 0);
  // }


  addemployees(event1: MatChipInputEvent): void {
    const input = event1.input;
    const value = event1.value;
    if ((value || '').trim()) {
      this.employees.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.fcEmployee.setValue(null);
  }

  removeemployees(emp: string): void {
    const index = this.employees.indexOf(emp);
    if (index >= 0) {
      this.employees.splice(index, 1);
    }
  }
  optionActivated($event: MatAutocompleteActivatedEvent) {
    if ($event.option) {
      this.autocompleteTagsOptionActivated = true;
    }
  }
  selectedemployees(event1: MatAutocompleteSelectedEvent): void {
    this.employees.push(event1.option.value);
    //console.log('employees',this.employees)
    this.empInput.nativeElement.value = '';
    this.fcEmployee.setValue(null);

  }
  
  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        height:'43%',
        width:'20%'
      }
    const dialogRef = this.dialog.open(DatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      //console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }


  onCloseDialog(){   
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
     .afterClosed().subscribe(res => {
       if (res) {
        // this.service.form.reset();
        // this.service.initializeFormGroup();
        this.dialogRef.close({event: 'CLEAR'});
      }
    });
  }

  async setDefault()
  {
    let ClientID=this.KanbanBoardFilterFormGroup.get('fcClient').value;
    let statusEntity=this.KanbanBoardFilterFormGroup.get('fcStatus').value;
    let pirorityEntity=this.KanbanBoardFilterFormGroup.get('fcPriority').value;

    let setdefault=[{"Status":statusEntity,"Priority":pirorityEntity,"Client":ClientID,"Employee":this.employees,"Project":this.projects,"fromDate":this.fromdate,"ToDate":this.todate}]
    let resultPriority
    let resultStatus
    let resultClient

    if(statusEntity!='' ){
  
      resultStatus = this.Status.filter(data => {
        return statusEntity.some(filter => filter === data.StatusID);
      });
      //localStorage.setItem('Status',JSON.stringify(statusEntity));    
    }
    else{
     // localStorage.setItem('Status',JSON.stringify(['']));   
      resultStatus=[{'EntityID':'','Tag':''}]
    }
    
    if(pirorityEntity!=''){
      
     resultPriority = this.Priority.filter(data => {
      return pirorityEntity.some(filter => filter === data.EntityID);
    });
    //localStorage.setItem('Prioritydata',JSON.stringify(pirorityEntity));   
    }
    else{
      resultPriority=[{'EntityID':'','Tag':''}]
      //localStorage.setItem('Prioritydata',JSON.stringify(['']));
    }
    
    
    if(ClientID!=''){  
      resultClient = this.Clients.filter(data => {
       return ClientID.some(filter => filter === data.ClientID);
     });
     //localStorage.setItem('Client',JSON.stringify(ClientID));   
     }
     else{
      resultClient=[{'ClientID':'','ClientName':''}]
     // localStorage.setItem('Client',JSON.stringify(['']));
     }

     let setdefault1=[{"Status":resultStatus,"Priority":resultPriority,"Client":resultClient,"LedgerID":this.employees,"Project":this.projects,"FromDate":this.fromdate,"ToDate":this.todate}]


   console.log('setdefault',setdefault)


   let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   let params = {
     CID:this.CID, 
     UserID:curUser.userid,   
     FilterDetails:JSON.stringify(setdefault),
     FilterDetails1:JSON.stringify(setdefault1),
     BoardID:this.BoardID   
   };

   console.log('params',params)


   this.showSpinner=true;
  let result= await this.service.httpPost('/Kanbanboard/SetKanbanBoardFilter',params)
     .toPromise().then(
       (data) => {
         this.showSpinner=true;
         setTimeout(() =>{
           this.showSpinner=false;
         },
         800)
         let result: any;
         result = data;
         if (result.errno === 0) {       
           
          this.notificationService.success("success");
         }
         else {
           this.notificationService.success(result.errdesc); //':: Problem details');
         }
       },
       (error: AppError) => {
         if (error instanceof BadInput)           
           console.log(error);
         else throw error;       
       });
  }

  async GetDefault()
  {

    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
   let params = {
     CID:this.CID, 
     UserID:curUser.userid,
     BoardID:this.BoardID 
   };

   console.log('params',params)


   this.showSpinner=true;
  let result= await this.service.httpPost('/Kanbanboard/GetKanbanBoardFilter',params)
     .toPromise().then(
       (data) => {
         this.showSpinner=true;
         setTimeout(() =>{
           this.showSpinner=false;
         },
         800)
         let result: any;
         result = data;

         console.log("result",result)
         if (result.errno === 0) {       
           
          let FilterDetails
         this.tempp =result.respdata.FilterDetails;
        const filterDetailsArray = JSON.parse(result.respdata.FilterDetails[0].FilterDetails);


        console.log('Priority',filterDetailsArray[0].Priority); // Access Status
        this.KanbanBoardFilterFormGroup.controls.fcStatus.setValue(filterDetailsArray[0].Status);
        this.KanbanBoardFilterFormGroup.controls.fcPriority.setValue(filterDetailsArray[0].Priority);

        this.employees=filterDetailsArray[0].Employee;   
        this.projects=filterDetailsArray[0].Project;  


       this.KanbanBoardFilterFormGroup.controls.fcClient.setValue(filterDetailsArray[0].Client); 

       this.fromdate=filterDetailsArray[0].fromDate
       this.todate=filterDetailsArray[0].ToDate

      // this.ApplyFilter()

         }
         else {
           //this.notificationService.success(result.errdesc); //':: Problem details');
         }
       },
       (error: AppError) => {
         if (error instanceof BadInput)           
           console.log(error);
         else throw error;       
       });

  }



  ApplyFilter(){     


let resultPriority
let resultStatus
let resultClient

let ClientID=this.KanbanBoardFilterFormGroup.get('fcClient').value;
let statusEntity=this.KanbanBoardFilterFormGroup.get('fcStatus').value;
let pirorityEntity=this.KanbanBoardFilterFormGroup.get('fcPriority').value;

console.log('temp',ClientID,statusEntity,pirorityEntity)

if(statusEntity!='' ){
  
  resultStatus = this.Status.filter(data => {
    return statusEntity.some(filter => filter === data.StatusID);
  });
  localStorage.setItem('Status',JSON.stringify(statusEntity));    
}
else{
  localStorage.setItem('Status',JSON.stringify(['']));   
  resultStatus=[{'EntityID':'','Tag':''}]
}

if(pirorityEntity!=''){
  
 resultPriority = this.Priority.filter(data => {
  return pirorityEntity.some(filter => filter === data.EntityID);
});
localStorage.setItem('Prioritydata',JSON.stringify(pirorityEntity));   
}
else{
  resultPriority=[{'EntityID':'','Tag':''}]
  localStorage.setItem('Prioritydata',JSON.stringify(['']));
}


if(ClientID!=''){  
  resultClient = this.Clients.filter(data => {
   return ClientID.some(filter => filter === data.ClientID);
 });
 localStorage.setItem('Client',JSON.stringify(ClientID));   
 }
 else{
  resultClient=[{'ClientID':'','ClientName':''}]
  localStorage.setItem('Client',JSON.stringify(['']));
 }




        let params = {  
          LedgerID:this.employees,  
          Project:this.projects,
          Client:resultClient,//this.KanbanBoardFilterFormGroup.get('fcClient').value,      
          Status:resultStatus,//this.KanbanBoardFilterFormGroup.get('fcStatus').value,
          Priority:resultPriority,
          FromDate:this.fromdate,
          ToDate:this.todate,
          DateType:"cs",
          }         
          
          if(this.employees.length>0)
          localStorage.setItem('FilterEmployee',JSON.stringify(this.employees));  
          else
          localStorage.setItem('FilterEmployee',JSON.stringify([]));   


          if(this.projects.length>0)
          localStorage.setItem('Projectdata',JSON.stringify(this.projects));   
          else 
          localStorage.setItem('Projectdata',JSON.stringify([]));   
          
        
          console.log('FilterParam',params)
        this.dialogRef.close(params);
  }

  onClear(){
    this.employees = []; // making the chipList empty
     this.fcEmployee.reset(); // resets the control
     this.fcEmployee.markAsPristine();
     this.fcEmployee.markAsUntouched();
     this.projects = []; // making the chipList empty
     this.fcProject.reset(); // resets the control
     this.fcProject.markAsPristine();
     this.fcProject.markAsUntouched();
    this.KanbanBoardFilterFormGroup.controls.fcClient.setValue('');
    this.KanbanBoardFilterFormGroup.controls.fcPriority.setValue('');
    this.KanbanBoardFilterFormGroup.controls.fcStatus.setValue('');
    
    localStorage.setItem('Projectdata',JSON.stringify([]));   
    localStorage.setItem('FilterEmployee',JSON.stringify([]));   
    localStorage.setItem('Client',JSON.stringify(['']));
    localStorage.setItem('Prioritydata',JSON.stringify(['']));
    localStorage.setItem('Status',JSON.stringify(['']));   

  }

}
