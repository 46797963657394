

import { AfterViewInit, Component, HostListener, Input, ViewChild } from '@angular/core';
import { DxContextMenuComponent, DxDataGridComponent } from 'devextreme-angular';

import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { BadInput } from 'src/app/Services/httpServices/ErrorHandler/bad-input';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { NotificationService } from 'src/app/Services/OtherServices/notification.service';
import { DialogformainformComponent } from '../dialogformainform/dialogformainform.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { DatePipe } from '@angular/common';
import { DatecontrolComponent } from '../../HR/DateControl/datecontrol/datecontrol.component';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { EmiteventserviceService } from 'src/app/Services/OtherServices/emiteventservice.service';
import { Menu } from '../../ITS/Ticket/ticketlist/ticketlist.component';
import { current } from 'devextreme/ui/themes';
import { FlexLayoutModule } from '@angular/flex-layout';
import { User } from 'src/app/Modules/user';
import { UserandgrouppermissionComponent } from '../userandgrouppermission/userandgrouppermission.component';


@Component({
  selector: 'app-devxgrid',
  templateUrl: './devxgrid.component.html',
  styleUrls: ['./devxgrid.component.css']
})
export class DevxgridComponent implements AfterViewInit  {

  @ViewChild('contextMenu', { static: false }) contextMenu: any;
  @ViewChild('contextMenu1', { static: false }) contextMenu1: any;
  @ViewChild('gridContainer1', { static: false }) gridContainer1: DxDataGridComponent;
 @ViewChild('customHeaderTemplate', { static: false }) customHeaderTemplate: any;
 @ViewChild('actionCellTemplate', { static: false }) actionCellTemplate: any;

  menuDetails: any;
  gridLayoutDetails: any[] = [];
  filterDetails: any[] = [];
  selectedLayoutName: string = 'Default';
  selectedFilterName: string = 'Default';
  flag:any;
  flag1:any;
  condition:any='';
  gridloyoutlist:any;
  gridfilterlist:any;
  LayoutName:any;
  DefaultLayout:boolean=false;
  dynamicColumns: any[] = [];
  menuID:any;
   DateTypeFlag:any="Today"
   fromdate:any;
   todate:any;
   Filterstring:any={"field":"city","operator":"=","value":"New York"};
   isDataLoaded = false;
   setDefault:any;
   setDefault1:any;
   showSpinner:any
  
  formatting2 = [];
  groupSummary = [];
  Summary = [];
  formatColumn: string;
  fontCondition: string='';
  backgroundCondition: string='';
  formatStyle: string;

  conditionalFormattingRules: any[] = [];
isContextMenuVisible = false;
  contextMenuPosition = {
    my:"center",
    at:"center",
    of:"#someElement"
  };
  contextMenuPosition1 = {
    my:"left",
    at:"left",
    of:"#target"
  };
  target: any; 
  someElement: any; 
  contextMenuTarget:any;
  
  contextMenuItems: any[] = [
    {
      text: 'Summary',
      icon: 'summarize', // Example icon name
      items: [
        { text: 'Count', action: 'count', icon: 'pin',parent:'Summary' }, // Example icon name
        { text: 'Sum', action: 'sum', icon: 'functions',parent:'Summary' } // Example icon name
      ]
    },
    {
      text: 'Group Summary',
      icon: 'summarize', // Example icon name
      items: [
        { text: 'Count', action: 'count', icon: 'pin',parent:'GroupSummary' }, // Example icon name
        { text: 'Sum', action: 'sum', icon: 'functions',parent:'GroupSummary' } // Example icon name
      ]
    },
    {
      text: 'Formatting',
      icon: 'palette', // Example icon name
      items: [
        {
          text: 'Font Color',
          icon: 'text_format', // Example icon name
          items: [
            {
              template: 'colorPickerTemplateFont',
              action: 'fontColor',
              icon: 'font-color-picker', // Example icon name
              parent:'font-color'
            }
          ]
        },
        {
          text: 'Background Color',
          icon: 'format_paint', // Example icon name
          items: [
            {
              template: 'colorPickerTemplateBackground',
              action: 'backgroundColor',
              icon: 'background-color-picker', // Example icon name
              parent:'background-color'
            }
          ]
        }
      ]
    }
  ];
  

  eventColumn:string;
  selectedColumn: string | null = null;
  selectedColor: string = '#000000';  // Default color
  colorPickerAction: string | null = null;
  menuVisible = false;
  eventPosition: any;
  menuItems = [
    { text: 'Edit',icon: 'edit' },
    { text: 'View',icon: 'visibility' },
    // Add more menu items here
  ];
  selectedRowData: any;
  actioncolumnvisible:boolean=false;
  isFilterRowVisible: boolean = false;
  dynamicHeight: string;
  curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  companies:any= this.curUser.dtsiteid;
  CID:any=JSON.parse(sessionStorage.getItem('cid'));
  userrights:any;
  grouprights:any;

  constructor(private datePipe: DatePipe,private sharedService: GenericService,private dialog: MatDialog,private service:HttpserviceService, private NotificationService:NotificationService, private eventService: EmiteventserviceService) 
  {

  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    // Check if the click was outside the context menu and the target element
    if (this.menuVisible && this.target && !this.target.contains(event.target as Node) && !this.contextMenu1.nativeElement.contains(event.target)) {
      this.menuVisible = false; // Hide the context menu
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setDynamicHeight();
  }
  setDynamicHeight() {
    const windowHeight = window.innerHeight;
    
    this.dynamicHeight = `${windowHeight - 180}px`;
    console.log('height', this.dynamicHeight)
  }

  onToolbarPreparing(e: any) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxCheckBox',
      options: {
          text: 'Show Filter Row',
          value: this.isFilterRowVisible,
          onValueChanged: (args: any) => {
              this.isFilterRowVisible = args.value;
          }
      },
      // Add a custom class to the item
      Class: 'custom-toolbar-item'

  });
  }

  // toggleFilterRow() {
  //   this.isFilterRowVisible = !this.isFilterRowVisible;
  // }
  hideOnOutsideClick(e) {
    return e.target === document.getElementById('someElement');
}
  ngOnInit() {
    this.setDynamicHeight();
    this.fromdate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.todate=this.datePipe.transform(new Date(),"dd-MM-yyyy");
    this.flag='LoadlLayout';
    this.loaddata()
  }
  ngAfterViewInit() {
   
  }
  onGridContentReady(e) {
    if (!this.isDataLoaded) {
      this.isDataLoaded = true;
      this.applyFilter();
    }
  }

  applyFilter() {
    if (this.gridContainer1 && this.gridContainer1.instance) {
      if (this.Filterstring) {
        try {
          let filterString = this.Filterstring;
  
          // If filterString is an object, convert it to a JSON string
          if (typeof filterString === 'object') {
            filterString = JSON.stringify(filterString);
          }
  
          // If filterString is a string, trim it and parse it
          if (typeof filterString === 'string') {
            filterString = filterString.trim();
            const parsedFilter = JSON.parse(filterString);
            this.gridContainer1.instance.filter(parsedFilter);
          } else {
          }
        } catch (e) {
        }
      } else {
        this.gridContainer1.instance.clearFilter(); // Clear any existing filters if Filterstring is empty
      }
    }
  }
  

  updateFilterString(result: any) {
    try {
      const filterString = result.respdata.Table2[0].FilterString;
      if (filterString) {
        JSON.parse(filterString); // Check if it's valid JSON
        this.Filterstring = filterString;
      } else {
      }
    } catch (e) {
    }
  }

  changelayout(e)
  {
    this.flag='';
    this.selectedLayoutName=e;
    this.condition=e;
    this.selectedFilterName='Default'
    // this.loaddata()

  }
  Filterchange(e)
  {
    this.flag='Filter';
    this.selectedFilterName=e;
    this.condition=e;
    this.loaddata()
  }

  Layout(flag_:string)
  {
    if (flag_=='ADD')
    {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid:JSON.parse(sessionStorage.getItem('cid')),
      layoutname:this.selectedLayoutName,
      lablname:"Layout Name"
    }
    const dialogRef = this.dialog.open(DialogformainformComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {      
     if (result.LayoutName!='' && result.LayoutName!='Default' && result.event!='CLEAR' )
     {
      this.LayoutName=result.LayoutName;
      this.DefaultLayout=result.setDefault;
      this.flag1=result.event;

      this.saveGridLayout()
     }
      
    });
    }
    else if(this.LayoutName==='DEFAULT')
      {
        this.LayoutName=this.selectedLayoutName;
        this.DefaultLayout=true;
        this.flag1=flag_;
  
        this.saveGridLayout()
      }
    else
    {
      this.LayoutName=this.selectedLayoutName;
      this.DefaultLayout=this.setDefault;
      this.flag1=flag_;

      this.saveGridLayout()
    }
    
  }
  
saveFilterLauout(flag_:string) {
  const filterState = this.gridContainer1.instance.getCombinedFilter(true);
  if (flag_=='ADD')
    {
      if (filterState) {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus =  true;
        dialogConfig.data = {       
          cid:JSON.parse(sessionStorage.getItem('cid')),
          layoutname:'',
          lablname:"Filter Name"
        }
        const dialogRef = this.dialog.open(DialogformainformComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {     
         if (result.LayoutName!='' && result.LayoutName!='Default' && result.event!='CLEAR' )
         {
          this.LayoutName=result.LayoutName;
          this.DefaultLayout=false;
          this.flag1=result.event;
    
          this.saveGridFilter()
         }
          
        }); 
          
        }
    }
    else if(this.LayoutName==='DEFAULT')
    {
      this.LayoutName=this.selectedFilterName;
      this.DefaultLayout=true;
      this.flag1=flag_;

      this.saveGridFilter()
    }
    else
    {

      this.LayoutName=this.selectedFilterName;
      this.DefaultLayout=this.setDefault1;
      this.flag1=flag_;

      this.saveGridFilter()
    }
 
   
}

saveGridFilter(): void {
  console.log('yyy')
  const filterState = this.gridContainer1.instance.getCombinedFilter(true);
  this.menuID=sessionStorage.getItem('SelectedMenuID')

  if(this.flag1!=='PERMISSION')
    {
      this.userrights=[{"Name":this.curUser.username}]
    }

  let params = {
    _CID: JSON.parse(sessionStorage.getItem('cid')),
    _MenuID:this.menuID,
    _Flag:this.flag1,
    _FilterName:this.LayoutName,
    _FilterString:JSON.stringify(filterState),
    _DefaultLayout:this.DefaultLayout,
    _UserRights:this.userrights==undefined?"''":JSON.stringify(this.userrights),
    _GroupRights:this.grouprights==undefined?"''":JSON.stringify(this.grouprights),
    _UpdatedBy:this.curUser.username,
   // _UpdatedDate:new Date(),
  
      }
      this.service.httpPost('/MainForm/UpdateFilterString?',params)
      .subscribe(
        (data) => {
          let users: any;
          users = data; //result.users;
          if (users.errno === 0){
            this.NotificationService.success('Submitted successfully');
            if (this.flag1=='ADD')
            {
              this.flag='LoadlLayout'
              this.loaddata();
            }
            
          }
          else{
            this.NotificationService.success(users.errdesc); //':: Problem updating user');
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });
}

captureGridState() {


  const state = this.gridContainer1.instance.state();

  const filteredColumns ={'columns':state.columns?.filter(column => column.name !== 'Action')} ;

  const gridState = {
    grid:filteredColumns,
    formatting:this.formatting2,
    groupSummary:this.groupSummary,
    Summary:this.Summary
  };
  return gridState;
}

  saveGridLayout(): void {
    //const masterLayout =  JSON.stringify(this.gridContainer1.instance.state());
    const masterLayout =JSON.stringify( this.captureGridState());
    this.menuID=sessionStorage.getItem('SelectedMenuID')

    if(this.flag1!=='PERMISSION')
    {
      this.userrights=[{"Name":this.curUser.username}]
    }
     
  
    let params = {
      _CID: JSON.parse(sessionStorage.getItem('cid')),
      _MenuID:this.menuID,
      _Flag:this.flag1,
      _GridLayoutName:this.LayoutName,
      _MasterGridLayoutXML:masterLayout,
      _ChildGridLayoutXML:masterLayout,
      _DefaultLayout:this.DefaultLayout,
      _UserRights:this.userrights==undefined?"''":JSON.stringify(this.userrights),
      _GroupRights:this.grouprights==undefined?"''":JSON.stringify(this.grouprights),
      _UpdatedBy:this.curUser.username,
     // _UpdatedDate:new Date(),
    
        }  
        this.service.httpPost('/MainForm/UpdateGridLayout?',params)
        .subscribe(
          (data) => {
            let users: any;
            users = data; //result.users;
            if (users.errno === 0){
              this.NotificationService.success('Submitted successfully');
        

              this.flag='LoadlLayout'
              this.loaddata();
            }
            else{
              this.NotificationService.success(users.errdesc); //':: Problem updating user');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });
  }

  loadGridLayout(): void {
   const tem= sessionStorage.getItem('gridlayout');
   this.gridContainer1.instance.state(JSON.parse(tem));
  
  }
  refresh()
  {
    this.flag='';
    this.loaddata()
  }

  DatePicker()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
     dialogConfig.data = {     
        DateTypeFlag: this.DateTypeFlag,
        height:'43%',
        width:'20%'
      }
    const dialogRef = this.dialog.open(DatecontrolComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {           
      console.log('RESULT',result)   
      this.fromdate=result.frmdate;
      this.todate=result.todate;
      this.DateTypeFlag=result.datetype
    });
  }
  
  inferDataType(value: any): string {
    if (value === null || value === undefined) return 'unknown';
  
    // Handle numbers explicitly
    if (typeof value === 'number' || (!isNaN(value) && typeof value !== 'string')) return 'number';
  
    // Handle strings that look like numbers
    if (typeof value === 'string') {
        if (!isNaN(Number(value)) && value.trim() !== '') return 'number';  // Handle numeric strings
  
         // Check if the string is a valid ISO date format
        const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3}Z)?$/;
        if (isoDatePattern.test(value)) return 'date';
  
        // Additional simple check for common date formats
        const commonDatePattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
        if (commonDatePattern.test(value)) return 'date';
  
        return 'string';  // Default to string if no other match
    }
  
    if (typeof value === 'boolean') return 'boolean';
  
    return 'unknown';
  }

onGridInitialized(e) {
  // Optional: You can use this if you need to perform additional actions when the grid is initialized
  console.log('Grid initialized', e);
}
async  loaddata()
  {
    this.showSpinner=true;

    this.menuID=sessionStorage.getItem('SelectedMenuID')

    let params = {
      _CID: JSON.parse(sessionStorage.getItem('cid')),
      _MenuID:this.menuID,
      _Flag:this.flag,
      _Condition:this.condition,
      FromDate:this.fromdate ,
      ToDate:this.todate,
      UserName:this.curUser.username,
      GroupID:this.curUser.groupid,
      _FilterName:this.selectedFilterName

        }

        console.log('params',params)

       let t=await this.service.httpPost('/MainForm/GetGridLayout?',params)
        .toPromise().then(
          (data) => {
            let result: any;
            result=data;

            this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
    
            if (result.errno === 0){

              this.Filterstring=''
              console.log('Filterstring',result.respdata)

              if (this.flag=='LoadlLayout')
              {

                //console.log('data',result.respdata.Table3[0].FilterName)
                this.gridloyoutlist =result.respdata.Table.length>0 ? result.respdata.Table:'';
                this.selectedLayoutName= result.respdata.Table1.length>0 ? result.respdata.Table1[0].GridLayOutName:'Default';
                this.gridfilterlist = result.respdata.Table2.length>0 ? result.respdata.Table2:''; 
                this.selectedFilterName = result.respdata.Table3.length>0 ? result.respdata.Table3[0].FilterName:'Default';
                this.condition= this.selectedLayoutName
              }
              else if(this.flag=='Filter')
              {
                if (result.respdata.Table.length>0)
                {
                  this.gridContainer1.instance.filter(JSON.parse(result.respdata.Table[0].FilterString))
                  this.Filterstring='Filter : '+ result.respdata.Table[0].FilterString
                }
                else
                {
                  this.gridContainer1.instance.filter('')
                  this.Filterstring=''
                  console.log('Filterstring',this.Filterstring)
                }
          

              }
              else
              {
               
                this.menuDetails =result.respdata.Table;

                if(this.menuDetails.length>0)
                {
                  this.actioncolumnvisible=true;
                }

                this.dynamicColumns=[];
                this.groupSummary=[];
                this.formatting2=[];
                this.Summary=[];
                if (result.respdata.Table1.length>0)
                  {
                    const parsedGridState = JSON.parse(result.respdata.Table1[0].MasterGridLayOutXML);

                    console.log('parsedGridState',parsedGridState)
                    
                  const layoutData = parsedGridState.grid;
                   this.groupSummary= parsedGridState.groupSummary;
                   this.formatting2= parsedGridState.formatting;
                   this.Summary= parsedGridState.Summary;
                    // const layoutData = JSON.parse(result.respdata.Table1[0].MasterGridLayOutXML);
                    layoutData.columns.forEach(column => {
                      const dataField = column.dataField;
                      const visible = column.visible;
                      const visibleIndex = column.visibleIndex;
                      const groupIndex = column.groupIndex;
                      const width= column.width;
                      const dataType= column.dataType 
    
                      this.dynamicColumns.push({'field':dataField,'caption':dataField,'visible':visible,'groupIndex':groupIndex,'width':width,'dataType':dataType})
                      
                      //console.log(`Column: ${dataField}, Visible: ${visible}, Index: ${visibleIndex}`);
                    });

                  }
                  else{

                    
                    this.dynamicColumns = Object.keys(result.respdata.Table[0]).map(key => {
                      // Infer the data type based on the sample row
                      const dataType = this.inferDataType(result.respdata.Table[0][key]);
                  
                      return {
                          field: key,
                          caption: key, // Customize captions if needed
                          dataType: dataType // Add data type
                      };
                  });
              
                }
                
                if (result.respdata.Table1.length>0)
                {
                  const layoutData = JSON.parse(result.respdata.Table1[0].MasterGridLayOutXML);
                   this.applyGridLayout(layoutData);
                }
                else
                {
                  this.gridContainer1.instance.state('')
                }
                this.gridContainer1.instance.filter('')

                if (result.respdata.Table2.length>0)
                  {
                    this.selectedFilterName =this.condition=='Default'?'':result.respdata.Table2[0].FilterName;

                    this.Filterstring='Filter : '+ result.respdata.Table2[0].FilterString

                    this.updateFilterString(result);
                    setTimeout(() => {
                      this.applyFilter(); // Apply the filter after updating the filter string
                    }, 0); // Delaying execution slightly to ensure everything is ready
                  }
                  else
                  {
                    this.gridContainer1.instance.filter('')
                    this.Filterstring=''
                  }
                
              }
              
            }
            else{
              this.NotificationService.success(result.errdesc); //':: Problem updating user');
            }
          },
          (error: AppError) => {
            if (error instanceof BadInput)           
              console.log(error);
            else throw error;       
          });

  }

  applyGridLayout(layoutData: any) {
    if (this.gridContainer1) {
    //  console.log('data1');
      this.gridContainer1.instance.state(layoutData);
    }
  }
  

  addGroupColumn(columnField: string) {
    const column = this.dynamicColumns.find(col => col.dataField === columnField);
    if (column) {
      column.groupIndex = this.dynamicColumns.filter(col => col.groupIndex !== undefined).length;
      this.gridContainer1.instance.clearGrouping();
      this.dynamicColumns.forEach((col, index) => {
        if (col.groupIndex !== undefined) {
          this.gridContainer1.instance.columnOption(col.dataField, 'groupIndex', col.groupIndex);
        }
      });
    }
  }

  removeGroupColumn(columnField: string) {
    const column = this.dynamicColumns.find(col => col.dataField === columnField);
    if (column && column.groupIndex !== undefined) {
      delete column.groupIndex;
      this.gridContainer1.instance.clearGrouping();
      this.dynamicColumns.forEach((col, index) => {
        if (col.groupIndex !== undefined) {
          this.gridContainer1.instance.columnOption(col.dataField, 'groupIndex', col.groupIndex);
        }
      });
    }
  }

  async applySummary(columnField: string, summaryType: string,parent:string) {

    if (parent=='GroupSummary')
    {
      // Find if a summary for this column and type already exists
    const existingSummary = await this.groupSummary.find(summary => summary.column === columnField && summary.summaryType === summaryType);

    // If no existing summary is found, add the new one
    if (!existingSummary) {
      this.groupSummary.push({ column: columnField, summaryType: summaryType });
    }

    }
    else  if (parent=='Summary')
    {
      const existingSummary = await this.Summary.find(summary => summary.column === columnField && summary.summaryType === summaryType);

      // If no existing summary is found, add the new one
      if (!existingSummary) {
        this.Summary.push({ column: columnField, summaryType: summaryType });
      }
  
    }
    
    // Refresh the grid to apply the summary
    this.gridContainer1.instance.refresh();
}



  addConditionalFormatting() {
   // console.log('formating')
    if (this.formatColumn && this.backgroundCondition && this.formatStyle) {
      this.formatting2.push({
        column: this.formatColumn,
        condition: this.backgroundCondition,
        style: this.formatStyle
      });
    }
    //console.log('formating1',this.conditionalFormattingRules)
    this.gridContainer1.instance.refresh();
  }

  applyFormatting(e) {
    let styles = '';
  
    this.formatting2.forEach(f => {
      if (e.column.dataField === f.column && e.value != null) {
        // Check if the condition is a simple equality or an expression
        if (f.condition && this.evaluateCondition(f.condition, e.value)) {
          styles += `${f.style};`;
        }
      }
    });
  
    if (styles) {
      e.cellElement.style.cssText += styles;
    }
  }
  
  evaluateCondition(condition: string, value: any): boolean {
    try {
      const valueString = value.toString().trim();
  
      // Handle exact match condition
      if (condition === valueString) {
        return true;
      }
      
      // Handle numeric comparisons if the condition includes comparison operators
      const numericCondition = condition.match(/^([<>]=?)\s*(\d+)$/);
      if (numericCondition) {
        const operator = numericCondition[1];
        const number = parseFloat(numericCondition[2]);
  
        switch (operator) {
          case '>':
            return parseFloat(valueString) > number;
          case '<':
            return parseFloat(valueString) < number;
          case '>=':
            return parseFloat(valueString) >= number;
          case '<=':
            return parseFloat(valueString) <= number;
          case '=':
            return parseFloat(valueString) === number;
          default:
            return false;
        }
      }
  
      // Handle boolean condition (e.g., 'true' or 'false')
      if (condition.toLowerCase() === 'true' || condition.toLowerCase() === 'false') {
        return valueString.toLowerCase() === condition.toLowerCase();
      }
  
      // If the condition is not a recognized pattern, return false
      return false;
    } catch (e) {
      console.error('Error evaluating condition:', condition, e);
      return false;
    }
  }
  
  
     onCellPrepared(e) {
      
      this.applyFormatting(e);
    }


  // getAverage(field: string): string {
  //   const values = this.menuDetails.map(item => item[field]);
  //   const sum = values.reduce((acc, val) => acc + val, 0);
  //   return (sum / values.length).toFixed(2);
  // }

  // getCount(field: string): number {
  //   return this.menuDetails.length;
  // }

  // getSum(field: string): number {
  //   const values = this.menuDetails.map(item => item[field]);
  //   return values.reduce((acc, val) => acc + val, 0);
  // }

  // groupByColumn(field: string) {
  //   const column = this.dynamicColumns.find(col => col.field === field);
  //   if (column) {
  //     column.groupIndex = 0;
  //   }
  // }

  onIconClick(field: any, event: MouseEvent) {
    this.someElement= event.currentTarget;
    this.isContextMenuVisible = true
    event.stopPropagation();  // Prevents other event handlers from being triggered
    this.eventColumn=field
    this.contextMenu.instance.show();  // Show the context menu without arguments
  }

  onMenuItemClick2(value)
  {
    console.log('onMenuItemClick2',value)
  }
  onMenuItemClick(event: any) {
    const item = event.itemData;
    const columnField = item.field;
    const summaryType = item.action;

  
    switch (summaryType) {
      case 'count':
        this.applySummary(this.eventColumn, 'count',item.parent );
        break;
      case 'average':
        this.applySummary(this.eventColumn, 'average',item.parent );
        break;
      case 'sum':
        this.applySummary(this.eventColumn, 'sum',item.parent );
        break;
    }
  }

    onColorChange(condition: string,event: any) {
      const color = event.color.hex || '#000000';
      if (this.eventColumn) {
        if (condition === 'fontColor') {
          this.applyFontColor(this.eventColumn, color);
        } else if (condition === 'backgroundColor') {
          this.applyBackgroundColor(this.eventColumn, color);
          console.log('backgroundColor',this.eventColumn,color)
        }
      }
    }

    
    

    onConditionChange(condition: string, type: string) {
      // Handle condition input change
      if (type === 'font') {
        this.fontCondition = condition;
      } else if (type === 'background') {
        this.backgroundCondition = condition;
      }
      // Apply condition change logic here
    }

    applyFontColor(columnField: string, color: string) {
      if (columnField && this.fontCondition && color) {
        // Check if a font color setting already exists for the specified column
        const existingFontFormattingIndex = this.formatting2.findIndex(
          format => format.column === columnField &&format.condition === this.backgroundCondition && format.style.startsWith('color:')
        );
    
        if (existingFontFormattingIndex !== -1) {
          // If it exists, remove it first
          this.formatting2.splice(existingFontFormattingIndex, 1);
        }
    
        // Then add the new font color formatting rule
        this.formatting2.push({
          column: columnField,
          condition: this.fontCondition,
          style: 'color:' + color
        });
      }
  
      // Hide the context menu and refresh the grid to apply the formatting changes
      this.isContextMenuVisible = false;
      this.gridContainer1.instance.refresh();
    }
    

    applyBackgroundColor(columnField: string, color: string) {
      if (columnField && this.backgroundCondition && color) {
        // Check if a background color setting already exists for the specified column
        const existingFormattingIndex = this.formatting2.findIndex(
          format => format.column === columnField &&format.condition === this.backgroundCondition && format.style.startsWith('background-color:')
        );
    
        if (existingFormattingIndex !== -1) {
          // If it exists, remove it first
          this.formatting2.splice(existingFormattingIndex, 1);
        }
    
        // Then add the new formatting rule
        this.formatting2.push({
          column: columnField,
          condition: this.backgroundCondition,
          style: 'background-color:' + color
        });
      }

      this.gridContainer1.instance.refresh();
    }
    

    stopPropagation(event: Event) {
      event.stopPropagation();
    }
 
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
  
      const conditionalFormattingRules = this.formatting2;
  
      exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          customizeCell: ({ gridCell, excelCell }) => {
              if (gridCell && excelCell) {
                  // Ensure gridCell has the expected structure
                  if (gridCell.rowType === "data" && gridCell.column) {
                      const dataField = gridCell.column?.dataField;
                      const cellValue = gridCell.data?.[dataField];
  
                      // Set default font color to black
                      excelCell.font = {
                          color: { argb: 'FF000000' } // Default to black
                      };
  
                      // Apply conditional formatting
                      conditionalFormattingRules.forEach(rule => {
                          if (rule.column === dataField && cellValue?.toString() === rule.condition?.toString()) {
                              // Apply background color if specified
                              if (rule.style.includes('background-color')) {
                                  const bgColorMatch = rule.style.match(/background-color:\s*(#[0-9a-fA-F]{6})/i);
                                  if (bgColorMatch) {
                                      excelCell.fill = {
                                          type: 'pattern',
                                          pattern: 'solid',
                                          fgColor: { argb: bgColorMatch[1].replace('#', 'FF') }
                                      };
                                  }
                              }
  
                              // Apply font color if specified
                              if (/color:\s*#[0-9a-fA-F]{6}/i.test(rule.style) && !/background-color:/i.test(rule.style)) {
                                  const fontColorMatch = rule.style.match(/color:\s*(#[0-9a-fA-F]{6})/i);
                                  if (fontColorMatch) {
                                      excelCell.font = {
                                          color: { argb: fontColorMatch[1].replace('#', 'FF') }
                                      };
                                  }
                              }
                          }
                      });
                  }
  
                  // Handle group rows and group footers
                  if (gridCell.rowType === "groupFooter" || gridCell.rowType === "group") {
                      const groupField = gridCell.column?.dataField;
                      const groupValue = gridCell.value;
  
                      conditionalFormattingRules.forEach(rule => {
                          if (rule.column === groupField && groupValue?.toString() === rule.condition?.toString()) {
                              const bgColorMatch = rule.style.match(/background-color:\s*(#[0-9a-fA-F]{6})/i);
                              if (bgColorMatch) {
                                // Ensure row reference is correct and apply color to all cells
                                //const row = worksheet.getRow(excelCell.row);
                                const columnCount = worksheet.columnCount;
                               // console.log(`Applying color to row ${columnCount}`); // Debugging output
                              for (let col = 1; col <= columnCount; col++) { // Columns A to H (1 to 8)
                                const cell = worksheet.getCell(excelCell.row, col); // Row 1, columns 1 to 8
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: bgColorMatch[1].replace('#', 'FF') }
                                };
                            }
                            }


                              if (/color:\s*#[0-9a-fA-F]{6}/i.test(rule.style) && !/background-color:/i.test(rule.style)) {
                                  const fontColorMatch = rule.style.match(/color:\s*(#[0-9a-fA-F]{6})/i);
                              if (fontColorMatch) {
                                // Ensure row reference is correct and apply color to all cells
                                //const row = worksheet.getRow(excelCell.row);
                                const columnCount = worksheet.columnCount;
                               // console.log(`Applying color to row ${columnCount}`); // Debugging output
                              for (let col = 1; col <= columnCount; col++) { // Columns A to H (1 to 8)
                                const cell = worksheet.getCell(excelCell.row, col); // Row 1, columns 1 to 8
                                cell.font = {
                                    color: { argb: fontColorMatch[1].replace('#', 'FF') }
                                };
                            }
                            }

                          }
                        }
                      });
                  }
              } else {
                  console.error('gridCell or excelCell is undefined');
              }
          }
      }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
          });
      });
  
      e.cancel = true; // Prevent the default export behavior
  }
  
  

  onActionClick(event: any, cellData: any) {
    this.target = event.currentTarget;
    console.log('yyy',cellData)
    this.eventPosition = event.element;
    this.menuVisible = true;
    this.selectedRowData=cellData;
  }

  onMenuHiding() {
    this.menuVisible = false;
  }

   findMenuId = (menu, targetId) => {
    for (const item of menu) {
        if (item.menuid === targetId) {
            return item;
        }
        if (item.children) {
            const found = this.findMenuId(item.children, targetId);
            if (found) {
                return found;
            }
        }
    }
    return null;  // Return null if not found
};

  onMenuItemClick1(event: any, cellData: any) {
    const clickedItem = event.itemData.text;
    console.log('Menu item clicked:', clickedItem, 'for', this.selectedRowData);
    const menulist=JSON.parse(sessionStorage.getItem('menulist'));
    const result = this.findMenuId(menulist, this.selectedRowData.data.MenuID);

    const PassingData = {
      rowValue:this.selectedRowData.data,
      flag:clickedItem,
      otherForm:true
     
    };

    
    sessionStorage.setItem('ManinformrowData',JSON.stringify(PassingData));
    
    if(this.selectedRowData.data.MenuID=='STS_134')  {
      sessionStorage.setItem('activityTicketID', this.selectedRowData.data.TicketID);
      sessionStorage.setItem('TaskSubject', this.selectedRowData.data.Subject);
      sessionStorage.setItem('TemplateID',  this.selectedRowData.data.TemplateID);
      sessionStorage.setItem('ActivityID',  this.selectedRowData.data.ActiviteID);
      const result= { "name": this.selectedRowData.data.TicketID, "type": "FORM", "icon": "arrow_right", "menuid": "STS_134", "parentid": "54", "visible": true };
      this.eventService.emitMenuSelect(result);
    }      
    else{
      const result= this.findMenuId(menulist, this.selectedRowData.data.MenuID);
      this.eventService.emitMenuSelect(result);
    }

    this.menuVisible = false; // Hide menu after selection
  }

  onFocusedRowChanged(e) {  
    //sessionStorage.setItem('TicketID', e.data.TicketNo);

    const PassingData = {
      rowValue:e.data,
      flag:'View',
      otherForm:true
     
    };

    sessionStorage.setItem('ManinformrowData',JSON.stringify(PassingData));
   
    //console.log('resul',e.data.TicketID,' + ',e.data.TicketID)
    const menulist=JSON.parse(sessionStorage.getItem('menulist'));
    

    if(e.data.MenuID=='STS_134')  {
      sessionStorage.setItem('activityTicketID', e.data.TicketID);
      sessionStorage.setItem('TaskSubject', e.data.Subject);
      sessionStorage.setItem('TemplateID',  e.data.TemplateID);
      sessionStorage.setItem('ActivityID',  e.data.ActiviteID);
      const result= { "name": e.data.TicketID, "type": "FORM", "icon": "arrow_right", "menuid": "STS_134", "parentid": "54", "visible": true };
      this.eventService.emitMenuSelect(result);
    }      
    else{
      const result= this.findMenuId(menulist, e.data.MenuID);
      this.eventService.emitMenuSelect(result);
    }
      
    //console.log('result',result)
    

    // let item: any = { "name": "Ticket Manager", "type": "FORM", "icon": "arrow_right", "menuid": "STS_203", "parentid": "54", "visible": true };
    // this.eventService.emitMenuSelect(item);
       
  }
  Permission(val)
  {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      cid:JSON.parse(sessionStorage.getItem('cid')),
      userrights:this.userrights,
      grouprights:this.grouprights,
      LayoutName:val=='Grid'? this.selectedLayoutName:this.selectedFilterName,
      flag:val,
      MenuID:this.menuID
    }
    const dialogRef = this.dialog.open(UserandgrouppermissionComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {      
     if (result.LayoutName!='' && result.LayoutName!='Default' && result.event!='CLEAR' )
     {
      console.log('permission',result)

      this.LayoutName=result.LayoutName;
      this.DefaultLayout=false;
      this.flag1='PERMISSION';
      this.userrights= result.userrights== undefined || result.userrights==""?"":result.userrights.map(role => ({ Name: role }));
      this.grouprights= result.grouprights== undefined || result.grouprights==""?"":result.grouprights.map(role => ({ Name: role }));

//       const formattedRoles = result.userrights.map(role => ({ Name: role }));

// console.log('permission3',formattedRoles);
       if (val=='Grid')
       {
        this.saveGridLayout()
       }
       else if(val=='Filter')
       {
        this.saveGridFilter()

       }
       
     }
      
    });
  }

  shouldApplyHeaderFilter(column: any): boolean {
    // Example condition: Disable header filter for columns with the field 'noFilterField'
    return column.field !== 'Action';
  }
  
  }

