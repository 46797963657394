import { DialogService } from 'src/app/Services/OtherServices/dialog.service';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { MatTableDataSource } from '@angular/material/table';
// import { MatDialogRef, MAT_DIALOG_DATA, MatInputModule, MatFormFieldModule, MatAutocomplete } from '@angular/material';
import { Inject } from '@angular/core';
import * as _ from 'underscore/underscore-min.js';
import { map, startWith, pluck } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-trainingacess',
  templateUrl: './trainingacess.component.html',
  styleUrls: ['./trainingacess.component.css']
})
export class TrainingacessComponent implements OnInit {

  emplist:any;
  selectedOptions: string[];
  lastSelectedOptions: string[];
  TID:any
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fcEmployee = new FormControl();
  filteredEmployees: Observable<string[]>;
  employees: string[] = [];
  allEmployees: string[] = [];
  selectable = true;
  removable = true;
  CID:any;

  @ViewChild('empInput') empInput: ElementRef<HTMLInputElement>;
  // @ViewChild('auto') matAutocomplete: MatAutocomplete;
  
  constructor(private datePipe: DatePipe,public service: HttpserviceService,public notificationService: NotificationService, public dialogRef: MatDialogRef<TrainingacessComponent>,
    private dialogService: DialogService,@Inject(MAT_DIALOG_DATA) public data: any) 
  { 
  this.TID=data.TID;
  this.CID=data.cid

  this.filteredEmployees = this.fcEmployee.valueChanges.pipe(
    startWith(null),
    map((value: string | null) => value ? this._filteremployees(value) : this.allEmployees.slice()));
  }

  addemployees(event1: MatChipInputEvent): void {
    const input = event1.input;
    const value = event1.value;
    if ((value || '').trim()) {
      this.employees.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.fcEmployee.setValue(null);
  }

  removeemployees(emp: string): void {
    const index = this.employees.indexOf(emp);
    if (index >= 0) {
      this.employees.splice(index, 1);
    }
  }

   selectedemployees(event1: MatAutocompleteSelectedEvent): void {
    this.employees.push(event1.option.value);
    this.empInput.nativeElement.value = '';
    this.fcEmployee.setValue(null);
  }

  private _filteremployees(value1: string): string[] {
    const filterprojectValue = value1.toLowerCase();

    return this.allEmployees.filter(value1 => value1.toLowerCase().indexOf(filterprojectValue) === 0);
  }


  ngOnInit(): void {
    this.dialogRef.updateSize('70%');
    this.getGoalsdetails();
  }
  onCloseDialog()
  {
    this.dialogService.openConfirmDialog('Are you sure do you want to close this?')
    .afterClosed().subscribe(res => {
      if (res) {
    this.dialogRef.close({event: 'CLEAR'});
      }
    });
  }
  onClose(model){
    this.dialogRef.close({event: 'CLEAR', data: model});
  }
  
  onSubmit()
  {
    let  paramsExtra;
   let currentdate = new Date();
    paramsExtra = {
      cid: this.CID,
      flag:"ADD",
      TID:this.TID,
      emplist:this.employees
  
    };
    this.service.httpPost('/Training/SetTrainingAccessGroup?',paramsExtra)
  .subscribe(
    (data) => {
      let users: any;
      users = data; //result.users;

      if (users.errno === 0){
        this.notificationService.success('Submitted successfully');
  
      }
      else{
        this.notificationService.success(users.errdesc); //':: Problem updating user');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)           
        console.log(error);
      else throw error;       
    });
  }
getGoalsdetails()
  {
       let params = {
       cid:this.CID,
       flag:'Emplist',
       TID:this.TID,
       LedgerID:0,
       FromDate:this.datePipe.transform(new Date(), 'yyy-MM-dd')
        }
        const query = `cid=${params.cid}&flag=${params.flag}&TID=${params.TID}&LedgerID=${params.LedgerID}&FromDate=${params.FromDate}`;
        this.service.httpGet('/Training/GetTraining?',query)
           .subscribe(data => {
             let result: any;
             result=data;
             if (result.errno === 0){

              this.emplist= result.respdata.Employeelist;
             this.employees= _.pluck(result.respdata.selectedEmployee, 'Name'); 
              
              this.allEmployees= _.pluck(this.emplist, 'Name');      
 
             }
             else{
               this.notificationService.success(result.errdesc); //':: Problem details');
             }
           }, 
           (error: AppError) => {
             if (error instanceof BadInput)           
               console.log(error);
             else throw error;       
           });
    }
    
}
