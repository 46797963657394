import { GenericService } from './../../../../Services/OtherServices/generic.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeeHierarchyComponent } from './../employee-hierarchy/employee-hierarchy.component';
import { ENTER, COMMA, I } from '@angular/cdk/keycodes';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';
import { NotificationService } from './../../../../Services/OtherServices/notification.service';
import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { FormControl } from '@angular/forms';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {Observable} from 'rxjs';
import {startWith, map, pluck} from 'rxjs/operators';
import * as _ from 'underscore/underscore-min.js';
import { HierarchyexcelimportComponent } from '../hierarchyexcelimport/hierarchyexcelimport.component';
import { MatListOption } from '@angular/material/list';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DialogService } from './../../../../Services/OtherServices/dialog.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EmpprojectlistComponent } from './../empprojectlist/empprojectlist.component';

interface  EmployeeHierarchy {
  ParentID: number;
  LedgerID: number;
  Level: number;
  name: string;
  leveldesc: string;
  children?: EmployeeHierarchy[];
}

interface Project {
  ID: number;
  ProjectName: string;
}

interface Employee {
  LedgerID: number;
  name: string;
}

interface Level {
  id: number;
  name: string;
}

@Component({
  selector: 'app-employee-hierarchy-list',
  templateUrl: './employee-hierarchy-list.component.html',
  styleUrls: ['./employee-hierarchy-list.component.css']
})
export class EmployeeHierarchyListComponent implements OnInit {
  hirarchyMain: MatTableDataSource<HierarchyMain>;
  displayedColumns = ['approvergroupid', 'typeid','ProjectName', 'approvegroupname', 'Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  searchKey: string;

  flag = 'ADD';
  addbtnvisible = true;
  editbtnvisible = true;
  deletebtnvisible = true;
  viewbtnvisible = true;
  public filteredList5

  approvergroupid = 0;
  projectid: number;
  projectname: string;
  approvegroupname: string;
  level: Level[];
  employeselect: Employee[];
  employeelistselected: any = [];

  project: Project[];
  employeeOriginal: Employee[];
  employee: Employee[];
  employeelist: any;

  //dx-selector attribute
  searchModeOption = 'contains';
  searchExprOption: any = 'ProjectName';
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;

  treeControl = new NestedTreeControl<EmployeeHierarchy>(node => node.children);
  dataSource = new MatTreeNestedDataSource<EmployeeHierarchy>();
  
  treedeletevisible:boolean=false;

  model: any;
  row: any;
  activeNode:any;
  
  emplevel: Level[] = [
    {id: 1, name: 'L1'},
    {id: 2, name: 'L2'},
    {id: 3, name: 'L3'},
    {id: 4, name: 'L4'},
    {id: 5, name: 'L5'},
    {id: 6, name: 'L6'},
    {id: 7, name: 'L7'},
    {id: 8, name: 'L8'},
    {id: 9, name: 'L9'},
    {id: 10, name: 'L10'}

  ];

  projectids: string[]= [];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  
  constructor(public service: HttpserviceService,
              public notificationService: NotificationService,
              private dialog: MatDialog,
              private generic: GenericService,
              private dialogService: DialogService
              // public dialogRef: MatDialogRef<EmployeeHierarchyComponent>,
              ){

    // this.flag = this.data.flag;
    // this.row = this.data.row;

    // this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));

      // console.log("dsdf",this.allFruits)
    
  }

  hasChild = (_: number, node: EmployeeHierarchy) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.getDefaultValues();

    // if (this.flag != 'ADD') {
    //   this.populateForm();
    // }
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter1(value))
      );
  }

  Submit()
  {
    this.getDefaultValues();
  }

  openDialog() {

    const ExcelData = {cid: JSON.parse(sessionStorage.getItem('cid')),                                      
                          flag:"Excel",                     
                          approvergroupid: this.approvergroupid,
                          projectid: this.projectid
                          //,approvergroupname: f.value.approvegroupname
                       };
    sessionStorage.setItem('Exceldata', JSON.stringify(ExcelData));
    const dialogRef = this.dialog.open(HierarchyexcelimportComponent);
    dialogRef.afterClosed().subscribe(result => {   
    });
  }

  private _filter1(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.projectids.filter(option => option.toLowerCase().includes(filterValue));

  }

  selectedproduct(event) {
    this.projectid = JSON.parse(_.pluck(_.where(this.project, { 'ProjectName': event.option.value }), 'ID'));
  }

  getDefaultValues(){

    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
    };
    const query = `cid=${params.cid}`;
    this.service.httpGet('/projecthierarchy/hierarchydefaults?',query)
    .subscribe(
      data => {
        let result: any;
        result = data;

        this.project = result.respdata.Project;
        this.projectids = _.pluck(this.project, 'ProjectName');
        this.employeeOriginal = result.respdata.Employee;
        this.employee = this.employeeOriginal;
        this.employeelist = this.employeeOriginal;

        this.filteredList5= this.employee.slice();

        //  console.log("ess", this.filteredemployeelist)

        // let btnVisilityData;
        // btnVisilityData = this.generic.assignButtonVisibility(result.respdata.formrights);

        // console.log("ess", btnVisilityData)

        // this.addbtnvisible = btnVisilityData.addflag;
        // this.editbtnvisible = btnVisilityData.editflag;
        // this.deletebtnvisible = btnVisilityData.deleteflag;
        // this.viewbtnvisible = btnVisilityData.viewflag;


        this.hirarchyMain = result.respdata.HierarchyGroupList;
        this.hirarchyMain = new MatTableDataSource<HierarchyMain>(result.respdata.HierarchyGroupList);
        // console.log('hierarchy main: ', this.hirarchyMain);
        this.hirarchyMain.sort = this.sort;
        this.hirarchyMain.paginator = this.paginator;

        // if (this.flag != 'ADD') {         
        //   this.projectname = _.pluck(_.where(this.project, { 'ID': this.row.typeid }), 'ProjectName'); 
        //   this.populateForm();
        // }       
      },
      (error: AppError) => {
        if (error instanceof BadInput)
          // this.service.form.setErrors(error.originalError);
          console.log(error);
        else throw error;
      });
  }

  //using for edit/delete/view option
  populateForm(){
    this.approvergroupid = this.row.ApprovalGroupID;
    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      approvergroupid: this.approvergroupid
    };
    const query = `cid=${params.cid}&approvergroupid=${params.approvergroupid}`;
    this.service.httpGet('/projecthierarchy/gethierarchylistsub?',query)
    .subscribe(data => {
      let result: any;
      result = data;
      if (result.errno == 0){
        this.dataSource.data = result.respdata;
        this.approvegroupname = this.row.ApprovalGroupName;
      }
      else{ 
        console.log('error');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)
        // this.service.form.setErrors(error.originalError);
        console.log(error);
      else throw error;
    });
  }

  onCloseDialog(){
    this.initializeFormGroup();
    // this.dialogRef.close({event: 'CLEAR'});
  }

  onClear(){
     this.myControl.setValue('');
     this.approvegroupname='';
    this.treedeletevisible=false
    this.initializeFormGroup();
    this.dataSource = new MatTreeNestedDataSource<EmployeeHierarchy>();
    
  }

  initializeFormGroup(){
    approvegroupid: 0;
    projectid: 0;
    approvegroupname: '';
    level: 0;
    employee: 0;
    employeelist: 0;
  }

  onLevelSelect(e){
    const _data = this.dataSource.data;
     
    this.level = e.value;
    // console.log('level: ',this.level['name']);
    // console.log('datasourcedata : ', _data);
    if (this.level['name'] != 'L1'){
      this.employee = [];
      // console.log('levelname: ', this.level['name']);
      this.filterData(_data, this.level['name']);
    }
    else if (this.level['name'] == 'L1' && _data.length>0){
      this.employee = [];
      this.employee.push({LedgerID: _data[0].LedgerID, name: _data[0].name});
    }

  }

  //filter employee based on level selection and assign into employee dropdown
  filterData(data, filter) {
    // console.log('inside filter data');
    // this.employee = [];
    let found = false;
    // console.log('data : ', data);
    // console.log('filter: ', filter);

    const foundParent = data.forEach(item => {

      if (item.leveldesc === filter) {
        // console.log('found');
        // found = true;
        //  console.log('if : ', item);
        this.employee.push({LedgerID: item.LedgerID, name: item.name});
        //  console.log('employee: ', this.employee);
      }
       else {
        if (item.children != undefined ){
          // console.log('else: ', item.children);
           this.filterData(item.children, filter);
        }
      }

    });

    if (found){
      // console.log('employee: ', this.employee);
      return;
      // return obj;
    }
    // console.log('final call: ', obj);
  }

  onEmployeeSelect(e) {
    this.employeselect = e.value;
    const valueToRemove = e.value.LedgerID;
    const _data = this.dataSource.data;


    if (Object.keys(_data).length == 0){
      this.employeelist = this.employeelist.filter(function(emp){ return emp.LedgerID != valueToRemove; });
    }
    else{
      // this.removeTreeEmployeeFromEmployeeList(_data);
      const resultobj = this.employeelist.filter(({LedgerID: i}) => !(((x) => {const f = a => a.flatMap(({LedgerID: i, children: c}) => c ? [i, ...f(c)] : i); return f(x); })(_data)).includes(i));
      
      this.employeelist = resultobj;

      // this.fruitCtrl.reset()
      // this.employeelistselected.splice(1, 1);
      

    }

    // console.log(this.employeelist);
  }

  removeTreeEmployeeFromEmployeeList(_data){
    const empledgerInTree = [];
    _data.forEach((item) => {
      if (item.children != undefined && item.children.length > 0){
        this.removeTreeEmployeeFromEmployeeList(item.children);
      }
      // else{
        empledgerInTree.push(item.LedgerID);
      // }
    });

    // console.log('empledgerInTree: ', empledgerInTree);
    // Object.values(this.employeelist).forEach(function(o,i){
    //   if(empledgerInTree.ledgerid == o.ledgerid)
    //     delete this.employeelist[i];
    // });

    // console.log('this.employeelist' , this.employeelist);
  }

  // onEmployeeSelect1(e) {
  //   this.employeselect = e.value;
  //   // console.log('employee select: ',this.employeselect);
  //   const valueToRemove = e.value.LedgerID;
  //   const _data = this.dataSource.data;

  //   if (Object.keys(_data).length == 0){
  //     this.employeelist = this.employeelist.filter(function(emp){ return emp.LedgerID != valueToRemove; });
  //   }
  //   else{
  //     this.filterArray(valueToRemove, this.level['name']);
  //   }

  // }

   //remove all selected and its higher level employee and assign into employeelist
  filterArray(valueToRemove, markedItemLevel){
    const result = [];
    result.pop();
    const _data = this.dataSource.data;

    const itemIndexinTree = _data.findIndex(c => c.LedgerID == valueToRemove);
    // var markedItem = this.employeelist.find(t => t.ledgerid == valueToRemove);
    // var markedItemLevel = markedItem.level;
    const markedItemLevelNo = markedItemLevel.split('L')[1];
    const levelToSkip = [];
    for (let index = 1 ; index <=  markedItemLevelNo; index++) {
        levelToSkip.push('L' + index);
    }

    // console.log('levelToSkip: ', levelToSkip);
    // console.log('data: ', _data);

    const idsToSkip = [];
    for (let indexforSkip = 0; indexforSkip < levelToSkip.length; indexforSkip++) {
      _data.forEach((item) => { //debugger;
              if (item.leveldesc == levelToSkip[indexforSkip]){
                idsToSkip.push(item.LedgerID);
              }

              if (item.children.length > 0){
                for (let cindex = 0; cindex < item.children.length; cindex++) {
                    if (item.children[cindex].leveldesc == levelToSkip[indexforSkip]){
                        idsToSkip.push(item.children[cindex].LedgerID);
                  }
                }
              }
      });
    }

    // console.log('idsToSkip: ', idsToSkip);

    this.employeelist = [];
    this.employeeOriginal.forEach((item) => { //debugger;
      // console.log('item: ', item) ;
      if (idsToSkip.indexOf(item.LedgerID) < 0){
        // console.log('item: ', item);
        this.employeelist.push(item);
      }
    });
    // console.log('result: ', this.employeelist);

  }

  // onEmployeeGroupsChange(options: MatListOption[]) {
  //   // map these MatListOptions to their values
  //   this.employeelistselected = options.map(o => o.value);
  //   console.log('map: ',this.employeelistselected);
  // }

  // add treeemployee into treeview control
  addTreeNode(){
    



    // this.service.initializeFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {
      flag: 'ADD',
      employeelist:this.employeelist,
      panelClass: 'custom-dialog-container'
    };
    const dialogRef = this.dialog.open(EmployeeHierarchyComponent, dialogConfig);
    dialogRef.afterClosed()
    .subscribe(res => {
      if (res.event == 'ADD'){
        this.employeelistselected=res.data

    const subdata: EmployeeHierarchy[] = [];
    const subLevel: string = this.level['name'].slice(0, -1) + (this.level['id'] + 1).toString();
    // console.log('subLevel: ', subLevel);
    for (const key of Object.keys(this.employeelistselected)) {
      const keydata = this.employeelistselected[key];
      // console.log('keydata: ', keydata);
      subdata.push({LedgerID: keydata.LedgerID, name: keydata.name, ParentID: this.employeselect['LedgerID'], Level: this.level['id'] + 1, leveldesc: subLevel });
    }

    const _data = this.dataSource.data;

    if (Object.keys(_data).length == 0){
      _data.push({LedgerID:  this.employeselect['LedgerID'], name:  this.employeselect['name'], ParentID: 0, Level: this.level['id'], leveldesc: 'L1', children: subdata});
    }else{
      this.findParent(_data, subdata);
    }
   
    this.dataSource.data = null;
    // console.log('data: ',JSON.stringify(_data));
    this.dataSource.data = _data;

        // this.hirarchyMain.data.push(res);
      }else if (res.event == 'EXIT'){
        // this.updateRowData(res.data);
      }
    });


    
  }

  findParent(data, subdata) {
    // console.log('inside find parent');
    //loop original data with subdata
    const foundParent = data.forEach(item => {
      // check original data ledgerid matched with subdata parentid
      if (item.LedgerID === subdata[0].ParentID) {
        // if original data have children
        if (item.children && item.children.length > 0) {
          // add new children into an existing children
          // console.log('beforechildren',item.children);
          // console.log('existingchildren');
          //item.children = [...item.children, subdata]
          const old = item.children;
          // console.log('before children', JSON.stringify(old));
          //item.children = Object.assign(old,subdata);
          // console.log('before children', JSON.stringify(old));
          item.children = old.concat(subdata);
          // console.log('subdata', subdata);
          // console.log('after children', JSON.stringify(item.children));
        } else {
          //add first children to original data
          // console.log('newchildren');
          item['children'] = subdata;
        }

      } else {
        if (item.children) {
          // again call the function by passing its children
          this.findParent(item.children, subdata);
        }
      }
    });
  }

  submit(f){
    // console.log('save: ',f);
    const param = {
      flag: this.flag,
      cid: JSON.parse(sessionStorage.getItem('cid')),
      approvergroupid: this.approvergroupid,
      projectid: this.projectid,
      approvergroupname: f.value.approvegroupname,
      isactive: true,
      authorisesub: this.dataSource.data
    };
    // console.log('param: ', param);
    // this.service.create(JSON.stringify(param))
    this.service.httpPost('/projecthierarchy/projecthierarchyupdate',param)
    .subscribe(
      (data) => {
        // console.log('inside save');
        let hierarchy: any;
        hierarchy = data; //result.users;

        if (hierarchy.errno === 0){
          // console.log('hierarchy: ', hierarchy.respdata);
          // this.service.form.reset();
          this.initializeFormGroup();
          this.notificationService.success('Submitted successfully');

          // need to add userdetails to send back to grid for display
          this.model = { 'ApprovalGroupID': hierarchy.respdata, 'typeid': this.projectid, 'ApprovalGroupName': f.value.approvegroupname};  //get the model from the form
          // console.log('model ', this.model);
          this.onClose(this.model);
          
          this.getDefaultValues();

        }
        else{
          // console.log('fail');
          this.notificationService.success(hierarchy.errdesc); //':: Problem updating user');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)
          f.setErrors(error.originalError);
          // console.log(error);
        else throw error;
      });
  }


  onClose(model){
    // this.service.form.reset();
    this.initializeFormGroup();
    // this.dialogRef.close({event: this.flag, data: model});
  }

  onEdit(row, _flag,stepper: MatStepper){
    stepper.next();
    this.flag=_flag
    this.myControl.setValue(row.ProjectName)
    this.projectid =row.typeid
     this.approvegroupname=row.ApprovalGroupName;
    
    this.approvergroupid = row.ApprovalGroupID;
    const params = {
      cid: JSON.parse(sessionStorage.getItem('cid')),
      approvergroupid: this.approvergroupid
    };
    const query = `cid=${params.cid}&approvergroupid=${params.approvergroupid}`;
    this.service.httpGet('/projecthierarchy/gethierarchylistsub?',query)
    .subscribe(data => {
      let result: any;
      result = data;
      if (result.errno == 0){
        this.dataSource.data = result.respdata;
        // this.approvegroupname = this.row.ApprovalGroupName;
      }
      else{ 
        console.log('error');
      }
    },
    (error: AppError) => {
      if (error instanceof BadInput)
        // this.service.form.setErrors(error.originalError);
        console.log(error);
      else throw error;
    });
  }
  
  View(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus =  true;
    dialogConfig.data = {       
      CID:JSON.parse(sessionStorage.getItem('cid')),
      Flag:'View'
    }
    const dialogRef = this.dialog.open(EmpprojectlistComponent, dialogConfig)
    dialogRef.afterClosed()
  }

  onDelete(row){
    this.dialogService.openConfirmDialog('Are you sure to delete this hierarchy?')
    .afterClosed().subscribe(res => {
      console.log("dialog",res)
      if (res) {
        const params = {
          flag: 'DELETE',
          cid: '101',
          approvergroupid: row.ApprovalGroupID,
          projectid: row.typeid,
          approvergroupname: row.ApprovalGroupName,
          isactive: true,
          authorisesub: undefined
        };
        // console.log('params: ', params);
        this.service.httpPost('/projecthierarchy/projecthierarchyupdate',params)
          .subscribe(
            data => {
              let result: any;
              result = data;
              if (result.errno == 0){
                // this.hirarchyMain.data.pop(result.data) //.push(res.data);
                this.notificationService.warn('Deleted successfully');
                this.getDefaultValues();
              }else{
                this.notificationService.warn('! Problem in deleting');
              }

            },
            (error: AppError) => {
              if (error instanceof BadInput)
                console.log('Error: ', error);
                // this.service.form.setErrors(error.originalError);
              else throw error;
            });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.hirarchyMain.filter = filterValue.trim().toLowerCase();
  }

  onSearchClear(){
    this.searchKey = '';
    this.hirarchyMain.filter = '';
  }

findPosition(ledgerid: number, data: EmployeeHierarchy[]) {
  for (let i = 0; i < data.length; i += 1) {
    if (ledgerid === data[i].LedgerID) {
      return i;
    }
  }
}

findFatherNode(ledgerid: number, data: EmployeeHierarchy[]) {
  
  for (let i = 0; i < data.length; i += 1) {
    const currentFather = data[i];
    
    for (let z = 0; z < currentFather.children.length; z += 1) {
      if (ledgerid === currentFather.children[z]['LedgerID']) {
        return [currentFather, z];
      }
    }
    for (let z = 0; z < currentFather.children.length; z += 1) {
      if (ledgerid !== currentFather.children[z]['LedgerID']) {
        const result = this.findFatherNode(ledgerid, currentFather.children);
        if (result !== false) {
          return result;
        }
      }
    }
  }
  
  return false;
}
groupdelete()
{
  this.treedeletevisible=true;
}
refreshTreeData() {
  const data = this.dataSource.data;
  this.dataSource.data = null;
  this.dataSource.data = data;
}

deleteNode(nodeToBeDeleted: EmployeeHierarchy) {
  if(nodeToBeDeleted.leveldesc !=='L1')
  {
    const deletedElement: EmployeeHierarchy = this.findFatherNode(nodeToBeDeleted.LedgerID, this.dataSource.data);
    let elementPosition: number;
    if (window.confirm('Are you sure you want to delete ' + nodeToBeDeleted.name + '?' )) {
        if (deletedElement[0]) {

          deletedElement[0].children.splice(deletedElement[1], 1);
        } else {
          console.log('else');
          elementPosition = this.findPosition(nodeToBeDeleted.LedgerID, this.dataSource.data);
          this.dataSource.data.splice(elementPosition, 1);
      }
      this.refreshTreeData();
    }
  }
  
}



} 





export interface HierarchyMain {
  ApprovalGroupID: number;
  typeid: number;
  ApprovalGroupName: string;
}
export class EmployeeHierarchy1 {
  ParentID: number;
  LedgerID: number;
  Level: number;
  name: string;
  leveldesc: string;
  children?: EmployeeHierarchy1[];
}
