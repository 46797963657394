import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {HttpserviceService} from '../../../../Services/httpServices/httpservice.service'
import { NotificationService } from '../../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../../Services/httpServices/ErrorHandler/bad-input';
import { AppError } from './../../../../Services/httpServices/ErrorHandler/app-error';

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss'],
})
export class EntityComponent implements OnInit {

  EntityFormGroup: FormGroup;
  entityid:any="0";
  tag:any;
  description:any
  Flag:any;
  cid:any;
  Type:any='';
  visible:boolean=true;
  effortvisible:boolean=false;
  Taglabel:any='Tag';
  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private formBuilder: FormBuilder
    ,private dialog: MatDialog,public dialogRef: MatDialogRef<EntityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
    { 
      console.log('dialogdata',this.data)
      this.cid = this.data.cid;   
      this.Flag=this.data.Flag;
      this.Type = this.data.Type;
      this.entityid=this.data.entityid;
      this.tag=this.data.Tag;
      this.description=this.data.Description;

      if(this.Flag=='Config')
      {
        this.visible=false;
        this.Taglabel='Type'
      }
      if(this.data.Type=='Request Type')
      {
          this.effortvisible=true;          
      }
  
      this.EntityFormGroup = this.formBuilder.group({  
        fcTag:['', Validators.required],     
        fcDescription:[''],
        fcReadonlyTag:[false], 
        fcAllowEffort:[false] 
      });  

      this.EntityFormGroup.controls.fcTag.setValue(this.tag);
      this.EntityFormGroup.controls.fcDescription.setValue(this.description);
      this.EntityFormGroup.controls.fcReadonlyTag.setValue(this.data.ReadonlyTag);
      this.EntityFormGroup.controls.fcAllowEffort.setValue(this.data.AllowEffort);

    }

  ngOnInit() 
  {}
  onCloseDialog()
  {
    // this.AdvanceRequestViewFormGroup.reset();
    this.dialogRef.close();
  }
  Submit()
  {
    let params = { 
      cid:this.cid,
      flag: this.Flag, 
      EntityID:this.entityid,
      Type:this.Type,             
      Tag:  this.EntityFormGroup.get('fcTag').value,   
      Description: this.EntityFormGroup.get('fcDescription').value,
      ReadonlyTag:this.EntityFormGroup.get('fcReadonlyTag').value,
      AllowEffort:this.EntityFormGroup.get('fcAllowEffort').value
      
     };
    this.service.httpPost('/Entity/SetEntity',params)
    .subscribe(
      (data) => {
        let result: any;
        result = data;       
        if (result.errno === 0) {
          this.NotificationService.success(result.errdesc);
          //console.log('Project list: ', result.respdata);
          this.dialogRef.close({data:'data'});
        }
        else {
          this.NotificationService.success(result.errdesc); //':: Problem details');
        }
      },
      (error: AppError) => {
        if (error instanceof BadInput)         
          console.log(error);
        else throw error;       
      });
  }

}
