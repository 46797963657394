import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {  FormGroup,  Validators, FormBuilder,FormControl } from '@angular/forms';
import { AppError } from 'src/app/Services/httpServices/ErrorHandler/app-error';
import { HttpserviceService } from 'src/app/Services/httpServices/httpservice.service';
import { GenericService } from 'src/app/Services/OtherServices/generic.service';
import { NotificationService } from './../../../Services/OtherServices/notification.service';
import { BadInput } from './../../../Services/httpServices/ErrorHandler/bad-input';
import { User } from './../../../Modules/user';
import { MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import { TicketComponent } from '../../ITS/Ticket/ticket/ticket.component';

@Component({
  selector: 'app-customizeditemrpt',
  templateUrl: './customizeditemrpt.component.html',
  styleUrls: ['./customizeditemrpt.component.scss'],
})
export class CustomizeditemrptComponent implements OnInit {

  Companies:any;
  dataSource: any;
  CustomizedItemFormGroup:FormGroup;
  cid:any;
  showSpinner:any;

  // TaskTemplate = [
  //   { 
  //     ControlType: "select",
  //     ID: "CLIENT",
  //     LabelName: "Merchant Name",
  //     Mandatory: "",
  //     MandatorySymbol: null,
  //     TagReadOnly: null,
  //     Value: null,
  //     parent: "0"
  //   },
  //   { 
  //     ControlType: "select",
  //     ID: "ITEM",
  //     LabelName: "Item Desc",
  //     Mandatory: "",
  //     MandatorySymbol: null,
  //     TagReadOnly: null,
  //     Value: null,
  //     parent: "0"
  //   },
  //   {
  //     ControlType: "textArea",
  //     ID: "DESC",
  //     LabelName: "Description",
  //     Mandatory: "",
  //     MandatorySymbol: null,
  //     TagReadOnly: null,
  //     Value: null,
  //     parent: "0"
  //   }
  // ];

  constructor(private service:HttpserviceService,private NotificationService:NotificationService,private dialog: MatDialog,
    private formBuilder: FormBuilder,private generic: GenericService,private datePipe: DatePipe) { 
      this.CustomizedItemFormGroup = this.formBuilder.group({  
        fcCompany: ['', Validators.required],
        fcFromDate: ['', Validators.required],
        fcToDate: ['', Validators.required] 
      });      
    }

  ngOnInit() {
    this.cid=JSON.parse(sessionStorage.getItem('cid'));       
    let curUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    this.Companies =curUser.dtsiteid; 
    this.CustomizedItemFormGroup.controls.fcCompany.setValue(this.cid); 
  }

  changesite(e) {
    this.cid=e.value;    
  }

  Submit()
  {
    if (this.CustomizedItemFormGroup.invalid) {
      this.NotificationService.warn('Please select date range');     
      return;
      }  
    this.showSpinner=true;
      let params = {     
      CID: this.CustomizedItemFormGroup.get('fcCompany').value,
      Flag: "LOADGRID",                     
      SalesManID:0,
      FromDate: this.datePipe.transform(this.CustomizedItemFormGroup.get('fcFromDate').value,"yyyy-MM-dd"),    
      ToDate: this.datePipe.transform(this.CustomizedItemFormGroup.get('fcToDate').value,"yyyy-MM-dd")==null? 
      this.datePipe.transform(this.CustomizedItemFormGroup.get('fcFromDate').value,"yyyy-MM-dd"):this.datePipe.transform(this.CustomizedItemFormGroup.get('fcToDate').value,"yyyy-MM-dd")      
    };
    this.service.httpPost('/CustomizedItemRpt/GetItemValues',params)
      .subscribe(
        (data) => {
          this.showSpinner=true;
          setTimeout(() =>{
            this.showSpinner=false;
          },
          800)
          let result: any;
          result = data;          
          if (result.errno === 0) {            
            this.dataSource = result.respdata.ItemList;            
          }
          else {
            this.NotificationService.success(result.errdesc); 
          }
        },
        (error: AppError) => {
          if (error instanceof BadInput)           
            console.log(error);
          else throw error;       
        });

  }

  logEvent(_flag, e)
  {
    
  }

  startEdit(e) {
    //console.log("cellclick",e.data)
    if(e.rowType === "data") {
        e.component.editRow(e.rowIndex);
    }
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus =  true;
      dialogConfig.data = {       
        CID:this.CustomizedItemFormGroup.get('fcCompany').value,
        flag: "ITEMREPORT",
        pid:0,
        height: '350px',
        width: '60%',
        // template:this.TaskTemplate,
        TicketID:"0",
        ActiviteID:"INT_EDIT",
        LoginFlag:'INTERNAL',
        TemplateGroupID:1,
        TemplateID:2 ,
        rowdata:e.data  
      }
      console.log("Item dialog",dialogConfig.data)
      const dialogRef = this.dialog.open(TicketComponent, dialogConfig)
      dialogRef.afterClosed()
}
}